import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import parse from "html-react-parser";

import {
  getDomainServices,
  isFetchingDomainServices,
  isCancellingDomainService
} from "../../../Customer/reducers/customerReducer";

import {
  fetchDomainServices,
  cancelDomainService,
  buyDomainIdProtection
} from "../../../Customer/actions/customerActions";

import { addProductToCart } from "../../../Cart/actions/cartActions";
import { isStoringItemInCart } from "../../../Cart/reducers/cartReducer";

import DomainAddon from "./DomainAddon";

import withLoading from "../../../../components/Loaders/WithLoading";
import AddonsLoader from "../../../../components/Loaders/AddonsLoader";
import ActionModal from "../../../../components/ActionModal";

import displayToastMessageForResponse from "../../../../utils/displayToastMessageForResponse";

import {
  compileRoute,
  ROUTE_BILLING_VIEW_PROINVOICE,
  ROUTE_CART
} from "../../../../routes/routes";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import PrimaryWarningButton from "../../../../components/Buttons/PrimaryWarningButton";

const StyledDomainAddons = styled.div`
  margin-bottom: 1px;
`;

const SERVICE_ID_PROTECT = "IdProtect";

class Addons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cancelServiceSubmitting: false,
      cancelServiceSubmittingId: 0,
      showCancelDomainIdProtectionModal: false,
      serviceToCancel: null
    };
  }

  componentDidMount() {
    this.props.fetchDomainServices(this.props.domain);
  }

  cancelService = service => {
    this.setState({
      cancelServiceSubmitting: true,
      cancelServiceSubmittingId: service.id
    });
    this.props
      .cancelDomainService(this.props.domain, service)
      .then(response => {
        this.onHideCancelIdProtectionModal();
        displayToastMessageForResponse(
          this.props.translate("general.domain"),
          response
        );
        this.setState({
          cancelServiceSubmitting: false,
          cancelServiceSubmittingId: service.id
        });
      });
  };

  addToCartService = service => {
    this.setState({
      cancelServiceSubmitting: true,
      cancelServiceSubmittingId: service.id
    });
    this.props
      .addProductToCart({
        type: "domain_service",
        quantity: 1,
        domain: this.props.domain.domain_name,
        purchased_item: {
          id: this.props.domain.id,
          type: "domain",
          name: this.props.domain.domain_name
        },
        service_id: service.id
      })
      .then(response => {
        displayToastMessageForResponse(
          this.props.translate("cart.title"),
          response,
          ROUTE_CART,
          this.props.translate("cart.view-cart")
        );
        this.setState({
          cancelServiceSubmitting: false,
          cancelServiceSubmittingId: 0
        });
      });
  };

  onShowCancelIdProtectionModal = service => {
    this.setState({
      showCancelDomainIdProtectionModal: true,
      serviceToCancel: service
    });
  };

  onHideCancelIdProtectionModal = () => {
    this.setState({
      showCancelDomainIdProtectionModal: false,
      serviceToCancel: null
    });
  };

  render() {
    const StyledDomainAddonsWithLoading = withLoading(
      StyledDomainAddons,
      AddonsLoader
    );

    return (
      <StyledDomainAddonsWithLoading
        isLoading={this.props.isFetchingDomainServices}
      >
        <div className="row">
          {this.props.services.length > 0 &&
            this.props.services.map(service => {
              if (service.name === SERVICE_ID_PROTECT) {
                return (
                  <div key={service.id} className="col-4 col-sm-4 col-md-4">
                    <DomainAddon
                      domain={this.props.domain}
                      service={service}
                      onShowCancelDomainIdProtection={
                        this.onShowCancelIdProtectionModal
                      }
                      onShowBuyDomainIdProtection={
                        this.props.onShowBuyDomainIdProtection
                      }
                      isCancellingDomainService={
                        this.props.isCancellingDomainService
                      }
                      isStoringItemInCart={
                        this.props.isSubmittingEnableIdProtection
                      }
                      cancelServiceSubmitting={
                        this.state.cancelServiceSubmitting
                      }
                      cancelServiceSubmittingId={
                        this.state.cancelServiceSubmittingId
                      }
                    />
                  </div>
                );
              }

              return (
                <div key={service.id} className="col-4 col-sm-4 col-md-4">
                  <DomainAddon
                    domain={this.props.domain}
                    service={service}
                    onShowCancelDomainIdProtection={
                      this.onShowCancelIdProtectionModal
                    }
                    isCancellingDomainService={
                      this.props.isCancellingDomainService
                    }
                    isStoringItemInCart={this.props.isStoringItemInCart}
                    cancelServiceSubmitting={this.state.cancelServiceSubmitting}
                    cancelServiceSubmittingId={
                      this.state.cancelServiceSubmittingId
                    }
                  />
                </div>
              );
            })}
        </div>
        {this.state.showCancelDomainIdProtectionModal && (
          <ActionModal
            title={this.props.translate(
              "domain.addons.id-protect.cancel-title"
            )}
            onCloseActionModal={this.onHideCancelIdProtectionModal}
            body={() => (
              <div>
                {parse(
                  this.props
                    .translate("domain.addons.id-protect.cancel-description")
                    .replace("${DOMAIN}", this.props.domain.domain_name)
                )}
              </div>
            )}
            footer={() => (
              <React.Fragment>
                <SecondaryButton
                  onClick={() => this.onHideCancelIdProtectionModal()}
                >
                  {this.props.translate("domain.general-info.cancel")}
                </SecondaryButton>
                <PrimaryWarningButton
                  disabled={this.state.cancelServiceSubmitting}
                  submitting={this.state.cancelServiceSubmitting}
                  onClick={() => this.cancelService(this.state.serviceToCancel)}
                >
                  {this.props.translate("domain.addons.id-protect.confirm")}
                </PrimaryWarningButton>
              </React.Fragment>
            )}
          />
        )}
      </StyledDomainAddonsWithLoading>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    services: getDomainServices(state, ownProps.domain),
    isFetchingDomainServices: isFetchingDomainServices(state),
    isCancellingDomainService: isCancellingDomainService(state),
    isStoringItemInCart: isStoringItemInCart(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDomainServices,
      cancelDomainService,
      addProductToCart,
      buyDomainIdProtection
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocalize(Addons)));
