import React from "react";
import styled from "styled-components";

const StyledTabContent = styled.div`
  padding-top: 30px;
  height: 100%;
`;

const TabContent = ({ active, label, children }) => (
  <StyledTabContent className={`tab-pane active`}>{children}</StyledTabContent>
);

export default TabContent;
