import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

import FormGroup from "../FormGroup";
import Label from "../Label";
import InputHelpBlock from "../InputField/InputHelpBlock";
import InputFieldIcon from "../InputField/InputFieldIcon";

const StyledTextArea = styled.textarea`
  font-size: 14px;
  background-color: ${theme.neutral1};
  border-radius: 3px;
  border: 1px solid transparent;
  color: ${theme.neutral5};
  line-height: 1.43;
  padding: 10px 20px 10px 10px;
  min-height: 160px;
  resize: none;

  &:hover {
    border: 1px solid ${theme.neutral3};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${theme.blue3};
    border: 1px solid ${theme.blueBase};
  }
  &:active {
    background-color: ${theme.white};
    border: 1px solid ${theme.blueBase};
    outline: none;
    box-shadow: 0;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${theme.neutral2};
  }

  &.is-valid {
    background-color: ${theme.green1};
    border: 1px solid ${theme.greenBase};
    color: ${theme.neutralBase};
  }

  &.is-invalid {
    background-color: ${theme.red1};
    border: 1px solid ${theme.redBase};
    color: ${theme.neutralBase};
  }
`;

const StyledTextAreaWrapper = styled.div`
  position: relative;
  & span.input-icon {
    display: absolute;
    top: 20px;
    right: 10px;
  }
`;

const TextArea = ({ input, meta, ...rest }) => {
  let fieldValidationClass = "";
  if (meta.touched && !meta.pristine && meta.valid && !meta.invalid) {
    fieldValidationClass = "is-valid";
  }

  if (
    (meta.touched && !meta.valid && meta.invalid) ||
    (meta.invalid && meta.initial)
  ) {
    fieldValidationClass = "is-invalid";
  }

  return (
    <FormGroup>
      {rest.label.length > 0 && (
        <Label icon={rest.labelIcon}>{rest.label}</Label>
      )}

      <StyledTextAreaWrapper>
        {rest.inputIcon.length > 0 && (
          <InputFieldIcon
            icon={rest.inputIcon}
            valid={meta.valid}
            invalid={meta.invalid}
          />
        )}
        {meta.touched &&
          !meta.pristine &&
          meta.valid &&
          !meta.invalid && (
            <InputFieldIcon
              icon="success"
              valid={meta.valid}
              invalid={meta.invalid}
            />
          )}
        {meta.touched &&
          meta.invalid &&
          !meta.valid && (
            <InputFieldIcon
              icon="warning"
              valid={meta.valid}
              invalid={meta.invalid}
            />
          )}

        <StyledTextArea
          {...input}
          {...rest}
          className={`form-control ${fieldValidationClass}`}
          autoCorrect="off"
          autoComplete="off"
          autoCapitalize="off"
          spellCheck={false}
        />
        {((meta.touched && meta.invalid && !meta.valid) ||
          (meta.invalid && meta.initial)) && (
          <InputHelpBlock valid={meta.valid} invalid={meta.invalid}>
            {meta.error}
          </InputHelpBlock>
        )}
        {rest.helpText.length > 0 && (
          <InputHelpBlock valid={meta.valid} invalid={meta.invalid}>
            {rest.helpText}
          </InputHelpBlock>
        )}
      </StyledTextAreaWrapper>
    </FormGroup>
  );
};

TextArea.defaultProps = {
  className: "form-control",
  label: "",
  helpText: "",
  labelIcon: "",
  inputIcon: "",
  placeholder: "",
  valid: false,
  invalid: false
};

export default TextArea;
