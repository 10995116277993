import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Translate } from "react-localize-redux";
import { isMobileOnly } from "react-device-detect";

import AmountBadge from "../../components/AmountBadge";

const StyledTable = styled.table`
  color: ${theme.neutralBase};
`;
const StyledTHead = styled.thead`
  background-color: ${theme.neutral1};
`;
const StyledTBody = styled.tbody``;
const StyledTR = styled.tr`
  border-bottom: 1px solid ${theme.neutral3};
  &:last-child {
    border-bottom: 0;
  }
`;
const StyledTH = styled.th`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: ${theme.neutralBase};
  text-transform: uppercase;
`;
const StyledTD = styled.td`
  font-size: 16px;
  & strong {
    font-weight: 600;
  }
`;

const TransactionsTable = ({ transactions, balance, currency }) => {
  return (
    <Translate>
      {({ translate }) => (
        <div className="table-responsive-sm">
          <StyledTable className="table table-borderless">
            <StyledTHead>
              <StyledTR>
                {!isMobileOnly && (
                  <StyledTH>
                    {translate("pro-invoices.view.transaction-date")}
                  </StyledTH>
                )}
                {!isMobileOnly && (
                  <StyledTH>{translate("pro-invoices.view.gateway")}</StyledTH>
                )}
                <StyledTH>
                  {translate("pro-invoices.view.transaction-id")}
                </StyledTH>
                <StyledTH className="text-right">
                  {translate("pro-invoices.view.amount")}
                </StyledTH>
              </StyledTR>
            </StyledTHead>
            <StyledTBody>
              {transactions &&
                transactions.map(transaction => (
                  <StyledTR key={`pro-invoice-transaction-${transaction.id}`}>
                    {!isMobileOnly && <StyledTD>{transaction.date}</StyledTD>}
                    {!isMobileOnly && (
                      <StyledTD>{transaction.gateway}</StyledTD>
                    )}
                    <StyledTD>{transaction.transid}</StyledTD>
                    <StyledTD className="text-right">
                      <AmountBadge
                        amount={parseFloat(transaction.amountin).toFixed(2)}
                        currency={currency}
                      />
                    </StyledTD>
                  </StyledTR>
                ))}
              <StyledTR key={`pro-invoice-transaction-balance`}>
                <StyledTD
                  colSpan={!isMobileOnly ? 3 : ""}
                  className="text-right"
                >
                  {translate("pro-invoices.view.balance")}
                </StyledTD>
                <StyledTD className="text-right">
                  <AmountBadge
                    amount={parseFloat(balance).toFixed(2)}
                    currency={currency}
                  />
                </StyledTD>
              </StyledTR>
            </StyledTBody>
          </StyledTable>
        </div>
      )}
    </Translate>
  );
};

export default TransactionsTable;
