import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Link } from "react-router-dom";

const StyledNavItem = styled.li`
  & a {
    color: ${theme.neutral4};
    padding-top: 25px;
    padding-bottom: 25px;
  }

  & a:hover {
    color: ${theme.neutral5};
  }
`;

StyledNavItem.defaultProps = {
  className: "nav-item"
};

const NavItem = ({ children, ...rest }) => (
  <StyledNavItem
    className={`nav-item ${rest.active ? "active" : ""} ${
      rest.disabled ? "disabled" : ""
    }`}
  >
    {rest.to && (
      <Link className="nav-link px-3" to={rest.to}>
        {children}
      </Link>
    )}
    {!rest.to && (
      <a className="nav-link px-3" href="/">
        {children}
      </a>
    )}
  </StyledNavItem>
);

export default NavItem;
