import * as customerService from "../../../services/customerService.js";
import axios from "axios";

export const CUSTOMER_FEATURE = "features/Customer";
export const CUSTOMER_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_REQUEST`;
export const CUSTOMER_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_RECEIVE`;

export const CUSTOMER_PAYERS_FOR_FILTER_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_PAYERS_FOR_FILTER_REQUEST`;
export const CUSTOMER_PAYERS_FOR_FILTER_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_PAYERS_FOR_FILTER_RECEIVE`;

export const CUSTOMER_DOMAINS_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_REQUEST`;
export const CUSTOMER_DOMAINS_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_RECEIVE`;
export const CUSTOMER_DOMAIN_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_RECEIVE`;
export const CUSTOMER_DOMAINS_VIEW_DOMAIN = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_VIEW_DOMAIN`;
export const CUSTOMER_DOMAINS_DOMAIN_SETTINGS_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_DOMAIN_SETTINGS_REQUEST`;
export const CUSTOMER_DOMAINS_DOMAIN_SETTINGS_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_DOMAIN_SETTINGS_RECEIVE`;
export const CUSTOMER_DOMAINS_DOMAIN_MAMESERVERS_UPDATE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_DOMAIN_MAMESERVERS_UPDATE`;
export const CUSTOMER_DOMAINS_DOMAIN_SERVICES_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_DOMAIN_SERVICES_REQUEST`;
export const CUSTOMER_DOMAINS_DOMAIN_SERVICES_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_DOMAIN_SERVICES_RECEIVE`;
export const CUSTOMER_DOMAINS_DOMAIN_SERVICE_CANCELLATION_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_DOMAIN_SERVICE_CANCELLATION_REQUEST`;
export const CUSTOMER_DOMAINS_DOMAIN_SERVICE_CANCELLATION_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_DOMAIN_SERVICE_CANCELLATION_RECEIVE`;
export const CUSTOMER_DOMAINS_WITHOUT_HOSTING_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_WITHOUT_HOSTING_REQUEST`;
export const CUSTOMER_DOMAINS_WITHOUT_HOSTING_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_WITHOUT_HOSTING_RECEIVE`;
export const CUSTOMER_DOMAIN_NAME_SERVERS_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_NAME_SERVERS_REQUEST`;
export const CUSTOMER_DOMAIN_NAME_SERVERS_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_NAME_SERVERS_RECEIVE`;
export const CUSTOMER_DOMAIN_REGISTER_CONTACTS_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_REGISTER_CONTACTS_REQUEST`;
export const CUSTOMER_DOMAIN_REGISTER_CONTACTS_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_REGISTER_CONTACTS_RECEIVE`;
export const CUSTOMER_DOMAIN_ZONE_FILE_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_ZONE_FILE_REQUEST`;
export const CUSTOMER_DOMAIN_ZONE_FILE_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_ZONE_FILE_RECEIVE`;
export const CUSTOMER_DOMAIN_ZONE_FILE_DELETE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_ZONE_FILE_DELETE`;
export const CUSTOMER_DOMAIN_DNS_RECORD_UPDATE_TYPE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_DNS_RECORD_UPDATE_TYPE`;
export const CUSTOMER_DOMAIN_DNS_RECORD_UPDATE_FIELD = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_DNS_RECORD_UPDATE_FIELD`;
export const CUSTOMER_DOMAIN_DNS_RECORD_UPDATE_ALL_FIELDS = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_DNS_RECORD_UPDATE_ALL_FIELDS`;
export const CUSTOMER_DOMAIN_REDIRECTION_AVAILABLE_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_REDIRECTION_AVAILABLE_REQUEST`;
export const CUSTOMER_DOMAIN_REDIRECTION_AVAILABLE_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_REDIRECTION_AVAILABLE_RECEIVE`;
export const CUSTOMER_DOMAIN_GET_REDIRECTION_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_GET_REDIRECTION_REQUEST`;
export const CUSTOMER_DOMAIN_GET_REDIRECTION_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_GET_REDIRECTION_RECEIVE`;
export const CUSTOMER_DOMAIN_UPDATE_REDIRECTION_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_UPDATE_REDIRECTION_REQUEST`;
export const CUSTOMER_DOMAIN_UPDATE_REDIRECTION_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_UPDATE_REDIRECTION_RECEIVE`;
export const CUSTOMER_DOMAIN_DELETE_REDIRECTION_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_DELETE_REDIRECTION_REQUEST`;
export const CUSTOMER_DOMAIN_DELETE_REDIRECTION_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAIN_DELETE_REDIRECTION_RECEIVE`;

export const CUSTOMER_PRODUCTS_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_REQUEST`;
export const CUSTOMER_PRODUCTS_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_RECEIVE`;
export const CUSTOMER_PRODUCTS_UNASSIGN_PRODUCT = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_UNASSIGN_PRODUCT`;
export const CUSTOMER_PRODUCTS_PRODUCT_SERVICES_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_SERVICES_REQUEST`;
export const CUSTOMER_PRODUCTS_PRODUCT_AVAILABLE_BACKUPS_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_AVAILABLE_BACKUPS_REQUEST`;
export const CUSTOMER_PRODUCTS_PRODUCT_BACKUP_HISTORY_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_BACKUP_HISTORY_REQUEST`;
export const CUSTOMER_PRODUCTS_PRODUCT_BACKUP_START_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_BACKUP_START_REQUEST`;
export const CUSTOMER_PRODUCTS_PRODUCT_SERVICES_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_SERVICES_RECEIVE`;
export const CUSTOMER_PRODUCTS_PRODUCT_AVAILABLE_BACKUPS_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_AVAILABLE_BACKUPS_RECEIVE`;
export const CUSTOMER_PRODUCTS_PRODUCT_BACKUP_HISTORY_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_BACKUP_HISTORY_RECEIVE`;
export const CUSTOMER_PRODUCTS_PRODUCT_BACKUP_START_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_BACKUP_START_RECEIVE`;
export const CUSTOMER_PRODUCTS_PRODUCT_SERVICE_CANCELLATION_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_SERVICE_CANCELLATION_REQUEST`;
export const CUSTOMER_PRODUCTS_PRODUCT_SERVICE_CANCELLATION_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_SERVICE_CANCELLATION_RECEIVE`;

export const CUSTOMER_PRODUCTS_SERVER_SERVICES_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_SERVER_SERVICES_REQUEST`;
export const CUSTOMER_PRODUCTS_SERVER_SERVICES_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_SERVER_SERVICES_RECEIVE`;

export const CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_DIRECT_LOGIN_LINK_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_DIRECT_LOGIN_LINK_REQUEST`;
export const CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_DIRECT_LOGIN_LINK_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_DIRECT_LOGIN_LINK_RECEIVE`;

export const CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_PASSWORD_CHANGE_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_PASSWORD_CHANGE_REQUEST`;
export const CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_PASSWORD_CHANGE_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_PASSWORD_CHANGE_RECEIVE`;

export const CUSTOMER_SERVERS_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_SERVERS_REQUEST`;
export const CUSTOMER_SERVERS_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_SERVERS_RECEIVE`;

export const CUSTOMER_CONTACTS_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_CONTACTS_REQUEST`;
export const CUSTOMER_CONTACTS_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_CONTACTS_RECEIVE`;
export const CUSTOMER_CONTACTS_DOMAIN_CONTACT_CHANGE = `${CUSTOMER_FEATURE}/CUSTOMER_CONTACTS_DOMAIN_CONTACT_CHANGE`;
export const CUSTOMER_CONTACTS_VIEW_CONTACT = `${CUSTOMER_FEATURE}/VIEW_CONTACT`;
export const CUSTOMER_CONTACTS_SELECT_CONTACT = `${CUSTOMER_FEATURE}/SELECT_CONTACT`;
export const CUSTOMER_CONTACTS_ACCEPT_INVITATION = `${CUSTOMER_FEATURE}/ACCEPT_INVITATION`;

export const CUSTOMER_DOMAINS_REQUEST_FILTER_STATUS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_REQUEST_FILTER_STATUS_COLLECTION`;
export const CUSTOMER_DOMAINS_RECEIVE_FILTER_STATUS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_RECEIVE_FILTER_STATUS_COLLECTION`;

export const CUSTOMER_DOMAINS_REQUEST_FILTER_EXTENSIONS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_REQUEST_FILTER_EXTENSIONS_COLLECTION`;
export const CUSTOMER_DOMAINS_RECEIVE_FILTER_EXTENSIONS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_RECEIVE_FILTER_EXTENSIONS_COLLECTION`;

export const CUSTOMER_DOMAINS_REQUEST_FILTER_REGISTER_CONTACTS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_REQUEST_FILTER_REGISTER_CONTACTS_COLLECTION`;
export const CUSTOMER_DOMAINS_RECEIVE_FILTER_REGISTER_CONTACTS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_RECEIVE_FILTER_REGISTER_CONTACTS_COLLECTION`;

export const CUSTOMER_DOMAINS_LIST_SELECT_TABLE_ROW = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_LIST_SELECT_TABLE_ROW`;
export const CUSTOMER_DOMAINS_LIST_DESELECT_TABLE_ROW = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_LIST_DESELECT_TABLE_ROW`;

export const CUSTOMER_DOMAINS_LIST_SELECT_ALL_TABLE_ROWS = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_LIST_SELECT_ALL_TABLE_ROWS`;
export const CUSTOMER_DOMAINS_LIST_DESELECT_ALL_TABLE_ROWS = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_LIST_DESELECT_ALL_TABLE_ROWS`;

export const CUSTOMER_DOMAINS_EPP_CODE_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_EPP_CODE_REQUEST`;
export const CUSTOMER_DOMAINS_EPP_CODE_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_DOMAINS_EPP_CODE_RECEIVE`;

export const CUSTOMER_PRODUCTS_REQUEST_FILTER_STATUS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_REQUEST_FILTER_STATUS_COLLECTION`;
export const CUSTOMER_PRODUCTS_RECEIVE_FILTER_STATUS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_RECEIVE_FILTER_STATUS_COLLECTION`;

export const CUSTOMER_PRODUCTS_REQUEST_FILTER_PRODUCTS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_REQUEST_FILTER_PRODUCTS_COLLECTION`;
export const CUSTOMER_PRODUCTS_RECEIVE_FILTER_PRODUCTS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_RECEIVE_FILTER_PRODUCTSs_COLLECTION`;

export const CUSTOMER_SERVERS_REQUEST_FILTER_STATUS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_SERVERS_REQUEST_FILTER_STATUS_COLLECTION`;
export const CUSTOMER_SERVERS_RECEIVE_FILTER_STATUS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_SERVERS_RECEIVE_FILTER_STATUS_COLLECTION`;

export const CUSTOMER_SERVERS_REQUEST_FILTER_SERVERS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_SERVERS_REQUEST_FILTER_SERVERS_COLLECTION`;
export const CUSTOMER_SERVERS_RECEIVE_FILTER_SERVERS_COLLECTION = `${CUSTOMER_FEATURE}/CUSTOMER_SERVERS_RECEIVE_FILTER_SERVERS_COLLECTION`;

export const CUSTOMER_PRODUCTS_LIST_SELECT_TABLE_ROW = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_LIST_SELECT_TABLE_ROW`;
export const CUSTOMER_PRODUCTS_LIST_DESELECT_TABLE_ROW = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_LIST_DESELECT_TABLE_ROW`;

export const CUSTOMER_PRODUCTS_LIST_SELECT_ALL_TABLE_ROWS = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_LIST_SELECT_ALL_TABLE_ROWS`;
export const CUSTOMER_PRODUCTS_LIST_DESELECT_ALL_TABLE_ROWS = `${CUSTOMER_FEATURE}/CUSTOMER_PRODUCTS_LIST_DESELECT_ALL_TABLE_ROWS`;

export const CUSTOMER_PERSONAL_INFORMATION_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_PERSONAL_INFORMATION_REQUEST`;
export const CUSTOMER_PERSONAL_INFORMATION_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_PERSONAL_INFORMATION_RECEIVE`;
export const CUSTOMER_PERSONAL_INFORMATION_FAILED = `${CUSTOMER_FEATURE}/CUSTOMER_PERSONAL_INFORMATION_FAILED`;

export const CUSTOMER_PERSONAL_INFORMATION_UPDATE_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_PERSONAL_INFORMATION_UPDATE_REQUEST`;
export const CUSTOMER_PERSONAL_INFORMATION_UPDATE_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_PERSONAL_INFORMATION_UPDATE_RECEIVE`;

export const CUSTOMER_EMAILS_REQUEST = `${CUSTOMER_FEATURE}/CUSTOMER_EMAILS_REQUEST`;
export const CUSTOMER_EMAILS_RECEIVE = `${CUSTOMER_FEATURE}/CUSTOMER_EMAILS_RECEIVE`;
export const CUSTOMER_EMAILS_VIEW_EMAIL = `${CUSTOMER_FEATURE}/CUSTOMER_EMAILS_VIEW_EMAIL`;

export function selectCustomerDomainsListTableRow(domain) {
  return dispatch => {
    dispatch(customerDomainsListSelectTableRow(domain));
  };
}

export function deselectCustomerDomainsListTableRow(domain) {
  return dispatch => {
    dispatch(customerDomainsListDeselectTableRow(domain));
  };
}

export function selectAllCustomerDomainsListTableRows(domain) {
  return dispatch => {
    dispatch(customerDomainsListSelectAllTableRows(domain));
  };
}

export function deselectAllCustomerDomainsListTableRows(domain) {
  return dispatch => {
    dispatch(customerDomainsListDeselectAllTableRows(domain));
  };
}

export function selectCustomerProductsListTableRow(product) {
  return dispatch => {
    dispatch(customerProductsListSelectTableRow(product));
  };
}

export function deselectCustomerProductsListTableRow(product) {
  return dispatch => {
    dispatch(customerProductsListDeselectTableRow(product));
  };
}

export function selectAllCustomerProductsListTableRows() {
  return dispatch => {
    dispatch(customerProductsListSelectAllTableRows());
  };
}

export function deselectAllCustomerProductsListTableRows() {
  return dispatch => {
    dispatch(customerProductsListDeselectAllTableRows());
  };
}

export function fetchCustomerPayersForFilter() {
  return dispatch => {
    dispatch(requestCustomerPayersForFilter());
    return customerService.fetchCustomerPayersForFilter().then(result => {
      const { payers } = result.data.data;
      dispatch(receiveCustomerPayersForFilter(payers));
    });
  };
}

export function fetchCustomerPersonalInformation() {
  return dispatch => {
    dispatch(requestCustomerPersonalInformation());
    return customerService
      .fetchCustomerPersonalInformation()
      .then(
        result => {
          if (result && result.status && result.status === 200) {
            const { customer } = result.data.data;
            dispatch(receiveCustomerPersonalInformation(customer));
            return customer;
          } else if (
            result &&
            result.response &&
            result.response.status === 401
          ) {
            dispatch(receiveCustomerPersonalInformationFailed());
            return false;
          }
        },
        err => {
          dispatch(receiveCustomerPersonalInformationFailed());
          return err;
        }
      )
      .catch(err => {
        console.log("sesion expired in customer action catch");
        return err;
      });
  };
}

export function fetchCustomerDomains(limit, page, sort, filters) {
  return dispatch => {
    dispatch(requestCustomerDomains());
    return customerService
      .fetchCustomerDomains(limit, page, sort, filters)
      .then(result => {
        if (result.data) {
          const { domains, paging, filters, sort } = result.data.data;
          dispatch(receiveCustomerDomains(domains, paging, filters, sort));
        }
      });
  };
}

export function fetchDomainsWithoutHosting() {
  return dispatch => {
    dispatch(requestCustomerDomainsWithoutHosting());
    return customerService.fetchCustomerDomainsWithoutHosting().then(result => {
      const { domains } = result.data.data;
      dispatch(receiveCustomerDomainsWithoutHosting(domains));
    });
  };
}

export function fetchCustomerDomainsFiltersStatusCollection() {
  return dispatch => {
    dispatch(requestCustomerDomainsFilterStatusCollection());
    return customerService
      .fetchCustomerDomainsFilterStatusCollection()
      .then(
        result => {
          const { statuses } = result.data.data;
          dispatch(receiveCustomerDomainsFilterStatusCollection(statuses));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchCustomerDomainsFiltersExtensionsCollection() {
  return dispatch => {
    dispatch(requestCustomerDomainsFilterExtensionsCollection());
    return customerService
      .fetchCustomerDomainsFilterExtensionsCollection()
      .then(
        result => {
          const { extensions } = result.data.data;
          dispatch(
            receiveCustomerDomainsFilterExtensionsCollection(extensions)
          );
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchCustomerDomainsFiltersRegisterContactsCollection() {
  return dispatch => {
    dispatch(requestCustomerDomainsFilterRegisterContactsCollection());
    return customerService
      .fetchCustomerDomainsFilterRegisterContactsCollection()
      .then(
        result => {
          const { contacts } = result.data.data;
          dispatch(
            receiveCustomerDomainsFilterRegisterContactsCollection(contacts)
          );
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchCustomerProductsFiltersStatusCollection() {
  return dispatch => {
    dispatch(requestCustomerProductsFilterStatusCollection());
    return customerService
      .fetchCustomerProductsFilterStatusCollection()
      .then(
        result => {
          const { statuses } = result.data.data;
          dispatch(receiveCustomerProductsFilterStatusCollection(statuses));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchCustomerProductsFiltersProductsCollection() {
  return dispatch => {
    dispatch(requestCustomerProductsFilterProductsCollection());
    return customerService
      .fetchCustomerProductsFilterProductsCollection()
      .then(
        result => {
          const { products_grouped } = result.data.data;
          dispatch(
            receiveCustomerProductsFilterProductsCollection(products_grouped)
          );
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchCustomerServersFiltersStatusCollection() {
  return dispatch => {
    dispatch(requestCustomerServersFilterStatusCollection());
    return customerService
      .fetchCustomerServersFilterStatusCollection()
      .then(
        result => {
          const { statuses } = result.data.data;
          dispatch(receiveCustomerServersFilterStatusCollection(statuses));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchCustomerServersFiltersServersCollection() {
  return dispatch => {
    dispatch(requestCustomerServersFilterServersCollection());
    return customerService
      .fetchCustomerServersFilterServersCollection()
      .then(
        result => {
          const { servers_grouped } = result.data.data;
          dispatch(
            receiveCustomerServersFilterServersCollection(servers_grouped)
          );
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchCustomerProductDetails(productId) {
  return dispatch => {
    return customerService
      .fetchCustomerProductDetails(productId)
      .then(
        result => {
          const { error } = result.data;
          const { messages, hosting } = result.data.data;

          return { error, messages, hosting };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function fetchCustomerServerDetails(productId) {
  return dispatch => {
    return customerService
      .fetchCustomerServerDetails(productId)
      .then(
        result => {
          const { error } = result.data;
          const { messages, server } = result.data.data;

          return { error, messages, server };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function getHostingCPanelDirectLoginLink(hosting) {
  return dispatch => {
    dispatch(requestHostingCPanelDirectLoginLink());
    return customerService
      .getHostingCPanelDirectLoginLink(hosting)
      .then(
        result => {
          const { error } = result.data;
          const { messages, link } = result.data.data;

          if (error) {
            return { error, messages };
          }

          dispatch(receiveHostingCPanelDirectLoginLink(link));
          return link;
        },
        error => {
          dispatch(receiveHostingCPanelDirectLoginLink(""));
          return { error: true, messages: [error.message] };
        }
      )
      .catch(error => {
        dispatch(receiveHostingCPanelDirectLoginLink(""));
        return { error: true, messages: [error.message] };
      });
  };
}

export function changeHostingCPanelPassword(
  hosting,
  newPassword,
  confirmNewPassword
) {
  return dispatch => {
    dispatch(requestHostingCPanelPasswordChange());
    return customerService
      .changeHostingCPanelPassword(hosting, newPassword, confirmNewPassword)
      .then(
        result => {
          const { error } = result.data;
          const { messages, link } = result.data.data;

          if (error) {
            return { error, messages };
          }

          dispatch(receiveHostingCPanelPasswordChange());
          return { error, messages, link };
        },
        error => {
          dispatch(receiveHostingCPanelPasswordChange());
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(receiveHostingCPanelPasswordChange());
        return { error: true, messages: [error.message] };
      });
  };
}

export function acceptInvitation(token) {
  return dispatch => {
    return customerService.acceptInvitation(token).then(result => {
      return result;
    });
  };
}

export function registerAssistant(token, assistant) {
  return dispatch => {
    return customerService.registerAssistant(token, assistant).then(result => {
      const { customer } = result.data.data;
      return customer;
    });
  };
}

export function fetchCustomerEmails(limit, page, sort, filters) {
  return dispatch => {
    dispatch(requestCustomerEmails());
    return customerService
      .fetchCustomerEmails(limit, page, sort, filters)
      .then(result => {
        const { emails, paging, filters, sort } = result.data.data;
        dispatch(receiveCustomerEmails(emails, paging, filters, sort));
      });
  };
}

export function fetchCustomerDomainZoneFile(domain) {
  return dispatch => {
    dispatch(requestCustomerDomainZoneFile());
    return customerService
      .fetchCustomerDomainZoneFile(domain)
      .then(
        result => {
          const { dns_zone } = result.data.data;
          dispatch(receiveCustomerDomainZoneFile(domain, dns_zone));
        },
        error => {
          if (error.response.status === 404) {
            dispatch(receiveCustomerDomainZoneFile(domain, null));
          }
        }
      )
      .catch(() => dispatch(receiveCustomerDomainZoneFile(domain, null)));
  };
}

export function createCustomerDomainZoneFile(domain) {
  return dispatch => {
    dispatch(requestCustomerDomainZoneFile());
    return customerService.createCustomerDomainZoneFile(domain).then(
      result => {
        const { error } = result.data;
        const { dns_zone, messages } = result.data.data;
        dispatch(receiveCustomerDomainZoneFile(domain, dns_zone));
        return {
          error,
          dns_zone,
          messages
        };
      },
      err => {
        const { error } = err.response.data;
        const { messages } = err.response.data.data;
        return {
          error,
          messages
        };
      }
    );
  };
}

export function deleteCustomerDomainZoneFile(domain) {
  return dispatch => {
    dispatch(requestCustomerDomainZoneFile());
    return customerService.deleteCustomerDomainZoneFile(domain).then(
      result => {
        const { error } = result.data;
        const { messages } = result.data.data;
        dispatch(customerDomainZoneFileDelete(domain));
        return {
          error,
          messages
        };
      },
      err => {
        const { error } = err.response.data;
        const { messages } = err.response.data.data;
        return {
          error,
          messages
        };
      }
    );
  };
}

export function resetCustomerDomainZoneFile(domain) {
  return dispatch => {
    dispatch(requestCustomerDomainZoneFile());
    return customerService.resetCustomerDomainZoneFile(domain).then(
      result => {
        const { error } = result.data;
        const { dns_zone, messages } = result.data.data;
        dispatch(receiveCustomerDomainZoneFile(domain, dns_zone));
        return {
          error,
          dns_zone,
          messages
        };
      },
      err => {
        const { error } = err.response.data;
        const { messages } = err.response.data.data;
        return {
          error,
          messages
        };
      }
    );
  };
}

function requestCustomerDomainZoneFile() {
  return {
    type: CUSTOMER_DOMAIN_ZONE_FILE_REQUEST
  };
}

function receiveCustomerDomainZoneFile(domain, zoneFile) {
  return {
    type: CUSTOMER_DOMAIN_ZONE_FILE_RECEIVE,
    payload: {
      domain,
      zoneFile: zoneFile
    }
  };
}

function customerDomainZoneFileDelete(domain) {
  return {
    type: CUSTOMER_DOMAIN_ZONE_FILE_DELETE,
    payload: { domain }
  };
}
export function updateCustomerDomainDnsRecordType(domain, record, type) {
  return dispatch => {
    dispatch({
      type: CUSTOMER_DOMAIN_DNS_RECORD_UPDATE_TYPE,
      payload: { domain, record, type }
    });
  };
}

export function updateCustomerDomainDnsField(domain, record, field, value) {
  return dispatch => {
    dispatch({
      type: CUSTOMER_DOMAIN_DNS_RECORD_UPDATE_FIELD,
      payload: {
        domain,
        record,
        field,
        value
      }
    });
  };
}

export function updateAllCustomerDomainDnsFields(domain, record, fields) {
  return dispatch => {
    dispatch({
      type: CUSTOMER_DOMAIN_DNS_RECORD_UPDATE_ALL_FIELDS,
      payload: {
        domain,
        record,
        fields
      }
    });
  };
}

export function addCustomerDnsZoneRecord(domain, record) {
  return dispatch => {
    return customerService.addCustomerDomainZoneRecord(domain, record).then(
      result => {
        const { error } = result.data;
        const { messages, dns_zone } = result.data.data;
        dispatch(receiveCustomerDomainZoneFile(domain, dns_zone));
        return {
          error,
          messages
        };
      },
      err => {
        const { error } = err.response.data;
        const { messages } = err.response.data.data;
        return {
          error,
          messages
        };
      }
    );
  };
}

export function saveCustomerDnsZoneRecord(domain, record) {
  return dispatch => {
    return customerService.saveCustomerDomainZoneRecord(domain, record).then(
      result => {
        const { error } = result.data;
        const { messages, dns_zone } = result.data.data;
        dispatch(receiveCustomerDomainZoneFile(domain, dns_zone));
        return {
          error,
          messages
        };
      },
      err => {
        const { error } = err.response.data;
        const { messages } = err.response.data.data;
        return {
          error,
          messages
        };
      }
    );
  };
}

export function deleteCustomerDnsZoneRecord(domain, record) {
  return dispatch => {
    return customerService.deleteCustomerDomainZoneRecord(domain, record).then(
      result => {
        const { error } = result.data;
        const { messages, dns_zone } = result.data.data;
        dispatch(receiveCustomerDomainZoneFile(domain, dns_zone));
        return {
          error,
          messages
        };
      },
      err => {
        const { error } = err.response.data;
        const { messages } = err.response.data.data;
        return {
          error,
          messages
        };
      }
    );
  };
}

export function requestCustomerPersonalInformation() {
  return {
    type: CUSTOMER_PERSONAL_INFORMATION_REQUEST
  };
}

export function receiveCustomerPersonalInformation(customer) {
  return {
    type: CUSTOMER_PERSONAL_INFORMATION_RECEIVE,
    payload: customer
  };
}

export function receiveCustomerPersonalInformationFailed() {
  return {
    type: CUSTOMER_PERSONAL_INFORMATION_FAILED
  };
}

function requestCustomerPayersForFilter() {
  return {
    type: CUSTOMER_PAYERS_FOR_FILTER_REQUEST
  };
}

function receiveCustomerPayersForFilter(payers) {
  return {
    type: CUSTOMER_PAYERS_FOR_FILTER_RECEIVE,
    payload: payers
  };
}

function requestCustomerDomains() {
  return {
    type: CUSTOMER_DOMAINS_REQUEST
  };
}

function receiveCustomerDomains(domains, paging, filters, sort) {
  return {
    type: CUSTOMER_DOMAINS_RECEIVE,
    payload: {
      items: domains,
      paging,
      filters,
      sort
    }
  };
}

function requestCustomerDomainsWithoutHosting() {
  return {
    type: CUSTOMER_DOMAINS_WITHOUT_HOSTING_REQUEST
  };
}

function receiveCustomerDomainsWithoutHosting(domains) {
  return {
    type: CUSTOMER_DOMAINS_WITHOUT_HOSTING_RECEIVE,
    payload: domains
  };
}

function requestCustomerEmails() {
  return {
    type: CUSTOMER_EMAILS_REQUEST
  };
}

function receiveCustomerEmails(emails, paging, filters, sort) {
  return {
    type: CUSTOMER_EMAILS_RECEIVE,
    payload: {
      items: emails,
      paging,
      filters,
      sort
    }
  };
}

function receiveCustomerDomain(domain) {
  return {
    type: CUSTOMER_DOMAIN_RECEIVE,
    payload: domain
  };
}

export function fetchCustomerProducts(limit, page, sort, filters) {
  return dispatch => {
    dispatch(requestCustomerProducts());
    return customerService
      .fetchCustomerProducts(limit, page, sort, filters)
      .then(result => {
        if (result.data) {
          const { hostings, paging, filters, sort } = result.data.data;
          dispatch(receiveCustomerProducts(hostings, paging, filters, sort));
        }
      });
  };
}

export function fetchCustomerServers(limit, page, sort, filters) {
  return dispatch => {
    dispatch(requestCustomerServers());
    return customerService
      .fetchCustomerServers(limit, page, sort, filters)
      .then(result => {
        if (result.data) {
          const { servers, paging, filters, sort } = result.data.data;
          dispatch(receiveCustomerServers(servers, paging, filters, sort));
        }
      });
  };
}

function requestCustomerDomainEppCode() {
  return {
    type: CUSTOMER_DOMAINS_EPP_CODE_REQUEST
  };
}

function receiveCustomerDomainEppCode() {
  return {
    type: CUSTOMER_DOMAINS_EPP_CODE_RECEIVE
  };
}

function requestCustomerProducts() {
  return {
    type: CUSTOMER_PRODUCTS_REQUEST
  };
}

function receiveCustomerProducts(products, paging, filters, sort) {
  return {
    type: CUSTOMER_PRODUCTS_RECEIVE,
    payload: { products, paging, filters, sort }
  };
}

function requestCustomerServers() {
  return {
    type: CUSTOMER_SERVERS_REQUEST
  };
}

function receiveCustomerServers(servers, paging, filters, sort) {
  return {
    type: CUSTOMER_SERVERS_RECEIVE,
    payload: { servers, paging, filters, sort }
  };
}

export function cancelDomain(domain, period) {
  return dispatch => {
    return customerService
      .cancelDomain(domain, period)
      .then(result => {
        const { error } = result.data;
        const { messages, domain } = result.data.data;
        dispatch(receiveCustomerDomain(domain));
        return { error, messages };
      })
      .catch(error => {
        return { error: true, messages: [error.messages] };
      });
  };
}

export function autoRenewDomain(domain) {
  return dispatch => {
    return customerService
      .autoRenewDomain(domain)
      .then(result => {
        const { error } = result.data;
        const { messages, domain } = result.data.data;
        dispatch(receiveCustomerDomain(domain));
        return { error, messages };
      })
      .catch(error => {
        return { error: true, messages: [error.messages] };
      });
  };
}

export function requestRenewDomainWithPeriod(domain, period) {
  return dispatch => {
    if (domain.is_auto_renew_disabled === false) {
      return customerService
        .requestRenewDomainWithPeriod(domain, period)
        .then(
          result => {
            const { error } = result.data;
            const { messages, invoice } = result.data.data;

            return { error, messages, invoice };
          },
          error => {
            const { messages } = error.response.data.data;
            return { error: true, messages: messages };
          }
        )
        .catch(error => {
          return { error: true, messages: [error.message] };
        });
    }
    return customerService
      .autoRenewDomain(domain)
      .then(result => {
        const { error } = result.data;
        const { messages, domain } = result.data.data;
        dispatch(receiveCustomerDomain(domain));

        return customerService
          .requestRenewDomainWithPeriod(domain, period)
          .then(
            result => {
              const { error } = result.data;
              const { messages, invoice } = result.data.data;

              return { error, messages, invoice };
            },
            error => {
              const { messages } = error.response.data.data;
              return { error: true, messages: messages };
            }
          )
          .catch(error => {
            return { error: true, messages: [error.message] };
          });
      })
      .catch(error => {
        return { error: true, messages: [error.messages] };
      });
  };
}

export function requestRenewDomain(domain) {
  return dispatch => {
    if (domain.is_auto_renew_disabled === false) {
      return customerService
        .requestRenewDomain(domain)
        .then(
          result => {
            const { error } = result.data;
            const { messages, invoice } = result.data.data;

            return { error, messages, invoice };
          },
          error => {
            const { messages } = error.response.data.data;
            return { error: true, messages: messages };
          }
        )
        .catch(error => {
          return { error: true, messages: [error.message] };
        });
    }

    return customerService
      .autoRenewDomain(domain)
      .then(result => {
        const { error } = result.data;
        const { messages, domain } = result.data.data;
        dispatch(receiveCustomerDomain(domain));

        return customerService
          .requestRenewDomain(domain)
          .then(
            result => {
              const { error } = result.data;
              const { messages, invoice } = result.data.data;

              return { error, messages, invoice };
            },
            error => {
              const { messages } = error.response.data.data;
              return { error: true, messages: messages };
            }
          )
          .catch(error => {
            return { error: true, messages: [error.message] };
          });
      })
      .catch(error => {
        return { error: true, messages: [error.messages] };
      });
  };
}

export function fetchDomain(id) {
  return dispatch => {
    return customerService
      .fetchDomain(id)
      .then(
        result => {
          const { error } = result.data;
          const { messages, domain } = result.data.data;

          if (!error) {
            dispatch(receiveCustomerDomain(domain));
          }

          return { error, messages, domain };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function fetchDomainByName(domainName) {
  return dispatch => {
    return customerService
      .fetchDomainByName(domainName)
      .then(
        result => {
          const { error } = result.data;
          const { messages, domain } = result.data.data;

          if (!error) {
            dispatch(receiveCustomerDomain(domain));
          }

          return { error, messages, domain };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function fetchDomainNameServers(domain) {
  return dispatch => {
    dispatch(requestCustomerDomainNameServers());

    return customerService
      .fetchDomainNameServers(domain.id)
      .then(
        result => {
          const { error } = result.data;
          const { messages, name_servers } = result.data.data;

          dispatch(
            receiveCustomerDomainNameServers(domain, name_servers || [])
          );

          return { error, messages, name_servers };
        },
        error => {
          dispatch(receiveCustomerDomainNameServers(domain, []));
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(receiveCustomerDomainNameServers(domain, []));
        return { error: true, messages: [error.message] };
      });
  };
}

export function fetchDomainRegisterContacts(domain) {
  return dispatch => {
    dispatch(requestCustomerDomainRegisterContacts());

    return customerService
      .fetchDomainRegisterContacts(domain.id)
      .then(
        result => {
          const { error } = result.data;
          const { messages, register_contacts } = result.data.data;

          dispatch(
            receiveCustomerDomainRegisterContacts(
              domain,
              register_contacts || []
            )
          );

          return { error, messages, register_contacts };
        },
        error => {
          dispatch(receiveCustomerDomainRegisterContacts(domain, []));
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(receiveCustomerDomainRegisterContacts(domain, []));
        return { error: true, messages: [error.message] };
      });
  };
}

export function transferInDomain(domain, epp_code) {
  return dispatch => {
    return customerService
      .transferInDomain(domain, epp_code)
      .then(
        result => {
          const { error } = result.data;
          const { messages, invoice } = result.data.data;
          return { error, messages, invoice };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function fetchEmail(id) {
  return dispatch => {
    return customerService.fetchEmail(id).then(result => {
      const { email } = result.data.data;

      return email;
    });
  };
}

export function unlockDomain(domain) {
  return dispatch => {
    return customerService.unlockDomain(domain).then(result => {
      // if an error didnt occur then domain was unlocked

      const { error } = result.data;
      const { messages } = result.data.data;

      dispatch(receiveDomainSettings(domain, { is_locked: false }));

      return { error, messages, locked: false };
    });
  };
}

export function lockDomain(domain) {
  return dispatch => {
    return customerService.lockDomain(domain).then(result => {
      // if an error didnt occur then domain was locked

      const { error } = result.data;
      const { messages } = result.data.data;

      dispatch(receiveDomainSettings(domain, { is_locked: true }));

      return { error, messages, locked: true };
    });
  };
}

export function fetchDomainLockStatus(domain) {
  return dispatch => {
    return customerService.fetchDomainLockStatus(domain).then(result => {
      const { is_locked } = result.data.data;
      return is_locked;
    });
  };
}

export function fetchEppTransferCode(domain) {
  return dispatch => {
    dispatch(requestCustomerDomainEppCode());
    return customerService
      .fetchEppTransferCode(domain)
      .then(
        result => {
          const { error } = result.data;
          const { messages, epp_transfer_code } = result.data.data;

          dispatch(receiveCustomerDomainEppCode());

          return { error, messages, epp_transfer_code };
        },
        error => {
          dispatch(receiveCustomerDomainEppCode());
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(receiveCustomerDomainEppCode());
        return { error: true, messages: [error.message] };
      });
  };
}

export function domainDirectTransfer(domain, epp_code) {
  return dispatch => {
    return customerService.domainDirectTransfer(domain, epp_code);
  };
}

export function viewDomain(domain) {
  return { type: CUSTOMER_DOMAINS_VIEW_DOMAIN, payload: domain };
}

export function viewEmail(email) {
  return { type: CUSTOMER_EMAILS_VIEW_EMAIL, payload: email };
}

export function fetchProductServices(product) {
  return dispatch => {
    dispatch(requestProductServices());
    return customerService.fetchProductServices(product).then(result => {
      const { services } = result.data.data;
      dispatch(receiveProductServices(services, product));
      return services;
    });
  };
}

export function fetchHostingBackups(product) {
    return dispatch => {
        dispatch(requestProductAvailableBackups());
        return customerService.fetchHostingBackups(product).then(result => {
            const { backups_dates } = result.data.data;
            dispatch(receiveProductAvailableBackups(backups_dates, product));
            return backups_dates;
        })
    };
}

export function fetchHostingBackupHistory(product, limit, page, silent = false) {
    return dispatch => {
        if(!silent)
        dispatch(requestProductBackupHistory());
        return customerService.fetchHostingBackupHistory(product, limit, page).then(result => {
            const { restore_history, paging } = result.data.data;
            dispatch(receiveProductBackupHistory(restore_history, product));
            return {restore_history, paging};
        });
    };
}

export function startRestore(product, restoreOptions) {
    return dispatch => {
        dispatch(requestStartBackup());
        return customerService.startRestore(product, restoreOptions).then(result => {
            dispatch(receiveStartBackup(result.data.data.messages.join(' ')));
            return result.data;
        }).catch((error) => {
            dispatch(receiveStartBackup(error.response.data.data.messages.join(' ')));
            return error.response.data
        });
    };
}



export function fetchServerServices(product) {
  return dispatch => {
    dispatch(requestServerServices());
    return customerService.fetchServerServices(product).then(result => {
      const { services } = result.data.data;
      dispatch(receiveServerServices(services, product));
      return services;
    });
  };
}

export function fetchDomainServices(domain) {
  return dispatch => {
    dispatch(requestDomainServices());
    return customerService.fetchDomainServices(domain).then(result => {
      const { services } = result.data.data;
      dispatch(receiveDomainServices(services, domain));
    });
  };
}

export function cancelDomainService(domain, service) {
  return dispatch => {
    dispatch(requestDomainServiceCancellation());
    return customerService
      .cancelDomainService(domain, service)
      .then(
        result => {
          const { error } = result.data;
          const { messages } = result.data.data;

          dispatch(
            receiveDomainServiceCancellation(error ? null : service, domain)
          );

          return { error, messages };
        },
        error => {
          dispatch(receiveDomainServiceCancellation(null, domain));
          return { error: true, messages: [error.message] };
        }
      )
      .catch(error => {
        dispatch(receiveDomainServiceCancellation(null, domain));
        return { error: true, messages: [error.message] };
      });
  };
}

export function buyDomainIdProtection(domain) {
  return dispatch => {
    return customerService
      .buyDomainIdProtection(domain)
      .then(result => {
        const { error } = result.data;
        const { messages, invoice_id } = result.data.data;
        return {
          error,
          invoice_id,
          messages
        };
      })
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function cancelProductService(product, service) {
  return dispatch => {
    dispatch(requestProductServiceCancellation());
    return customerService
      .cancelProductService(product, service)
      .then(
        result => {
          const { error } = result.data;
          const { messages } = result.data.data;

          dispatch(
            receiveProductServiceCancellation(error ? null : service, product)
          );

          return { error, messages };
        },
        error => {
          dispatch(receiveProductServiceCancellation(null, product));
          return { error: true, messages: [error.message] };
        }
      )
      .catch(error => {
        dispatch(receiveProductServiceCancellation(null, product));
        return { error: true, messages: [error.message] };
      });
  };
}

export function fetchDomainSettings(domain) {
  return dispatch => {
    dispatch(requestDomainSettings(domain));
    return customerService.fetchDomainLockStatus(domain).then(result => {
      const { is_locked } = result.data.data;
      dispatch(receiveDomainSettings(domain, { is_locked }));
    });
  };
}

export function requestDomainSettings(domain) {
  return {
    type: CUSTOMER_DOMAINS_DOMAIN_SETTINGS_REQUEST,
    payload: domain
  };
}

export function receiveDomainSettings(domain, settings) {
  return {
    type: CUSTOMER_DOMAINS_DOMAIN_SETTINGS_RECEIVE,
    payload: {
      domain,
      settings
    }
  };
}

export function requestDomainServices() {
  return {
    type: CUSTOMER_DOMAINS_DOMAIN_SERVICES_REQUEST
  };
}

export function receiveDomainServices(services, domain) {
  return {
    type: CUSTOMER_DOMAINS_DOMAIN_SERVICES_RECEIVE,
    payload: { services, domain }
  };
}

export function requestProductServices() {
  return {
    type: CUSTOMER_PRODUCTS_PRODUCT_SERVICES_REQUEST
  };
}

export function receiveProductServices(services, product) {
  return {
    type: CUSTOMER_PRODUCTS_PRODUCT_SERVICES_RECEIVE,
    payload: { services, product }
  };
}

export function requestProductAvailableBackups() {
    return {
        type: CUSTOMER_PRODUCTS_PRODUCT_AVAILABLE_BACKUPS_REQUEST
    };
}
export function receiveProductAvailableBackups(backups, product) {
  return {
    type: CUSTOMER_PRODUCTS_PRODUCT_AVAILABLE_BACKUPS_RECEIVE,
    payload: { backups, product }
  };
}

export function requestProductBackupHistory() {
    return {
        type: CUSTOMER_PRODUCTS_PRODUCT_BACKUP_HISTORY_REQUEST
    };
}
export function receiveProductBackupHistory(backup_history, product) {
    return {
        type: CUSTOMER_PRODUCTS_PRODUCT_BACKUP_HISTORY_RECEIVE,
        payload: { backup_history, product }
    };
}


export function requestStartBackup() {
    return {
        type: CUSTOMER_PRODUCTS_PRODUCT_BACKUP_START_REQUEST
    };
}
export function receiveStartBackup(restoreStatus) {
    return {
        type: CUSTOMER_PRODUCTS_PRODUCT_BACKUP_START_RECEIVE,
        payload: { restoreStatus }
    };
}

export function requestServerServices() {
  return {
    type: CUSTOMER_PRODUCTS_SERVER_SERVICES_REQUEST
  };
}

export function receiveServerServices(services, product) {
  return {
    type: CUSTOMER_PRODUCTS_SERVER_SERVICES_RECEIVE,
    payload: { services, product }
  };
}

export function requestDomainServiceCancellation() {
  return {
    type: CUSTOMER_DOMAINS_DOMAIN_SERVICE_CANCELLATION_REQUEST
  };
}

export function receiveDomainServiceCancellation(service, domain) {
  return {
    type: CUSTOMER_DOMAINS_DOMAIN_SERVICE_CANCELLATION_RECEIVE,
    payload: { service, domain }
  };
}

export function requestProductServiceCancellation() {
  return {
    type: CUSTOMER_PRODUCTS_PRODUCT_SERVICE_CANCELLATION_REQUEST
  };
}

export function receiveProductServiceCancellation(service, product) {
  return {
    type: CUSTOMER_PRODUCTS_PRODUCT_SERVICE_CANCELLATION_RECEIVE,
    payload: { service, product }
  };
}

export function updateDomainNameservers(domain, nameservers, type) {
  return dispatch => {
    return customerService
      .updateDomainNameservers(domain, nameservers)
      .then(
        result => {
          const { error } = result.data;
          const { domain, messages } = result.data.data;

          if (!error) {
            dispatch(domainNameserversUpdate(domain, nameservers, type));
          }

          return { error, messages };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

function domainNameserversUpdate(domain, nameservers, type) {
  return {
    type: CUSTOMER_DOMAINS_DOMAIN_MAMESERVERS_UPDATE,
    payload: {
      domain,
      nameservers,
      type
    }
  };
}

export function fetchCustomerContacts() {
  return dispatch => {
    dispatch(requestCustomerContacts());
    return customerService.fetchCustomerContacts().then(result => {
      const { contacts } = result.data.data;
      dispatch(receiveCustomerContacts(contacts));
    });
  };
}

export function fetchCustomerContact(id) {
  return dispatch => {
    dispatch(requestCustomerContacts());
    return customerService.fetchCustomerContact(id).then(result => {
      const { assistant, contact_items } = result.data.data;
      dispatch(receiveCustomerContacts([{ ...assistant, contact_items }]));
      return { ...assistant, contact_items };
    });
  };
}

export function requestCustomerContacts() {
  return {
    type: CUSTOMER_CONTACTS_REQUEST
  };
}

export function receiveCustomerContacts(contacts) {
  return {
    type: CUSTOMER_CONTACTS_RECEIVE,
    payload: contacts
  };
}

export function viewCustomerContact(contact) {
  return {
    type: CUSTOMER_CONTACTS_VIEW_CONTACT,
    payload: contact
  };
}

export function selectCustomerContact(contact) {
  return {
    type: CUSTOMER_CONTACTS_SELECT_CONTACT,
    payload: contact
  };
}

export function changeCustomerDomainContact(domain, contactType, contact) {
  return dispatch => {
    return customerService
      .changeCustomerDomainContact(domain, contactType, contact)
      .then(
        result => {
          const { error } = result.data;
          //const { domain, contact, messages } = result.data.data;
          const { messages } = result.data.data;
          if (!error) {
            dispatch(
              customerDomainContactChange(domain, {
                ...contact,
                type: contactType
              })
            );
          }

          return { error, messages, domain, contact };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function assignCustomerContactToProduct(
  product,
  productType,
  contact,
  role
) {
  return dispatch => {
    return customerService
      .assignCustomerContactToProduct(product, productType, contact, role)
      .then(result => {
        dispatch(customerDomainContactChange(product, contact));
        return {
          product,
          contact
        };
      });
  };
}

// this usually will be only for biller / developer roles
export function aggregatedCallToAssignContactRoleForProduct(roles) {
  return dispatch => {
    const rolesApiCalls = roles.map(role => {
      if (role.action === "assign") {
        return customerService.assignCustomerContactToProduct(
          role.product,
          role.type,
          role.contact,
          role.role
        );
      }

      if (role.action === "unassign") {
        return customerService.unassignProductFromContact(
          role.product,
          role.contact
        );
      }
      return role;
    });

    return customerService
      .aggregatedCallToAssignContactRoleForProduct(rolesApiCalls)
      .then(
        axios.spread((...args) => {
          return args;
        })
      );
  };
}

function customerDomainContactChange(domain, contact) {
  return {
    type: CUSTOMER_CONTACTS_DOMAIN_CONTACT_CHANGE,
    payload: {
      domain,
      contact
    }
  };
}

export function searchContacts(keyword) {
  return dispatch => {
    return customerService.searchContacts(keyword).then(result => {
      const { contacts } = result.data.data;
      return contacts;
    });
  };
}

export function searchPayers(keyword) {
  return dispatch => {
    return customerService.searchPayers(keyword).then(result => {
      const { contacts } = result.data.data;
      return contacts;
    });
  };
}

export function unassignProductFromContact(product, contact) {
  return dispatch => {
    return customerService
      .unassignProductFromContact(product, contact)
      .then(result => {
        dispatch(contactProductUnassign(product, contact));
        return result.data;
      });
  };
}

function contactProductUnassign(product, contact) {
  return {
    type: CUSTOMER_PRODUCTS_UNASSIGN_PRODUCT,
    payload: {
      product,
      contact
    }
  };
}

function requestCustomerDomainsFilterStatusCollection() {
  return {
    type: CUSTOMER_DOMAINS_REQUEST_FILTER_STATUS_COLLECTION
  };
}

function receiveCustomerDomainsFilterStatusCollection(statuses) {
  return {
    type: CUSTOMER_DOMAINS_RECEIVE_FILTER_STATUS_COLLECTION,
    payload: statuses
  };
}

function requestCustomerDomainsFilterExtensionsCollection() {
  return {
    type: CUSTOMER_DOMAINS_REQUEST_FILTER_EXTENSIONS_COLLECTION
  };
}

function receiveCustomerDomainsFilterExtensionsCollection(extensions) {
  return {
    type: CUSTOMER_DOMAINS_RECEIVE_FILTER_EXTENSIONS_COLLECTION,
    payload: extensions
  };
}

function requestCustomerDomainsFilterRegisterContactsCollection() {
  return {
    type: CUSTOMER_DOMAINS_REQUEST_FILTER_REGISTER_CONTACTS_COLLECTION
  };
}

function receiveCustomerDomainsFilterRegisterContactsCollection(contacts) {
  return {
    type: CUSTOMER_DOMAINS_RECEIVE_FILTER_REGISTER_CONTACTS_COLLECTION,
    payload: contacts
  };
}

function customerDomainsListSelectTableRow(domain) {
  return {
    type: CUSTOMER_DOMAINS_LIST_SELECT_TABLE_ROW,
    payload: domain
  };
}

function customerDomainsListDeselectTableRow(domain) {
  return {
    type: CUSTOMER_DOMAINS_LIST_DESELECT_TABLE_ROW,
    payload: domain
  };
}

function customerDomainsListSelectAllTableRows() {
  return {
    type: CUSTOMER_DOMAINS_LIST_SELECT_ALL_TABLE_ROWS
  };
}

function customerDomainsListDeselectAllTableRows() {
  return {
    type: CUSTOMER_DOMAINS_LIST_DESELECT_ALL_TABLE_ROWS
  };
}

function requestCustomerProductsFilterStatusCollection() {
  return {
    type: CUSTOMER_PRODUCTS_REQUEST_FILTER_STATUS_COLLECTION
  };
}

function receiveCustomerProductsFilterStatusCollection(statuses) {
  return {
    type: CUSTOMER_PRODUCTS_RECEIVE_FILTER_STATUS_COLLECTION,
    payload: statuses
  };
}

function requestCustomerProductsFilterProductsCollection() {
  return {
    type: CUSTOMER_PRODUCTS_REQUEST_FILTER_PRODUCTS_COLLECTION
  };
}

function receiveCustomerProductsFilterProductsCollection(products) {
  return {
    type: CUSTOMER_PRODUCTS_RECEIVE_FILTER_PRODUCTS_COLLECTION,
    payload: products
  };
}

function requestCustomerServersFilterStatusCollection() {
  return {
    type: CUSTOMER_SERVERS_REQUEST_FILTER_STATUS_COLLECTION
  };
}

function receiveCustomerServersFilterStatusCollection(statuses) {
  return {
    type: CUSTOMER_SERVERS_RECEIVE_FILTER_STATUS_COLLECTION,
    payload: statuses
  };
}

function requestCustomerServersFilterServersCollection() {
  return {
    type: CUSTOMER_SERVERS_REQUEST_FILTER_SERVERS_COLLECTION
  };
}

function receiveCustomerServersFilterServersCollection(products) {
  return {
    type: CUSTOMER_SERVERS_RECEIVE_FILTER_SERVERS_COLLECTION,
    payload: products
  };
}

function customerProductsListSelectTableRow(product) {
  return {
    type: CUSTOMER_PRODUCTS_LIST_SELECT_TABLE_ROW,
    payload: product
  };
}

function customerProductsListDeselectTableRow(product) {
  return {
    type: CUSTOMER_PRODUCTS_LIST_DESELECT_TABLE_ROW,
    payload: product
  };
}

function customerProductsListSelectAllTableRows() {
  return {
    type: CUSTOMER_PRODUCTS_LIST_SELECT_ALL_TABLE_ROWS
  };
}

function customerProductsListDeselectAllTableRows() {
  return {
    type: CUSTOMER_PRODUCTS_LIST_DESELECT_ALL_TABLE_ROWS
  };
}

function requestCustomerPersonalInformationUpdate() {
  return {
    type: CUSTOMER_PERSONAL_INFORMATION_UPDATE_REQUEST
  };
}

function receiveCustomerPersonalInformationUpdate(customer) {
  return {
    type: CUSTOMER_PERSONAL_INFORMATION_UPDATE_RECEIVE,
    payload: customer
  };
}

function requestHostingCPanelDirectLoginLink() {
  return {
    type: CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_DIRECT_LOGIN_LINK_REQUEST
  };
}

function receiveHostingCPanelDirectLoginLink(link) {
  return {
    type: CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_DIRECT_LOGIN_LINK_RECEIVE,
    payload: link
  };
}

function requestHostingCPanelPasswordChange() {
  return {
    type: CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_PASSWORD_CHANGE_REQUEST
  };
}

function receiveHostingCPanelPasswordChange() {
  return {
    type: CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_PASSWORD_CHANGE_RECEIVE
  };
}

export function updateCustomerPersonalInformation(personalInformation) {
  return dispatch => {
    dispatch(requestCustomerPersonalInformationUpdate());
    return customerService
      .updateCustomerPersonalInfromation(personalInformation)

      .then(
        result => {
          const { error } = result.data;
          const { messages, customer } = result.data.data;
          dispatch(receiveCustomerPersonalInformationUpdate(customer));

          return { error, messages };
        },
        error => {
          dispatch(receiveCustomerPersonalInformationUpdate(null));
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(receiveCustomerPersonalInformationUpdate(null));
        return { error: true, messages: [error.message] };
      });
  };
}

export function updateCustomerLanguage(lang, customer) {
  return dispatch => {
    return customerService.updateCustomerLanguage(lang).then(() => {
      dispatch(
        receiveCustomerPersonalInformationUpdate({
          ...customer,
          language: lang
        })
      );
    });
  };
}

function requestCustomerDomainNameServers() {
  return {
    type: CUSTOMER_DOMAIN_NAME_SERVERS_REQUEST
  };
}

function receiveCustomerDomainNameServers(domain, nameServers) {
  return {
    type: CUSTOMER_DOMAIN_NAME_SERVERS_RECEIVE,
    payload: {
      domain: domain,
      nameServers: nameServers
    }
  };
}

function requestCustomerDomainRegisterContacts() {
  return {
    type: CUSTOMER_DOMAIN_REGISTER_CONTACTS_REQUEST
  };
}

function receiveCustomerDomainRegisterContacts(domain, contacts) {
  return {
    type: CUSTOMER_DOMAIN_REGISTER_CONTACTS_RECEIVE,
    payload: {
      domain: domain,
      contacts: contacts
    }
  };
}

export function fetchCustomerDomainRedirectionAvailable(domain) {
  return dispatch => {
    dispatch(requestCustomerDomainRedirectionAvailable());
    return customerService
      .checkCustomerDomainRedirection(domain)
      .then(
        result => {
          const { error } = result.data;
          const { redirection_possible, messages } = result.data.data;
          if (!error) {
            dispatch(
              receiveCustomerDomainRedirectionAvailable(
                redirection_possible,
                messages[0] || ""
              )
            );
            return true;
          }
          if (error) {
            dispatch(
              receiveCustomerDomainRedirectionAvailable(false, messages[0])
            );
            return false;
          }
          return false;
        },
        error => {
          const { messages } = error.response.data.data;
          dispatch(
            receiveCustomerDomainRedirectionAvailable(false, messages[0])
          );
        }
      )
      .catch(error => {
        const { messages } = error.response.data.data;
        dispatch(receiveCustomerDomainRedirectionAvailable(false, messages[0]));
      });
  };
}

function requestCustomerDomainRedirectionAvailable() {
  return {
    type: CUSTOMER_DOMAIN_REDIRECTION_AVAILABLE_REQUEST
  };
}

function receiveCustomerDomainRedirectionAvailable(
  redirection_available,
  reason
) {
  return {
    type: CUSTOMER_DOMAIN_REDIRECTION_AVAILABLE_RECEIVE,
    payload: {
      redirection_available: redirection_available,
      reason
    }
  };
}

export function fetchDomainRedirection(domain) {
  return dispatch => {
    dispatch(requestCustomerDomainRedirection());
    return customerService
      .getCustomerDomainRedirection(domain)
      .then(
        result => {
          const { error } = result.data;
          const { redirection } = result.data.data;
          dispatch(receiveCustomerDomainRedirection(redirection));
        },
        () => {
          dispatch(receiveCustomerDomainRedirection(null));
        }
      )
      .catch(() => {
        dispatch(receiveCustomerDomainRedirection(null));
      });
  };
}

function requestCustomerDomainRedirection() {
  return {
    type: CUSTOMER_DOMAIN_GET_REDIRECTION_REQUEST
  };
}

function receiveCustomerDomainRedirection(redirection) {
  return {
    type: CUSTOMER_DOMAIN_GET_REDIRECTION_RECEIVE,
    payload: redirection
  };
}

export function updateCustomerDomainRedirection(domain, redirect_to) {
  return dispatch => {
    dispatch(requestCustomerDomainRedirectionUpdate());
    return customerService
      .updateCustomerDomainRedirection(domain, redirect_to)
      .then(
        result => {
          const { error } = result.data;
          const { redirection } = result.data.data;
          if (!error) {
            dispatch(receiveCustomerDomainRedirectionUpdate(redirection));
          }

          if (error) {
            dispatch(receiveCustomerDomainRedirectionUpdate(null));
          }

          return { error };
        },
        err => {
          dispatch(receiveCustomerDomainRedirectionUpdate(null));
          return { error: true };
        }
      )
      .catch(err => {
        dispatch(receiveCustomerDomainRedirectionUpdate(null));
        return { error: true };
      });
  };
}

function requestCustomerDomainRedirectionUpdate() {
  return {
    type: CUSTOMER_DOMAIN_UPDATE_REDIRECTION_REQUEST
  };
}

function receiveCustomerDomainRedirectionUpdate(redirection) {
  return {
    type: CUSTOMER_DOMAIN_UPDATE_REDIRECTION_RECEIVE,
    payload: redirection
  };
}

export function removeCustomerDomainRedirection(domain) {
  return dispatch => {
    dispatch(requestCustomerDomainRedirectionRemove());
    return customerService
      .deleteCustomerDomainRedirection(domain)
      .then(result => {
        const { error } = result.data;
        if (!error) dispatch(receiveCustomerDomainRedirectionRemove());
        return { error };
      });
  };
}

function requestCustomerDomainRedirectionRemove() {
  return {
    type: CUSTOMER_DOMAIN_DELETE_REDIRECTION_REQUEST
  };
}

function receiveCustomerDomainRedirectionRemove() {
  return {
    type: CUSTOMER_DOMAIN_DELETE_REDIRECTION_RECEIVE
  };
}
