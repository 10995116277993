import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Link } from "react-router-dom";

const StyledUserWithEmail = styled.div`
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  & .user {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: ${theme.neutralBase};
    margin-bottom: 2px;
  }

  & .email {
    font-size: 14px;
    line-height: 1.43;
    color: ${theme.neutral6};
    margin-bottom: 0;
  }
`;

const UserWithEmail = ({ user, email, linkToUser, uppercaseUsername }) => (
  <StyledUserWithEmail className="user-with-email">
    {linkToUser && (
      <Link to={linkToUser}>
        <p className={`user ${uppercaseUsername && "text-uppercase"}`}>
          {user}
        </p>
        <p className="email">{email}</p>
      </Link>
    )}
    {!linkToUser && [
      <p key="user" className={`user ${uppercaseUsername && "text-uppercase"}`}>
        {user}
      </p>,
      <p key="email" className="email">
        {email}
      </p>
    ]}
  </StyledUserWithEmail>
);

UserWithEmail.defaultProps = {
  user: "",
  email: "",
  linkToUser: null
};
export default UserWithEmail;
