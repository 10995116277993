import React from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {isFetchingBackupHistory} from "../../../../Customer/reducers/customerReducer";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchHostingBackupHistory} from "../../../../Customer/actions/customerActions";
import withLoading from "../../../../../components/Loaders/WithLoading";
import ContentLoader from "../../../../../components/Loaders/ContentLoader";
import styled from "styled-components";
import Table from "../../../../../components/Table";
import TableHeader from "../../../../../components/Table/TableHeader";
import TableRow from "../../../../../components/Table/TableRow";
import {isBrowser} from "react-device-detect";
import TableHeading from "../../../../../components/Table/TableHeading";
import TableBody from "../../../../../components/Table/TableBody";
import TableCell from "../../../../../components/Table/TableCell";
import Pagination from "../../../../../components/Pagination";
import {theme} from "../../../../../design/Themes"
import Panel from "../../../../../components/Panel";
import getBadgeForStatus from "../../../../../utils/getBadgeForStatus";
import Icon from "../../../../../components/Icon";
import CustomTooltip from "../../../../../components/CustomTooltip";


const StyledHostingAvailableBackups = styled.div
    `margin-bottom: 1px;`;


const ContainerTitle = styled.h3`
    text-align:left;
    text-transform: uppercase;
`

const StyledHeader = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${theme.neutral3};
`;

const StyledContent = styled.div`
  padding: 0;
`;
const ActionIcon = styled.div`
position:absolute;
 top : -70px;
 cursor: pointer; 
 font-size:30px;
 right:40px;`


class BackupRestoreHistory extends React.Component {

    // timeoutFunction;
    constructor(props) {
        super(props);

        this.state = {
            restore_history: [],
            paging: {
                limit: 5,
                current_page: 0,
                next_page: 0,
                previous_page: 0,
                total_pages: 0,
                total: 0
            },

        };
    }


    handlePaginationClick = (event, page) => {
        event.preventDefault();

        if (page <= 0 || page > this.state.paging.total_pages) {
            return;
        }

        this.props.fetchHostingBackupHistory(
            this.props.hosting,
            this.state.paging.limit,
            page
        ).then(data => {
            this.setState({restore_history: data.restore_history, paging: data.paging});
        });
    };

    onPerPageOptionChanged = value => {
        this.props.fetchHostingBackupHistory(
            this.props.hosting,
            value.value,
            1
        ).then(data => {
            this.setState({restore_history: data.restore_history, paging: data.paging});
        });
    };

    componentDidMount() {
        this.fillData();
    }

    fillData (silent = false){
        // clearTimeout(this.timeoutFunction)
        this.props.fetchHostingBackupHistory(this.props.hosting, this.props.limit, this.props.page, silent).then(data => {
            this.setState({restore_history: data.restore_history, paging: data.paging});
            // this.timeoutFunction = setTimeout(() => {
            //     this.fillData(true);
            // },10000)
        });
    }


    render() {
        const StyledHostingHistoryBackupsWithLoading = withLoading(
            StyledHostingAvailableBackups,
            ContentLoader
        );

        return (
            <Translate>
                {({translate}) => (
                    <div>
                        <StyledContent>
                            <Panel title={translate("backup.backups-history")}>
                                <ActionIcon onClick={() => {this.fillData()}}>
                                    &#8635;
                                </ActionIcon>
                            <StyledHostingHistoryBackupsWithLoading
                                isLoading={this.props.isFetchingBackupHistory}
                            >
                                <div>
                                    <Table>
                                        <TableHeader>
                                            <TableRow>
                                                {isBrowser && (<TableHeading
                                                    width="20%"
                                                >

                                                    {translate(
                                                        "backup.date"
                                                    )}
                                                </TableHeading>)}
                                                {isBrowser && (<TableHeading
                                                    width="30%"


                                                >
                                                    {translate(
                                                        "backup.history"
                                                    )}
                                                </TableHeading>)}
                                                <TableHeading
                                                    width="20%"
                                                    isSortable={false}
                                                >
                                                    {translate(
                                                        "backup.from"
                                                    )}
                                                </TableHeading>
                                                {isBrowser && (<TableHeading
                                                    width="30%"


                                                >
                                                    {translate(
                                                        "backup.time"
                                                    )}
                                                </TableHeading>)}
                                                <TableHeading
                                                    width="20%"
                                                >
                                                    {translate(
                                                        "backup.status"
                                                    )}
                                                </TableHeading>


                                            </TableRow>

                                        </TableHeader>

                                        <TableBody>
                                            {this.state.restore_history.length === 0 && (
                                                <TableRow>
                                                    <TableCell colSpan="6">
                                                        {translate("referral-admin.table-empty")}
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                            {this.state.restore_history.length > 0 &&
                                            this.state.restore_history.map(restorePoint => {
                                                const BadgeComponent = getBadgeForStatus(
                                                    restorePoint.flag
                                                );
                                                return (
                                                    <TableRow
                                                        key={`customer-backup-${restorePoint.restore_point}-${restorePoint.initialization_time}`}>
                                                        {isBrowser && (<TableCell>
                                                            {restorePoint.restore_point}
                                                        </TableCell>)}
                                                        {isBrowser && (<TableCell>
                                                            {restorePoint.restored_data.length > 0 &&
                                                            (<span> {restorePoint.restored_data.join(', ')} </span>)
                                                            }
                                                        </TableCell>)}

                                                        <TableCell>
                                                            {restorePoint.initialization_Account}
                                                        </TableCell>
                                                        {isBrowser && (<TableCell>
                                                            {restorePoint.initialization_time}
                                                        </TableCell>)}
                                                        <TableCell>

                                                                <CustomTooltip
                                                                    content={translate("backup.status-info." + restorePoint.flag
                                                                    )}
                                                                    inline={true}
                                                                >
                                                                    <BadgeComponent>
                                                                        <>
                                                                    {restorePoint.flag == 'green' &&
                                                                    <Icon icon="success" />
                                                                    }
                                                                    {restorePoint.flag == 'yellow' &&
                                                                    <Icon icon="info" />
                                                                    }
                                                                    {restorePoint.flag == 'red' &&
                                                                    <Icon icon="info" />
                                                                    }

                                                                    {restorePoint.flag == 'white' &&
                                                                    <Icon icon="info" />
                                                                    }
                                                                        {restorePoint.status}
                                                                </>
                                                            </BadgeComponent>
                                                                </CustomTooltip>

                                                        </TableCell>

                                                    </TableRow>
                                                );

                                            })}


                                        </TableBody>

                                    </Table>

                                    <Pagination
                                        paging={this.state.paging}
                                        handlePaginationClick={this.handlePaginationClick}
                                        onPerPageOptionChanged={this.onPerPageOptionChanged}
                                    />

                                </div>
                            </StyledHostingHistoryBackupsWithLoading>
                            </Panel>
                        </StyledContent>

                    </div>

                )}
            </Translate>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isFetchingBackupHistory: isFetchingBackupHistory(state),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {fetchHostingBackupHistory},
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(BackupRestoreHistory));
