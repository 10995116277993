import * as RA from "../actions/referralActions";
import handleReducerActions from "../../../core/handleReducerActions";
import {APP_STATE_COMPLETE_RESET} from "../../AppState/actions/appStateActions";
import {SORT_ORDER_DESCENDING} from "../../../types/SortTypes";



const initialState = {
  all: [],
  referral_code: '',
  referral_url: '',
  paging: {
    limit: 10,
    current_page: 0,
    next_page: 0,
    previous_page: 0,
    total_pages: 0,
    total: 0
  },
  filters: {
    date_created_range: "",
    date_paid_on_range: "",
  },
  sort: {
    sort_by: "id", // Remove magic variable implementation
    order_by: SORT_ORDER_DESCENDING
  },
  isFetching: false,
  isFetchingFilters: false
};

const referralStateHandlers = {
  // [RA.REFERRAL_GENERATE]: generateCode,
  [RA.REFERRAL_CODE_RECEIVE]: codeReceived,
  [RA.REFERRAL_FETCH_INVOICES]: fetchInvoices,
  [APP_STATE_COMPLETE_RESET]: resetState,

};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(referralStateHandlers, state, action);
}

export function codeReceived(state,action){
  return {
    ...state,
    referral_code:  action.referral_code,
    referral_url:  action.referral_url,
  }
}

export function fetchInvoices(state, data){
  return{
    ...state,
    all : data.all,
    filters: data.filters,
    sort: data.sort,
    paging: data.all.paging,
    total_revenue : data.total_revenue
  }
}

function resetState(state) {
  return { ...initialState };
}

// this is a selector
export function getAllReferralInvoices(state) {
  return state.invoices.all;
}

export function getReferralDetails(state){
  return state.referral
}

export function getCode(state){
  return state.referral_code;
}

export function getUrl(state){
  return state.referral_url;
}

export function getFilters(state){
  return state.filters
}
export function getTotalRevenue(state){
  return state.total_revenue
}





