import graphicDedicatedIp from "../assets/images/graphic-dedicated-ip.svg";
import graphicSsl from "../assets/images/graphic-ssl.svg";
import graphicSupport from "../assets/images/graphic-support.svg";

const icon = {
  "Dedicated IP": graphicDedicatedIp,
  "SSL certificates": graphicSsl,
  "Premium support": graphicSupport,
  "Server management": graphicDedicatedIp,
  "Personal SSL": graphicSsl,
  "E-commerce SSL": graphicSsl,
  "Multi SSL": graphicSsl
};

export default function getIconForHostingAddon(name) {
  return icon[name] ? icon[name] : "";
}
