import * as AA from "../actions/appsActions";

import handleReducerActions from "../../../../core/handleReducerActions";

const initialState = {
  items: [],
  scopes: [],
  isFetchingApps: false,
  isCreatingApp: false,
  isUpdatingApp: false,
  isDeletingApp: false,
  isFetchingScopes: false
};

const appsStateHandlers = {
  [AA.APPS_REQUEST]: appsRequest,
  [AA.APPS_RECEIVE]: appsReceive,
  [AA.APPS_APP_SELECT]: appSelect,
  [AA.APPS_APP_CREATE_REQUEST]: appCreateRequest,
  [AA.APPS_APP_CREATE_RECEIVE]: appCreateReceive,
  [AA.APPS_SCOPES_REQUEST]: appsScopesRequest,
  [AA.APPS_SCOPES_RECEIVE]: appsScopesReceive,
  [AA.APPS_APP_UPDATE_REQUEST]: appUpdateRequest,
  [AA.APPS_APP_UPDATE_RECEIVE]: appUpdateReceive,
  [AA.APPS_APP_DELETE_REQUEST]: appDeleteRequest,
  [AA.APPS_APP_DELETE_RECEIVE]: appDeleteReceive
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(appsStateHandlers, state, action);
}

function appsRequest(state, action) {
  return { ...state, isFetchingApps: true };
}

function appsReceive(state, action) {
  const items = action.payload.map(item => {
    return { ...item, selected: false };
  });
  if (items.length > 0) {
    items[0].selected = true;
  }

  return {
    ...state,
    items,
    isFetchingApps: false
  };
}

function appSelect(state, action) {
  return {
    ...state,
    items: state.items.map(app => {
      if (app.id !== action.payload.id) {
        return { ...app, selected: false };
      }

      return {
        ...app,
        selected: true
        // scopes
      };
    })
  };
}

function appCreateRequest(state, action) {
  return { ...state, isCreatingApp: true };
}

function appCreateReceive(state, action) {
  return {
    ...state,
    items: state.items.concat({ ...action.payload, scopes: [] }),
    isCreatingApp: false
  };
}

function appsScopesRequest(state) {
  return {
    ...state,
    isFetchingScopes: true
  };
}

function appsScopesReceive(state, action) {
  return {
    ...state,
    scopes: [...action.payload],
    isFetchingScopes: false
  };
}

function appUpdateRequest(state) {
  return {
    ...state,
    isUpdatingApp: true
  };
}

function appUpdateReceive(state, action) {
  return {
    ...state,
    isUpdatingApp: false,
    items: state.items.map(app => {
      if (app.id !== action.payload.id) {
        return { ...app };
      }

      return {
        ...app,
        ...action.payload
      };
    })
  };
}

function appDeleteRequest(state) {
  return {
    ...state,
    isDeletingApp: true
  };
}

function appDeleteReceive(state, action) {
  return {
    ...state,
    items: state.items.filter(app => app.id !== action.payload.id),
    isDeletingApp: false
  };
}

// this is a selector
export function getAllApps(state) {
  return state.apps.items;
}

export function getAllScopes(state) {
  return state.apps.scopes;
}

export function getSelectedApp(state) {
  return state.apps.items.find(app => app.selected);
}

export function isFetchingApps(state) {
  return state.apps.isFetchingApps;
}

export function isFetchingScopes(state) {
  return state.apps.isFetchingScopes;
}

export function isDeletingApp(state) {
  return state.apps.isDeletingApp;
}
