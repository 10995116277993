import axios from "axios";
import { sprintf } from "sprintf-js";

import { SEARCH_API_ENDPOINT } from "./endpoints";

export function globalSearch(keyword) {
  return new Promise(resolve => {
    axios
      .get(sprintf(SEARCH_API_ENDPOINT, { keyword }))
      .then(result => {
        const { error } = result.data;
        const { messages, results } = result.data.data;

        if (error) {
          resolve({ error, messages });
          return;
        }

        return resolve(results);
      })
      .catch(error => {
        resolve({ error: true, messages: [error.messages] });
      });
  });
}
