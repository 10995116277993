import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { withLocalize } from "react-localize-redux";
import isLaptop from "../../../../utils/isLaptopScreen";
import withSizes from "react-sizes";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";

import Container from "../../../../components/Containers/Container";
import ToggleBox from "../../../../components/Togglebox";
import ActionButton from "../../../../components/Buttons/ActionButton";
import Icon from "../../../../components/Icon";

import ScopesList from "./ScopesList";
import { isMobile } from "react-sizes/src/presets";

const StyledAppDetailsWrapper = styled(Container)`
  margin-bottom: ${props => (props.isMobile ? "100px" : "30px")};
`;

const StyledHeader = styled.div`
  padding: ${props => (props.isMobile ? "15px" : "30px")};
  border-bottom: 1px solid ${theme.neutral3};

  & .app-enable-toggle {
    display: inline-block;
    top: ${props => props.isMobile && "12px"};
  }
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: ${theme.neutralBase};
`;

const StyledAppEnabled = styled.span`
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${theme.neutral5};
  margin-right: 15px;
  vertical-align: top;
  position: relative;
  top: ${props => (props.isMobile ? "18px" : "7px")};
`;

const StyledContent = styled.div`
  padding: ${props => (props.isMobile ? "15px" : "30px")};
`;

const StyledActionButton = styled(ActionButton)`
  position: relative;
  top: ${props => !props.isMobile && "-12px"};
  margin-left: 35px;
`;

const AppDetails = ({
  app,
  scopes,
  onEnableApp,
  onDisableApp,
  onToggleAppScope,
  onDeleteApp,
  isDeletingApp,
  translate,
  isLaptop
}) => {
  let leftColClassName = "col-12 col-sm-12 col-md-7";
  let rightColClassName = "col-12 col-sm-12 col-md-5";

  if (isLaptop || isTablet) {
    leftColClassName = "col-12 col-sm-12 col-md-12";
    rightColClassName = "col-12 col-sm-12 col-md-12";
  }

  if (isMobileOnly) {
    leftColClassName = "col-12 col-sm-12 col-md-12";
    rightColClassName = "col-12 col-sm-12 col-md-12";
  }

  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12">
        <StyledAppDetailsWrapper isMobile={isMobileOnly}>
          {(isMobileOnly || (isTablet && !isBrowser)) && (
            <StyledHeader isMobile={isMobileOnly}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledTitle>{app.name}</StyledTitle>
                </div>
                <div className="col-12 col-sm-12 col-md-12 text-right">
                  <StyledAppEnabled isMobile={isMobileOnly}>
                    {translate("general.enabled")}
                  </StyledAppEnabled>
                  <ToggleBox
                    className="app-enable-toggle"
                    toggled={app.is_active}
                    disabled={isDeletingApp}
                    onToggle={() =>
                      app.is_active ? onDisableApp(app) : onEnableApp(app)
                    }
                  />
                  <StyledActionButton
                    onClick={() => onDeleteApp(app)}
                    isMobile={isMobileOnly}
                  >
                    <Icon icon="delete" />
                  </StyledActionButton>
                </div>
              </div>
            </StyledHeader>
          )}
          {!isMobileOnly && !isTablet && isBrowser && (
            <StyledHeader isMobile={isMobileOnly}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledTitle>{app.name}</StyledTitle>
                </div>
                <div className="col-12 col-sm-12 col-md-12 text-right">
                  <StyledAppEnabled isMobile={isMobileOnly}>
                    {translate("general.enabled")}
                  </StyledAppEnabled>
                  <ToggleBox
                    className="app-enable-toggle"
                    toggled={app.is_active}
                    disabled={isDeletingApp}
                    onToggle={() =>
                      app.is_active ? onDisableApp(app) : onEnableApp(app)
                    }
                  />
                  <StyledActionButton
                    onClick={() => onDeleteApp(app)}
                    isMobile={isMobileOnly}
                  >
                    <Icon icon="delete" />
                  </StyledActionButton>
                </div>
              </div>
            </StyledHeader>
          )}
          <StyledContent isMobile={isMobileOnly}>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <ScopesList
                  app={app}
                  allScopes={scopes}
                  appScopes={app.scopes}
                  isAppActive={app.is_active}
                  onToggleAppScope={onToggleAppScope}
                />
              </div>
            </div>
          </StyledContent>
        </StyledAppDetailsWrapper>
      </div>
    </div>
  );
};
export default withSizes(isLaptop)(withLocalize(AppDetails));
