import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";

import {
  isFetchingServerServices,
  isCancellingProductService
} from "../../../Customer/reducers/customerReducer";

import {
  fetchServerServices,
  cancelProductService
} from "../../../Customer/actions/customerActions";

import { addProductToCart } from "../../../Cart/actions/cartActions";
import { isStoringItemInCart } from "../../../Cart/reducers/cartReducer";

import HostingAddon from "./HostingAddon";

import withLoading from "../../../../components/Loaders/WithLoading";
import AddonsLoader from "../../../../components/Loaders/AddonsLoader";

import { ROUTE_CART } from "../../../../routes/routes";

import displayToastMessageForResponse from "../../../../utils/displayToastMessageForResponse";

const StyledHostingAddons = styled.div`
  margin-bottom: 1px;
`;

class Addons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      services: [],
      addToCartSubmitting: false,
      addToCartId: 0
    };
  }

  componentDidMount() {
    this.props.fetchServerServices(this.props.server).then(services => {
      this.setState({ services: services });
    });
  }

  cancelService = service => {
    this.props
      .cancelProductService(this.props.server, service)
      .then(response => {
        displayToastMessageForResponse(
          this.props.translate("servers.title"),
          response
        );

        if (!response.error) {
          this.setState({
            services: this.state.services.map(current => {
              if (current.id !== service.id) return current;

              return {
                ...current,
                state: {
                  name: "Inactive",
                  state: "inactive"
                }
              };
            })
          });
        }
      });
  };

  addToCartService = service => {
    this.setState({
      addToCartSubmitting: true,
      addToCartSubmittingId: service.id
    });
    this.props
      .addProductToCart({
        type: "server_service",
        quantity: 1,
        purchased_item: {
          id: this.props.server.id,
          type: "server",
          name: this.props.server.name
        },
        service_id: service.id,
        domain: this.props.server.domain
      })
      .then(response => {
        displayToastMessageForResponse(
          this.props.translate("servers.title"),
          response,
          ROUTE_CART,
          this.props.translate("cart.title")
        );
        this.setState({
          addToCartSubmitting: false,
          addToCartSubmittingId: 0
        });
      });
  };

  render() {
    const StyledHostingAddonsWithLoading = withLoading(
      StyledHostingAddons,
      AddonsLoader
    );

    return (
      <StyledHostingAddonsWithLoading isLoading={false}>
        <div className="row">
          {this.state.services.length > 0 &&
            this.state.services.map(service => {
              return (
                <div key={service.id} className="col-4 col-sm-4 col-md-4">
                  <HostingAddon
                    service={service}
                    cancelService={this.cancelService}
                    addToCartService={this.addToCartService}
                    addToCartSubmitting={this.state.addToCartSubmitting}
                    addToCartSubmittingId={this.state.addToCartSubmittingId}
                    isCancellingHostingService={
                      this.props.isCancellingHostingService
                    }
                    isStoringItemInCart={this.props.isStoringItemInCart}
                  />
                </div>
              );
            })}
        </div>
      </StyledHostingAddonsWithLoading>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isFetchingServerServices: isFetchingServerServices(state),
    isCancellingHostingService: isCancellingProductService(state),
    isStoringItemInCart: isStoringItemInCart(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      cancelProductService,
      addProductToCart,
      fetchServerServices
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Addons));
