import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import { theme } from "../../design/Themes";
import moment from "moment";

import Container from "../../components/Containers/Container";
import WidgetTitle from "../WidgetTitle";

import Table from "../../components/Table";
import TableBody from "../../components/Table/TableBody";
import TableRow from "../../components/Table/TableRow";
import TableCell from "../../components/Table/TableCell";

import withLoading from "../../components/Loaders/WithLoading";
import DomainsWidgetLoader from "../../components/Loaders/DomainsWidgetLoader";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

import getBadgeForStatus from "../../utils/getBadgeForStatus";
import getStatusTextForStatus from "../../utils/getStatusTextForStatus";

import DomainsWidgetEmptyState from "./DomainsWidgetEmptyState";

import {
  DOMAIN_STATUS_ACTIVE,
  DOMAIN_STATUS_REDEMPTION,
  DOMAIN_STATUS_GRACE_PERIOD
} from "../../features/Domains/DomainStatuses/DomainStatuses";

const StyledDomainsWidget = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
`;

const StyledDomainsWidgetContent = styled.div`
  margin-top: -1px;
  & a.domain-link {
    font-size: 16px;
  }

  & a.domain-link-block {
    display: block;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }
`;

const DomainsWidgetContentWithLoading = withLoading(
  StyledDomainsWidgetContent,
  DomainsWidgetLoader
);

const DomainsWidget = props => (
  <Translate>
    {({ translate }) => (
      <Container className="widget-container">
        <StyledDomainsWidget className="widget pro-invoices-widget">
          <WidgetTitle
            title={translate("home.widgets.domains.title")}
            actionTo={props.domainsRoute()}
            actionTitle={translate("home.widgets.domains.view-all-domains")}
          />
          <DomainsWidgetContentWithLoading isLoading={props.isFetchingDomains}>
            <div className="row">
              <div
                className={`col-12 col-sm-12 col-md-12 ${isMobileOnly &&
                  "table-responsive-sm"}`}
              >
                {!props.domains ||
                  (props.domains.length <= 0 && <DomainsWidgetEmptyState />)}
                {props.domains.length > 0 && (
                  <Table>
                    <TableBody>
                      {props.domains.map(domain => {
                        const BadgeComponent = getBadgeForStatus(
                          domain.expiry_status.state
                        );

                        const StatusComponent = getStatusTextForStatus(
                          domain.expiry_status.state
                        );

                        return (
                          <TableRow key={`${`customer-domain-${domain.id}`}`}>
                            <TableCell className="row-selector">
                              <Link
                                className={
                                  isTablet || isMobileOnly
                                    ? "domain-link domain-link-block"
                                    : "domain-link"
                                }
                                to={props.viewDomainRoute({
                                  name: domain.domain_name,
                                  id: domain.id
                                })}
                              >
                                {domain.domain_name}
                              </Link>
                              {(isTablet || isMobileOnly) && (
                                <StatusComponent>
                                  {domain.expiry_status.text}
                                </StatusComponent>
                              )}
                              {((domain.status.code === DOMAIN_STATUS_ACTIVE &&
                                moment()
                                  .add(2, "M")
                                  .isAfter(
                                    moment(domain.expiry_date),
                                    "day"
                                  )) ||
                                domain.status.code ===
                                  DOMAIN_STATUS_GRACE_PERIOD ||
                                domain.status.code ===
                                  DOMAIN_STATUS_REDEMPTION) &&
                                (isTablet || isMobileOnly) && (
                                  <Link
                                    to={props.viewDomainRoute({
                                      name: domain.domain_name,
                                      id: domain.id
                                    })}
                                  >
                                    <SecondaryButton
                                      className="d-block mt-1"
                                      size="s"
                                    >
                                      {translate("domains.table.renew-now")}
                                    </SecondaryButton>
                                  </Link>
                                )}
                            </TableCell>
                            {isBrowser && (
                              <TableCell>
                                <BadgeComponent>
                                  {domain.expiry_status.text}
                                </BadgeComponent>
                              </TableCell>
                            )}
                            {isBrowser && (
                              <TableCell className="text-right actions">
                                {((domain.status.code ===
                                  DOMAIN_STATUS_ACTIVE &&
                                  moment()
                                    .add(2, "M")
                                    .isAfter(
                                      moment(domain.expiry_date),
                                      "day"
                                    )) ||
                                  domain.status.code ===
                                    DOMAIN_STATUS_GRACE_PERIOD ||
                                  domain.status.code ===
                                    DOMAIN_STATUS_REDEMPTION) && (
                                  <Link
                                    to={props.viewDomainRoute({
                                      name: domain.domain_name,
                                      id: domain.id
                                    })}
                                  >
                                    <SecondaryButton size="s">
                                      {translate("domains.table.renew-now")}
                                    </SecondaryButton>
                                  </Link>
                                )}
                              </TableCell>
                            )}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                )}
              </div>
            </div>
          </DomainsWidgetContentWithLoading>
        </StyledDomainsWidget>
      </Container>
    )}
  </Translate>
);

export default DomainsWidget;
