import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import { withLocalize } from "react-localize-redux";
import parse from "html-react-parser";

import ProductPlan from "../../../Shop/Hosting/ProductPlan";

const StyledCartAddHostingToDomainHostingPlans = styled.div`
  & .hosting-plan {
    padding-top: ${props =>
      !props.isMobile && props.adjustForEn ? "80px" : "100px"};
  }

  & .product-plan-wrapper {
    margin-top: -2px;
  }
`;

const StyledLookingForHosting = styled.span`
  display: inline-block;
  float: right;
  color: ${theme.blueBase};
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
`;

const StyledRecommendedPlan = styled.div`
  background-color: ${theme.blue4};
  padding: 20px 30px;
  margin-top: ${props =>
    !props.isMobile && props.adjustForEn ? "-80px" : "-100px"};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  z-index: 10;
  position: relative;

  & .title {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    color: ${theme.white};
    display: block;
  }

  & .description {
    display: block;
    font-size: 14px;
    color: ${theme.white};
  }
`;

const calculateHostingColSize = numberOfHostings => {
  const size = numberOfHostings > 1 ? Math.round(12 / numberOfHostings) : 4;

  return size > 4 ? size : 4;
};

class CartAddHostingToDomainHostingPlans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hostingsType: "web"
    };
  }

  calculateHostingColSize = numberOfHostings => {
    const size = numberOfHostings > 1 ? Math.round(12 / numberOfHostings) : 4;

    return size > 4 ? size : 4;
  };

  onShowWindowsHostings = () => {
    this.setState({
      hostingsType: "windows"
    });
  };

  onShowWebHostings = () => {
    this.setState({
      hostingsType: "web"
    });
  };

  render() {
    const {
      hostings,
      availableDomains,
      submitting,
      disabled,
      onAddToCart
    } = this.props;

    let web = null;
    let windows = null;
    if (this.props.hostings.length > 0) {
      web = this.props.hostings.find(hosting => hosting.name === "Web Hosting");
      windows = this.props.hostings.find(
        hosting => hosting.name === "Windows Hosting"
      );
    }

    return (
      <StyledCartAddHostingToDomainHostingPlans
        isMobile={isMobileOnly}
        adjustForEn={
          this.props.activeLanguage && this.props.activeLanguage.code === "en"
        }
      >
        {this.state.hostingsType === "web" && (
          <div>
            {isMobileOnly && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    {web &&
                      web.hostings.length > 0 &&
                      web.hostings.map(hosting => {
                        return (
                          <div
                            key={`hosting-plan-${hosting.id}`}
                            className={`col-12 col-sm-12 col-md-12 hosting-plan`}
                          >
                            {availableDomains.length === 1 &&
                              hosting.name === "Basic" && (
                                <StyledRecommendedPlan
                                  isMobile={isMobileOnly}
                                  adjustForEn={
                                    this.props.activeLanguage &&
                                    this.props.activeLanguage.code === "en"
                                  }
                                >
                                  <span className="title">
                                    {this.props.translate(
                                      "cart.recommended-for-you"
                                    )}
                                  </span>
                                  <span className="description">
                                    {parse(
                                      this.props.translate(
                                        "cart.host-one-domain"
                                      )
                                    )}
                                  </span>
                                </StyledRecommendedPlan>
                              )}
                            {availableDomains.length > 1 &&
                              availableDomains.length < 6 &&
                              hosting.name === "Regular" && (
                                <StyledRecommendedPlan
                                  isMobile={isMobileOnly}
                                  adjustForEn={
                                    this.props.activeLanguage &&
                                    this.props.activeLanguage.code === "en"
                                  }
                                >
                                  <span className="title">
                                    {this.props.translate(
                                      "cart.recommended-for-you"
                                    )}
                                  </span>
                                  <span className="description">
                                    {parse(
                                      this.props
                                        .translate("cart.host-all-domains")
                                        .replace(
                                          "{DOMAINS}",
                                          availableDomains.length
                                        )
                                    )}
                                  </span>
                                </StyledRecommendedPlan>
                              )}
                            {availableDomains.length >= 6 &&
                              hosting.name === "Power" && (
                                <StyledRecommendedPlan
                                  isMobile={isMobileOnly}
                                  adjustForEn={
                                    this.props.activeLanguage &&
                                    this.props.activeLanguage.code === "en"
                                  }
                                >
                                  <span className="title">
                                    {this.props.translate(
                                      "cart.recommended-for-you"
                                    )}
                                  </span>
                                  <span className="description">
                                    {parse(
                                      this.props
                                        .translate("cart.host-all-domains")
                                        .replace(
                                          "{DOMAINS}",
                                          availableDomains.length
                                        )
                                    )}
                                  </span>
                                </StyledRecommendedPlan>
                              )}
                            <ProductPlan
                              hosting={hosting}
                              onAddToCart={this.onAddToCart}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledLookingForHosting
                    onClick={() => this.onShowWindowsHostings()}
                  >
                    {this.props.translate("cart.looking-for-windows-hosting")}
                  </StyledLookingForHosting>
                </div>
              </div>
            )}
            {!isMobileOnly && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    {web &&
                      web.hostings.length > 0 &&
                      web.hostings.map(hosting => {
                        let colSize = this.calculateHostingColSize(
                          web.hostings.length
                        );

                        return (
                          <div
                            key={`hosting-plan-${hosting.id}`}
                            className={`col-12 col-sm-${colSize} col-md-${colSize} hosting-plan`}
                          >
                            {availableDomains.length === 1 &&
                              hosting.name === "Basic" && (
                                <StyledRecommendedPlan
                                  isMobile={isMobileOnly}
                                  adjustForEn={
                                    this.props.activeLanguage &&
                                    this.props.activeLanguage.code === "en"
                                  }
                                >
                                  <span className="title">
                                    {this.props.translate(
                                      "cart.recommended-for-you"
                                    )}
                                  </span>
                                  <span className="description">
                                    {parse(
                                      this.props.translate(
                                        "cart.host-one-domain"
                                      )
                                    )}
                                  </span>
                                </StyledRecommendedPlan>
                              )}
                            {availableDomains.length > 1 &&
                              availableDomains.length < 6 &&
                              hosting.name === "Regular" && (
                                <StyledRecommendedPlan
                                  isMobile={isMobileOnly}
                                  adjustForEn={
                                    this.props.activeLanguage &&
                                    this.props.activeLanguage.code === "en"
                                  }
                                >
                                  <span className="title">
                                    {this.props.translate(
                                      "cart.recommended-for-you"
                                    )}
                                  </span>
                                  <span className="description">
                                    {parse(
                                      this.props
                                        .translate("cart.host-all-domains")
                                        .replace(
                                          "{DOMAINS}",
                                          availableDomains.length
                                        )
                                    )}
                                  </span>
                                </StyledRecommendedPlan>
                              )}
                            {availableDomains.length >= 6 &&
                              hosting.name === "Power" && (
                                <StyledRecommendedPlan
                                  isMobile={isMobileOnly}
                                  adjustForEn={
                                    this.props.activeLanguage &&
                                    this.props.activeLanguage.code === "en"
                                  }
                                >
                                  <span className="title">
                                    {this.props.translate(
                                      "cart.recommended-for-you"
                                    )}
                                  </span>
                                  <span className="description">
                                    {parse(
                                      this.props
                                        .translate("cart.host-all-domains")
                                        .replace(
                                          "{DOMAINS}",
                                          availableDomains.length
                                        )
                                    )}
                                  </span>
                                </StyledRecommendedPlan>
                              )}
                            <ProductPlan
                              hosting={hosting}
                              onAddToCart={onAddToCart}
                              submitting={submitting}
                              disabled={disabled}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledLookingForHosting
                    onClick={() => this.onShowWindowsHostings()}
                  >
                    {this.props.translate("cart.looking-for-windows-hosting")}
                  </StyledLookingForHosting>
                </div>
              </div>
            )}
          </div>
        )}

        {this.state.hostingsType === "windows" && (
          <div>
            {isMobileOnly && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    {windows &&
                      windows.hostings.length > 0 &&
                      windows.hostings.map(hosting => {
                        return (
                          <div
                            key={`hosting-plan-${hosting.id}`}
                            className={`col-12 col-sm-12 col-md-12 hosting-plan`}
                          >
                            {availableDomains.length === 1 &&
                              hosting.name === "ASPBASIC" && (
                                <StyledRecommendedPlan
                                  isMobile={isMobileOnly}
                                  adjustForEn={
                                    this.props.activeLanguage &&
                                    this.props.activeLanguage.code === "en"
                                  }
                                >
                                  <span className="title">
                                    {this.props.translate(
                                      "cart.recommended-for-you"
                                    )}
                                  </span>
                                  <span className="description">
                                    {parse(
                                      this.props.translate(
                                        "cart.host-one-domain"
                                      )
                                    )}
                                  </span>
                                </StyledRecommendedPlan>
                              )}
                            {availableDomains.length > 1 &&
                              availableDomains.length < 6 &&
                              hosting.name === "ASPREGULAR" && (
                                <StyledRecommendedPlan
                                  isMobile={isMobileOnly}
                                  adjustForEn={
                                    this.props.activeLanguage &&
                                    this.props.activeLanguage.code === "en"
                                  }
                                >
                                  <span className="title">
                                    {this.props.translate(
                                      "cart.recommended-for-you"
                                    )}
                                  </span>
                                  <span className="description">
                                    {parse(
                                      this.props
                                        .translate("cart.host-all-domains")
                                        .replace(
                                          "{DOMAINS}",
                                          availableDomains.length
                                        )
                                    )}
                                  </span>
                                </StyledRecommendedPlan>
                              )}
                            {availableDomains.length >= 6 &&
                              hosting.name === "ASPPOWER" && (
                                <StyledRecommendedPlan
                                  isMobile={isMobileOnly}
                                  adjustForEn={
                                    this.props.activeLanguage &&
                                    this.props.activeLanguage.code === "en"
                                  }
                                >
                                  <span className="title">
                                    {this.props.translate(
                                      "cart.recommended-for-you"
                                    )}
                                  </span>
                                  <span className="description">
                                    {parse(
                                      this.props
                                        .translate("cart.host-all-domains")
                                        .replace(
                                          "{DOMAINS}",
                                          availableDomains.length
                                        )
                                    )}
                                  </span>
                                </StyledRecommendedPlan>
                              )}
                            <ProductPlan
                              hosting={hosting}
                              onAddToCart={this.onAddToCart}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledLookingForHosting
                    onClick={() => this.onShowWebHostings()}
                  >
                    {this.props.translate("cart.back-to-web-hosting")}
                  </StyledLookingForHosting>
                </div>
              </div>
            )}
            {!isMobileOnly && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    {windows &&
                      windows.hostings.length > 0 &&
                      windows.hostings.map(hosting => {
                        let colSize = this.calculateHostingColSize(
                          windows.hostings.length
                        );

                        return (
                          <div
                            key={`hosting-plan-${hosting.id}`}
                            className={`col-12 col-sm-${colSize} col-md-${colSize} hosting-plan`}
                          >
                            {availableDomains.length === 1 &&
                              hosting.name === "ASPBASIC" && (
                                <StyledRecommendedPlan
                                  isMobile={isMobileOnly}
                                  adjustForEn={
                                    this.props.activeLanguage &&
                                    this.props.activeLanguage.code === "en"
                                  }
                                >
                                  <span className="title">
                                    {this.props.translate(
                                      "cart.recommended-for-you"
                                    )}
                                  </span>
                                  <span className="description">
                                    {parse(
                                      this.props.translate(
                                        "cart.host-one-domain"
                                      )
                                    )}
                                  </span>
                                </StyledRecommendedPlan>
                              )}
                            {availableDomains.length > 1 &&
                              availableDomains.length < 6 &&
                              hosting.name === "ASPREGULAR" && (
                                <StyledRecommendedPlan
                                  isMobile={isMobileOnly}
                                  adjustForEn={
                                    this.props.activeLanguage &&
                                    this.props.activeLanguage.code === "en"
                                  }
                                >
                                  <span className="title">
                                    {this.props.translate(
                                      "cart.recommended-for-you"
                                    )}
                                  </span>
                                  <span className="description">
                                    {parse(
                                      this.props
                                        .translate("cart.host-all-domains")
                                        .replace(
                                          "{DOMAINS}",
                                          availableDomains.length
                                        )
                                    )}
                                  </span>
                                </StyledRecommendedPlan>
                              )}
                            {availableDomains.length >= 6 &&
                              hosting.name === "ASPPOWER" && (
                                <StyledRecommendedPlan
                                  isMobile={isMobileOnly}
                                  adjustForEn={
                                    this.props.activeLanguage &&
                                    this.props.activeLanguage.code === "en"
                                  }
                                >
                                  <span className="title">
                                    {this.props.translate(
                                      "cart.recommended-for-you"
                                    )}
                                  </span>
                                  <span className="description">
                                    {parse(
                                      this.props
                                        .translate("cart.host-all-domains")
                                        .replace(
                                          "{DOMAINS}",
                                          availableDomains.length
                                        )
                                    )}
                                  </span>
                                </StyledRecommendedPlan>
                              )}
                            <ProductPlan
                              hosting={hosting}
                              onAddToCart={onAddToCart}
                              submitting={submitting}
                              disabled={disabled}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledLookingForHosting
                    onClick={() => this.onShowWebHostings()}
                  >
                    {this.props.translate("cart.back-to-web-hosting")}
                  </StyledLookingForHosting>
                </div>
              </div>
            )}
          </div>
        )}
      </StyledCartAddHostingToDomainHostingPlans>
    );
  }
}

export default withLocalize(CartAddHostingToDomainHostingPlans);
