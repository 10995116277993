import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { withLocalize } from "react-localize-redux";

import { Form, Field, FormSpy } from "react-final-form";
import InputField from "../../../../../components/InputField";
import Radiobox from "../../../../../components/Radiobox";
import SelectBox from "../../../../../components/SelectBox";
import Avatar from "../../../../../components/Avatar";
import BusinessAvatar from "../../../../../components/BusinessAvatar";
import UserWithEmail from "../../../../../components/UserWithEmail";

import {
  REASON_COPY,
  REASON_NOT_ARRIVED,
  REASON_WRONG_ADDRESS
} from "./SendViaPostOptions";

const StyledReasonOptionsWrapper = styled.div`
  margin-bottom: 30px;

  & .custom-radio {
    margin-bottom: 10px;
  }
`;

const StyledInvoiceAlreadySentInfo = styled.div`
  display: block;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.neutral6};
  margin-bottom: 30px;
`;

const StyledIsAddressCorrect = styled.strong`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.neutralBase};
  margin-bottom: 10px;
`;

const StyledAddressInfo = styled.div`
  display: block;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.38;
  color: ${theme.neutralBase};

  & p {
    margin-bottom: 5px;
  }
`;

class SendViaPostDialog extends React.Component {
  onSelectReason = reason => {
    this.props.onChangeViaPostReason(reason);
  };

  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <StyledInvoiceAlreadySentInfo>
            {this.props.translate("invoices.view.invoice-already-sent")}
          </StyledInvoiceAlreadySentInfo>
          <StyledReasonOptionsWrapper>
            <Radiobox
              name={REASON_COPY}
              value={REASON_COPY}
              onChange={this.onSelectReason}
              checked={this.props.reason === REASON_COPY}
            >
              {this.props.translate("invoices.view.i-need-another-copy")}
            </Radiobox>
            <Radiobox
              name={REASON_NOT_ARRIVED}
              value={REASON_NOT_ARRIVED}
              onChange={this.onSelectReason}
              checked={this.props.reason === REASON_NOT_ARRIVED}
            >
              {this.props.translate("invoices.view.i-didnt-receive-invoice")}
            </Radiobox>
            <Radiobox
              name={REASON_WRONG_ADDRESS}
              value={REASON_WRONG_ADDRESS}
              onChange={this.onSelectReason}
              checked={this.props.reason === REASON_WRONG_ADDRESS}
            >
              {this.props.translate("invoices.view.wrong-address-on-invoice")}
            </Radiobox>
          </StyledReasonOptionsWrapper>
        </div>
        <div className="col-12 col-sm-12 col-md-12">
          {this.props.reason === REASON_NOT_ARRIVED && (
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <StyledIsAddressCorrect>
                  {this.props.translate(
                    "invoices.view.is-this-address-correct"
                  )}
                </StyledIsAddressCorrect>
                <StyledAddressInfo>
                  <p>
                    {this.props.invoice.payer.firstname}{" "}
                    {this.props.invoice.payer.lastname}
                  </p>
                  <p>{this.props.invoice.payer.address}</p>
                  <p>
                    {this.props.invoice.payer.zip}{" "}
                    {this.props.invoice.payer.city}
                  </p>

                  <p>
                    {this.props.invoice.payer.country &&
                    this.props.invoice.payer.country.value
                      ? this.props.invoice.payer.country.value
                      : this.props.invoice.payer.country}
                  </p>
                </StyledAddressInfo>
                <StyledAddressInfo>
                  <p>{this.props.invoice.payer.email}</p>
                  <p>{this.props.invoice.payer.phonenumber}</p>
                </StyledAddressInfo>
              </div>
            </div>
          )}
          {this.props.reason === REASON_WRONG_ADDRESS && (
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <Form
                  initialValues={this.props.invoice.payer || {}}
                  onSubmit={this.props.onSubmit}
                  validate={this.props.validate}
                  render={({ handleSubmit }) => (
                    <form
                      id="send-via-post-dialog-form"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          {this.props.invoice.payer.company &&
                            this.props.invoice.payer.company !== "" && (
                              <div>
                                <BusinessAvatar />{" "}
                                <UserWithEmail
                                  user={this.props.invoice.payer.company}
                                  email={this.props.invoice.payer.email}
                                />
                              </div>
                            )}
                          {!this.props.invoice.payer.company ||
                            (this.props.invoice.payer.company === "" && (
                              <div>
                                <Avatar />{" "}
                                <UserWithEmail
                                  user={`${this.props.invoice.payer.firstname} ${this.props.invoice.payer.lastname}`}
                                  email={this.props.invoice.payer.email}
                                />
                              </div>
                            ))}
                        </div>
                        <div className="col-12 csm12 col-md-6">
                          <Field
                            label={this.props.translate(
                              "domain.domain-contacts.address"
                            )}
                            component={InputField}
                            name="address"
                          />
                        </div>
                        <div className="col-12 csm12 col-md-6">
                          <Field
                            label={this.props.translate(
                              "domain.domain-contacts.city"
                            )}
                            component={InputField}
                            name="city"
                          />
                        </div>
                        <div className="col-12 csm12 col-md-6">
                          <Field
                            label={this.props.translate(
                              "domain.domain-contacts.state-region"
                            )}
                            component={InputField}
                            name="state"
                          />
                        </div>
                        <div className="col-12 csm12 col-md-6">
                          {this.props.countries &&
                            this.props.countries.length > 0 && (
                              <Field
                                label={this.props.translate(
                                  "domain.domain-contacts.country"
                                )}
                                component={SelectBox}
                                options={this.props.countries}
                                isSearchable={true}
                                name="country"
                              />
                            )}
                        </div>
                        <div className="col-12 csm12 col-md-6">
                          <Field
                            label={this.props.translate(
                              "domain.domain-contacts.phone-number"
                            )}
                            component={InputField}
                            name="phonenumber"
                          />
                        </div>
                        <div className="col-12 csm12 col-md-6">
                          <Field
                            label={this.props.translate(
                              "domain.domain-contacts.zip-code"
                            )}
                            component={InputField}
                            name="zip"
                          />
                        </div>
                      </div>
                      <FormSpy
                        subscription={{ submitting: true }}
                        onChange={formstate =>
                          this.props.onSubmitting({ ...formstate })
                        }
                      />
                    </form>
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withLocalize(SendViaPostDialog);
