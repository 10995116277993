export function calculateDomainReducedPrice(
  domainPrice,
  reducePrice,
  currency = null
) {
  if (!reducePrice) {
    return parseFloat(domainPrice);
  }

  if (reducePrice.discountType === "percentage") {
    const finalPrice = domainPrice - (domainPrice / 100) * reducePrice.value;
    if (
      currency &&
      (currency.toLowerCase() === "мкд" || currency.toLowerCase() === "mk")
    ) {
      return parseFloat(Math.floor(finalPrice).toFixed(0));
    }
    return parseFloat(finalPrice.toFixed(2));
  }

  if (reducePrice.discountType === "fixed") {
    const finalPrice = domainPrice - reducePrice.value;
    if (
      currency &&
      (currency.toLowerCase() === "мкд" || currency.toLowerCase() === "mk")
    ) {
      return parseFloat(Math.floor(finalPrice).toFixed(0));
    }
    return parseFloat(finalPrice.toFixed(2));
  }

  return parseFloat(domainPrice);
}
