import React from "react";
import styled from "styled-components";

import withSizes from "react-sizes";
import {
  MobileOnlyView,
  BrowserView,
  TabletView,
  isMobileOnly
} from "react-device-detect";
import isLaptop from "../../../utils/isLaptopScreen";

import withLoading from "../../../components/Loaders/WithLoading";
import FilterLoader from "../../../components/Loaders/FilterLoader";

import InvoicesFilter from "./InvoicesFilter";
import InvoicesList from "./InvoicesList";

const StyledInvoicesWrapper = styled.div`
  margin-bottom: ${props => props.isMobile && "100px"};
`;

class Invoices extends React.Component {
  render() {
    const InvoicesFilterWithLoading = withLoading(InvoicesFilter, FilterLoader);

    return (
      <StyledInvoicesWrapper isMobile={isMobileOnly} className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <MobileOnlyView>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <InvoicesFilterWithLoading
                  isLoading={this.props.isFilterLoading}
                  statusCollection={this.props.statusCollection}
                  paging={this.props.paging}
                  sort={this.props.sort}
                  filters={this.props.filters}
                  payersForFilter={this.props.payersForFilter}
                  filterPayerOptions={this.props.filterPayerOptions}
                  onPayerFilterOptionChange={
                    this.props.onPayerFilterOptionChange
                  }
                  countFiltersApplied={this.props.countFiltersApplied}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12">
                <InvoicesList
                  isLoading={this.props.isLoading}
                  all={this.props.all}
                  paging={this.props.paging}
                  statusCollection={this.props.statusCollection}
                  filters={this.props.filters}
                  sort={this.props.sort}
                  onViewInvoice={this.props.onViewInvoice}
                  onRowSelectionChanged={this.props.onRowSelectionChanged}
                  selectAllRows={this.props.selectAllRows}
                  deselectRows={this.props.deselectRows}
                  selectedRowsCount={this.props.selectedRowsCount}
                  payersForFilter={this.props.payersForFilter}
                  filterPayerOptions={this.props.filterPayerOptions}
                  onPayerFilterOptionChange={
                    this.props.onPayerFilterOptionChange
                  }
                  countFiltersApplied={this.props.countFiltersApplied}
                  massPay={this.props.massPay}
                  payAllUnpaid={this.props.payAllUnpaid}
                  isPerformingMasspay={this.props.isPerformingMasspay}
                />
              </div>
            </div>
          </MobileOnlyView>
          <TabletView>
            <div className="row">
              <div className="col-4 col-sm-4 col-md-4">
                <InvoicesFilterWithLoading
                  isLoading={this.props.isFilterLoading}
                  statusCollection={this.props.statusCollection}
                  paging={this.props.paging}
                  sort={this.props.sort}
                  filters={this.props.filters}
                  payersForFilter={this.props.payersForFilter}
                  filterPayerOptions={this.props.filterPayerOptions}
                  onPayerFilterOptionChange={
                    this.props.onPayerFilterOptionChange
                  }
                  countFiltersApplied={this.props.countFiltersApplied}
                />
              </div>
              <div className="col-8 col-sm-8 col-md-8">
                <InvoicesList
                  isLoading={this.props.isLoading}
                  all={this.props.all}
                  paging={this.props.paging}
                  statusCollection={this.props.statusCollection}
                  filters={this.props.filters}
                  sort={this.props.sort}
                  onViewInvoice={this.props.onViewInvoice}
                  onRowSelectionChanged={this.props.onRowSelectionChanged}
                  selectAllRows={this.props.selectAllRows}
                  deselectRows={this.props.deselectRows}
                  selectedRowsCount={this.props.selectedRowsCount}
                  payersForFilter={this.props.payersForFilter}
                  filterPayerOptions={this.props.filterPayerOptions}
                  onPayerFilterOptionChange={
                    this.props.onPayerFilterOptionChange
                  }
                  countFiltersApplied={this.props.countFiltersApplied}
                  massPay={this.props.massPay}
                  payAllUnpaid={this.props.payAllUnpaid}
                  isPerformingMasspay={this.props.isPerformingMasspay}
                />
              </div>
            </div>
          </TabletView>
          <BrowserView>
            {this.props.isLaptop && (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <InvoicesList
                    isLoading={this.props.isLoading}
                    isLaptop={this.props.isLaptop}
                    all={this.props.all}
                    paging={this.props.paging}
                    sort={this.props.sort}
                    statusCollection={this.props.statusCollection}
                    filters={this.props.filters}
                    onViewInvoice={this.props.onViewInvoice}
                    onRowSelectionChanged={this.props.onRowSelectionChanged}
                    selectAllRows={this.props.selectAllRows}
                    deselectRows={this.props.deselectRows}
                    selectedRowsCount={this.props.selectedRowsCount}
                    payersForFilter={this.props.payersForFilter}
                    filterPayerOptions={this.props.filterPayerOptions}
                    onPayerFilterOptionChange={
                      this.props.onPayerFilterOptionChange
                    }
                    countFiltersApplied={this.props.countFiltersApplied}
                    massPay={this.props.massPay}
                    payAllUnpaid={this.props.payAllUnpaid}
                    isPerformingMasspay={this.props.isPerformingMasspay}
                  />
                </div>
              </div>
            )}

            {!this.props.isLaptop && (
              <div className="row">
                <div className="col-3 col-sm-3 col-md-3">
                  <InvoicesFilterWithLoading
                    isLoading={this.props.isFilterLoading}
                    statusCollection={this.props.statusCollection}
                    paging={this.props.paging}
                    sort={this.props.sort}
                    filters={this.props.filters}
                    payersForFilter={this.props.payersForFilter}
                    filterPayerOptions={this.props.filterPayerOptions}
                    onPayerFilterOptionChange={
                      this.props.onPayerFilterOptionChange
                    }
                    countFiltersApplied={this.props.countFiltersApplied}
                  />
                </div>
                <div className="col-9 col-sm-9 col-md-9">
                  <InvoicesList
                    isLoading={this.props.isLoading}
                    all={this.props.all}
                    paging={this.props.paging}
                    statusCollection={this.props.statusCollection}
                    filters={this.props.filters}
                    sort={this.props.sort}
                    onViewInvoice={this.props.onViewInvoice}
                    onRowSelectionChanged={this.props.onRowSelectionChanged}
                    selectAllRows={this.props.selectAllRows}
                    deselectRows={this.props.deselectRows}
                    selectedRowsCount={this.props.selectedRowsCount}
                    payersForFilter={this.props.payersForFilter}
                    filterPayerOptions={this.props.filterPayerOptions}
                    onPayerFilterOptionChange={
                      this.props.onPayerFilterOptionChange
                    }
                    countFiltersApplied={this.props.countFiltersApplied}
                    massPay={this.props.massPay}
                    payAllUnpaid={this.props.payAllUnpaid}
                    isPerformingMasspay={this.props.isPerformingMasspay}
                  />
                </div>
              </div>
            )}
          </BrowserView>
        </div>
      </StyledInvoicesWrapper>
    );
  }
}

export default withSizes(isLaptop)(Invoices);
