import React from "react";

import Loader from "../Loader";

const AddonsLoader = () => (
  <Loader width="100" height="120">
    <rect x="2" y="1" width="3" height="3" rx="1" />
    <rect x="7" y="1.2" width="10" height="1.5" rx="0.5" />
    <rect x="7" y="3.8" width="20" height="5" rx="1" />
    <rect x="7" y="10" width="20" height="2.5" rx="0.5" />

    <rect x="2" y="14" width="3" height="3" rx="1" />
    <rect x="7" y="14.2" width="10" height="1.5" rx="0.5" />
    <rect x="7" y="16.8" width="20" height="5" rx="1" />
    <rect x="7" y="23" width="20" height="2.5" rx="0.5" />
  </Loader>
);

export default AddonsLoader;
