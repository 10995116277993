import * as NA from "../actions/notificationsActions";

import handleReducerActions from "../../../core/handleReducerActions";

import { APP_STATE_COMPLETE_RESET } from "../../AppState/actions/appStateActions";

export const NOTIFICATION_PRODUCT_SUBSCRIPTION = "product";
export const NOTIFICATION_DOMAIN_SUBSCRIPTION = "domain";
export const NOTIFICATION_BILLING_SUBSCRIPTION = "billing";
export const NOTIFICATION_SUPPORT_SUBSCRIPTION = "support";
export const NOTIFICATION_MARKETING_SUBSCRIPTION = "marketing";

const initialState = {
  items: [],
  isFetchingNotifications: false,
  notificationsConfig: {
    [NOTIFICATION_PRODUCT_SUBSCRIPTION]: true,
    [NOTIFICATION_DOMAIN_SUBSCRIPTION]: true,
    [NOTIFICATION_BILLING_SUBSCRIPTION]: true,
    [NOTIFICATION_SUPPORT_SUBSCRIPTION]: true,
    [NOTIFICATION_MARKETING_SUBSCRIPTION]: true
  },
  updatingNotificationsConfig: {
    [NOTIFICATION_PRODUCT_SUBSCRIPTION]: false,
    [NOTIFICATION_DOMAIN_SUBSCRIPTION]: false,
    [NOTIFICATION_BILLING_SUBSCRIPTION]: false,
    [NOTIFICATION_SUPPORT_SUBSCRIPTION]: false,
    [NOTIFICATION_MARKETING_SUBSCRIPTION]: false
  },
  isFetchingNotificationsConfig: false
};

const notificationsStateHandlers = {
  [NA.NOTIFICATIONS_REQUEST]: notificationsRequest,
  [NA.NOTIFICATIONS_RECEIVE]: notificationsReceive,
  [NA.NOTIFICATIONS_ENABLE_REQUEST]: notificationsEnableRequest,
  [NA.NOTIFICATIONS_ENABLE_RECEIVE]: notificationsEnableReceive,
  [NA.NOTIFICATIONS_MARK_AS_READ]: notificationsMarkAsRead,
  [NA.NOTIFICATIONS_CONFIG_REQUEST]: notificationsConfigRequest,
  [NA.NOTIFICATIONS_CONFIG_RECEIVE]: notificationsConfigReceive,
  [APP_STATE_COMPLETE_RESET]: resetNotificationsState
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(notificationsStateHandlers, state, action);
}

function notificationsRequest(state, action) {
  return { ...state, isFetchingNotifications: true };
}

function notificationsReceive(state, action) {
  return {
    ...state,
    items: [...action.payload],
    isFetchingNotifications: false
  };
}

function notificationsEnableRequest(state, action) {
  return {
    ...state,
    updatingNotificationsConfig: {
      ...state.updatingNotificationsConfig,
      [action.payload.notification]: true
    }
  };
}

function notificationsEnableReceive(state, action) {
  return {
    ...state,
    notificationsConfig: {
      ...state.notificationsConfig,
      [action.payload.notification]: action.payload.enabled
    },
    updatingNotificationsConfig: {
      ...state.updatingNotificationsConfig,
      [action.payload.notification]: false
    }
  };
}

function notificationsMarkAsRead(state, action) {
  return {
    ...state,
    items: state.items.filter(
      notification => notification.id !== action.payload.id
    )
  };
}

function notificationsConfigRequest(state) {
  return {
    ...state,
    isFetchingNotificationsConfig: true
  };
}

function notificationsConfigReceive(state, action) {
  return {
    ...state,
    notificationsConfig: {
      ...action.payload
    },
    isFetchingNotificationsConfig: false
  };
}

function resetNotificationsState(state) {
  return { ...initialState };
}

// this is a selector
export function getNotifications(state) {
  return state.notifications.items;
}

export function isFetchingNotifications(state) {
  return state.notifications.isFetchingNotifications;
}

export function marketingNotificationsEnabled(state) {
  return state.notifications.notificationsConfig[
    NOTIFICATION_MARKETING_SUBSCRIPTION
  ];
}

export function isFetchingNotificationsConfig(state) {
  return state.notifications.isFetchingNotificationsConfig;
}

export function isUpdatingNotificationConfig(state, notification) {
  return state.notifications.updatingNotificationsConfig[notification];
}
