import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { isMobileOnly } from "react-device-detect";

import loading from "../../../assets/images/logomark-white.svg";
import loadingBlue from "../../../assets/images/logomark-blue.svg";

const ButtonSizes = {
  s: "32px",
  small: "32px",
  m: "40px",
  medium: "40px",
  l: "50px",
  large: "50px"
};

const LoadingTopPosition = {
  s: "0px",
  small: "0px",
  m: "4px",
  medium: "4px",
  l: "9px",
  large: "9px"
};

const StyledButton = styled.button`
  height: ${props => (props.size ? ButtonSizes[props.size] : ButtonSizes["m"])}
  background-color: ${theme.white};
  color: ${theme.blueBase};
  position:relative;
  padding: ${isMobileOnly ? "0 15px" : "0 20px"};
  border: solid 1px ${theme.neutral3}
  border-radius: 3px;
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  letter-spacing: 0.2px;
  // margin-right: 15px;
  outline: none;
    &:hover {
      background-color: ${theme.neutral1};
      cursor: pointer;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px ${theme.blue3};
    }

    &:active {
      border: 1px solid ${theme.neutral3};
      background-color: ${theme.neutral1};
      color: ${theme.blue5};
    }

    &:disabled {
      background-color: ${theme.neutral2};
      color: ${theme.white};
      border: 1px solid transparent;
      cursor: not-allowed;
    }


    &:only-child {
      margin-right:0;
    }

  &:disabled.submitting{
    color: transparent;
  }
  &:disabled.submitting span[class^="icon-"] {
    visibility: hidden;
  }

 & .loading-wrapper {
    position: absolute;
    top: 3px;
    left: 3px;
    right: 3px;
    bottom: 3px;
    text-align: center;
    z-index:1;
    background-color: transparent;
  }


  & .btn-loading-img {
    position: relative;
    top: ${props =>
      props.size ? LoadingTopPosition[props.size] : LoadingTopPosition["m"]}

    animation: btn-loading-img 1.2s linear infinite;
    width: 16px;
    height: 16px;
  }

  & > span[class^="icon-"] {
    left: -7px;
  }


  @keyframes  btn-loading-img {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Button = ({ children, ...rest }) => {
  return (
    <StyledButton
      {...rest}
      className={`${rest.className} ${rest.disabled &&
        "disabled"} ${rest.disabled && rest.submitting && "submitting"}`}
    >
      {children}
      {rest.disabled && rest.submitting && (
        <div className="loading-wrapper">
          <img
            src={
              rest.loadingIconColor && rest.loadingIconColor === "blue"
                ? loadingBlue
                : loading
            }
            className="btn-loading-img"
            alt=""
          />
        </div>
      )}
    </StyledButton>
  );
};

Button.defaultProps = {
  className: "btn"
};

export default Button;
