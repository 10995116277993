import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

import ToggleBoxLoader from "../Loaders/ToggleBoxLoader";

const StyledTogglebox = styled.div`
  background-color: ${theme.neutral4};
  width: 60px;
  height: 32px;
  padding: 4px;
  position: relative;
  border-radius: 20px;
  z-index: 1;
  cursor: pointer;

  &.toggled {
    background-color: ${theme.blueBase};
  }

  &.disabled,
  &.toggled.disabled {
    background-color: ${theme.neutral3};
    cursor: not-allowed;
  }
`;

const StyledToggle = styled.span`
  background-color: ${theme.white};
  display: inline-block;
  position: absolute;
  right: ${props => (props.toggled ? "4px" : "30px")};
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: left 200ms linear, right 200ms linear;
`;

const StyledInputIcon = styled.span`
  display: inline-block;
  position: absolute;
  left: 4px;
  top: 16px;
  margin: 0;
  padding: 0;
  font-size: 24px;
  color: ${theme.white};
`;

StyledTogglebox.defaultProps = {
  className: ""
};

class Togglebox extends React.Component {
  onToggle = e => {
    e.preventDefault();
    if (this.props.disabled) return;
    this.props.onToggle();
  };

  render() {
    const toggledClass = this.props.toggled ? "toggled" : "";
    const disabledClass = this.props.disabled ? "disabled" : "";

    return (
      <StyledTogglebox
        {...this.props}
        onClick={e => this.onToggle(e)}
        className={`togglebox-control ${toggledClass} ${disabledClass} ${
          this.props.className
        }`}
      >
        {this.props.submitting &&
          this.props.alignLoader === "left" && (
            <ToggleBoxLoader alignLoader={this.props.alignLoader} />
          )}

        <StyledInputIcon className={`input-icon icon-input-checkbox`} />
        <StyledToggle toggled={this.props.toggled} />

        {this.props.submitting &&
          this.props.alignLoader === "right" && (
            <ToggleBoxLoader alignLoader={this.props.alignLoader} />
          )}
      </StyledTogglebox>
    );
  }
}

Togglebox.defaultProps = {
  onToggle: () => {},
  alignLoader: "left"
};

export default Togglebox;
