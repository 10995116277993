import ProgressBar from "../components/ProgressBars/ProgressBar";
//import ProgressBarInfo from "../components/ProgressBars/ProgressBarInfo";
import ProgressBarDanger from "../components/ProgressBars/ProgressBarDanger";
import ProgressBarWarning from "../components/ProgressBars/ProgressBarWarning";
import ProgressBarSuccess from "../components/ProgressBars/ProgressBarSuccess";

const progressBars = {
  active: ProgressBarSuccess,
  warning: ProgressBarWarning,
  danger: ProgressBarDanger
};

export default function getProgressBarForStatus(status) {
  return progressBars[status] ? progressBars[status] : ProgressBar;
}
