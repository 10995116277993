import React from "react";

import ContactsList from "./ContactsList";

const dummyData = [
  {
    id: 123,
    firstName: "Sanja",
    lastName: "Zakovska",
    status: "active",
    assigned: "02 Mar 2018",
    roles: [{ name: "developer" }, { name: "payer" }]
  }
];

class Contacts extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <ContactsList contacts={dummyData} />
        </div>
      </div>
    );
  }
}

export default Contacts;
