import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes/index";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import { isMobileOnly, isTablet } from "react-device-detect";

import graphicsCart from "../../../assets/images/graphic-cart.svg";

import PrimaryButton from "../../../components/Buttons/PrimaryButton";

const StyledCartEmptyState = styled.div`
  padding: 50px 0 25px;
`;

const StyledCartEmptyStateContainer = styled.div`
  background-color: ${theme.white};
  display: inline-block;
  padding: ${props => (props.isMobile ? "70px 15px" : "100px 80px")};
  max-width: 730px;

  & img {
    margin-bottom: 30px;
  }

  & .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    text-align: center;
    margin-top: 5px;
  }

  & .empty-cart-text {
    font-size: 16px;
    line-height: 1.5;
    color: ${theme.neutral6};
    text-align: center;
    margin-bottom: 50px;
  }
  & button {
    width: 100%;
  }

  & button:last-child {
    @media (max-width: 425px) {
      margin-top: 7px;
    }
  }
`;

const CartEmptyState = ({
  shopDomainsRoute,
  shopHostingRoute,
  shopServersRoute,
  ...rest
}) => (
  <Translate>
    {({ translate }) => (
      <StyledCartEmptyState isMobile={isMobileOnly || isTablet}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 text-center justify-content-center">
            <StyledCartEmptyStateContainer isMobile={isMobileOnly}>
              <img src={graphicsCart} alt="" />
              <h4 className="title">{translate("cart.your-cart-is-empty")}</h4>
              <p className="empty-cart-text">
                {translate("cart.empty-cart-text")}
              </p>
              <div className="row">
                <div
                  className={
                    isMobileOnly
                      ? "col-12 col-sm-4 col-md-4 mb-1  justify-content-center"
                      : "col-12 col-sm-4 col-md-4 text-right justify-content-center"
                  }
                >
                  <Link to={shopDomainsRoute}>
                    <PrimaryButton>
                      {translate("cart.shop-domains")}
                    </PrimaryButton>
                  </Link>
                </div>
                <div
                  className={
                    isMobileOnly
                      ? "col-12 col-sm-4 col-md-4 justify-content-center"
                      : "col-12 col-sm-4 col-md-4 text-center justify-content-center"
                  }
                >
                  <Link to={shopHostingRoute}>
                    <PrimaryButton>
                      {translate("cart.shop-hosting-plan")}
                    </PrimaryButton>
                  </Link>
                </div>
                <div
                  className={
                    isMobileOnly
                      ? "col-12 col-sm-4 col-md-4 justify-content-center"
                      : "col-12 col-sm-4 col-md-4 text-left justify-content-center"
                  }
                >
                  <Link to={shopServersRoute}>
                    <PrimaryButton>
                      {translate("cart.shop-servers")}
                    </PrimaryButton>
                  </Link>
                </div>
              </div>
            </StyledCartEmptyStateContainer>
          </div>
        </div>
      </StyledCartEmptyState>
    )}
  </Translate>
);

export default CartEmptyState;
