import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import { withLocalize } from "react-localize-redux";

import InputField from "../../../../../../../components/InputField";

const StyledHostnameValue = styled.div`
  display: inline-block;
  width: ${props => (props.isMobile ? "100%" : "250px")};
  margin-right: 15px;
  vertical-align: top;
`;

const HostnameValue = props => (
  <StyledHostnameValue isMobile={isMobileOnly}>
    <InputField
      label={props.translate("domain.zone-file.hostname-value")}
      {...props}
    />
  </StyledHostnameValue>
);

export default withLocalize(HostnameValue);
