import axios from "axios";

import {
  logout,
  loginFailed
} from "../features/Authentication/actions/authActions";
import { ROUTE_LOGIN } from "../routes/routes";
import { AUTH_API_ENDPOINT } from "../services/endpoints";

export default function(dispatch) {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      // redirect to login if api returns that we're not authorized (token expired and such)
      // unless we are getting a response from the login api
      // we want to pass that response back to the form to show a message
      if (
        error.response &&
        error.response.status === 401 &&
        error.response.request.responseURL !== AUTH_API_ENDPOINT
      ) {
        dispatch(loginFailed());
        //history.push(ROUTE_LOGIN);
      } else {
        return Promise.reject(error);
      }
    }
  );
}
