import StatusText from "../components/StatusTexts/StatusText";
//import StatusTextInfo from "../components/StatusTexts/StatusTextInfo";
import StatusTextDanger from "../components/StatusTexts/StatusTextDanger";
import StatusTextWarning from "../components/StatusTexts/StatusTextWarning";
import StatusTextSuccess from "../components/StatusTexts/StatusTextSuccess";
import StatusTextInfo from "../components/StatusTexts/StatusTextnfo";

const text = {
  active: StatusTextSuccess,
  warning: StatusTextWarning,
  danger: StatusTextDanger,
  available: StatusTextSuccess,
  unavailable: StatusTextDanger
};

export default function getStatusTextForStatus(status) {
  return text[status] ? text[status] : StatusText;
}
