import React from "react";
import styled from "styled-components";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import isLaptop from "../../../../../../../utils/isLaptopScreen";
import { withSizes } from "react-sizes";
import { withLocalize } from "react-localize-redux";

import InputField from "../../../../../../../components/InputField";

const StyledTextInformation = styled.div`
  display: inline-block;
  width: ${props => {
    if (props.isMobile) return "100%";
    if (props.isTablet) return "350px";
    if (props.isLaptop) return "350px;";
    if (!props.isLaptop && props.isBrowser) return "350px";
  }};
   margin-right: ${props => {
     if (props.isLaptop) return "20px";
     return "40px";
   }}
  vertical-align: top;
  margin-left: ${props => {
    if (props.isTablet) return "190px";
    return 0;
  }};
`;

const TextInformation = props => (
  <StyledTextInformation
    isMobile={isMobileOnly}
    isTablet={isTablet}
    isLaptop={props.isLaptop}
    isBrowser={isBrowser}
  >
    <InputField
      label={props.translate("domain.zone-file.text-information")}
      {...props}
    />
  </StyledTextInformation>
);

export default withLocalize(withSizes(isLaptop)(TextInformation));
