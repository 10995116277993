import * as serversService from "../../../services/serversService.js";
import * as productsService from "../../../services/productsService";

export const SERVERS_FEATURE = "features/Servers";
export const SERVERS_REQUEST = `${SERVERS_FEATURE}/SERVERS_REQUEST`;
export const SERVERS_RECEIVE = `${SERVERS_FEATURE}/SERVERS_RECEIVE`;

export function fetchServers() {
  return dispatch => {
    dispatch(requestServers());
    return serversService
      .allDedicatedServers()
      .then(
        dedicatedServers => {
          return serversService.allCloudServers().then(cloudServers => {
            const dedicated = dedicatedServers.data.data.servers_grouped;
            const cloud = cloudServers.data.data.servers_grouped;
            const allServers = dedicated.concat(cloud);
            dispatch(receiveServers(allServers));
            return allServers;
          });
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchServerUpgrades(server) {
  return dispatch => {
    return serversService.fetchServerUpgrades(server).then(result => {
      return result;
    });
  };
}

export function upgradeServer(currentServer, upgradeServer) {
  return dispatch => {
    return serversService
      .upgradeServer(currentServer, upgradeServer)
      .then(result => {
        const { error } = result.data;
        const { messages, invoice } = result.data.data;
        return { error, messages, invoice };
      })
      .catch(err => {
        const { error } = err.response.data;
        const { messages } = err.response.data.data;
        return { error, messages };
      });
  };
}

export function requestRenewServer(server) {
  return dispatch => {
    return serversService
      .requestRenewServer(server)
      .then(
        result => {
          const { error } = result.data;
          const { messages, invoice } = result.data.data;

          return { error, messages, invoice };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function cancelServer(server, period) {
  return dispatch => {
    return serversService
      .cancelServer(server, period)
      .then(result => {
        if (result.data.data.server) {
          return true;
        }
        return false;
      })
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

function requestServers() {
  return {
    type: SERVERS_REQUEST
  };
}

function receiveServers(servers) {
  return {
    type: SERVERS_RECEIVE,
    payload: servers
  };
}
