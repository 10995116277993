import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Flag from "../Flag";

const StyledFlagNormalWarning = styled.div`
  & .icon:not(.icon-close) {
    color: ${theme.yellowBase};
  }
`;

const FlagNormalWarning = ({ children, ...rest }) => (
  <StyledFlagNormalWarning>
    <Flag icon={rest.icon || "warning"} {...rest}>
      {children}
    </Flag>
  </StyledFlagNormalWarning>
);

FlagNormalWarning.defaultProps = {
  onDismiss: () => {}
};

export default FlagNormalWarning;
