import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Container from "../Container";

const ContainerTooltip = styled(Container)`
  background-color: ${theme.neutralBase};
  color: ${theme.white};
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.43;
  letter-spacing: normal;
`;

export default ContainerTooltip;
