import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { addCustomerDnsZoneRecord } from "../../../../Customer/actions/customerActions";

import displayToastMessageForResponse from "../../../../../utils/displayToastMessageForResponse";

import Container from "../../../../../components/Containers/Container";

import AAAARecordForm from "../Forms/AAAARecordForm";
import ARecordForm from "../Forms/ARecordForm";
import CNAMERecordForm from "../Forms/CNAMERecordForm";
import MXRecordForm from "../Forms/MXRecordForm";
import PTRRecordForm from "../Forms/PTRRecordForm";
import SRVRecordForm from "../Forms/SRVRecordForm";
import TXTRecordForm from "../Forms/TXTRecordForm";
import CAARecordForm from "../Forms/CAARecordForm";

import { zoneFileDefaultValues } from "../ZoneFileDefaultValues";

const StyledAddDnsRecordFormWrapper = styled.div`
  margin-bottom: 30px;
`;
const StyledAddDnsRecordContainer = styled(Container)`
  padding: 30px;
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  margin-bottom: 40px;
`;

const recordTypes = {
  A: ARecordForm,
  AAAA: AAAARecordForm,
  CNAME: CNAMERecordForm,
  MX: MXRecordForm,
  PTR: PTRRecordForm,
  TXT: TXTRecordForm,
  SRV: SRVRecordForm,
  CAA: CAARecordForm
};

class AddDnsRecordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "A"
    };
  }

  onSubmit = values => {
    return this.props
      .addCustomerDnsZoneRecord(this.props.domain, {
        ...values,
        type: this.state.type
      })
      .then(
        result => {
          displayToastMessageForResponse(
            this.props.translate("domain.zone-file.title"),
            result
          );
        },
        err => {
          displayToastMessageForResponse(
            this.props.translate("domain.zone-file.title"),
            err
          );
        }
      );
  };

  onChangeType = type => {
    if (!type || type === "" || type.length === 0) {
      this.setState({
        type: "A"
      });
    } else {
      this.setState({
        type
      });
    }
  };

  render() {
    const RecordComponent = recordTypes[this.state.type];
    return (
      <StyledAddDnsRecordFormWrapper className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <StyledAddDnsRecordContainer>
            <StyledTitle>
              {this.props.translate("domain.zone-file.add-new-dns-record")}
            </StyledTitle>
            <RecordComponent
              onSubmit={this.onSubmit}
              onChange={this.onChangeType}
              formType={this.state.type}
              initialValues={{
                ttl: zoneFileDefaultValues(this.state.type),
                name:
                  this.state.type === "MX" ? this.props.domain.domain_name : ""
              }}
            />
          </StyledAddDnsRecordContainer>
        </div>
      </StyledAddDnsRecordFormWrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addCustomerDnsZoneRecord }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(withLocalize(AddDnsRecordForm));
