import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Container from "../Container";

const ContainerInlineDialog = styled(Container)`
  box-shadow: 0 3px 5px 0 ${theme.neutral3};
`;

export default ContainerInlineDialog;
