import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { withLocalize } from "react-localize-redux";

import Modal from "../../../components/Modal";
import ActionModal from "../../../components/ActionModal";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import PrimaryWarningButton from "../../../components/Buttons/PrimaryWarningButton";

import AppsList from "./AppsList";
import NewAppForm from "./NewAppForm";
import NewAppOneTimeDetails from "./NewAppOneTimeDetails";
import NoApps from "./NoApps";
import AppDetails from "./AppDetails";

import { validateRequired } from "../../../common/validationRules";

import displayToasMessageForResponse from "../../../utils/displayToastMessageForResponse";

import {
  fetchAllApps,
  selectApp,
  createApp,
  updateApp,
  deleteApp,
  fetchAllScopes,
  toggleAppScope
} from "./actions/appsActions";
import {
  getAllApps,
  isFetchingApps,
  getSelectedApp,
  getAllScopes,
  isFetchingScopes,
  isDeletingApp
} from "./reducers/appsReducer";

class Apps extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCreateAppModal: false,
      newAppClientId: "",
      newAppClientSecret: "",
      newAppClient: null,
      newAppCreated: false,
      submitting: false,
      invalid: false,
      showDeleteAppModal: false,
      appToDelete: null
    };
  }

  componentDidMount() {
    this.props.fetchAllScopes();
    this.props.fetchAllApps();
  }

  onShowCreateAppModal = () => {
    this.setState({
      showCreateAppModal: true
    });
  };

  onHideCreateAppModal = () => {
    this.setState({
      showCreateAppModal: false,
      newAppClientId: "",
      newAppClientSecret: "",
      newAppClient: null,
      newAppCreated: false
    });
  };

  validateAppName = values => {
    return {
      name: validateRequired(values.name)
    };
  };
  onCreateNewApp = values => {
    return this.props.createApp(values.name).then(result => {
      const { client_id, client_secret, client } = result;
      this.setState({
        newAppClientId: client_id,
        newAppClientSecret: client_secret,
        newAppClient: client,
        newAppCreated: true
      });
    });
  };

  onTriggerSubmit = () => {
    document
      .getElementById("new-app-form")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  onSubmitting = ({ submitting, invalid }) => {
    this.setState({
      submitting,
      invalid
    });
  };

  onSelectApp = app => {
    this.props.selectApp(app);
  };

  onEnableApp = app => {
    return this.props.updateApp(app, { is_active: 1 });
  };

  onDisableApp = app => {
    return this.props.updateApp(app, { is_active: 0 });
  };

  onDeleteApp = () => {
    return this.props.deleteApp(this.state.appToDelete).then(result => {
      displayToasMessageForResponse(
        this.props.translate("my-account.apps.title"),
        result
      );
      this.onHideDeleteAppModal();
    });
  };

  onShowDeleteAppModal = app => {
    this.setState({
      appToDelete: app,
      showDeleteAppModal: true
    });
  };

  onHideDeleteAppModal = () => {
    this.setState({
      appToDelete: null,
      showDeleteAppModal: false
    });
  };

  onToggleAppScope = (app, scope) => {
    return this.props.toggleAppScope(app, scope);
  };

  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-4">
          <AppsList
            apps={this.props.apps}
            isFetchingApps={this.props.isFetchingApps}
            isFetchingScopes={this.props.isFetchingScopes}
            onShowCreateAppModal={this.onShowCreateAppModal}
            onSelectApp={this.onSelectApp}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-8">
          {this.props.apps &&
            this.props.apps.length === 0 &&
            !this.props.selectedApp &&
            (!this.props.isFetchingApps || !this.props.isFetchingScopes) && (
              <NoApps onShowCreateAppModal={this.onShowCreateAppModal} />
            )}
          {this.props.selectedApp && (
            <AppDetails
              app={this.props.selectedApp}
              scopes={this.props.scopes}
              onEnableApp={this.onEnableApp}
              onDisableApp={this.onDisableApp}
              onToggleAppScope={this.onToggleAppScope}
              onDeleteApp={this.onShowDeleteAppModal}
              isDeletingApp={this.props.isDeletingApp}
            />
          )}
        </div>

        {this.state.showCreateAppModal && (
          <Modal
            title={this.props.translate("my-account.apps.create-app")}
            onCloseModal={this.onHideCreateAppModal}
            body={() => (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  {!this.state.newAppCreated && (
                    <NewAppForm
                      onSubmit={this.onCreateNewApp}
                      validate={this.validateAppName}
                      onSubmitting={this.onSubmitting}
                    />
                  )}
                  {this.state.newAppCreated && (
                    <NewAppOneTimeDetails
                      clientId={this.state.newAppClientId}
                      clientSecret={this.state.newAppClientSecret}
                      client={this.state.newAppClient}
                    />
                  )}
                </div>
              </div>
            )}
            footer={() => (
              <React.Fragment>
                {!this.state.newAppCreated && (
                  <SecondaryButton onClick={() => this.onHideCreateAppModal()}>
                    {this.props.translate("general.cancel")}
                  </SecondaryButton>
                )}
                {!this.state.newAppCreated && (
                  <PrimaryButton
                    disabled={this.state.invalid || this.state.submitting}
                    submitting={this.state.submitting}
                    onClick={() => this.onTriggerSubmit()}
                  >
                    {this.props.translate("my-account.apps.create-app")}
                  </PrimaryButton>
                )}
                {this.state.newAppCreated && (
                  <SecondaryButton onClick={() => this.onHideCreateAppModal()}>
                    {this.props.translate("general.close")}
                  </SecondaryButton>
                )}
              </React.Fragment>
            )}
          />
        )}

        {this.state.showDeleteAppModal && (
          <ActionModal
            title={this.props.translate("my-account.apps.delete-app-title")}
            onCloseActionModal={this.onHideDeleteAppModal}
            body={() => (
              <p>
                {this.props.translate("my-account.apps.delete-app-description")}
              </p>
            )}
            footer={() => (
              <React.Fragment>
                <SecondaryButton
                  disabled={this.state.isDeletingApp}
                  onClick={() => this.onHideDeleteAppModal()}
                >
                  {this.props.translate("general.cancel")}
                </SecondaryButton>
                <PrimaryWarningButton
                  disabled={this.state.isDeletingApp}
                  submitng={this.state.isDeletingApp}
                  onClick={() => this.onDeleteApp()}
                >
                  {this.props.translate("general.delete")}
                </PrimaryWarningButton>
              </React.Fragment>
            )}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    apps: getAllApps(state),
    isFetchingApps: isFetchingApps(state),
    selectedApp: getSelectedApp(state),
    scopes: getAllScopes(state),
    isFetchingScopes: isFetchingScopes(state),
    isDeletingApp: isDeletingApp(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchAllApps,
      selectApp,
      createApp,
      updateApp,
      deleteApp,
      fetchAllScopes,
      toggleAppScope
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Apps));
