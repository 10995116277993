import * as securityService from "../../../services/securityService.js";

export const SECURITY_FEATURE = "features/Security";
export const SECURITY_QUESTIONS_FETCH_REQUEST = `${SECURITY_FEATURE}/SECURITY_QUESTIONS_FETCH_REQUEST`;
export const SECURITY_QUESTIONS_FETCH_RECEIVE = `${SECURITY_FEATURE}/SECURITY_QUESTIONS_FETCH_RECEIVE`;

export const SECURITY_QUESTION_UPDATE_REQUEST = `${SECURITY_FEATURE}/SECURITY_QUESTION_UPDATE_REQUEST`;
export const SECURITY_QUESTION_UPDATE_RECEIVE = `${SECURITY_FEATURE}/SECURITY_QUESTION_UPDATE_RECEIVE`;

export const SECURITY_PASSWORD_UPDATE_REQUEST = `${SECURITY_FEATURE}/SECURITY_PASSWORD_UPDATE_REQUEST`;
export const SECURITY_PASSWORD_UPDATE_RECEIVE = `${SECURITY_FEATURE}/SECURITY_PASSWORD_UPDATE_RECEIVE`;

export function fetchSecurityQuestions() {
  return dispatch => {
    dispatch(requestSecurityQuestions());
    return securityService
      .fetchSecurityQuestions()
      .then(
        result => {
          const { questions } = result.data.data;
          dispatch(receiveSecurityQuestions(questions));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function updateSecurityQuestion(question, answer) {
  return dispatch => {
    dispatch(requestSecurityQuestionUpdate());
    return securityService
      .updateSecurityQuestion(question, answer)
      .then(
        result => {
          const { error } = result.data;
          const { messages } = result.data.data;
          dispatch(receiveSecurityQuestionUpdate());

          return { error, messages };
        },
        error => {
          const { messages } = error.response.data.data;
          dispatch(receiveSecurityQuestionUpdate());
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(receiveSecurityQuestionUpdate());
        return { error: true, messages: [error.message] };
      });
  };
}

export function changeCustomerPassword(
  oldPassword,
  newPassword,
  confirmNewPassword
) {
  return dispatch => {
    dispatch(requestPasswordUpdate());
    return securityService
      .changeCustomerPassword(oldPassword, newPassword, confirmNewPassword)
      .then(
        result => {
          const { error } = result.data;
          const { messages } = result.data.data;

          dispatch(receivePasswordUpdate());

          return { error, messages };
        },
        error => {
          const { messages } = error.response.data.data;
          dispatch(receivePasswordUpdate());
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(receivePasswordUpdate());
        return { error: true, messages: [error.message] };
      });
  };
}

function requestSecurityQuestions() {
  return {
    type: SECURITY_QUESTIONS_FETCH_REQUEST
  };
}

function receiveSecurityQuestions(questions) {
  return {
    type: SECURITY_QUESTIONS_FETCH_RECEIVE,
    payload: questions
  };
}

function requestSecurityQuestionUpdate() {
  return {
    type: SECURITY_QUESTION_UPDATE_REQUEST
  };
}

function receiveSecurityQuestionUpdate() {
  return {
    type: SECURITY_QUESTION_UPDATE_RECEIVE
  };
}

function requestPasswordUpdate() {
  return {
    type: SECURITY_PASSWORD_UPDATE_REQUEST
  };
}

function receivePasswordUpdate() {
  return {
    type: SECURITY_PASSWORD_UPDATE_RECEIVE
  };
}
