import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Form, Field } from "react-final-form";
import { Translate } from "react-localize-redux";
import { isMobileOnly } from "react-device-detect";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import InputField from "../../../../components/InputField";

import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import NoticeDanger from "../../../../components/Notices/NoticeDanger";

const StyledResetPasswordForm = styled.form`
  padding: 50px;
  background-color: ${theme.white};
  border: 1px solid ${theme.neutral3};
  border-left: ${props => !props.isMobile && "0"};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;

  & h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    margin-bottom: 30px;
  }

  & .login-button {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
  }

  & .forgot-password-link {
    display: block;
    color: ${theme.blueBase};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: 0.2px;
    text-align: center;
  }
`;

const ResetPasswordForm = ({ onSubmit, validate, onSetReCaptchaToken }) => (
  <Translate>
    {({ translate }) => (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({
          handleSubmit,
          submitting,
          pristine,
          invalid,
          submitError
        }) => (
          <StyledResetPasswordForm
            onSubmit={handleSubmit}
            isMobile={isMobileOnly}
          >
            <h3>{translate("security.authentication.reset-password")}</h3>
            <Field
              name="password"
              placeholder={translate(
                "my-account.security.new-password-placeholder"
              )}
              label={translate("my-account.security.new-password")}
              type="password"
              component={InputField}
            />
            <Field
              name="confirm_password"
              placeholder={translate(
                "my-account.security.confirm-new-password-placeholder"
              )}
              label={translate("my-account.security.confirm-new-password")}
              type="password"
              component={InputField}
            />
            <GoogleReCaptcha onVerify={token => onSetReCaptchaToken(token)} />
            {submitError && <NoticeDanger>{submitError}</NoticeDanger>}
            <PrimaryButton
              type="submit"
              className="login-button"
              disabled={submitting}
              submitting={submitting}
            >
              {translate("my-account.security.save-and-continue")}
            </PrimaryButton>
          </StyledResetPasswordForm>
        )}
      />
    )}
  </Translate>
);

export default ResetPasswordForm;
