import * as feedbackService from "../../../services/feedbackService";

export function createFeedback(data) {
  return dispatch => {
    return feedbackService
      .create(data)
      .then(
        result => {
          const { error } = result.data;
          const { messages } = result.data.data;
          return { error, messages };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}
