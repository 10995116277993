import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";

import Table from "../../../../components/Table";

import DomainPriceTableHeader from "./DomainPriceTableHeader";
import DomainPriceTableBody from "./DomainPriceTableBody";

const StyledDomainPriceTable = styled.div`
  tbody td {
    &:first-child {
      border-right: 1px solid ${theme.neutral3};
    }
    &:not(:nth-child(2)) {
      font-weight: 600;
    }
  }
`;

const DomainPriceTable = props => (
  <StyledDomainPriceTable className="table-responsive">
    <Table>
      <DomainPriceTableHeader
        increaseDuration={props.increaseDuration}
        decreaseDuration={props.decreaseDuration}
      />
      <DomainPriceTableBody
        tableItems={props.tableItems}
        customerCurrencyCode={props.customerCurrencyCode}
        duration={props.duration}
      />
    </Table>
  </StyledDomainPriceTable>
);

export default DomainPriceTable;
