import React from "react";
import styled from "styled-components";

import ButtonGroup from "../ButtonGroup";
import Icon from "../Icon";
import SecondaryButton from "../Buttons/SecondaryButton";

const StyledVaryAmount = styled.div`
  display: inline-block;
  margin-left: 10px;
  button {
    padding: 4px;
    height: 32px;
    width: 32px;
  }
`;

const VaryAmount = props => (
  <StyledVaryAmount>
    <ButtonGroup>
      <SecondaryButton onClick={e => props.decrease()}>
        <Icon icon="input-indeterminate" />
      </SecondaryButton>

      <SecondaryButton onClick={e => props.increase()}>
        <Icon icon="add" />
      </SecondaryButton>
    </ButtonGroup>
  </StyledVaryAmount>
);

export default VaryAmount;
