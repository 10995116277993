import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Translate } from "react-localize-redux";

import graphicsDone from "../../../assets/images/icon-illustration-done.png";

const StyledProInvoiceWidgetEmptyState = styled.div`
  padding: 50px 0 25px;

  & img {
    margin-bottom: 30px;
    width: 100px;
    height: 100px;
  }

  & h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    text-align: center;
    margin-bottom: 5px;
  }

  & p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    color: ${theme.neutral6};
    text-align: center;
  }
`;

const ProInvoiceWidgetEmptyState = () => (
  <Translate>
    {({ translate }) => (
      <StyledProInvoiceWidgetEmptyState>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 text-center justify-content-center">
            <img src={graphicsDone} alt="" />
            <h4>{translate("home.widgets.pro-invoices.all-done")}</h4>
            <p>{translate("home.widgets.pro-invoices.all-done-description")}</p>
          </div>
        </div>
      </StyledProInvoiceWidgetEmptyState>
    )}
  </Translate>
);

export default ProInvoiceWidgetEmptyState;
