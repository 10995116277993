import * as SA from "../actions/serversActions"; // EA = Example Actions

import handleReducerActions from "../../../core/handleReducerActions";

const initialState = {
  isFetchingServers: false,
  items: []
};

const serversStateHandlers = {
  [SA.SERVERS_REQUEST]: serversRequest,
  [SA.SERVERS_RECEIVE]: serversReceive
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(serversStateHandlers, state, action);
}

function serversRequest(state, action) {
  return { ...state, isFetchingServers: true };
}

function serversReceive(state, action) {
  return {
    ...state,
    items: [...action.payload],
    isFetchingServers: false
  };
}

// this is a selector
export function getServers(state) {
  return state.catalog.servers.items;
}

export function isFetchingServers(state) {
  return state.catalog.servers.isFetchingServers;
}
