import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { isMobileOnly } from "react-device-detect";
import { Translate } from "react-localize-redux";
import isLaptop from "../../../../utils/isLaptopScreen";
import { withSizes } from "react-sizes";

import Container from "../../../../components/Containers/Container";
import Icon from "../../../../components/Icon";
import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";

import SelectableProductAddon from "../../../../components/SelectableProductAddon";
import HostingPricePerYearSelector from "../../../../components/HostingPricePerYearSelector";

import DeletingRecordLoader from "../../../../components/Loaders/DeletingRecordLoader";

const StyledDedicatedServer = styled.div`
  padding: ${props => {
    if (props.isMobile) return "30px 20px";
    if (props.isLaptop) return "25px";
    return "50px";
  }};
`;

const StyledRow = styled.div`
  margin-bottom: 30px;
`;

const StyledTypeTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${theme.neutral4};
  margin-bottom: 5px;
  text-transform: uppercase;

  & .domain {
    text-transform: lowercase;
  }
`;

const StyledDedicatedServerName = styled.h4`
  font-weight: 20px;
  font-weight: 600;
  font-size: 1.4;
  color: ${theme.neutralBase};
`;

const StyledDeleteIcon = styled.span`
  position: absolute;
  right: 0;
  top: ${props => (props.isMobile ? "-10px" : "-20px")};
  cursor: ${props => (props.isDeleting ? "default" : "pointer")};
`;

const StyledPriceBadge = styled.strong`
  text-transform: uppercase;
`;

const StyledPriceWrapper = styled.div`
  display: inline-block;
  vertical-align: bottom;
  margin-left: 15px;
`;

const DedicatedServer = ({
  product,
  onAttachServiceToProduct,
  onRemoveServiceFromProduct,
  onRemoveProductFromCart,
  deletingProductId,
  onUpdateHostingPeriod,
  updatingHostingPeriod,
  updatingHostingPeriodId,
  isLaptop
}) => {

  let firstYearDerivation = null;
  let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];

  // const firstYearDerivation = product.item.pricing.buy.billing_cycle.derivations.find(
  //   d => d.numeric_code === 0.1
  // );

  // Temporary solution, should be sync with api derivation numeric codes
  possiblePeriodNumericCodes.some(numericCode => {
    firstYearDerivation = product.item.pricing.buy.billing_cycle.derivations.find(
      d => d.numeric_code === numericCode
    );

    if (typeof(firstYearDerivation) !== 'undefined') {
      console.log(numericCode);
      return true;
    }
  });
  // END

  const selectedYearDerivation = product.item.pricing.buy.billing_cycle.derivations.find(
    d => d.numeric_code === product.config.period
  );
  return (
    <Translate>
      {({ translate }) => (
        <StyledRow className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <Container>
              <StyledDedicatedServer
                isMobile={isMobileOnly}
                isLaptop={isLaptop}
              >
                <StyledRow className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <StyledDeleteIcon
                      isMobile={isMobileOnly}
                      isDeleting={deletingProductId === product.id}
                      onClick={e =>
                        deletingProductId !== product.id &&
                        onRemoveProductFromCart(product)
                      }
                    >
                      {deletingProductId !== product.id && (
                        <Icon size="l" icon="delete" />
                      )}

                      {deletingProductId === product.id && (
                        <DeletingRecordLoader />
                      )}
                    </StyledDeleteIcon>
                    <StyledTypeTitle>
                      {translate("cart.dedicated-server")}{" "}
                      {product.domain && (
                        <span className="domain">({product.domain})</span>
                      )}
                    </StyledTypeTitle>
                    <StyledDedicatedServerName>
                      {product.item.name}
                    </StyledDedicatedServerName>
                  </div>
                </StyledRow>
                <StyledRow className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    {product.item.pricing.buy.billing_cycle.derivations.map(
                      derivation => (
                        <HostingPricePerYearSelector
                          product={product}
                          derivation={derivation}
                          onUpdateHostingPeriod={onUpdateHostingPeriod}
                          disabled={
                            updatingHostingPeriod &&
                            updatingHostingPeriodId === product.id
                          }
                          key={`${derivation.price.value}${derivation.numeric_code}`}
                          firstYearPrice={firstYearDerivation.price.value}
                          currency={firstYearDerivation.price.currency.code}
                          period={
                            derivation.numeric_code < 1
                              ? null
                              : derivation.numeric_code === 1
                              ? translate("shop.hostings.derivations.year")
                              : translate("shop.hostings.derivations.years")
                          }
                          years={derivation.numeric_code}
                          price={derivation.price.value}
                          selected={
                            product.config.period === derivation.numeric_code
                          }
                        />
                      )
                    )}

                    <StyledPriceWrapper>
                      <DiscountedAmountBadge
                        label={translate("cart.item-price")}
                        amount={{
                          amount: product.total,
                          discountedAmount:
                            selectedYearDerivation.numeric_code < 1
                              ? null
                              : firstYearDerivation.numeric_code !==
                                selectedYearDerivation.numeric_code
                              ? firstYearDerivation.price.value *
                                selectedYearDerivation.numeric_code
                              : null,
                          currency: firstYearDerivation.price.currency.code
                        }}
                      />
                    </StyledPriceWrapper>
                  </div>
                </StyledRow>
                <StyledRow className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    {product.item.services &&
                      product.item.services.length > 0 &&
                      product.item.services.map(service => {
                        if (
                          service.pricing.buy.billing_cycle.derivations
                            .length === 0
                        ) {
                          return null;
                        }
                        return (
                          <SelectableProductAddon
                            addon={service}
                            onSelect={e =>
                              service.activated
                                ? onRemoveServiceFromProduct(service, product)
                                : onAttachServiceToProduct(service, product)
                            }
                            key={`product-${product.id}-service-${service.id}`}
                            activated={service.activated}
                            disabled={service.updating}
                          >
                            {translate("cart.item-add")} {service.name}{" "}
                            <StyledPriceBadge>
                              {service.pricing.buy.billing_cycle.derivations[0]
                                .price.value > 0
                                ? service.pricing.buy.billing_cycle
                                    .derivations[0].price.currency.code +
                                  " " +
                                  service.pricing.buy.billing_cycle
                                    .derivations[0].price.value
                                : translate("general.free")}
                            </StyledPriceBadge>
                            {service.pricing.buy.billing_cycle.derivations[0]
                              .price.value > 0 && (
                              <span>
                                /
                                {translate(
                                  `price.derivations.${service.pricing.buy.billing_cycle.derivations[0].code}`
                                )}
                              </span>
                            )}
                          </SelectableProductAddon>
                        );
                      })}
                  </div>
                </StyledRow>
              </StyledDedicatedServer>
            </Container>
          </div>
        </StyledRow>
      )}
    </Translate>
  );
};

DedicatedServer.defaultProps = {
  product: {
    domainName: "mkhost.com",
    type: "buy"
  }
};

export default withSizes(isLaptop)(DedicatedServer);
