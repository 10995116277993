import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";

import ProductPlan from "./ProductPlan";

const StyledUpgradeDowngradeServer = styled.div``;

class UpgradeDowngradeServer extends React.Component {
  render() {
    const {
      productUpgrades,
      currentPlan,
      onUpgradeProduct,
      upgradingProduct
    } = this.props;

    return (
      <StyledUpgradeDowngradeServer>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              {productUpgrades &&
                productUpgrades.length > 0 &&
                productUpgrades.map(product => {
                  let colSize =
                    12 /
                    (productUpgrades.length > 1 ? productUpgrades.length : 3);
                  if (
                    product.pricing.buy.billing_cycle.derivations.length === 0
                  )
                    return null;

                  return (
                    <div
                      key={`product-plan-${product.id}`}
                      className={`col-12 col-sm-${colSize} col-md-${colSize} `}
                    >
                      <ProductPlan
                        server={product}
                        currentProduct={currentPlan}
                        onUpgradeProduct={onUpgradeProduct}
                        upgradingProduct={upgradingProduct}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </StyledUpgradeDowngradeServer>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(UpgradeDowngradeServer);
