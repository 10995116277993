import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { withLocalize } from "react-localize-redux";
import { isMobileOnly, isTablet } from "react-device-detect";

import { fetchProductsByGroup } from "../../Products/actions/productsActions";
import {
  getProductsListingByGroup,
  isFetchingProducts
} from "../../Products/reducers/productsReducer";

import Container from "../../../components/Containers/Container";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";

const CartAddHostingToDomainWrapper = styled.div`
  margin-bottom: 50px;
`;
const CartAddHostingToDomainContainer = styled(Container)`
  padding: ${props => (props.isMobile || props.isTablet ? "20px" : "50px")};
`;

const StyledTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  color: ${theme.neutralBase};
  margin-bottom: 10px;
`;
const StyledDescription = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.neutral6};
`;

class CartAddHostingToDomain extends React.Component {
  render() {
    return (
      <CartAddHostingToDomainWrapper>
        <CartAddHostingToDomainContainer
          isMobile={isMobileOnly}
          isTablet={isTablet}
        >
          <StyledTitle>
            {this.props.translate("cart.add-hosting-to-domain-title")}
          </StyledTitle>
          <StyledDescription>
            {this.props.translate("cart.add-hosting-to-domain-description")}
          </StyledDescription>
          <PrimaryButton
            onClick={() =>
              this.props.onShowAssignHostingToDomain(this.props.domain)
            }
          >
            {this.props.translate("cart.choose-hosting-plan")}
          </PrimaryButton>
        </CartAddHostingToDomainContainer>
      </CartAddHostingToDomainWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    hostings: getProductsListingByGroup(state),
    isFetchingHostings: isFetchingProducts(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProductsByGroup
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(CartAddHostingToDomain));
