import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { withLocalize } from "react-localize-redux";
import { withSizes } from "react-sizes";
import isLaptop from "../../../utils/isLaptopScreen";

import * as domainsService from "../../../services/domainsService";

import { addProductToCart } from "../actions/cartActions";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import Container from "../../../components/Containers/Container";
import DomainSearch from "../../../components/DomainSearch";
import DomainSearchResults from "../../../components/DomainSearch/DomainSearchResults";

import { DOMAIN_STATUS_UNAVAILABLE } from "../../Domains/DomainStatuses";

import { ROUTE_CART } from "../../../routes/routes";

import {
  REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_TYPE,
  REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE
} from "../../../config/promotions";

const StyledCartAddMoreDomainsWrapper = styled.div`
  margin-bottom: 30px;
  & .domain-search .domain-search-input {
    width: ${props => props.isLaptop && "53%"};
  }

  & .domain-search button.primary-button {
    margin-right: 0;
    width: ${props => props.isLaptop && "21%"};

    @media (max-width: 1440px) {
      width: 24%;
      padding: 0;
    }
    @media (max-width: 1366px) {
      width: 25%;
    }
    @media (max-width: 425px) {
      width: 42%;
    }
  }
`;
const StyledTitle = styled.h3`
  font-size: 24px;
  font-weight: 600;
  color: ${theme.neutralBase};
  margin-bottom: 10px;
`;

const StyledCartAddMoreDomains = styled(Container)``;

class CartAddMoreDomains extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      extensions: null,
      searching: false,
      selectedDomain: null,
      whoIs: null,
      addedToCart: false,
      error: false,
      submitting: false
    };
    this.domainResultsRef = null;
  }

  setDomainResultsRef = ref => (this.domainResultsRef = ref);

  componentDidMount() {
    if (this.props.extensions) {
      let extensions = [];

      Object.keys(this.props.extensions).map(ext => {
        extensions.push({ label: `.${ext}`, value: ext });
        return ext;
      });

      this.setState({
        extensions
      });
    }
  }

  onSearchDomain = values => {
    this.setState({
      searching: true,
      domain: values.domain.toLowerCase(),
      searchDomain: values.domain + values.extension.label,
      selectedExtension: values.extension.value
    });

    return domainsService
      .isDomainAvailable(values.domain + values.extension.label)
      .then(result => {
        const { domain } = result.data.data;
        const { error } = result.data;

        return domainsService.domainWhoIs(domain.name).then(result => {
          this.setState({
            selectedDomain: domain,
            whoIs: result.data.data,
            searching: false,
            addedToCart: false,
            error: error
          });

          if (this.domainResultsRef) {
            try {
              this.domainResultsRef.scrollIntoView({ behavior: "smooth" });
            } catch (e) {
              this.domainResultsRef.scrollIntoView(false);
            }
          }
          return result;
        });
      })
      .catch(errorResult => {
        const { error } = errorResult.response.data;
        this.setState({
          selectedDomain: {
            name: values.domain + values.extension.label,
            status: DOMAIN_STATUS_UNAVAILABLE
          },
          whoIs: null,
          searching: false,
          error: error
        });
      });
  };

  onAddToCart = domain => {
    this.setState({
      submitting: true
    });
    return this.props
      .addProductToCart({
        type: "domain",
        domain_id: null,
        domain: domain.name,
        quantity: 1,
        config: {
          type: "register",
          period:
            domain.pricing["register"].billing_cycle.derivations[0].numeric_code
        }
      })
      .then(
        result => {
          displayToastMessageForResponse(
            this.props.translate("cart.shop"),
            result
          );

          this.setState({ addedToCart: true, submitting: false });
        },
        err => {
          displayToastMessageForResponse(
            this.props.translate("cart.shop"),
            err
          );
          this.setState({ addedToCart: false, submitting: false });
        }
      )
      .catch(err => {
        displayToastMessageForResponse(this.props.translate("cart.shop"), err);
        this.setState({ addingDomainFromUrl: false, submitting: false });
      });
  };

  render() {
    return (
      <StyledCartAddMoreDomainsWrapper isLaptop={this.props.isLaptop}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <StyledTitle>
              {this.props.translate("cart.add-more-domains")}
            </StyledTitle>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            {this.state.extensions && (
              <DomainSearch
                onSubmit={this.onSearchDomain}
                type="register"
                extensions={this.state.extensions}
                searchPlaceholder={this.props.translate(
                  "shop.domains.search-tabs.domain-name-you-are-looking-for"
                )}
                onAddToCart={this.onAddToCart}
              />
            )}

            <div ref={this.setDomainResultsRef}>
              {this.state.selectedDomain && (
                <DomainSearchResults
                  domain={this.state.selectedDomain}
                  search={this.state.searchDomain}
                  onAddToCart={this.onAddToCart}
                  addedToCart={this.state.addedToCart}
                  submitting={this.state.submitting}
                  type="register"
                  whoIs={this.state.whoIs}
                  error={this.state.error}
                  priceReduce={
                    this.state.selectedExtension === "mk"
                      ? {
                          type: "register",
                          discountType: REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_TYPE,
                          value: REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE
                        }
                      : null
                  }
                />
              )}
            </div>
          </div>
        </div>
      </StyledCartAddMoreDomainsWrapper>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ addProductToCart }, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(withLocalize(withSizes(isLaptop)(CartAddMoreDomains)));
