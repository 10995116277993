import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";

import Filter from "../../../components/Filter";
import Container from "../../../components/Containers/Container";
import Form from "../../../components/Forms/Form";
import SelectBox from "../../../components/SelectBox";

const StyledDomainFilter = styled.div`
  margin-bottom: 30px;
`;

class DomainsFilter extends React.Component {
  onStatusFilterOptionChange = selectedOptionValue => {
    this.props.fetchCustomerDomains(
      this.props.domains.paging.limit,
      1,
      this.props.domains.sort,
      {
        ...this.props.domains.filters,
        status: selectedOptionValue.value
      }
    );
  };

  onExtensionFilterOptionChange = selectedOptionValue => {
    this.props.fetchCustomerDomains(
      this.props.domains.paging.limit,
      1,
      this.props.domains.sort,
      {
        ...this.props.domains.filters,
        extension: selectedOptionValue.value
      }
    );
  };

  onRegisterContactFilterOptionChange = selectedOptionValue => {
    this.props.fetchCustomerDomains(
      this.props.domains.paging.limit,
      1,
      this.props.domains.sort,
      {
        ...this.props.domains.filters,
        registrant: selectedOptionValue.value
      }
    );
  };

  onClear = () => {
    this.props.fetchCustomerDomains(
      this.props.domains.paging.limit,
      1,
      this.props.domains.sort,
      {}
    );
  };

  render() {
    let selectedRegisterContact = this.props.domainsListFilters.registerContacts.filter(
      contact => contact.code === this.props.domains.filters.registrant
    )[0];

    return (
      <Translate>
        {({ translate }) => (
          <StyledDomainFilter>
            <Container>
              <Filter
                title={translate("domains.filter.title")}
                clearTitle={translate("domains.filter.clear-all")}
                count={this.props.countFiltersApplied()}
                onClear={this.onClear}
              >
                <Form>
                  <SelectBox
                    name="registrant"
                    label={translate("filters.registrant")}
                    placeholder={translate("filters.registrant-placeholder")}
                    async={true}
                    loadOptions={this.props.filterRegisterContactsOptions}
                    defaultOptions
                    isSearchable={false}
                    input={{
                      onChange: this.onRegisterContactFilterOptionChange
                    }}
                    value={
                      selectedRegisterContact
                        ? {
                            value: selectedRegisterContact.code,
                            label: selectedRegisterContact.name
                          }
                        : false
                    }
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue &&
                      translate("domains.filter.no-registrant-options")
                    }
                  />
                  <SelectBox
                    name="status"
                    label={translate("filters.status")}
                    placeholder={translate("filters.status-placeholder")}
                    async={true}
                    loadOptions={this.props.filterStatusOptions}
                    defaultOptions
                    isSearchable={false}
                    input={{ onChange: this.onStatusFilterOptionChange }}
                    value={
                      this.props.domains.filters &&
                      this.props.domains.filters.status !== ""
                        ? {
                            value: this.props.domains.filters.status,
                            label: translate(
                              "domains.filter.filter-statuses." +
                                this.props.domains.filters.status
                            )
                          }
                        : false
                    }
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue &&
                      translate("domains.filter.no-status-options")
                    }
                  />
                  <SelectBox
                    name="extensions"
                    label={translate("filters.extensions")}
                    placeholder={translate("filters.extensions-placeholder")}
                    async={true}
                    loadOptions={this.props.filterExtensionsOptions}
                    defaultOptions
                    isSearchable={false}
                    input={{ onChange: this.onExtensionFilterOptionChange }}
                    value={
                      this.props.domains.filters &&
                      this.props.domains.filters.extension !== ""
                        ? {
                            value: this.props.domains.filters.extension,
                            label: this.props.domains.filters.extension
                          }
                        : false
                    }
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue &&
                      translate("domains.filter.no-extension-options")
                    }
                  />
                </Form>
              </Filter>
            </Container>
          </StyledDomainFilter>
        )}
      </Translate>
    );
  }
}

DomainsFilter.defaultProps = {
  count: 0,
  onClear: () => {}
};

export default DomainsFilter;
