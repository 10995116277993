import React from "react";

import Loader from "../Loader";

const KnowledgeBaseCategoryArticlesLoader = () => (
  <Loader width="280" height="300">
    <rect x="5" y="10" width="50" height="15" rx="3" />
    <rect x="5" y="30" width="100" height="18" rx="3" />

    <rect x="5" y="60" width="270" height="20" rx="3" />
    <rect x="5" y="90" width="270" height="20" rx="3" />
    <rect x="5" y="120" width="270" height="20" rx="3" />
    <rect x="5" y="150" width="270" height="20" rx="3" />
  </Loader>
);

export default KnowledgeBaseCategoryArticlesLoader;
