import React from "react";
import styled from "styled-components";

import loading from "../../../assets/images/logomark-blue.svg";

const StyledUpdatingHostingLoader = styled.div`
  position: absolute;
  display: inline-block;
  vertical-align: top;
  left: 59px;
  top: 97px;
  & .loading-wrapper {
    position: absolute;
    z-index: 1;
    background-color: transparent;
    width: 16px;
    height: 16px;
  }

  & .btn-loading-img {
    position: relative;
    top: 4px;
    width: 16px;
    height: 16px;
    animation: btn-loading-img 1.2s linear infinite;
  }

  @keyframes btn-loading-img {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const UpdatingHostingLoader = () => (
  <StyledUpdatingHostingLoader>
    <div className="loading-wrapper">
      <img src={loading} className="btn-loading-img" alt="" />
    </div>
  </StyledUpdatingHostingLoader>
);

export default UpdatingHostingLoader;
