import Badge from "../components/Badges/Badge";
import BadgeInfo from "../components/Badges/BadgeInfo";
import BadgeDanger from "../components/Badges/BadgeDanger";
import BadgeWarning from "../components/Badges/BadgeWarning";
import BadgeSuccess from "../components/Badges/BadgeSuccess";
import BadgeTerminate from "../components/Badges/BadgeTerminate";
import StatusTextSuccess from "../components/StatusTexts/StatusTextSuccess";
import StatusTextInfo from "../components/StatusTexts/StatusTextnfo";

const badges = {
  active: BadgeSuccess,
  warning: BadgeWarning,
  danger: BadgeDanger,
  open: BadgeSuccess,
  close: BadgeDanger,
  high: BadgeDanger,
  medium: BadgeWarning,
  low: BadgeInfo,
  unpaid: BadgeDanger,
  pending: BadgeWarning,
  refunded: Badge,
  paid: BadgeSuccess,
  terminated: BadgeTerminate,
  answered: BadgeInfo,
  closed: Badge,
  yellow:BadgeWarning,
  green: BadgeSuccess,
  white: BadgeInfo,
  red: BadgeDanger
};

export default function getBadgeForStatus(status) {
  return badges[status] ? badges[status] : Badge;
}
