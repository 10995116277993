import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import ToggleboxWithTitleAndDecription from "../../../../../components/ToggleboxWithTitleAndDecription";

const StyledScopesList = styled.div`
  padding: ${props => !props.isMobile && "0px 50px"};
`;
const StyledScopeListItem = styled.div`
  & .scope-enable-toggle {
    display: inline-block;
  }
`;

const StyledNoScopes = styled.div``;

const ScopesList = ({
  app,
  allScopes,
  appScopes,
  isAppActive,
  onToggleAppScope
}) => {
  const mergedScopes = allScopes.map(scope => {
    if (appScopes.find(current => current.id === scope.id)) {
      return {
        ...scope,
        enabled: true
      };
    }
    return {
      ...scope,
      enabled: false
    };
  });

  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12">
        {(!mergedScopes || mergedScopes.length === 0) && (
          <StyledNoScopes>123</StyledNoScopes>
        )}
        {mergedScopes &&
          mergedScopes.length > 0 && (
            <StyledScopesList isMobile={isMobileOnly}>
              {mergedScopes.map(scope => (
                <StyledScopeListItem key={scope.id}>
                  <ToggleboxWithTitleAndDecription
                    className="scope-enable-toggle"
                    toggled={scope.enabled}
                    disabled={!isAppActive}
                    title={scope.name}
                    description={scope.description}
                    onToggle={() => onToggleAppScope(app, scope)}
                  />
                </StyledScopeListItem>
              ))}
            </StyledScopesList>
          )}
      </div>
    </div>
  );
};

export default ScopesList;
