import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Flag from "../Flag";

const StyledFlagNormalSuccess = styled.div`
  & .icon:not(.icon-close) {
    color: ${theme.greenBase};
  }
`;

const FlagNormalSuccess = ({ children, ...rest }) => (
  <StyledFlagNormalSuccess>
    <Flag icon={rest.icon || "success"} {...rest}>
      {children}
    </Flag>
  </StyledFlagNormalSuccess>
);

FlagNormalSuccess.defaultProps = {
  onDismiss: () => {}
};

export default FlagNormalSuccess;
