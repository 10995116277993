import * as CA from "../actions/customerActions";
import { AUTH_LOGOUT } from "../../Authentication/actions/authActions";

import { APP_STATE_COMPLETE_RESET } from "../../AppState/actions/appStateActions";

import handleReducerActions from "../../../core/handleReducerActions";
import { SORT_ORDER_DESCENDING } from "../../../types/SortTypes";
import { DOMAIN_STATUS_PENDING } from "../../../features/Domains/DomainStatuses";

const initialState = {
  products: {
    items: [],
    paging: {
      limit: 10,
      current_page: 0,
      next_page: 0,
      previous_page: 0,
      total_pages: 0,
      total: 0
    },
    filters: {
      status: "",
      keyword: "",
      plan_type: ""
    },
    sort: {
      sort_by: "renewal_date", // Remove magic variable implementation
      order_by: SORT_ORDER_DESCENDING
    }
  },
  servers: {
    items: [],
    paging: {
      limit: 10,
      current_page: 0,
      next_page: 0,
      previous_page: 0,
      total_pages: 0,
      total: 0
    },
    filters: {
      status: "",
      keyword: "",
      plan_type: ""
    },
    sort: {
      sort_by: "renewal_date", // Remove magic variable implementation
      order_by: SORT_ORDER_DESCENDING
    }
  },
  domains: {
    items: [],
    paging: {
      limit: 10,
      current_page: 0,
      next_page: 0,
      previous_page: 0,
      total_pages: 0,
      total: 0
    },
    filters: {
      status: "",
      keyword: "",
      registrant: "",
      extension: ""
    },
    sort: {
      sort_by: "expiry_date", // Remove magic variable implementation
      order_by: SORT_ORDER_DESCENDING
    }
  },
  contacts: [],
  isFetchingProducts: false,
  isFetchingServers: false,
  isFetchingDomains: false,
  isFetchingContact: false,
  isFetchingDomainFilters: false,
  isFetchingDomainStatusFilters: false,
  isFetchingDomainExtensionsFilters: false,
  isFetchingDomainRegisterContactsFilter: false,
  domainsListFilters: {
    statuses: [],
    extensions: [],
    registerContacts: []
  },
  productsListFilters: {
    statuses: [],
    products: []
  },
  serversListFilters: {
    statuses: []
  },
  backup_history: {
    restore_history: [],
    backups_dates: [],
    paging: {
      limit: 5,
      current_page: 0,
      next_page: 0,
      previous_page: 0,
      total_pages: 0,
      total: 0
    },
  },
  payersForFilter: [],
  personalInformation: null,
  isUpdatingCustomerPersonalInformation: false,
  isCustomerPersonalInformationComplete: true,
  isFetchingEppTransferCode: false,
  isFetchingDomainServices: false,
  isFetchingDomainNameServers: false,
  isFetchingDomainRegisterContacts: false,
  isFetchingDomainZoneFile: false,
  isFetchingProductServices: false,
  isFetchingAvailableBackups: false,
  isFetchingBackupHistory: false,
  isBackupRequest: false,
  isFetchingServerServices: false,
  isCancellingDomainService: false,
  isCancellingProductService: false,
  isGettingHostingCPanelDirectLoginLink: false,
  isChangingHostingCPanelPassword: false,
  isFetchingCustomerPersonalInformation: true,
  isFetchingEmails: false,
  emails: {
    items: [],
    paging: {
      limit: 10,
      current_page: 0,
      next_page: 0,
      previous_page: 0,
      total_pages: 0,
      total: 0
    },
    filters: {
      status: "",
      keyword: "",
      plan_type: ""
    },
    sort: {
      sort_by: "date", // Remove magic variable implementation
      order_by: SORT_ORDER_DESCENDING
    }
  },
  domainsWithoutHosting: [],
  isFetchingDomainRedirectionAvailable: false,
  isDomainRedirectionAvailable: false,
  domainRedirectionReason: "",
  isFetchingDomainRedirection: false,
  domainRedirection: null,
  isUpdatingDomainRedirection: false,
  isFetchingDomainSettings: false
};

const customerStateHandlers = {
  [CA.CUSTOMER_PRODUCTS_REQUEST]: customerProductsRequest,
  [CA.CUSTOMER_PRODUCTS_RECEIVE]: customerProductsReceive,
  [CA.CUSTOMER_PRODUCTS_UNASSIGN_PRODUCT]: customerProductUnassign,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_SERVICES_REQUEST]: productServicesRequest,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_AVAILABLE_BACKUPS_REQUEST]: productAvailableBackupsRequest,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_BACKUP_HISTORY_REQUEST]: productBackupHistoryRequest,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_BACKUP_START_REQUEST]: productBackupHistoryRequestStart,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_SERVICES_RECEIVE]: productServicesReceive,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_AVAILABLE_BACKUPS_RECEIVE]: productAvailableBackupsReceive,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_BACKUP_HISTORY_RECEIVE]: productBackupHistoryReceive,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_BACKUP_START_RECEIVE]: productBackupHistoryReceiveStart,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_SERVICE_CANCELLATION_REQUEST]: customerProductServiceCancellationRequest,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_SERVICE_CANCELLATION_RECEIVE]: customerProductServiceCancellationReceive,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_DIRECT_LOGIN_LINK_REQUEST]: customerProductHostingCPanelDirectLoginLinkRequest,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_DIRECT_LOGIN_LINK_RECEIVE]: customerProductHostingCPanelDirectLoginLinkReceive,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_PASSWORD_CHANGE_REQUEST]: customerProductHostingCPanelPasswordChangeRequest,
  [CA.CUSTOMER_PRODUCTS_PRODUCT_HOSTING_CPANEL_PASSWORD_CHANGE_RECEIVE]: customerProductHostingCPanelPasswordChangeReceive,
  [CA.CUSTOMER_SERVERS_REQUEST]: customerServersRequest,
  [CA.CUSTOMER_SERVERS_RECEIVE]: customerServersReceive,
  [CA.CUSTOMER_PRODUCTS_SERVER_SERVICES_REQUEST]: serverServicesRequest,
  [CA.CUSTOMER_PRODUCTS_SERVER_SERVICES_RECEIVE]: serverServicesReceive,
  [CA.CUSTOMER_DOMAINS_REQUEST]: customerDomainsRequest,
  [CA.CUSTOMER_DOMAINS_RECEIVE]: customerDomainsReceive,
  [CA.CUSTOMER_DOMAIN_RECEIVE]: customerDomainReceive,
  [CA.CUSTOMER_DOMAINS_VIEW_DOMAIN]: customerDomainsView,
  [CA.CUSTOMER_EMAILS_VIEW_EMAIL]: customerEmailView,
  [CA.CUSTOMER_DOMAINS_DOMAIN_SETTINGS_REQUEST]: customerDomainsSettingsRequest,
  [CA.CUSTOMER_DOMAINS_DOMAIN_SETTINGS_RECEIVE]: customerDomainsSettingsReceive,
  [CA.CUSTOMER_DOMAINS_DOMAIN_SERVICES_REQUEST]: customerDomainsServicesRequest,
  [CA.CUSTOMER_DOMAINS_DOMAIN_SERVICES_RECEIVE]: customerDomainsServicesReceive,
  [CA.CUSTOMER_DOMAINS_DOMAIN_SERVICE_CANCELLATION_REQUEST]: customerDomainServiceCancellationRequest,
  [CA.CUSTOMER_DOMAINS_DOMAIN_SERVICE_CANCELLATION_RECEIVE]: customerDomainServiceCancellationReceive,
  [CA.CUSTOMER_CONTACTS_REQUEST]: customerContactsRequest,
  [CA.CUSTOMER_CONTACTS_RECEIVE]: customerContactsReceive,
  [CA.CUSTOMER_DOMAINS_DOMAIN_MAMESERVERS_UPDATE]: customerDomainNameserversUpdate,
  [CA.CUSTOMER_CONTACTS_DOMAIN_CONTACT_CHANGE]: customerContactsDomainContactChange,
  [CA.CUSTOMER_CONTACTS_VIEW_CONTACT]: customerContactsViewContact,
  [CA.CUSTOMER_CONTACTS_SELECT_CONTACT]: customerContactsSelectContact,
  [CA.CUSTOMER_DOMAINS_REQUEST_FILTER_STATUS_COLLECTION]: customerDomainsRequestFilterStatusCollection,
  [CA.CUSTOMER_DOMAINS_RECEIVE_FILTER_STATUS_COLLECTION]: customerDomainsReceiveFilterStatusCollection,
  [CA.CUSTOMER_DOMAINS_REQUEST_FILTER_EXTENSIONS_COLLECTION]: customerDomainsRequestFilterExtensionsCollection,
  [CA.CUSTOMER_DOMAINS_RECEIVE_FILTER_EXTENSIONS_COLLECTION]: customerDomainsReceiveFilterExtensionsCollection,
  [CA.CUSTOMER_DOMAINS_REQUEST_FILTER_REGISTER_CONTACTS_COLLECTION]: customerDomainsRequestFilterRegisterContactsCollection,
  [CA.CUSTOMER_DOMAINS_RECEIVE_FILTER_REGISTER_CONTACTS_COLLECTION]: customerDomainsReceiveFilterRegisterContactsCollection,
  [CA.CUSTOMER_DOMAINS_LIST_SELECT_TABLE_ROW]: customerDomainsListSelectTableRow,
  [CA.CUSTOMER_DOMAINS_LIST_DESELECT_TABLE_ROW]: customerDomainsListDeselectTableRow,
  [CA.CUSTOMER_DOMAINS_LIST_SELECT_ALL_TABLE_ROWS]: customerDomainsListSelectAllTableRows,
  [CA.CUSTOMER_DOMAINS_LIST_DESELECT_ALL_TABLE_ROWS]: customerDomainsListDeselectAllTableRows,
  [CA.CUSTOMER_PRODUCTS_REQUEST_FILTER_STATUS_COLLECTION]: customerProductsRequestFilterStatusCollection,
  [CA.CUSTOMER_PRODUCTS_RECEIVE_FILTER_STATUS_COLLECTION]: customerProductsReceiveFilterStatusCollection,
  [CA.CUSTOMER_SERVERS_REQUEST_FILTER_STATUS_COLLECTION]: customerServersRequestFilterStatusCollection,
  [CA.CUSTOMER_SERVERS_RECEIVE_FILTER_STATUS_COLLECTION]: customerServersReceiveFilterStatusCollection,
  [CA.CUSTOMER_PRODUCTS_REQUEST_FILTER_PRODUCTS_COLLECTION]: customerProductsRequestFilterProductsCollection,
  [CA.CUSTOMER_PRODUCTS_RECEIVE_FILTER_PRODUCTS_COLLECTION]: customerProductsReceiveFilterProductsCollection,
  [CA.CUSTOMER_PRODUCTS_LIST_SELECT_TABLE_ROW]: customerProductsListSelectTableRow,
  [CA.CUSTOMER_PRODUCTS_LIST_DESELECT_TABLE_ROW]: customerProductsListDeselectTableRow,
  [CA.CUSTOMER_PRODUCTS_LIST_SELECT_ALL_TABLE_ROWS]: customerProductsListSelectAllTableRows,
  [CA.CUSTOMER_PRODUCTS_LIST_DESELECT_ALL_TABLE_ROWS]: customerProductsListDeselectAllTableRows,
  [CA.CUSTOMER_PAYERS_FOR_FILTER_REQUEST]: customerPayersForFilterRequest,
  [CA.CUSTOMER_PAYERS_FOR_FILTER_RECEIVE]: customerPayersForFilterReceive,
  [CA.CUSTOMER_PERSONAL_INFORMATION_REQUEST]: customerPersonalInformationRequest,
  [CA.CUSTOMER_PERSONAL_INFORMATION_RECEIVE]: customerPersonalInformationReceive,
  [CA.CUSTOMER_PERSONAL_INFORMATION_FAILED]: customerPersonalInformationFailed,
  [CA.CUSTOMER_PERSONAL_INFORMATION_UPDATE_REQUEST]: customerPersonalInformationUpdateRequest,
  [CA.CUSTOMER_PERSONAL_INFORMATION_UPDATE_RECEIVE]: customerPersonalInformationUpdateReceive,
  [CA.CUSTOMER_DOMAINS_EPP_CODE_REQUEST]: customerDomainEppCodeRequest,
  [CA.CUSTOMER_DOMAINS_EPP_CODE_RECEIVE]: customerDomainEppCodeReceive,
  [AUTH_LOGOUT]: resetCustomerPersonalInformation,
  [CA.CUSTOMER_EMAILS_REQUEST]: customerEmailsRequest,
  [CA.CUSTOMER_EMAILS_RECEIVE]: customerEmailsReceive,
  [CA.CUSTOMER_DOMAINS_WITHOUT_HOSTING_REQUEST]: customerDomainsWithoutHostingRequest,
  [CA.CUSTOMER_DOMAINS_WITHOUT_HOSTING_RECEIVE]: customerDomainsWithoutHostingReceive,
  [APP_STATE_COMPLETE_RESET]: resetState,
  [CA.CUSTOMER_DOMAIN_NAME_SERVERS_REQUEST]: customerDomainNameServersRequest,
  [CA.CUSTOMER_DOMAIN_NAME_SERVERS_RECEIVE]: customerDomainNameServersReceive,
  [CA.CUSTOMER_DOMAIN_REGISTER_CONTACTS_REQUEST]: customerDomainRegisterContactsRequest,
  [CA.CUSTOMER_DOMAIN_REGISTER_CONTACTS_RECEIVE]: customerDomainRegisterContactsReceive,
  [CA.CUSTOMER_DOMAIN_ZONE_FILE_REQUEST]: customerDomainZoneFileRequest,
  [CA.CUSTOMER_DOMAIN_ZONE_FILE_RECEIVE]: customerDomainZoneFileReceive,
  [CA.CUSTOMER_DOMAIN_ZONE_FILE_DELETE]: customerDomainZoneFileDelete,
  [CA.CUSTOMER_DOMAIN_DNS_RECORD_UPDATE_TYPE]: customerDomainDnsRecordUpdateType,
  [CA.CUSTOMER_DOMAIN_DNS_RECORD_UPDATE_FIELD]: customerDomainDnsRecordUpdateField,
  [CA.CUSTOMER_DOMAIN_DNS_RECORD_UPDATE_ALL_FIELDS]: customerDomainDnsRecordUpdateAllFields,
  [CA.CUSTOMER_DOMAIN_REDIRECTION_AVAILABLE_REQUEST]: customerDomainRedirectionAvailableRequest,
  [CA.CUSTOMER_DOMAIN_REDIRECTION_AVAILABLE_RECEIVE]: customerDomainRedirectionAvailableReceive,
  [CA.CUSTOMER_DOMAIN_GET_REDIRECTION_REQUEST]: customerDomainRedirectionRequest,
  [CA.CUSTOMER_DOMAIN_GET_REDIRECTION_RECEIVE]: customerDomainRedirectionReceive,
  [CA.CUSTOMER_DOMAIN_UPDATE_REDIRECTION_REQUEST]: customerDomainRedirectionUpdateRequest,
  [CA.CUSTOMER_DOMAIN_UPDATE_REDIRECTION_RECEIVE]: customerDomainRedirectionUpdateReceive,
  [CA.CUSTOMER_DOMAIN_DELETE_REDIRECTION_REQUEST]: customerDomainRedirectionRemoveRequest,
  [CA.CUSTOMER_DOMAIN_DELETE_REDIRECTION_RECEIVE]: customerDomainRedirectionRemoveReceive
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(customerStateHandlers, state, action);
}

function customerDomainEppCodeRequest(state, action) {
  return {
    ...state,
    isFetchingEppTransferCode: true
  };
}

function customerDomainEppCodeReceive(state, action) {
  return {
    ...state,
    isFetchingEppTransferCode: false
  };
}

function customerPersonalInformationRequest(state, action) {
  return {
    ...state,
    isFetchingCustomerPersonalInformation: true
  };
}

function shouldFillPersonalInformation(data) {
  if (!data || !data.email || data.email === "") {
    return true;
  }
  if (!data || !data.first_name || data.first_name === "") {
    return true;
  }
  if (!data || !data.last_name || data.last_name === "") {
    return true;
  }
  return false;
}

function customerPersonalInformationReceive(state, action) {
  const shouldFill = shouldFillPersonalInformation(action.payload);
  return {
    ...state,
    personalInformation: {
      ...action.payload,
      full_name: action.payload.first_name + " " + action.payload.last_name
    },
    isFetchingCustomerPersonalInformation: false,
    isCustomerPersonalInformationComplete: !shouldFill
  };
}

function customerPersonalInformationFailed(state, action) {
  return {
    ...state,
    personalInformation: null,
    isFetchingCustomerPersonalInformation: false,
    isCustomerPersonalInformationComplete: false
  };
}

function customerPersonalInformationUpdateRequest(state, action) {
  return {
    ...state,
    isUpdatingCustomerPersonalInformation: true
  };
}

function customerPersonalInformationUpdateReceive(state, action) {
  let personalInformation = action.payload
    ? action.payload
    : state.personalInformation;

  return {
    ...state,
    personalInformation: personalInformation,
    isUpdatingCustomerPersonalInformation: false
  };
}

function customerPayersForFilterRequest(state, action) {
  return {
    ...state
  };
}

function customerPayersForFilterReceive(state, action) {
  return {
    ...state,
    payersForFilter: action.payload
  };
}

function customerProductsRequest(state, action) {
  return {
    ...state,
    isFetchingProducts: true
  };
}

function customerProductsReceive(state, action) {
  return {
    ...state,
    products: {
      ...action.payload,
      items: action.payload.products.map(product => {
        return { ...product, services: [] };
      })
    },
    isFetchingProducts: false
  };
}

function customerServersRequest(state, action) {
  return {
    ...state,
    isFetchingServers: true
  };
}

function customerServersReceive(state, action) {
  return {
    ...state,
    servers: {
      ...action.payload,
      items: action.payload.servers.map(server => {
        return { ...server, services: [] };
      })
    },
    isFetchingServers: false
  };
}

function customerEmailsRequest(state, action) {
  return {
    ...state,
    isFetchingEmails: true
  };
}

function customerEmailsReceive(state, action) {
  return {
    ...state,
    emails: {
      ...action.payload,
      items: action.payload.items.map(email => {
        return { ...email, view: false };
      })
    },
    isFetchingEmails: false
  };
}

function productServicesRequest(state, action) {
  return {
    ...state,
    isFetchingProductServices: true
  };
}

function productServicesReceive(state, action) {
  return {
    ...state,
    isFetchingProductServices: false
  };
}

function productAvailableBackupsRequest(state, action) {
  return {
    ...state,
    isFetchingAvailableBackups: true
  };
}

function productAvailableBackupsReceive(state, action) {
  return {
    ...state,
    isFetchingAvailableBackups: false
  };
}

function productBackupHistoryRequest(state, action) {
  return {
    ...state,
    isFetchingBackupHistory: true
  };
}

function productBackupHistoryReceive(state, action) {
  return {
    ...state,
    isFetchingBackupHistory: false
  };
}

function productBackupHistoryRequestStart(state, action) {
  return {
    ...state,
    isBackupRequest: true
  };
}

function productBackupHistoryReceiveStart(state, action) {
  return {
    ...state,
    isBackupRequest: false
  };
}

function serverServicesRequest(state, action) {
  return {
    ...state,
    isFetchingServerServices: true
  };
}

function serverServicesReceive(state, action) {
  return {
    ...state,
    isFetchingServerServices: false
  };
}

function customerProductUnassign(state, action) {
  const { product, contact } = action.payload;
  return {
    ...state,
    contacts: state.contacts.map(currentContact => {
      if (currentContact.id !== contact.id) return { ...currentContact };
      return {
        ...currentContact,
        contact_items: currentContact.contact_items.map(currentProduct => {
          if (currentProduct.id !== product.id) return { ...currentProduct };
          return {
            ...currentProduct,
            state: {
              name: "Inactive", //@todo: this might change to remove the product instead of state change
              state: "inactive"
            }
          };
        })
      };
    })
  };
}

function customerDomainsRequest(state, action) {
  return { ...state, isFetchingDomains: true };
}

function remapDomain(domain) {
  return {
    ...domain,
    view: false,
    selected: false,
    generalInfo: null,
    settings: null,
    services: [],
    domainContacts: null,
    zoneFile: null,
    addons: null,
    contacts: null,
    isMkDomain:
      domain.domain_name.endsWith(".mk") || domain.domain_name.endsWith(".мкд"),
    register_contacts: [],
    name_servers: {
      list: {},
      default_name_servers: {},
      type: ""
    }
  };
}

function customerDomainsReceive(state, action) {
  // we've fetched an array of domains so map them
  if (
    typeof action.payload.items === "object" &&
    action.payload.items.constructor === Array
  ) {
    return {
      ...state,
      isFetchingDomains: false,
      domains: {
        ...state.domains,
        ...action.payload,
        filters: {
          ...state.domains.filters,
          ...action.payload.filters,
          status:
            Array.isArray(action.payload.filters.status) &&
            action.payload.filters.status.length === 0
              ? ""
              : action.payload.filters.status
        },
        items: action.payload.items.map(current => {
          return remapDomain(current);
        })
      }
    };
  } else {
    // we've fetched a single domain
    // check if we already have it in store first and add / update the domain
    const domain = action.payload;
    const domainExists = state.domains.items.find(
      current => current.id === domain.id
    );

    if (!domainExists) {
      return {
        ...state,
        isFetchingDomains: false,
        domains: {
          ...state.domains,
          items: state.domains.items.concat(remapDomain(domain))
        },
        rowsSelected: 0
      };
    } else {
      return {
        ...state,
        isFetchingDomains: false,
        domains: {
          ...state.domains,
          items: state.domains.items.map(current => {
            if (current.id !== domain.id) return { ...current };
            return {
              ...current,
              ...domain
            };
          }),
          rowsSelected: 0
        }
      };
    }
  }
}

function customerDomainReceive(state, action) {
  const domain = action.payload;
  const domainExists = state.domains.items.find(
    current => current.id === domain.id
  );

  if (!domainExists) {
    return {
      ...state,
      isFetchingDomains: false,
      domains: {
        ...state.domains,
        items: state.domains.items.concat(remapDomain(domain))
      },
      rowsSelected: 0
    };
  } else {
    return {
      ...state,
      isFetchingDomains: false,
      domains: {
        ...state.domains,
        items: state.domains.items.map(current => {
          if (current.id !== domain.id) return { ...current };
          return {
            ...current,
            ...domain
          };
        }),
        rowsSelected: 0
      }
    };
  }
}

function customerDomainNameServersRequest(state, action) {
  return { ...state, isFetchingDomainNameServers: true };
}

function customerDomainNameServersReceive(state, action) {
  const { domain, nameServers } = action.payload;

  return {
    ...state,
    isFetchingDomainNameServers: false,
    domains: {
      ...state.domains,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id) return { ...current };
        return {
          ...current,
          name_servers: nameServers
        };
      }),
      rowsSelected: 0
    }
  };
}

function customerDomainRegisterContactsRequest(state, action) {
  return { ...state, isFetchingDomainRegisterContacts: true };
}

function customerDomainRegisterContactsReceive(state, action) {
  const { domain, contacts } = action.payload;

  return {
    ...state,
    isFetchingDomainRegisterContacts: false,
    domains: {
      ...state.domains,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id) return { ...current };
        return {
          ...current,
          register_contacts: contacts
        };
      }),
      rowsSelected: 0
    }
  };
}

function customerDomainsView(state, action) {
  const domain = action.payload;
  return {
    ...state,
    domains: {
      ...state.domains,
      filters: state.domains.filters || initialState.domains.filters,
      paging: state.domains.paging || initialState.domains.paging,
      sort: state.domains.sort || initialState.domains.sort,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id)
          return {
            ...current,
            view: false
          };
        return {
          ...current,
          view: true
        };
      }),
      rowsSelected: 0
    }
  };
}

function customerEmailView(state, action) {
  const email = action.payload;
  return {
    ...state,
    emails: {
      ...state.domains,
      items: state.emails.items.map(current => {
        if (current.id !== email.id)
          return {
            ...current,
            view: false
          };
        return {
          ...current,
          view: true
        };
      }),
      rowsSelected: 0
    }
  };
}

function customerDomainsSettingsRequest(state, action) {
  return { ...state, isFetchingDomainSettings: true };
}

function customerDomainsSettingsReceive(state, action) {
  const { domain, settings } = action.payload;
  return {
    ...state,
    domains: {
      ...state.domains,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id) return { ...current };
        return {
          ...current,
          settings: {
            ...settings
          }
        };
      }),
      rowsSelected: 0
    },
    isFetchingDomainSettings: false
  };
}

export function customerDomainsServicesRequest(state, action) {
  return { ...state, isFetchingDomainServices: true };
}

function customerDomainsServicesReceive(state, action) {
  const { services, domain } = action.payload;
  return {
    ...state,
    domains: {
      ...state.domains,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id) return { ...current };
        return {
          ...current,
          services: services
        };
      }),
      rowsSelected: 0
    },
    isFetchingDomainServices: false
  };
}

function customerDomainServiceCancellationRequest(state, action) {
  return { ...state, isCancellingDomainService: true };
}

function customerDomainServiceCancellationReceive(state, action) {
  const { service, domain } = action.payload;

  return {
    ...state,
    domains: {
      ...state.domains,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id) {
          return { ...current };
        }
        return {
          ...current,
          services: current.services.map(currentService => {
            if (currentService.id !== service.id) return { ...currentService };
            return {
              ...currentService,
              state: {
                name: "Inactive",
                state: "inactive"
              }
            };
          })
        };
      }),
      rowsSelected: 0
    },
    isCancellingDomainService: false
  };
}

function customerProductServiceCancellationRequest(state, action) {
  return { ...state, isCancellingProductService: true };
}

function customerProductServiceCancellationReceive(state, action) {
  return {
    ...state,
    isCancellingProductService: false
  };
}

function customerProductHostingCPanelDirectLoginLinkRequest(state, action) {
  return { ...state, isGettingHostingCPanelDirectLoginLink: true };
}

function customerProductHostingCPanelDirectLoginLinkReceive(state, action) {
  return {
    ...state,
    isGettingHostingCPanelDirectLoginLink: false
  };
}

function customerProductHostingCPanelPasswordChangeRequest(state, action) {
  return { ...state, isChangingHostingCPanelPassword: true };
}

function customerProductHostingCPanelPasswordChangeReceive(state, action) {
  return {
    ...state,
    isChangingHostingCPanelPassword: false
  };
}

export function customerContactsRequest(state, action) {
  return { ...state, isFetchingContacts: true };
}

export function customerContactsReceive(state, action) {
  const contacts = action.payload;
  return {
    ...state,
    isFetchingContacts: false,
    contacts: contacts.map(contact => {
      return {
        ...contact,
        view: false,
        selected: false
      };
    })
  };
}

function customerContactsViewContact(state, action) {
  return {
    ...state,
    contacts: state.contacts.map(contact => {
      if (contact.id !== action.payload.id) {
        return {
          ...contact,
          view: false
        };
      }
      return {
        ...contact,
        view: true
      };
    })
  };
}

function customerContactsSelectContact(state, action) {
  return {
    ...state,
    contacts: state.contacts.map(contact => {
      if (contact.id !== action.payload.id) {
        return contact;
      }
      return {
        ...contact,
        selected: !contact.selected
      };
    })
  };
}

function customerDomainNameserversUpdate(state, action) {
  const { domain, nameservers, type } = action.payload;

  return {
    ...state,
    domains: {
      ...state.domains,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id) return { ...current };
        return {
          ...current,
          ...domain,
          name_servers: {
            ...current.name_servers,
            list: nameservers,
            type: type
          }
        };
      })
    }
  };
}

function customerContactsDomainContactChange(state, action) {
  const { domain, contact } = action.payload;
  return {
    ...state,
    domains: {
      ...state.domains,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id) return { ...current };
        return {
          ...domain,
          register_contacts: domain.register_contacts.map(current => {
            if (current.type !== contact.type) return { ...current };
            return {
              ...current,
              ...contact
            };
          })
        };
      })
    }
  };
}

function customerDomainsRequestFilterStatusCollection(state, action) {
  return { ...state, isFetchingDomainStatusFilters: true };
}

function customerDomainsReceiveFilterStatusCollection(state, action) {
  return {
    ...state,
    domainsListFilters: {
      ...state.domainsListFilters,
      statuses:
        Array.isArray(action.payload) && action.payload.length === 0
          ? ""
          : action.payload
    },
    isFetchingDomainStatusFilters: false
  };
}

function customerDomainsRequestFilterExtensionsCollection(state, action) {
  return { ...state, isFetchingDomainExtensionsFilters: true };
}

function customerDomainsReceiveFilterExtensionsCollection(state, action) {
  return {
    ...state,
    domainsListFilters: {
      ...state.domainsListFilters,
      extensions: action.payload
    },
    isFetchingDomainExtensionsFilters: false
  };
}

function customerDomainsRequestFilterRegisterContactsCollection(state, action) {
  return { ...state, isFetchingDomainRegisterContactsFilter: true };
}

function customerDomainsReceiveFilterRegisterContactsCollection(state, action) {
  return {
    ...state,
    domainsListFilters: {
      ...state.domainsListFilters,
      registerContacts: action.payload
    },
    isFetchingDomainRegisterContactsFilter: false
  };
}

function customerDomainsListSelectTableRow(state, action) {
  return {
    ...state,
    domains: {
      ...state.domains,
      items: state.domains.items.map(domain => {
        return domain.id === action.payload.id
          ? { ...domain, selected: true }
          : domain;
      })
    }
  };
}

function customerDomainsListDeselectTableRow(state, action) {
  return {
    ...state,
    domains: {
      ...state.domains,
      items: state.domains.items.map(domain => {
        return domain.id === action.payload.id
          ? { ...domain, selected: false }
          : domain;
      })
    }
  };
}

function customerDomainsListSelectAllTableRows(state, action) {
  return {
    ...state,
    domains: {
      ...state.domains,
      items: state.domains.items.map(domain => {
        return {
          ...domain,
          selected: domain.status.code !== DOMAIN_STATUS_PENDING ? true : false
        };
      })
    }
  };
}

function customerDomainsListDeselectAllTableRows(state, action) {
  return {
    ...state,
    domains: {
      ...state.domains,
      items: state.domains.items.map(domain => {
        return { ...domain, selected: false };
      })
    }
  };
}

function customerProductsListSelectTableRow(state, action) {
  return {
    ...state,
    products: {
      ...state.products,
      items: state.products.items.map(product => {
        return product.id === action.payload.id
          ? { ...product, selected: true }
          : product;
      })
    }
  };
}

function customerProductsListDeselectTableRow(state, action) {
  return {
    ...state,
    products: {
      ...state.products,
      items: state.products.items.map(product => {
        return product.id === action.payload.id
          ? { ...product, selected: false }
          : product;
      })
    }
  };
}

function customerProductsListSelectAllTableRows(state, action) {
  return {
    ...state,
    products: {
      ...state.products,
      items: state.products.items.map(product => {
        return { ...product, selected: true };
      })
    }
  };
}

function customerProductsListDeselectAllTableRows(state, action) {
  return {
    ...state,
    products: {
      ...state.products,
      items: state.products.items.map(product => {
        return { ...product, selected: false };
      })
    }
  };
}

function customerProductsRequestFilterStatusCollection(state, action) {
  return state;
}

function customerProductsReceiveFilterStatusCollection(state, action) {
  return {
    ...state,
    productsListFilters: {
      ...state.productsListFilters,
      statuses: action.payload
    }
  };
}

function customerServersRequestFilterStatusCollection(state, action) {
  return state;
}

function customerServersReceiveFilterStatusCollection(state, action) {
  return {
    ...state,
    serversListFilters: {
      ...state.serversListFilters,
      statuses: action.payload
    }
  };
}

function customerProductsRequestFilterProductsCollection(state, action) {
  return state;
}

function customerProductsReceiveFilterProductsCollection(state, action) {
  return {
    ...state,
    productsListFilters: {
      ...state.productsListFilters,
      products: action.payload
    }
  };
}

function resetCustomerPersonalInformation(state, action) {
  return {
    ...initialState
  };
}

function customerDomainsWithoutHostingRequest(state, action) {
  return { ...state };
}

function customerDomainsWithoutHostingReceive(state, action) {
  return { ...state, domainsWithoutHosting: action.payload };
}

function resetState(state) {
  return { ...initialState, isFetchingCustomerPersonalInformation: false };
}

export function getCustomerPersonalInformation(state) {
  return state.customer.personalInformation;
}

export function getCustomerPayersForFilter(state) {
  return state.customer.payersForFilter;
}

export function getDomainsListFilters(state) {
  return state.customer.domainsListFilters;
}

export function getProductsListFilters(state) {
  return state.customer.productsListFilters;
}

export function getServersListFilters(state) {
  return state.customer.serversListFilters;
}

function customerDomainZoneFileRequest(state) {
  return {
    ...state,
    isFetchingDomainZoneFile: true
  };
}

function customerDomainZoneFileReceive(state, action) {
  const { domain, zoneFile } = action.payload;
  return {
    ...state,
    isFetchingDomainZoneFile: false,
    domains: {
      ...state.domains,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id) return { ...current };
        return {
          ...current,
          ...domain,
          zoneFile: zoneFile ? { ...zoneFile } : null
        };
      })
    }
  };
}

function customerDomainZoneFileDelete(state, action) {
  const { domain } = action.payload;
  return {
    ...state,
    isFetchingDomainZoneFile: false,
    domains: {
      ...state.domains,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id) return { ...current };
        return {
          ...current,
          ...domain,
          zoneFile: null
        };
      })
    }
  };
}

function customerDomainDnsRecordUpdateType(state, action) {
  const { domain, record, type } = action.payload;
  return {
    ...state,
    domains: {
      ...state.domains,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id) return { ...current };
        return {
          ...current,
          ...domain,
          zoneFile: {
            ...domain.zoneFile,
            records: domain.zoneFile.records.map(currentRecord => {
              if (currentRecord.line !== record.line)
                return { ...currentRecord };
              return {
                ...currentRecord,
                type
              };
            })
          }
        };
      })
    }
  };
}

function customerDomainDnsRecordUpdateField(state, action) {
  const { domain, record, field, value } = action.payload;
  return {
    ...state,
    domains: {
      ...state.domains,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id) return { ...current };
        return {
          ...current,
          ...domain,
          zoneFile: {
            ...domain.zoneFile,
            records: domain.zoneFile.records.map(currentRecord => {
              if (currentRecord.line !== record.line) return currentRecord;
              return {
                ...currentRecord,
                [field]: value
              };
            })
          }
        };
      })
    }
  };
}

function customerDomainDnsRecordUpdateAllFields(state, action) {
  const { domain, record, fields } = action.payload;
  return {
    ...state,
    domains: {
      ...state.domains,
      items: state.domains.items.map(current => {
        if (current.id !== domain.id) return current;
        return {
          ...current,
          ...domain,
          zoneFile: {
            ...domain.zoneFile,
            records: domain.zoneFile.records.map(currentRecord => {
              if (currentRecord.line !== record.line) return currentRecord;
              return {
                ...currentRecord,
                ...fields
              };
            })
          }
        };
      })
    }
  };
}

function customerDomainRedirectionAvailableRequest(state) {
  return {
    ...state,
    isFetchingDomainRedirectionAvailable: true,
    isDomainRedirectionAvailable: false,
    domainRedirectionReason: ""
  };
}
function customerDomainRedirectionAvailableReceive(state, action) {
  return {
    ...state,
    isFetchingDomainRedirectionAvailable: false,
    isDomainRedirectionAvailable: action.payload.redirection_available,
    domainRedirectionReason: action.payload.reason
  };
}

function customerDomainRedirectionRequest(state) {
  return {
    ...state,
    isFetchingDomainRedirection: true,
    domainRedirection: null
  };
}

function customerDomainRedirectionReceive(state, action) {
  return {
    ...state,
    isFetchingDomainRedirection: false,
    domainRedirection: action.payload
  };
}

function customerDomainRedirectionUpdateRequest(state) {
  return {
    ...state,
    isUpdatingDomainRedirection: true
  };
}

function customerDomainRedirectionUpdateReceive(state, action) {
  return {
    ...state,
    isUpdatingDomainRedirection: false,
    domainRedirection: action.payload
  };
}

function customerDomainRedirectionRemoveRequest(state) {
  return {
    ...state,
    isUpdatingDomainRedirection: true
  };
}

function customerDomainRedirectionRemoveReceive(state) {
  return {
    ...state,
    isUpdatingDomainRedirection: false,
    domainRedirection: ""
  };
}

// this is a selector
export function getCustomerProducts(state) {
  return state.customer.products;
}

export function getCustomerServers(state) {
  return state.customer.servers;
}

export function getCustomerDomains(state) {
  return state.customer.domains;
}

export function getDomainToView(state) {
  return state.customer.domains.items.find(domain => domain.view);
}

export function getEmailToView(state) {
  return state.customer.emails.items.find(email => email.view);
}

export function getDomainSettings(state, domain) {
  const foundDomain = state.customer.domains.items.find(
    current => current.id === domain.id
  );
  return foundDomain.settings;
}

export function getDomainServices(state, domain) {
  const foundDomain = state.customer.domains.items.find(
    current => current.id === domain.id
  );
  if (foundDomain) {
    return foundDomain.services;
  }
  return false;
}

export function getContactToView(state) {
  return state.customer.contacts.find(contact => contact.view);
}

export function getCustomerContacts(state) {
  return state.customer.contacts;
}

export function isFetchingProducts(state) {
  return state.customer.isFetchingProducts;
}

export function isFetchingServers(state) {
  return state.customer.isFetchingProducts;
}

export function isFetchingDomains(state) {
  return state.customer.isFetchingDomains;
}

export function isFetchingDomainFilters(state) {
  return state.customer.isFetchingDomainFilters;
}

export function isFetchingDomainStatusFilters(state) {
  return state.customer.isFetchingDomainStatusFilters;
}

export function isFetchingDomainExtensionsFilters(state) {
  return state.customer.isFetchingDomainExtensionsFilters;
}

export function isFetchingDomainRegisterContactsFilter(state) {
  return state.customer.isFetchingDomainRegisterContactsFilter;
}

export function isFetchingDomainZoneFile(state) {
  return state.customer.isFetchingDomainZoneFile;
}

export function isFetchingContacts(state) {
  return state.customer.isFetchingContacts;
}

export function isUpdatingCustomerPersonalInformation(state) {
  return state.customer.isUpdatingCustomerPersonalInformation;
}

export function getDomainListSelectedRowsCount(state) {
  const rows = state.customer.domains.items.filter(item => item.selected);
  return rows ? rows.length : 0;
}

export function getProductListSelectedRowsCount(state) {
  const rows = state.customer.products.items.filter(item => item.selected);
  return rows ? rows.length : 0;
}

export function isFetchingEppTransferCode(state) {
  return state.customer.isFetchingEppTransferCode;
}

export function isFetchingDomainServices(state) {
  return state.customer.isFetchingDomainServices;
}

export function isFetchingProductServices(state) {
  return state.customer.isFetchingProductServices;
}

export function isFetchingAvailableBackups(state) {
  return state.customer.isFetchingAvailableBackups;
}

export function isFetchingBackupHistory(state) {
  return state.customer.isFetchingBackupHistory;
}

export function isBackupRequest(state) {
  return state.customer.isBackupRequest;
}


export function isFetchingServerServices(state) {
  return state.customer.isFetchingServerServices;
}
export function isCancellingDomainService(state) {
  return state.customer.isCancellingDomainService;
}

export function isCancellingProductService(state) {
  return state.customer.isCancellingProductService;
}

export function isGettingHostingCPanelDirectLoginLink(state) {
  return state.customer.isGettingHostingCPanelDirectLoginLink;
}

export function isChangingHostingCPanelPassword(state) {
  return state.customer.isChangingHostingCPanelPassword;
}

export function isFetchingCustomerPersonalInformation(state) {
  return state.customer.isFetchingCustomerPersonalInformation;
}

export function isCustomerPersonalInformationComplete(state) {
  return state.customer.isCustomerPersonalInformationComplete;
}

export function isFetchingEmails(state) {
  return state.customer.isFetchingEmails;
}

export function getCustomerEmails(state) {
  return state.customer.emails;
}

export function getCustomerCurrencyCode(state) {
  return state.customer.personalInformation
    ? state.customer.personalInformation.currency_code
    : null;
}

export function getDomainsWithoutHosting(state) {
  return state.customer.domainsWithoutHosting;
}

export function isFetchingDomainNameServers(state) {
  return state.customer.isFetchingDomainNameServers;
}

export function isFetchingDomainRegisterContacts(state) {
  return state.customer.isFetchingDomainRegisterContacts;
}

export function isFetchingDomainRedirectionAvailable(state) {
  return state.customer.isFetchingDomainRedirectionAvailable;
}

export function isDomainRedirectionAvailable(state) {
  return state.customer.isDomainRedirectionAvailable;
}

export function getDomainRedirectionReason(state) {
  return state.customer.domainRedirectionReason;
}

export function isFetchingDomainRedirection(state) {
  return state.customer.isFetchingDomainRedirection;
}

export function getDomainRedirection(state) {
  return state.customer.domainRedirection;
}

export function isUpdatingDomainRedirection(state) {
  return state.customer.isUpdatingDomainRedirection;
}

export function isFetchingDomainSettings(state) {
  return state.customer.isFetchingDomainSettings;
}
