import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

import Icon from "../Icon";

const StyledAvatar = styled.div`
  display: inline-block;

  max-width: 48px;
  max-height: 48px;
  width: 48px;
  height: 48px;
  padding: ${props => (props.hasImage ? "0px" : "12px")};
  text-align: center;
  vertical-align: top;
  border-radius: 50%;
  margin-right: 5px;
  background-color: ${props => (props.admin ? theme.green1 : theme.neutral1)};

  & .icon {
    left: -3px;
    color: ${theme.neutral4};
  }

  & img {
    border-radius: 50%;
  }
`;

const Avatar = ({ image, admin }) => (
  <StyledAvatar className="user-avatar" admin={admin} hasImage={!!image}>
    {image && <img className="img-fluid" src={image} alt="" />}
    {!image && <Icon size="l" icon="user" />}
  </StyledAvatar>
);

Avatar.defaultProps = {
  image: null
};

export default Avatar;
