import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import Filter from "../../../../components/Filter";
import DateRangePicker from "../../../../components/DateRangePicker";
import Container from "../../../../components/Containers/Container";
import Form from "../../../../components/Forms/Form";
import SelectBox from "../../../../components/SelectBox";
import { fetchProInvoices } from "../../ProInvoices/actions/proInvoicesActions";

const StyledProInvoicesFilterContainer = styled.div`
  margin-bottom: ${props => props.isMobile && "15px"};
`;

class ProInvoicesFilter extends React.Component {
  onStatusFilterOptionChange = selectedOptionValue => {
    this.props.fetchProInvoices(this.props.paging.limit, 1, this.props.sort, {
      ...this.props.filters,
      status: selectedOptionValue.value
    });
  };

  onClear = () => {
    this.props.fetchProInvoices(this.props.paging.limit, 1, this.props.sort, {
      keyword: this.props.filters.keyword
    });
  };

  dateCreatedRangeSelected = (startDate, endDate) => {
    this.props.fetchProInvoices(this.props.paging.limit, 1, this.props.sort, {
      ...this.props.filters,
      date_created_range:
        startDate.format("YYYY-MM-DD") + "." + endDate.format("YYYY-MM-DD")
    });
  };

  dueDateRangeSelected = (startDate, endDate) => {
    this.props.fetchProInvoices(this.props.paging.limit, 1, this.props.sort, {
      ...this.props.filters,
      date_due_range:
        startDate.format("YYYY-MM-DD") + "." + endDate.format("YYYY-MM-DD")
    });
  };

  paidOnDateRangeSelected = (startDate, endDate) => {
    this.props.fetchProInvoices(this.props.paging.limit, 1, this.props.sort, {
      ...this.props.filters,
      date_paid_on_range:
        startDate.format("YYYY-MM-DD") + "." + endDate.format("YYYY-MM-DD")
    });
  };

  render() {
    const [
      startDateCreatedInitial,
      endDateCreatedInitial
    ] = this.props.filters.date_created_range.split(".");

    const [
      startDueDateInitial,
      endDueDateInitial
    ] = this.props.filters.date_due_range.split(".");

    const [
      startPaidOnDateInitial,
      endPaidOnDateInitial
    ] = this.props.filters.date_paid_on_range.split(".");

    let selectedPayer =
      this.props.payersForFilter &&
      this.props.payersForFilter.filter(
        payer => payer.code === this.props.filters.payer
      )[0];

    return (
      <StyledProInvoicesFilterContainer isMobile={isMobileOnly}>
        <Translate>
          {({ translate }) => (
            <Container>
              <Filter
                title={translate("pro-invoices.filter.title")}
                clearTitle={translate("pro-invoices.filter.clear-all")}
                count={this.props.countFiltersApplied()}
                onClear={this.onClear}
              >
                <Form>
                  <SelectBox
                    name="contact"
                    label={translate("filters.billing-contact")}
                    placeholder={translate(
                      "filters.billing-contact-placeholder"
                    )}
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue &&
                      translate("pro-invoices.table.no-contact-options")
                    }
                    async={true}
                    loadOptions={this.props.filterPayerOptions}
                    defaultOptions
                    isSearchable={false}
                    input={{
                      onChange: this.props.onPayerFilterOptionChange
                    }}
                    value={
                      selectedPayer
                        ? {
                            value: selectedPayer.code,
                            label: selectedPayer.name
                          }
                        : false
                    }
                  />

                  <SelectBox
                    name="status"
                    label={translate("filters.status")}
                    placeholder={translate("filters.status-placeholder")}
                    value={
                      this.props.filters.status !== ""
                        ? {
                            value: this.props.filters.status,
                            label: translate(
                              "pro-invoices.table.filter-statuses." +
                                this.props.filters.status
                            )
                          }
                        : false
                    }
                    options={this.props.statusCollection.map(status => {
                      return {
                        value: status.code,
                        label: translate(
                          "pro-invoices.table.filter-statuses." + status.code
                        )
                      };
                    })}
                    input={{ onChange: this.onStatusFilterOptionChange }}
                  />

                  <DateRangePicker
                    name="date-created"
                    label={translate("filters.date-created-range")}
                    dateRangeSelected={this.dateCreatedRangeSelected}
                    initialStartDate={startDateCreatedInitial}
                    initialEndDate={endDateCreatedInitial}
                    startDatePlaceholderText={translate("filters.start-date")}
                    endDatePlaceholderText={translate("filters.end-date")}
                  />

                  <DateRangePicker
                    name="due-date"
                    label={translate("filters.due-date-range")}
                    dateRangeSelected={this.dueDateRangeSelected}
                    initialStartDate={startDueDateInitial}
                    initialEndDate={endDueDateInitial}
                    startDatePlaceholderText={translate("filters.start-date")}
                    endDatePlaceholderText={translate("filters.end-date")}
                  />

                  <DateRangePicker
                    name="paid-on-date"
                    label={translate("filters.paid-on-date-range")}
                    dateRangeSelected={this.paidOnDateRangeSelected}
                    initialStartDate={startPaidOnDateInitial}
                    initialEndDate={endPaidOnDateInitial}
                    startDatePlaceholderText={translate("filters.start-date")}
                    endDatePlaceholderText={translate("filters.end-date")}
                  />
                </Form>
              </Filter>
            </Container>
          )}
        </Translate>
      </StyledProInvoicesFilterContainer>
    );
  }
}

ProInvoicesFilter.defaultProps = {
  count: 0,
  onClear: () => {},
  statusCollection: []
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProInvoices
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(ProInvoicesFilter);
