import * as IA from "../actions/invoicesActions";

import { APP_STATE_COMPLETE_RESET } from "../../../AppState/actions/appStateActions";

import handleReducerActions from "../../../../core/handleReducerActions";
import { INVOICE_UNPAID } from "../InvoicesStatus/InvoicesStatus";

const initialState = {
  all: [],
  paging: {
    limit: 10,
    current_page: 0,
    next_page: 0,
    previous_page: 0,
    total_pages: 0,
    total: 0
  },
  filters: {
    keyword: "",
    date_range: "",
    payer: ""
  },
  sort: {
    sort_by: "",
    order_by: ""
  },
  statusCollection: [],
  isFetching: false,
  isFetchingFilters: false,
  invoicesForWidget: [],
  isFetchingInvoicesForWidget: false,
  isPerformingMasspay: false,
  isFetchingInvoicesSettings: false,
  settings: {
    digitally_signed_invoices: false,
    digital_invoices_available: false
  }
};

const invoicesStateHandlers = {
  [IA.INVOICES_CLEAR_FILTERS]: invoicesClearFitlers,
  [IA.INVOICES_REQUEST]: invoicesRequest,
  [IA.INVOICES_REQUEST_STATUS_COLLECTION]: invoicesRequestStatusCollection,
  [IA.INVOICES_RECEIVE_STATUS_COLLECTION]: invoicesReceiveStatusCollection,
  [IA.INVOICES_RECEIVE]: invoicesReceive,
  [IA.INVOICES_UPDATE_INVOICE_PAYER]: invoicesUpdateInvoicePayer,
  [IA.INVOICES_LIST_SELECT_TABLE_ROW]: invoicesListSelectTableRow,
  [IA.INVOICES_LIST_DESELECT_TABLE_ROW]: invoicesListDeselectTableRow,
  [IA.INVOICES_LIST_SELECT_ALL_TABLE_ROWS]: invoicesListSelectAllTableRows,
  [IA.INVOICES_LIST_DESELECT_ALL_TABLE_ROWS]: invoicesListDeselectAllTableRows,
  [IA.INVOICES_FOR_WIDGET_REQUEST]: invoicesForWidgetRequest,
  [IA.INVOICES_FOR_WIDGET_RECEIVE]: invoicesForWidgetReceive,
  [IA.INVOICES_MASS_PAY_REQUEST]: invoicesRequestMassPay,
  [IA.INVOICES_MASS_PAY_RECEIVE]: invoicesReceiveMassPay,
  [IA.INVOICES_DIGITAL_INVOICES_SETTINGS_REQUEST]: invoicesDigitalInvoicesRequest,
  [IA.INVOICES_DIGITAL_INVOICES_SETTINGS_RECEIVE]: invoicesDigitalInvoicesReceive,
  [APP_STATE_COMPLETE_RESET]: resetState
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(invoicesStateHandlers, state, action);
}

function invoicesForWidgetRequest(state, action) {
  return {
    ...state,
    isFetchingInvoicesForWidget: true
  };
}

function invoicesForWidgetReceive(state, action) {
  return {
    ...state,
    isFetchingInvoicesForWidget: false,
    invoicesForWidget: action.payload
  };
}

function invoicesRequestMassPay(state, action) {
  return {
    ...state,
    isPerformingMasspay: true
  };
}

function invoicesReceiveMassPay(state, action) {
  return {
    ...state,
    isPerformingMasspay: false
  };
}

function invoicesRequest(state, action) {
  return {
    ...state,
    isFetching: true
  };
}

function invoicesClearFitlers(state) {
  return {
    ...state,
    filters: {
      keyword: "",
      date_range: "",
      payer: ""
    }
  };
}

function invoicesRequestStatusCollection(state, action) {
  return {
    ...state,
    isFetchingFilters: false
  };
}

function invoicesReceive(state, action) {
  return {
    ...state,
    all: action.payload.invoices,
    paging: action.payload.paging,
    filters: action.payload.filters,
    sort: action.payload.sort,
    isFetching: false
  };
}

function invoicesReceiveStatusCollection(state, action) {
  return {
    ...state,
    statusCollection: action.payload.statuses,
    isFetchingFilters: false
  };
}

function invoicesUpdateInvoicePayer(state, action) {
  const { invoice, payer } = action.payload;
  return {
    ...state,
    all: state.all.map(currentInvoice => {
      if (invoice.id !== currentInvoice.id) return { ...currentInvoice };
      return {
        ...currentInvoice,
        invoice_to: {
          ...payer
        }
      };
    })
  };
}

function invoicesListSelectTableRow(state, action) {
  return {
    ...state,
    all: state.all.map(invoice => {
      return invoice.id === action.payload.id
        ? { ...invoice, selected: true }
        : invoice;
    })
  };
}

function invoicesListDeselectTableRow(state, action) {
  return {
    ...state,
    all: state.all.map(invoice => {
      return invoice.id === action.payload.id
        ? { ...invoice, selected: false }
        : invoice;
    })
  };
}

function invoicesListSelectAllTableRows(state, action) {
  return {
    ...state,
    all: state.all.map(invoice => {
      return { ...invoice, selected: true };
    })
  };
}

function invoicesListDeselectAllTableRows(state, action) {
  return {
    ...state,
    all: state.all.map(invoice => {
      return { ...invoice, selected: false };
    })
  };
}

function invoicesDigitalInvoicesRequest(state) {
  return {
    ...state,
    isFetchingInvoicesSettings: true
  };
}

function invoicesDigitalInvoicesReceive(state, action) {
  return {
    ...state,
    isFetchingInvoicesSettings: false,
    settings: {
      ...state.settings,
      ...action.payload
    }
  };
}

function resetState(state) {
  return { ...initialState };
}

// this is a selector
export function getAllInvoices(state) {
  return state.invoices.all;
}

export function getInvoicesPaging(state) {
  return state.invoices.paging;
}

export function getInvoicesSort(state) {
  return state.invoices.sort;
}

export function getInvoicesFilters(state) {
  return state.invoices.filters;
}

export function getInvoicesStatusCollection(state) {
  return state.invoices.statusCollection;
}

export function isFetchingInvoices(state) {
  return state.invoices.isFetching;
}

export function isFetchingInvoicesFilters(state) {
  return state.invoices.isFetchingFilters;
}

export function getInvoicesListSelectedRowsCount(state) {
  const rows = state.invoices.all.filter(item => item.selected);
  return rows ? rows.length : 0;
}

export function getInvoicesSelectedRowsIds(state) {
  const rows = state.invoices.all.filter(item => item.selected);

  return rows.map(row => row.id);
}

export function getAllUnpaidIds(state) {
  const rows = state.invoices.all.filter(
    item => item.status.code === INVOICE_UNPAID
  );

  return rows.map(row => row.id);
}

export function getInvoicesForWidget(state) {
  return state.invoices.invoicesForWidget;
}

export function isFetchingInvoicesForWidget(state) {
  return state.invoices.isFetchingInvoicesForWidget;
}

export function isPerformingMasspay(state) {
  return state.invoices.isPerformingMasspay;
}

export function isFetchingInvoicesSettings(state) {
  return state.invoices.isFetchingInvoicesSettings;
}

export function getInvoicesSettings(state) {
  return state.invoices.settings;
}
