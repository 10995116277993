import styled from "styled-components";
import { theme } from "../../../design/Themes";

import StatusText from "../StatusText";

const StatusTextSuccess = styled(StatusText)`
  color: ${theme.green2};
`;

export default StatusTextSuccess;
