import axios from "axios";
import Cookies from "universal-cookie";

import {
  ROOT_DOMAIN_NO_HTTPS,
  AUTH_API_ENDPOINT,
  CUSTOMER_REGISTER,
  CUSTOMER_UPDATE_REGISTER,
  FORGOT_PASSWORD_API_ENDPOINT,
  RESET_PASSWORD_API_ENDPOINT,
  VALIDATE_RESET_TOKEN_API_ENDPOINT
} from "./endpoints";

const jwtTokenName = "mkhostToken";

export function login(email, password, recaptcha = null) {
  return axios.post(AUTH_API_ENDPOINT, {
    username: email,
    password,
    recaptcha
  });
}

export function register(email, password, cart, recaptcha) {
  return axios.post(CUSTOMER_REGISTER, {
    email,
    password,
    cart,
    recaptcha
  });
}

export function updateRegisterDetails(details) {
  return axios.put(CUSTOMER_UPDATE_REGISTER, {
    ...details
  });
}

export function forgotPassword(email, recaptcha) {
  return axios.post(FORGOT_PASSWORD_API_ENDPOINT, { email, recaptcha });
}

export function resetPassword(token, password, confirm_password, recaptcha) {
  return axios.post(RESET_PASSWORD_API_ENDPOINT, {
    token,
    password,
    retypePassword: confirm_password,
    recaptcha
  });
}

export function validateResetToken(token) {
  return axios.post(VALIDATE_RESET_TOKEN_API_ENDPOINT, { token });
}

//
//export function refreshToken(token) {
//  return axios.get(REFRESH_TOKEN_API_ENDPOINT, {
//    params: {
//      refresh_token: token
//    }
//  });
//}

export function getToken() {
  const cookies = new Cookies();
  try {
    let token = localStorage.getItem(jwtTokenName);
    if (!token) {
      token = cookies.get(jwtTokenName);
    }
    if (!token || token === undefined || token === "undefined") return null;
    return token;
  } catch (e) {
    //@todo log out user if we can't read the token?
    return null;
  }
}

export function getRefreshToken() {
  try {
    const tokens = localStorage.getItem(jwtTokenName);
    return tokens.refresh_token;
  } catch (e) {
    //@todo log out user if we can't read the token?
    return null;
  }
}

export function setToken(token) {
  const cookies = new Cookies();
  try {
    localStorage.setItem(jwtTokenName, token);
    cookies.set(jwtTokenName, token, {
      path: "/",
      domain: "." + ROOT_DOMAIN_NO_HTTPS
    });
  } catch (e) {
    //@todo use sessionstorage if in incognito mode?
    console.log(e);
  }
  setAuthorizationToken(token);
}

export function removeToken() {
  const cookies = new Cookies();
  try {
    localStorage.removeItem(jwtTokenName);
    cookies.remove(jwtTokenName, {
      path: "/",
      domain: "." + ROOT_DOMAIN_NO_HTTPS
    });
  } catch (e) {
    //@todo use sessionstorage if in incognito mode?
    console.log(e);
  }
}

export function setAuthorizationToken(token) {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
}
