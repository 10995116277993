import React from "react";
import { Translate } from "react-localize-redux";
import { Field, Form, FormSpy } from "react-final-form";
import styled from "styled-components";

import InputField from "../../InputField";
import SecondaryButton from "../../Buttons/SecondaryButton";
import Icon from "../../Icon";

import Panel from "../../Panel";

const StyledButton = styled(SecondaryButton)`
  margin-top: 25px;
`;

const PurchaseCredit = ({
  title,
  description,
  currency,
  onPurchaseCredit,
  addFundsMinimum,
  validatePurchaseCredit
}) => (
  <Translate>
    {({ translate }) => (
      <Form
        onSubmit={e => {
          e.preventDefault();
        }}
        validate={validatePurchaseCredit}
        render={({ handleSubmit, values, invalid }) => {
          return (
            <form
              id="purchase-credit-form"
              onSubmit={e => {
                e.preventDefault();
              }}
            >
              <Panel title={title} description={description}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-6">
                    <Field
                      component={InputField}
                      label={translate(
                        "my-account.general-info.purchase-credit.credit-to-purchase"
                      )}
                      placeholder={translate(
                        "my-account.general-info.purchase-credit.enter-amount"
                      )}
                      name="amount"
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-6 text-right">
                    <StyledButton
                      onClick={e => onPurchaseCredit(values)}
                      disabled={invalid || !values.amount || values.amount <= 0}
                    >
                      <Icon icon="cart" />{" "}
                      {translate(
                        "my-account.general-info.purchase-credit.purchase"
                      )}
                    </StyledButton>
                  </div>
                </div>
              </Panel>
              <FormSpy
                subscription={{ submitting: true, invalid: true }}
                onChange={formstate => {}}
              />
            </form>
          );
        }}
      />
    )}
  </Translate>
);

PurchaseCredit.defaultProps = {
  amount: "0.00",
  currency: "MKD"
};

export default PurchaseCredit;
