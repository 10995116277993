import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { isMobile } from "react-device-detect";
import isLaptop from "../../../../../utils/isLaptopScreen";
import withSizes from "react-sizes";
import { Translate } from "react-localize-redux";

import AmountBadge from "../../../../../components/AmountBadge";
import Icon from "../../../../../components/Icon";

import {
  PAYMENT_STATUS_UNPAID,
  PAYMENT_STATUS_PAID
} from "../../../../PaymentMethods/PaymentStatus";

const StyledProInvoicePaymentDetailsWrapper = styled.div`
  margin-left: ${props =>
    props.isMobile || props.isLaptop ? "-15px" : "-30px"};
  margin-right: ${props =>
    props.isMobile || props.isLaptop ? "-15px" : "-30px"};
  margin-top: ${props => (props.isMobile || props.isLaptop ? "15px" : "30px")};
  margin-bottom: ${props =>
    props.isMobile || props.isLaptop ? "15px" : "30px"};

  background-color: ${theme.neutral1};
  border-top: 1px solid ${theme.neutral3};
  border-bottom: 1px solid ${theme.neutral3};
`;

const StyledProInvoicePaymentDetails = styled.div`
  display: block;
  width: 100%;
  margin-left: 0;
  margin-right: 15px;
`;
const StyledProInvoicePaymentDetailsBlock = styled.div`
  display: ${props =>
    props.isMobile || props.isLaptop ? "block" : "inline-block"};
  padding: ${props => (props.isMobile || props.isLaptop ? "15px" : "30px")};
  width: ${props => (props.isMobile || props.isLaptop ? "100%" : "20%")};
  border-right: 1px solid;
  border-right-color: ${props =>
    props.isMobile || props.isLaptop ? "transparent" : props.theme.neutral3};
  &:last-child {
    border-right-color: transparent;
  }
  margin-bottom: 10px;
`;

const StyledTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${theme.neutral5};
  display: ${props =>
    props.isMobile || props.isLaptop ? "block" : "inline-block"};
  width: ${props => (props.isMobile || props.isLaptop ? "50%" : "100%")};
  float: ${props => (props.isMobile || props.isLaptop ? "left" : "none")};
`;
const StyledValue = styled.div`
  font-size: 16px;
  margin-bottom: 0;
  & strong {
    font-weight: 600;
    color: ${theme.neutralBase};
  }

  display: ${props =>
    props.isMobile || props.isLaptop ? "block" : "inline-block"};
  width: ${props => (props.isMobile || props.isLaptop ? "49%" : "100%")};
  float: ${props => (props.isMobile || props.isLaptop ? "left" : "none")};
  text-align: ${props => (props.isMobile || props.isLaptop ? "right" : "left")};

  & .icon-paid {
    color: ${theme.greenBase};
  }

  & .icon-unpaid {
    color: ${theme.redBase};
  }

  & .icon-info {
    color: ${theme.blueBase};
  }
`;

const ProInvoicePaymentDetails = ({
  amountDue,
  dateCreated,
  dueDate,
  invoiceNumber,
  transactionId,
  currency,
  isLaptop,
  status
}) => {
  let proInvoiceMessageIcon = "";
  switch (status.code) {
    case PAYMENT_STATUS_PAID:
      proInvoiceMessageIcon = "paid";
      break;

    case PAYMENT_STATUS_UNPAID:
      proInvoiceMessageIcon = "unpaid";
      break;

    default:
      proInvoiceMessageIcon = "info";
  }

  return (
    <Translate>
      {({ translate }) => (
        <StyledProInvoicePaymentDetailsWrapper
          className="row"
          isMobile={isMobile}
        >
          <div className="col-12 col-sm-12 col-md-12">
            <StyledProInvoicePaymentDetails className="row">
              <StyledProInvoicePaymentDetailsBlock
                isMobile={isMobile}
                isLaptop={isLaptop}
              >
                <StyledTitle isMobile={isMobile} isLaptop={isLaptop}>
                  {translate("pro-invoices.view.amount-due")}
                </StyledTitle>
                <StyledValue isMobile={isMobile} isLaptop={isLaptop}>
                  <AmountBadge
                    amount={amountDue}
                    currency={currency}
                    className={
                      isMobile || isLaptop ? "text-right" : "text-left"
                    }
                  />
                </StyledValue>
              </StyledProInvoicePaymentDetailsBlock>
              <StyledProInvoicePaymentDetailsBlock
                isMobile={isMobile}
                isLaptop={isLaptop}
              >
                <StyledTitle isMobile={isMobile} isLaptop={isLaptop}>
                  {translate("pro-invoices.view.date-created")}
                </StyledTitle>
                <StyledValue isMobile={isMobile} isLaptop={isLaptop}>
                  <strong>{dateCreated}</strong>
                </StyledValue>
              </StyledProInvoicePaymentDetailsBlock>
              <StyledProInvoicePaymentDetailsBlock
                isMobile={isMobile}
                isLaptop={isLaptop}
              >
                <StyledTitle isMobile={isMobile} isLaptop={isLaptop}>
                  {translate("pro-invoices.view.due-date")}
                </StyledTitle>
                <StyledValue isMobile={isMobile} isLaptop={isLaptop}>
                  <strong>{dueDate}</strong>
                </StyledValue>
              </StyledProInvoicePaymentDetailsBlock>
              <StyledProInvoicePaymentDetailsBlock
                isMobile={isMobile}
                isLaptop={isLaptop}
              >
                <StyledTitle isMobile={isMobile} isLaptop={isLaptop}>
                  {translate("pro-invoices.view.invoice")}
                </StyledTitle>
                <StyledValue isMobile={isMobile} isLaptop={isLaptop}>
                  <strong>{invoiceNumber}</strong>
                </StyledValue>
              </StyledProInvoicePaymentDetailsBlock>
              <StyledProInvoicePaymentDetailsBlock
                isMobile={isMobile}
                isLaptop={isLaptop}
              >
                <StyledTitle isMobile={isMobile} isLaptop={isLaptop}>
                  {translate("pro-invoices.view.status")}
                </StyledTitle>
                <StyledValue isMobile={isMobile} isLaptop={isLaptop}>
                  <strong>
                    <Icon
                      className={`icon-${proInvoiceMessageIcon}`}
                      icon={proInvoiceMessageIcon}
                    />
                    {translate(
                      "pro-invoices.view.invoice-statuses-raw." + status.code
                    )}
                  </strong>
                </StyledValue>
              </StyledProInvoicePaymentDetailsBlock>
            </StyledProInvoicePaymentDetails>
          </div>
        </StyledProInvoicePaymentDetailsWrapper>
      )}
    </Translate>
  );
};

ProInvoicePaymentDetails.defaultProps = {
  amountDue: "0",
  dateCreated: "na",
  dueDate: "n/a",
  invoiceNumber: "n/a",
  transactionId: "n/a",
  currency: "n/a"
};

export default withSizes(isLaptop)(ProInvoicePaymentDetails);
