import React from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {connect} from "react-redux";
import {
    isBackupRequest
} from "../../../../Customer/reducers/customerReducer";
import styled from "styled-components";
import Modal from "../../../../../components/Modal";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import moment from "moment";
import {bindActionCreators} from "redux";
import {startRestore} from "../../../../Customer/actions/customerActions";
import Icon from "../../../../../components/Icon";
import CustomTooltip from "../../../../../components/CustomTooltip";
import {toastDanger, toastSuccess} from "../../../../ToastMessage";
import SmallLogoLoader from "../../../../../components/Loaders/SmallLogoLoader";

const StyledCheckbox = styled.div`
  & {
    width: 100%;
    cursor: pointer;
   
    padding-left: 0;
    margin-bottom:10px;
    font-size: 16px;
    line-height: 1.5;
    color: #5E6C83;
    padding-bottom: 10px;
    
  }
  
  label{
  padding-left:10px;
  }

`;

const BackupInfoTitle = styled.h1`
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: #263857
`

const CenterLoader = styled.div`
display:block;
margin: 0 auto;`



class BackupInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            subdomains: false,
            email: false,
            mysql: false,
            inProgress: 0,
            showModal: false
        };
    }

    componentDidMount() {

    }

    restoreBackup() {
        this.setState({
            showModal: true
        })
    }

    onHideModal() {
        this.setState({
            inProgress: 0,
            showModal: false
        })
    }

    setBackup() {
        this.setState({
            inProgress: 1
        })
        const restoreOptions = {
                mysql: this.state.mysql ? 1 : 0,
                mail_config: this.state.email ? 1 : 0,
                subdomains: this.state.subdomains ? 1 : 0,
                restorePoint : moment(this.props.dateSelected).format("YYYY-MM-DD")
            }
        this.props.startRestore(this.props.hosting, restoreOptions).then((data) => {
            this.onHideModal();
            if(data.error){
                toastDanger(this.props.translate("backup.fail-msg"), data.data.messages.join(' '))
            }

            else {
                toastSuccess(this.props.translate("backup.success-msg") , data.data.messages.join(' '))
            }
            this.resetData();
        })
    }

    resetData(){
        this.state = {
            subdomains: false,
            email: false,
            mysql: false,
            inProgress: 0,
            showModal: false
        };
        this.props.resetDate();
    }


    render() {

        return (
            <Translate>
                {({translate}) => (
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">

                            <BackupInfoTitle>
                                {translate(
                                    "backup.options"
                                )}
                            </BackupInfoTitle>

                            <StyledCheckbox>
                                <input type={'checkbox'} id={'check-domain'}
                                       value={this.state.subdomains}
                                       onChange={() =>
                                           this.setState({
                                               subdomains: !this.state.subdomains
                                           })}/>

                                <label className="backup-check"
                                       htmlFor={'check-domain'}>
                                    <CustomTooltip
                                        content={translate("backup.backup-note-1"
                                        )}
                                        inline={true}
                                    >
                                        <Icon icon="info" />
                                    </CustomTooltip>
                                    {translate("backup.restore-subdomains"
                                    )}</label>
                            </StyledCheckbox>


                            <StyledCheckbox>
                                <input type={'checkbox'} id={'check-email'}
                                       value={this.state.email}
                                       onChange={() =>
                                           this.setState({
                                               email: !this.state.email
                                           })}
                                />
                                <label className="backup-check"
                                       htmlFor={'check-email'}>   <CustomTooltip
                                    content={translate("backup.backup-note-2"
                                    )}
                                    inline={true}
                                >
                                    <Icon icon="info" />
                                </CustomTooltip> {translate("backup.restore-mail-configuration"
                                )}</label>
                            </StyledCheckbox>
                            <StyledCheckbox>
                                <input type={'checkbox'} id={'check-mysql'}
                                       value={this.state.mysql}
                                       onChange={() => {
                                           this.setState({
                                               mysql: !this.state.mysql
                                           })
                                       }}
                                />
                                <label className="backup-check"
                                       htmlFor={'check-mysql'}>    <CustomTooltip
                                    content={translate("backup.backup-note-3"
                                    )}
                                    inline={true}
                                >
                                    <Icon icon="info" />
                                </CustomTooltip> {translate("backup.restore-my-sql"
                                )}</label>
                            </StyledCheckbox>
                            <PrimaryButton onClick={() => this.restoreBackup()}>{translate("backup.restore-backups-now"
                            )} </PrimaryButton>
                        </div>

                        {this.state.showModal && (
                            <Modal
                                title={translate("backup.backup-modal-title"
                                )}
                                subTitle={translate("backup.options-for-backup"
                                )}
                                onCloseModal={() => this.onHideModal()}
                                body={() =>

                                    <div className={'row'}>

                                        {this.state.inProgress === 0 && <div>
                                            <p> {translate("backup.backup-default"
                                            )} </p>
                                            <p>
                                                {translate("backup.date"
                                            )} : {moment(this.props.dateSelected).format("DD-MM-YYYY").toString()} </p>
                                            {this.state.subdomains && (<p>{translate("backup.restore-subdomains"
                                            )}</p>)}
                                            {this.state.email && (<p>{translate("backup.restore-mail-configuration"
                                            )}</p>)}
                                            {this.state.mysql && (<p>{translate("backup.restore-my-sql"
                                            )}</p>)}
                                        </div>}
                                        {this.state.inProgress === 1 && <CenterLoader>
                                            <SmallLogoLoader/>
                                        </CenterLoader>}

                                        {this.state.inProgress === 2 && <div>
                                            <p>{translate("backup.done"
                                            )}</p>
                                        </div>}

                                    </div>

                                }
                                footer={() => (
                                    this.state.inProgress === 0 && <div>
                                        <SecondaryButton
                                            style={{marginRight: "20px"}}
                                            onClick={() => this.onHideModal()}
                                        >
                                            {translate("backup.cancel"
                                            )}
                                        </SecondaryButton>


                                        <PrimaryButton
                                            onClick={() => this.setBackup()}
                                        >
                                            {translate("backup.restore-backups-now"
                                            )}
                                        </PrimaryButton>
                                    </div>
                                )}
                            />
                        )}
                    </div>

                )}
            </Translate>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isBackupRequest: isBackupRequest(state),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {startRestore},
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(BackupInfo));

