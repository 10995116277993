import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FORM_ERROR } from "final-form";
import qs from "query-string";
import { BrowserView, MobileView, isMobile } from "react-device-detect";

import styled from "styled-components";
import { theme } from "../../design/Themes";

import { login } from "../Authentication/actions/authActions";
import {
  isAuthenticated,
  getAuthToken
} from "../Authentication/reducers/authReducer";

import {
  acceptInvitation,
  registerAssistant
} from "../Customer/actions/customerActions";

import InvitationLoginForm from "./InvitationLoginForm";
import InvitationRegisterForm from "./InvitationRegisterForm";

import {
  validateEmail,
  validatePassword,
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
  validateMkPhoneNumber,
  validateTermsAndConditions
} from "../../common/validationRules";

import whiteLogo from "../../assets/images/white-logo.svg";

import { ROUTE_INDEX, ROUTE_LOGIN } from "../../routes/routes";

const StyledLogoContainer = styled.div`
  background-color: ${theme.neutralBase};
  position: relative;
  text-align: center;
  vertical-align: middle;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const StyledInvitationFormContainer = styled.div`
  margin-top: 100px;
`;

const StyledInvitationFormWrapper = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledLogo = styled.img`
  position: relative;
  top: 45%;
`;

const INVITATION_FORM_MODE_LOGIN = "login";
const INVITATION_FORM_MODE_REGISTER = "register";

class Invitation extends React.Component {
  constructor(props) {
    super(props);

    const params = qs.parse(this.props.location.search);
    if (!params.token || params.token.length === 0) {
      this.props.history.push(ROUTE_LOGIN);
    }

    if (
      !params.action ||
      (params.action !== INVITATION_FORM_MODE_LOGIN &&
        params.action !== INVITATION_FORM_MODE_REGISTER)
    ) {
      this.props.history.push(ROUTE_LOGIN);
    }

    this.state = {
      mode: params.action,
      token: params.token,
      reCaptchaToken: null
    };
  }

  onSetReCaptchaToken = token => {
    this.setState({
      reCaptchaToken: token
    });
  };

  componentDidMount() {
    if (this.props.isAuthenticated) {
      if (this.state.token && this.props.authToken) {
        this.props.acceptInvitation({ token: this.state.token }).then(() => {
          this.props.history.push(ROUTE_INDEX);
        });
      }
    }
  }

  validateLogin = values => {
    return {
      email: validateEmail(values.email),
      password: validatePassword(values.password)
    };
  };

  validateRegister = values => {
    return {
      first_name: validateFirstName(values.first_name),
      last_name: validateLastName(values.last_name),
      phone: validateMkPhoneNumber(values.phone),
      password: validatePassword(values.password),
      terms: validateTermsAndConditions(values.terms)
    };
  };

  onLogin = values => {
    return this.props
      .login(
        values.email,
        values.password,
        null,
        null,
        this.state.reCaptchaToken
      )
      .then(() => {
        return this.props
          .acceptInvitation({ token: this.state.token })
          .then(() => {
            this.props.history.push(ROUTE_INDEX);
          });
      })
      .catch(error => {
        const { messages } = error.data.data;
        return {
          [FORM_ERROR]: messages[0]
        };
      });
  };

  onRegister = values => {
    return this.props
      .registerAssistant(this.state.token, values)
      .then(customer => {
        if (customer) {
          return this.props
            .login(
              customer.email,
              values.password,
              null,
              null,
              this.state.reCaptchaToken
            )
            .then(() => {
              return this.props
                .acceptInvitation({ token: this.state.token })
                .then(() => {
                  this.props.history.push(ROUTE_INDEX);
                });
            });
        }
      });
  };

  render() {
    return (
      <div className="row">
        <BrowserView viewClassName="col-8 offset-2 col-sm-8 offset-sm-2 col-md-8 offset-md-2">
          <StyledInvitationFormContainer className="row no-gutters">
            <StyledLogoContainer className="col-4 col-sm-4 col-md-4">
              <StyledLogo src={whiteLogo} alt="MKHost" />
            </StyledLogoContainer>
            <StyledInvitationFormWrapper className="col-8 col-sm-8 col-md-8">
              {this.state.mode === INVITATION_FORM_MODE_LOGIN ? (
                <InvitationLoginForm
                  onSubmit={this.onLogin}
                  validate={this.validateLogin}
                  isMobile={isMobile}
                  onSetReCaptchaToken={this.onSetReCaptchaToken}
                />
              ) : (
                <InvitationRegisterForm
                  onSubmit={this.onRegister}
                  validate={this.validateRegister}
                  isMobile={isMobile}
                  onSetReCaptchaToken={this.onSetReCaptchaToken}
                />
              )}
            </StyledInvitationFormWrapper>
          </StyledInvitationFormContainer>
        </BrowserView>
        <MobileView viewClassName="col-12 col-sm-12 col-md-12">
          <StyledInvitationFormContainer className="row">
            <StyledInvitationFormWrapper className="col-10 offset-1 col-sm-12 col-md-12">
              {this.state.mode === INVITATION_FORM_MODE_LOGIN ? (
                <InvitationLoginForm
                  onSubmit={this.onLogin}
                  validate={this.validateLogin}
                  isMobile={isMobile}
                  onSetReCaptchaToken={this.onSetReCaptchaToken}
                />
              ) : (
                <InvitationRegisterForm
                  onSubmit={this.onRegister}
                  validate={this.validateRegister}
                  isMobile={isMobile}
                  onSetReCaptchaToken={this.onSetReCaptchaToken}
                />
              )}
            </StyledInvitationFormWrapper>
          </StyledInvitationFormContainer>
        </MobileView>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state),
    authToken: getAuthToken(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      login,
      acceptInvitation,
      registerAssistant
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Invitation);
