import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { Form, Field, FormSpy } from "react-final-form";
import { isMobile, isMobileOnly, isBrowser } from "react-device-detect";
import isLaptop from "../../../../../utils/isLaptopScreen";
import withSizes from "react-sizes";
import { Translate } from "react-localize-redux";

import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import Label from "../../../../../components/Label";
import InputField from "../../../../../components/InputField";
import SelectBox from "../../../../../components/SelectBox";

import {
  validateDnsRecordHostname,
  validateInArray
} from "../../../../../common/validationRules";

import { operatingSystemData, platformData, regionData } from "./settingsData";

const StyledSettingsWrapper = styled.div`
  padding-top: 20px;
`;

const StyledSettingsOption = styled.div`
  margin-bottom: 20px;
`;

const operatingSystems = [
  {
    label: "CentOS",
    value: "centos"
  },
  {
    label: "Fedora",
    value: "fedora"
  },
  {
    label: "Debian",
    value: "debian"
  }
];

class Settings extends React.Component {
  validate = values => {
    return {
      hostname: validateDnsRecordHostname(values.hostname, true),
      operating_system: validateInArray(
        values.operating_system,
        operatingSystemData
      ),
      platform: validateInArray(values.platform, platformData),
      region: validateInArray(values.region, regionData)
    };
  };

  render() {
    const {
      product,
      onSaveSettings,
      updatingServerSettings,
      updatingServerSettingsId,
      onBlockCheckout,
      parentId,
      isLaptop
    } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <Form
            onSubmit={onSaveSettings}
            validate={this.validate}
            initialValues={{
              hostname: product.hostname || "",
              operating_system:
                operatingSystemData.find(
                  o => o.value === product.operating_system
                ) || "",
              platform:
                platformData.find(o => o.value === product.platform) || "",
              region: regionData.find(o => o.value === product.region) || ""
            }}
            render={({ handleSubmit }) => (
              <StyledSettingsWrapper>
                <form
                  id={`cloud-server-form-${product.id}`}
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <StyledSettingsOption className="col-12 col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                          <Field
                            component={InputField}
                            required={true}
                            name="hostname"
                            label={translate("cart.hostname")}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <Field
                            required={true}
                            component={SelectBox}
                            name="operating_system"
                            label={translate("cart.os")}
                            options={operatingSystemData}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <Field
                            required={true}
                            component={SelectBox}
                            name="platform"
                            label={translate("cart.platform")}
                            options={platformData}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <Field
                            required={true}
                            component={SelectBox}
                            name="region"
                            label={translate("cart.region")}
                            options={regionData}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 text-right">
                          <PrimaryButton
                            type="submit"
                            disabled={updatingServerSettings}
                            submitting={
                              updatingServerSettings &&
                              updatingServerSettingsId === product.id
                            }
                            key="save-ns"
                          >
                            {translate("domain.settings.save-changes")}
                          </PrimaryButton>
                        </div>
                      </div>
                    </StyledSettingsOption>
                  </div>

                  <FormSpy
                    subscription={{
                      invalid: true,
                      valid: true,
                      submitSucceeded: true
                    }}
                    onChange={formstate =>
                      onBlockCheckout({
                        formId: parentId,
                        ...formstate
                      })
                    }
                  />
                </form>
              </StyledSettingsWrapper>
            )}
          />
        )}
      </Translate>
    );
  }
}

export default withSizes(isLaptop)(Settings);
