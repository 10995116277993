import axios from "axios";

import {
  SECURITY_FETCH_SECURITY_QUESTIONS_API_ENDPOINT,
  SECURITY_UPDATE_SECURITY_QUESTION_API_ENDPOINT,
  SECURITY_CHANGE_PASSWORD_API_ENDPOINT
} from "./endpoints";

export function fetchSecurityQuestions() {
  return axios.get(SECURITY_FETCH_SECURITY_QUESTIONS_API_ENDPOINT);
}

export function updateSecurityQuestion(question, answer) {
  return axios.put(SECURITY_UPDATE_SECURITY_QUESTION_API_ENDPOINT, {
    question,
    answer
  });
}

export function changeCustomerPassword(
  oldPassword,
  newPassword,
  confirmNewPassword
) {
  return axios.put(SECURITY_CHANGE_PASSWORD_API_ENDPOINT, {
    old_password: oldPassword,
    password: newPassword,
    confirm_password: confirmNewPassword
  });
}
