export default {
  "Ivan Petrushevski": "/assets/images/employees/ivan_p.jpg",
  "Ivan Petrusevski": "/assets/images/employees/ivan_p.jpg",
  "Иван Петрушевски": "/assets/images/employees/ivan_p.jpg",
  "Билјана Шијаковска": "/assets/images/employees/biljana_s.png",
  "Biljana Shijakovska": "/assets/images/employees/biljana_s.png",
  "Magdalena Ilijevska": "/assets/images/employees/magdalena_i.jpg",
  "Магдалена Илијевска": "/assets/images/employees/magdalena_i.jpg",
  "Ivana Veljkovic": "/assets/images/employees/ivana_v.jpg",
  "Ивана Вељковиќ": "/assets/images/employees/ivana_v.jpg",
  "Darko Karanfilov": "/assets/images/employees/darko_k.jpg",
  "Дарко Каранфилов": "/assets/images/employees/darko_k.jpg",
  "Angela Stefanovska": "/assets/images/employees/angela_s.jpg",
  "Ангела Стефановска": "/assets/images/employees/angela_s.jpg",
  "Katerina Trajkovska": "/assets/images/employees/katerina_t.jpg",
  "Катерина Трајковска": "/assets/images/employees/katerina_t.jpg",
  "Marija Dimitrijevska": "/assets/images/employees/marija_d.jpg",
  "Марија Димитријевска": "/assets/images/employees/marija_d.jpg",
  "Bojan Krstevski": "/assets/images/employees/bojan_k.jpg",
  "Бојан Крстевски": "/assets/images/employees/bojan_k.jpg",
  "Martina Cavdarevska": "/assets/images/employees/martina_c.jpg",
  "Мартина Чавдаревска": "/assets/images/employees/martina_c.jpg",
  "Irena Madjovska": "/assets/images/employees/irena_m.jpg",
  "Ирена Маџовска": "/assets/images/employees/irena_m.jpg",
  "Robert Jakimov": "/assets/images/employees/robert_j.jpg",
  "Роберт Јакимов": "/assets/images/employees/robert_j.jpg",
  "Stefan Hristovski": "/assets/images/employees/stefan_h.jpg",
  "Стефан Христовски": "/assets/images/employees/stefan_h.jpg",
  "Enes Ismaili": "/assets/images/employees/enes_i.jpg",
  "Енес Исмаили": "/assets/images/employees/enes_i.jpg"
};
