export function trackPurchase(proInvoice) {
  const fbq = window.fbq;

  if (typeof fbq !== "function") {
    return false;
  }

  fbq("track", "Purchase", {
    value: parseFloat(proInvoice.total),
    currency:
      proInvoice.currency.code.toLowerCase() === "eur"
        ? proInvoice.currency.code.toUpperCase()
        : "MKD",
    content_ids: [proInvoice.id],
    invoice_id: proInvoice.id
  });
}
