import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { isMobileOnly } from "react-device-detect";
import { Translate } from "react-localize-redux";

import Avatar from "../../../../../components/Avatar";
import BusinessAvatar from "../../../../../components/BusinessAvatar";
import UserWithEmail from "../../../../../components/UserWithEmail";

const StyledWrapper = styled.div`
  // border: 2px dashed ${theme.neutral3};
  border-radius: 5px;
  margin-bottom: 15px;
  & .user-avatar {
    vertical-align: top;
    float: left;
  }
`;

const StyledAddressDetails = styled.div`
  & p {
    margin-bottom: 5px;
    font-size: 16px;
    color: ${theme.neutralBase};
  }
`;
const StyledContactDetails = styled.div`
  padding-top: 0;

  & p {
    margin-bottom: 5px;
    font-size: 16px;
    color: ${theme.neutralBase};
  }
`;

const InvoiceTo = ({ contact }) => {
  return (
    <Translate>
      {({ translate }) => (
        <div className="row">
          <StyledWrapper className="col-12 col-sm-12 col-md-12">
            {contact.company && contact.company !== "" ? (
              <BusinessAvatar />
            ) : (
              <Avatar />
            )}
            <UserWithEmail
              user={
                contact.company && contact.company !== ""
                  ? contact.company
                  : contact.firstname + " " + contact.lastname
              }
              email={contact.email}
              uppercaseUsername={contact.company && contact.company !== ""}
            />
          </StyledWrapper>
          <StyledWrapper className="col-12 col-sm-12 col-md-12">
            <StyledAddressDetails>
              {(!contact.company || contact.company === "") &&
                contact.firstname &&
                contact.firstname !== "" && (
                  <p>
                    {contact.firstname} {contact.lastname}
                  </p>
                )}
              {contact.company &&
                contact.company !== "" && (
                  <p className="text-uppercase">{contact.company}</p>
                )}
              {contact.address &&
                contact.address !== "" && <p>{contact.address}</p>}
              {contact.city &&
                contact.zip && (
                  <p>
                    {contact.zip} {contact.city}
                  </p>
                )}
              {contact.country &&
                contact.country !== "" && <p>{contact.country}</p>}
              {contact.company === "" && (
                <p className="text-uppercase">{contact.company}</p>
              )}
            </StyledAddressDetails>
            <StyledContactDetails>
              {contact.email && contact.email !== "" && <p>{contact.email}</p>}
              {contact.phonenumber &&
                contact.phonenumber !== "" && <p>{contact.phonenumber}</p>}
            </StyledContactDetails>
          </StyledWrapper>
        </div>
      )}
    </Translate>
  );
};

export default InvoiceTo;
