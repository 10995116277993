import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { Translate } from "react-localize-redux";

const StyledPayToTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${theme.neutral5};
  text-transform: uppercase;
  margin-bottom: 15px;
`;

const StyledAddressDetails = styled.div`
  padding: 30px;
  padding-top: 15px;
  padding-bottom: 0;
  padding-left: 15px;

  & p {
    margin-bottom: 5px;
    font-size: 16px;
    color: ${theme.neutralBase};
  }
`;

const PayTo = ({ contact }) => {
  return (
    <Translate>
      {({ translate }) => (
        <div className="row">
          <StyledPayToTitle className="col-12 col-sm-12 col-md-12">
            {translate("pro-invoices.view.pay-to")}
          </StyledPayToTitle>

          <StyledAddressDetails>
            <p>
              <strong>{translate("pro-invoices.view.mkhost-dooel")}</strong>
            </p>
          {/*  <p>{translate("pro-invoices.view.mkhost-address")}</p>*/}
          </StyledAddressDetails>
          <StyledAddressDetails>
            <p>{translate("pro-invoices.view.mkhost-tax-number")}</p>
          </StyledAddressDetails>
          <StyledAddressDetails>
            <p>{translate("pro-invoices.view.mkhost-bank-account-nlb")}</p>
            <p>{translate("pro-invoices.view.mkhost-bank-nlb")}</p>
          </StyledAddressDetails>
          <StyledAddressDetails>
            <p>{translate("pro-invoices.view.mkhost-bank-account-halkbank")}</p>
            <p>{translate("pro-invoices.view.mkhost-bank-halkbank")}</p>
          </StyledAddressDetails>

          <StyledAddressDetails>
            <p>{translate("pro-invoices.view.mkhost-phone")}</p>
          </StyledAddressDetails>
        </div>
      )}
    </Translate>
  );
};

export default PayTo;
