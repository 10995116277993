import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { Translate } from "react-localize-redux";

import Container from "../../../../../components/Containers/Container";
import Label from "../../../../../components/Label";

import getProgressBarForStatus from "../../../../../utils/getProgressBarForStatus";

const StyledHeader = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${theme.neutral3};
`;

const StyledContent = styled.div``;

const StyledTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  color: ${theme.neutral5};
`;

const StyledSection = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  padding: 30px;

  &:last-child {
    border-bottom: 0;
  }
`;
const StyledValue = styled.div`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.neutralBase};
  margin-top: 8px;
`;

const Usage = ({ disk, bandwidth }) => {
  const DiskProgressBar = getProgressBarForStatus(disk.status.state);
  const BandwidthProgressBar = getProgressBarForStatus(bandwidth.status.state);
  return (
    <Translate>
      {({ translate }) => (
        <Container>
          <StyledHeader>
            <StyledTitle>{translate("hosting.general-info.usage")}</StyledTitle>
          </StyledHeader>
          <StyledContent>
            <StyledSection>
              <Label>{translate("hosting.general-info.disk-space")}</Label>
              <StyledValue>
                {disk.usage} MB / {disk.limit} MB
              </StyledValue>
              <DiskProgressBar
                value={disk.usage}
                minValue={0}
                maxValue={100}
                striped={true}
                percentage={disk.percentage}
              />
            </StyledSection>
            <StyledSection>
              <Label>{translate("hosting.general-info.bandwidth")}</Label>
              <StyledValue>
                {bandwidth.usage} MB / {bandwidth.limit} MB
              </StyledValue>
              <BandwidthProgressBar
                value={bandwidth.usage}
                striped={true}
                minValue={0}
                maxValue={100}
                percentage={disk.percentage}
              />
            </StyledSection>
          </StyledContent>
        </Container>
      )}
    </Translate>
  );
};
export default Usage;
