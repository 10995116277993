import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import TableHeader from "../../../../../components/Table/TableHeader";
import TableHeading from "../../../../../components/Table/TableHeading";
import TableRow from "../../../../../components/Table/TableRow";

import VaryAmount from "../../../../../components/VaryAmount";

import { Translate } from "react-localize-redux";

const InlineSpan = styled.span`
  display: inline-block;
`;
const StyledVaryAmount = styled(VaryAmount)`
  display: inline-block;
`;

const DomainPriceTableHeader = props => (
  <Translate>
    {({ translate }) => (
      <TableHeader>
        {isMobileOnly && (
          <TableRow>
            <TableHeading colSpan="4">
              <InlineSpan>
                {translate("shop.domains.domain-prices.duration")}
              </InlineSpan>
              <StyledVaryAmount
                increase={props.increaseDuration}
                decrease={props.decreaseDuration}
              />
            </TableHeading>
          </TableRow>
        )}

        {isMobileOnly && (
          <TableRow>
            <TableHeading />
            <TableHeading>
              {translate("shop.domains.domain-prices.register")}
            </TableHeading>
            <TableHeading>
              {translate("shop.domains.domain-prices.renew")}
            </TableHeading>
            <TableHeading>
              {translate("shop.domains.domain-prices.transfer")}
            </TableHeading>
            <TableHeading />
          </TableRow>
        )}

        {!isMobileOnly && (
          <TableRow>
            <TableHeading>
              {translate("shop.domains.domain-prices.tld")}
            </TableHeading>
            <TableHeading>
              {translate("shop.domains.domain-prices.duration")}
              <VaryAmount
                increase={props.increaseDuration}
                decrease={props.decreaseDuration}
              />
            </TableHeading>
            <TableHeading>
              {translate("shop.domains.domain-prices.register")}
            </TableHeading>
            <TableHeading>
              {translate("shop.domains.domain-prices.renew")}
            </TableHeading>
            <TableHeading>
              {translate("shop.domains.domain-prices.transfer")}
            </TableHeading>
            <TableHeading />
          </TableRow>
        )}
      </TableHeader>
    )}
  </Translate>
);

export default DomainPriceTableHeader;
