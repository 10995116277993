import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { isMobileOnly, isTablet } from "react-device-detect";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import Container from "../../../../components/Containers/Container";
import Avatar from "../../../../components/Avatar";
import Icon from "../../../../components/Icon";
import UserWithEmail from "../../../../components/UserWithEmail";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import ActionModal from "../../../../components/ActionModal";

import attachmentIcon from "../../../../assets/images/attachment-icon.svg";
import mkhostLogo from "../../../../assets/images/logomark-blue.svg";

import TicketReplyForm from "./TicketReplyForm";

import { FilesEncoder } from "../../../../common/filesEncoder";
import { validateMessage } from "../../../../common/validationRules";

import {
  replyToTicket,
  closeTicket,
  viewTicket
} from "../../actions/helpdeskActions";

import { getCustomerPersonalInformation } from "../../../Customer/reducers/customerReducer";

import displayToastMessageForResponse from "../../../../utils/displayToastMessageForResponse";

import { ROUTE_WEBSITE } from "../../../../routes/routes";
import employeesPictures from "../../employeesPictures";

const StyledReplies = styled.div`
  padding: ${props => (props.isMobile || props.isTablet ? "20px" : "50px")};
`;

const StyledReply = styled.div``;
const StyledReplyHeader = styled.div`
  margin-bottom: ${props => (props.isMobile ? "30px" : "10px")};
`;

const StyledReplyTime = styled.span`
  display: ${props => (props.isMobile ? "block" : "inline-block")};
  float: ${props => (props.isMobile ? "left" : "right")};
  margin-top: ${props => !props.isMobile && "24px"};
  margin-left: ${props => props.isMobile && "0"};
  font-size: 14px;
  color: ${theme.neutral6};
  width: ${props => props.isMobile && "100%"};
  text-align: ${props => (props.isMobile ? "right" : "left")};
`;
const StyledReplyContent = styled.div`
  padding: 20px;
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 1.38;
  border-radius: 5px;
  background-color: ${theme.neutral1};
  color: ${theme.neutralBase};

  & pre {
    white-space: pre-line;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${theme.neutral6};
  }
`;

const StyledReplyAttachments = styled.div`
  border-top: 1px solid ${theme.neutral3};
  padding: 20px 20px 10px 20px;
  background-color: ${theme.neutral1};
  margin-top: -31px;
  margin-bottom: 30px;
  color: ${theme.neutral5};
  font-size: 14px;

  & .attachment-title {
    padding-bottom: 5px;
  }
`;

const StyledAdminReplyContent = styled(StyledReplyContent)`
  background-color: ${theme.green1};
  & pre {
    white-space: pre-line;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${theme.neutral6};
  }
`;

const StyledReplyFooter = styled.div`
  border-top: 1px solid ${theme.neutral3};
  padding: 30px 0;
`;

const StyledAttachment = styled(Link)`
  font-size: 14px;
`;

const StyledAttachmentContainer = styled.div`
  display: inline;
  margin-bottom: 2px;
  margin-right: 15px;
  font-size: 16px;

  & img {
    margin-top: -4px;
    margin-left: -4px;
  }
`;

const validate = values => {
  return { message: validateMessage(values.message, true) };
};

class Replies extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showReplyForm: false,
      closingTicket: false,
      showConfirmCloseTicketModal: false
    };

    this.replyFormRef = null;
  }

  setReplyFormRef = ref => (this.replyFormRef = ref);

  onShowReplyForm = () => {
    this.setState({ showReplyForm: true }, () => {
      if (this.replyFormRef) {
        try {
          this.replyFormRef.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        } catch (e) {
          this.replyFormRef.scrollIntoView(false);
        }
      }
    });
  };

  onSubmitReply = values => {
    if (values.attachments) {
      return this.prepareAttachments(values.attachments).then(attachments => {
        const reply = {
          ...values,
          attachments
        };
        return this.props
          .replyToTicket(this.props.ticket, reply)
          .then(() => this.onCancelReply());
      });
    }

    return this.props
      .replyToTicket(this.props.ticket, values)
      .then(() => this.onCancelReply());
  };

  prepareAttachments = files => {
    return new Promise((resolve, reject) => {
      FilesEncoder.createAttachments(files).then(result => {
        resolve(result);
      });
    });
  };

  onCancelReply = () => {
    this.setState({
      showReplyForm: false
    });
  };

  onCloseTicket = () => {
    this.setState({
      closingTicket: true
    });
    this.props
      .closeTicket(this.props.ticket.id)
      .then(({ error, messages, ticket }) => {
        displayToastMessageForResponse("Ticket", { error, messages });

        this.setState({
          closingTicket: false,
          showConfirmCloseTicketModal: false
        });
        if (!error) {
          this.props.viewTicket(ticket);
        }
      });
  };

  onConfirmCloseTicket = ticket => {
    this.setState({
      showConfirmCloseTicketModal: true
    });
  };

  onHideConfirmCloseTicket = () => {
    this.setState({
      showConfirmCloseTicketModal: false
    });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => {
          return (
            <Container>
              <StyledReplies isMobile={isMobileOnly} isTablet={isTablet}>
                {this.props.ticket &&
                  this.props.ticket.replies &&
                  this.props.ticket.replies.length > 0 &&
                  this.props.ticket.replies.map((reply, index) => {
                    let employeeImage = mkhostLogo;
                    if (reply.is_from_admin && employeesPictures[reply.name]) {
                      employeeImage = `${ROUTE_WEBSITE}${
                        employeesPictures[reply.name]
                      }`;
                    }

                    return (
                      <StyledReply
                        key={`reply-${index}-${reply.email}-${reply.created_date}`}
                      >
                        <StyledReplyHeader isMobile={isMobileOnly}>
                          <Avatar
                            admin={reply.is_from_admin}
                            image={reply.is_from_admin && employeeImage}
                          />
                          {!reply.is_from_admin && (
                            <UserWithEmail
                              user={reply.name}
                              email={reply.email}
                            />
                          )}
                          {reply.is_from_admin && (
                            <UserWithEmail
                              user={reply.name}
                              email={reply.email}
                            />
                          )}
                          <StyledReplyTime isMobile={isMobileOnly}>
                            {reply.created_date}
                          </StyledReplyTime>
                        </StyledReplyHeader>
                        {!reply.is_from_admin && (
                          <StyledReplyContent>
                            <pre>{parse(reply.message)}</pre>
                          </StyledReplyContent>
                        )}
                        {reply.is_from_admin && (
                          <StyledAdminReplyContent>
                            <pre>{parse(reply.message)}</pre>
                          </StyledAdminReplyContent>
                        )}

                        {reply.attachments.length > 0 && (
                          <StyledReplyAttachments>
                            <div className="attachment-title">
                              Attachments ({reply.attachments.length})
                            </div>
                            {reply.attachments.map((attachment, index) => (
                              <StyledAttachmentContainer key={index}>
                                <img src={attachmentIcon} alt="" />
                                <StyledAttachment
                                  key={`${attachment.name}-${index}`}
                                  onClick={e => {
                                    e.preventDefault();
                                    this.props.showAttachmentModal(
                                      attachment.link
                                    );
                                  }}
                                  to=""
                                >
                                  {attachment.name}
                                </StyledAttachment>
                              </StyledAttachmentContainer>
                            ))}
                          </StyledReplyAttachments>
                        )}
                      </StyledReply>
                    );
                  })}
                <StyledReplyFooter>
                  {!this.state.showReplyForm && (
                    <React.Fragment>
                      {this.props.ticket &&
                        this.props.ticket.status !== "Closed" && (
                          <SecondaryButton
                            disabled={this.state.closingTicket}
                            submitting={this.state.closingTicket}
                            onClick={() => this.onConfirmCloseTicket()}
                          >
                            {translate("help-desk.ticket.close-ticket")}
                          </SecondaryButton>
                        )}
                      <PrimaryButton onClick={() => this.onShowReplyForm()}>
                        <Icon icon="reply" />{" "}
                        {translate("help-desk.ticket.reply")}
                      </PrimaryButton>
                    </React.Fragment>
                  )}
                  {this.state.showReplyForm && (
                    <div ref={this.setReplyFormRef}>
                      <TicketReplyForm
                        onSubmit={this.onSubmitReply}
                        onCancel={this.onCancelReply}
                        validate={validate}
                      />
                    </div>
                  )}
                </StyledReplyFooter>
              </StyledReplies>

              {this.state.showConfirmCloseTicketModal && (
                <ActionModal
                  title={translate("help-desk.ticket.close-ticket")}
                  body={() => (
                    <div>
                      {translate("help-desk.ticket.confirm-close-ticket")}
                    </div>
                  )}
                  onCloseActionModal={this.onHideConfirmCloseTicket}
                  footer={() => (
                    <React.Fragment>
                      <SecondaryButton
                        onClick={() => this.onHideConfirmCloseTicket()}
                      >
                        {translate("general.cancel")}
                      </SecondaryButton>
                      <PrimaryButton
                        disabled={this.state.closingTicket}
                        submitting={this.state.closingTicket}
                        onClick={() => this.onCloseTicket(this.props.ticket)}
                      >
                        {translate("help-desk.ticket.close-ticket")}
                      </PrimaryButton>
                    </React.Fragment>
                  )}
                />
              )}
            </Container>
          );
        }}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    customer: getCustomerPersonalInformation(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ replyToTicket, closeTicket, viewTicket }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Replies);
