import * as paymentMethodsService from "../../../services/paymentService.js";

export const PAYMENT_METHODS_FEATURE = "features/PaymentMethods";
export const PAYMENT_METHODS_REQUEST = `${PAYMENT_METHODS_FEATURE}/PAYMENT_METHODS_REQUEST`;
export const PAYMENT_METHODS_RECEIVE = `${PAYMENT_METHODS_FEATURE}/PAYMENT_METHODS_RECEIVE`;

export function fetchPaymentMethods() {
  return dispatch => {
    dispatch(requestPaymentMethods());
    return paymentMethodsService
      .fetchPaymentMethods()
      .then(result => {
        const { payment_methods } = result.data.data;
        dispatch(receivePaymentMethods(payment_methods));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

function requestPaymentMethods() {
  return {
    type: PAYMENT_METHODS_REQUEST
  };
}

function receivePaymentMethods(paymentMethods) {
  return {
    type: PAYMENT_METHODS_RECEIVE,
    payload: paymentMethods
  };
}
