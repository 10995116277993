import React from "react";

import Loader from "../Loader";

const ApplyCreditLoader = () => (
  <Loader width="380" height="180">
    <rect x="30" y="30" width="30%" height="30" rx="3" />
    <rect x="30" y="65" width="80%" height="35" rx="3" />
    <rect x="58%" y="115" width="30%" height="35" rx="3" />
  </Loader>
);

export default ApplyCreditLoader;
