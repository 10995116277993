import styled from "styled-components";
import { theme } from "../../design/Themes";

const StyledSectionTitle = styled.h3`
  font-size: 30px;
  font-weight: 600;
  color: ${theme.neutralBase};
  margin-bottom: 30px;
  span.highlighted {
    font-weight: bold;
  }
`;

export default StyledSectionTitle;
