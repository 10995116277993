import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

import Container from "../../../../components/Containers/Container";

import attachmentIcon from "../../../../assets/images/attachment-icon.svg";

const StyledTicketAttachments = styled.div`
  margin-bottom: 30px;
`;
const StyledHeader = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${theme.neutral3};
  & .badge {
    margin-left: 0;
    margin-right: 10px;
    margin-top: 10px;
  }
`;

const StyledContent = styled.div`
  padding: 30px;
`;

const StyledTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  display: block;
`;

const StyledAttachmentContainer = styled.div`
  display: block;
  margin-bottom: 17px;

  & img {
    margin-top: -4px;
    margin-left: -4px;
  }
`;

const Attachments = ({ ticket, showAttachmentModal }) => {
  return (
    <Translate>
      {({ translate }) => (
        <StyledTicketAttachments>
          <Container>
            <StyledHeader>
              <StyledTitle>
                {translate("help-desk.ticket.attachments")}
              </StyledTitle>
            </StyledHeader>
            <StyledContent>
              {ticket.replies.map(reply =>
                reply.attachments.map((attachment, index) => (
                  <StyledAttachmentContainer key={index}>
                    <img src={attachmentIcon} alt="" />
                    <Link
                      key={`${attachment.name}-${index}`}
                      onClick={e => {
                        e.preventDefault();
                        showAttachmentModal(attachment.link);
                      }}
                      to=""
                    >
                      {attachment.name}
                    </Link>
                  </StyledAttachmentContainer>
                ))
              )}
            </StyledContent>
          </Container>
        </StyledTicketAttachments>
      )}
    </Translate>
  );
};
export default Attachments;
