import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { isMobileOnly } from "react-device-detect";

import DomainPrices from "../DomainPrices";

import Icon from "../../../components/Icon";
import Container from "../../../components/Containers/Container";
import DomainSearch from "../../../components/DomainSearch";
import DomainSearchResults from "../../../components/DomainSearch/DomainSearchResults";
import DomainExtensionsQuickPrices from "../../../components/DomainExtensionsQuickPrices";
import OtherAvailableDomains from "../../../components/OtherAvailableDomains";

import ErrorBoundary from "../../../components/ErrorBoundary";

import { addProductToCart } from "../../Cart/actions/cartActions";

import {
  validateOnlyDomainName,
  validateExtension
} from "../../../common/validationRules";

import {
  getCartId,
  isStoringItemInCart
} from "../../Cart/reducers/cartReducer";

import { getCustomerCurrencyCode } from "../../Customer/reducers/customerReducer";

import * as domainsService from "../../../services/domainsService";

import {
  getDomainsPricing,
  getTopLevelDomainsByGroup,
  isFetchingTopLevelGroupedDomains,
  getAvailableExtensions,
  getTopLevelDomainGroups
} from "../../Domains/reducers/domainsReducer";

import { fetchTopLevelGroupedDomains } from "../../Domains/actions/domainsActions";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import { ROUTE_CART } from "../../../routes/routes";

import {
  DOMAIN_STATUS_AVAILABLE,
  DOMAIN_STATUS_UNAVAILABLE
} from "../../Domains/DomainStatuses";

import {
  REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_TYPE,
  REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE
} from "../../../config/promotions";

const StyledShopDomainsWrapper = styled.div`
  margin-left: -30px;
  margin-right: -30px;
`;

const StyledHeader = styled.div`
  background-color: ${theme.neutralBase};
  color: ${theme.white};
  padding-bottom: 20px;
`;

const StyledTitle = styled.h1`
  margin: 0;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
  position: relative;
  padding-top: 80px;
  text-transform: uppercase;
`;

const StyledP = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.38;
  margin-bottom: 7px;
  & .icon {
    color: ${theme.greenBase};
  }
  &:last-child {
    margin-bottom: 20px;
  }
`;

const StyledFormWrapper = styled.div`
  color: ${theme.neutral5};
  position: relative;
  margin-top: 40px;
`;
const StyledFormTabs = styled.div`
  position: absolute;
  top: ${props => (props.isMobile ? "-39px" : "-41px")};
  right: 0;
`;
const StyledTab = styled.div`
  background-color: ${theme.white};
  cursor: pointer;
  padding: ${props => (props.isMobile ? "10px 6.25px" : "10px 15px")};
  font-size: ${props => (props.isMobile ? "13px" : "14px")};
  font-weight: 600;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.2px;
  color: ${props =>
    props.active ? props.theme.neutralBase : props.theme.neutral5};

  border-bottom: 1px solid
    ${props => (props.active ? props.theme.white : props.theme.neutral3)};

  &:first-child {
    border-top-left-radius: 3px;
    border-right: 1px solid
      ${props => (props.active ? props.theme.neutral3 : props.theme.white)};
  }
  &:last-child {
    border-top-right-radius: 3px;
    border-left: 1px solid
      ${props => (props.active ? props.theme.neutral3 : props.theme.white)};
  }
`;
const StyledContentWrapper = styled(Container)`
  padding: 0;
  border: 0;
  border-top-right-radius: 0;
  border-top-left-radius: ${props => props.isMobile && "0"};
`;
const StyledTabContent = styled.div`
  display: ${props => (props.active ? "block" : "none")};
`;

const StyledPricesWrapper = styled.div`
  padding-top: 50px;
  margin-bottom: 50px;
`;

const homepageDomains = ["mk", "com", "com.mk", "net", "info", "edu.mk", "org"];
const mobileHomepageDomains = ["mk", "com", "com.mk", "net"];

const validate = values => {
  let domainName = "";
  if (typeof values.domain === "object") {
    domainName = values.domain.name.split(".")[0];
  } else {
    domainName = values.domain;
  }

  return {
    domain: validateOnlyDomainName(domainName, values.extension.value),
    extension: validateExtension(values.extension.value)
  };
};

class ShopDomainsContainer extends React.Component {
  constructor(props) {
    super(props);

    const selectedForm = props.location.state
      ? props.location.state.selectedForm || null
      : "register";

    this.state = {
      selectedForm: selectedForm,
      tld: null,
      currency: null,
      extensions: null,
      quickPrices: null,
      searchDomain: null,
      searchDomainName: null,
      domain: null,
      addedToCart: false,
      submitting: false,
      searching: false,
      whoIs: null,
      error: false,
      selectedDomain: null,
      selectedExtension: null,
      triggerDomainSearch: false,
      otherAvailableDomains: [],
      submittingAdditional: false,
      addedAdditionalDomains: []
    };
    this.quoteRef = null;
  }

  componentDidMount() {
    if (
      this.props.match.params.domain &&
      this.props.match.params.domain !== ""
    ) {
      // prettier-ignore
      const [domain, extension] = this.props.match.params.domain.split(/\.(.+)/, 2);

      this.setState({
        domain: domain,
        selectedExtension: extension,
        triggerDomainSearch: true
      });
    }

    this.props.fetchTopLevelGroupedDomains();
    this.fetchDomainPrices();
  }

  fetchDomainPrices = () => {
    domainsService.fetchDomainsPrices().then(result => {
      const { tld } = result.data.data;

      let extensions = [];
      let currency = this.props.customerCurrencyCode
        ? this.props.customerCurrencyCode
        : "МКД";
      let quickPrices = [];

      const domains = isMobileOnly ? mobileHomepageDomains : homepageDomains;

      Object.keys(tld).map(ext => {
        extensions.push({ label: `.${ext}`, value: ext });
        if (domains.indexOf(ext) > -1) {
          quickPrices.push(
            `.${ext} ${isMobileOnly ? "<br />" : ""} ${currency} ${parseFloat(
              tld[ext][currency.toLowerCase()].register[1]
            ).toFixed(currency.toLowerCase() === "eur" ? 2 : 0)}`
          );
        }
        return ext;
      });

      this.setState(
        {
          tld,
          currency,
          extensions,
          quickPrices
        },
        () => {
          if (this.state.domain && this.state.selectedExtension) {
            document
              .getElementById(`domain-search-form-${this.state.selectedForm}`)
              .dispatchEvent(new Event("submit", { cancelable: true }));
          }
        }
      );
    });
  };

  onSearchDomain = values => {
    this.setState({
      searching: true,
      domain: values.domain,
      searchDomain: values.domain + values.extension.label,
      searchDomainName: values.domain,
      selectedExtension: values.extension.value,
      selectedDomain: null
    });

    return domainsService
      .isDomainAvailable(values.domain + values.extension.label)
      .then(result => {
        const { domain } = result.data.data;
        const { error } = result.data;

        if (
          !values.extension.label.endsWith("mk") &&
          !values.extension.label.endsWith("мкд")
        ) {
          this.setState(
            {
              ...this.state,
              selectedDomain: domain,
              whoIs: null,
              searching: false,
              addedToCart: false,
              error: error
            },
            () => {
              if (this.quoteRef) {
                try {
                  this.quoteRef.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                  });
                } catch (e) {
                  this.quoteRef.scrollIntoView(false);
                }
              }
            }
          );
          return result;
        } else {
          if (domain.status === DOMAIN_STATUS_AVAILABLE) {
            this.setState(
              {
                ...this.state,
                selectedDomain: domain,
                whoIs: null,
                searching: false,
                addedToCart: false,
                error: error
              },
              () => {
                if (this.quoteRef) {
                  try {
                    this.quoteRef.scrollIntoView({
                      behavior: "smooth",
                      block: "start"
                    });
                  } catch (e) {
                    this.quoteRef.scrollIntoView(false);
                  }
                }
              }
            );
            return result;
          } else if (domain.status === DOMAIN_STATUS_UNAVAILABLE) {
            return domainsService.domainWhoIs(domain.name).then(result => {
              this.setState(
                {
                  ...this.state,
                  selectedDomain: domain,
                  whoIs: result.data.data,
                  searching: false,
                  addedToCart: false,
                  error: error
                },
                () => {
                  if (this.quoteRef) {
                    try {
                      this.quoteRef.scrollIntoView({
                        behavior: "smooth",
                        block: "start"
                      });
                    } catch (e) {
                      this.quoteRef.scrollIntoView(false);
                    }
                  }
                }
              );
              return result;
            });
          }
        }
      })
      .catch(errorResult => {
        const { error } = errorResult.response.data;
        this.setState({
          selectedDomain: {
            name: values.domain + values.extension.label,
            status: DOMAIN_STATUS_UNAVAILABLE
          },
          whoIs: null,
          searching: false,
          error: error
        });
      });
  };

  onSelectTab = tab => {
    this.setState({
      selectedForm: tab
    });
  };

  onAddToCart = (domain, type, period, additionalDomain) => {
    this.setState({
      submitting: additionalDomain ? false : true,
      addedToCart: false,
      submittingAdditional: additionalDomain ? true : false
    });
    this.props
      .addProductToCart({
        type: "domain",
        domain_id: null,
        domain: domain.name,
        quantity: 1,
        config: {
          type: type || "register",
          period: period
            ? period
            : domain.pricing[type || "register"].billing_cycle.derivations[0]
                .numeric_code
        }
      })
      .then(result => {
        displayToastMessageForResponse(
          this.props.translate("cart.shop"),
          result,
          ROUTE_CART,
          this.props.translate("cart.view-cart")
        );

        this.setState({
          submitting: false,
          addedToCart: additionalDomain ? false : true,
          submittingAdditional: false,
          addedAdditionalDomains: this.state.addedAdditionalDomains.concat([
            domain.name
          ])
        });
        return result;
      })
      .catch(err => {
        const { error } = err.response.data;
        const { cart, messages } = err.response.data.data;

        const data = { error, messages };
        displayToastMessageForResponse(
          this.props.translate("cart.shop"),
          data,
          ROUTE_CART,
          this.props.translate("cart.view-cart")
        );
        this.setState({
          submitting: false,
          addedToCart: false,
          submittingAdditional: false,
          addedAdditionalDomains: this.state.addedAdditionalDomains.concat([
            domain.name
          ])
        });
      });
  };

  render() {
    return (
      <StyledShopDomainsWrapper className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <StyledHeader className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <StyledTitle>
                      {this.props.translate("shop.domains.title")}
                    </StyledTitle>
                    <StyledP>
                      <Icon icon="input-checkbox" />{" "}
                      {this.props.translate(
                        "shop.domains.header-options.instant-activation-in-5-min"
                      )}
                    </StyledP>
                    <StyledP>
                      <Icon icon="input-checkbox" />{" "}
                      {this.props.translate(
                        "shop.domains.header-options.registration-from-1-10-years"
                      )}
                    </StyledP>
                    <StyledP>
                      <Icon icon="input-checkbox" />{" "}
                      {this.props.translate(
                        "shop.domains.header-options.free-redirections-and-dns-hosting"
                      )}
                    </StyledP>
                  </div>
                </div>

                <StyledFormWrapper>
                  <StyledFormTabs isMobile={isMobileOnly}>
                    <StyledTab
                      isMobile={isMobileOnly}
                      active={this.state.selectedForm === "register"}
                      onClick={() => this.onSelectTab("register")}
                    >
                      {this.props.translate(
                        "shop.domains.search-tabs.register-domains"
                      )}
                    </StyledTab>
                    <StyledTab
                      isMobile={isMobileOnly}
                      active={this.state.selectedForm === "transfer"}
                      onClick={() => this.onSelectTab("transfer")}
                    >
                      {this.props.translate(
                        "shop.domains.search-tabs.transfer-domains"
                      )}
                    </StyledTab>
                  </StyledFormTabs>
                  <StyledContentWrapper isMobile={isMobileOnly}>
                    <StyledTabContent
                      active={this.state.selectedForm === "register"}
                    >
                      {this.state.extensions &&
                        this.state.selectedForm === "register" && (
                          <DomainSearch
                            domain={this.state.domain}
                            searchPlaceholder={this.props.translate(
                              "shop.domains.search-tabs.domain-name-you-are-looking-for"
                            )}
                            extensions={this.state.extensions}
                            onSubmit={this.onSearchDomain}
                            validate={validate}
                            searching={this.state.searching}
                            type="register"
                            selectedExtension={this.state.selectedExtension}
                            triggerDomainSearch={this.state.triggerDomainSearch}
                          />
                        )}
                    </StyledTabContent>
                    <StyledTabContent
                      active={this.state.selectedForm === "transfer"}
                    >
                      {this.state.extensions &&
                        this.state.selectedForm === "transfer" && (
                          <DomainSearch
                            domain={this.state.domain}
                            searchPlaceholder={this.props.translate(
                              "shop.domains.search-tabs.domain-you-would-like-to-transfer"
                            )}
                            extensions={this.state.extensions}
                            onSubmit={this.onSearchDomain}
                            validate={validate}
                            searching={this.state.searching}
                            type="transfer"
                            selectedExtension={this.state.selectedExtension}
                            triggerDomainSearch={this.state.triggerDomainSearch}
                          />
                        )}
                    </StyledTabContent>
                  </StyledContentWrapper>
                </StyledFormWrapper>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="container">
                      {this.state.quickPrices &&
                        this.state.selectedForm === "register" && (
                          <DomainExtensionsQuickPrices
                            prices={this.state.quickPrices}
                          />
                        )}

                      {this.state.quickPrices &&
                        this.state.selectedForm === "transfer" && (
                          <DomainExtensionsQuickPrices
                            prices={() => (
                              <React.Fragment>
                                {this.props.translate(
                                  "shop.domains.search-tabs.free-transfer"
                                )}{" "}
                                {this.props.translate(
                                  "shop.domains.search-tabs.for-all-macedonian-domains"
                                )}{" "}
                                |{" "}
                                <strong>
                                  {this.props.translate(
                                    "shop.domains.search-tabs.transfer-price"
                                  )}
                                </strong>{" "}
                                {this.props.translate(
                                  "shop.domains.search-tabs.for-most-international-domains"
                                )}
                                <strong />
                              </React.Fragment>
                            )}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </StyledHeader>

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <div className="container">
                {this.state.selectedDomain && (
                  <StyledPricesWrapper ref={ref => (this.quoteRef = ref)}>
                    <DomainSearchResults
                      domain={this.state.selectedDomain}
                      search={this.state.searchDomain}
                      onAddToCart={this.onAddToCart}
                      addedToCart={this.state.addedToCart}
                      submitting={this.state.submitting}
                      type={this.state.selectedForm}
                      whoIs={this.state.whoIs}
                      error={this.state.error}
                      priceReduce={
                        this.state.selectedExtension === "mk"
                          ? {
                              type: "register",
                              discountType: REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_TYPE,
                              value: REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE
                            }
                          : null
                      }
                    />
                  </StyledPricesWrapper>
                )}

                {this.state.selectedDomain &&
                  this.state.selectedForm === "register" &&
                  this.state.selectedExtension !== "мкд" && (
                    <ErrorBoundary>
                      <OtherAvailableDomains
                        domain={this.state.searchDomainName}
                        extension={this.state.selectedExtension}
                        onAddToCart={this.onAddToCart}
                        extensions={this.state.extensions}
                        priceReduce={{
                          type: "register",
                          discountType: REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_TYPE,
                          value: REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE
                        }}
                        priceReduceExtensions={[".mk"]}
                        topLevelDomainGroups={this.props.topLevelDomainGroups}
                        topLevelDomainsListingByGroup={
                          this.props.topLeveldomainsListingByGroup
                        }
                        customerCurrencyCode={this.props.customerCurrencyCode}
                        submitting={this.state.submittingAdditional}
                        addedAdditionalDomains={
                          this.state.addedAdditionalDomains
                        }
                      />
                    </ErrorBoundary>
                  )}

                <ErrorBoundary>
                  <DomainPrices
                    availableMacedonianExtensions={this.props.domainsListing}
                    customerCurrencyCode={this.props.customerCurrencyCode}
                    isFetchingTopLevelGroupedDomains={
                      this.props.isFetchingTopLevelGroupedDomains
                    }
                    topLeveldomainsListingByGroup={
                      this.props.topLeveldomainsListingByGroup
                    }
                  />
                </ErrorBoundary>
              </div>
            </div>
          </div>
        </div>
      </StyledShopDomainsWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    cartId: getCartId(state),
    domainsListing: getDomainsPricing(state),
    customerCurrencyCode: getCustomerCurrencyCode(state),
    topLeveldomainsListingByGroup: getTopLevelDomainsByGroup(state),
    isFetchingTopLevelGroupedDomains: isFetchingTopLevelGroupedDomains(state),
    isStoringItemInCart: isStoringItemInCart(state),
    availableExtensions: getAvailableExtensions(state),
    topLevelDomainGroups: getTopLevelDomainGroups(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { addProductToCart, fetchTopLevelGroupedDomains },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocalize(ShopDomainsContainer)));
