import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Field, FormSpy } from "react-final-form";
import { Translate, withLocalize } from "react-localize-redux";

import { FilesEncoder } from "../../common/filesEncoder";
import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

import { createFeedback } from "./actions/feedbackFormActions";

import Modal from "../../components/Modal";
import SelectBox from "../../components/SelectBox";
import InputField from "../../components/InputField";
import TextArea from "../../components/TextArea";
import FileUpload from "../../components/FileUpload";
import Checkbox from "../../components/Checkbox";
import Label from "../../components/Label";
import FormGroup from "../../components/FormGroup";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

import { validateSubject, validateMessage } from "../../common/validationRules";

const StyledPlatfomWrapper = styled.div`
  padding-top: 16px;

  & .custom-checkbox {
    margin-right: 30px;
  }
`;
const BlockLabel = styled(Label)`
  display: block;
`;
const InlineCheckbox = styled(Checkbox)`
  display: inline;
`;

const validatePlatform = (values, translate) => {
  if (!values) {
    return translate("validation.feedback-select-platform");
  }
  let platformsSelected = 0;
  Object.keys(values).forEach(value => {
    if (values[value]) {
      platformsSelected++;
    }
  });
  if (!platformsSelected) {
    return translate("validation.feedback-select-platform");
  }
  return undefined;
};

const validateType = (value, translate) => {
  if (!value) {
    return translate("validation.feedback-select-type");
  }
  return undefined;
};

const validate = (values, translate) => {
  if (values.type === FEEDBACK_TYPE_FEEDBACK) {
    return {
      type: validateType(values.type, translate),
      description: validateMessage(values.description)
    };
  }

  if (values.type === FEEDBACK_TYPE_FEATURE) {
    return {
      type: validateType(values.type, translate),
      subject: validateSubject(values.subject),
      description: validateMessage(values.description)
    };
  }

  if (values.type === FEEDBACK_TYPE_PROBLEM) {
    return {
      type: validateType(values.type, translate),
      subject: validateSubject(values.subject),
      description: validateMessage(values.description),
      platform: validatePlatform(values.platform, translate)
    };
  }

  return {
    type: validateType(values.type, translate)
  };
};

const FEEDBACK_TYPE_PROBLEM = "problem";
const FEEDBACK_TYPE_FEATURE = "feature";
const FEEDBACK_TYPE_FEEDBACK = "feedback";

const feedBackTypes = [
  {
    label: (
      <Translate>
        {({ translate }) => translate("feedback.form.i-have-a-problem")}
      </Translate>
    ),
    value: FEEDBACK_TYPE_PROBLEM
  },
  {
    label: (
      <Translate>
        {({ translate }) => translate("feedback.form.suggest-feature")}
      </Translate>
    ),
    value: FEEDBACK_TYPE_FEATURE
  },
  {
    label: (
      <Translate>
        {({ translate }) => translate("feedback.form.general-feedback")}
      </Translate>
    ),
    value: FEEDBACK_TYPE_FEEDBACK
  }
];

class FeedbackForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFeedbackForm: false,
      submitting: false,
      invalid: false
    };
  }

  onShow = () => {
    this.setState({ showFeedbackForm: true });
  };

  onHide = () => {
    this.setState({ showFeedbackForm: false });
  };

  onSubmit = values => {
    if (values.attachments) {
      return this.prepareAttachments(values.attachments).then(attachments => {
        const feedback = {
          ...values,
          type: values.type.value,
          attachments
        };
        return this.props.createFeedback(feedback).then(response => {
          displayToastMessageForResponse(
            this.props.translate("feedback.feedback"),
            response
          );

          if (!response.error) {
            this.onHide();
            return response;
          }
        });
      });
    }

    const feedback = {
      ...values,
      type: values.type.value
    };
    return this.props.createFeedback(feedback).then(response => {
      displayToastMessageForResponse(
        this.props.translate("feedback.feedback"),
        response
      );
      if (!response.error) {
        this.onHide();
        return response;
      }
    });
  };

  onTriggerSubmit = () => {
    if (this.state.showFeedbackForm) {
      document
        .getElementById("feedback-form")
        .dispatchEvent(new Event("submit", { cancelable: true }));
    }
  };
  onSubmitting = state => {
    this.setState({
      submitting: state.submitting,
      invalid: state.invalid
    });
  };

  prepareAttachments = files => {
    return new Promise((resolve, reject) => {
      FilesEncoder.createAttachments(files).then(result => {
        resolve(result);
      });
    });
  };

  render() {
    if (this.state.showFeedbackForm) {
      return (
        <Translate>
          {({ translate }) => (
            <React.Fragment>
              <Modal
                title={translate("feedback.form.label")}
                subTitle={translate("feedback.form.description")}
                onCloseModal={this.onHide}
                body={() => (
                  <Form
                    onSubmit={this.onSubmit}
                    validate={values => validate(values, translate)}
                    render={({ handleSubmit, form, values, invalid }) => (
                      <form id="feedback-form" onSubmit={handleSubmit}>
                        <Field
                          component={SelectBox}
                          name="type"
                          placeholder={translate(
                            "feedback.form.choose-type-of-feedback"
                          )}
                          options={feedBackTypes}
                        />
                        {values.type &&
                          (values.type.value === FEEDBACK_TYPE_FEATURE ||
                            values.type.value === FEEDBACK_TYPE_PROBLEM) && (
                            <Field
                              component={InputField}
                              name="subject"
                              label={translate("feedback.form.subject")}
                              placeholder={translate(
                                "feedback.form.enter-subject"
                              )}
                            />
                          )}
                        {values.type && values.type.value !== "" && (
                          <Field
                            component={TextArea}
                            label={translate("feedback.form.field-description")}
                            name="description"
                            placeholder={translate(
                              "feedback.form.field-description-placeholder"
                            )}
                          />
                        )}
                        {values.type &&
                          values.type.value === FEEDBACK_TYPE_PROBLEM && (
                            <FormGroup>
                              <BlockLabel>
                                {translate("feedback.form.where-problem-occur")}
                              </BlockLabel>
                              <StyledPlatfomWrapper>
                                <Field
                                  component={InlineCheckbox}
                                  name="platform.desktop"
                                  label={translate("feedback.form.desktop")}
                                />
                                <Field
                                  component={InlineCheckbox}
                                  name="platform.tablet"
                                  label={translate("feedback.form.tablet")}
                                />
                                <Field
                                  component={InlineCheckbox}
                                  name="platform.mobile"
                                  label={translate("feedback.form.mobile")}
                                />
                              </StyledPlatfomWrapper>
                            </FormGroup>
                          )}
                        {values.type &&
                          values.type.value === FEEDBACK_TYPE_PROBLEM && (
                            <Field component={FileUpload} name="attachments" />
                          )}
                        <FormSpy
                          subscription={{ submitting: true, invalid: true }}
                          onChange={formstate =>
                            this.onSubmitting({ ...formstate })
                          }
                        />
                      </form>
                    )}
                  />
                )}
                footer={() => (
                  <React.Fragment>
                    <SecondaryButton onClick={e => this.onHide()}>
                      {translate("feedback.form.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={this.state.submitting || this.state.invalid}
                      submitting={this.state.submitting}
                      type="submit"
                      onClick={e => this.onTriggerSubmit()}
                    >
                      {translate("feedback.form.submit")}
                    </PrimaryButton>
                  </React.Fragment>
                )}
              />
              {this.props.children()}
            </React.Fragment>
          )}
        </Translate>
      );
    }

    return this.props.children(this.onShow);
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators({ createFeedback }, dispatch);
export default connect(
  null,
  mapDispatchToProps
)(withLocalize(FeedbackForm));
