import axios from "axios";
import Cookies from "universal-cookie";

import { ROOT_DOMAIN_NO_HTTPS } from "./endpoints";
const MKHOST_LOCALE = "mkhost-locale";

export function getLocaleKey() {
  return MKHOST_LOCALE;
}

export function setApplicationLocale(locale = "mk") {
  axios.defaults.headers.common["Accept-Language"] = locale;
  try {
    const cookies = new Cookies();
    cookies.set(MKHOST_LOCALE, locale, {
      path: "/",
      domain: "." + ROOT_DOMAIN_NO_HTTPS
    });
    localStorage.setItem(MKHOST_LOCALE, locale);
  } catch (e) {
    console.log("Failed to set locale.");
  }
}

export function getApplicationLocale(defaultLocale = "mk") {
  const cookies = new Cookies();
  let locale = cookies.get(MKHOST_LOCALE);
  if (!locale) {
    locale = localStorage.getItem(MKHOST_LOCALE) || defaultLocale;
  }
  return locale;
}
