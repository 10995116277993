import React, { useEffect, useState } from "react";
import axios from 'axios';

import { MobileOnlyView, TabletView, BrowserView } from "react-device-detect";

import AccountPassword from "../../../components/AccountPassword";
import SecurityQuestion from "../../../components/SecurityQuestion";
import TwoStepAuthentication from "../../../components/TwoStepAuthentication";
import { CHECK_STATUS_2FA_ENPOINT } from "../../../services/endpoints";


import SecurityCodes from "../../../components/SecurityCodes/SecurityCodes";

const Security = ({
  validateChangePasswordSubmit,
  onChangePassword,
  isChangingPassword,
  

}) => {

  const [is2FAEnabled, setIs2FAEnabled] = useState(false);

  useEffect(() => {
    // Fetch data when component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(CHECK_STATUS_2FA_ENPOINT); 
        console.log(response);
        if (response.data.data['2fa'] === true) {
          setIs2FAEnabled(true);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  
  return (
    <div className="row">
      <MobileOnlyView renderWithFragment>
        <div className="col-12 col-sm-12 col-md-12">
          <AccountPassword
            validateChangePasswordSubmit={validateChangePasswordSubmit}
            onChangePassword={onChangePassword}
            isChangingPassword={isChangingPassword}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-12">
          <TwoStepAuthentication

          />
        </div>
        {is2FAEnabled && (
          <div className="col-12 col-sm-12 col-md-12">
            <SecurityCodes />
          </div>
        )}

      </MobileOnlyView>
      <TabletView renderWithFragment>
        <div className="col-6 col-sm-6 col-md-6">
          <AccountPassword
            validateChangePasswordSubmit={validateChangePasswordSubmit}
            onChangePassword={onChangePassword}
            isChangingPassword={isChangingPassword}
          />
        </div>
        <div className="col-6 col-sm-6 col-md-6">
          <TwoStepAuthentication

          />
        </div>
        {is2FAEnabled && (
          <div className="col-6 col-sm-6 col-md-6">
            <SecurityCodes />
          </div>
        )}
      </TabletView>
      <BrowserView renderWithFragment>
        <div className="col-6 col-sm-6 col-md-6">
          <AccountPassword
            validateChangePasswordSubmit={validateChangePasswordSubmit}
            onChangePassword={onChangePassword}
            isChangingPassword={isChangingPassword}
          />
        </div>
        <div className="col-6 col-sm-6 col-md-6">
          <TwoStepAuthentication

          />
        </div>
        {is2FAEnabled && (
          <div className="col-6 col-sm-6 col-md-6">
            <SecurityCodes />
          </div>
        )}
      </BrowserView>
    </div>
  );
};

export default Security;