import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Badge from "../Badge";

const BadgeInfo = styled(Badge)`
  background-color: ${theme.blue1}
  color: ${theme.blueBase};
`;

export default BadgeInfo;
