import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { isMobile } from "react-device-detect";
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";
import { Form, Field, FormSpy } from "react-final-form";

import Container from "../../../../components/Containers/Container";
import Icon from "../../../../components/Icon";
import InputField from "../../../../components/InputField";
import NoticeWarning from "../../../../components/Notices/NoticeWarning";
import CustomTooltip from "../../../../components/CustomTooltip";

import SelectBox from "../../../../components/SelectBox";
import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";
import SelectableProductAddon from "../../../../components/SelectableProductAddon";

import DeletingRecordLoader from "../../../../components/Loaders/DeletingRecordLoader";

import { validateRequired } from "../../../../common/validationRules";

import {
  CART_DOMAIN_TYPE_REGISTER,
  CART_DOMAIN_TYPE_TRANSFER
} from "../../CartDomainTypes/CartDomainTypes";

const StyledDomain = styled.div`
  padding: ${props => (props.isMobile ? "20px" : "50px")};
`;

const StyledRow = styled.div`
  margin-bottom: 30px;
  margin-top: ${props => props.isMobile && "20px"};
`;

const StyledTypeTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${theme.neutral4};
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const StyledDomainName = styled.h4`
  font-weight: 20px;
  font-weight: 600;
  font-size: 1.4;
  color: ${theme.neutralBase};
  word-break: break-word;
`;

const StyledDeleteIcon = styled.span`
  position: absolute;
  right: 0;
  top: ${props => (props.isMobile ? "-10px" : "-20px")};
  cursor: ${props => (props.isDeleting ? "default" : "pointer")};
`;

const StyledPriceBadge = styled.strong`
  text-transform: uppercase;
`;

const validate = values => {
  return {
    eppcode: validateRequired(values.eppcode)
  };
};

class Domain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eppcode: this.props.product.config.eppcode || "",
      submitting: false
    };
  }

  onUpdateDomainEPPCode = () => {
    this.props.onUpdateDomainEPPCode(this.props.product, this.state.eppcode);
  };

  onChangeDomainEPPCode = event => {
    this.setState({
      eppcode: event.target.value
    });
  };

  render() {
    const {
      product,
      onAttachServiceToProduct,
      onRemoveServiceFromProduct,
      onUpdateProductPeriod,
      onRemoveProductFromCart,
      updatingDomain,
      updatingEPPCode,
      onBlockCheckout
    } = this.props;
    const selectOptions = product.item.pricing[
      product.config.type
    ].billing_cycle.derivations.map(derivation => {
      return {
        label:
          derivation.numeric_code +
          (derivation.numeric_code > 1
            ? ` ${this.props.translate("cart.years")}`
            : ` ${this.props.translate("cart.year")}`),
        value: `${derivation.code}`,
        code: derivation.numeric_code
      };
    });
    const derivation = product.item.pricing[
      product.config.type
    ].billing_cycle.derivations.find(
      derivation => derivation.numeric_code === product.config.period
    );
    const selectedValue = {
      label:
        derivation.numeric_code +
        (derivation.numeric_code > 1
          ? ` ${this.props.translate("cart.years")}`
          : ` ${this.props.translate("cart.year")}`),
      value: `${derivation.code}`,
      code: derivation.numeric_code
    };

    return (
      <Translate>
        {({ translate }) => (
          <StyledRow className="row" isMobile={isMobile}>
            <div className="col-12 col-sm-12 col-md-12">
              <Container>
                <StyledDomain isMobile={isMobile}>
                  <StyledRow className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledDeleteIcon
                        isMobile={isMobile}
                        isDeleting={
                          this.props.deletingProductId === this.props.product.id
                        }
                        onClick={e =>
                          this.props.deletingProductId !==
                            this.props.product.id &&
                          onRemoveProductFromCart(product)
                        }
                      >
                        {this.props.deletingProductId !==
                          this.props.product.id && (
                          <Icon size="l" icon="delete" />
                        )}

                        {this.props.deletingProductId ===
                          this.props.product.id && <DeletingRecordLoader />}
                      </StyledDeleteIcon>
                      <StyledTypeTitle>
                        {product.config.type === CART_DOMAIN_TYPE_REGISTER
                          ? translate("cart.domain-registration")
                          : translate("cart.domain-transfer")}
                      </StyledTypeTitle>
                      <StyledDomainName>{product.domain}</StyledDomainName>
                    </div>
                  </StyledRow>
                  <StyledRow className="row">
                    <div className="col-6 col-sm-4 col-md-3">
                      {product.config.type === CART_DOMAIN_TYPE_REGISTER && (
                        <SelectBox
                          label={translate(
                            `cart.item-period-${product.config.type}`
                          )}
                          defaultValue={selectedValue}
                          options={selectOptions}
                          input={{
                            onChange: values =>
                              onUpdateProductPeriod(product, values)
                          }}
                          disabled={updatingDomain}
                          isLoading={updatingDomain}
                        />
                      )}
                      {product.config.type === CART_DOMAIN_TYPE_TRANSFER && (
                        <Form
                          onSubmit={this.onUpdateDomainEPPCode}
                          initialValues={{
                            eppcode: this.state.eppcode
                          }}
                          validate={validate}
                          render={({ handleSubmit }) => (
                            <form
                              id={`cart-domain-transfer-${product.id}`}
                              onSubmit={handleSubmit}
                            >
                              <Field
                                component={InputField}
                                name="eppcode"
                                label={translate("cart.item-epp-code")}
                                placeholder={translate("cart.domain-epp-code")}
                                onChange={this.onChangeDomainEPPCode}
                                onBlur={handleSubmit}
                                disabled={updatingEPPCode}
                                tooltip={
                                  <CustomTooltip
                                    content={translate(
                                      "cart.item-epp-code-tooltip"
                                    )}
                                  >
                                    <Icon size="l" icon="info" />
                                  </CustomTooltip>
                                }
                              />

                              <FormSpy
                                subscription={{
                                  invalid: true,
                                  valid: true,
                                  submitSucceeded: true
                                }}
                                onChange={formstate =>
                                  onBlockCheckout({
                                    formId: `cart-domain-transfer-${product.id}`,
                                    ...formstate
                                  })
                                }
                              />
                            </form>
                          )}
                        />
                      )}
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                      <DiscountedAmountBadge
                        label={translate("cart.item-price")}
                        amount={{
                          amount: product.total,
                          currency: derivation.price.currency.code,
                          discountedAmount:
                            product.total < product.price ? product.price : null
                        }}
                      />
                    </div>
                    {product.config.type === CART_DOMAIN_TYPE_TRANSFER &&
                      this.state.eppcode.length === 0 && (
                        <div className="col-6 col-sm-4 col-md-6">
                          <NoticeWarning>
                            {translate("cart.item-epp-code-empty")}
                          </NoticeWarning>
                        </div>
                      )}
                  </StyledRow>
                  {product.config.type === CART_DOMAIN_TYPE_REGISTER && (
                    <StyledRow className="row">
                      <div className="col-12 col-sm-12 col-md-12">
                        {product.item.services &&
                          product.item.services.length > 0 &&
                          product.item.services.map(service => (
                            <SelectableProductAddon
                              onSelect={e =>
                                service.activated
                                  ? onRemoveServiceFromProduct(service, product)
                                  : onAttachServiceToProduct(service, product)
                              }
                              key={`product-${product.id}-service-${service.id}`}
                              activated={service.activated}
                              disabled={service.updating}
                              addon={service}
                            >
                              {translate("cart.item-add")} {service.name}{" "}
                              <StyledPriceBadge>
                                {service.pricing.buy.billing_cycle
                                  .derivations[0].price.value > 0
                                  ? service.pricing.buy.billing_cycle
                                      .derivations[0].price.currency.code +
                                    " " +
                                    service.pricing.buy.billing_cycle
                                      .derivations[0].price.value
                                  : translate("general.free")}
                              </StyledPriceBadge>
                              {service.pricing.buy.billing_cycle.derivations[0]
                                .price.value > 0 && (
                                <span>
                                  /
                                  {translate(
                                    `price.derivations.${service.pricing.buy.billing_cycle.derivations[0].code}`
                                  )}
                                </span>
                              )}
                            </SelectableProductAddon>
                          ))}
                      </div>
                    </StyledRow>
                  )}
                </StyledDomain>
              </Container>
            </div>
          </StyledRow>
        )}
      </Translate>
    );
  }
}

Domain.defaultProps = {
  product: {
    domainName: "sanjazakovska",
    type: "domain"
  }
};

export default withLocalize(Domain);
