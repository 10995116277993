import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes/index";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import { isMobileOnly, isTablet } from "react-device-detect";

import graphicsEmpty from "../../../assets/images/icon-illustration-empty.png";

import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";

const StyledDomainsEmptyState = styled.div`
  padding: 50px 0 25px;
`;

const StyledDomainsEmptyStateContainer = styled.div`
  background-color: ${theme.white};
  display: inline-block;
  padding: ${props => (props.isMobile ? "70px 15px" : "100px 80px")};
  max-width: 600px;

  & img {
    margin-bottom: 30px;
    widht: 120px;
    height: 120px;
  }

  & .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    text-align: center;
    margin-top: 5px;
  }

  & .empty-cart-text {
    font-size: 16px;
    line-height: 1.5;
    color: ${theme.neutral6};
    text-align: center;
    margin-bottom: 50px;
  }
`;

const DomainsEmptyState = ({ shopDomainsRoute, ...rest }) => (
  <Translate>
    {({ translate }) => (
      <StyledDomainsEmptyState isMobile={isMobileOnly || isTablet}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12 text-center justify-content-center">
            <StyledDomainsEmptyStateContainer isMobile={isMobileOnly}>
              <img src={graphicsEmpty} alt="" />
              <h4 className="title">{translate("domains.no-domains")}</h4>
              <p className="empty-cart-text">
                {translate("domains.no-domains-description")}
              </p>
              <div className="row">
                <div
                  className={
                    isMobileOnly
                      ? "col-12 col-sm-6 col-md-6 mb-1  justify-content-center"
                      : "col-12 col-sm-6 col-md-6 text-right justify-content-center"
                  }
                >
                  <Link
                    to={{
                      pathname: shopDomainsRoute,
                      state: { selectedForm: "transfer" }
                    }}
                  >
                    <SecondaryButton>
                      {translate("domains.transfer-in-domain")}
                    </SecondaryButton>
                  </Link>
                </div>
                <div
                  className={
                    isMobileOnly
                      ? "col-12 col-sm-6 col-md-6 justify-content-center"
                      : "col-12 col-sm-6 col-md-6 text-left justify-content-center"
                  }
                >
                  <Link to={shopDomainsRoute}>
                    <PrimaryButton>
                      {translate("domains.visit-domain-shop")}
                    </PrimaryButton>
                  </Link>
                </div>
              </div>
            </StyledDomainsEmptyStateContainer>
          </div>
        </div>
      </StyledDomainsEmptyState>
    )}
  </Translate>
);

export default DomainsEmptyState;
