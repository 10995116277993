import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Translate } from "react-localize-redux";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";

import SelectBox from "../SelectBox";
import PaginationItem from "../../components/Pagination/PaginationItem";
import PaginationNext from "../../components/Pagination/PaginationNext";
import PaginationPrevious from "../../components/Pagination/PaginationPrevious";

const StyledPagination = styled.nav``;

const StyledPaginationList = styled.ul`
  margin-bottom: 0;
`;

const StyledPaginationWrapper = styled.div`
  padding-left: ${props => (props.isMobile ? "15px" : "25px")};
  padding-right: ${props => (props.isMobile ? "15px" : "25px")};
  border-top: 1px solid ${theme.neutral3};

  padding-top: ${props => (props.isMobile ? "15px" : "25px")};

  & .show-records-count {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 6px;
    margin-bottom: 5px;
  }
`;

const StyledPaginationRecords = styled.div`
  & .show-records {
    display: inline-block;
    margin-right: 10px;
  }

  & .show-records:last-child {
    margin-left: ${props => !props.isMobile && "10px"};
    margin-right: 0;
  }

  & .form-group {
    display: inline-block;
  }

  & .react-select-container input {
    min-width: 15px;
  }
`;

const StyledNumberOfRecordsDropDown = styled.div`
  width: 100px
  display: inline-block;
`;

const FIRST_PAGE = 1;
const NUMBER_OF_PREVIOUS_PAGES_TO_SHOW = 2;
const NUMBER_OF_NEXT_PAGES_TO_SHOW = 2;

class Pagination extends React.Component {
  render() {
    let paginationItems = [];

    let startPage =
      this.props.paging.current_page > FIRST_PAGE
        ? this.props.paging.current_page - NUMBER_OF_PREVIOUS_PAGES_TO_SHOW
        : this.props.paging.current_page;

    if (startPage === 0) {
      startPage = FIRST_PAGE;
    }

    const endPage =
      this.props.paging.current_page + NUMBER_OF_NEXT_PAGES_TO_SHOW <=
      this.props.paging.total_pages
        ? this.props.paging.current_page + NUMBER_OF_NEXT_PAGES_TO_SHOW
        : this.props.paging.total_pages;

    if (!isMobileOnly) {
      if (
        this.props.paging.current_page - NUMBER_OF_PREVIOUS_PAGES_TO_SHOW - 1 >=
        FIRST_PAGE
      ) {
        paginationItems.push(
          <PaginationItem
            key={
              this.props.paging.current_page -
                NUMBER_OF_PREVIOUS_PAGES_TO_SHOW -
                1 >=
              FIRST_PAGE
                ? this.props.paging.current_page -
                  NUMBER_OF_PREVIOUS_PAGES_TO_SHOW -
                  1
                : FIRST_PAGE
            }
            active={
              this.props.paging.current_page -
                NUMBER_OF_PREVIOUS_PAGES_TO_SHOW ===
              this.props.paging.current_page
            }
            link="#"
            page={
              this.props.paging.current_page -
                NUMBER_OF_PREVIOUS_PAGES_TO_SHOW -
                1 >=
              FIRST_PAGE
                ? this.props.paging.current_page -
                  NUMBER_OF_PREVIOUS_PAGES_TO_SHOW -
                  1
                : FIRST_PAGE
            }
            linkHandler={this.props.handlePaginationClick}
          >
            ...
          </PaginationItem>
        );
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <PaginationItem
          key={i}
          active={i === this.props.paging.current_page}
          link="#"
          page={i}
          linkHandler={this.props.handlePaginationClick}
        >
          {i}
        </PaginationItem>
      );
    }

    if (!isMobileOnly) {
      if (endPage < this.props.paging.total_pages) {
        paginationItems.push(
          <PaginationItem
            key={endPage + 1}
            active={endPage + 1 === this.props.paging.current_page}
            link="#"
            page={endPage + 1}
            linkHandler={this.props.handlePaginationClick}
          >
            ...
          </PaginationItem>
        );
      }
    }

    let layout = "";
    if (isMobileOnly) {
      layout = "col-12 col-sm-12 col-md-12";
    }

    if (isTablet) {
      layout = "col-12 col-sm-12 col-md-12";
    }

    if (isBrowser) {
      layout = "col-6 col-sm-6 col-md-6";
    }
    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <StyledPaginationWrapper isMobile={isMobileOnly}>
                <div className="row">
                  <div className={layout}>
                    <StyledPaginationRecords isMobile={isMobileOnly}>
                      <span className="show-records">
                        {translate("pagination.show")}
                      </span>
                      <StyledNumberOfRecordsDropDown>
                        <SelectBox
                          className="react-select-container"
                          searchable={false}
                          value={{
                            value: this.props.paging.limit,
                            label: this.props.paging.limit
                          }}
                          async={false}
                          autosize={true}
                          options={[
                            { value: 10, label: 10 },
                            { value: 20, label: 20 },
                            { value: 30, label: 30 },
                            { value: 40, label: 40 },
                            { value: 50, label: 50 }
                          ]}
                          input={{
                            onChange: this.props.onPerPageOptionChanged
                          }}
                        />
                      </StyledNumberOfRecordsDropDown>

                      <span className="show-records">
                        {!isMobileOnly && this.props.itemsName}{" "}
                        {translate("pagination.per-page")}
                      </span>
                    </StyledPaginationRecords>
                  </div>
                  <div className={layout}>
                    {isMobileOnly && (
                      <span className="show-records-count d-block text-right mb-2">
                        {translate("pagination.showing")}{" "}
                        {(this.props.paging.current_page - 1) *
                          this.props.paging.limit +
                          1}
                        -
                        {this.props.paging.current_page *
                          this.props.paging.limit >=
                        this.props.paging.total
                          ? this.props.paging.total
                          : this.props.paging.current_page *
                            this.props.paging.limit}{" "}
                        {translate("pagination.of")} {this.props.paging.total}{" "}
                        {translate("pagination.records")}
                      </span>
                    )}
                    <StyledPagination>
                      <StyledPaginationList className="pagination justify-content-end">
                        {!isMobileOnly && (
                          <span className="show-records-count">
                            {translate("pagination.showing")}{" "}
                            {(this.props.paging.current_page - 1) *
                              this.props.paging.limit +
                              1}
                            -
                            {this.props.paging.current_page *
                              this.props.paging.limit >=
                            this.props.paging.total
                              ? this.props.paging.total
                              : this.props.paging.current_page *
                                this.props.paging.limit}{" "}
                            {translate("pagination.of")}{" "}
                            {this.props.paging.total}{" "}
                            {translate("pagination.records")}
                          </span>
                        )}
                        <PaginationPrevious
                          linkHandler={this.props.handlePaginationClick}
                          page={this.props.paging.previous_page}
                          disabled={0 === this.props.paging.current_page}
                        />
                        {paginationItems}
                        <PaginationNext
                          linkHandler={this.props.handlePaginationClick}
                          page={this.props.paging.next_page}
                          disabled={
                            this.props.paging.total_pages ===
                            this.props.paging.current_page
                          }
                        />
                      </StyledPaginationList>
                    </StyledPagination>
                  </div>
                </div>
              </StyledPaginationWrapper>
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

Pagination.defaultProps = {
  paging: {
    limit: 10,
    current_page: 0,
    next_page: 0,
    previous_page: 0,
    total_pages: 0,
    total: 0
  },
  handlePaginationClick: () => {},
  onPerPageOptionChanged: () => {}
};

export default Pagination;
