import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FORM_ERROR } from "final-form";
import qs from "query-string";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import { BrowserView, MobileView, isMobileOnly } from "react-device-detect";

import styled from "styled-components";
import { theme } from "../../../design/Themes";

import { resetPassword, validateResetToken } from "../actions/authActions";
import { isAuthenticated } from "../reducers/authReducer";

import ResetPasswordForm from "./ResetPasswordForm";

import ContentLoader from "../../../components/Loaders/ContentLoader";
import withLoading from "../../../components/Loaders/WithLoading";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";

import {
  validatePassword,
  validatePasswordWithConfirm
} from "../../../common/validationRules";

import { ROUTE_LOGIN, ROUTE_FORGOT_PASSWORD } from "../../../routes/routes";

import whiteLogo from "../../../assets/images/white-logo.svg";

const StyledLogoContainer = styled.div`
  background-color: ${theme.neutralBase};
  position: relative;
  text-align: center;
  vertical-align: middle;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const StyledMobileLogoContainer = styled(StyledLogoContainer)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1031;
  border-radius: 0;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const StyledResetPasswordFormContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 30px;
`;

const StyledResetPasswordFormWrapper = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledLogo = styled.img`
  position: relative;
  top: 45%;
`;

const validateResetPassword = values => {
  return {
    password: validatePassword(values.password),
    confirm_password: validatePasswordWithConfirm(
      values.password,
      values.confirm_password
    )
  };
};

const StyledResetTokenContainer = styled.div`
  padding: 50px;
  background-color: ${theme.white};
  border: 1px solid ${theme.neutral3};
  border-left: ${props => !props.isMobile && "0"};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledResetTokenTitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  margin-bottom: 15px;
`;
const StyledResetTokenDescription = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: ${theme.neutral6};
  margin-bottom: 50px;
`;

const StyledResetPasswordFormContainerWithLoading = withLoading(
  StyledResetPasswordFormContainer,
  ContentLoader
);

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      validToken: false,
      validatingToken: false,
      reCaptchaToken: null
    };
  }

  componentDidMount() {
    const params = qs.parse(this.props.location.search);
    if (!params.token || params.token.length === 0) {
      this.props.history.push(ROUTE_LOGIN);
    }

    this.setState({
      validatingToken: true
    });
    this.props
      .validateResetToken(params.token)
      .then(valid => {
        this.setState({
          validToken: valid,
          validatingToken: false
        });
      })
      .catch(error => {
        this.setState({
          validToken: false,
          validatingToken: true
        });
      });
  }

  onResetPassword = values => {
    const params = qs.parse(this.props.location.search);
    if (!params.token || params.token.length === 0) {
      return false;
    }

    return this.props
      .resetPassword(
        values.password,
        values.confirm_password,
        params.token,
        this.state.reCaptchaToken
      )
      .then(
        () => {
          this.props.history.push(ROUTE_LOGIN);
        },
        err => {
          const { messages } = err.response.data.data;
          return {
            [FORM_ERROR]: messages[0]
          };
        }
      )
      .catch(error => {
        const { messages } = error.response.data.data;

        if (window.grecaptcha) {
          window.grecaptcha
            .execute(process.env.REACT_APP_MKHOST_GOOGLE_RECAPTCHA_KEY)
            .then(newToken => {
              this.onSetReCaptchaToken(newToken);
            });
        }
        return {
          [FORM_ERROR]: messages[0]
        };
      });
  };

  onSetReCaptchaToken = token => {
    this.setState({
      reCaptchaToken: token
    });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <BrowserView viewClassName="col-6 offset-3 col-sm-6 offset-sm-3 col-md-6 offset-md-3">
              <StyledResetPasswordFormContainerWithLoading
                isLoading={this.state.validatingToken}
                className="row no-gutters"
              >
                <StyledLogoContainer className="col-6 col-sm-6 col-md-6">
                  <Link to={ROUTE_LOGIN}>
                    <StyledLogo src={whiteLogo} alt="MKHost" />
                  </Link>
                </StyledLogoContainer>
                <StyledResetPasswordFormWrapper className="col-6 col-sm-6 col-md-6">
                  {this.state.validToken && (
                    <ResetPasswordForm
                      onSubmit={this.onResetPassword}
                      validate={validateResetPassword}
                      onSetReCaptchaToken={this.onSetReCaptchaToken}
                    />
                  )}
                  {!this.state.validToken && (
                    <StyledResetTokenContainer>
                      <StyledResetTokenTitle>
                        {translate("validation.password-reset-token-expired")}
                      </StyledResetTokenTitle>
                      <StyledResetTokenDescription>
                        {translate(
                          "validation.password-reset-token-expired-description"
                        )}
                      </StyledResetTokenDescription>
                      <div className="text-center">
                        <Link
                          to={ROUTE_FORGOT_PASSWORD}
                          className="d-block w-100"
                        >
                          <PrimaryButton className="d-block w-100">
                            {translate("general.try-again")}
                          </PrimaryButton>
                        </Link>
                      </div>
                    </StyledResetTokenContainer>
                  )}
                </StyledResetPasswordFormWrapper>
              </StyledResetPasswordFormContainerWithLoading>
            </BrowserView>
            <MobileView viewClassName="col-12 col-sm-12 col-md-12">
              <StyledResetPasswordFormContainerWithLoading
                isLoading={this.state.validatingToken}
                isMobile={isMobileOnly}
                className="row"
              >
                <StyledMobileLogoContainer className="col-12 col-sm-12 col-md-12">
                  <Link to={ROUTE_LOGIN}>
                    <StyledLogo src={whiteLogo} alt="MKHost" />
                  </Link>
                </StyledMobileLogoContainer>
                <StyledResetPasswordFormWrapper className="col-12 col-sm-12 col-md-12">
                  {this.state.validToken && (
                    <ResetPasswordForm
                      onSubmit={this.onResetPassword}
                      validate={validateResetPassword}
                      onSetReCaptchaToken={this.onSetReCaptchaToken}
                    />
                  )}
                  {!this.state.validToken && (
                    <StyledResetTokenContainer>
                      <StyledResetTokenTitle>
                        {translate("validation.password-reset-token-expired")}
                      </StyledResetTokenTitle>
                      <StyledResetTokenDescription>
                        {translate(
                          "validation.password-reset-token-expired-description"
                        )}
                      </StyledResetTokenDescription>
                      <div className="text-center">
                        <Link
                          to={ROUTE_FORGOT_PASSWORD}
                          className="d-block w-100"
                        >
                          <PrimaryButton className="d-block w-100">
                            {translate("general.try-again")}
                          </PrimaryButton>
                        </Link>
                      </div>
                    </StyledResetTokenContainer>
                  )}
                </StyledResetPasswordFormWrapper>
              </StyledResetPasswordFormContainerWithLoading>
            </MobileView>
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      resetPassword,
      validateResetToken
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword);
