import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { isMobileOnly } from "react-device-detect";

import Icon from "../../components/Icon";

const StyledFilter = styled.div`
  padding-top: ${props => (props.isMobile ? "20px" : "30px")};
  padding-bottom: ${props => (props.isMobile ? "20px" : "30px")};

  & .filter-header {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: ${props => (props.isMobile ? "0" : "25px")};
    border-bottom: ${props => !props.isMobile && `1px solid ${theme.neutral3}`};
  }

  & .filter-header h4 {
    color: ${theme.neutral5};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    text-transform: uppercase;
    margin-bottom: 0;
    cursor: ${props => (props.isMobile ? "pointer" : "default")};
  }

  & .filter-header span.filter-count {
    font-size: 12px;
    line-height: 1.33;
    background-color: ${theme.blueBase};
    color: ${theme.white};
    text-align: center;
    padding: 1px 6px;
    border-radius: 3px;
    margin-left: 5px;
  }

  & .filter-header span.filter-clear {
    font-size: 14px;
    font-weight: 600;
    color: ${props =>
      props.count > 0 ? props.theme.blueBase : props.theme.neutral4};
    line-height: 1.43;
    letter-spacing: 0.2px;
    cursor: ${props => (props.count > 0 ? "pointer" : "default")};
  }
`;

const StyledFilterFieldsContainer = styled.div`
  padding: 25px;
`;

class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
  }

  onToggle = () => {
    if (isMobileOnly) {
      this.setState({
        opened: !this.state.opened
      });
    }
  };

  render() {
    const { title, children, onClear, ...rest } = this.props;
    return (
      <StyledFilter {...rest} isMobile={isMobileOnly}>
        <div className="filter-header">
          <div className="row">
            <div className="col-6 col-sm-6 col-md-6">
              <h4 onClick={e => this.onToggle()}>
                {isMobileOnly && (
                  <Icon
                    icon={this.state.opened ? "chevron_up" : "chevron_down"}
                  />
                )}
                {title}{" "}
                {rest.count > 0 && (
                  <span className="filter-count">{rest.count}</span>
                )}
              </h4>
            </div>
            <div className="col-6 col-sm-6 col-md-6 text-right">
              <span
                className="filter-clear"
                onClick={e => rest.count > 0 && onClear()}
              >
                {rest.clearTitle}
              </span>
            </div>
          </div>
        </div>
        {isMobileOnly &&
          this.state.opened && (
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <StyledFilterFieldsContainer>
                  {children}
                </StyledFilterFieldsContainer>
              </div>
            </div>
          )}
        {!isMobileOnly && (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <StyledFilterFieldsContainer>
                {children}
              </StyledFilterFieldsContainer>
            </div>
          </div>
        )}
      </StyledFilter>
    );
  }
}

Filter.defaultProps = {
  title: "Filter",
  clearTitle: "Clear all",
  count: 0,
  onClear: () => {}
};

export default Filter;
