import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "custom-event-polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { BrowserRouter } from "react-router-dom";
import { LocalizeProvider } from "react-localize-redux";
import Cookies from "universal-cookie";
import * as Sentry from "@sentry/browser";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import rootReducer from "./core/rootReducer";

import { setAuthorizationToken, getToken } from "./services/authService";
import {
  setApplicationLocale,
  getApplicationLocale,
  getLocaleKey
} from "./services/localizationService";
import bootApplication from "./core/bootApplication";
import redirectIfSessionExpired from "./core/redirectIfSessionExpired";

import "./index.css";
import "./assets/customcss/ReactToastify.css";
import "rc-tooltip/assets/bootstrap.css";
import "./assets/customcss/rc-tooltip.css";

import App from "./App";

if (process.env.NODE_ENV === "production") {
  Sentry.init({ dsn: process.env.REACT_APP_MKHOST_SENTRY_DSN });
}

try {
  const cookies = new Cookies();

  setAuthorizationToken(getToken());
  if (cookies.get(getLocaleKey())) {
    setApplicationLocale(cookies.get(getLocaleKey()));
  } else {
    setApplicationLocale(getApplicationLocale());
  }
} catch (e) {
  console.log(e);
}
// define composeEnhancers
let composeEnhancers = null;
if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
  composeEnhancers = compose;
}
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

redirectIfSessionExpired(store.dispatch);
bootApplication(store.dispatch);

ReactDOM.render(
  <Provider store={store}>
    <LocalizeProvider>
      <BrowserRouter>
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_MKHOST_GOOGLE_RECAPTCHA_KEY}
        >
          <App />
        </GoogleReCaptchaProvider>
      </BrowserRouter>
    </LocalizeProvider>
  </Provider>,
  document.getElementById("root")
);
