import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobileOnly, isTablet } from "react-device-detect";

import { Translate } from "react-localize-redux";
import styled from "styled-components";

import Breadcrumb from "../../../components/Breadcrumb";
import BreadcrumbItem from "../../../components/Breadcrumb/BreadcrumbItem";
import Modal from "../../../components/Modal";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import ContentLoader from "../../../components/Loaders/ContentLoader";
import withLoading from "../../../components/Loaders/WithLoading";

import TicketDetails from "./TicketDetails";
import Attachments from "./Attachments";
import Replies from "./Replies";

import TicketNotFound from "./TicketNotFound";

import loadingGif from "../../../assets/images/loading.gif";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import {
  fetchTicket,
  viewTicket,
  fetchAttachment
} from "../actions/helpdeskActions";
import {
  getSelectedTicket,
  isFetchingTickets
} from "../reducers/helpdeskReducer";

import {
  ROUTE_INDEX,
  ROUTE_HELPDESK,
  ROUTE_CREATE_TICKET
} from "../../../routes/routes";

import { HELPDESK_TICKETS_TICKET_ATTACHMENT_BASE_ENDPOINT } from "../../../services/endpoints";

import { downloadFile } from "../../../utils/downloadFile";

const StyledWrapper = styled.div`
  margin-bottom: ${props => (props.isMobile ? "100px" : "30px")};
`;

const StyledTicketWrapper = styled.div``;

const StyledTicketWrapperWithLoading = withLoading(
  StyledTicketWrapper,
  ContentLoader
);

class OpenTicket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAttachmentModal: false,
      attachmentLoading: false,
      attachmentData: null
    };
  }

  componentDidMount() {
    const ticketId = this.props.match.params.id;
    this.props.fetchTicket(ticketId).then(ticket => {
      this.props.viewTicket(ticket);
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      const ticketId = this.props.match.params.id;
      this.props.fetchTicket(ticketId).then(ticket => {
        this.props.viewTicket(ticket);
      });
    }
  }

  onHideAttachmentModal = () => {
    this.setState({
      showAttachmentModal: false,
      attachmentData: null
    });
  };

  onShowAttachmentModal = attachmentSource => {
    if (this.isNonImageAttachment(attachmentSource)) {
      this.downloadAttachment(attachmentSource);
      return;
    }

    this.setState(
      {
        showAttachmentModal: true,
        attachmentLoading: true
      },
      () => {
        this.updateAttachmentIframe(
          HELPDESK_TICKETS_TICKET_ATTACHMENT_BASE_ENDPOINT + attachmentSource
        );
      }
    );
  };

  updateAttachmentIframe(attachmentSource) {
    this.props.fetchAttachment(attachmentSource).then(data => {
      this.setState({
        attachmentLoading: false,
        attachmentData: "data:image/jpeg;base64," + data
      });
    });
  }

  isNonImageAttachment = fileName => {
    const fileType = fileName.substr(fileName.lastIndexOf(".")).toLowerCase();

    // Remove this magic implementation
    if (fileType === ".pdf" || fileType === ".txt") {
      return true;
    }

    return false;
  };

  downloadAttachment(attachmentSource) {
    this.props
      .fetchAttachment(
        HELPDESK_TICKETS_TICKET_ATTACHMENT_BASE_ENDPOINT + attachmentSource,
        true
      )
      .then(data => {
        downloadFile(data, attachmentSource);
      });
  }

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <StyledWrapper
            className="row open-ticket-wrapper"
            isMobile={isMobileOnly}
          >
            <div className="col-12 col-sm-12 col-md-12">
              {!this.props.ticket && !this.props.isFetchingTickets && (
                <TicketNotFound openNewTicketRoute={ROUTE_CREATE_TICKET} />
              )}
              <StyledTicketWrapperWithLoading
                isLoading={this.props.isFetchingTickets}
              >
                {this.props.ticket && (
                  <Breadcrumb>
                    <BreadcrumbItem
                      to={ROUTE_INDEX}
                      title={translate("breadcrumbs.home")}
                    />
                    <BreadcrumbItem
                      to={ROUTE_HELPDESK}
                      title={translate("breadcrumbs.help-desk")}
                    />
                  </Breadcrumb>
                )}
                {this.props.ticket && (
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          {this.props.ticket && (
                            <TicketDetails
                              ticket={this.props.ticket}
                              showAttachmentModal={this.onShowAttachmentModal}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                      <Replies
                        showAttachmentModal={this.onShowAttachmentModal}
                        ticket={this.props.ticket}
                      />
                    </div>
                  </div>
                )}
              </StyledTicketWrapperWithLoading>
            </div>

            {this.state.showAttachmentModal && (
              <Modal
                size="xl"
                title={translate("help-desk.ticket.attachment")}
                onCloseModal={this.onHideAttachmentModal}
                body={() => (
                  <div className="text-center">
                    <img
                      className="img-fluid"
                      alt=""
                      src={
                        this.state.attachmentLoading
                          ? loadingGif
                          : this.state.attachmentData
                      }
                    />
                  </div>
                )}
                footer={() => (
                  <Fragment>
                    <SecondaryButton
                      onClick={() => this.onHideAttachmentModal()}
                    >
                      {translate("help-desk.ticket.close")}
                    </SecondaryButton>
                  </Fragment>
                )}
              />
            )}
          </StyledWrapper>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    ticket: getSelectedTicket(state),
    isFetchingTickets: isFetchingTickets(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTicket,
      viewTicket,
      fetchAttachment
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenTicket);
