import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Translate } from "react-localize-redux";

const StyledMostPopularArticles = styled.div`
  padding: 25px 25px 15px 25px;
`;
const StyledTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
`;
const StyledDescription = styled.h4`
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.neutral6};
  margin-bottom: 30px;
`;

const StyledMostPopularArticlesList = styled.div``;

const MostPopularArticles = ({ children }) => (
  <Translate>
    {({ translate }) => (
      <StyledMostPopularArticles>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <StyledTitle>
              {translate("help-desk.most-popular-articles.title")}
            </StyledTitle>
            <StyledDescription>
              {translate("help-desk.most-popular-articles.description")}
            </StyledDescription>

            <StyledMostPopularArticlesList>
              {children}
            </StyledMostPopularArticlesList>
          </div>
        </div>
      </StyledMostPopularArticles>
    )}
  </Translate>
);

export default MostPopularArticles;
