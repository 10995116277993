import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Translate } from "react-localize-redux";

import Icon from "../../../components/Icon";

import graphicBankTransfer from "./bank.png";

const StyledBankTransferPaymentMethond = styled.div`
  position: relative;
  background-color: ${props => (props.selected ? theme.blue1 : theme.white)};
  border: 1px solid
    ${props => (props.selected ? theme.blueBase : theme.neutral3)};
  border-radius: 3px;
  padding: 20px;
  cursor: pointer;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 15px;
  }
`;

const StyledSelectedIcon = styled.span`
  position: absolute;
  top: -5px;
  right: -5px;
  color: ${theme.blueBase};
`;
const StyledBankTransferIcon = styled.img`
  position: absolute;
  left: 10px;
  width: 50px;
  height: 50px;
`;

const StyledMethodDetails = styled.div`
  padding-left: 70px;
`;
const StyledMethodTitle = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
  color: ${theme.neutralBase};
  margin-bottom: 2px;
`;
const StyledMethodDescription = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
`;

const BankTransferPaymentMethod = ({
  selected,
  onSelectPayment,
  paymentMethod,
  description
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <StyledBankTransferPaymentMethond
          className="row"
          onClick={e => onSelectPayment(paymentMethod.module)}
          selected={selected}
        >
          <div className="col-12 col-sm-12 col-md-12">
            {selected && (
              <StyledSelectedIcon>
                <Icon icon="success" />
              </StyledSelectedIcon>
            )}

            <StyledMethodDetails>
              <StyledBankTransferIcon src={graphicBankTransfer} />
              <StyledMethodTitle>
                {translate("pro-invoices.view.bank-transfer")}
              </StyledMethodTitle>
              <StyledMethodDescription>
                {description
                  ? description
                  : translate("pro-invoices.view.bank-transfer-description")}
              </StyledMethodDescription>
            </StyledMethodDetails>
          </div>
        </StyledBankTransferPaymentMethond>
      )}
    </Translate>
  );
};

BankTransferPaymentMethod.defaultProps = {
  selected: false
};

export default BankTransferPaymentMethod;
