import React, {Component} from "react";
import "../../assets/customcss/CookieBannner.css";
import {Translate} from "react-localize-redux";
import PrimaryButton from "../Buttons/PrimaryButton";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {theme} from "../../design/Themes";
import Modal from "../Modal";
import InputField from "../InputField";
import SecondaryButton from "../Buttons/SecondaryButton";

const StyledCheckbox = styled.div`
  & {
    width: 100%;
    cursor: pointer;
   
    padding-left: 0;
    margin-bottom:10px;
  }

`;

class CookieBanner extends Component {
  constructor() {
    super();
    this.state = {
      isActive: false,
      defaultCookies: true,
      marketingCookies: false,
      analyticsCookies: false,
      showDomainModal: false
    }
  }

  onShowModal = product => {
    this.setState({
      hosting: product,
      showDomainModal: true
    });
  };

  onCloseModal = () => {
    this.setState({
      showDomainModal: false
    });
  };

  componentDidMount() {
    setTimeout(() => {
      if (!window.localStorage.getItem("mkhost-cookie")) {
        this.setState({isActive: true})
      }
    }, 2000);

  }

  acceptCookie() {
    this.setState({isActive: false})
    window.localStorage.setItem("mkhost-cookie", JSON.stringify({
      accepted: true,
      defaultCookies: this.state.defaultCookies,
      marketingCookies: this.state.marketingCookies,
      analyticsCookies: this.state.analyticsCookies
    }));
  }
  acceptAllCookie() {
    this.setState({isActive: false})
    window.localStorage.setItem("mkhost-cookie", JSON.stringify({
      accepted: true,
      defaultCookies:true,
      marketingCookies: true,
      analyticsCookies: true
    }));
  }
  rejectCookie() {
    this.setState({isActive: false})
    window.localStorage.setItem("mkhost-cookie", JSON.stringify({
      accepted: false,
      defaultCookies: true,
      marketingCookies: false,
      analyticsCookies: false
    }));
  }

  setActive(event, type){
    if(type=== 'marketing'){
      this.setState({
        marketingCookies: event.target.checked
      })
    }
    if(type ==='analytics'){
      this.setState({
        analyticsCookies: event.target.checked
      })
    }
  }


  render() {
    return (
        <Translate>
          {({translate, activeLanguage}) => (
              <div>
                {this.state.isActive &&
                <div className="cookie-container active">
                  <p className="cookies-text">
                    {translate(
                        "this-web-site-use-cookies"
                    )}
                    <a  href="https://mkhost.com/mk/other/legal/" >
                      {translate(
                          "privacy-policy"
                      )}
                    </a>.

                  </p>


                  <p className="cookies-text">
                    {translate(
                        "choose-options"
                    )}

                  </p>


                  <div className="row d-flex justify-content-center">


                    <button className="cookie-btn col-sm-3" onClick={() => this.acceptAllCookie()}>
                      {translate(
                          "accept"
                      )}
                    </button>
                    <button className="cookie-btn-rej col-sm-3" onClick={() => this.rejectCookie()}>
                      {translate(
                          "reject-cookies"
                      )}
                    </button>

                  </div>

                  <div className="row d-flex justify-content-center">
                    <div onClick={() => {
                      this.setState({showDomainModal: true})
                    }} className="cookie-btn-privacy col-sm-3 text-center">
                      {translate(
                          "setup-cookies"
                      )}
                    </div>
                  </div>


                  {this.state.showDomainModal && (
                      <Modal
                          title={translate("setup-cookies")}

                          onCloseModal={this.onCloseModal}
                          body={() => (
                              <div className="row">

                                <div className="col-12 col-sm-12 col-md-12">


                                  <StyledCheckbox>
                                    <input type={'checkbox'} id={'check-1'}
                                           checked={this.state.defaultCookies}
                                           disabled={true}
                                    />
                                    <label className="cookies-check"
                                           htmlFor={'check-1'}>{translate("necessary-cookies"
                                    )}</label>
                                  </StyledCheckbox>

                                  <StyledCheckbox>
                                    <input type={'checkbox'} id={'check-2'}
                                           onChange={(event) => this.setActive(event, 'analytics')}/>
                                    <label className="cookies-check"
                                           htmlFor={'check-2'}>{translate("analytics-cookies"
                                    )}</label>
                                  </StyledCheckbox>


                                  <StyledCheckbox>
                                    <input type={'checkbox'} id={'check-3'}
                                           onChange={(event) => this.setActive(event, 'marketing')}/>
                                    <label className="cookies-check"
                                           htmlFor={'check-3'}>  {translate("marketing-cookies"
                                    )}</label>
                                  </StyledCheckbox>


                                  <div className="row d-flex justify-content-center ">


                                    <button className="cookie-btn col-sm-10"
                                            onClick={() => this.acceptCookie()}>{translate(
                                        "accept-cookies-all"
                                    )}
                                    </button>
                                 {/*   <button className="cookie-btn col-sm-5"
                                            onClick={() => this.acceptCookie()}>{translate(
                                        "reject-cookies"
                                    )}
                                    </button>*/}

                                  </div>

                                </div>
                              </div>
                          )}
                      />
                  )}
                </div>


                }
              </div>
          )}
        </Translate>
    )
  }

}

export default CookieBanner;