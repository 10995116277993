import React from "react";
import styled from "styled-components";
import parse from "html-react-parser";

import { isMobileOnly } from "react-device-detect";

import TableBody from "../../../../../components/Table/TableBody";
import TableRow from "../../../../../components/Table/TableRow";
import TableCell from "../../../../../components/Table/TableCell";
import BadgeSuccess from "../../../../../components/Badges/BadgeSuccess";
import BadgeDanger from "../../../../../components/Badges/BadgeDanger";
import CustomTooltip from "../../../../../components/CustomTooltip";
import Icon from "../../../../../components/Icon";

import { Translate } from "react-localize-redux";

import { REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE } from "../../../../../config/promotions";

const StyledPriceHolder = styled.span`
  text-transform: uppercase;

  & .badge {
    margin-bottom: 0;
  }
  & .badge-success {
    margin-left: 10px;
  }

  & .custom-tooltip {
    vertical-align: middle;
  }
`;

const BlockSpan = styled.span`
  display: block;
  & .custom-tooltip {
    vertical-align: middle;
  }
`;

const DomainPriceTableBody = props => (
  <Translate>
    {({ translate }) => (
      <TableBody>
        {Object.keys(props.tableItems).map((item, i) => {
          if (!props.tableItems.hasOwnProperty(item)) return false;

          const currencyCode = props.customerCurrencyCode.toLowerCase();

          return isMobileOnly ? (
            <TableRow key={i}>
              <TableCell>
                <BlockSpan>
                  {"." + item}
                  {item === "org.mk" && (
                    <CustomTooltip
                      className="float-right"
                      inline={true}
                      content={translate(
                        "shop.domains.domain-prices.the-registrant-must-be-an-organization"
                      )}
                    >
                      <Icon icon="info" />
                    </CustomTooltip>
                  )}
                  {item === "edu.mk" && (
                    <CustomTooltip
                      className="float-right"
                      inline={true}
                      content={translate(
                        "shop.domains.domain-prices.the-registrant-must-be-an-educational-institution"
                      )}
                    >
                      <Icon icon="info" />
                    </CustomTooltip>
                  )}
                </BlockSpan>
                <BlockSpan>
                  {props.duration}{" "}
                  {props.duration === 1
                    ? translate("shop.domains.year")
                    : translate("shop.domains.years")}
                </BlockSpan>
              </TableCell>
              <TableCell>
                <StyledPriceHolder>
                  {props.tableItems[item][currencyCode].register[
                    props.duration.toString()
                  ] &&
                    item !== "gr" &&
                    props.tableItems[item][currencyCode].register[
                      props.duration.toString()
                    ] > 0 &&
                    currencyCode +
                      " " +
                      props.tableItems[item][currencyCode].register[
                        props.duration.toString()
                      ]}

                  {props.tableItems[item][currencyCode].register[
                    props.duration.toString()
                  ] &&
                    item === "gr" &&
                    props.duration % 2 === 0 &&
                    props.tableItems[item][currencyCode].register[
                      props.duration.toString()
                    ] > 0 &&
                    currencyCode +
                      " " +
                      props.tableItems[item][currencyCode].register[
                        props.duration.toString()
                      ]}
                  {item === "mk" && REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE > 0 &&(
                    <BadgeSuccess className="badge-success">
                      -{REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE}%
                    </BadgeSuccess>
                  )} 

                  {item === "gr" && props.duration % 2 !== 0 && (
                    <CustomTooltip
                      className="float-left"
                      inline={true}
                      content={parse(
                        translate(
                          "shop.domains.domain-prices.gr-domains-can-register-2-year"
                        )
                      )}
                    >
                      <Icon icon="info" />
                    </CustomTooltip>
                  )}
                </StyledPriceHolder>
              </TableCell>
              <TableCell>
                <StyledPriceHolder>
                  {props.tableItems[item][currencyCode].renew &&
                    props.tableItems[item][currencyCode].renew[
                      props.duration.toString()
                    ] &&
                    props.tableItems[item][currencyCode].renew[
                      props.duration.toString()
                    ] == "0.00" &&
                    translate("general.free")}

                  {props.tableItems[item][currencyCode].renew &&
                    props.tableItems[item][currencyCode].renew[
                      props.duration.toString()
                    ] &&
                    item !== "gr" &&
                    props.tableItems[item][currencyCode].renew[
                      props.duration.toString()
                    ] > 0 &&
                    currencyCode +
                      " " +
                      props.tableItems[item][currencyCode].renew[
                        props.duration.toString()
                      ]}

                  {props.tableItems[item][currencyCode].renew &&
                    props.tableItems[item][currencyCode].renew[
                      props.duration.toString()
                    ] &&
                    item === "gr" &&
                    props.duration % 2 === 0 &&
                    props.tableItems[item][currencyCode].renew[
                      props.duration.toString()
                    ] > 0 &&
                    currencyCode +
                      " " +
                      props.tableItems[item][currencyCode].renew[
                        props.duration.toString()
                      ]}

                  {item === "gr" && props.duration % 2 !== 0 && (
                    <CustomTooltip
                      className="float-left"
                      inline={true}
                      content={parse(
                        translate(
                          "shop.domains.domain-prices.gr-domains-can-register-2-year"
                        )
                      )}
                    >
                      <Icon icon="info" />
                    </CustomTooltip>
                  )}
                </StyledPriceHolder>
              </TableCell>
              <TableCell>
                <StyledPriceHolder>
                  {props.tableItems[item][currencyCode].transfer &&
                    props.tableItems[item][currencyCode].transfer[
                      props.duration.toString()
                    ] &&
                    props.tableItems[item][currencyCode].transfer[
                      props.duration.toString()
                    ] == "0.00" &&
                    translate("general.free")}

                  {props.tableItems[item][currencyCode].transfer &&
                    props.tableItems[item][currencyCode].transfer[
                      props.duration.toString()
                    ] &&
                    item !== "gr" &&
                    props.tableItems[item][currencyCode].transfer[
                      props.duration.toString()
                    ] > 0 &&
                    currencyCode +
                      " " +
                      props.tableItems[item][currencyCode].transfer[
                        props.duration.toString()
                      ]}

                  {props.tableItems[item][currencyCode].transfer &&
                    props.tableItems[item][currencyCode].transfer[
                      props.duration.toString()
                    ] &&
                    item === "gr" &&
                    props.duration % 2 === 0 &&
                    props.tableItems[item][currencyCode].transfer[
                      props.duration.toString()
                    ] > 0 &&
                    currencyCode +
                      " " +
                      props.tableItems[item][currencyCode].transfer[
                        props.duration.toString()
                      ]}
                  {item === "gr" && props.duration % 2 !== 0 && (
                    <CustomTooltip
                      className="float-left"
                      inline={true}
                      content={parse(
                        translate(
                          "shop.domains.domain-prices.gr-domains-can-register-2-year"
                        )
                      )}
                    >
                      <Icon icon="info" />
                    </CustomTooltip>
                  )}
                </StyledPriceHolder>
              </TableCell>
              <TableCell>
               {item === "mk" && REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE > 0 && (
               <BadgeDanger className="badge float-right">
                    {translate("general.on-sale").toUpperCase()}
                   </BadgeDanger>
               )}
              </TableCell>
            </TableRow>
          ) : (
            <TableRow key={i}>
              <TableCell>
                <BlockSpan isMobile={isMobileOnly}>
                  {"." + item}
                  {item === "org.mk" && (
                    <CustomTooltip
                      className="float-right"
                      inline={true}
                      content={translate(
                        "shop.domains.domain-prices.the-registrant-must-be-an-organization"
                      )}
                    >
                      <Icon icon="info" />
                    </CustomTooltip>
                  )}
                  {item === "edu.mk" && (
                    <CustomTooltip
                      className="float-right"
                      inline={true}
                      content={translate(
                        "shop.domains.domain-prices.the-registrant-must-be-an-educational-institution"
                      )}
                    >
                      <Icon icon="info" />
                    </CustomTooltip>
                  )}
                </BlockSpan>
              </TableCell>
              <TableCell>
                {props.duration}{" "}
                {props.duration === 1
                  ? translate("shop.domains.year")
                  : translate("shop.domains.years")}
              </TableCell>
              <TableCell>
                <StyledPriceHolder style={{ marginRight: "10px" }}>
                  {props.tableItems[item][currencyCode].register[
                    props.duration.toString()
                  ] &&
                    item !== "gr" &&
                    props.tableItems[item][currencyCode].register[
                      props.duration.toString()
                    ] > 0 &&
                    currencyCode +
                      " " +
                      props.tableItems[item][currencyCode].register[
                        props.duration.toString()
                      ]}

                  {props.tableItems[item][currencyCode].register[
                    props.duration.toString()
                  ] &&
                    item === "gr" &&
                    props.duration % 2 === 0 &&
                    props.tableItems[item][currencyCode].register[
                      props.duration.toString()
                    ] > 0 &&
                    currencyCode +
                      " " +
                      props.tableItems[item][currencyCode].register[
                        props.duration.toString()
                      ]}
                  {item === "mk" && REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE > 0 &&(
                    <BadgeSuccess className="badge-success">
                      -{REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE}%
                    </BadgeSuccess>
                  )}

                  {item === "gr" && props.duration % 2 !== 0 && (
                    <CustomTooltip
                      className="float-left"
                      inline={true}
                      content={parse(
                        translate(
                          "shop.domains.domain-prices.gr-domains-can-register-2-year"
                        )
                      )}
                    >
                      <Icon icon="info" />
                    </CustomTooltip>
                  )}
                </StyledPriceHolder>
              </TableCell>
              <TableCell>
                <StyledPriceHolder style={{ marginRight: "10px" }}>
                  {props.tableItems[item][currencyCode].renew &&
                    props.tableItems[item][currencyCode].renew[
                      props.duration.toString()
                    ] &&
                    props.tableItems[item][currencyCode].renew[
                      props.duration.toString()
                    ] == "0.00" &&
                    translate("general.free")}

                  {props.tableItems[item][currencyCode].renew &&
                    props.tableItems[item][currencyCode].renew[
                      props.duration.toString()
                    ] &&
                    item !== "gr" &&
                    props.tableItems[item][currencyCode].renew[
                      props.duration.toString()
                    ] > 0 &&
                    currencyCode +
                      " " +
                      props.tableItems[item][currencyCode].renew[
                        props.duration.toString()
                      ]}

                  {props.tableItems[item][currencyCode].renew &&
                    props.tableItems[item][currencyCode].renew[
                      props.duration.toString()
                    ] &&
                    item === "gr" &&
                    props.duration % 2 === 0 &&
                    props.tableItems[item][currencyCode].renew[
                      props.duration.toString()
                    ] > 0 &&
                    currencyCode +
                      " " +
                      props.tableItems[item][currencyCode].renew[
                        props.duration.toString()
                      ]}

                  {item === "gr" && props.duration % 2 !== 0 && (
                    <CustomTooltip
                      className="float-left"
                      inline={true}
                      content={parse(
                        translate(
                          "shop.domains.domain-prices.gr-domains-can-register-2-year"
                        )
                      )}
                    >
                      <Icon icon="info" />
                    </CustomTooltip>
                  )}
                </StyledPriceHolder>
              </TableCell>
              <TableCell>
                <StyledPriceHolder style={{ marginRight: "10px" }}>
                  {props.tableItems[item][currencyCode].transfer &&
                    props.tableItems[item][currencyCode].transfer[
                      props.duration.toString()
                    ] &&
                    props.tableItems[item][currencyCode].transfer[
                      props.duration.toString()
                    ] == "0.00" &&
                    translate("general.free")}

                  {props.tableItems[item][currencyCode].transfer &&
                    props.tableItems[item][currencyCode].transfer[
                      props.duration.toString()
                    ] &&
                    item !== "gr" &&
                    props.tableItems[item][currencyCode].transfer[
                      props.duration.toString()
                    ] > 0 &&
                    currencyCode +
                      " " +
                      props.tableItems[item][currencyCode].transfer[
                        props.duration.toString()
                      ]}

                  {props.tableItems[item][currencyCode].transfer &&
                    props.tableItems[item][currencyCode].transfer[
                      props.duration.toString()
                    ] &&
                    item === "gr" &&
                    props.duration % 2 === 0 &&
                    props.tableItems[item][currencyCode].transfer[
                      props.duration.toString()
                    ] > 0 &&
                    currencyCode +
                      " " +
                      props.tableItems[item][currencyCode].transfer[
                        props.duration.toString()
                      ]}
                  {item === "gr" && props.duration % 2 !== 0 && (
                    <CustomTooltip
                      className="float-left"
                      inline={true}
                      content={parse(
                        translate(
                          "shop.domains.domain-prices.gr-domains-can-register-2-year"
                        )
                      )}
                    >
                      <Icon icon="info" />
                    </CustomTooltip>
                  )}
                </StyledPriceHolder>
              </TableCell>
              <TableCell>
                {item === "mk" && REACT_APP_MKHOST_MK_DOMAINS_PROMOTION_DISCOUNT_VALUE > 0 && (
                  <BadgeDanger className="badge float-right">
                    {translate("general.on-sale").toUpperCase()}
                  </BadgeDanger>
                )} 
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    )}
  </Translate>
);

export default DomainPriceTableBody;
