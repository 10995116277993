import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { debounce } from "throttle-debounce";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";

import withLoading from "../../../../components/Loaders/WithLoading";
import TableContentLoader from "../../../../components/Loaders/TableContentLoader";
import Container from "../../../../components/Containers/Container";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import Form from "../../../../components/Forms/Form";
import SearchField from "../../../../components/SearchField";
import Checkbox from "../../../../components/Checkbox";
import AmountBadge from "../../../../components/AmountBadge";
import ButtonGroupContentDropdown from "../../../../components/ButtonGroupContentDropdown";

import Table from "../../../../components/Table";
import TableHeader from "../../../../components/Table/TableHeader";
import TableHeading from "../../../../components/Table/TableHeading";
import TableBody from "../../../../components/Table/TableBody";
import TableRow from "../../../../components/Table/TableRow";
import TableCell from "../../../../components/Table/TableCell";

import InvoicesFilter from "../InvoicesFilter";
import { fetchInvoices } from "../../Invoices/actions/invoicesActions";

import {
  compileRoute,
  ROUTE_BILLING_VIEW_INVOICE,
  ROUTE_BILLING_VIEW_PROINVOICE
} from "../../../../routes/routes";

import Pagination from "../../../../components/Pagination";
import {
  SORT_ORDER_DESCENDING,
  SORT_ORDER_ASCENDING
} from "../../../../types/SortTypes";

import CustomTooltip from "../../../../components/CustomTooltip";
import Icon from "../../../../components/Icon";

const StyledInvoicesListWrapper = styled.div`
  margin-bottom: ${props => (props.isMobile ? "50px" : "30px")};
`;

const StyledInvoicesList = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;

  & .hosting-list-header {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
  }

  //& .hosting-list-header button.purchase-hosting {
  //  margin-right: 0;
  //}
`;

const StyledInvoicesListContainer = styled.div`
  margin-bottom: 50px;
  & a.invoice-link {
    font-size: 16px;
    display: inline-block;
    width: 100%;
  }

  & a.invoice-link-block {
    display: block;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }
`;

const StyledTableTitle = styled.h4`
  padding-left: 25px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
`;

const StyledDigitalInvoiceIcon = styled(Icon)`
  color: ${theme.greenBase};
  font-size: 14px;
`;

class InvoicesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { searchQuery: props.filters.keyword || "" };

    this.viewProInvoicePath = compileRoute(ROUTE_BILLING_VIEW_PROINVOICE);
    this.viewInvoicePath = compileRoute(ROUTE_BILLING_VIEW_INVOICE);
    this.searchInvoicesByKeywordThrottled = debounce(
      1000,
      this.searchInvoicesByKeyword
    );
  }

  handlePaginationClick = (event, page) => {
    event.preventDefault();

    if (page <= 0 || page > this.props.paging.total_pages) {
      return;
    }

    this.props.fetchInvoices(
      this.props.paging.limit,
      page,
      this.props.sort,
      this.props.filters
    );
  };

  onPerPageOptionChanged = value => {
    this.props.fetchInvoices(
      value.value,
      1,
      this.props.sort,
      this.props.filters
    );
  };

  searchInvoicesByKeyword = value => {
    this.props.fetchInvoices(this.props.paging.limit, 1, this.props.sort, {
      ...this.props.filters,
      keyword: value
    });
  };

  onChangeKeywordSearch = event => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.searchInvoicesByKeywordThrottled(this.state.searchQuery);
    });
  };

  changeListOrderTrigger = (sortBy, orderBy) => {
    this.props.fetchInvoices(
      this.props.paging.limit,
      this.props.paging.current_page,
      {
        sort_by: sortBy,
        order_by:
          orderBy === SORT_ORDER_DESCENDING
            ? SORT_ORDER_ASCENDING
            : SORT_ORDER_DESCENDING
      },
      this.props.filters
    );
  };

  render() {
    const StyledInvoicesTableWithLoading = withLoading(
      StyledInvoicesListContainer,
      TableContentLoader
    );

    const searchLayout = isMobileOnly
      ? "col-12 col-sm-12 col-md-12"
      : "col-6 col-sm-6 col-md-6";

    const filtersApplied = this.props.countFiltersApplied();

    return (
      <Translate>
        {({ translate }) => (
          <StyledInvoicesListWrapper>
            <Container>
              <StyledInvoicesList>
                <div className="hosting-list-header">
                  <div className="row">
                    <div className={searchLayout}>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                        }}
                      >
                        <SearchField
                          onChange={this.onChangeKeywordSearch}
                          name="search"
                          value={this.state.searchQuery}
                          placeholder={translate(
                            "invoices.table.search-placeholder"
                          )}
                        />
                      </Form>
                    </div>
                    <div className={`${searchLayout} text-right`}>
                      {this.props.isLaptop && (
                        <ButtonGroupContentDropdown
                          text={`${translate("invoices.filter.title")}  ${
                            filtersApplied > 0 ? " - " + filtersApplied : ""
                          }`}
                          size="m"
                          alignMenu="right"
                        >
                          <InvoicesFilter
                            paging={this.props.paging}
                            statusCollection={this.props.statusCollection}
                            sort={this.props.sort}
                            filters={this.props.filters}
                            payersForFilter={this.props.payersForFilter}
                            filterPayerOptions={this.props.filterPayerOptions}
                            onPayerFilterOptionChange={
                              this.props.onPayerFilterOptionChange
                            }
                            countFiltersApplied={this.props.countFiltersApplied}
                          />
                        </ButtonGroupContentDropdown>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <StyledTableTitle>
                      {translate("invoices.table.invoices")}
                    </StyledTableTitle>
                    <StyledInvoicesTableWithLoading
                      isLoading={this.props.isLoading}
                    >
                      <Table>
                        {isBrowser && (
                          <TableHeader>
                            {this.props.selectedRowsCount === 0 ? (
                              <TableRow>
                                <TableHeading
                                  width="25%"
                                  isSortable={true}
                                  sortBy="invoice"
                                  isDefaultSort={
                                    this.props.sort.sort_by === "invoice"
                                  }
                                  sortDirection={this.props.sort.order_by}
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("invoices.table.invoice")}
                                </TableHeading>
                                <TableHeading
                                  width="25%"
                                  isSortable={true}
                                  sortBy="createdAt"
                                  isDefaultSort={
                                    this.props.sort.sort_by === "createdAt"
                                  }
                                  sortDirection={this.props.sort.order_by}
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("invoices.table.created")}
                                </TableHeading>

                                <TableHeading
                                  width="25%"
                                  isSortable={true}
                                  sortBy="paid_on"
                                  isDefaultSort={
                                    this.props.sort.sort_by === "paid_on"
                                  }
                                  sortDirection={this.props.sort.order_by}
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("invoices.table.paid-on")}
                                </TableHeading>

                                <TableHeading
                                  width="25%"
                                  isSortable={true}
                                  sortBy="pro_invoice"
                                  isDefaultSort={
                                    this.props.sort.sort_by === "pro_invoice"
                                  }
                                  sortDirection={this.props.sort.order_by}
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("pro-invoices.table.pro-invoices")}
                                </TableHeading>

                                <TableHeading
                                  className="text-right"
                                  width="25%"
                                  isSortable={true}
                                  sortBy="amount"
                                  isDefaultSort={
                                    this.props.sort.sort_by === "amount"
                                  }
                                  sortDirection={this.props.sort.order_by}
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("invoices.table.amount")}
                                </TableHeading>
                              </TableRow>
                            ) : (
                              <TableRow>
                                <TableHeading colSpan={5}>
                                  <span>
                                    {this.props.selectedRowsCount}{" "}
                                    {translate("invoices.table.selected")}
                                  </span>
                                  <SecondaryButton
                                    style={{ marginLeft: "10px" }}
                                    size="s"
                                    disabled={this.props.isPerformingMasspay}
                                    submitting={this.props.isPerformingMasspay}
                                    onClick={e => this.props.massPay()}
                                  >
                                    {translate("invoices.table.pay-now")}
                                  </SecondaryButton>
                                </TableHeading>
                              </TableRow>
                            )}
                          </TableHeader>
                        )}
                        <TableBody>
                          {this.props.all.map(invoice => {
                            return (
                              <TableRow key={`invoice-${invoice.invoice}`}>
                                <TableCell width="21%">
                                  <Link
                                    className={
                                      isTablet || isMobileOnly
                                        ? "invoice-link invoice-link-block"
                                        : "invoice-link"
                                    }
                                    to={this.viewInvoicePath({
                                      id: invoice.invoice
                                    })}
                                  >
                                    #{invoice.invoice}
                                    {invoice.digital && (
                                      <CustomTooltip
                                        placement="bottom"
                                        customClassName={
                                          !isMobileOnly
                                            ? "float-right"
                                            : "position-absolute"
                                        }
                                        inline={true}
                                        content={translate(
                                          "invoices.view.digital-invoice"
                                        )}
                                      >
                                        <StyledDigitalInvoiceIcon icon="profile" />
                                      </CustomTooltip>
                                    )}
                                  </Link>
                                </TableCell>
                                {isBrowser && (
                                  <TableCell width="25%">
                                    {invoice.createdAt}
                                  </TableCell>
                                )}

                                {isBrowser && (
                                  <TableCell width="25%">
                                    {invoice.datePaid}
                                  </TableCell>
                                )}
                                <TableCell width="10%">
                                  {invoice.proinvoice &&
                                    invoice.proinvoice !== "" && (
                                      <Link
                                        to={this.viewProInvoicePath({
                                          id: invoice.proinvoice
                                        })}
                                      >
                                        #{invoice.proinvoice}
                                      </Link>
                                    )}
                                </TableCell>

                                <TableCell width="15%">
                                  <AmountBadge
                                    currency={invoice.currency}
                                    amount={invoice.total}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </StyledInvoicesTableWithLoading>
                  </div>
                </div>
                <Pagination
                  itemsName={translate("invoices.table.invoices")}
                  paging={this.props.paging}
                  handlePaginationClick={this.handlePaginationClick}
                  onPerPageOptionChanged={this.onPerPageOptionChanged}
                />
              </StyledInvoicesList>
            </Container>
          </StyledInvoicesListWrapper>
        )}
      </Translate>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInvoices
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(InvoicesList);
