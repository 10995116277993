import React from "react";
import styled from "styled-components";

const StyledBreadcrumbWrapper = styled.div`
  border-bottom: ${props =>
    props.separator && `1px solid ${props.theme.neutral3}`};
  margin-bottom: ${props => props.separator && "30px"};
  margin-left: -30px;
  margin-right: -30px;

  & > div {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const StyledBreadcrumb = styled.nav`
  & ol {
    background-color: transparent;
    padding: 20px;
    padding-left: 0;
    margin-bottom: 0;
  }
`;

const Breadcrumb = ({ children, separator, ...rest }) => (
  <StyledBreadcrumbWrapper separator={separator} className="row">
    <div className="col-12 col-sm-12 col-md-12">
      <StyledBreadcrumb {...rest}>
        <ol className="breadcrumb">{children}</ol>
      </StyledBreadcrumb>
    </div>
  </StyledBreadcrumbWrapper>
);

export default Breadcrumb;
