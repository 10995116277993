import * as PMA from "../actions/paymentMethodsActions";

import handleReducerActions from "../../../core/handleReducerActions";

import { APP_STATE_COMPLETE_RESET } from "../../AppState/actions/appStateActions";

const initialState = [];

const paymentMethodsStateHandlers = {
  [PMA.PAYMENT_METHODS_REQUEST]: paymentMethodsRequest,
  [PMA.PAYMENT_METHODS_RECEIVE]: paymentMethodsReceive,
  [APP_STATE_COMPLETE_RESET]: resetState
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(paymentMethodsStateHandlers, state, action);
}

function paymentMethodsRequest(state, action) {
  return state;
}

function paymentMethodsReceive(state, action) {
  return [...action.payload];
}

function resetState(state) {
  return { ...initialState };
}

// this is a selector
export function getPaymentMethods(state) {
  return state.paymentMethods;
}
