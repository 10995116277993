import React from "react";

import Loader from "../Loader";

const TableLoader = () => (
  <Loader width="900" height="300">
    <rect x="0" y="0" width="150" height="30" rx="3" />
    <rect x="750" y="0" width="150" height="30" rx="3" />

    <rect x="0" y="60" width="20" height="20" rx="3" />
    <rect x="30" y="60" width="900" height="20" rx="3" />

    <rect x="0" y="105" width="20" height="20" rx="3" />
    <rect x="30" y="105" width="900" height="20" rx="3" />

    <rect x="0" y="145" width="20" height="20" rx="3" />
    <rect x="30" y="145" width="900" height="20" rx="3" />

    <rect x="0" y="185" width="20" height="20" rx="3" />
    <rect x="30" y="185" width="900" height="20" rx="3" />

    <rect x="0" y="225" width="20" height="20" rx="3" />
    <rect x="30" y="225" width="900" height="20" rx="3" />
  </Loader>
);

export default TableLoader;
