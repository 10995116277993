import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Flag from "../Flag";

const StyledFlagDanger = styled.div`
  & .flag-container {
    background-color: ${theme.redBase};
  }

  & span.flag-title {
    color: ${theme.white};
  }

  & div.flag-content {
    color: ${theme.white};
  }

  & .icon {
    color: ${theme.white};
  }
  & .icon:not(.icon-close) {
    color: ${theme.white};
  }
`;

const FlagDanger = ({ children, ...rest }) => (
  <StyledFlagDanger>
    <Flag icon={rest.icon || "warning"} {...rest}>
      {children}
    </Flag>
  </StyledFlagDanger>
);

FlagDanger.defaultProps = {
  onDismiss: () => {}
};

export default FlagDanger;
