import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Form, Field } from "react-final-form";

import { Translate } from "react-localize-redux";

import Panel from "../../../../../components/Panel";
import InputField from "../../../../../components/InputField";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import SecondaryWarningButton from "../../../../../components/Buttons/SecondaryWarningButton";
import NoticeInfo from "../../../../../components/Notices/NoticeInfo";

import {
  ROUTE_CREATE_TICKET,
  ROUTE_VIEW_DOMAIN_ZONE_FILE,
  compileRoute
} from "../../../../../routes/routes";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";

const StyledSecondaryWarningButton = styled(SecondaryWarningButton)`
  margin-right: 0;
`;

const removeExtraHttpsFromDomainName = value => {
  if (!value || value.length === 0) {
    return value;
  }

  let domain = value;
  let protocol = "";
  if (value.startsWith("http://")) {
    domain = value.split("http://")[1];
    protocol = "http://";
  }

  if (value.startsWith("https://")) {
    domain = value.split("https://")[1];
    protocol = "https://";
  }

  domain = domain.replace(/http:\/\//gi, "");
  domain = domain.replace(/https:\/\//gi, "");

  return protocol + domain;
};

const DomainRedirect = ({
  domain,
  isDomainRedirectionAvailable,
  domainRedirectionReason,
  domainRedirection,
  validate,
  onUpdateDomainRedirect,
  onRemoveDomainRedirect,
  isUpdatingDomainRedirection
}) => (
  <Translate>
    {({ translate }) => (
      <Panel
        title={translate("domain.settings.domain-redirect")}
        description={translate("domain.settings.domain-redirect-description")}
      >
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            {!isDomainRedirectionAvailable && (
              <NoticeInfo>
                <span className="d-block w-100 mb-3">
                  {domainRedirectionReason}
                </span>
                <span className="d-block w-100 mb-3">
                  <Link
                    to={compileRoute(ROUTE_VIEW_DOMAIN_ZONE_FILE)({
                      name: domain.domain_name,
                      id: domain.id
                    })}
                  >
                    {translate("domain.settings.configure-dns-zone")}
                  </Link>
                </span>
              </NoticeInfo>
            )}
            {isDomainRedirectionAvailable && (
              <Form
                onSubmit={onUpdateDomainRedirect}
                initialValues={{
                  redirect_to:
                    domainRedirection && domainRedirection !== ""
                      ? domainRedirection
                      : ""
                }}
                validate={validate}
                render={({ handleSubmit, submitting, invalid }) => (
                  <form onSubmit={handleSubmit}>
                    <Field
                      component={InputField}
                      label={translate("domain.settings.redirection-address")}
                      name="redirect_to"
                      placeholder="https://"
                      normalize
                      parse={value =>
                        value && removeExtraHttpsFromDomainName(value)
                      }
                    />
                    <SecondaryButton
                      type="submit"
                      disabled={
                        invalid ||
                        submitting ||
                        !isDomainRedirectionAvailable ||
                        isUpdatingDomainRedirection
                      }
                      submitting={submitting}
                    >
                      {translate("domain.settings.redirect-domain")}
                    </SecondaryButton>

                    <StyledSecondaryWarningButton
                      type="button"
                      onClick={() => onRemoveDomainRedirect()}
                      disabled={
                        submitting ||
                        invalid ||
                        !isDomainRedirectionAvailable ||
                        isUpdatingDomainRedirection ||
                        domainRedirection == null ||
                        domainRedirection === ""
                      }
                      submitting={isUpdatingDomainRedirection}
                      className="float-xs-let float-sm-left float-md-right"
                    >
                      {translate("domain.settings.remove-redirection")}
                    </StyledSecondaryWarningButton>
                  </form>
                )}
              />
            )}
          </div>
        </div>
      </Panel>
    )}
  </Translate>
);

export default DomainRedirect;
