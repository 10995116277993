import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import withLoading from "../../../components/Loaders/WithLoading";
import KnowledgeBaseSearchByKeywordResultsLoader from "../../../components/Loaders/KnowledgeBaseSearchByKeywordResultsLoader";
import KnowledgeBaseArticle from "../../../components/KnowledgeBase/KnowledgeBaseArticle";

const StyledKnowledgeBaseSearchByKeywordArticlesContainer = styled.div`
  padding: 25px 25px 25px 25px;
  border-bottom: 1px solid ${theme.neutral3};

  & .search-title-container {
    margin-bottom: 10px;
  }

  & .search-title {
    font-weight: 600;
    color: ${theme.neutral6};
    font-size: 14px;
    padding: 5px 0px;
    text-align: left;
  }

  & .number-of-results-found-container {
    text-align: right;
  }

  & .number-of-results-found {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: ${theme.neutral1};
    display: inline-block;
    margin-left: 10px;
    text-align: center;
  }
`;

const StyledKnowledgeBaseSearchByKeywordArticles = styled.div`
  margin-top: 10px;
`;

const StyledKnowledgeBaseSearchByKeywordArticlesWithLoading = withLoading(
  StyledKnowledgeBaseSearchByKeywordArticles,
  KnowledgeBaseSearchByKeywordResultsLoader
);

const KnowledgeBaseArticlesSearchByKeywordResultsContainer = ({ ...props }) => (
  <div>
    {props.searchQuery !== "" && (
      <div>
        <StyledKnowledgeBaseSearchByKeywordArticlesContainer>
          <div className="row">
            <div className="col-8 col-sm-8 col-md-8 search-title-container">
              <div className="search-title">Search by: {props.searchQuery}</div>
            </div>
            <div className="col-4 col-sm-4 col-md-4 number-of-results-found-container">
              <div className="number-of-results-found">
                Found: {props.searchedArticles.length}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <StyledKnowledgeBaseSearchByKeywordArticlesWithLoading
                isLoading={props.isFetchingKnowledgeBaseArticleByKeyword}
              >
                {props.searchedArticles.map(article => {
                  return (
                    <KnowledgeBaseArticle
                      key={`${`helpdesk-knowledge-base-article-${article.id}`}`}
                      title={article.title}
                      votes={article.votes}
                      categoryName={article.category_name}
                      showMeta={true}
                      articleLink={props.knowledgeArticlePath({
                        id: article.id
                      })}
                    />
                  );
                })}
              </StyledKnowledgeBaseSearchByKeywordArticlesWithLoading>
            </div>
          </div>
        </StyledKnowledgeBaseSearchByKeywordArticlesContainer>
      </div>
    )}
  </div>
);

export default KnowledgeBaseArticlesSearchByKeywordResultsContainer;
