import React, { Component } from 'react';
import axios from 'axios';
import { withLocalize, Translate } from 'react-localize-redux';
import styled from 'styled-components';
import { toastSuccess, toastWarning } from '../../ToastMessage';
import { EMAIL_SEND_VALIDATION_LINK } from "../../../services/endpoints";



const AnchorLink = styled.span`
  font-weight: 600;
  color: #007bff;
  line-height: 1.43;
  letter-spacing: 0.2px;
  cursor: pointer;
  text-decoration: underline;
`;

class EmailVerificationLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSent: false,
    };
  }

  handleButtonClick = async () => {
    try {
      const response = await axios.post(EMAIL_SEND_VALIDATION_LINK);

      if (!response.error) {
        this.setState({ emailSent: true });
        toastSuccess(this.props.translate("navigation.profile.email-sent"))
      }
    } catch (error) {
      toastWarning(
        this.props.translate("navigation.profile.link-not-sent")
      );
    }
  };


  render() {
    const { emailSent } = this.state;
    const { text } = this.props;

    return (
      <Translate>
        {({ translate }) => (
          <AnchorLink onClick={this.handleButtonClick} disabled={emailSent}>
            {text || translate('my-account.general-info.details.click-here-to-verify')}
          </AnchorLink>
        )}
      </Translate>
    );
  }
}

export default withLocalize(EmailVerificationLink);
