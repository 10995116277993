import React from "react";
import {Translate, withLocalize} from "react-localize-redux";
import Breadcrumb from "../../components/Breadcrumb";
import BreadcrumbItem from "../../components/Breadcrumb/BreadcrumbItem";
import Tabs from "../../components/Tabs/Tabs";
import Tab from "../../components/Tabs/Tab";
import {
    ROUTE_REFERRAL_PROGRAM_APPLY,
    ROUTE_REFERRAL_PROGRAM_CODE,
    ROUTE_REFERRAL_PROGRAM_INVOICE
} from "../../routes/routes";
import {Route, Switch} from "react-router-dom";
import TabContent from "../../components/Tabs/TabContent";
import MyReferralCode from "./MyReferralCode";
import MyReferralInvoices from "./MyReferralInvoices";
import ReferralApplyCode from "../RefferalApplyCode";



class Referral extends React.Component {




    render() {
        return (
            <Translate>
                {({translate}) => (
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                            <Breadcrumb>
                                <BreadcrumbItem to="/" title={translate("breadcrumbs.home")}/>
                                <BreadcrumbItem title={translate("breadcrumbs.referral")}/>
                            </Breadcrumb>
                            <Tabs
                                defaultTab="code"
                                renderTabs={(active, onSelect) => [
                                    <Tab
                                        key="tab-code"
                                        label="code"
                                        active={active}
                                        onSelect={onSelect}
                                        linkTo={ROUTE_REFERRAL_PROGRAM_CODE}
                                    >
                                        {translate("referral.title")}
                                    </Tab>,
                                    <Tab
                                        key="tab-invoices"
                                        label="invoices"
                                        active={active}
                                        onSelect={onSelect}
                                        linkTo={ROUTE_REFERRAL_PROGRAM_INVOICE}
                                    >

                                        {translate("referral.invoices")}
                                    </Tab>
                                ]}
                                renderTabContent={active => (
                                    <Switch>
                                        <Route
                                            path={ROUTE_REFERRAL_PROGRAM_CODE}
                                            exact
                                            render={() => (
                                                <TabContent
                                                    key="tab-content-code"
                                                    label="code"
                                                    active={true}
                                                >
                                                    <MyReferralCode/>
                                                </TabContent>
                                            )}
                                        />

                                        <Route
                                            path={ROUTE_REFERRAL_PROGRAM_INVOICE}
                                            exact
                                            render={() => (
                                                <TabContent
                                                    key="tab-content-invoices"
                                                    label="invoices"
                                                    active={true}
                                                >
                                                    <MyReferralInvoices/>
                                                </TabContent>
                                            )}
                                        />
                                        <Route
                                            path={ROUTE_REFERRAL_PROGRAM_APPLY}
                                            exact
                                            render={() => (
                                                <TabContent
                                                    key="tab-content-apply"
                                                    label="apply"
                                                    active={true}
                                                >
                                                  <ReferralApplyCode/>
                                                </TabContent>
                                            )}
                                        />
                                    </Switch>
                                )}
                            />
                        </div>
                    </div>
                )}
            </Translate>
        );
    }
}
export default Referral;