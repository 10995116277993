import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Link } from "react-router-dom";

const StyledWidgetTitle = styled.div`
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 25px;
  & h4 {
    color: ${theme.neutral5};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    text-transform: uppercase;
  }

  & a {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blueBase};
    line-height: 2.29px;
    letter-spacing: 0.2px;
  }
`;

const WidgetTitle = props => (
  <StyledWidgetTitle className="widget-title">
    <div className="row">
      <div className="col-6 col-sm-6 col-md-6">
        <h4>{props.title}</h4>
      </div>
      <div className="col-6 col-sm-6 col-md-6 text-right">
        {props.actionTo && <Link to={props.actionTo}>{props.actionTitle}</Link>}
      </div>
    </div>
  </StyledWidgetTitle>
);

WidgetTitle.default = {
  title: "untitled",
  actionTo: "/",
  actionTitle: "Action"
};

export default WidgetTitle;
