import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

const StyledDropdownMenu = styled.div`
  border: 1px solid ${theme.neutral3};
  box-shadow: 0 3px 5px 0 ${theme.neutral3};
`;

const DropdownMenu = props => (
  <StyledDropdownMenu
    className={`dropdown-menu ${props.className} dropdown-menu-${props.alignMenu}`}
  >
    {props.children}
  </StyledDropdownMenu>
);

DropdownMenu.defaultProps = {
  alignMenu: "left"
};

export default DropdownMenu;
