import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Translate } from "react-localize-redux";

import { MobileOnlyView, TabletView, BrowserView } from "react-device-detect";
import isLaptop from "../../../../utils/isLaptopScreen";
import withSizes from "react-sizes";

import Container from "../../../../components/Containers/Container";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Label from "../../../../components/Label";
//import Togglebox from "../../../../components/Togglebox";
import AmountBadge from "../../../../components/AmountBadge";

import Usage from "./Usage";

import getBadgeForStatus from "../../../../utils/getBadgeForStatus";

import {
  SERVER_STATUS_ACTIVE,
  SERVER_STATUS_SUSPENDED,
  SERVER_STATUS_TERMINATED
} from "../../ServersStatuses/ServersStatuses";

import {
  SERVER_PANEL_TYPE_CPANEL,
  SERVER_PANEL_TYPE_PLESK
} from "../ServerPanelTypes";

const StyledHeader = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${theme.neutral3};
`;

const StyledContent = styled.div`
  padding: 30px;
`;

const StyledHostingTitle = styled.h3`
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
`;

const StyledDomain = styled.span`
  font-size: 16px;
  color: ${theme.blueBase};
  font-weight: 400;
  margin-left: 10px;
`;

const StyledDetailsWrapper = styled.div`
  margin-bottom: 30px;
`;

const StyledDomainDetail = styled.div`
  vertical-align: top;
  position: relative;
  display: inline-block;
  margin-right: 50px;
  width: 140px;
  margin-bottom: 30px;
  &:last-child {
    margin-right: 0;
  }
`;
const StyledValue = styled.div`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.neutralBase};
  margin-top: 8px;
`;

const StyledGeneralInfoWrapper = styled.div`
  margin-bottom: 30px;
`;

const StyledActionsWrapper = styled.div`
  margin-bottom: 30px;
  & .renew-button {
    width: 78%;
  }
`;

class GeneralInfo extends React.Component {
  render() {
    const {
      server,
      quickLinks,
      isGettingHostingCPanelDirectLoginLink,
      onShowCPanelChangePasswordModal,
      onRenewNow,
      renewNowSubmitting
    } = this.props;

    const StatusBadgeComponent = getBadgeForStatus(server.status.state);

    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <MobileOnlyView viewClassName="w-100">
              <StyledGeneralInfoWrapper className="col-12 col-sm-12 col-md-12">
                <StyledGeneralInfoWrapper>
                  <Container>
                    <StyledHeader>
                      <StyledActionsWrapper className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          {(server.status.code === SERVER_STATUS_ACTIVE ||
                            server.status.code === SERVER_STATUS_SUSPENDED) && (
                            <PrimaryButton
                              onClick={() => onRenewNow(server)}
                              disabled={renewNowSubmitting}
                              submitting={renewNowSubmitting}
                              className="renew-button"
                            >
                              {translate("servers.general-info.renew-now")}
                            </PrimaryButton>
                          )}
                        </div>
                      </StyledActionsWrapper>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <StatusBadgeComponent>
                            {translate(
                              "servers.statuses." + server.status.name
                            )}
                          </StatusBadgeComponent>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12">
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StyledHostingTitle>
                                {server.name}{" "}
                                <StyledDomain>{server.domain}</StyledDomain>
                              </StyledHostingTitle>
                            </div>
                          </div>
                        </div>
                      </div>
                    </StyledHeader>
                    <StyledContent>
                      <StyledDetailsWrapper className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <StyledDomainDetail>
                            <Label>
                              {translate("servers.general-info.purchase-date")}
                            </Label>
                            <StyledValue>{server.purchase_date}</StyledValue>
                          </StyledDomainDetail>
                          <StyledDomainDetail>
                            <Label>
                              {translate("servers.general-info.renewal-date")}
                            </Label>
                            <StyledValue>{server.renewal_date}</StyledValue>
                          </StyledDomainDetail>
                          <StyledDomainDetail>
                            <Label>
                              {translate("servers.general-info.renewal-period")}
                            </Label>
                            <StyledValue>
                              {translate(
                                `servers.renewal-period.${server.renewal_period.toLowerCase()}`
                              )}
                            </StyledValue>
                          </StyledDomainDetail>
                          <StyledDomainDetail>
                            <Label>
                              {translate("servers.general-info.renewal-price")}
                            </Label>
                            <StyledValue>
                              <AmountBadge
                                amount={server.renewal_price.value}
                                currency={server.renewal_price.currency.code}
                                textAlign="left"
                              />
                            </StyledValue>
                          </StyledDomainDetail>
                        </div>
                      </StyledDetailsWrapper>
                      {(server.status.code === SERVER_STATUS_ACTIVE ||
                        server.status.code === SERVER_STATUS_SUSPENDED) && (
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <StyledDomainDetail>
                              <Label>
                                {translate("servers.general-info.hostname")}
                              </Label>
                              <StyledValue>{server.hostname}</StyledValue>
                            </StyledDomainDetail>
                            <StyledDomainDetail>
                              <Label>
                                {translate("servers.general-info.server-ip")}
                              </Label>
                              <StyledValue>{server.dedicated_ip}</StyledValue>
                            </StyledDomainDetail>
                          </div>
                        </div>
                      )}
                    </StyledContent>
                  </Container>
                </StyledGeneralInfoWrapper>
              </StyledGeneralInfoWrapper>
            </MobileOnlyView>
            <TabletView viewClassName="w-100">
              <StyledGeneralInfoWrapper className="col-12 col-sm-12 col-md-12">
                <StyledGeneralInfoWrapper>
                  <Container>
                    <StyledHeader>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <StatusBadgeComponent>
                            {translate(
                              "servers.statuses." + server.status.name
                            )}
                          </StatusBadgeComponent>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8 col-sm-8 col-md-8">
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StyledHostingTitle>
                                {server.name}{" "}
                                <StyledDomain>{server.domain}</StyledDomain>
                              </StyledHostingTitle>
                            </div>
                          </div>
                        </div>
                        <div className="col-4 col-sm-4 col-md-4 text-right">
                          {(server.status.code === SERVER_STATUS_ACTIVE ||
                            server.status.code === SERVER_STATUS_SUSPENDED) && (
                            <PrimaryButton
                              onClick={() => onRenewNow(server)}
                              disabled={renewNowSubmitting}
                              submitting={renewNowSubmitting}
                              className="renew-button"
                            >
                              {translate("servers.general-info.renew-now")}
                            </PrimaryButton>
                          )}
                        </div>
                      </div>
                    </StyledHeader>
                    <StyledContent>
                      <StyledDetailsWrapper className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <StyledDomainDetail>
                            <Label>
                              {translate("servers.general-info.purchase-date")}
                            </Label>
                            <StyledValue>{server.purchase_date}</StyledValue>
                          </StyledDomainDetail>
                          <StyledDomainDetail>
                            <Label>
                              {translate("servers.general-info.renewal-date")}
                            </Label>
                            <StyledValue>{server.renewal_date}</StyledValue>
                          </StyledDomainDetail>
                          <StyledDomainDetail>
                            <Label>
                              {translate("servers.general-info.renewal-period")}
                            </Label>
                            <StyledValue>
                              {translate(
                                `servers.renewal-period.${server.renewal_period.toLowerCase()}`
                              )}
                            </StyledValue>
                          </StyledDomainDetail>
                          <StyledDomainDetail>
                            <Label>
                              {translate("servers.general-info.renewal-price")}
                            </Label>
                            <StyledValue>
                              <AmountBadge
                                amount={server.renewal_price.value}
                                currency={server.renewal_price.currency.code}
                                textAlign="left"
                              />
                            </StyledValue>
                          </StyledDomainDetail>
                        </div>
                      </StyledDetailsWrapper>
                      {(server.status.code === SERVER_STATUS_ACTIVE ||
                        server.status.code === SERVER_STATUS_SUSPENDED) && (
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <StyledDomainDetail>
                              <Label>
                                {translate("servers.general-info.hostname")}
                              </Label>
                              <StyledValue>{server.hostname}</StyledValue>
                            </StyledDomainDetail>
                            <StyledDomainDetail>
                              <Label>
                                {translate("servers.general-info.server-ip")}
                              </Label>
                              <StyledValue>{server.dedicated_ip}</StyledValue>
                            </StyledDomainDetail>
                          </div>
                        </div>
                      )}
                    </StyledContent>
                  </Container>
                </StyledGeneralInfoWrapper>
                <StyledGeneralInfoWrapper>
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-6"></div>
                  </div>
                </StyledGeneralInfoWrapper>
              </StyledGeneralInfoWrapper>
            </TabletView>
            <BrowserView renderWithFragment>
              {this.props.isLaptop && (
                <React.Fragment>
                  <StyledGeneralInfoWrapper className="col-12 col-sm-12 col-md-12">
                    <StyledGeneralInfoWrapper>
                      <Container>
                        <StyledHeader>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StatusBadgeComponent>
                                {translate(
                                  "servers.statuses." + server.status.name
                                )}
                              </StatusBadgeComponent>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8 col-sm-8 col-md-8">
                              <div className="row">
                                <div className="col-12 col-sm-12 col-md-12">
                                  <StyledHostingTitle>
                                    {server.name}{" "}
                                    <StyledDomain>{server.domain}</StyledDomain>
                                  </StyledHostingTitle>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 col-sm-4 col-md-4 text-right">
                              {(server.status.code === SERVER_STATUS_ACTIVE ||
                                server.status.code ===
                                  SERVER_STATUS_SUSPENDED) && (
                                <PrimaryButton
                                  onClick={() => onRenewNow(server)}
                                  disabled={renewNowSubmitting}
                                  submitting={renewNowSubmitting}
                                  className="renew-button"
                                >
                                  {translate("servers.general-info.renew-now")}
                                </PrimaryButton>
                              )}
                            </div>
                          </div>
                        </StyledHeader>
                        <StyledContent>
                          <StyledDetailsWrapper className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StyledDomainDetail>
                                <Label>
                                  {translate(
                                    "servers.general-info.purchase-date"
                                  )}
                                </Label>
                                <StyledValue>
                                  {server.purchase_date}
                                </StyledValue>
                              </StyledDomainDetail>
                              <StyledDomainDetail>
                                <Label>
                                  {translate(
                                    "servers.general-info.renewal-date"
                                  )}
                                </Label>
                                <StyledValue>{server.renewal_date}</StyledValue>
                              </StyledDomainDetail>
                              <StyledDomainDetail>
                                <Label>
                                  {translate(
                                    "servers.general-info.renewal-period"
                                  )}
                                </Label>
                                <StyledValue>
                                  {translate(
                                    `servers.renewal-period.${server.renewal_period.toLowerCase()}`
                                  )}
                                </StyledValue>
                              </StyledDomainDetail>
                              <StyledDomainDetail>
                                <Label>
                                  {translate(
                                    "servers.general-info.renewal-price"
                                  )}
                                </Label>
                                <StyledValue>
                                  <AmountBadge
                                    amount={server.renewal_price.value}
                                    currency={
                                      server.renewal_price.currency.code
                                    }
                                    textAlign="left"
                                  />
                                </StyledValue>
                              </StyledDomainDetail>
                            </div>
                          </StyledDetailsWrapper>
                          {(server.status.code === SERVER_STATUS_ACTIVE ||
                            server.status.code === SERVER_STATUS_SUSPENDED) && (
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12">
                                <StyledDomainDetail>
                                  <Label>
                                    {translate("servers.general-info.hostname")}
                                  </Label>
                                  <StyledValue>{server.hostname}</StyledValue>
                                </StyledDomainDetail>
                                <StyledDomainDetail>
                                  <Label>
                                    {translate(
                                      "servers.general-info.server-ip"
                                    )}
                                  </Label>
                                  <StyledValue>
                                    {server.dedicated_ip}
                                  </StyledValue>
                                </StyledDomainDetail>
                              </div>
                            </div>
                          )}
                        </StyledContent>
                      </Container>
                    </StyledGeneralInfoWrapper>
                  </StyledGeneralInfoWrapper>
                  {server.status.code !== SERVER_STATUS_TERMINATED && (
                    <StyledGeneralInfoWrapper className="col-12 col-sm-12 col-md-12">
                      <Usage disk={server.disk} bandwidth={server.bandwidth} />
                    </StyledGeneralInfoWrapper>
                  )}
                </React.Fragment>
              )}
              {!this.props.isLaptop && (
                <React.Fragment>
                  <StyledGeneralInfoWrapper className="col-8 col-sm-8 col-md-8">
                    <StyledGeneralInfoWrapper>
                      <Container>
                        <StyledHeader>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StatusBadgeComponent>
                                {translate(
                                  "servers.statuses." + server.status.name
                                )}
                              </StatusBadgeComponent>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8 col-sm-8 col-md-8">
                              <div className="row">
                                <div className="col-12 col-sm-12 col-md-12">
                                  <StyledHostingTitle>
                                    {server.name}{" "}
                                    <StyledDomain>{server.domain}</StyledDomain>
                                  </StyledHostingTitle>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 col-sm-4 col-md-4 text-right">
                              {(server.status.code === SERVER_STATUS_ACTIVE ||
                                server.status.code ===
                                  SERVER_STATUS_SUSPENDED) && (
                                <PrimaryButton
                                  onClick={() => onRenewNow(server)}
                                  disabled={renewNowSubmitting}
                                  submitting={renewNowSubmitting}
                                  className="renew-button"
                                >
                                  {translate("servers.general-info.renew-now")}
                                </PrimaryButton>
                              )}
                            </div>
                          </div>
                        </StyledHeader>
                        <StyledContent>
                          <StyledDetailsWrapper className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StyledDomainDetail>
                                <Label>
                                  {translate(
                                    "servers.general-info.purchase-date"
                                  )}
                                </Label>
                                <StyledValue>
                                  {server.purchase_date}
                                </StyledValue>
                              </StyledDomainDetail>
                              <StyledDomainDetail>
                                <Label>
                                  {translate(
                                    "servers.general-info.renewal-date"
                                  )}
                                </Label>
                                <StyledValue>{server.renewal_date}</StyledValue>
                              </StyledDomainDetail>
                              <StyledDomainDetail>
                                <Label>
                                  {translate(
                                    "servers.general-info.renewal-period"
                                  )}
                                </Label>
                                <StyledValue>
                                  {translate(
                                    `servers.renewal-period.${server.renewal_period.toLowerCase()}`
                                  )}
                                </StyledValue>
                              </StyledDomainDetail>
                              <StyledDomainDetail>
                                <Label>
                                  {translate(
                                    "servers.general-info.renewal-price"
                                  )}
                                </Label>
                                <StyledValue>
                                  <AmountBadge
                                    amount={server.renewal_price.value}
                                    currency={
                                      server.renewal_price.currency.code
                                    }
                                    textAlign="left"
                                  />
                                </StyledValue>
                              </StyledDomainDetail>
                            </div>
                          </StyledDetailsWrapper>
                          {(server.status.code === SERVER_STATUS_ACTIVE ||
                            server.status.code === SERVER_STATUS_SUSPENDED) && (
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12">
                                <StyledDomainDetail>
                                  <Label>
                                    {translate("servers.general-info.hostname")}
                                  </Label>
                                  <StyledValue>{server.hostname}</StyledValue>
                                </StyledDomainDetail>
                                <StyledDomainDetail>
                                  <Label>
                                    {translate(
                                      "servers.general-info.server-ip"
                                    )}
                                  </Label>
                                  <StyledValue>
                                    {server.dedicated_ip}
                                  </StyledValue>
                                </StyledDomainDetail>
                              </div>
                            </div>
                          )}
                        </StyledContent>
                      </Container>
                    </StyledGeneralInfoWrapper>
                  </StyledGeneralInfoWrapper>
                </React.Fragment>
              )}
            </BrowserView>
          </div>
        )}
      </Translate>
    );
  }
}

export default withSizes(isLaptop)(GeneralInfo);
