import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { NavLink } from "react-router-dom";

import Icon from "../../Icon";

const StyledSidebarNavItem = styled.li`
  & .nav-link {
    color: ${theme.white.fade(0.1)};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letterspacing: 0.2px;
    padding-left: 30px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 10px;
    border-right: 3px solid transparent;
  }
  & .nav-link:hover {
    color: ${theme.white};
    background-color: ${theme.blue3.fade(0.7)};
  }

  & .nav-link.active {
    border-right: 3px solid ${theme.white};
    background-color: ${theme.blue3.fade(0.7)};
  }
  & .nav-link.new{
    position: relative;
  }
  
  & .nav-link.new .label-new{
    position: absolute;
    width: 35px;
    height: 13px;
    background: green;
    border-radius: 5px;
    top: 0;
    left: 45px;
    font-size: 10px;
    padding: 5px;
    line-height: 0;
    text-align: center;
  }

  & .nav-link .icon {
    margin-right: 15px;
    color: ${theme.white.fade(0.4)};
  }
  & .nav-link:hover .icon {
    color: ${theme.white};
  }

  & .nav-link .sidebar-notifications {
    display: inline-block;
    padding: 1px 5.5px;
    background-color: ${theme.white};
    border-radius: 3px;
    color: ${theme.blueBase};
    font-size: 12px;
    font-weight: 600;
    line-height: 1.33;
    text-align: center;
    float: right;
    margin-right: 10px;
  }
`;

const SidebarNavItem = props => (
  <StyledSidebarNavItem className="nav-item">
    <NavLink
      exact={props.exact}
      className={props.isNew ? 'nav-link  new ' : 'nav-link '}
      activeClassName="active"
      to={props.to}
    >
      {props.icon && <Icon icon={props.icon} />}
      {props.title}{" "}
      {props.notifications && (
        <span className="sidebar-notifications">{props.notifications}</span>
      )}
        {props.isNew && (
            <span className={"label-new"}>
                {props.isNew}
            </span>
        )}
    </NavLink>
  </StyledSidebarNavItem>
);

SidebarNavItem.defaultProps = {
  className: "nav-item",
  to: "",
  active: false,
  icon: null,
  notificatins: null,
  title: "",
  onClick: () => {}
};

export default SidebarNavItem;
