  import {VERIFY_EMAIL_SUCCESS,VERIFY_EMAIL_FAILURE,VERIFY_EMAIL_REQUEST} from '../actions/EmailVerificationActions';
  
  const initialState = {
    isLoading: false,
    isSuccess: false,
    isError: false,
    isEmailVerified:false,
  };
  


  export const verifyEmailReducer = (state = initialState, action) => {
    switch (action.type) {
      case VERIFY_EMAIL_REQUEST:
        return {
          ...state,
          isLoading: true,
          isSuccess: false,
          isError: false,
          isEmailVerified:false
        };
      case VERIFY_EMAIL_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isError: false,
          isEmailVerified:true,
        };
      case VERIFY_EMAIL_FAILURE:
        return {
          ...state,
          isLoading: false,
          isSuccess: false,
          isError: true,
          isEmailVerified:false,
        };
      default:
        return state;
    }
  };
  

  export function isEmailVerified(state) {
    return state.isEmailVerified;
  }
  

  export default verifyEmailReducer;
  