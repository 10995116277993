import React from "react";
import styled from "styled-components";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker as ReactDateRangePicker } from "react-dates";
import Label from "../Label";
import moment from "moment";

const StyledDateRangePicker = styled.div`
  & .DateRangePicker {
    width: 100%;
    border-radius: 4px;
  }

  & .DateRangePickerInput {
    border-radius: 4px;
    width: 100%;
  }

  & .CalendarDay__selected_span {
    background: red; //background
    color: white; //text
    border: 1px solid #1b75bc; //default styles include a border
  }

  // Will edit selected date or the endpoints of a range of dates
  & .CalendarDay__selected {
    background: #1b75bc;
    color: white;
    font-size: 14px;
  }

  // Will edit when hovered over. _span style also has this property
  & .CalendarDay__selected:hover {
    background: #1b75bc;
    color: white;
  }

  // Will edit when the second date (end date) in a range of dates
  // is not yet selected. Edits the dates between your mouse and said date
  & .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: #1b75bc;
    color: white;
  }

  & .DateInput {
    background-color: transparent;
  }
  & .DateInput_input {
    font-size: 14px;
    line-height: 1.43;
    border-radius: 4px;
  }
`;

class DateRangePicker extends React.Component {
  constructor(props) {
    super(props);

    let focusedInput = null;
    if (props.autoFocus) {
      focusedInput = null;
    } else if (props.autoFocusEndDate) {
      focusedInput = null;
    }

    this.state = {
      focusedInput,
      startDate: props.initialDateWrapper(props.initialStartDate),
      endDate: props.initialDateWrapper(props.initialEndDate)
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onDatesChange({ startDate, endDate }) {
    const { stateDateWrapper } = this.props;
    this.setState({
      startDate: startDate && stateDateWrapper(startDate),
      endDate: endDate && stateDateWrapper(endDate)
    });

    if (startDate && endDate) {
      this.props.dateRangeSelected(startDate, endDate);
    }
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput });
  }

  render() {
    const { focusedInput, startDate, endDate } = this.state;
    const {
      label,
      name,
      stateDateWrapper,
      initialDateWrapper,
      dateRangeSelected,
      initialStartDate,
      initialEndDate,
      ...rest
    } = this.props;

    return (
      <StyledDateRangePicker className="form-group">
        {label.length > 0 && <Label icon={this.props.labelIcon}>{label}</Label>}
        <ReactDateRangePicker
          {...rest}
          onDatesChange={this.onDatesChange}
          onFocusChange={this.onFocusChange}
          focusedInput={focusedInput}
          startDate={startDate}
          endDate={endDate}
        />
      </StyledDateRangePicker>
    );
  }
}

DateRangePicker.defaultProps = {
  startDateId: "startDate",
  startDatePlaceholderText: "Start Date",
  endDateId: "endDate",
  endDatePlaceholderText: "End Date",
  disabled: false,
  required: false,
  screenReaderInputMessage: "",
  showClearDates: false,
  showDefaultInputIcon: false,
  customInputIcon: null,
  customArrowIcon: null,
  customCloseIcon: null,
  block: false,
  small: false,
  regular: false,
  renderMonthText: null,
  orientation: "horizontal",
  anchorDirection: "left",
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  initialVisibleMonth: null,
  numberOfMonths: 2,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  isRTL: false,
  navPrev: null,
  navNext: null,
  onPrevMonthClick() {},
  onNextMonthClick() {},
  onClose() {},
  renderCalendarDay: undefined,
  renderDayContents: null,
  minimumNights: 1,
  enableOutsideDays: false,
  isDayBlocked: () => false,
  isDayHighlighted: () => false,
  monthFormat: "MMMM YYYY",
  stateDateWrapper: date => date,
  initialDateWrapper: date => {
    if (date && date !== "") return moment(date);
  },
  isOutsideRange: () => false
};

export default DateRangePicker;
