import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { MobileOnlyView, TabletView, BrowserView } from "react-device-detect";

import Badge from "../../Badges/Badge";
import Icon from "../../Icon";

const StyledKnowledgeBaseArticlePane = styled.div`
  padding: 15px 25px 25px 25px;

  border-bottom: 1px solid ${theme.neutral3};
  & .icon-wrapper .icon {
    top: 10px;
  }
`;

const StyledKnowledgeBaseArticleHeader = styled.div`
  cursor: pointer;
`;

const StyledKnowledgeBaseArticleContent = styled.div`
  padding-top: 15px;
  padding-bottom: 20px;
`;

const StyledTitle = styled.p`
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${theme.neutralBase};

  & .articles-count {
    float: right;
  }
`;
const StyledDescription = styled.p`
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
`;

class KnowledgeBaseArticlesPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
  }

  onToggle = e => {
    e.preventDefault();
    this.setState({
      opened: !this.state.opened
    });
  };

  render() {
    return (
      <StyledKnowledgeBaseArticlePane>
        <MobileOnlyView>
          <StyledKnowledgeBaseArticleHeader>
            <div className="row" onClick={this.onToggle}>
              <div className="col-2 col-sm-2 col-md-1 icon-wrapper align-content-center align-items-center align-middle">
                <Icon icon={`chevron_${this.state.opened ? "up" : "down"}`} />
              </div>
              <div className="col-10 col-sm-10 col-md-11">
                <StyledTitle>{this.props.title}</StyledTitle>
                <StyledDescription>{this.props.description}</StyledDescription>
              </div>
              <div className="offset-2 col-10 offset-sm-2 col-sm-10 offset-md-1 col-md-11 ">
                <Badge className="articles-count">
                  {this.props.numberOfArticles} Articles
                </Badge>
              </div>
            </div>
          </StyledKnowledgeBaseArticleHeader>
        </MobileOnlyView>
        <TabletView>
          {" "}
          <StyledKnowledgeBaseArticleHeader>
            <div className="row" onClick={this.onToggle}>
              <div className="col-2 col-sm-2 col-md-2 icon-wrapper align-content-center align-items-center">
                <Icon icon={`chevron_${this.state.opened ? "up" : "down"}`} />
              </div>
              <div className="col-10 col-sm-10 col-md-10">
                <StyledTitle>{this.props.title}</StyledTitle>
                <StyledDescription>{this.props.description}</StyledDescription>
              </div>
              <div className="offset-2 col-10 offset-sm-2 col-sm-10 offset-md-2 col-md-10 ">
                <Badge className="articles-count">
                  {this.props.numberOfArticles} Articles
                </Badge>
              </div>
            </div>
          </StyledKnowledgeBaseArticleHeader>
        </TabletView>
        <BrowserView>
          <StyledKnowledgeBaseArticleHeader>
            <div className="row" onClick={this.onToggle}>
              <div className="col-2 col-sm-2 col-md-1 icon-wrapper align-content-center align-items-center align-middle">
                <Icon icon={`chevron_${this.state.opened ? "up" : "down"}`} />
              </div>
              <div className="col-10 col-sm-10 col-md-11">
                <StyledTitle>
                  {this.props.title}{" "}
                  <Badge className="articles-count">
                    {this.props.numberOfArticles} Articles
                  </Badge>
                </StyledTitle>
                <StyledDescription>{this.props.description}</StyledDescription>
              </div>
            </div>
          </StyledKnowledgeBaseArticleHeader>
        </BrowserView>
        {this.state.opened && (
          <div className="row">
            <div className="col-10 offset-2 col-sm-12 offset-sm-2 col-md-11 offset-md-1">
              <StyledKnowledgeBaseArticleContent>
                {this.props.children}
              </StyledKnowledgeBaseArticleContent>
            </div>
          </div>
        )}
      </StyledKnowledgeBaseArticlePane>
    );
  }
}

export default KnowledgeBaseArticlesPanel;
