import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { MobileOnlyView, TabletView, BrowserView } from "react-device-detect";
import { Translate } from "react-localize-redux";
import withSizes from "react-sizes";
import isLaptop from "../../../../utils/isLaptopScreen";

import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";
import CompactCartProductAddon from "./CompactCartProductAddon";

const StyledCompactCartProduct = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  padding: 30px;
`;

const StyledProductType = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutralBase};
`;
const StyledProductTitle = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
`;

const CompactCartProduct = ({ product, isLaptop }) => {

  let selectedPeriodCode = product.item.pricing.buy.billing_cycle.derivations.find(
    d => d.numeric_code === product.config.period
  );
  selectedPeriodCode = typeof(selectedPeriodCode) !== 'undefined' ? selectedPeriodCode.code : 'monthly';

  return (
  <Translate>
    {({ translate }) => (
      <StyledCompactCartProduct>
        <MobileOnlyView>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <StyledProductType>{product.item.name}</StyledProductType>
              <StyledProductTitle>
                {product.domain} -{" "}
                {product.config.period < 1 ? "" : product.config.period}{" "}
                {product.config.period < 1
                  ? `${translate("shop.hostings.derivations." + selectedPeriodCode)}`
                  : product.config.period > 1
                    ? ` ${translate("cart.years")}`
                    : ` ${translate("cart.year")}`}
              </StyledProductTitle>
            </div>
            <div className="col-12 col-sm-12 col-md-12 text-right">
              <DiscountedAmountBadge
                amount={{
                  currency:
                    product.item.pricing.buy.billing_cycle.derivations[0].price
                      .currency.code,
                  amount: product.total,
                  discountedAmount:
                    product.total < product.price ? product.price : null
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12" />
          </div>
        </MobileOnlyView>
        <TabletView>
          <div className="row">
            <div className="col-8 col-sm-8 col-md-8">
              <StyledProductType>{product.item.name}</StyledProductType>
              <StyledProductTitle>
                {product.domain} -{" "}
                {product.config.period < 1 ? "" : product.config.period}{" "}
                {product.config.period < 1
                  ? `${translate("shop.hostings.derivations." + selectedPeriodCode)}`
                  : product.config.period > 1
                    ? ` ${translate("cart.years")}`
                    : ` ${translate("cart.year")}`}
              </StyledProductTitle>
            </div>
            <div className="col-4 col-sm-4 col-md-4 text-right">
              <DiscountedAmountBadge
                amount={{
                  currency:
                    product.item.pricing.buy.billing_cycle.derivations[0].price
                      .currency.code,
                  amount: product.total,
                  discountedAmount:
                    product.total < product.price ? product.price : null
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12" />
          </div>
        </TabletView>
        <BrowserView>
          {isLaptop && (
            <div className="row">
              <div className="col-7 col-sm-7 col-md-7">
                <StyledProductType>{product.item.name}</StyledProductType>
                <StyledProductTitle>
                  {product.domain} -{" "}
                  {product.config.period < 1 ? "" : product.config.period}{" "}
                  {product.config.period < 1
                    ? `${translate("shop.hostings.derivations." + selectedPeriodCode)}`
                    : product.config.period > 1
                      ? ` ${translate("cart.years")}`
                      : ` ${translate("cart.year")}`}
                </StyledProductTitle>
              </div>
              <div className="col-5 col-sm-5 col-md-5 text-right">
                <DiscountedAmountBadge
                  amount={{
                    currency:
                      product.item.pricing.buy.billing_cycle.derivations[0]
                        .price.currency.code,
                    amount: product.total,
                    discountedAmount:
                      product.total < product.price ? product.price : null
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12" />
            </div>
          )}
          {!isLaptop && (
            <div className="row">
              <div className="col-8 col-sm-8 col-md-8">
                <StyledProductType>{product.item.name}</StyledProductType>
                <StyledProductTitle>
                  {product.domain} -{" "}
                  {product.config.period < 1 ? "" : product.config.period}{" "}
                  {product.config.period < 1
                    ? `${translate("shop.hostings.derivations." + selectedPeriodCode)}`
                    : product.config.period > 1
                      ? ` ${translate("cart.years")}`
                      : ` ${translate("cart.year")}`}
                </StyledProductTitle>
              </div>
              <div className="col-4 col-sm-4 col-md-4 text-right">
                <DiscountedAmountBadge
                  amount={{
                    currency:
                      product.item.pricing.buy.billing_cycle.derivations[0]
                        .price.currency.code,
                    amount: product.total,
                    discountedAmount:
                      product.total < product.price ? product.price : null
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12" />
            </div>
          )}
        </BrowserView>
        {product.item.services &&
          product.item.services.length > 0 &&
          product.item.services
            .filter(service => service.activated)
            .map(service => (
              <CompactCartProductAddon
                key={`compact-cart-product-service-${service.id}`}
                service={service}
              />
            ))}
      </StyledCompactCartProduct>
    )}
  </Translate>
  );
};

CompactCartProduct.defaultProps = {
  product: "Product",
  price: {
    currency: "EUR",
    amount: 30.5
  },
  addons: null
};

export default withSizes(isLaptop)(CompactCartProduct);
