import React from "react";
import { isMobileOnly } from "react-device-detect";
import DomainContact from "./DomainContact";
import { Translate } from "react-localize-redux";
import styled from "styled-components";

import ContentLoader from "../../../../components/Loaders/ContentLoader";
import withLoading from "../../../../components/Loaders/WithLoading";

import DomainProtection from "./DomainProtection";

import {
  CONTACT_TYPE_ADMINISTRATIVE,
  CONTACT_TYPE_REGISTRANT,
  CONTACT_TYPE_TECHNICAL
} from "./DomainContactTypes";

const StyledDomainContactContainer = styled.div``;

const DomainContactsStyledContainerWithLoader = withLoading(
  StyledDomainContactContainer,
  ContentLoader
);

const DomainContacts = ({
  title,
  domain,
  contacts,
  onEditDomainContact,
  validateDomainContact,
  onChangeDomainContact,
  isMkDomain,
  isFetchingDomainRegisterContacts,
  domainServices,
  addToCartDomainService,
  onShowBuyDomainIdProtection,
  isSubmittingDomainIdProtection
}) => {
  const registrant = contacts.find(
    contact => contact.type === CONTACT_TYPE_REGISTRANT
  );
  const administrative = contacts.find(
    contact => contact.type === CONTACT_TYPE_ADMINISTRATIVE
  );
  const technical = contacts.find(
    contact => contact.type === CONTACT_TYPE_TECHNICAL
  );
  return (
    <Translate>
      {({ translate }) => (
        <DomainContactsStyledContainerWithLoader
          isLoading={isFetchingDomainRegisterContacts}
          className="row"
        >
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              {registrant && (
                <div
                  className={
                    isMobileOnly
                      ? "col-12 col-sm-12 col-md-12"
                      : "col-4 col-sm-4 col-md-4"
                  }
                >
                  <DomainContact
                    title={translate(
                      "domain.domain-contacts.domain-registrant"
                    )}
                    contact={registrant}
                    onEditDomainContact={onEditDomainContact}
                    onChangeDomainContact={onChangeDomainContact}
                    disableEdit={isMkDomain}
                    disableChange={isMkDomain}
                    isMkDomain={isMkDomain}
                    type={CONTACT_TYPE_REGISTRANT}
                  />
                </div>
              )}
              {administrative && (
                <div
                  className={
                    isMobileOnly
                      ? "col-12 col-sm-12 col-md-12"
                      : "col-4 col-sm-4 col-md-4"
                  }
                >
                  <DomainContact
                    title={translate(
                      "domain.domain-contacts.administrative-contact"
                    )}
                    contact={administrative}
                    onEditDomainContact={onEditDomainContact}
                    onChangeDomainContact={onChangeDomainContact}
                    isMkDomain={isMkDomain}
                    type={CONTACT_TYPE_ADMINISTRATIVE}
                  />
                </div>
              )}
              {technical && (
                <div
                  className={
                    isMobileOnly
                      ? "col-12 col-sm-12 col-md-12"
                      : "col-4 col-sm-4 col-md-4"
                  }
                >
                  <DomainContact
                    title={translate(
                      "domain.domain-contacts.technical-contact"
                    )}
                    contact={technical}
                    onEditDomainContact={onEditDomainContact}
                    onChangeDomainContact={onChangeDomainContact}
                    isMkDomain={isMkDomain}
                    type={CONTACT_TYPE_TECHNICAL}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-12">
            <DomainProtection
              domain={domain}
              services={domainServices}
              onShowBuyDomainIdProtection={onShowBuyDomainIdProtection}
              isSubmittingDomainIdProtection={isSubmittingDomainIdProtection}
            />
          </div>
        </DomainContactsStyledContainerWithLoader>
      )}
    </Translate>
  );
};

export default DomainContacts;
