export function trackPurchase(proInvoice, filterItems) {
  const gtag = window.gtag;

  if (typeof gtag !== "function") {
    return false;
  }

  // prettier-ignore
  gtag('set', {'currency': proInvoice.currency.code.toLowerCase() === 'мкд' ? 'MKD' : 'EUR'});
  // prettier-ignore
  gtag("set", {
            'currencyCode':
                proInvoice.currency.code.toLowerCase() === "мкд" ? "MKD" : "EUR"
        });
  // prettier-ignore
  gtag("config", process.env.REACT_APP_MKHOST_GOOGLE_ANALYTICS_TRACKING_ID, {
            'page_path': `${window.location.pathname}?invoice=${
                proInvoice.id
            }&total=${proInvoice.total}&currency=${
                proInvoice.currency.code.toLowerCase() === "мкд" ? "MKD" : "EUR"
            }&currencyCode=${
                proInvoice.currency.code.toLowerCase() === "мкд" ? "MKD" : "EUR"
            }`,
            'page_location': `${window.location.href}?invoice=${
                proInvoice.id
            }&total=${proInvoice.total}&currency=${
                proInvoice.currency.code.toLowerCase() === "мкд" ? "MKD" : "EUR"
            }&currencyCode=${
                proInvoice.currency.code.toLowerCase() === "мкд" ? "MKD" : "EUR"
            }`
        });

  //prettier-ignore
  gtag("event", "purchase", {
            'value': parseFloat(proInvoice.total),
            'currency':
                proInvoice.currency.code.toLowerCase() === "мкд" ? "MKD" : "EUR",
            'currency_code':
                proInvoice.currency.code.toLowerCase() === "мкд" ? "MKD" : "EUR",
            'currencyCode':
                proInvoice.currency.code.toLowerCase() === "мкд" ? "MKD" : "EUR",
            'transaction_id': proInvoice.id,
            'order_id': proInvoice.id,
            'invoiceid': proInvoice.id,
            'action': "Purchase-Click",
            'event_category': "Purchase",
            'event_label': "Customers",
            'items': proInvoice.items.filter(item => filterItems.indexOf(item.type) > -1).map(item => {
                // prettier-ignore
                return {
                    'id': item.id,
                    'name': '[' + item.type  + '] - ' +item.description,
                    'category': item.type,
                    'quantity': 1,
                    'price': item.amount
                }
            })
        });
  // prettier-ignore
  gtag("event", "conversion", {
    'send_to': `${process.env.REACT_APP_MKHOST_GOOGLE_ADWORDS_TRACKING_ID}/${process.env.REACT_APP_MKHOST_GOOGLE_ADWORDS_TRACKING_LABEL}`,
    'value': parseFloat(proInvoice.total),
    'currency':
      proInvoice.currency.code.toLowerCase() === "мкд" ? "MKD" : "EUR",
    'currency_code':
      proInvoice.currency.code.toLowerCase() === "мкд" ? "MKD" : "EUR",
    'currencyCode':
      proInvoice.currency.code.toLowerCase() === "мкд" ? "MKD" : "EUR",
    'transaction_id': proInvoice.id,
    'order_id': proInvoice.id,
    'invoiceid': proInvoice.id,
    'action': "Conversion-Click",
    'event_category': "Conversion-Purchase",
    'event_label': "Conversion-Customer",
    'items': proInvoice.items.filter(item => filterItems.indexOf(item.type) > -1).map(item => {
      // prettier-ignore
      return {
        'id': item.id,
        'name': '[' + item.type  + '] - ' +item.description,
        'category': item.type,
        'quantity': 1,
        'price': item.amount
      }
    })
  });
}
