import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import {
  MobileOnlyView,
  isMobileOnly,
  isTablet,
  isBrowser
} from "react-device-detect";

import Container from "../../../components/Containers/Container";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import DropdownButton from "../../../components/DropdownButton";
import DropdownItem from "../../../components/DropdownItem";
import ButtonGroupContentDropdown from "../../../components/ButtonGroupContentDropdown";
import Form from "../../../components/Forms/Form";
import SearchField from "../../../components/SearchField";
import Checkbox from "../../../components/Checkbox";
import AmountBadge from "../../../components/AmountBadge";
import Icon from "../../../components/Icon";

import Table from "../../../components/Table";
import TableHeader from "../../../components/Table/TableHeader";
import TableHeading from "../../../components/Table/TableHeading";
import TableBody from "../../../components/Table/TableBody";
import TableRow from "../../../components/Table/TableRow";
import TableCell from "../../../components/Table/TableCell";

import Pagination from "../../../components/Pagination";

import TicketsFilter from "../TicketsFilter";

import getBadgeForStatus from "../../../utils/getBadgeForStatus";
import getStatusTextForStatus from "../../../utils/getStatusTextForStatus";

import withLoading from "../../../components/Loaders/WithLoading";
import TableContentLoader from "../../../components/Loaders/TableContentLoader";

import {
  compileRoute,
  ROUTE_HELPDESK_TICKET_VIEW,
  ROUTE_CREATE_TICKET
} from "../../../routes/routes";

import {
  HOSTING_STATUS_PENDING,
  HOSTING_STATUS_TERMINATED
} from "../TicketStatuses/TicketStatuses";

const StyledTicketsListWrapper = styled.div`
  margin-bottom: ${props => (props.isMobile ? "50px" : "30px")};
`;

const StyledTicketsList = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;

  & .tickets-list-header {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
  }
  //
  //& .tickets-list-header button.open-ticket {
  //  margin-right: 0;
  //}
`;

const StyledTicketsListContainer = styled.div`
  & a.ticket-link {
    font-size: 16px;
  }

  & a.ticket-link-block {
    display: block;
  }

  & .domain-url-block {
    display: block;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }

  & p.domain-name {
    font-size: 12px;
  }
`;

const StyledCancelPlan = styled.span`
  color: #be2b16;
`;

const StyledPurchaseTickets = styled(Link)`
  margin-right: 15px;
`;

const StyledSelectedCount = styled.span`
  margin-right: 15px;
`;

const TicketsList = props => {
  const StyledTicketsTableWithLoading = withLoading(
    StyledTicketsListContainer,
    TableContentLoader
  );

  const filtersApplied = props.countFiltersApplied();

  return (
    <Translate>
      {({ translate }) => (
        <StyledTicketsListWrapper>
          <Container>
            <StyledTicketsList>
              <MobileOnlyView>
                <div className="tickets-list-header">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledPurchaseTickets to={ROUTE_CREATE_TICKET}>
                        <PrimaryButton className="open-ticket w-100">
                          {translate("help-desk.tickets.table.open-ticket")}
                        </PrimaryButton>
                      </StyledPurchaseTickets>

                      {props.isLaptop && (
                        <ButtonGroupContentDropdown
                          text={`${translate(
                            "help-desk.tickets.filter.title"
                          )}  ${
                            filtersApplied > 0 ? " - " + filtersApplied : ""
                          }`}
                          size="m"
                          alignMenu="right"
                        >
                          <TicketsFilter
                            onStatusFilterOptionChange={
                              props.onStatusFilterOptionChange
                            }
                            onProductsFilterOptionChange={
                              props.onProductsFilterOptionChange
                            }
                            onDepartmentFilterOptionChange={
                              props.onDepartmentFilterOptionChange
                            }
                            onFiltersClear={props.onFiltersClear}
                            countFiltersApplied={props.countFiltersApplied}
                            filterStatusOptions={props.filterStatusOptions}
                            filterProductsOptions={props.filterProductsOptions}
                            filterDepartmentsOptions={
                              props.filterDepartmentsOptions
                            }
                            products={props.products}
                            departments={props.departments}
                            tickets={props.tickets}
                          />
                        </ButtonGroupContentDropdown>
                      )}
                    </div>
                  </div>
                </div>
                <div className="tickets-list-header">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                        }}
                      >
                        <SearchField
                          onChange={props.onChangeKeywordSearch}
                          name="search"
                          value={props.searchQuery}
                          placeholder={translate(
                            "help-desk.tickets.table.search-placeholder"
                          )}
                        />
                      </Form>
                    </div>
                  </div>
                </div>
              </MobileOnlyView>
              {(isTablet || isBrowser) && (
                <div className="tickets-list-header">
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-6">
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                        }}
                      >
                        <SearchField
                          onChange={props.onChangeKeywordSearch}
                          name="search"
                          value={props.searchQuery}
                          placeholder={translate(
                            "help-desk.tickets.table.search-placeholder"
                          )}
                        />
                      </Form>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 text-right">
                      <StyledPurchaseTickets to={ROUTE_CREATE_TICKET}>
                        <PrimaryButton className="open-ticket">
                          {translate("help-desk.tickets.table.open-ticket")}
                        </PrimaryButton>
                      </StyledPurchaseTickets>

                      {props.isLaptop && (
                        <ButtonGroupContentDropdown
                          text={`${translate("hosting.filter.title")}  ${
                            filtersApplied > 0 ? " - " + filtersApplied : ""
                          }`}
                          size="m"
                          alignMenu="right"
                        >
                          <TicketsFilter
                            onStatusFilterOptionChange={
                              props.onStatusFilterOptionChange
                            }
                            onProductsFilterOptionChange={
                              props.onProductsFilterOptionChange
                            }
                            onDepartmentFilterOptionChange={
                              props.onDepartmentFilterOptionChange
                            }
                            onFiltersClear={props.onFiltersClear}
                            countFiltersApplied={props.countFiltersApplied}
                            filterStatusOptions={props.filterStatusOptions}
                            filterProductsOptions={props.filterProductsOptions}
                            filterDepartmentsOptions={
                              props.filterDepartmentsOptions
                            }
                            products={props.products}
                            departments={props.departments}
                            tickets={props.tickets}
                          />
                        </ButtonGroupContentDropdown>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledTicketsTableWithLoading isLoading={props.isLoading}>
                    <Table>
                      {isBrowser && (
                        <TableHeader>
                          <TableRow>
                            <TableHeading
                              width="30%"
                              isSortable={true}
                              sortBy="subject"
                              isDefaultSort={
                                props.tickets.sort.sort_by === "subject"
                              }
                              sortDirection={props.tickets.sort.order_by}
                              changeListOrderTrigger={
                                props.changeListOrderTrigger
                              }
                            >
                              {translate("help-desk.tickets.table.subject")}
                            </TableHeading>
                            <TableHeading
                              width="17%"
                              isSortable={true}
                              sortBy="product"
                              isDefaultSort={
                                props.tickets.sort.sort_by === "product"
                              }
                              sortDirection={props.tickets.sort.order_by}
                              changeListOrderTrigger={
                                props.changeListOrderTrigger
                              }
                            >
                              {translate("help-desk.tickets.table.product")}
                            </TableHeading>
                            <TableHeading
                              width="17%"
                              isSortable={true}
                              sortBy="department"
                              isDefaultSort={
                                props.tickets.sort.sort_by === "department"
                              }
                              sortDirection={props.tickets.sort.order_by}
                              changeListOrderTrigger={
                                props.changeListOrderTrigger
                              }
                            >
                              {translate("help-desk.tickets.table.department")}
                            </TableHeading>
                            <TableHeading
                              width="17%"
                              isSortable={true}
                              sortBy="created_date"
                              isDefaultSort={
                                props.tickets.sort.sort_by === "created_date"
                              }
                              sortDirection={props.tickets.sort.order_by}
                              changeListOrderTrigger={
                                props.changeListOrderTrigger
                              }
                            >
                              {translate(
                                "help-desk.tickets.table.created-date"
                              )}
                            </TableHeading>
                            <TableHeading
                              width="16%"
                              isSortable={true}
                              sortBy="updated_date"
                              isDefaultSort={
                                props.tickets.sort.sort_by === "updated_date"
                              }
                              sortDirection={props.tickets.sort.order_by}
                              changeListOrderTrigger={
                                props.changeListOrderTrigger
                              }
                            >
                              {translate(
                                "help-desk.tickets.table.updated-date"
                              )}
                            </TableHeading>
                            <TableHeading
                              width="10%"
                              isSortable={true}
                              sortBy="status"
                              isDefaultSort={
                                props.tickets.sort.sort_by === "status"
                              }
                              sortDirection={props.tickets.sort.order_by}
                              changeListOrderTrigger={
                                props.changeListOrderTrigger
                              }
                            >
                              {translate("help-desk.tickets.table.status")}
                            </TableHeading>
                          </TableRow>
                        </TableHeader>
                      )}
                      <TableBody>
                        {props.tickets.items.length === 0 && (
                          <TableRow>
                            <TableCell colSpan="6">
                              {translate("help-desk.tickets.table.empty")}
                            </TableCell>
                          </TableRow>
                        )}
                        {props.tickets.items.length > 0 &&
                          props.tickets.items.map(ticket => {
                            const BadgeComponent = getBadgeForStatus(
                              ticket.status.toLowerCase()
                            );

                            return (
                              <TableRow key={`customer-ticket-${ticket.id}`}>
                                <TableCell width="30%">
                                  <Link
                                    className={
                                      isTablet || isMobileOnly
                                        ? "ticket-link ticket-link-block"
                                        : "ticket-link"
                                    }
                                    to={compileRoute(
                                      ROUTE_HELPDESK_TICKET_VIEW
                                    )({
                                      id: ticket.id
                                    })}
                                  >
                                    #{ticket.id} {ticket.subject}
                                  </Link>{" "}
                                  {(isTablet || isMobileOnly) && (
                                    <BadgeComponent>
                                      {translate(
                                        "help-desk.ticket.statuses." +
                                          ticket.status
                                      )}
                                    </BadgeComponent>
                                  )}
                                </TableCell>
                                {isBrowser && (
                                  <TableCell width="17%">
                                    {ticket.related_product &&
                                    ticket.related_product.name
                                      ? ticket.related_product.name
                                      : "/"}
                                  </TableCell>
                                )}
                                {isBrowser && (
                                  <TableCell width="17%">
                                    {ticket.department || ""}
                                  </TableCell>
                                )}

                                {isBrowser && (
                                  <TableCell width="17%">
                                    {ticket.created_date}
                                  </TableCell>
                                )}
                                <TableCell width="16%">
                                  {ticket.updated_date}
                                </TableCell>
                                {isBrowser && (
                                  <TableCell width="10%">
                                    <BadgeComponent>
                                      {translate(
                                        "help-desk.ticket.statuses." +
                                          ticket.status
                                      )}
                                    </BadgeComponent>
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </StyledTicketsTableWithLoading>
                </div>
              </div>
              <Pagination
                itemsName={translate("help-desk.tickets.table.tickets")}
                paging={props.tickets.paging}
                handlePaginationClick={props.handlePaginationClick}
                onPerPageOptionChanged={props.onPerPageOptionChanged}
              />
            </StyledTicketsList>
          </Container>
        </StyledTicketsListWrapper>
      )}
    </Translate>
  );
};

export default TicketsList;
