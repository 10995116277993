import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

import withSizes from "react-sizes";
import isLaptop from "../../utils/isLaptopScreen";
import { isMobile, isBrowser } from "react-device-detect";
import { Translate } from "react-localize-redux";

import Container from "../../components/Containers/Container";
import Label from "../../components/Label";
import PrimarySuccessButton from "../../components/Buttons/PrimarySuccessButton";
import FormGroup from "../../components/FormGroup";
import Icon from "../../components/Icon";

import FeedbackForm from "../../features/FeedbackForm";

const StyledFeedbackWidget = styled.div`
  padding: 30px;
  position: relative;
  & label {
    font-size: 16px;
    line-height: 22px;
    color: ${theme.neutralBase};
  }

  & p {
    font-weight: 400;
    font-size: 14px;
    color: ${theme.neutral6};
  }

  @media screen and (min-width: 1440px) and (max-width: 1600px) {
    .action-button {
      width: 100% !important;
      flex: 0 0 100%;
      max-width: 100%;
    }

    .widget-info {
      width: 90% !important;
      flex: 0 0 90%;
      max-width: 90%;
    }
  }
`;

const StyledRemoveIcon = styled(Icon)`
  position: absolute;
  right: 10px;
  top: 20px;
`;

const FeedbackWidget = props => {
  let colSizeInfo = "col-12 col-sm-12 col-md-12";
  let colSizeButton = "col-12 col-sm-12 col-md-12 text-right";

  if (!isMobile && isBrowser && props.isLaptop) {
    colSizeInfo = "col-12 col-sm-12 col-md-12";
    colSizeButton = "col-12 col-sm-12 col-md-12";
  } else if (!isMobile && isBrowser && !props.isLaptop) {
    colSizeInfo = "col-12 col-sm-12 col-md-8";
    colSizeButton = "col-12 col-sm-12 col-md-4";
  }
  return (
    <Translate>
      {({ translate }) => (
        <Container className="widget-container">
          <StyledFeedbackWidget className="widget domain-search-widget">
            {props.onRemove &&
              typeof props.onRemove === "function" && (
                <StyledRemoveIcon
                  icon="close"
                  onClick={() => props.onRemove()}
                />
              )}

            <div className="row align-items-center">
              <div className={`${colSizeInfo} widget-info`}>
                <Label>{translate("home.widgets.feedback.label")}</Label>
                <p>{translate("home.widgets.feedback.description")}</p>
              </div>
              <div className={`${colSizeButton} action-button`}>
                <FormGroup className="align-content-center">
                  <FeedbackForm>
                    {onShow => (
                      <PrimarySuccessButton size="m" onClick={() => onShow()}>
                        {translate("home.widgets.feedback.button")}
                      </PrimarySuccessButton>
                    )}
                  </FeedbackForm>
                </FormGroup>
              </div>
            </div>
          </StyledFeedbackWidget>
        </Container>
      )}
    </Translate>
  );
};

export default withSizes(isLaptop)(FeedbackWidget);
