import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";

import Filter from "../../../components/Filter";
import Container from "../../../components/Containers/Container";
import Form from "../../../components/Forms/Form";
import SelectBox from "../../../components/SelectBox";

const StyledHostingFilter = styled.div`
  margin-bottom: 30px;
`;

class HostingFilter extends React.Component {
  render() {
    const selectedProductGroupFilter = this.props.productsListFilters.products.find(
      productGroup => {
        return productGroup.products.find(
          product =>
            product.id === parseInt(this.props.products.filters.product, 10)
        );
      }
    );

    const selectedProductFilter = selectedProductGroupFilter
      ? selectedProductGroupFilter.products.find(
          product =>
            product.id === parseInt(this.props.products.filters.product, 10)
        )
      : false;

    return (
      <Translate>
        {({ translate }) => (
          <StyledHostingFilter>
            <Container>
              <Filter
                title={translate("hosting.filter.title")}
                clearTitle={translate("hosting.filter.clear-all")}
                count={this.props.countFiltersApplied()}
                onClear={this.props.onFiltersClear}
              >
                <Form>
                  <SelectBox
                    name="status"
                    label={translate("filters.status")}
                    placeholder={translate("filters.status-placeholder")}
                    async={true}
                    loadOptions={this.props.filterStatusOptions}
                    defaultOptions
                    isSearchable={false}
                    input={{ onChange: this.props.onStatusFilterOptionChange }}
                    value={
                      this.props.products.filters.status !== ""
                        ? {
                            value: this.props.products.filters.status,
                            label: translate(
                              "hosting.filter.filter-statuses." +
                                this.props.products.filters.status
                            )
                          }
                        : false
                    }
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue &&
                      translate("hosting.filter.no-status-options")
                    }
                  />
                  <SelectBox
                    name="plan"
                    label={translate("filters.product")}
                    placeholder={translate("filters.product-placeholder")}
                    async={true}
                    loadOptions={this.props.filterProductsOptions}
                    defaultOptions
                    isSearchable={false}
                    input={{
                      onChange: this.props.onProductsFilterOptionChange
                    }}
                    value={
                      selectedProductFilter
                        ? {
                            value: selectedProductFilter.id,
                            label: selectedProductFilter.name
                          }
                        : false
                    }
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue &&
                      translate("hosting.filter.no-product-options")
                    }
                  />
                </Form>
              </Filter>
            </Container>
          </StyledHostingFilter>
        )}
      </Translate>
    );
  }
}

HostingFilter.defaultProps = {
  count: 0,
  onClear: () => {}
};

export default HostingFilter;
