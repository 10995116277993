import styled from "styled-components";

const FormGroup = styled.div`
  position: relative;
`;

FormGroup.defaultProps = {
  className: "form-group"
};

export default FormGroup;
