import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes/index";
import { Translate } from "react-localize-redux";
import { Link } from "react-router-dom";
import { isMobileOnly, isTablet } from "react-device-detect";

import graphicsError from "../../../../assets/images/general-error.png";

import PrimaryButton from "../../../../components/Buttons/PrimaryButton";

const StyledTicketNotFound = styled.div`
  padding: 50px 0 25px;
`;

const StyledTicketNotFoundContainer = styled.div`
  background-color: ${theme.white};
  display: inline-block;
  padding: ${props => (props.isMobile ? "70px 15px" : "100px 80px")};
  max-width: 600px;

  & img {
    margin-bottom: 30px;
    width: 200px;
  }

  & .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    text-align: center;
    margin-top: 5px;
  }

  & .description {
    font-size: 16px;
    line-height: 1.5;
    color: ${theme.neutral6};
    text-align: center;
    margin-bottom: 50px;
  }
`;

const TicketNotFound = ({ openNewTicketRoute }) => (
  <Translate>
    {({ translate }) => (
      <StyledTicketNotFound isMobile={isMobileOnly || isTablet}>
        <div className="row">
          <div className="col-8 offset-2 col-sm-8 offset-sm-2 col-md-8 offset-md-2 text-center justify-content-center">
            <StyledTicketNotFoundContainer isMobile={isMobileOnly}>
              <img src={graphicsError} className="img-fluid" alt="" />
              <h4 className="title">
                {translate("help-desk.ticket.not-found")}
              </h4>
              <p className="description">
                {translate("help-desk.ticket.not-found-description")}
              </p>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <Link to={openNewTicketRoute}>
                    <PrimaryButton>
                      {translate("help-desk.ticket.open-new-ticket")}
                    </PrimaryButton>
                  </Link>
                </div>
              </div>
            </StyledTicketNotFoundContainer>
          </div>
        </div>
      </StyledTicketNotFound>
    )}
  </Translate>
);

export default TicketNotFound;
