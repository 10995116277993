import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Translate } from "react-localize-redux";

import Icon from "../../../../components/Icon";
import CustomTooltip from "../../../../components/CustomTooltip";

const StyledDomainContact = styled.div`
  height: auto;
  margin-bottom: 25px;
`;

const StyledContactHeader = styled.div`
  margin-bottom: 20px;
`;
const StyledContactType = styled.h3`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${theme.neutral5};
  text-transform: uppercase;
  float: left;
`;

const StyledContactName = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${theme.neutralBase};
`;

const StyledContactDetail = styled.div`
  margin-bottom: 5px;
`;
const StyledContactValue = styled.div`
  font-size: 16px;
  color: ${theme.neutralBase};
`;

const StyledCustomTooltip = styled.div`
  display: inline;
`;

const DomainContact = ({
  title,
  contact,
  onEditDomainContact,
  onChangeDomainContact,
  tooltip
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <StyledDomainContact>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <StyledContactHeader className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledContactType>{title}</StyledContactType>
                  {tooltip && (
                    <StyledCustomTooltip>
                      <CustomTooltip content={tooltip}>
                        <Icon size="l" icon="info" />
                      </CustomTooltip>
                    </StyledCustomTooltip>
                  )}
                </div>
              </StyledContactHeader>

              {contact && (
                <div>
                  <StyledContactDetail className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledContactName className="align-middle">
                        {contact.company !== ""
                          ? contact.company
                          : `${contact.first_name} ${contact.last_name}`}
                      </StyledContactName>
                      <StyledContactValue>{contact.email}</StyledContactValue>
                    </div>
                  </StyledContactDetail>
                  <StyledContactDetail className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledContactValue>{contact.address}</StyledContactValue>
                      <StyledContactValue>
                        {contact.zip} {contact.city}
                      </StyledContactValue>
                      <StyledContactValue>
                        {contact.country && contact.country.name}
                      </StyledContactValue>
                    </div>
                  </StyledContactDetail>
                  <StyledContactDetail className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledContactValue>{contact.phone}</StyledContactValue>
                    </div>
                  </StyledContactDetail>
                </div>
              )}
            </div>
          </div>
        </StyledDomainContact>
      )}
    </Translate>
  );
};

DomainContact.defaultProps = {
  sameAsRegistrant: false
};

export default DomainContact;
