import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Notification from "../Notification";

const StyledNotificationWarning = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${theme.blue1};
  }

  & .icon {
    color: ${theme.yellowBase};
  }
`;

const NotificationWarning = ({ children, ...rest }) => (
  <StyledNotificationWarning className="notice-success">
    <Notification icon="warning" {...rest}>
      {children}
    </Notification>
  </StyledNotificationWarning>
);

NotificationWarning.defaultProps = {
  className: "notification-warning"
};

export default NotificationWarning;
