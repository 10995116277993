import React from "react";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";

import SelectBox from "../../../../../../../components/SelectBox";

const recordTypes = [
  { label: "A", value: "A" },
  { label: "AAAA", value: "AAAA" },
  { label: "CNAME", value: "CNAME" },
  { label: "MX", value: "MX" },
  { label: "PTR", value: "PTR" },
  { label: "TXT", value: "TXT" },
  { label: "SRV", value: "SRV" },
  { label: "CAA", value: "CAA" }
];

const StyledRecordTypeSelect = styled.div`
  display: inline-block;
  width: 150px;
  margin-right: 15px;
`;

const RecordTypeSelect = props => {
  const val = recordTypes.find(type => props.formType === type.value);
  return (
    <StyledRecordTypeSelect>
      <SelectBox
        label={props.translate("domain.zone-file.type")}
        name={props.name}
        options={recordTypes}
        defaultValue={val}
        value={val}
        input={{
          onChange: val => props.onChange(val.value)
        }}
      />
    </StyledRecordTypeSelect>
  );
};

export default withLocalize(RecordTypeSelect);
