import React from "react";
import withSizes from "react-sizes";
import styled from "styled-components";
import { isTablet, isMobileOnly } from "react-device-detect";

import ButtonGroup from "../ButtonGroup";
import ButtonGroupDropdown from "../ButtonGroupDropdown";
import DropdownItem from "../DropdownItem";

const StyledAdaptableButtonGroupDropdown = styled(ButtonGroupDropdown)`
  display: ${props => props.isLaptop && "inline-block"};
  margin-right: ${props => props.isLaptop && "15px"};
  position: relative;
  float: ${props => props.isMobile && "right"};
`;

const AdaptableButtonGroup = ({ title, isLaptop, children, ...rest }) => {
  return isLaptop || isTablet || isMobileOnly ? (
    <StyledAdaptableButtonGroupDropdown
      isLaptop={isLaptop || isTablet}
      isMobile={isMobileOnly}
      text={title}
      {...rest}
    >
      {children &&
        React.Children.map(children, child => {
          if (!child) return null;

          if (child && !child.props.disabled) {
            return (
              <DropdownItem onClick={child.props.onClick}>
                {child.props.children}
              </DropdownItem>
            );
          }
          return (
            <DropdownItem disabled={child.props.disabled}>
              {child.props.children}
            </DropdownItem>
          );
        })}
    </StyledAdaptableButtonGroupDropdown>
  ) : (
    <ButtonGroup {...rest} className={`btn-group ${rest.className}`}>
      {children}
    </ButtonGroup>
  );
};

const mapSizesToProps = ({ width }) => ({
  isLaptop: width > 1024 && width < 1600
});

export default withSizes(mapSizesToProps)(AdaptableButtonGroup);
