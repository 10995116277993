import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withSizes from "react-sizes";
import isLaptop from "../../utils/isLaptopScreen";
import { MobileOnlyView, TabletView, BrowserView } from "react-device-detect";
import { debounce } from "throttle-debounce";

import Breadcrumb from "../../components/Breadcrumb";
import BreadcrumbItem from "../../components/Breadcrumb/BreadcrumbItem";

import TicketsList from "./TicketsList";
import Ticket from "../../components/Ticket";
import ActionModal from "../../components/ActionModal";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

//import TicketsWidgetEmptyState from "./TicketsWidgetEmptyState";

import withLoading from "../../components/Loaders/WithLoading";
import TicketsWidgetLoader from "../../components/Loaders/TicketsWidgetLoader";

import KnowledgeBasePanel from "./KnowledgeBasePanel";

import { ROUTE_INDEX, ROUTE_CREATE_TICKET } from "../../routes/routes";

import { SORT_ORDER_DESCENDING } from "../../types/SortTypes";

import {
  fetchHelpDeskKnowledgeBaseCategories,
  fetchHelpDeskKnowledgeBaseMostPopularArticles,
  searchKnowledgeBaseArticles,
  fetchTickets,
  viewTicket,
  closeTicket
} from "../HelpDesk/actions/helpdeskActions";

import {
  getKnowledgeBaseCategories,
  isFetchingKnowledgeBaseCategories,
  getKnowledgeBaseMostPopularArticles,
  isFetchingKnowledgeBaseMostPopularArticles,
  getKnowledgeBaseSearchedArticles,
  isFetchingKnowledgeBaseArticleByKeyword,
  getTickets,
  isFetchingTickets
} from "../HelpDesk/reducers/helpdeskReducer";

import {
  compileRoute,
  ROUTE_HELPDESK_KNOWLEDGE_BASE,
  ROUTE_HELPDESK_VIEW_KNOWLEDGE_BASE_ARTICLE,
  ROUTE_HELPDESK_TICKET_VIEW
} from "../../routes/routes";

import {
  TICKET_STATUS_OPEN,
  TICKET_STATUS_ANSWERED,
  TICKET_STATUS_CUSTOMER_REPLY,
  TICKET_STATUS_IN_PROGRESS,
  TICKET_STATUS_ON_HOLD
} from "../HelpDesk/TicketStatuses";

import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

const TicketListWrapper = styled.div``;
const TicketsListWithLoading = withLoading(
  TicketListWrapper,
  TicketsWidgetLoader
);

const NoTicketsFound = styled.div`
  padding: 30px;
  font-size: 16px;
`;

class HelpDesk extends React.Component {
  constructor(props) {
    super(props);

    this.knowledgeBasePath = compileRoute(ROUTE_HELPDESK_KNOWLEDGE_BASE);
    this.knowledgeArticlePath = compileRoute(
      ROUTE_HELPDESK_VIEW_KNOWLEDGE_BASE_ARTICLE
    );
    this.viewTicketPath = compileRoute(ROUTE_HELPDESK_TICKET_VIEW);

    this.state = {
      searchQuery: "",
      ticketsSearchQuery: "",
      closingTicket: false,
      closingTicketId: 0,
      selectedTicket: null,
      showConfirmCloseTicketModal: false
    };
  }

  componentDidMount() {
    this.searchArticlesByKeywordThrottled = debounce(
      1000,
      this.searchArticlesByKeyword
    );

    this.searchTicketsByKeywordThrottled = debounce(
      1000,
      this.props.fetchTickets
    );

    if (!this.props.knowledgeBaseCategories.length)
      this.props.fetchHelpDeskKnowledgeBaseCategories();

    if (!this.props.knowledgeBaseMostPopularArticles.length)
      this.props.fetchHelpDeskKnowledgeBaseMostPopularArticles();

    this.props.fetchTickets(10, 1, {
      sort_by: "last_reply",
      order_by: SORT_ORDER_DESCENDING
    });
  }

  searchArticlesByKeyword = value => {
    if (value.length > 0) this.props.searchKnowledgeBaseArticles(value);
  };

  onChangeKeywordSearch = event => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.searchArticlesByKeywordThrottled(this.state.searchQuery);
    });
  };

  onChangeTicketKeywordSearch = event => {
    this.setState({ ticketsSearchQuery: event.target.value }, () => {
      this.searchTicketsByKeywordThrottled(
        10,
        {
          sort_by: "last_reply",
          order_by: SORT_ORDER_DESCENDING
        },
        this.state.ticketsSearchQuery
      );
    });
  };

  onOpenNewTicket = () => {
    this.props.history.push(ROUTE_CREATE_TICKET);
  };

  onOpenTicket = (ticket, url) => {
    this.props.viewTicket(ticket);
    this.props.history.push(url);
  };

  onConfirmCloseTicket = ticket => {
    this.setState({
      showConfirmCloseTicketModal: true,
      selectedTicket: ticket
    });
  };

  onHideConfirmCloseTicket = () => {
    this.setState({
      showConfirmCloseTicketModal: false,
      selectedTicket: null
    });
  };

  onCloseTicket = ticket => {
    this.setState({
      closingTicket: true,
      closingTicketId: ticket.id
    });
    this.props.closeTicket(ticket.id).then(({ error, messages, ticket }) => {
      displayToastMessageForResponse("Ticket", { error, messages });

      this.setState({
        closingTicket: false,
        closingTicketId: 0,
        showConfirmCloseTicketModal: false,
        selectedTicket: null
      });
      if (!error) {
        this.props.viewTicket(ticket);
      }
    });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <Breadcrumb separator={true}>
                <BreadcrumbItem
                  to={ROUTE_INDEX}
                  title={translate("breadcrumbs.home")}
                />
                <BreadcrumbItem title={translate("breadcrumbs.help-desk")} />
              </Breadcrumb>
              <MobileOnlyView>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <TicketsList
                      onOpenNewTicket={this.onOpenNewTicket}
                      onSearch={this.onChangeTicketKeywordSearch}
                    >
                      <TicketsListWithLoading
                        isLoading={this.props.isFetchingTickets}
                      >
                        {this.props.tickets.items.length > 0 ? (
                          this.props.tickets.items.map(ticket => (
                            <Ticket
                              key={`ticket-${ticket.id}`}
                              ticket={ticket}
                              closed={ticket.closed}
                              url={this.viewTicketPath(ticket)}
                              onOpenTicket={this.onOpenTicket}
                              onCloseTicket={this.onConfirmCloseTicket}
                              closingTicketId={this.state.closingTicketId}
                            />
                          ))
                        ) : (
                          <NoTicketsFound>
                            {translate("help-desk.no-tickets-found")}...
                          </NoTicketsFound>
                        )}
                      </TicketsListWithLoading>
                    </TicketsList>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12">
                    <KnowledgeBasePanel
                      knowledgeBaseCategories={
                        this.props.knowledgeBaseCategories
                      }
                      isLoadingKnowledgeBaseCategories={
                        this.props.isFetchingKnowledgeBaseCategories
                      }
                      isLoadingKnowledgeBaseMostPopularArticles={
                        this.props.isFetchingKnowledgeBaseMostPopularArticles
                      }
                      knowledgeBaseMostPopularArticles={
                        this.props.knowledgeBaseMostPopularArticles
                      }
                      knowledgeBasePath={this.knowledgeBasePath}
                      articleLink={this.knowledgeArticlePath}
                      searchQuery={this.state.searchQuery}
                      isFetchingKnowledgeBaseArticleByKeyword={
                        this.props.isFetchingKnowledgeBaseArticleByKeyword
                      }
                      searchedArticles={this.props.searchedArticles}
                      knowledgeArticlePath={this.knowledgeArticlePath}
                      onChangeKeywordSearch={this.onChangeKeywordSearch}
                    />
                  </div>
                </div>
              </MobileOnlyView>
              <TabletView>
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-6">
                    <TicketsList
                      onOpenNewTicket={this.onOpenNewTicket}
                      onSearch={this.onChangeTicketKeywordSearch}
                    >
                      <TicketsListWithLoading
                        isLoading={this.props.isFetchingTickets}
                      >
                        {this.props.tickets.items.length > 0 ? (
                          this.props.tickets.items.map(ticket => (
                            <Ticket
                              key={`ticket-${ticket.id}`}
                              ticket={ticket}
                              closed={ticket.closed}
                              url={this.viewTicketPath(ticket)}
                              onOpenTicket={this.onOpenTicket}
                              onCloseTicket={this.onConfirmCloseTicket}
                              closingTicketId={this.state.closingTicketId}
                            />
                          ))
                        ) : (
                          <NoTicketsFound>
                            {translate("help-desk.no-tickets-found")}...
                          </NoTicketsFound>
                        )}
                      </TicketsListWithLoading>
                    </TicketsList>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6">
                    <KnowledgeBasePanel
                      knowledgeBaseCategories={
                        this.props.knowledgeBaseCategories
                      }
                      isLoadingKnowledgeBaseCategories={
                        this.props.isFetchingKnowledgeBaseCategories
                      }
                      isLoadingKnowledgeBaseMostPopularArticles={
                        this.props.isFetchingKnowledgeBaseMostPopularArticles
                      }
                      knowledgeBaseMostPopularArticles={
                        this.props.knowledgeBaseMostPopularArticles
                      }
                      knowledgeBasePath={this.knowledgeBasePath}
                      articleLink={this.knowledgeArticlePath}
                      searchQuery={this.state.searchQuery}
                      isFetchingKnowledgeBaseArticleByKeyword={
                        this.props.isFetchingKnowledgeBaseArticleByKeyword
                      }
                      searchedArticles={this.props.searchedArticles}
                      knowledgeArticlePath={this.knowledgeArticlePath}
                      onChangeKeywordSearch={this.onChangeKeywordSearch}
                    />
                  </div>
                </div>
              </TabletView>
              <BrowserView>
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-6">
                    <TicketsList
                      onOpenNewTicket={this.onOpenNewTicket}
                      onSearch={this.onChangeTicketKeywordSearch}
                    >
                      <TicketsListWithLoading
                        isLoading={this.props.isFetchingTickets}
                      >
                        {this.props.tickets.items.length > 0 ? (
                          this.props.tickets.items.map(ticket => (
                            <Ticket
                              key={`ticket-${ticket.id}`}
                              ticket={ticket}
                              closed={ticket.closed}
                              url={this.viewTicketPath(ticket)}
                              onOpenTicket={this.onOpenTicket}
                              onCloseTicket={this.onConfirmCloseTicket}
                              closingTicketId={this.state.closingTicketId}
                            />
                          ))
                        ) : (
                          <NoTicketsFound>
                            {translate("help-desk.no-tickets-found")}...
                          </NoTicketsFound>
                        )}
                      </TicketsListWithLoading>
                    </TicketsList>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6">
                    <KnowledgeBasePanel
                      knowledgeBaseCategories={
                        this.props.knowledgeBaseCategories
                      }
                      isLoadingKnowledgeBaseCategories={
                        this.props.isFetchingKnowledgeBaseCategories
                      }
                      isLoadingKnowledgeBaseMostPopularArticles={
                        this.props.isFetchingKnowledgeBaseMostPopularArticles
                      }
                      knowledgeBaseMostPopularArticles={
                        this.props.knowledgeBaseMostPopularArticles
                      }
                      knowledgeBasePath={this.knowledgeBasePath}
                      articleLink={this.knowledgeArticlePath}
                      searchQuery={this.state.searchQuery}
                      isFetchingKnowledgeBaseArticleByKeyword={
                        this.props.isFetchingKnowledgeBaseArticleByKeyword
                      }
                      searchedArticles={this.props.searchedArticles}
                      knowledgeArticlePath={this.knowledgeArticlePath}
                      onChangeKeywordSearch={this.onChangeKeywordSearch}
                    />
                  </div>
                </div>
              </BrowserView>
            </div>

            {this.state.showConfirmCloseTicketModal && (
              <ActionModal
                title={translate("help-desk.ticket.close-ticket")}
                body={() => (
                  <div>
                    {translate("help-desk.ticket.confirm-close-ticket")}
                  </div>
                )}
                onCloseActionModal={this.onHideConfirmCloseTicket}
                footer={() => (
                  <React.Fragment>
                    <SecondaryButton
                      onClick={() => this.onHideConfirmCloseTicket()}
                    >
                      {translate("general.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={this.state.closingTicket}
                      submitting={this.state.closingTicket}
                      onClick={() =>
                        this.onCloseTicket(this.state.selectedTicket)
                      }
                    >
                      {translate("help-desk.ticket.close-ticket")}
                    </PrimaryButton>
                  </React.Fragment>
                )}
              />
            )}
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetchingKnowledgeBaseCategories: isFetchingKnowledgeBaseCategories(state),
    knowledgeBaseCategories: getKnowledgeBaseCategories(state),
    isFetchingKnowledgeBaseMostPopularArticles: isFetchingKnowledgeBaseMostPopularArticles(
      state
    ),
    knowledgeBaseMostPopularArticles: getKnowledgeBaseMostPopularArticles(
      state
    ),
    searchedArticles: getKnowledgeBaseSearchedArticles(state),
    isFetchingKnowledgeBaseArticleByKeyword: isFetchingKnowledgeBaseArticleByKeyword(
      state
    ),
    tickets: getTickets(state),
    isFetchingTickets: isFetchingTickets(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchHelpDeskKnowledgeBaseCategories,
      fetchHelpDeskKnowledgeBaseMostPopularArticles,
      searchKnowledgeBaseArticles,
      fetchTickets,
      viewTicket,
      closeTicket
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(HelpDesk));
