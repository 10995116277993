import styled from "styled-components";
import { theme } from "../../../design/Themes";

import StatusText from "../StatusText";

const StatusTextInfo = styled(StatusText)`
  color: ${theme.blueBase};
`;

export default StatusTextInfo;
