import axios from "axios";
import {
  DOMAIN_AVAILABILITY_ENDPOINT,
  DOMAINS_LISTING_API_ENDPOINT,
  DOMAINS_LISTING_BY_GROUP_API_ENDPOINT,
  DOMAINS_WHOIS_API_ENDPOINT,
  DOMAINS_SUGGESTIONS_ENDPOINT
} from "./endpoints";

let domainSearchRequests = [];

export function isDomainAvailable(domain, cancelRequestToken = null) {
  return axios.get(`${DOMAIN_AVAILABILITY_ENDPOINT}/${domain}`, {
    cancelToken: cancelRequestToken
  });
}

export function fetchDomainsPrices() {
  return axios.get(`${DOMAINS_LISTING_API_ENDPOINT}`);
}

export function fetchGroupedTopLevelDomains() {
  return axios.get(`${DOMAINS_LISTING_BY_GROUP_API_ENDPOINT}`);
}

export function domainWhoIs(domain) {
  return axios.get(`${DOMAINS_WHOIS_API_ENDPOINT}/${domain}`);
}

export function domainSuggestionsByGroup(
  domain,
  group,
  cancelRequestToken = null
) {
  return axios.get(`${DOMAINS_SUGGESTIONS_ENDPOINT}/${domain}/${group}`, {
    cancelToken: cancelRequestToken
  });
}
