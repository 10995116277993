import * as productsService from "../../../services/productsService.js";

export const PRODUCTS_FEATURE = "features/Products";
export const PRODUCTS_LISTING_REQUEST = `${PRODUCTS_FEATURE}/PRODUCTS_REQUEST`;
export const PRODUCTS_LISTING_RECEIVE = `${PRODUCTS_FEATURE}/PRODUCTS_RECEIVE`;
export const PRODUCTS_LISTING_BY_GROUP_REQUEST = `${PRODUCTS_FEATURE}/PRODUCTS_LISTING_BY_GROUP_REQUEST`;
export const PRODUCTS_LISTING_BY_GROUP_RECEIVE = `${PRODUCTS_FEATURE}/PRODUCTS_LISTING_BY_GROUP_RECEIVE`;

export function fetchProducts() {
  return dispatch => {
    dispatch(requestProductsListing());
    return productsService
      .all()
      .then(
        result => {
          const { products } = result.data.data;
          dispatch(receiveProductsListing(products));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}
export function fetchProductsByGroup() {
  return dispatch => {
    dispatch(requestProductsListingByGroup());
    return productsService
      .allByGroup()
      .then(
        result => {
          const { hostings_grouped } = result.data.data;
          dispatch(receiveProductsListingByGroup(hostings_grouped));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchProductUpgrades(product) {
  return dispatch => {
    return productsService.fetchProductUpgrades(product).then(result => {
      return result;
    });
  };
}

export function upgradeProduct(currentProduct, upgradeProduct) {
  return dispatch => {
    return productsService
      .upgradeProduct(currentProduct, upgradeProduct)
      .then(result => {
        const { error } = result.data;
        const { messages, invoice } = result.data.data;
        return { error, messages, invoice };
      })
      .catch(err => {
        const { error } = err.response.data;
        const { messages } = err.response.data.data;
        return { error, messages };
      });
  };
}

export function requestRenewProduct(product) {
  return dispatch => {
    return productsService
      .requestRenewProduct(product)
      .then(
        result => {
          const { error } = result.data;
          const { messages, invoice } = result.data.data;

          return { error, messages, invoice };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function cancelProduct(product, period) {
  return dispatch => {
    return productsService.cancelProduct(product, period).then(result => {
      if (result.data.data.product) {
        return true;
      }
      return false;
    });
  };
}

function requestProductsListing() {
  return {
    type: PRODUCTS_LISTING_REQUEST
  };
}

function receiveProductsListing(products) {
  return {
    type: PRODUCTS_LISTING_RECEIVE,
    payload: products
  };
}

function requestProductsListingByGroup() {
  return {
    type: PRODUCTS_LISTING_BY_GROUP_REQUEST
  };
}

function receiveProductsListingByGroup(products) {
  return {
    type: PRODUCTS_LISTING_BY_GROUP_RECEIVE,
    payload: products
  };
}
