import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";

import {
  validateDnsRecordHostname,
  validateDnsRecordTTL,
  validateDnsRecordHostnameValue
} from "../../../../../../common/validationRules";

import RecordType from "../Fields/RecordType";
import RecordTypeSelect from "../Fields/RecordTypeSelect";
import Hostname from "../Fields/Hostname";
import TTL from "../Fields/TTL";
import HostnameValue from "../Fields/HostnameValue";

import ActionWarningButton from "../../../../../../components/Buttons/ActionWarningButton";
import Icon from "../../../../../../components/Icon";
import SavingRecordLoader from "../Fields/SavingRecordLoader";

const StyledActionsWrapper = styled.div`
  display: inline-block;
  float: right;
  margin-right: auto !important;
  padding-top: 22px;
  & button {
    vertical-align: top;
  }
`;

const validate = values => {
  return {
    name: validateDnsRecordHostname(values.name),
    ttl: validateDnsRecordTTL(values.ttl),
    hostname: validateDnsRecordHostnameValue(values.hostname)
  };
};

class NSRecord extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <Form
            onSubmit={this.props.onSaveRecord}
            validate={validate}
            initialValues={this.props.record ? { ...this.props.record } : {}}
            render={({ handleSubmit, submitting, invalid, values, form }) => (
              <form
                id={
                  this.props.record &&
                  `dns-record-form-${this.props.record.line}`
                }
                onSubmit={e => {
                  form.blur("name");
                  form.blur("ttl");
                  form.blur("hostname");
                  handleSubmit(e);
                }}
              >
                <Field
                  component={this.props.record ? RecordType : RecordTypeSelect}
                  name="type"
                  onChange={this.props.onUpdateRecordType}
                  domain={this.props.domain}
                  record={this.props.record}
                  formValues={values}
                />
                <Field
                  component={Hostname}
                  name="name"
                  disabled={submitting}
                  onBlur={e =>
                    this.props.onUpdateRecordField(
                      this.props.domain,
                      this.props.record,
                      e.target.name,
                      e.target.value
                    )
                  }
                />
                <Field
                  component={TTL}
                  name="ttl"
                  disabled={submitting}
                  onBlur={e =>
                    this.props.onUpdateRecordField(
                      this.props.domain,
                      this.props.record,
                      e.target.name,
                      e.target.value
                    )
                  }
                />
                <Field
                  component={HostnameValue}
                  name="hostname"
                  disabled={submitting}
                  onBlur={e =>
                    this.props.onUpdateRecordField(
                      this.props.domain,
                      this.props.record,
                      e.target.name,
                      e.target.value
                    )
                  }
                />
                <StyledActionsWrapper>
                  {submitting && <SavingRecordLoader />}
                  <ActionWarningButton
                    type="button"
                    onClick={e => {
                      e.preventDefault();
                      this.props.onDeleteRecord(this.props.record);
                    }}
                    disabled={submitting || this.props.deletingRecord}
                  >
                    <Icon icon="delete" />
                  </ActionWarningButton>
                </StyledActionsWrapper>
              </form>
            )}
          />
        </div>
      </div>
    );
  }
}

export default NSRecord;
