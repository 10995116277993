import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";

import ProductPlan from "./ProductPlan";

const StyledUpgradeDowngradeHosting = styled.div``;

class UpgradeDowngradeHosting extends React.Component {
  render() {
    const {
      productUpgrades,
      currentPlan,
      onUpgradeProduct,
      upgradingProduct
    } = this.props;

    return (
      <StyledUpgradeDowngradeHosting>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              {productUpgrades &&
                productUpgrades.length > 0 &&
                productUpgrades.map(product => {
                  let colSize;
                  if( productUpgrades.length > 4){
                    colSize = 4
                  }else {
                    colSize =
                        Math.ceil(12 /
                            (productUpgrades.length > 1 ? productUpgrades.length : 3));
                  }

                  if (
                    product.pricing.buy.billing_cycle.derivations.length === 0
                  )
                    return null;

                  return (
                    <div
                      key={`product-plan-${product.id}`}
                      className={`col-12 col-sm-12 col-lg-12 col-md-12 col-xl-${colSize} `}
                    >
                      <ProductPlan
                        hosting={product}
                        currentProduct={currentPlan}
                        onUpgradeProduct={onUpgradeProduct}
                        upgradingProduct={upgradingProduct}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </StyledUpgradeDowngradeHosting>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  null,
  mapDispatchToProps
)(UpgradeDowngradeHosting);
