import * as addressingService from "../../../services/addressingService.js";

export const ADDRESSING_FEATURE = "features/Addressing";
export const ADDRESSING_REQUEST = `${ADDRESSING_FEATURE}/ADDRESSING_REQUEST`;
export const ADDRESSING_RECEIVE = `${ADDRESSING_FEATURE}/ADDRESSING_RECEIVE`;

export function fetchCountries() {
  return dispatch => {
    dispatch(requestAddressing());
    return addressingService
      .getCountries()
      .then(
        result => {
          const { countries } = result.data.data;
          dispatch(receiveAddressing(countries));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

function requestAddressing() {
  return {
    type: ADDRESSING_REQUEST
  };
}

function receiveAddressing(addressing) {
  return {
    type: ADDRESSING_RECEIVE,
    payload: addressing
  };
}
