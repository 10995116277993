import axios from "axios";

import { CREDIT_API_ENDPOINT, CREDIT_PURCHASE_API_ENDPOINT } from "./endpoints";

export function getAvailableCredit() {
  return axios.get(CREDIT_API_ENDPOINT);
}

export function purchaseCredit(amount) {
  return axios.post(CREDIT_PURCHASE_API_ENDPOINT, {
    amount: amount
  });
}
