import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import {
  isMobileOnly,
  isTablet,
  BrowserView,
  isBrowser
} from "react-device-detect";
import isLaptop from "../../../../utils/isLaptopScreen";
import withSizes from "react-sizes";

import Container from "../../../../components/Containers/Container";
import Form from "../../../../components/Forms/Form";
import SearchField from "../../../../components/SearchField";

import Table from "../../../../components/Table";
import TableHeader from "../../../../components/Table/TableHeader";
import TableHeading from "../../../../components/Table/TableHeading";
import TableBody from "../../../../components/Table/TableBody";
import TableRow from "../../../../components/Table/TableRow";
import TableCell from "../../../../components/Table/TableCell";

import Pagination from "../../../../components/Pagination";

import withLoading from "../../../../components/Loaders/WithLoading";
import TableContentLoader from "../../../../components/Loaders/TableContentLoader";

const StyledEmailsListWrapper = styled.div`
  margin-bottom: ${props => (props.isMobile ? "30px" : "50px")};
`;

const StyledEmailsList = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;

  & .email-list-header {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
  }
`;

const StyledEmailsListContainer = styled.div`
  & a.email-type {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }
  & .email-type-icon {
    color: ${theme.neutral4};
  }

  & .email-date {
    display: block;
  }
`;

const StyledEmailsTableWithLoading = withLoading(
  StyledEmailsListContainer,
  TableContentLoader
);

const EmailsList = ({
  handlePaginationClick,
  onPerPageOptionChanged,
  changeListOrderTrigger,
  onChangeKeywordSearch,
  searchQuery,
  emails,
  isFetchingEmails,
  emailRoute
}) => (
  <Translate>
    {({ translate }) => (
      <StyledEmailsListWrapper>
        <Container>
          <StyledEmailsList>
            <div className="email-list-header">
              {(isMobileOnly || isTablet) && (
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <Form
                      onSubmit={e => {
                        e.preventDefault();
                      }}
                    >
                      <SearchField
                        name="search"
                        placeholder={translate(
                          "my-account.emails-and-notifications.search-placeholder"
                        )}
                        type="text"
                        onChange={onChangeKeywordSearch}
                        value={searchQuery}
                      />
                    </Form>
                  </div>
                </div>
              )}
              <BrowserView>
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-6">
                    <Form
                      onSubmit={e => {
                        e.preventDefault();
                      }}
                    >
                      <SearchField
                        name="search"
                        placeholder={translate(
                          "my-account.emails-and-notifications.search-placeholder"
                        )}
                        type="text"
                        onChange={onChangeKeywordSearch}
                        value={searchQuery}
                      />
                    </Form>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 text-right" />
                </div>
              </BrowserView>
            </div>
            <StyledEmailsListContainer className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <StyledEmailsTableWithLoading
                  className="table-responsive"
                  isLoading={isFetchingEmails}
                >
                  <Table>
                    <TableHeader>
                      <TableRow>
                        {isBrowser && (
                          <TableHeading
                            isSortable={true}
                            sortBy="date"
                            isDefaultSort={emails.sort.sort_by === "date"}
                            sortDirection={emails.sort.order_by}
                            changeListOrderTrigger={changeListOrderTrigger}
                          >
                            {translate(
                              "my-account.emails-and-notifications.date-sent"
                            )}
                          </TableHeading>
                        )}
                        <TableHeading
                          isSortable={true}
                          sortBy="subject"
                          isDefaultSort={emails.sort.sort_by === "subjecte"}
                          sortDirection={emails.sort.order_by}
                          changeListOrderTrigger={changeListOrderTrigger}
                        >
                          {translate(
                            "my-account.emails-and-notifications.subject"
                          )}
                        </TableHeading>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {emails.items.length === 0 && (
                        <TableRow>
                          <TableCell colSpan="2">
                            {translate(
                              "my-account.emails-and-notifications.no-emails"
                            )}
                            ...
                          </TableCell>
                        </TableRow>
                      )}
                      {emails.items.length > 0 &&
                        emails.items.map(email => {
                          return (
                            <TableRow key={email.id}>
                              {isBrowser && <TableCell>{email.date}</TableCell>}
                              <TableCell>
                                <Link
                                  className="email-type"
                                  to={emailRoute(email)}
                                >
                                  {email.subject}
                                </Link>{" "}
                                {(isMobileOnly || isTablet) && (
                                  <span className="email-date">
                                    {email.date}
                                  </span>
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </StyledEmailsTableWithLoading>
              </div>
            </StyledEmailsListContainer>
            <Pagination
              itemsName={translate(
                "my-account.emails-and-notifications.emails"
              )}
              paging={emails.paging}
              handlePaginationClick={handlePaginationClick}
              onPerPageOptionChanged={onPerPageOptionChanged}
            />
          </StyledEmailsList>
        </Container>
      </StyledEmailsListWrapper>
    )}
  </Translate>
);

export default withSizes(isLaptop)(EmailsList);
