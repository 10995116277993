import * as DA from "../actions/domainsActions";

import handleReducerActions from "../../../core/handleReducerActions";

import { APP_STATE_COMPLETE_RESET } from "../../AppState/actions/appStateActions";

const initialState = {
  tld: null,
  domainsForWidget: [],
  isFetchingDomainsForWidget: false,
  isFetchingTopLevelGroupedDomains: false,
  tldGrouped: {},
  tldGroups: [],
  isFetchingDomains: false
};

const domainsStateHandlers = {
  [DA.DOMAINS_REQUEST]: domainsRequest,
  [DA.DOMAINS_RECEIVE]: domainsReceive,
  [DA.DOMAINS_FOR_WIDGET_REQUEST]: domainsForWidgetRequest,
  [DA.DOMAINS_FOR_WIDGET_RECEIVE]: domainsForWidgetReceive,
  [DA.TOP_LEVEL_DOMAINS_GROUPED_REQUEST]: topLevelDomainsGroupedRequest,
  [DA.TOP_LEVEL_DOMAINS_GROUPED_RECEIVE]: topLevelDomainsGroupedReceive,
  [APP_STATE_COMPLETE_RESET]: resetState
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(domainsStateHandlers, state, action);
}

function domainsRequest(state, action) {
  return { ...state, isFetchingDomains: true };
}

function domainsReceive(state, action) {
  return {
    ...state,
    tld: { ...action.payload },
    isFetchingDomains: false
  };
}

function domainsForWidgetRequest(state, action) {
  return {
    ...state,
    isFetchingDomainsForWidget: true
  };
}

function domainsForWidgetReceive(state, action) {
  return {
    ...state,
    isFetchingDomainsForWidget: false,
    domainsForWidget: action.payload
  };
}

function topLevelDomainsGroupedRequest(state, action) {
  return {
    ...state,
    isFetchingTopLevelGroupedDomains: true
  };
}

function topLevelDomainsGroupedReceive(state, action) {
  return {
    ...state,
    isFetchingTopLevelGroupedDomains: false,
    tldGrouped: action.payload,
    tldGroups: Object.keys(action.payload)
      .map(i => {
        if (i === "macedonian") return { name: i, priority: 2 };
        if (i === "international") return { name: i, priority: 1 };
        return { name: i, priority: 0 };
      })
      .sort((a, b) => (a.priority > b.priority ? -1 : 1))
      .map(i => i.name)
  };
}

function resetState(state) {
  return { ...initialState };
}

// this is a selector
export function getDomainsPricing(state) {
  return state.catalog.domains.tld;
}

export function getDomainsForWidget(state) {
  return state.catalog.domains.domainsForWidget;
}

export function isFetchingDomainsForWidget(state) {
  return state.catalog.domains.isFetchingDomainsForWidget;
}

export function isFetchingTopLevelGroupedDomains(state) {
  return state.catalog.domains.isFetchingTopLevelGroupedDomains;
}

export function getTopLevelDomainsByGroup(state) {
  return state.catalog.domains.tldGrouped;
}

export function getTopLevelDomainGroups(state) {
  return state.catalog.domains.tldGroups;
}

export function getAvailableExtensions(state) {
  let extensions = [];

  Object.keys(state.catalog.domains.tldGrouped).forEach((item, i) => {
    Object.keys(state.catalog.domains.tldGrouped[item]).forEach(
      domainExtension => extensions.push("." + domainExtension)
    );
  });

  return extensions;
}

export function isFetchingDomains(state) {
  return state.catalog.domains.isFetchingDomains;
}
