import axios from "axios";
import { sprintf } from "sprintf-js";

import {
  DEDICATED_SERVERS_LISTING_API_ENDPOINT,
  CLOUD_SERVERS_LISTING_API_ENDPOINT,
  SERVERS_SERVER_CANCEL,
  SERVERS_SERVER_REQUEST_RENEWAL,
  SERVERS_UPGRADES_LISTING,
  SERVERS_UPGRADE_SERVER
} from "./endpoints";

export function allDedicatedServers() {
  return axios.get(DEDICATED_SERVERS_LISTING_API_ENDPOINT);
}

export function allCloudServers() {
  return axios.get(CLOUD_SERVERS_LISTING_API_ENDPOINT);
}

export function fetchServerUpgrades(server) {
  return axios.get(sprintf(SERVERS_UPGRADES_LISTING, server));
}

export function upgradeServer(currentServer, upgradeServer) {
  return axios.post(SERVERS_UPGRADE_SERVER, {
    server_id: currentServer.id,
    catalog_id: upgradeServer.id,
    billing_cycle: currentServer.pricing.buy.billing_cycle.derivations[0].code
  });
}

export function requestRenewServer(server) {
  return axios.put(sprintf(SERVERS_SERVER_REQUEST_RENEWAL, server));
}

export function cancelServer(server, period) {
  return axios.put(sprintf(SERVERS_SERVER_CANCEL, server), {
    type: period
  });
}
