import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { isMobileOnly } from "react-device-detect";

import DomainContact from "./DomainContact";

import {
  CONTACT_TYPE_ADMINISTRATIVE,
  CONTACT_TYPE_REGISTRANT,
  CONTACT_TYPE_TECHNICAL
} from "../../../Domains/ViewDomain/DomainContacts/DomainContactTypes";

import { Translate } from "react-localize-redux";

const StyledDomainContactsWrapper = styled.div`
  margin-top: 30px;
`;

const StyledDomainContactWrapper = styled.div`
  margin-bottom: 30px;
`;

const StyledChangeRegistrant = styled.span`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: ${theme.blueBase};
  cursor: pointer;
`;

const DomainContacts = ({ title, contacts, product, onChangeContact }) => {
  const registrant =
    contacts &&
    contacts.find(contact => contact.type === CONTACT_TYPE_REGISTRANT);
  const administrative =
    contacts &&
    contacts.find(contact => contact.type === CONTACT_TYPE_ADMINISTRATIVE);
  const technical =
    contacts &&
    contacts.find(contact => contact.type === CONTACT_TYPE_TECHNICAL);

  return (
    <Translate>
      {({ translate }) => (
        <StyledDomainContactsWrapper className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <div className="row">
              <StyledDomainContactWrapper
                className={
                  isMobileOnly
                    ? "col-12 col-sm-12 col-md-12"
                    : "col-4 col-sm-4 col-md-4"
                }
              >
                <DomainContact
                  title={translate("domain.domain-contacts.domain-registrant")}
                  contact={registrant}
                  product={product}
                  tooltip={translate(
                    "domain.domain-contacts.domain-registrant-tooltip"
                  )}
                />
                {product.isMkDomain && (
                  <StyledChangeRegistrant
                    onClick={() =>
                      onChangeContact(
                        product,
                        registrant,
                        CONTACT_TYPE_REGISTRANT
                      )
                    }
                  >
                    {translate("domain.domain-contacts.change-registrant")}
                  </StyledChangeRegistrant>
                )}
              </StyledDomainContactWrapper>
              <StyledDomainContactWrapper
                className={
                  isMobileOnly
                    ? "col-12 col-sm-12 col-md-12"
                    : "col-4 col-sm-4 col-md-4"
                }
              >
                <DomainContact
                  title={translate(
                    "domain.domain-contacts.administrative-contact"
                  )}
                  contact={administrative}
                  product={product}
                  tooltip={translate(
                    "domain.domain-contacts.administrative-contact-tooltip"
                  )}
                />
                {product.isMkDomain && (
                  <StyledChangeRegistrant
                    onClick={() =>
                      onChangeContact(
                        product,
                        administrative,
                        CONTACT_TYPE_ADMINISTRATIVE
                      )
                    }
                  >
                    {translate("domain.domain-contacts.use-different-contact")}
                  </StyledChangeRegistrant>
                )}
              </StyledDomainContactWrapper>
              <StyledDomainContactWrapper
                className={
                  isMobileOnly
                    ? "col-12 col-sm-12 col-md-12"
                    : "col-4 col-sm-4 col-md-4"
                }
              >
                <DomainContact
                  title={translate("domain.domain-contacts.technical-contact")}
                  contact={technical}
                  product={product}
                  tooltip={translate(
                    "domain.domain-contacts.technical-contact-tooltip"
                  )}
                />
                {product.isMkDomain && (
                  <StyledChangeRegistrant
                    onClick={() =>
                      onChangeContact(
                        product,
                        technical,
                        CONTACT_TYPE_TECHNICAL
                      )
                    }
                  >
                    {translate("domain.domain-contacts.use-different-contact")}
                  </StyledChangeRegistrant>
                )}
              </StyledDomainContactWrapper>
            </div>
          </div>
        </StyledDomainContactsWrapper>
      )}
    </Translate>
  );
};

export default DomainContacts;
