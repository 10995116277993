import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { isMobileOnly } from "react-device-detect";
import { Translate } from "react-localize-redux";

import { PROINVOICE_TYPE_CREDIT_PURCHASE } from "../../features/Billing/ProInvoices/ProInvoiceTypes/ProInvoiceTypes";

const StyledTable = styled.table`
  color: ${theme.neutralBase};
`;
const StyledTHead = styled.thead`
  background-color: ${theme.neutral1};
`;
const StyledTBody = styled.tbody``;
const StyledTR = styled.tr`
  border-bottom: 1px solid ${theme.neutral3};
  &:last-child {
    border-bottom: 0;
  }
`;
const StyledTH = styled.th`
  font-size: 14px;
  letter-spacing: 0.2px;
  color: ${theme.neutralBase};
  text-transform: uppercase;
`;
const StyledTD = styled.td`
  font-size: 16px;
  & strong {
    font-weight: 600;
  }

  & span {
    display: block;
  }
`;

const specificTranslations = {
  [PROINVOICE_TYPE_CREDIT_PURCHASE]: PROINVOICE_TYPE_CREDIT_PURCHASE
};
const hasSpecificTranslations = key => specificTranslations[key] || false;

const formatDescription = description => {
  const [name, itemDetails] = description.split(/-(.+)/);

  return (
    <div>
      {name && itemDetails ? (
        <div>
          <div>{name}</div>
          <div>{itemDetails}</div>
        </div>
      ) : (
        description
      )}
    </div>
  );
};

const ItemsTable = ({ items }) => {
  return (
    <Translate>
      {({ translate }) => (
        <div className="table-responsive-sm">
          <StyledTable className="table table-borderless">
            <StyledTHead>
              <StyledTR>
                <StyledTH>{translate("pro-invoices.view.item")}</StyledTH>
                {!isMobileOnly && (
                  <StyledTH>
                    {translate("pro-invoices.view.description")}
                  </StyledTH>
                )}
                <StyledTH className="text-right">
                  {translate("pro-invoices.view.amount")}
                </StyledTH>
              </StyledTR>
            </StyledTHead>
            <StyledTBody>
              {items &&
                items.map(item => (
                  <StyledTR key={`pro-invoice-item-${item.id}`}>
                    <StyledTD>
                      <span>
                        {hasSpecificTranslations(item.description)
                          ? translate("pro-invoices.view.invoice-notes.credit")
                          : item.type}
                      </span>
                      {isMobileOnly && (
                        <span>
                          {hasSpecificTranslations(item.description)
                            ? translate(
                                `pro-invoices.view.invoice-notes.${
                                  item.description
                                }`
                              )
                            : formatDescription(item.description)}
                        </span>
                      )}
                    </StyledTD>
                    {!isMobileOnly && (
                      <StyledTD>
                        {hasSpecificTranslations(item.description)
                          ? translate(
                              `pro-invoices.view.invoice-notes.${
                                item.description
                              }`
                            )
                          : formatDescription(item.description)}
                      </StyledTD>
                    )}
                    <StyledTD className="text-right">{item.amount}</StyledTD>
                  </StyledTR>
                ))}
            </StyledTBody>
          </StyledTable>
        </div>
      )}
    </Translate>
  );
};

export default ItemsTable;
