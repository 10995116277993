import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { isMobileOnly } from "react-device-detect";
import qs from "query-string";
import { Translate, withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import { sprintf } from "sprintf-js";
import { INVOICES_PREVIEW_INVOICE_API_ENDPOINT } from "../../../../services/endpoints";

import {
  REASON_COPY,
  REASON_NOT_ARRIVED,
  REASON_WRONG_ADDRESS
} from "./SendViaPostDialog/SendViaPostOptions.js";

import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validateAddress,
  validatePhoneNumber,
  validateMkPhoneNumber,
  validateCompany,
  validateTaxNumber,
  validateCountry,
  validateCity,
  validateZipCode
} from "../../../../common/validationRules";

import { fetchCountries } from "../../../Addressing/actions/addressingActions";
import {
  getCountriesWithISOFormatted,
  isFetchingCountries
} from "../../../Addressing/reducers/addressingReducer";

import Breadcrumb from "../../../../components/Breadcrumb";
import BreadcrumbItem from "../../../../components/Breadcrumb/BreadcrumbItem";
import Container from "../../../../components/Containers/Container";
import AdaptableButtonGroup from "../../../../components/AdaptableButtonGroup";

import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import GhostButton from "../../../../components/Buttons/GhostButton";
import CustomTooltip from "../../../../components/CustomTooltip";
import Icon from "../../../../components/Icon";

import Modal from "../../../../components/Modal";

import ContentLoader from "../../../../components/Loaders/ContentLoader";

import InvoicePaymentDetails from "./InvoicePaymentDetails";
import ItemsTable from "../../../../components/ItemsTable";
import TransactionsTable from "../../../../components/TransactionsTable";
import InvoiceTotal from "../../../../components/InvoiceTotal";
import Payment from "../../Payment";
import InvoiceTo from "./InvoiceTo";
import PayTo from "./PayTo";
import ForwardDialog from "../../../../components/ForwardDialog";
import SendViaPostDialog from "./SendViaPostDialog";

import PayerDetailsForm from "./PayerDetailsForm";
import PayerDetailsFormWithSearch from "./PayerDetailsFormWithSearch";

import {
  fetchInvoice,
  downloadInvoice,
  previewInvoice,
  applyCreditToInvoice,
  forwardInvoice,
  sendViaPost,
  payInvoice,
  updateInvoicePayment,
  assignInvoicePayer
} from "../actions/invoicesActions";

import { fetchPaymentMethods } from "../../../PaymentMethods/actions/paymentMethodsActions";
import { getPaymentMethods } from "../../../PaymentMethods/reducers/paymentMethodsReducer";

import { searchPayers } from "../../../Customer/actions/customerActions";

import {
  fetchCredit,
  reduceCredit
} from "../../../Credit/actions/creditActions";
import {
  getAvailableCredit,
  isFetchingCredit
} from "../../../Credit/reducers/creditReducer";

import { validateMaxAmount } from "../../../../common/validationRules";

import { downloadFile } from "../../../../utils/downloadFile";

import { PAYMENT_METHOD_BANK_TRANSFER } from "../../../PaymentMethods/PaymentMethodsModules";

import { PAYMENT_STATUS_UNPAID } from "../../../PaymentMethods/PaymentStatus";

import {
  PAYER_DETAILS_TYPE_COMPANY,
  PAYER_DETAILS_TYPE_INDIVIDUAL
} from "./PayerDetailsForm/PayerDetailsTypes";

import { INVOICE_TYPE_CREDIT_PURCHASE } from "../InvoiceTypes/InvoiceTypes";

import {
  ROUTE_INDEX,
  ROUTE_BILLING,
  ROUTE_BILLING_INVOICES
} from "../../../../routes/routes";

import displayToastMessageForResponse from "../../../../utils/displayToastMessageForResponse";

const StyledViewInvoiceDetails = styled.div`
  padding: ${props => (props.isMobile ? "15px" : "30px")};
`;

const StyledViewInvoiceWrapper = styled.div`
  margin-bottom: 30px;

  & {
    @media print {
      width: 100% !important;
      max-width: 100% !important;
      height: 100% !important;
      flex: 0 0 100%;
    }
  }
`;

const StyledInvoiceName = styled.h3`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  margin-bottom: 0;

  @media (max-width: 1440px) {
    font-size: 23px;
  }
`;

const StyledInvoiceItemsWrapper = styled.div``;
const StyledInvoiceToWrapper = styled.div``;

const StyledPayNowCallToAction = styled(PrimaryButton)`
  width: ${props => props.isMobile && "100%"};
  margin-top: ${props => props.isMobile && "15px"};
  margin-bottom: ${props => props.isMobile && "15px"};
  margin-right: 0;
`;

const StyleInvoicePreviewWrapper = styled.div`
  margin: -30px 0;
  margin-right: -30px;
  padding: 30px;
  background-color: ${theme.white};
  height: 100%;
  overflow: hidden;
  padding-bottom: 100px;
`;

const StyledInvoiceActions = styled.div`
  margin-bottom: 30px;
`;

const StyledInvoiceEmbed = styled.embed``;

const StyledSendPopupTitle = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 600;
  color: ${theme.neutralBase};
`;

const StyledSendPopupDescription = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
`;

const StyledDigitalInvoiceIcon = styled(Icon)`
  margin-right: 15px;
  color: ${theme.greenBase};
`;

class ViewInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoice: null,
      downloading: false,
      showForwardModal: false,
      submittingForwardInvoice: false,
      submittingInvoicePayment: false,
      showUpdatePayerModal: false,
      selectedPayer: null,
      invalidPayer: false,
      showCreatePayerModal: false,
      showChangePayerModal: false,
      invoicePreview: null,
      showSendViaPostModal: false,
      submittingSendViaPost: false,
      sendViaPostReason: REASON_COPY
    };
  }

  componentDidMount() {
    this.doFetchInvoice(this.props.match.params.id);

    if (!this.props.countries && !this.props.isFetchingCountries) {
      this.props.fetchCountries();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.doFetchInvoice(this.props.match.params.id);
    }
  }

  doFetchInvoice = id => {
    this.setState({ invoice: null });
    this.props.fetchInvoice(id).then(
      result => {
        if (result && result.found) {
          this.setState({
            invoice: {
              ...result.invoice,
              payer: {
                ...result.invoice.payer,
                country: this.props.countries.find(
                  c => c.value === result.invoice.payer.country
                )
              }
            }
          });
          this.props.previewInvoice(id).then(result => {
            this.setState({
              previewInvoice: URL.createObjectURL(
                new Blob([result], { type: "application/pdf" })
              )
            });
          });
        } else {
          this.props.history.push(ROUTE_BILLING_INVOICES);
        }
      },
      error => {
        this.props.history.push(ROUTE_BILLING_INVOICES);
      }
    );
  };

  onDownload = id => {
    this.setState({
      downloading: true
    });
    this.props.downloadInvoice(id).then(response => {
      this.setState({
        downloading: false
      });

      if (response.error) {
        displayToastMessageForResponse(
          this.props.translate("invoices.invoice"),
          {
            error: true,
            messages: [this.props.translate("invoices.download-invoice-error")]
          }
        );

        return;
      }

      downloadFile(
        response.file.data,
        `MKHOST-INVOICE-${id}.pdf`,
        "application/pdf"
      );
    });
  };

  onPrint = () => {
    var iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = this.state.previewInvoice;
    iframe.onload = function() {
      {
        setTimeout(function() {
          iframe.focus();
          iframe.contentWindow.print();
        }, 50);
      }
    };
    document.body.append(iframe);
  };

  /**
   * Forwaring Invoice
   */

  onValidateForwardInvoice = values => {
    return {
      email: validateEmail(values.email)
    };
  };

  onSubmitForwardInvoice = () => {
    document
      .getElementById("forward-dialog-form")
      .dispatchEvent(new Event("submit"));
  };

  onSubmittingForwardInvoice = ({ submitting }) => {
    if (this.state.submittingForwardInvoice !== submitting) {
      this.setState({
        submittingForwardInvoice: submitting
      });
    }
  };

  onForwardInvoice = values => {
    return this.props
      .forwardInvoice(this.state.invoice.invoice, values.email, values.message)
      .then(response => {
        if (response.sent) {
          const data = {
            ...response,
            messages: [
              this.props.translate("invoices.view.invoice-sent-via-email")
            ]
          };
          displayToastMessageForResponse(
            this.props.translate("invoices.title"),
            data
          );
        } else {
          displayToastMessageForResponse(
            this.props.translate("invoices.title"),
            response
          );
        }

        this.onHideForwardInvoiceModal();
      });
  };

  onShowForwardInvoiceModal = () => {
    this.setState({
      showForwardModal: true
    });
  };

  onHideForwardInvoiceModal = () => {
    this.setState({
      showForwardModal: false
    });
  };

  validatePayer = values => {
    //if (Object.keys(values).length === 0) return {};
    //if (Object.keys(values).length === 1) return {}; // don't validate if contact_type is only field
    if (values.contact_type === PAYER_DETAILS_TYPE_COMPANY) {
      return {
        email: validateEmail(values.email),
        first_name: validateFirstName(values.first_name),
        last_name: validateLastName(values.last_name),
        phone: validateMkPhoneNumber(values.phone),
        country:
          values.country && values.country.id
            ? validateCountry(values.country.id)
            : undefined,
        address: validateAddress(values.address),
        city: validateCity(values.city),
        zip: validateZipCode(values.zip),
        tax_number: validateTaxNumber(values.tax_number),
        company: validateCompany(values.company)
      };
    }
    return {
      email: validateEmail(values.email),
      first_name: validateFirstName(values.first_name),
      last_name: validateLastName(values.last_name),
      phone: validateMkPhoneNumber(values.phone),
      country:
        values.country && values.country.id
          ? validateCountry(values.country.id)
          : undefined,
      address: validateAddress(values.address),
      city: validateCity(values.city),
      zip: validateZipCode(values.zip)
    };
  };
  onUpdatePayer = values => {
    return this.props
      .assignInvoicePayer(this.state.invoice, {
        ...values,
        country_id: values.country.value
          ? values.country.value
          : values.country.id,
        tax_number:
          values.contact_type === PAYER_DETAILS_TYPE_COMPANY
            ? values.tax_number
            : "",
        company:
          values.contact_type === PAYER_DETAILS_TYPE_COMPANY
            ? values.company
            : ""
      })
      .then(result => {
        const { payer } = result;
        this.setState({
          ...this.state,
          invoice: {
            ...this.state.invoice,
            invoice_to: {
              ...payer
            }
          }
        });
        this.onHideUpdatePayerModal();
        this.onHideChangePayerModal();
        this.onHideCreatePayerModal();

        displayToastMessageForResponse(
          this.props.translate("invoices.invoice"),
          result
        );
      });
  };

  onShowSendViaPost = () => {
    this.setState({
      showSendViaPostModal: true
    });
  };

  onHideSendViaPost = () => {
    this.setState({
      showSendViaPostModal: false
    });
  };

  onChangeViaPostReason = reason => {
    this.setState({
      sendViaPostReason: reason
    });
  };

  onEditPostAddress = () => {
    this.onChangeViaPostReason(REASON_WRONG_ADDRESS);
  };

  validatePostInfo = values => {
    return {
      email: validateEmail(values.email),
      firstname: validateFirstName(values.firstname),
      lastname: validateLastName(values.lastname),
      phonenumber: validateMkPhoneNumber(values.phonenumber, false),
      country:
        values.country && values.country.value
          ? validateCountry(values.country.value)
          : undefined,
      address: validateAddress(values.address),
      city: validateCity(values.city),
      zip: validateZipCode(values.zip)
    };
  };

  onSubmitSendViaPost = () => {
    if (this.state.sendViaPostReason === REASON_WRONG_ADDRESS) {
      document
        .getElementById("send-via-post-dialog-form")
        .dispatchEvent(new Event("submit"));
    } else {
      return this.onSendViaPost(this.state.invoice.payer);
    }
  };

  onSendViaPost = values => {
    this.setState({
      submittingSendViaPost: true
    });
    return this.props
      .sendViaPost(
        this.state.invoice.invoice,
        values,
        this.state.sendViaPostReason
      )
      .then(response => {
        if (response.posted) {
          const data = {
            ...response,
            messages: [
              this.props.translate("invoices.view.invoice-sent-via-post")
            ]
          };
          displayToastMessageForResponse(
            this.props.translate("invoices.title"),
            data
          );
          this.setState({
            invoice: {
              ...this.state.invoice,
              postInfo: [response.post_info],
              payer: {
                ...this.state.invoice.payer,
                ...values,
                country:
                  values.country && values.country.value
                    ? values.country.value
                    : values.country
              }
            }
          });
        } else {
          displayToastMessageForResponse(
            this.props.translate("invoices.title"),
            response
          );
        }
        this.setState({
          submittingSendViaPost: false
        });
        this.onHideSendViaPost();
      });
  };

  onSubmittingSendViaPost = ({ submitting }) => {
    if (this.state.submittingSendViaPost !== submitting) {
      this.setState({
        submittingSendViaPost: submitting
      });
    }
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              {!this.state.invoice && <ContentLoader />}
              {this.state.invoice && (
                <Breadcrumb separator={true} className="d-print-none">
                  <BreadcrumbItem
                    to={ROUTE_INDEX}
                    title={translate("breadcrumbs.home")}
                  />
                  <BreadcrumbItem
                    to={ROUTE_BILLING_INVOICES}
                    title={translate("breadcrumbs.invoices")}
                  />
                  <BreadcrumbItem title={`#${this.state.invoice.invoice}`} />
                </Breadcrumb>
              )}

              {this.state.invoice && (
                <div className="row">
                  <StyledViewInvoiceWrapper className="col-12 col-sm-12 col-md-4 col-lg-4">
                    <Container>
                      <StyledViewInvoiceDetails isMobile={isMobileOnly}>
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <StyledInvoiceName>
                              {this.state.invoice.digital && (
                                <CustomTooltip
                                  inline={true}
                                  placement="bottom"
                                  content={translate(
                                    "invoices.view.digital-invoice"
                                  )}
                                >
                                  <StyledDigitalInvoiceIcon icon="profile" />
                                </CustomTooltip>
                              )}
                              {translate("invoices.view.title")} #
                              {this.state.invoice.invoice}
                            </StyledInvoiceName>
                          </div>
                        </div>
                        <InvoicePaymentDetails
                          amountDue={this.state.invoice.total}
                          dateCreated={this.state.invoice.createdAt}
                          datePaid={this.state.invoice.datePaid}
                          currency={this.state.invoice.currency}
                          invoiceNumber={this.state.invoice.invoiceId}
                          proinvoiceNumber={this.state.invoice.proinvoice}
                        />

                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <StyledInvoiceToWrapper>
                              {this.state.invoice.payer && (
                                <InvoiceTo
                                  contact={{
                                    ...this.state.invoice.payer,
                                    company: this.state.invoice.company,
                                    country:
                                      this.state.invoice.payer.country &&
                                      this.state.invoice.payer.country.value
                                        ? this.state.invoice.payer.country.label
                                        : this.state.invoice.payer.country
                                  }}
                                />
                              )}
                            </StyledInvoiceToWrapper>
                          </div>
                        </div>

                        <StyledInvoiceItemsWrapper className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <InvoiceTotal
                              total={parseFloat(this.state.invoice.total)}
                              subtotal={parseFloat(this.state.invoice.subtotal)}
                              tax={parseFloat(this.state.invoice.tax)}
                              tax_rate={parseFloat(this.state.invoice.taxrate)}
                              credit={parseFloat(this.state.invoice.credit)}
                              discount={0}
                              currency={this.state.invoice.currency.code}
                            />
                          </div>
                        </StyledInvoiceItemsWrapper>
                      </StyledViewInvoiceDetails>
                    </Container>
                  </StyledViewInvoiceWrapper>
                  <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                    <StyleInvoicePreviewWrapper>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 text-right">
                          <StyledInvoiceActions>
                            <AdaptableButtonGroup
                              className="d-print-none mainDivButtons"
                              title={translate("invoices.view.actions")}
                            >
                              <SecondaryButton onClick={e => this.onPrint()}>
                                {translate("invoices.view.print")}
                              </SecondaryButton>
                              <SecondaryButton
                                disabled={this.state.downloading}
                                submitting={this.state.downloading}
                                loadingIconColor="blue"
                                onClick={e =>
                                  this.onDownload(this.state.invoice.invoice)
                                }
                              >
                                {translate("invoices.view.download-pdf")}
                              </SecondaryButton>

                              {this.state.invoice &&
                                !this.state.invoice.digital &&
                                this.state.invoice.postInfo &&
                                this.state.invoice.postInfo.length === 0 &&
                                this.state.invoice.company !== "" &&
                                (this.state.invoice.payer.country.value ===
                                  "MK" ||
                                  this.state.invoice.payer.country.value ===
                                    "МК") && (
                                  <SecondaryButton
                                    onClick={e => this.onShowSendViaPost()}
                                  >
                                    {translate("invoices.view.send-via-post")}
                                  </SecondaryButton>
                                )}

                              {this.state.invoice &&
                                !this.state.invoice.digital &&
                                this.state.invoice.postInfo &&
                                this.state.invoice.postInfo.length > 0 &&
                                this.state.invoice.company === "" &&
                                (this.state.invoice.payer.country.value ===
                                  "MK" ||
                                  this.state.invoice.payer.country.value ===
                                    "МК") && (
                                  <SecondaryButton
                                    disabled={true}
                                    onClick={e => this.onShowSendViaPost()}
                                  >
                                    <CustomTooltip
                                      placement="bottom"
                                      content={() => (
                                        <div>
                                          <StyledSendPopupTitle>
                                            {translate(
                                              "invoices.view.cannot-send-via-post"
                                            )}
                                          </StyledSendPopupTitle>
                                          <StyledSendPopupDescription>
                                            {translate(
                                              "invoices.view.cannot-send-via-post-description"
                                            )}
                                          </StyledSendPopupDescription>
                                        </div>
                                      )}
                                    >
                                      <span>
                                        {translate(
                                          "invoices.view.send-via-post"
                                        )}
                                      </span>
                                    </CustomTooltip>
                                  </SecondaryButton>
                                )}

                              {this.state.invoice &&
                                !this.state.invoice.digital &&
                                this.state.invoice.postInfo &&
                                this.state.invoice.postInfo.length > 0 &&
                                this.state.invoice.postInfo[0].returned ===
                                  false &&
                                this.state.invoice.postInfo[0].two_weeks ===
                                  "before" &&
                                this.state.invoice.company !== "" &&
                                (this.state.invoice.payer.country.value ===
                                  "MK" ||
                                  this.state.invoice.payer.country.value ===
                                    "МК") && (
                                  <SecondaryButton
                                    disabled={true}
                                    onClick={e => this.onShowSendViaPost()}
                                  >
                                    <CustomTooltip
                                      placement="bottom"
                                      content={() => (
                                        <div>
                                          <StyledSendPopupTitle>
                                            {translate(
                                              "invoices.view.invoice-en-route"
                                            )}
                                          </StyledSendPopupTitle>
                                          <StyledSendPopupDescription>
                                            {translate(
                                              "invoices.view.invoice-en-route-description"
                                            )}
                                          </StyledSendPopupDescription>
                                        </div>
                                      )}
                                    >
                                      <span>
                                        {translate(
                                          "invoices.view.send-via-post"
                                        )}
                                      </span>
                                    </CustomTooltip>
                                  </SecondaryButton>
                                )}

                              {this.state.invoice &&
                                !this.state.invoice.digital &&
                                this.state.invoice.postInfo &&
                                this.state.invoice.postInfo.length > 0 &&
                                this.state.invoice.postInfo[0].returned ===
                                  true &&
                                this.state.invoice.postInfo[0].two_weeks ===
                                  "after" &&
                                this.state.invoice.company !== "" &&
                                (this.state.invoice.payer.country.value ===
                                  "MK" ||
                                  this.state.invoice.payer.country.value ===
                                    "МК") && (
                                  <SecondaryButton
                                    onClick={e => this.onShowSendViaPost()}
                                  >
                                    {translate("invoices.view.send-via-post")}
                                  </SecondaryButton>
                                )}

                              {this.state.invoice &&
                                !this.state.invoice.digital &&
                                this.state.invoice.postInfo &&
                                this.state.invoice.postInfo.length > 0 &&
                                this.state.invoice.postInfo[0].returned ===
                                  false &&
                                this.state.invoice.postInfo[0].two_weeks ===
                                  "after" &&
                                this.state.invoice.company !== "" &&
                                (this.state.invoice.payer.country.value ===
                                  "MK" ||
                                  this.state.invoice.payer.country.value ===
                                    "МК") && (
                                  <SecondaryButton
                                    onClick={e => this.onShowSendViaPost()}
                                  >
                                    {translate("invoices.view.send-via-post")}
                                  </SecondaryButton>
                                )}

                              {this.state.invoice &&
                                this.state.invoice.digital && (
                                  <SecondaryButton
                                    disabled={true}
                                    onClick={e => this.onShowSendViaPost()}
                                  >
                                    <CustomTooltip
                                      placement="bottom"
                                      content={() => (
                                        <div>
                                          <StyledSendPopupTitle>
                                            {translate(
                                              "invoices.view.cannot-send-via-post"
                                            )}
                                          </StyledSendPopupTitle>
                                          <StyledSendPopupDescription>
                                            {translate(
                                              "invoices.view.cannot-send-digital-invoice-via-post"
                                            )}
                                          </StyledSendPopupDescription>
                                        </div>
                                      )}
                                    >
                                      <span>
                                        {translate(
                                          "invoices.view.send-via-post"
                                        )}
                                      </span>
                                    </CustomTooltip>
                                  </SecondaryButton>
                                )}

                              <SecondaryButton
                                onClick={e => this.onShowForwardInvoiceModal()}
                              >
                                <span>{translate("invoices.view.email")}</span>
                              </SecondaryButton>
                            </AdaptableButtonGroup>
                          </StyledInvoiceActions>
                        </div>
                      </div>
                      <div className="row h-100">
                        <div className="col-12 col-sm-12 col-md-12">
                          {this.state.previewInvoice && (
                            <embed
                              frameBorder={0}
                              className="w-100 h-100"
                              src={`${this.state.previewInvoice}#toolbar=0`}
                              width="100%"
                              height="100%"
                              type="application/pdf"
                            />
                          )}
                        </div>
                      </div>
                    </StyleInvoicePreviewWrapper>
                  </div>
                </div>
              )}

              {this.state.showForwardModal && (
                <Modal
                  title={translate("invoices.view.send-via-email")}
                  onCloseModal={this.onHideForwardInvoiceModal}
                  body={() => (
                    <ForwardDialog
                      onSubmit={this.onForwardInvoice}
                      onSubmitting={this.onSubmittingForwardInvoice}
                      validate={this.onValidateForwardInvoice}
                    />
                  )}
                  footer={() => [
                    <SecondaryButton
                      disabled={this.state.submittingForwardInvoice}
                      onClick={e => this.onHideForwardInvoiceModal()}
                      key="forward-invoice-cancel"
                    >
                      {translate("invoices.view.cancel")}
                    </SecondaryButton>,
                    <PrimaryButton
                      disabled={this.state.submittingForwardInvoice}
                      submitting={this.state.submittingForwardInvoice}
                      onClick={e => this.onSubmitForwardInvoice()}
                      type="submit"
                      key="forward-invoice-send"
                    >
                      {translate("invoices.view.send")}
                    </PrimaryButton>
                  ]}
                />
              )}

              {this.state.showSendViaPostModal && (
                <Modal
                  title={translate("invoices.view.send-via-email")}
                  subTitle={
                    translate("invoices.view.title") +
                    " #" +
                    this.state.invoice.invoice
                  }
                  onCloseModal={this.onHideSendViaPost}
                  body={() => (
                    <SendViaPostDialog
                      validate={this.validatePostInfo}
                      onSubmit={this.onSendViaPost}
                      onSubmitting={this.onSubmittingSendViaPost}
                      validate={this.onValidateForwardInvoice}
                      onChangeViaPostReason={this.onChangeViaPostReason}
                      invoice={this.state.invoice}
                      reason={this.state.sendViaPostReason}
                      countries={this.props.countries}
                    />
                  )}
                  footer={() => {
                    if (this.state.sendViaPostReason !== REASON_NOT_ARRIVED) {
                      return (
                        <React.Fragment>
                          <SecondaryButton
                            disabled={this.state.submittingSendViaPost}
                            onClick={e => this.onHideSendViaPost()}
                            key="send-viapost-invoice-cancel"
                          >
                            {translate("invoices.view.cancel")}
                          </SecondaryButton>
                          <PrimaryButton
                            disabled={this.state.submittingSendViaPost}
                            submitting={this.state.submittingSendViaPost}
                            onClick={e => this.onSubmitSendViaPost()}
                            type="submit"
                            key="send-viapost-invoice-send"
                          >
                            {translate("invoices.view.send")}
                          </PrimaryButton>
                        </React.Fragment>
                      );
                    }
                    if (this.state.sendViaPostReason === REASON_NOT_ARRIVED) {
                      return (
                        <React.Fragment>
                          <GhostButton
                            className="mr-auto"
                            disabled={this.state.submittingSendViaPost}
                            onClick={e => this.onHideSendViaPost()}
                            key="send-viapost-invoice-cancel"
                          >
                            {translate("invoices.view.cancel")}
                          </GhostButton>

                          <SecondaryButton
                            disabled={this.state.submittingSendViaPost}
                            onClick={e => this.onEditPostAddress()}
                          >
                            {translate("invoices.view.no-edit-address")}
                          </SecondaryButton>
                          <PrimaryButton
                            disabled={this.state.submittingSendViaPost}
                            submitting={this.state.submittingSendViaPost}
                            onClick={e => this.onSubmitSendViaPost()}
                            type="submit"
                            key="send-viapost-invoice-send"
                          >
                            {translate("invoices.view.yes-send-invoice")}
                          </PrimaryButton>
                        </React.Fragment>
                      );
                    }
                  }}
                />
              )}
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    paymentMethods: getPaymentMethods(state),
    credit: getAvailableCredit(state),
    isFetchingCredit: isFetchingCredit(state),
    countries: getCountriesWithISOFormatted(state),
    isFetchingCountries: isFetchingCountries(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInvoice,
      downloadInvoice,
      previewInvoice,
      applyCreditToInvoice,
      forwardInvoice,
      sendViaPost,
      fetchPaymentMethods,
      payInvoice,
      updateInvoicePayment,
      assignInvoicePayer,
      searchPayers,
      fetchCredit,
      reduceCredit,
      fetchCountries
    },
    dispatch
  );
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocalize(ViewInvoice)));
