import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { isMobileOnly } from "react-device-detect";
import { Translate } from "react-localize-redux";
import isLaptop from "../../../../utils/isLaptopScreen";
import { withSizes } from "react-sizes";

import Container from "../../../../components/Containers/Container";
import Icon from "../../../../components/Icon";
import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";

import SelectableProductAddon from "../../../../components/SelectableProductAddon";
import HostingPricePerYearSelector from "../../../../components/HostingPricePerYearSelector";

import DeletingRecordLoader from "../../../../components/Loaders/DeletingRecordLoader";

import Settings from "./Settings";

const StyledDedicatedServer = styled.div`
  padding: ${props => {
    if (props.isMobile) return "30px 20px";
    if (props.isLaptop) return "25px";
    return "50px";
  }};
`;

const StyledRow = styled.div`
  margin-bottom: 30px;
`;

const StyledTypeTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${theme.neutral4};
  margin-bottom: 5px;
  text-transform: uppercase;

  & .domain {
    text-transform: lowercase;
  }
`;

const StyledDedicatedServerName = styled.h4`
  font-weight: 20px;
  font-weight: 600;
  font-size: 1.4;
  color: ${theme.neutralBase};
`;

const StyledDeleteIcon = styled.span`
  position: absolute;
  right: 0;
  top: ${props => (props.isMobile ? "-10px" : "-20px")};
  cursor: ${props => (props.isDeleting ? "default" : "pointer")};
`;

const StyledPriceBadge = styled.strong`
  text-transform: uppercase;
`;

const StyledPriceWrapper = styled.div`
  display: inline-block;
  vertical-align: bottom;
  margin-left: 15px;
`;

const StyledSettingsWrapper = styled.div`
  border-top: 1px solid ${theme.neutral3};
  padding-left: ${props => (props.isMobile ? "20px" : "50px")};
  padding-right: ${props => (props.isMobile ? "20px" : "50px")};
  padding-top: 15px;
  padding-bottom: 40px;
`;

const StyledSettingsTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  position: relative;
  left: -30px;
  cursor: pointer;

  & .icon {
    position: relative;
    left: -5px;
  }
`;

class DedicatedServer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSettings: true
    };
  }

  onToggleSettings = () => {
    this.setState({ showSettings: !this.state.showSettings });
  };

  render() {
    const {
      product,
      onAttachServiceToProduct,
      onRemoveServiceFromProduct,
      onRemoveProductFromCart,
      onUpdateHostingPeriod,
      deletingProductId,
      updatingHostingPeriod,
      updatingHostingPeriodId,
      onUpdateServerSettings,
      updatingServerSettings,
      updatingServerSettingsId,
      onBlockCheckout,
      isLaptop
    } = this.props;

    let firstYearDerivation = null;
    let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];

    // const firstYearDerivation = product.item.pricing.buy.billing_cycle.derivations.find(
    //   d => d.numeric_code === 0.1
    // );

    // Temporary solution, should be sync with api derivation numeric codes
    possiblePeriodNumericCodes.some(numericCode => {
      firstYearDerivation = product.item.pricing.buy.billing_cycle.derivations.find(
        d => d.numeric_code === numericCode
      );

      if (typeof(firstYearDerivation) !== 'undefined') {
        console.log(numericCode);
        return true;
      }
    });
    // END

    const selectedYearDerivation = product.item.pricing.buy.billing_cycle.derivations.find(
      d => d.numeric_code === product.config.period
    );

    return (
      <Translate>
        {({ translate }) => (
          <StyledRow
            className="row"
            id={`checkout-dedicated-server-${product.id}`}
          >
            <div className="col-12 col-sm-12 col-md-12">
              <Container>
                <StyledDedicatedServer
                  isMobile={isMobileOnly}
                  isLaptop={isLaptop}
                >
                  <StyledRow className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledDeleteIcon
                        isMobile={isMobileOnly}
                        isDeleting={deletingProductId === product.id}
                        onClick={e =>
                          deletingProductId !== product.id &&
                          onRemoveProductFromCart(product)
                        }
                      >
                        {deletingProductId !== product.id && (
                          <Icon size="l" icon="delete" />
                        )}

                        {deletingProductId === product.id && (
                          <DeletingRecordLoader />
                        )}
                      </StyledDeleteIcon>
                      <StyledTypeTitle>
                        {translate("cart.dedicated-server")}{" "}
                        {product.domain && (
                          <span className="domain">({product.domain})</span>
                        )}
                      </StyledTypeTitle>
                      <StyledDedicatedServerName>
                        {product.item.name}
                      </StyledDedicatedServerName>
                    </div>
                  </StyledRow>
                  <StyledRow className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {product.item.pricing.buy.billing_cycle.derivations.map(
                        derivation => (
                          <HostingPricePerYearSelector
                            product={product}
                            derivation={derivation}
                            onUpdateHostingPeriod={onUpdateHostingPeriod}
                            disabled={
                              updatingHostingPeriod &&
                              updatingHostingPeriodId === product.id
                            }
                            key={`${derivation.price.value}${derivation.numeric_code}`}
                            firstYearPrice={firstYearDerivation.price.value}
                            currency={firstYearDerivation.price.currency.code}
                            period={
                              derivation.numeric_code < 1
                                ? null
                                : derivation.numeric_code === 1
                                ? translate("shop.hostings.derivations.year")
                                : translate("shop.hostings.derivations.years")
                            }
                            years={derivation.numeric_code}
                            price={derivation.price.value}
                            selected={
                              product.config.period === derivation.numeric_code
                            }
                          />
                        )
                      )}

                      <StyledPriceWrapper>
                        <DiscountedAmountBadge
                          label={translate("cart.item-price")}
                          amount={{
                            amount: product.total,
                            discountedAmount:
                              selectedYearDerivation.numeric_code < 1
                                ? null
                                : firstYearDerivation.numeric_code !==
                                  selectedYearDerivation.numeric_code
                                ? firstYearDerivation.price.value *
                                  selectedYearDerivation.numeric_code
                                : null,
                            currency: firstYearDerivation.price.currency.code
                          }}
                        />
                      </StyledPriceWrapper>
                    </div>
                  </StyledRow>
                  <StyledRow className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {product.item.services &&
                        product.item.services.length > 0 &&
                        product.item.services.map(service => {
                          if (
                            service.pricing.buy.billing_cycle.derivations
                              .length === 0
                          ) {
                            return null;
                          }
                          return (
                            <SelectableProductAddon
                              addon={service}
                              onSelect={e =>
                                service.activated
                                  ? onRemoveServiceFromProduct(service, product)
                                  : onAttachServiceToProduct(service, product)
                              }
                              key={`product-${product.id}-service-${service.id}`}
                              activated={service.activated}
                              disabled={service.updating}
                            >
                              {translate("cart.item-add")} {service.name}{" "}
                              <StyledPriceBadge>
                                {service.pricing.buy.billing_cycle
                                  .derivations[0].price.value > 0
                                  ? service.pricing.buy.billing_cycle
                                      .derivations[0].price.currency.code +
                                    " " +
                                    service.pricing.buy.billing_cycle
                                      .derivations[0].price.value
                                  : translate("general.free")}
                              </StyledPriceBadge>
                              {service.pricing.buy.billing_cycle.derivations[0]
                                .price.value > 0 && (
                                <span>
                                  /
                                  {translate(
                                    `price.derivations.${service.pricing.buy.billing_cycle.derivations[0].code}`
                                  )}
                                </span>
                              )}
                            </SelectableProductAddon>
                          );
                        })}
                    </div>
                  </StyledRow>
                </StyledDedicatedServer>
                <StyledSettingsWrapper>
                  <StyledSettingsTitle onClick={() => this.onToggleSettings()}>
                    <Icon
                      icon={
                        this.state.showSettings ? "chevron_up" : "chevron_down"
                      }
                    />{" "}
                    {translate("cart.settings")}
                  </StyledSettingsTitle>
                  {this.state.showSettings && (
                    <Settings
                      product={product}
                      updating
                      onSaveSettings={values =>
                        onUpdateServerSettings(product, values)
                      }
                      updatingServerSettings={updatingServerSettings}
                      updatingServerSettingsId={updatingServerSettingsId}
                      onBlockCheckout={onBlockCheckout}
                      parentId={`checkout-dedicated-server-${product.id}`}
                    />
                  )}
                </StyledSettingsWrapper>
              </Container>
            </div>
          </StyledRow>
        )}
      </Translate>
    );
  }
}

DedicatedServer.defaultProps = {
  product: {
    domainName: "mkhost.com",
    type: "buy"
  }
};

export default withSizes(isLaptop)(DedicatedServer);
