import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import parse from "html-react-parser";
import { Translate } from "react-localize-redux";

import getBadgeForStatus from "../../../utils/getBadgeForStatus";

const StyledResult = styled.span`
  display: block;
  font-size: 16px;
  padding: 13px 20px;
  color: ${theme.neutralBase};
  border-bottom: 1px solid ${theme.neutral3};

  background-color: ${props =>
    props.active ? props.theme.blue1 : props.theme.white};

  &:hover {
    background-color: ${theme.blue1};
    cursor: pointer;
  }
`;

const StyledContactName = styled.span`
  display: block;
  font-size: 16px;
  line-height: 1.38;
  color: ${theme.neutralBase};
  margin-bottom: 2px;
`;
const StyledContactEmail = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  margin-bottom: 0;
`;

const StyledDomainResult = ({ keyword, type, result, active, ...rest }) => {
  const BadgeComponent = getBadgeForStatus(result.status.state);
  return (
    <Translate>
      {({ translate }) => (
        <StyledResult className="result" active={active} {...rest}>
          {parse(
            result.name.replace(
              new RegExp("(" + keyword + ")", "gi"),
              "<strong>$1</strong>"
            )
          )}
          <BadgeComponent className="float-right">
            {translate(
              `global.search.status.domain.${result.status.code.toLowerCase()}`
            )}
          </BadgeComponent>
        </StyledResult>
      )}
    </Translate>
  );
};

const StyledHostingResult = ({ keyword, type, result, active, ...rest }) => {
  const BadgeComponent = getBadgeForStatus(result.status.state);
  return (
    <Translate>
      {({ translate }) => (
        <StyledResult active={active} {...rest}>
          {parse(
            result.name.replace(
              new RegExp("(" + keyword + ")", "gi"),
              "<strong>$1</strong>"
            )
          )}{" "}
          -{" "}
          {parse(
            result.domain.replace(
              new RegExp("(" + keyword + ")", "gi"),
              "<strong>$1</strong>"
            )
          )}
          <BadgeComponent className="float-right">
            {translate(
              `global.search.status.hosting.${result.status.code.toLowerCase()}`
            )}
          </BadgeComponent>
        </StyledResult>
      )}
    </Translate>
  );
};

const StyledInvoiceResult = ({ keyword, type, result, active, ...rest }) => {
  const BadgeComponent = getBadgeForStatus(result.status.state);
  const DueDateBadgeComponent = getBadgeForStatus();
  const invoiceId = result.id.toString();

  return (
    <Translate>
      {({ translate }) => (
        <StyledResult active={active} {...rest}>
          #
          {parse(
            invoiceId.replace(
              new RegExp("(" + keyword + ")", "gi"),
              "<strong>$1</strong>"
            )
          )}
          <DueDateBadgeComponent className="float-right">
            {result.dueDate}
          </DueDateBadgeComponent>
          <BadgeComponent className="float-right mr-1">
            {translate(
              `global.search.status.invoice.${result.status.code.toLowerCase()}`
            )}
          </BadgeComponent>
        </StyledResult>
      )}
    </Translate>
  );
};

const StyledContactResult = ({ keyword, type, result, active, ...rest }) => {
  return (
    <StyledResult active={active} {...rest}>
      <StyledContactName>
        {parse(
          result.name.replace(
            new RegExp("(" + keyword + ")", "gi"),
            "<strong>$1</strong>"
          )
        )}
      </StyledContactName>
      <StyledContactEmail>
        {parse(
          result.email.replace(
            new RegExp("(" + keyword + ")", "gi"),
            "<strong>$1</strong>"
          )
        )}
      </StyledContactEmail>
    </StyledResult>
  );
};

const StyledViewAll = styled.div`
  display: block;
  padding: 13px 20px;
  font-size: 14px;
  font-weight: 600;
  color: ${theme.blueBase};
  background-color: ${props =>
    props.active ? props.theme.blue1 : props.theme.white};
  z-index: 2;

  &:hover {
    background-color: ${theme.blue1};
    cursor: pointer;
  }
`;

const StyledViewAllResult = ({ result, active, ...rest }) => (
  <StyledViewAll active={active} {...rest}>
    {result.name}
  </StyledViewAll>
);

const GlobalSearchResult = ({
  keyword,
  type,
  result,
  active,
  index,
  ...rest
}) => {
  if (!type || !result) return null;
  if (type === "domain")
    return (
      <StyledDomainResult
        keyword={keyword}
        result={result}
        active={active === index}
        type={type}
        {...rest}
      />
    );

  if (type === "hosting")
    return (
      <StyledHostingResult
        keyword={keyword}
        result={result}
        active={active === index}
        type={type}
        {...rest}
      />
    );
  if (type === "invoice")
    return (
      <StyledInvoiceResult
        keyword={keyword}
        result={result}
        active={active === index}
        type={type}
        {...rest}
      />
    );

  if (type === "contact")
    return (
      <StyledContactResult
        keyword={keyword}
        result={result}
        active={active === index}
        type={type}
        {...rest}
      />
    );

  if (type.indexOf("view_all") > -1)
    return (
      <StyledViewAllResult
        keyword={keyword}
        result={result}
        active={active === index}
        type={type}
        {...rest}
      />
    );

  return null;
};

export default GlobalSearchResult;
