import React from "react";
import { Form, Field } from "react-final-form";
import { withLocalize } from "react-localize-redux";
import { isBrowser, isMobileOnly, isTablet } from "react-device-detect";
import { withSizes } from "react-sizes";
import isLaptop from "../../../../../../utils/isLaptopScreen";

import {
  validateDnsRecordHostname,
  validateDnsRecordTTL,
  validateDnsRecordTextInformation
} from "../../../../../../common/validationRules";

import RecordType from "../Fields/RecordType";
import RecordTypeSelect from "../Fields/RecordTypeSelect";
import Hostname from "../Fields/Hostname";
import TTL from "../Fields/TTL";
import TextInformation from "../Fields/TextInformation";

import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton";
import styled from "styled-components";

const StyledPrimaryButton = styled(PrimaryButton)`
  top: ${props => {
    if (props.isTablet) return "23px";
  }};
`;

const validate = values => {
  return {
    name: validateDnsRecordHostname(values.name),
    ttl: validateDnsRecordTTL(values.ttl),
    text_information: validateDnsRecordTextInformation(values.text_information)
  };
};

class TXTRecordForm extends React.Component {
  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <Form
            onSubmit={this.props.onSubmit}
            validate={validate}
            initialValues={this.props.initialValues || {}}
            render={({ handleSubmit, submitting, invalid, values, form }) => (
              <form
                id={
                  this.props.record &&
                  `dns-record-form-${this.props.record.line}`
                }
                onSubmit={e => {
                  form.blur("name");
                  form.blur("ttl");
                  form.blur("text_information");
                  handleSubmit(e);
                }}
              >
                <Field
                  component={this.props.record ? RecordType : RecordTypeSelect}
                  name="type"
                  onChange={this.props.onChange}
                  domain={this.props.domain}
                  record={this.props.record}
                  formType={this.props.formType}
                />
                <Field component={Hostname} name="name" />
                <Field component={TTL} name="ttl" />
                <Field component={TextInformation} name="text_information" />
                <StyledPrimaryButton
                  type="submit"
                  disabled={submitting || invalid}
                  submitting={submitting}
                  isMobile={isMobileOnly}
                  isLaptop={this.props.isLaptop}
                  isTablet={isTablet}
                  isBrowser={isBrowser}
                >
                  {this.props.translate("domain.zone-file.add-dns-record")}
                </StyledPrimaryButton>
              </form>
            )}
          />
        </div>
      </div>
    );
  }
}

export default withLocalize(withSizes(isLaptop)(TXTRecordForm));
