import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import Parser from "html-react-parser";

import KnowledgeBaseArticleMeta from "../../../components/KnowledgeBase/KnowledgeBaseArticleMeta";

const StyledKnowledgeBaseViewArticle = styled.div`
  margin-bottom: 20px;
`;
const StyledTitle = styled.p`
  margin-bottom: 5px;
  color: ${theme.neutralBase};
  font-size: 30px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: normal;
`;
const StyledContent = styled.div`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  margin-top: 10px;

  & img {
    max-width: 100%;
  }
`;

const KnowledgeBaseViewArticle = ({
  title,
  article,
  articleId,
  votes,
  categoryName,
  onVoteArticle
}) => (
  <StyledKnowledgeBaseViewArticle>
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12">
        <KnowledgeBaseArticleMeta
          categoryName={categoryName}
          votes={votes}
          article={article}
          articleId={articleId}
          onVoteArticle={onVoteArticle}
        />
        <StyledTitle>{title}</StyledTitle>
      </div>
      <div className="col-12 col-sm-12 col-md-12">
        <StyledContent>{Parser(article)}</StyledContent>
      </div>
    </div>
  </StyledKnowledgeBaseViewArticle>
);

export default KnowledgeBaseViewArticle;
