import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { isMobile } from "react-device-detect";
import { withLocalize } from "react-localize-redux";
import { Translate } from "react-localize-redux";

import Container from "../../../../components/Containers/Container";
import Icon from "../../../../components/Icon";
import InputField from "../../../../components/InputField";
import NoticeWarning from "../../../../components/Notices/NoticeWarning";

import SelectBox from "../../../../components/SelectBox";
import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";
import SelectableProductAddon from "../../../../components/SelectableProductAddon";

import DeletingRecordLoader from "../../../../components/Loaders/DeletingRecordLoader";

import DomainContacts from "./DomainContacts";
import Nameservers from "./Nameservers";

import {
  CART_DOMAIN_TYPE_REGISTER,
  CART_DOMAIN_TYPE_TRANSFER
} from "../../../Cart/CartDomainTypes/CartDomainTypes";

import { CONTACT_TYPE_REGISTRANT } from "../../../Domains/ViewDomain/DomainContacts/DomainContactTypes";

const StyledCheckoutDomain = styled.div`
  padding: ${props => (props.isMobile ? "20px" : "50px")};
`;

const StyledRow = styled.div`
  margin-bottom: 30px;
`;

const StyledTypeTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${theme.neutral4};
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const StyledCheckoutDomainName = styled.h4`
  font-weight: 20px;
  font-weight: 600;
  font-size: 1.4;
  color: ${theme.neutralBase};
  word-break: break-word;
`;

const StyledDeleteIcon = styled.span`
  position: absolute;
  right: 0;
  top: ${props => (props.isMobile ? "-10px" : "-20px")};
  cursor: ${props => (props.isDeleting ? "default" : "pointer")};
`;

const StyledPriceBadge = styled.strong`
  text-transform: uppercase;
`;

const StyledDomainContactsWrapper = styled.div`
  border-top: 1px solid ${theme.neutral3};
  padding-left: ${props => (props.isMobile ? "20px" : "50px")};
  padding-right: ${props => (props.isMobile ? "20px" : "50px")};
  padding-top: 15px;
  padding-bottom: 40px;
`;

const StyledDomainContactsTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  position: relative;
  left: -30px;
  cursor: pointer;

  & .icon {
    position: relative;
    left: -5px;
  }
`;

const StyledDomainNameserversWrapper = styled.div`
  border-top: 1px solid ${theme.neutral3};
  padding-left: ${props => (props.isMobile ? "20px" : "50px")};
  padding-right: ${props => (props.isMobile ? "20px" : "50px")};
  padding-top: 15px;
  padding-bottom: 40px;
`;

const StyledDomainNameserversTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  position: relative;
  left: -30px;
  cursor: pointer;

  & .icon {
    position: relative;
    left: -5px;
  }
`;

// prettier-ignore
const domainExtensionWarning = {
  "com.mk": "cart.checkout.com-mk-restriction-warning",
  "inf.mk": "cart.checkout.inf-mk-restriction-warning",
  "net.mk": "cart.checkout.net-mk-restriction-warning",
  "org.mk": "cart.checkout.org-mk-restriction-warning",
  "edu.mk": "cart.checkout.edu-mk-restriction-warning",
  "eu": "cart.checkout.eu-restriction-warning",
  "de": "cart.checkout.de-restriction-warning"
};

const hasDomainExtensionWarning = ext => !!domainExtensionWarning[ext];

class CheckoutDomain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDomainContacts: false,
      showDomainNameservers: false
    };
  }

  onToggleDomainContacts = () => {
    this.setState({
      showDomainContacts: !this.state.showDomainContacts
    });
  };

  onToggleDomainNameservers = () => {
    this.setState({
      showDomainNameservers: !this.state.showDomainNameservers
    });
  };

  render() {
    const {
      product,
      onAttachServiceToProduct,
      onRemoveServiceFromProduct,
      onRemoveProductFromCart,
      onChangeContact,
      onUpdateProductPeriod,
      updatingDomain,
      itemsCount,
      onSaveSingleDomainNameservers,
      onSubmittingDomainNameservers,
      submittingDomainNameservers,
      invalidDomainNameservers
    } = this.props;

    const selectOptions = product.item.pricing[
      product.config.type
    ].billing_cycle.derivations.map(derivation => {
      return {
        label:
          derivation.numeric_code +
          (derivation.numeric_code > 1
            ? ` ${this.props.translate("cart.years")}`
            : ` ${this.props.translate("cart.year")}`),
        value: `${derivation.code}`,
        code: derivation.numeric_code
      };
    });
    const derivation = product.item.pricing[
      product.config.type
    ].billing_cycle.derivations.find(
      derivation => derivation.numeric_code === product.config.period
    );
    const selectedValue = {
      label:
        derivation.numeric_code +
        (derivation.numeric_code > 1
          ? ` ${this.props.translate("cart.years")}`
          : ` ${this.props.translate("cart.year")}`),
      value: `${derivation.code}`,
      code: derivation.numeric_code
    };

    const [domain, domainExtension] = product.domain.split(/\.(.+)/, 2);

    const registrant = product.register_contacts.find(
      contact => contact.type === CONTACT_TYPE_REGISTRANT
    );

    return (
      <Translate>
        {({ translate }) => (
          <StyledRow className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <Container>
                <StyledCheckoutDomain isMobile={isMobile}>
                  <StyledRow className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledDeleteIcon
                        isMobile={isMobile}
                        isDeleting={
                          this.props.deletingProductId === this.props.product.id
                        }
                        onClick={e =>
                          this.props.deletingProductId !==
                            this.props.product.id &&
                          onRemoveProductFromCart(product)
                        }
                      >
                        {this.props.deletingProductId !==
                          this.props.product.id && (
                          <Icon size="l" icon="delete" />
                        )}

                        {this.props.deletingProductId ===
                          this.props.product.id && <DeletingRecordLoader />}
                      </StyledDeleteIcon>
                      <StyledTypeTitle>
                        {product.config.type === CART_DOMAIN_TYPE_REGISTER
                          ? translate("cart.domain-registration")
                          : translate("cart.domain-transfer")}
                      </StyledTypeTitle>
                      <StyledCheckoutDomainName>
                        {product.domain}
                      </StyledCheckoutDomainName>
                    </div>
                  </StyledRow>
                  <StyledRow className="row">
                    <div className="col-6 col-sm-4 col-md-3">
                      {product.config.type === CART_DOMAIN_TYPE_REGISTER && (
                        <SelectBox
                          label={translate("cart.item-period-register")}
                          options={selectOptions}
                          defaultValue={selectedValue}
                          input={{
                            onChange: values =>
                              onUpdateProductPeriod(product, values)
                          }}
                          disabled={updatingDomain}
                          isLoading={updatingDomain}
                        />
                      )}
                      {product.config.type === CART_DOMAIN_TYPE_TRANSFER && (
                        <InputField
                          value={product.config.eppcode}
                          label={translate("cart.item-epp-code")}
                          readOnly={true}
                        />
                      )}
                    </div>
                    <div className="col-6 col-sm-4 col-md-3">
                      <DiscountedAmountBadge
                        label={translate("cart.item-price")}
                        amount={{
                          amount: product.total,
                          currency: derivation.price.currency.code,
                          discountedAmount:
                            product.total < product.price ? product.price : null
                        }}
                      />
                    </div>

                    {hasDomainExtensionWarning(domainExtension) &&
                      product.config.type === CART_DOMAIN_TYPE_REGISTER &&
                      (!registrant ||
                        registrant.company === "" ||
                        registrant.tax_number === "") && (
                        <div className="col-6 col-sm-4 col-md-6">
                          <NoticeWarning>
                            {translate(domainExtensionWarning[domainExtension])}
                          </NoticeWarning>
                        </div>
                      )}
                  </StyledRow>
                  {product.config.type === CART_DOMAIN_TYPE_REGISTER && (
                    <StyledRow className="row">
                      <div className="col-12 col-sm-12 col-md-12">
                        {product.item.services &&
                          product.item.services.length > 0 &&
                          product.item.services.map(service => (
                            <SelectableProductAddon
                              addon={service}
                              onSelect={e =>
                                service.activated
                                  ? onRemoveServiceFromProduct(service, product)
                                  : onAttachServiceToProduct(service, product)
                              }
                              key={`product-${product.id}-service-${service.id}`}
                              activated={service.activated}
                            >
                              {translate("cart.item-add")} {service.name}{" "}
                              <StyledPriceBadge>
                                {service.pricing.buy.billing_cycle
                                  .derivations[0].price.value > 0
                                  ? service.pricing.buy.billing_cycle
                                      .derivations[0].price.currency.code +
                                    " " +
                                    service.pricing.buy.billing_cycle
                                      .derivations[0].price.value
                                  : translate("general.free")}
                              </StyledPriceBadge>
                              {service.pricing.buy.billing_cycle.derivations[0]
                                .price.value > 0 && (
                                <span>
                                  /
                                  {translate(
                                    `price.derivations.${service.pricing.buy.billing_cycle.derivations[0].code}`
                                  )}
                                </span>
                              )}
                            </SelectableProductAddon>
                          ))}
                      </div>
                    </StyledRow>
                  )}
                </StyledCheckoutDomain>
                {itemsCount === 1 &&
                  product.config.type === CART_DOMAIN_TYPE_REGISTER && (
                    <StyledDomainNameserversWrapper>
                      <StyledDomainNameserversTitle
                        onClick={() => this.onToggleDomainNameservers()}
                      >
                        <Icon
                          icon={
                            this.state.showDomainNameservers
                              ? "chevron_up"
                              : "chevron_down"
                          }
                        />{" "}
                        {translate("domain.settings.nameservers")}
                      </StyledDomainNameserversTitle>
                      {this.state.showDomainNameservers && (
                        <Nameservers
                          domain={product}
                          onSaveNameservers={onSaveSingleDomainNameservers}
                          onSubmittingDomainNameservers={
                            onSubmittingDomainNameservers
                          }
                          submittingDomainNameservers={
                            submittingDomainNameservers
                          }
                          invalidDomainNameservers={invalidDomainNameservers}
                        />
                      )}
                    </StyledDomainNameserversWrapper>
                  )}
                {product.config.type === CART_DOMAIN_TYPE_REGISTER && (
                  <StyledDomainContactsWrapper>
                    <StyledDomainContactsTitle
                      onClick={() => this.onToggleDomainContacts()}
                    >
                      <Icon
                        icon={
                          this.state.showDomainContacts
                            ? "chevron_up"
                            : "chevron_down"
                        }
                      />{" "}
                      {translate("domain.domain-contacts.registration")}
                    </StyledDomainContactsTitle>
                    {this.state.showDomainContacts && (
                      <DomainContacts
                        product={product}
                        onChangeContact={onChangeContact}
                        contacts={product.register_contacts}
                      />
                    )}
                  </StyledDomainContactsWrapper>
                )}
              </Container>
            </div>
          </StyledRow>
        )}
      </Translate>
    );
  }
}

CheckoutDomain.defaultProps = {
  product: {
    domainName: "sanjazakovska",
    type: "domain"
  }
};

export default withLocalize(CheckoutDomain);
