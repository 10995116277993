import React from "react";
import { Translate } from "react-localize-redux";

import Panel from "../../Panel";
import AmountBadge from "../../AmountBadge";

const AvailableCredit = ({ title, description, amount, currency }) => (
  <Translate>
    {({ translate }) => (
      <Panel title={title} description={description}>
        <div className="row">
          <div className="col-6 col-sm-6 col-md-6">
            <span className="title">
              {translate("my-account.general-info.available-credit.credit")}
            </span>
          </div>
          <div className="col-6 col-sm-6 col-md-6 text-right">
            <AmountBadge currency={currency} amount={amount} />
          </div>
        </div>
      </Panel>
    )}
  </Translate>
);

AvailableCredit.defaultProps = {
  amount: "0.00",
  currency: "MKD"
};

export default AvailableCredit;
