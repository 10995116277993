import * as PA from "../actions/preferenceActions"; //

import handleReducerActions from "../../../core/handleReducerActions";

import { APP_STATE_COMPLETE_RESET } from "../../AppState/actions/appStateActions";

import {
  PREFERENCE_ADD_FUNDS_MAXIMUM,
  PREFERENCE_ADD_FUNDS_MINIMUM
} from "../PreferenceTypes/PreferenceTypes";

const initialState = {
  all: []
};

const securityStateHandlers = {
  [PA.SYSTEM_PREFERENCES_FETCH_REQUEST]: fetchPreferencesRequest,
  [PA.SYSTEM_PREFERENCES_FETCH_RECEIVE]: fetchPreferencesReceive,
  [APP_STATE_COMPLETE_RESET]: resetState
};

export default function(state = initialState, action) {
  return handleReducerActions(securityStateHandlers, state, action);
}

function fetchPreferencesRequest(state, action) {
  return { ...state };
}

function fetchPreferencesReceive(state, action) {
  return {
    ...state,
    all: action.payload
  };
}

export function getAddFundsMinimum(state) {
  const preference = state.preferences.all.find(preference => {
    return preference.code === PREFERENCE_ADD_FUNDS_MINIMUM;
  });

  return preference ? preference.value : 0;
}

export function getAddFundsMaximum(state) {
  const preference = state.preferences.all.find(preference => {
    return preference.code === PREFERENCE_ADD_FUNDS_MAXIMUM;
  });

  return preference ? preference.value : 0;
}

function resetState(state) {
  return { ...initialState };
}
