import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import Dropzone from "react-dropzone";
import { Translate } from "react-localize-redux";

import FormGroup from "../FormGroup";
import Label from "../Label";
import Icon from "../Icon";
import SecondaryButton from "../Buttons/SecondaryButton";
import InputHelpBlock from "../InputField/InputHelpBlock";
import InputFieldIcon from "../InputField/InputFieldIcon";

const StyledFileUploadWrapper = styled.div`
  position: relative;
  padding-top: 5px;
  & .dropzone {
    background-color: ${theme.neutral1};
    min-height: 100px;
  }
  & .input-icon {
    top: 20px;
  }
`;

const StyledPlaceholder = styled.div`
  font-size: 14px;
  color: ${theme.neutral5};
`;

const StyledFile = styled.span`
  display: block;
  font-size: 14px;
  margin-bottom: 10px;
  color: ${theme.neutral6};
  padding-right: 30px;
`;

const StyledRemoveFile = styled.span`
  cursor: pointer;
`;

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptedFiles: null
    };
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    this.props.input.onChange(acceptedFiles);
    this.setState({
      acceptedFiles
    });
  };

  onRemoveFile = fileName => {
    const updatedFiles =
      this.state.acceptedFiles &&
      this.state.acceptedFiles.filter(file => file.name !== fileName);

    this.props.input.onChange(updatedFiles);
    this.setState({
      acceptedFiles: updatedFiles
    });
  };

  render() {
    const { input, meta, acceptFileExtensions, ...rest } = this.props;

    return (
      <Translate>
        {({ translate }) => (
          <FormGroup className={rest.size}>
            {rest.label.length > 0 && (
              <Label icon={rest.labelIcon}>{rest.label}</Label>
            )}

            <StyledFileUploadWrapper>
              {meta.dirty && meta.valid && !meta.invalid && (
                <InputFieldIcon
                  icon="success"
                  valid={meta.valid}
                  invalid={meta.invalid}
                />
              )}
              {meta.dirty && meta.invalid && !meta.valid && (
                <InputFieldIcon
                  icon="warning"
                  valid={meta.valid}
                  invalid={meta.invalid}
                />
              )}

              <Dropzone
                onDrop={this.onDrop}
                accept={acceptFileExtensions}
                onClick={e => e.preventDefault()}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  acceptedFiles,
                  open
                }) => {
                  return (
                    <div
                      {...getRootProps()}
                      className={`form-control dropzone ${isDragActive &&
                        "dropzone--isActive"}`}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <StyledPlaceholder>
                          {translate("file-upload.drop-files-here")}...
                        </StyledPlaceholder>
                      ) : this.state.acceptedFiles &&
                        this.state.acceptedFiles.length > 0 ? (
                        this.state.acceptedFiles.map(file => (
                          <StyledFile key={file.name}>
                            {file.name}{" "}
                            <StyledRemoveFile
                              onClick={() => this.onRemoveFile(file.name)}
                              className="float-right"
                            >
                              <Icon icon="close" />
                            </StyledRemoveFile>
                          </StyledFile>
                        ))
                      ) : (
                        <StyledPlaceholder>
                          <SecondaryButton
                            size="s"
                            onClick={e => {
                              e.preventDefault();
                              e.stopPropagation();
                              open();
                            }}
                          >
                            {translate("file-upload.upload")}
                          </SecondaryButton>{" "}
                          {translate("file-upload.or-drag-and-drop")}...
                        </StyledPlaceholder>
                      )}
                    </div>
                  );
                }}
              </Dropzone>

              {meta.dirty && meta.invalid && !meta.valid && (
                <InputHelpBlock valid={meta.valid} invalid={meta.invalid}>
                  {meta.error}
                </InputHelpBlock>
              )}
              {rest.helpText.length > 0 && (
                <InputHelpBlock>{rest.helpText}</InputHelpBlock>
              )}
            </StyledFileUploadWrapper>
          </FormGroup>
        )}
      </Translate>
    );
  }
}

FileUpload.defaultProps = {
  className: "form-control",
  label: "",
  helpText: "",
  labelIcon: "",
  inputIcon: "",
  placeholder: "",
  meta: {}
};

export default FileUpload;
