import styled from "styled-components";
import { theme } from "../../../design/Themes";

const Badge = styled.span`
  background-color: ${theme.neutral1};
  color: ${theme.neutral6}
  font-size: 14px;
  font-weight: 400;
  line-height: 1.43;
  text-align: center;
  border-radius: 3px;
  padding: 4px 10px;
  margin-left: 10px;
  margin-bottom: 5px;

  &:first-child {
    margin-left: 0;
  }
`;

Badge.defaultProps = {
  className: "badge"
};

export default Badge;
