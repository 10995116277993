import React from "react";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";

const StyledDomainExtensionsQuickPrices = styled.div`
padding-top: ${props => (props.isMobile ? "15px" : "50px")}
  color: ${props => props.theme.neutral5};
`;
const StyledPrice = styled.span`
  border-right: 1px solid ${props => props.theme.neutral5};
  padding-right: ${props => (props.isMobile ? "12px" : "20px")};
  padding-left: ${props => (props.isMobile ? "12px" : "20px")};

  display: ${props => props.isMobile && "inline-block"};
  font-size: ${props => props.isMobile && "14px"};
  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
    border-right: 0;
  }
`;

const DomainExtensionsQuickPrices = ({ prices }) => (
  <StyledDomainExtensionsQuickPrices className="row domain-extensions-quick-prices">
    <div
      className={`col-12 col-sm-12 col-md-12 ${
        isMobileOnly ? "text-center" : "text-center"
      }`}
    >
      {prices &&
        prices.constructor === Array &&
        prices.map(price => (
          <StyledPrice key={price} isMobile={isMobileOnly}>
            {isMobileOnly ? parse(price) : price}
          </StyledPrice>
        ))}
      {prices && typeof prices === "function" && prices()}
    </div>
  </StyledDomainExtensionsQuickPrices>
);

export default DomainExtensionsQuickPrices;
