import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobileOnly, isTablet } from "react-device-detect";
import isLaptop from "../../../utils/isLaptopScreen";
import { withSizes } from "react-sizes";
import { withRouter } from "react-router-dom";

import { withLocalize } from "react-localize-redux";
import styled from "styled-components";

import Breadcrumb from "../../../components/Breadcrumb";
import BreadcrumbItem from "../../../components/Breadcrumb/BreadcrumbItem";
import ContentLoader from "../../../components/Loaders/ContentLoader";
import withLoading from "../../../components/Loaders/WithLoading";

import TicketFeedbackForm from "./TicketFeedbackForm";
import TicketNotFound from "../OpenTicket/TicketNotFound";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import {
  fetchTicket,
  viewTicket,
  fetchAttachment,
  createTicketFeedback
} from "../actions/helpdeskActions";
import {
  getSelectedTicket,
  isFetchingTickets
} from "../reducers/helpdeskReducer";

import {
  ROUTE_INDEX,
  ROUTE_HELPDESK,
  ROUTE_HELPDESK_TICKETS,
  ROUTE_CREATE_TICKET
} from "../../../routes/routes";

import { HELPDESK_TICKETS_TICKET_ATTACHMENT_BASE_ENDPOINT } from "../../../services/endpoints";

const StyledWrapper = styled.div`
  margin-bottom: ${props => (props.isMobile ? "100px" : "30px")};
`;

const StyledTicketWrapper = styled.div``;

const StyledTicketWrapperWithLoading = withLoading(
  StyledTicketWrapper,
  ContentLoader
);

class TicketFeedback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ticket: null
    };
  }

  componentDidMount() {
    const ticketId = this.props.match.params.id;
    this.props.fetchTicket(ticketId).then(ticket => {
      this.props.viewTicket(ticket);
      this.setState({ ticket });
    });
  }

  onSubmit = values => {
    return this.props
      .createTicketFeedback(
        this.state.ticket.id,
        values.rating,
        values.comment,
        values.comment_admin
      )
      .then(
        result => {
          const { error, messages } = result;
          displayToastMessageForResponse(
            this.props.translate("help-desk.title"),
            { error, messages }
          );

          this.props.history.push(ROUTE_HELPDESK);
        },
        err => {
          const { error, messages } = err;
          displayToastMessageForResponse(
            this.props.translate("help-desk.title"),
            { error, messages }
          );
        }
      )
      .catch(error => {
        displayToastMessageForResponse(
          this.props.translate("help-desk.title"),
          {
            error
          }
        );
      });
  };

  render() {
    return (
      <StyledWrapper
        className="row open-ticket-wrapper"
        isMobile={isMobileOnly}
      >
        <div className="col-12 col-sm-12 col-md-12">
          {!this.props.ticket && !this.props.isFetchingTickets && (
            <TicketNotFound openNewTicketRoute={ROUTE_CREATE_TICKET} />
          )}
          <StyledTicketWrapperWithLoading
            isLoading={this.props.isFetchingTickets}
          >
            {this.props.ticket && (
              <Breadcrumb>
                <BreadcrumbItem
                  to={ROUTE_INDEX}
                  title={this.props.translate("breadcrumbs.home")}
                />
                <BreadcrumbItem
                  to={ROUTE_HELPDESK}
                  title={this.props.translate("breadcrumbs.help-desk")}
                />
                <BreadcrumbItem
                  to={ROUTE_HELPDESK_TICKETS}
                  title={this.props.translate("breadcrumbs.help-desk")}
                />
                <BreadcrumbItem
                  title={`#${this.props.ticket.id} - ${this.props.ticket.subject}`}
                />
              </Breadcrumb>
            )}
            {this.props.ticket && (
              <div className="row">
                <div
                  className={
                    isMobileOnly || isTablet
                      ? "col-12 col-sm-12 col-md-12"
                      : this.props.isLaptop
                      ? "col-8 offset-2 col-sm-8 offset-sm-2 col-md-8 offset-md-2"
                      : "col-4 offset-4 col-sm-4 offset-4 col-md-4 offset-4"
                  }
                >
                  <TicketFeedbackForm
                    ticket={this.props.ticket}
                    onSubmit={this.onSubmit}
                  />
                </div>
              </div>
            )}
          </StyledTicketWrapperWithLoading>
        </div>
      </StyledWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    ticket: getSelectedTicket(state),
    isFetchingTickets: isFetchingTickets(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTicket,
      viewTicket,
      fetchAttachment,
      createTicketFeedback
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocalize(withSizes(isLaptop)(TicketFeedback))));
