import React from "react";
import styled from "styled-components";


const RoundLoaderCircle = styled.div`
  display: inline-block;
  position: relative;
  width: 120px;
  height: 120px;
    
    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #757575;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #757575 transparent transparent transparent;
    }
    div:nth-child(1) {
      animation-delay: -0.45s;
    }
    div:nth-child(2) {
      animation-delay: -0.3s;
    }
    div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
`

const RoundLoader  = () => {
    return(
            <RoundLoaderCircle>
                <div/>
                <div/>
                <div/>
                <div/>
            </RoundLoaderCircle>
    )

}


export default RoundLoader;