import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import { theme } from "../../../design/Themes";
import { isMobileOnly } from "react-device-detect";

import DiscountedAmountBadge from "../../DiscountedAmountBadge";
import PrimaryButton from "../../Buttons/PrimaryButton";
import Icon from "../../Icon";

import { DOMAIN_STATUS_AVAILABLE } from "../../../features/Domains/DomainStatuses";

import getNoticeForStatus from "../../../utils/getNoticeForStatus";

import { calculateDomainReducedPrice } from "../../../services/pricingService";

import { compileRoute, ROUTE_WEBSITE_WHOIS } from "../../../routes/routes";

const StyledDomainSearchResult = styled.div`
  padding: 25px;

  & .notice-success,
  & .notice-danger,
  & .notice-info {
    margin-right: 30px;
    vertical-align: top;
  }
`;

const StyledDomain = styled.span`
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  color: ${props => props.theme.neutralBase};
  margin-top: ${props => !props.description && "10px"};
  position: ${props => props.isMobile && "relative"};
`;

const StyledPrice = styled.div`
  display: inline-block;
  float: right;
  color: ${props => props.theme.neutral6};
  font-size: 14px;
  font-weight: 600;

  & .discounted-amount-badge {
    display: inline-block;
    vertical-align: ${props => (props.hasDiscountPrice ? "sub" : "initial")};
  }
`;

const StyledAddToCartButton = styled(PrimaryButton)`
  display: inline-block;
  float: right;
  top: 5px;
`;

const StyledDescription = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: ${props => props.theme.neutral6};
`;

const StyledWhoIsLink = styled.a`
  display: inline-block;
  font-size: 16px;
  float: ${props => (props.isMobile ? "none" : "right")};
  color: ${props => props.theme.blueBase};
  position: ${props => (props.isMobile ? "absolute" : "relative")};
  top: ${props => (props.isMobile ? "auto" : "15px")};
  bottom: ${props => props.isMobile && "-25px"};
  right: ${props => props.isMobile && "0"};
`;

const DomainSearchResult = ({
  status,
  domain,
  price = null,
  currency = null,
  onAddToCart = null,
  addedToCart = null,
  submitting = null,
  statusText = null,
  description = null,
  type,
  priceReduce = null,
  showWhoIs
}) => {
  const NoticeComponent = getNoticeForStatus(status);

  return (
    <Translate>
      {({ translate, activeLanguage }) => (
        <StyledDomainSearchResult>
          {status && (
            <NoticeComponent>
              {statusText
                ? statusText
                : translate(`general.${status}`) +
                  " " +
                  translate("general.domain")}
            </NoticeComponent>
          )}
          {!isMobileOnly && (
            <>
              <StyledDomain description={description}>
                {domain.name}{" "}
                {description && (
                  <StyledDescription>{description}</StyledDescription>
                )}
              </StyledDomain>
              {showWhoIs && activeLanguage && (
                <StyledWhoIsLink
                  href={compileRoute(ROUTE_WEBSITE_WHOIS)({
                    language: activeLanguage.code,
                    domain: domain.name
                  })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translate("shop.domains.whois")}
                </StyledWhoIsLink>
              )}
            </>
          )}

          {isMobileOnly && (
            <StyledDomain description={description} isMobile={isMobileOnly}>
              {domain.name}{" "}
              {description && (
                <StyledDescription>{description}</StyledDescription>
              )}
              {showWhoIs && activeLanguage && (
                <StyledWhoIsLink
                  isMobile={isMobileOnly}
                  href={compileRoute(ROUTE_WEBSITE_WHOIS)({
                    language: activeLanguage.code,
                    domain: domain.name
                  })}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {translate("shop.domains.whois")}
                </StyledWhoIsLink>
              )}
            </StyledDomain>
          )}

          {onAddToCart && typeof onAddToCart === "function" && (
            <StyledAddToCartButton
              disabled={addedToCart || submitting}
              submitting={submitting}
              onClick={() => onAddToCart(domain, type)}
            >
              <Icon icon="cart" />{" "}
              {addedToCart
                ? translate("cart.added-to-cart")
                : translate("cart.add-to-cart")}
            </StyledAddToCartButton>
          )}
          {domain &&
            domain.pricing &&
            domain.pricing[type] &&
            status === DOMAIN_STATUS_AVAILABLE && (
              <StyledPrice
                hasDiscountPrice={priceReduce && priceReduce.type === type}
              >
                <DiscountedAmountBadge
                  amount={{
                    amount:
                      priceReduce && priceReduce.type === type
                        ? calculateDomainReducedPrice(
                            domain.pricing[type].billing_cycle.derivations[0]
                              .price.value,
                            priceReduce
                          )
                        : domain.pricing[type].billing_cycle.derivations[0]
                            .price.value,
                    discountedAmount:
                      priceReduce && priceReduce.type === type
                        ? domain.pricing[type].billing_cycle.derivations[0]
                            .price.value
                        : null,
                    currency:
                      domain.pricing[type].billing_cycle.derivations[0].price
                        .currency.prefix,
                    period:
                      domain.pricing[type].billing_cycle.derivations[0].name
                  }}
                />
              </StyledPrice>
            )}
        </StyledDomainSearchResult>
      )}
    </Translate>
  );
};
export default DomainSearchResult;
