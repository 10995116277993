import React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { components } from "react-select";
import { Translate } from "react-localize-redux";

import InputField from "../../../../../components/InputField";
import BlockRadiobox from "../../../../../components/BlockRadiobox";
import SelectBox from "../../../../../components/SelectBox";

import graphicIndividualDefault from "../../../../../assets/images/graphic_individual.png";
import graphicCompanyDefault from "../../../../../assets/images/graphic_company.png";

import { CONTACT_DETAILS_TYPE_COMPANY } from "../ContactDetailsForm/ContactDetailsTypes";

import { CONTACT_TYPE_ADMINISTRATIVE } from "../DomainContactTypes";

const StyledFormSeparator = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  margin-left: -30px;
  margin-right: -30px;
  margin-bottom: 30px;
`;

const StyledPaddedContainer = styled.div`
  padding: 30px;
  padding-top: 0;
  padding-bottom: 15px;
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.neutralBase};
  margin-bottom: 20px;
`;

const StyledEnableEditing = styled.div`
  font-size: 16px;
  color: ${theme.neutralBase};
  & span {
    color: ${theme.blueBase};
    cursor: pointer;
  }
`;

const StyledSelectOption = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  & span {
    display: block;
  }
`;

const ContactDetailsFormWithSearch = ({
  onSubmit,
  onCancel,
  validate,
  contact,
  onSubmitting,
  onSearchContacts,
  onSelectContact,
  contactEditingDisabled,
  onEnableContactEditing,
  countries,
  domain
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <Form
          onSubmit={onSubmit}
          validate={validate}
          initialValues={contact || {}}
          render={({ handleSubmit, values }) => {
            return (
              <form id="domain-contact-details-form" onSubmit={handleSubmit}>
                <StyledFormSeparator className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="row">
                      <StyledPaddedContainer className="col-12 col-sm-12 col-md-12">
                        <StyledTitle>
                          {translate(
                            "domain.domain-contacts.use-an-existing-contact"
                          )}
                        </StyledTitle>
                        <SelectBox
                          async={true}
                          defaultOptions
                          isClearable={true}
                          loadOptions={onSearchContacts}
                          onInputChange={newValue => newValue}
                          input={{ onChange: onSelectContact }}
                          placeholder={translate(
                            "domain.domain-contacts.search-by-name-or-email"
                          )}
                          components={{
                            Option: ({ children, ...rest }) => {
                              return (
                                <components.Option {...rest}>
                                  <StyledSelectOption>
                                    <span>{rest.data.name}</span>
                                    <span>{rest.data.email}</span>
                                  </StyledSelectOption>
                                </components.Option>
                              );
                            }
                          }}
                        />
                      </StyledPaddedContainer>
                    </div>
                  </div>
                </StyledFormSeparator>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <StyledTitle>
                      {translate(
                        "domain.domain-contacts.or-fill-in-the-contact-details-manually"
                      )}
                    </StyledTitle>
                    {!domain.isMkDomain &&
                      contact.type !== CONTACT_TYPE_ADMINISTRATIVE && (
                        <div className="row">
                          <div className="col-6 col-sm-6 col-md-6">
                            <Field
                              component={BlockRadiobox}
                              activeIcon={graphicIndividualDefault}
                              defaultIcon={graphicIndividualDefault}
                              name="contact_type"
                              value="individual"
                              type="radio"
                              readOnly={contact && contactEditingDisabled}
                            >
                              {translate("domain.domain-contacts.individual")}
                            </Field>
                          </div>

                          <div className="col-6 col-sm-6 col-md-6">
                            <Field
                              component={BlockRadiobox}
                              activeIcon={graphicCompanyDefault}
                              defaultIcon={graphicCompanyDefault}
                              name="contact_type"
                              value="company"
                              type="radio"
                              readOnly={contact && contactEditingDisabled}
                            >
                              {translate("domain.domain-contacts.company")}
                            </Field>
                          </div>
                        </div>
                      )}
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-6">
                        <Field
                          component={InputField}
                          label={translate("domain.domain-contacts.first-name")}
                          name="first_name"
                          readOnly={contactEditingDisabled}
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-md-6">
                        <Field
                          component={InputField}
                          label={translate("domain.domain-contacts.last-name")}
                          name="last_name"
                          readOnly={contactEditingDisabled}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6 col-sm-6 col-md-6">
                        {" "}
                        <Field
                          component={InputField}
                          label={translate("domain.domain-contacts.email")}
                          name="email"
                          readOnly={contactEditingDisabled}
                        />
                      </div>
                      <div className="col-6 col-sm-6 col-md-6">
                        {" "}
                        <Field
                          component={InputField}
                          label={translate(
                            "domain.domain-contacts.phone-number"
                          )}
                          name="phone"
                          readOnly={contactEditingDisabled}
                        />
                      </div>
                    </div>
                    {values.contact_type === CONTACT_DETAILS_TYPE_COMPANY &&
                      !domain.isMkDomain &&
                      contact.type !== CONTACT_TYPE_ADMINISTRATIVE && (
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <Field
                              component={InputField}
                              label={translate(
                                "domain.domain-contacts.company"
                              )}
                              name="company"
                              readOnly={contactEditingDisabled}
                            />
                          </div>
                        </div>
                      )}
                    {!domain.isMkDomain && (
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          {" "}
                          <Field
                            component={InputField}
                            label={translate("domain.domain-contacts.address")}
                            name="address1"
                            readOnly={contactEditingDisabled}
                          />
                        </div>
                      </div>
                    )}
                    {!domain.isMkDomain &&
                      contact.type !== CONTACT_TYPE_ADMINISTRATIVE && (
                        <div className="row">
                          <div className="col-6 col-sm-6 col-md-6">
                            {" "}
                            <Field
                              component={InputField}
                              label={translate("domain.domain-contacts.city")}
                              name="city"
                              readOnly={contactEditingDisabled}
                            />
                          </div>
                          <div className="col-6 col-sm-6 col-md-6">
                            <Field
                              component={InputField}
                              label={translate(
                                "domain.domain-contacts.zip-code"
                              )}
                              name="zip"
                              readOnly={contactEditingDisabled}
                            />
                          </div>
                        </div>
                      )}
                    <div className="row">
                      {!domain.isMkDomain &&
                        contact.type !== CONTACT_TYPE_ADMINISTRATIVE && (
                          <div className="col-6 col-sm-6 col-md-6">
                            <Field
                              component={InputField}
                              label={translate(
                                "domain.domain-contacts.state-region"
                              )}
                              name="state_region"
                              readOnly={contactEditingDisabled}
                            />
                          </div>
                        )}
                      <div className="col-6 col-sm-6 col-md-6">
                        <Field
                          component={SelectBox}
                          label={translate("domain.domain-contacts.country")}
                          options={countries}
                          isSearchable={true}
                          name="country"
                          readOnly={contactEditingDisabled}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {contact && contact.email && contactEditingDisabled && (
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledEnableEditing>
                        {translate(
                          "domain.domain-contacts.incorrect-information-you-can"
                        )}{" "}
                        <span onClick={e => onEnableContactEditing()}>
                          {translate(
                            "domain.domain-contacts.update-this-contact"
                          )}
                          .
                        </span>
                      </StyledEnableEditing>
                    </div>
                  </div>
                )}
                <FormSpy
                  subscription={{ submitting: true }}
                  onChange={formstate => onSubmitting({ ...formstate })}
                />
              </form>
            );
          }}
        />
      )}
    </Translate>
  );
};

export default ContactDetailsFormWithSearch;
