import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Translate } from "react-localize-redux";

import AmountBadge from "../../components/AmountBadge";

const StyledInvoiceTotal = styled.div`
  padding: 15px;
`;

const StyledLabel = styled.span`
  font-size: 16px;
  line-height: 1.43;
  color: ${theme.neutralBase};
`;
const StyledValue = styled(StyledLabel)`
  font-size: 16px;
`;
const StyledTotalLabel = styled(StyledLabel)`
  font-weight: 600;
  text-transform: uppercase;
`;

const StyledRow = styled.div`
  padding: 7.5px;
`;

const StyledTotalRow = styled.div`
  padding: 7.5px;
  border-top: 1px solid ${theme.neutral3};
`;

const StyledSubtotalRow = styled.div`
  padding: 7.5px;
  border-bottom: 1px solid ${theme.neutral3};
`;

const InvoiceTotal = ({
  total,
  subtotal,
  tax,
  tax_rate,
  credit,
  discount,
  currency
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <StyledInvoiceTotal>
          <StyledSubtotalRow className="row">
            <div className="col-6 col-sm-6 col-md-6">
              <StyledLabel>
                {translate("pro-invoices.view.subtotal")}
              </StyledLabel>
            </div>
            <div className="col-6 col-sm-6 col-md-6 text-right">
              <StyledValue>
                <strong>{subtotal.toFixed(2)}</strong>
              </StyledValue>
            </div>
          </StyledSubtotalRow>
          <StyledRow className="row">
            <div className="col-6 col-sm-6 col-md-6">
              <StyledLabel style={{ paddingLeft: "30px" }}>
                {translate("pro-invoices.view.tax")} ({tax_rate.toFixed(2)}%)
              </StyledLabel>
            </div>
            <div className="col-6 col-sm-6 col-md-6 text-right">
              <StyledValue>{tax.toFixed(2)}</StyledValue>
            </div>
          </StyledRow>

          <StyledRow className="row">
            <div className="col-6 col-sm-6 col-md-6">
              <StyledLabel style={{ paddingLeft: "30px" }}>
                {translate("pro-invoices.view.credit")}
              </StyledLabel>
            </div>
            <div className="col-6 col-sm-6 col-md-6 text-right">
              <StyledValue>
                {credit.toFixed(2) > 0.0 ? `-${credit.toFixed(2)}` : 0.0}
              </StyledValue>
            </div>
          </StyledRow>
          <StyledTotalRow className="row">
            <div className="col-6 col-sm-6 col-md-6">
              <StyledTotalLabel>
                {translate("pro-invoices.view.total")}
              </StyledTotalLabel>
            </div>
            <div className="col-6 col-sm-6 col-md-6 text-right">
              <AmountBadge amount={total.toFixed(2)} currency={currency} />
            </div>
          </StyledTotalRow>
        </StyledInvoiceTotal>
      )}
    </Translate>
  );
};

export default InvoiceTotal;
