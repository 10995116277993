export const TICKET_STATUS_OPEN = "Open";
export const TICKET_STATUS_CLOSED = "Closed";
export const TICKET_STATUS_ANSWERED = "Answered";
export const TICKET_STATUS_CUSTOMER_REPLY = "Customer-Reply";
export const TICKET_STATUS_ON_HOLD = "On Hold";
export const TICKET_STATUS_IN_PROGRESS = "In Progress";

export const ALL_TICKET_STATUSES = [
  TICKET_STATUS_OPEN,
  TICKET_STATUS_CLOSED,
  TICKET_STATUS_ANSWERED,
  TICKET_STATUS_CUSTOMER_REPLY,
  TICKET_STATUS_ON_HOLD,
  TICKET_STATUS_IN_PROGRESS
];
