import React from "react";

import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { isMobileOnly } from "react-device-detect";

import Container from "../../../components/Containers/Container";
import InvoiceTotal from "../../../components/InvoiceTotal";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import CustomTooltip from "../../../components/CustomTooltip";

import ApplyCredit from "./ApplyCredit";
import CreditCardPaymentMethod from "../../../components/PaymentMethods/CreditCardPaymentMethod";
import BankTransferPaymentMethod from "../../../components/PaymentMethods/BankTransferPaymentMethod";

import getNoticeForStatus from "../../../utils/getNoticeForStatus";
import FlagDanger from "../../../components/Flags/FlagDanger";

import {
  PAYMENT_METHOD_HALKBANK,
  PAYMENT_METHOD_2CHECKOUT,
  PAYMENT_METHOD_BANK_TRANSFER
} from "../../PaymentMethods/PaymentMethodsModules";

import {
  PAYMENT_STATUS_DRAFT,
  PAYMENT_STATUS_UNPAID,
  PAYMENT_STATUS_PAID,
  PAYMENT_STATUS_CANCELLED,
  PAYMENT_STATUS_REFUNDED,
  PAYMENT_STATUS_PAYMENT_PENDING
} from "../../PaymentMethods/PaymentStatus";

import { ROUTE_CREATE_TICKET } from "../../../routes/routes";
import {Field, Form} from "react-final-form";
import Checkbox from "../../../components/Checkbox";
import {validateCheckbox} from "../../../common/validationRules";

const StyledContainerWithoutBottom = styled(Container)`
  border-bottom: 0;
  margin-bottom: ${props => props.isMobile && "60px"};
`;

const StyledPayment = styled.div`
  padding-top: 30px;

  & .compact-payment-summary-header {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${theme.neutral3};
  }

  & .compact-payment-summary-header h4 {
    color: ${theme.neutral5};
    font-size: 14px;
    line-height: 1.43;
    text-transform: uppercase;
  }

  & .compact-payment-summary-header span.compact-payment-summary-count {
    font-size: 12px;
    line-height: 1.33;
    background-color: ${theme.blueBase};
    color: ${theme.white};
    text-align: center;
    padding: 1px 6px;
    border-radius: 3px;
    margin-left: 5px;
  }

  & .compact-payment-summary-header span.compact-payment-summary-clear {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blueBase};
    line-height: 1.43;
    letter-spacing: 0.2px;
    cursor: pointer;
  }
`;
const CheckboxInline = styled.div `
  .custom-checkbox{
  width:100% !important;
  }
  .input-help-block{
    position:relative !important;
    top:0;  
  }
`;


const validate = (values, maxCredit) => {
  return {
    pp: validateCheckbox(values.pp, 'accept-privacy-policy-global'),
    pp2: validateCheckbox(values.pp2, 'accept-privacy-policy-global')
  };
};

const StyledPaymentContainer = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
`;

const StyledPaymentApplyCreditContainer = styled.div`
  border-bottom: 0;
`;

const StyledPaddedContainer = styled.div`
  padding: 30px;
`;

const StyledPaddedPaymentMethodsContainer = styled.div`
  padding: 30px;
  border-bottom: 1px solid ${theme.neutral3};
  border-top: 1px solid ${theme.neutral3};
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: ${props => props.highlight && `2px solid ${props.theme.blue3}`};
`;

const StyledPayNowContainer = styled.div`
  padding: 0;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: ${props => props.isMobile && "35px"};
`;

const StyledPaymentError = styled.div`
  margin-bottom: 15px;
`;

const StyledChoosePaymentMethodTitle = styled.span`
  color: ${theme.neutral5};
  font-size: 14px;
  line-height: 1.43;
  text-transform: uppercase;
  display: block;
  margin-bottom: 20px;
`;

const StyledDonationInfo = styled.span`
  display: block;
  padding-bottom: 10px;
`;

const paymentMethodsDisplayName = method =>
  method === PAYMENT_METHOD_HALKBANK || method === PAYMENT_METHOD_2CHECKOUT
    ? "Credit Card"
    : "Bank Transfer";

const Payment = ({
  title,
  invoice,
  availableCredit,
  isFetchingCredit,
  onApplyCredit,
  onValidateCredit,
  selectedPayment,
  onSelectPayment,
  paymentMethods,
  onPayProInvoice,
  processPaymentGateway,
  paymentFailed,
  submittingProInvoicePayment,
  applyCreditSubmitting,
  disableCreditForm,
  highlightPaymentMethods,
  onSetPaymentMethodsRef,
  onCancelProInvoice,
  cancelingProInvoice
}) => {
  const NoticeComponent = getNoticeForStatus(invoice.status.state);
  let proInvoiceMessageIcon = "";
  switch (invoice.status.code) {
    case PAYMENT_STATUS_PAID:
      proInvoiceMessageIcon = "paid";
      break;

    case PAYMENT_STATUS_UNPAID:
      proInvoiceMessageIcon = "unpaid";
      break;

    default:
      proInvoiceMessageIcon = "info";
  }

  const hasCreditCardPayment = paymentMethods.find(
    method =>
      method.module === PAYMENT_METHOD_HALKBANK ||
      method.module === PAYMENT_METHOD_2CHECKOUT
  );
  const hasBankTransferPayment = paymentMethods.find(
    method => method.module === PAYMENT_METHOD_BANK_TRANSFER
  );

  // const donationAmount = (invoice.subtotal * 0.10) +' '+invoice.currency.suffix;
  // const invoiceDatePaid = new Date(invoice.date_paid);
 
    return (
    <Translate>
      {({ translate }) => (
        <StyledContainerWithoutBottom
          className="d-print-none"
          isMobile={isMobileOnly}
        >
          <StyledPayment>
            <div className="compact-payment-summary-header">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <h4>
                    {title}{" "}
                    {invoice.status.code !== PAYMENT_STATUS_CANCELLED &&
                      invoice.status.code !== PAYMENT_STATUS_PAID && (
                        <div className="float-right">
                          <CustomTooltip
                            inline
                            content={translate(
                              "pro-invoices.view.cancel-proinvoice-tooltip"
                            )}
                            placement="bottom"
                          >
                            <SecondaryButton
                              onClick={() => onCancelProInvoice()}
                              disabled={cancelingProInvoice}
                              submitting={cancelingProInvoice}
                              size="s"
                              className="float-right"
                            >
                              {translate("pro-invoices.view.cancel-proinvoice")}
                            </SecondaryButton>
                          </CustomTooltip>
                        </div>
                      )}
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <StyledPaddedContainer>
                  {paymentFailed &&
                    invoice.status.code === PAYMENT_STATUS_UNPAID && (
                      <StyledPaymentError>
                        <FlagDanger
                          title={translate(
                            "pro-invoices.view.payment-failed-title"
                          )}
                          dismissable={false}
                        >
                          {translate(
                            "pro-invoices.view.payment-failed-message"
                          )}
                          .
                          <Link to={ROUTE_CREATE_TICKET}>
                            {translate("pro-invoices.view.open-ticket")}
                          </Link>
                        </FlagDanger>
                      </StyledPaymentError>
                    )}
                  {proInvoiceMessageIcon && (
                    <NoticeComponent icon={proInvoiceMessageIcon}>
                      {invoice.status.code === PAYMENT_STATUS_DRAFT &&
                        translate("pro-invoices.view.invoice-statuses.draft", {
                          invoiceStatus: invoice.status.text
                        })}

                      {invoice.status.code === PAYMENT_STATUS_UNPAID &&
                        translate(
                          "pro-invoices.view.invoice-statuses.not-paid",
                          {
                            days: invoice.status.overdue_days
                          }
                        )}


                      {/* { invoiceDatePaid.valueOf() >= 1607986800000 && invoiceDatePaid.valueOf() <= 1610751599000 &&
                        <StyledDonationInfo>
                        {invoice.status.code === PAYMENT_STATUS_PAID &&
                          translate("pro-invoices.view.donation-info", {
                            donation: donationAmount
                          })}
                        </StyledDonationInfo>
                      } */}

                      {invoice.status.code === PAYMENT_STATUS_PAID &&
                        translate("pro-invoices.view.invoice-statuses.paid", {
                          datePaid: invoice.date_paid,
                          paymentModule: paymentMethodsDisplayName(
                            invoice.payment_method.module
                          )
                        })}

                      {invoice.status.code === PAYMENT_STATUS_CANCELLED &&
                        translate(
                          "pro-invoices.view.invoice-statuses.cancelled"
                        )}

                      {invoice.status.code === PAYMENT_STATUS_REFUNDED &&
                        translate(
                          "pro-invoices.view.invoice-statuses.refunded"
                        )}

                      {invoice.status.code === PAYMENT_STATUS_PAYMENT_PENDING &&
                        translate(
                          "pro-invoices.view.invoice-statuses.payment-pending"
                        )}
                    </NoticeComponent>
                  )}
                </StyledPaddedContainer>
                <StyledPaymentContainer>
                  <InvoiceTotal
                    total={parseFloat(invoice.total)}
                    subtotal={parseFloat(invoice.subtotal)}
                    tax={parseFloat(invoice.tax)}
                    tax_rate={parseFloat(invoice.tax_rate)}
                    credit={parseFloat(invoice.credit)}
                    discount={0}
                    currency={invoice.currency.code}
                  />
                </StyledPaymentContainer>
                {invoice.status.code === PAYMENT_STATUS_UNPAID && (
                  <StyledPaymentApplyCreditContainer>
                    <ApplyCredit
                      isFetchingCredit={isFetchingCredit}
                      availableCredit={availableCredit.amount}
                      useAmount={
                        availableCredit.amount > parseFloat(invoice.total)
                          ? parseFloat(invoice.total)
                          : availableCredit.amount
                      }
                      currency={availableCredit.currency.code}
                      onApplyCredit={onApplyCredit}
                      validate={onValidateCredit}
                      disableForm={disableCreditForm}
                      applyCreditSubmitting={applyCreditSubmitting}
                    />
                  </StyledPaymentApplyCreditContainer>
                )}
                {invoice.status.code === PAYMENT_STATUS_UNPAID && (
                  <StyledPaddedPaymentMethodsContainer
                    highlight={highlightPaymentMethods}
                    ref={onSetPaymentMethodsRef}
                  >
                    <StyledChoosePaymentMethodTitle>
                      {translate("pro-invoices.view.choose-payment-method")}
                    </StyledChoosePaymentMethodTitle>

                    <div className="col-12 col-sm-12 col-md-12">
                      {hasCreditCardPayment && (
                        <CreditCardPaymentMethod
                          selected={
                            selectedPayment === PAYMENT_METHOD_HALKBANK ||
                            selectedPayment === PAYMENT_METHOD_2CHECKOUT
                          }
                          onSelectPayment={onSelectPayment}
                          paymentMethod={hasCreditCardPayment}
                        />
                      )}
                      {hasBankTransferPayment && (
                        <BankTransferPaymentMethod
                          selected={
                            selectedPayment === PAYMENT_METHOD_BANK_TRANSFER
                          }
                          onSelectPayment={onSelectPayment}
                          paymentMethod={hasBankTransferPayment}
                        />
                      )}

                      {selectedPayment && (
                        <form
                          id="process-payment-form"
                          action={
                            processPaymentGateway &&
                            processPaymentGateway.process_url
                          }
                          method="POST"
                        >
                          {Object.keys(processPaymentGateway).map(data => (
                            <input
                              key={data}
                              type="hidden"
                              name={data}
                              value={processPaymentGateway[data]}
                            />
                          ))}
                        </form>
                      )}

                      {selectedPayment &&
                        hasCreditCardPayment.module === selectedPayment && (
                          <StyledPayNowContainer isMobile={isMobileOnly}>
                            <br/>
                            <Form validate={validate}
                                  onSubmit={() =>{console.log('submitting')}}
                                  className="col-12 col-sm-12 col-md-12"
                                  render={({invalid}) => {
                                    return (
                                    <form
                                        onSubmit={e => {
                                          console.log('submitting');
                                        }}
                                    >
                                      <CheckboxInline>
                                        <Field
                                            component={Checkbox}
                                            value={true}
                                            label={translate("agree-privacy-policy")}
                                            name="pp"
                                        />
                                        <br/>
                                      </CheckboxInline>
                                      <CheckboxInline>
                                        <Field
                                            component={Checkbox}
                                            value={true}
                                            label={translate("agreement-with-my-privacy-policy")}
                                            name="pp2"
                                        />
                                        <br/>
                                      </CheckboxInline>
                                      <PrimaryButton
                                          disabled={
                                            !selectedPayment || submittingProInvoicePayment || invalid
                                          }
                                          submitting={submittingProInvoicePayment}
                                          onClick={e => onPayProInvoice(e)}
                                          className="w-100"
                                      >
                                        {translate("pro-invoices.view.pay-now")}
                                      </PrimaryButton>
                                    </form>
                                    );
                                  }}
                            />
                          </StyledPayNowContainer>
                        )}
                    </div>
                  </StyledPaddedPaymentMethodsContainer>
                )}
              </div>
            </div>
          </StyledPayment>
        </StyledContainerWithoutBottom>
      )}
    </Translate>
  );
};

export default Payment;
