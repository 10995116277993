import React from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "throttle-debounce";

import withSizes from "react-sizes";
import isLaptop from "../../../utils/isLaptopScreen";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Container from "../../../components/Containers/Container";
import Breadcrumb from "../../../components/Breadcrumb";
import BreadcrumbItem from "../../../components/Breadcrumb/BreadcrumbItem";

import withLoading from "../../../components/Loaders/WithLoading";
import KnowledgeBaseCategoriesLoader from "../../../components/Loaders/KnowledgeBaseCategoriesLoader";
import KnowledgeBaseArticleLoader from "../../../components/Loaders/KnowledgeBaseArticleLoader";

import KnowledgeBaseArticlesPanel from "../../../components/KnowledgeBase/KnowledgeBaseArticlesPanel";
import KnowledgeBaseArticle from "../../../components/KnowledgeBase/KnowledgeBaseArticle";
import KnowledgeBaseViewArticle from "../../../components/KnowledgeBase/KnowledgeBaseViewArticle";
import KnowledgeBaseArticlesSearchByKeywordResultsContainer from "../../../components/KnowledgeBase/KnowledgeBaseArticlesSearchByKeywordResultsContainer";

import Form from "../../../components/Forms/Form";
import SearchField from "../../../components/SearchField";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import {
  compileRoute,
  ROUTE_INDEX,
  ROUTE_HELPDESK,
  ROUTE_HELPDESK_KNOWLEDGE_BASE,
  ROUTE_HELPDESK_VIEW_KNOWLEDGE_BASE_ARTICLE,
  ROUTE_HELPDESK_KNOWLEDGE_BASE_CATEGORY
} from "../../../routes/routes";

import {
  fetchKnowledgeBaseArticle,
  viewArticle,
  fetchHelpDeskKnowledgeBaseCategories,
  searchKnowledgeBaseArticles,
  voteArticle
} from "../../HelpDesk/actions/helpdeskActions";

import {
  getKnowledgeBaseCategories,
  isFetchingKnowledgeBaseCategories,
  getKnowledgeBaseMostPopularArticles,
  isFetchingKnowledgeBaseMostPopularArticles,
  getKnowledgeBaseArticleToView,
  isFetchingKnowledgeBaseArticle,
  getFetchedArticles,
  getKnowledgeBaseSearchedArticles,
  isFetchingKnowledgeBaseArticleByKeyword
} from "../../HelpDesk/reducers/helpdeskReducer";

const StyledKnowledgeBaseContainer = styled.div`
  & a.tickets-type {
    font-size: 16px;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }
`;

const StyledKnowledgeBaseArticleContainer = styled.div`
  padding: 20px;
`;

const StyledKnowledgeBase = styled.div`
  & .knowledge-base-search {
    padding: 25px 25px 10px 25px;
    border-bottom: 1px solid ${theme.neutral3};
  }

  & .knowledge-base-categories-title {
    padding: 25px 25px 25px 25px;
    border-bottom: 1px solid ${theme.neutral3};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--neutral-5);
  }
`;

class ViewKnowledgeBaseArticle extends React.Component {
  constructor(props) {
    super(props);

    this.knowledgeArticlePath = compileRoute(
      ROUTE_HELPDESK_VIEW_KNOWLEDGE_BASE_ARTICLE
    );

    this.knowledgeCategoryPath = compileRoute(
      ROUTE_HELPDESK_KNOWLEDGE_BASE_CATEGORY
    );

    this.state = {
      searchQuery: ""
    };

    this.searchArticlesByKeywordThrottled = debounce(
      1000,
      this.searchArticlesByKeyword
    );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      this.fetchArticle(nextProps.match.params.id);
    }
  }

  componentDidMount() {
    if (!this.props.knowledgeBaseCategories.length)
      this.props.fetchHelpDeskKnowledgeBaseCategories();

    this.fetchArticle(this.props.match.params.id);
  }

  fetchArticle(articleId) {
    const article = this.props.articles.find(article => {
      return article.id === parseInt(articleId, 10);
    });

    if (article) {
      this.props.viewArticle(article);
      return true;
    }

    if (
      !this.props.currentArticle ||
      this.props.currentArticle.id !== articleId
    ) {
      this.props.fetchKnowledgeBaseArticle(articleId).then(article => {
        this.props.viewArticle(article);
      });
    }
  }

  searchArticlesByKeyword = value => {
    if (value.length > 0) this.props.searchKnowledgeBaseArticles(value);
  };

  onChangeKeywordSearch = event => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.searchArticlesByKeywordThrottled(this.state.searchQuery);
    });
  };

  onVoteArticle = (article, votes) => {
    return this.props.voteArticle(article, votes).then(result => {
      if (result.alreadyVoted) {
        displayToastMessageForResponse(
          this.props.translate("help-desk.knowledge-base.title"),
          {
            error: true,
            messages: {
              0: this.props.translate("help-desk.knowledge-base.already-voted")
            }
          }
        );
      } else if (!result.alreadyVoted) {
        displayToastMessageForResponse(
          this.props.translate("help-desk.knowledge-base.title"),
          {
            error: false,
            messages: {
              0: this.props.translate("help-desk.knowledge-base.vote-added")
            }
          }
        );
      }
    });
  };

  render() {
    const StyledKnowledgeBaseContainerCategoriesWithLoading = withLoading(
      StyledKnowledgeBaseContainer,
      KnowledgeBaseCategoriesLoader
    );

    const StyledKnowledgeBaseArticleContainerWithLoading = withLoading(
      StyledKnowledgeBaseArticleContainer,
      KnowledgeBaseArticleLoader
    );

    return (
      <Translate>
        {({ translate }) => (
          <StyledKnowledgeBase>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <Breadcrumb>
                  <BreadcrumbItem
                    to={ROUTE_INDEX}
                    title={translate("breadcrumbs.home")}
                  />
                  <BreadcrumbItem
                    to={ROUTE_HELPDESK}
                    title={translate("breadcrumbs.help-desk")}
                  />
                  <BreadcrumbItem
                    to={ROUTE_HELPDESK_KNOWLEDGE_BASE}
                    title={translate("breadcrumbs.knowledge-base")}
                  />
                  <BreadcrumbItem
                    to={this.knowledgeCategoryPath({
                      id: this.props.currentArticle.category_id
                    })}
                    title={this.props.currentArticle.category_name}
                  />
                  <BreadcrumbItem title={this.props.currentArticle.title} />
                </Breadcrumb>

                <div className="row">
                  <div className="col-6 col-sm-6 col-md-6">
                    <Container>
                      <div className="knowledge-base-categories-title">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            {translate("help-desk.knowledge-base.categories")}
                          </div>
                        </div>
                      </div>
                      <div className="knowledge-base-search">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <Form>
                              <SearchField
                                onChange={this.onChangeKeywordSearch}
                                name="search"
                                placeholder={translate(
                                  "help-desk.knowledge-base.search-placeholder"
                                )}
                              />
                            </Form>
                          </div>
                        </div>
                      </div>
                      <KnowledgeBaseArticlesSearchByKeywordResultsContainer
                        searchQuery={this.state.searchQuery}
                        isFetchingKnowledgeBaseArticleByKeyword={
                          this.props.isFetchingKnowledgeBaseArticleByKeyword
                        }
                        searchedArticles={this.props.searchedArticles}
                        knowledgeArticlePath={this.knowledgeArticlePath}
                      />
                      <StyledKnowledgeBaseContainerCategoriesWithLoading
                        isLoading={this.props.isFetchingKnowledgeBaseCategories}
                      >
                        {this.props.knowledgeBaseCategories.map(category => {
                          return (
                            <KnowledgeBaseArticlesPanel
                              key={`${`helpdesk-knowledge-base-category-${category.id}`}`}
                              title={category.name}
                              description={category.description}
                              numberOfArticles={category.number_of_articles}
                            >
                              {category.articles.map(article => {
                                return (
                                  <KnowledgeBaseArticle
                                    key={`${`helpdesk-knowledge-base-article-${article.id}`}`}
                                    title={article.title}
                                    article={article}
                                    onVoteArticle={this.onVoteArticle}
                                    votes={article.votes}
                                    categoryName={article.category_name}
                                    showMeta={true}
                                    articleLink={this.knowledgeArticlePath({
                                      id: article.id
                                    })}
                                  />
                                );
                              })}
                            </KnowledgeBaseArticlesPanel>
                          );
                        })}
                      </StyledKnowledgeBaseContainerCategoriesWithLoading>
                    </Container>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6">
                    <Container>
                      <StyledKnowledgeBaseArticleContainerWithLoading
                        isLoading={this.props.isFetchingKnowledgeBaseArticle}
                      >
                        <KnowledgeBaseViewArticle
                          title={this.props.currentArticle.title}
                          votes={this.props.currentArticle.votes}
                          categoryName={this.props.currentArticle.category_name}
                          article={this.props.currentArticle.article}
                          articleId={this.props.currentArticle.id}
                          onVoteArticle={this.onVoteArticle}
                        />
                      </StyledKnowledgeBaseArticleContainerWithLoading>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </StyledKnowledgeBase>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetchingKnowledgeBaseCategories: isFetchingKnowledgeBaseCategories(state),
    knowledgeBaseCategories: getKnowledgeBaseCategories(state),
    isFetchingKnowledgeBaseMostPopularArticles: isFetchingKnowledgeBaseMostPopularArticles(
      state
    ),
    knowledgeBaseMostPopularArticles: getKnowledgeBaseMostPopularArticles(
      state
    ),
    currentArticle: getKnowledgeBaseArticleToView(state),
    isFetchingKnowledgeBaseArticle: isFetchingKnowledgeBaseArticle(state),
    articles: getFetchedArticles(state),
    searchedArticles: getKnowledgeBaseSearchedArticles(state),
    isFetchingKnowledgeBaseArticleByKeyword: isFetchingKnowledgeBaseArticleByKeyword(
      state
    )
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchKnowledgeBaseArticle,
      viewArticle,
      fetchHelpDeskKnowledgeBaseCategories,
      searchKnowledgeBaseArticles,
      voteArticle
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(withLocalize(ViewKnowledgeBaseArticle)));
