import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate } from "react-localize-redux";
import { BrowserView, MobileView, isMobileOnly,isIE } from "react-device-detect";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { ROUTE_WEBSITE, ROUTE_LOGIN } from "../../../routes/routes";
import TwoFactorAuthenticationForm from "./TwoFactorForm";
import {
  setApplicationLocale,
  getApplicationLocale
} from "../../../services/localizationService";

import * as authService from "../../../services/authService";

import { twoFactorLogin, twoFactorSetupLater } from "../actions/authActions";

import { fetchCart } from "../../Cart/actions/cartActions";
import { fetchCustomerPersonalInformation } from "../../Customer/actions/customerActions";

import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import whiteLogo from "../../../assets/images/white-logo.svg";

const StyledLogoContainer = styled.div`
  background-color: ${theme.neutralBase};
  position: relative;
  text-align: center;
  vertical-align: middle;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const StyledMobileLogoContainer = styled(StyledLogoContainer)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1031;
  border-radius: 0;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const StyledTwoFactorFormContainer = styled.div`
  margin-top: 80px;
  margin-bottom: 30px;
`;

const StyledTwoFactorFormWrapper = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledLogo = styled.img`
  position: relative;
  top: 45%;
`;

class TwoFactorVerification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLocale: getApplicationLocale(),
    };
  }

  

  onAuthSuccess = token => {
    authService.setToken(token);
    this.props.twoFactorLogin(token);
    this.props.fetchCustomerPersonalInformation();
    this.props.fetchCart();
  };

  onSetupLater = () => {
    this.props.twoFactorSetupLater();
    this.props.fetchCustomerPersonalInformation();
    this.props.fetchCart();
  };

  render() {
    const { setup2FA,twoFactorPhoneNumber } = this.props;
    
    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <BrowserView viewClassName="col-6 offset-3 col-sm-6 offset-sm-3 col-md-6 offset-md-3">
              <StyledTwoFactorFormContainer className="row no-gutters">
                <StyledLogoContainer className="col-6 col-sm-6 col-md-6">
                <a href={`${ROUTE_WEBSITE}/${this.state.currentLocale}`}>
                <StyledLogo src={whiteLogo} alt="MKHost" isIE={isIE} />
              </a>
               
                </StyledLogoContainer>
                <StyledTwoFactorFormWrapper className="col-6 col-sm-6 col-md-6">
                  <TwoFactorAuthenticationForm
                    onSetupLater={this.onSetupLater}
                    isTwoFAEnabled={setup2FA}
                    authToken={this.props.authToken}
                    onAuthSuccess={this.onAuthSuccess}
                    twoFactorPhoneNumber={twoFactorPhoneNumber}
                  />
                </StyledTwoFactorFormWrapper>
              </StyledTwoFactorFormContainer>
            </BrowserView>
            <MobileView viewClassName="col-12 col-sm-12 col-md-12">
              <StyledTwoFactorFormContainer
                isMobile={isMobileOnly}
                className="row"
              >
                <StyledMobileLogoContainer className="col-12 col-sm-12 col-md-12">
                <a href={`${ROUTE_WEBSITE}/${this.state.currentLocale}`}>
                <StyledLogo
                  isMobile={isMobileOnly}
                  isIE={isIE}
                  src={whiteLogo}
                  alt="MKHost"
                />
              </a>
                </StyledMobileLogoContainer>
                <StyledTwoFactorFormWrapper className="col-12 col-sm-12 col-md-12">
                  <TwoFactorAuthenticationForm
                    onSetupLater={this.onSetupLater}
                    isTwoFAEnabled={setup2FA}
                    authToken={this.props.authToken}
                    onAuthSuccess={this.onAuthSuccess}
                    twoFactorPhoneNumber={twoFactorPhoneNumber}
                  />
                </StyledTwoFactorFormWrapper>
              </StyledTwoFactorFormContainer>
            </MobileView>
          </div>
        )}
      </Translate>
    );
  }
}

// Define mapStateToProps and mapDispatchToProps if needed
const mapStateToProps = state => {
  return {
    // Map state to props
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      // Define any actions you need to dispatch
      twoFactorLogin,
      twoFactorSetupLater,
      fetchCart,
      fetchCustomerPersonalInformation
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwoFactorVerification);
