import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

import Icon from "../Icon";

const StyledBusinessAvatar = styled.div`
  display: inline-block;

  max-width: 48px;
  max-height: 48px;
  padding: 12px;
  text-align: center;
  vertical-align: top;
  border-radius: 50%;
  margin-right: 5px;
  background-color: ${theme.neutral1};

  & .icon {
    top: -3px;
    left: -3px;
    color: ${theme.neutral4};
  }
`;

const BusinessAvatar = ({ image }) => (
  <StyledBusinessAvatar className="user-avatar">
    {image && <img src={image} alt="" />}
    {!image && <Icon size="l" icon="company" />}
  </StyledBusinessAvatar>
);

BusinessAvatar.defaultProps = {
  image: null
};

export default BusinessAvatar;
