import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import {
  MobileOnlyView,
  isMobileOnly,
  isTablet,
  isBrowser
} from "react-device-detect";

import Container from "../../../components/Containers/Container";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import CreditCardPaymentMethod from "../../../components/PaymentMethods/CreditCardPaymentMethod";
import BankTransferPaymentMethod from "../../../components/PaymentMethods/BankTransferPaymentMethod";

import {
  PAYMENT_METHOD_HALKBANK,
  PAYMENT_METHOD_2CHECKOUT,
  PAYMENT_METHOD_BANK_TRANSFER
} from "../../PaymentMethods/PaymentMethodsModules";

import InvoiceTo from "./InvoiceTo";
import { Translate } from "react-localize-redux";

import PromotionInfo from "../../../components/PromotionInfo";

const StyledCheckoutPaymentWrapper = styled.div`
  margin-bottom: ${props => (props.isMobile ? "50px" : "30px")};
`;

const StyledCheckoutPayment = styled.div`
  padding: ${props => (props.isMobile ? "20px" : "50px")};
`;

const StyledHeader = styled.div`
  margin-bottom: 50px;
`;

const StyledTitle = styled.h4`
  color: ${theme.neutralBase};
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
`;

const StyledPaymentTitle = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${theme.neutral5};
  text-transform: uppercase;
  margin-bottom: 30px;
  padding-left: 0;
`;

const StyledPayButtonWrapper = styled.div`
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 20px;
  margin-bottom: ${props => props.isMobile && "35px"};
`;

const CheckoutPayment = props => {
  const hasCreditCardPayment = props.paymentMethods.find(
    method =>
      method.module === PAYMENT_METHOD_HALKBANK ||
      method.module === PAYMENT_METHOD_2CHECKOUT
  );
  const hasBankTransferPayment = props.paymentMethods.find(
    method => method.module === PAYMENT_METHOD_BANK_TRANSFER
  );

  return (
    <Translate>
      {({ translate }) => (
        <StyledCheckoutPaymentWrapper isMobile={isMobileOnly}>
          <Container>
            <StyledCheckoutPayment isMobile={isMobileOnly}>
              <StyledHeader>
                <StyledTitle>
                  {translate("cart.checkout.payment.title")}
                </StyledTitle>
              </StyledHeader>
              <MobileOnlyView>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <InvoiceTo contact={props.customer} />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12">
                    <StyledPaymentTitle>
                      {translate("cart.checkout.payment.payment-method")}
                    </StyledPaymentTitle>
                    {hasCreditCardPayment && (
                      <CreditCardPaymentMethod
                        selected={
                          props.selectedPayment === PAYMENT_METHOD_HALKBANK ||
                          props.selectedPayment === PAYMENT_METHOD_2CHECKOUT
                        }
                        onSelectPayment={props.onSelectPayment}
                        paymentMethod={hasCreditCardPayment}
                      />
                    )}
                    {hasBankTransferPayment && (
                      <BankTransferPaymentMethod
                        selected={
                          props.selectedPayment === PAYMENT_METHOD_BANK_TRANSFER
                        }
                        onSelectPayment={props.onSelectPayment}
                        paymentMethod={hasBankTransferPayment}
                      />
                    )}
                    <PromotionInfo></PromotionInfo>
                    <StyledPayButtonWrapper isMobile={isMobileOnly}>
                      <PrimaryButton
                        className="w-100"
                        size="l"
                        onClick={props.onCheckout}
                        disabled={props.disableCheckoutButton}
                        submitting={props.disableCheckoutButton}
                      >
                        {translate(
                          "cart.checkout.payment.confirm-order-and-pay"
                        )}
                      </PrimaryButton>
                    </StyledPayButtonWrapper>
                  </div>
                </div>
              </MobileOnlyView>
              {(isTablet || isBrowser) && (
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-6">
                    <InvoiceTo contact={props.customer} />
                  </div>
                  <div className="col-6 col-sm-6 col-md-6">
                    <StyledPaymentTitle>
                      {translate("cart.checkout.payment.payment-method")}
                    </StyledPaymentTitle>
                    {hasCreditCardPayment && (
                      <CreditCardPaymentMethod
                        selected={
                          props.selectedPayment === PAYMENT_METHOD_HALKBANK ||
                          props.selectedPayment === PAYMENT_METHOD_2CHECKOUT
                        }
                        onSelectPayment={props.onSelectPayment}
                        paymentMethod={hasCreditCardPayment}
                      />
                    )}
                    {hasBankTransferPayment && (
                      <BankTransferPaymentMethod
                        selected={
                          props.selectedPayment === PAYMENT_METHOD_BANK_TRANSFER
                        }
                        onSelectPayment={props.onSelectPayment}
                        paymentMethod={hasBankTransferPayment}
                        description={translate(
                          "pro-invoices.view.bank-transfer-description-checkout"
                        )}
                      />
                    )}
                    <PromotionInfo></PromotionInfo>
                    <StyledPayButtonWrapper isMobile={isMobileOnly}>
                      <PrimaryButton
                        className="w-100"
                        size="l"
                        onClick={props.onCheckout}
                        disabled={
                          !props.selectedPayment || props.disableCheckoutButton
                        }
                        submitting={props.disableCheckoutButton}
                      >
                        {translate(
                          "cart.checkout.payment.confirm-order-and-pay"
                        )}
                      </PrimaryButton>
                    </StyledPayButtonWrapper>
                  </div>
                </div>
              )}
            </StyledCheckoutPayment>
          </Container>
        </StyledCheckoutPaymentWrapper>
      )}
    </Translate>
  );
};

export default CheckoutPayment;
