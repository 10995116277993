import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FORM_ERROR } from "final-form";
import {
  BrowserView,
  MobileView,
  isMobile,
  isMobileOnly,
  isIE
} from "react-device-detect";
import { withLocalize } from "react-localize-redux";
import TwoFactorAuthentication from "../TwoFactorAuthentication";

import styled from "styled-components";
import { theme } from "../../../design/Themes";

import { login } from "../actions/authActions";
import {
  isAuthenticated,
  isPhoneNumberRequired,
  isTwoFactorEnabled
} from "../reducers/authReducer";

import LoginForm from "./LoginForm";

import {
  validateEmail,
  validateLoginPassword
} from "../../../common/validationRules";

import whiteLogo from "../../../assets/images/white-logo.svg";

import { fetchCart } from "../../Cart/actions/cartActions";
import { fetchCustomerPersonalInformation } from "../../Customer/actions/customerActions";

import {
  setApplicationLocale,
  getApplicationLocale
} from "../../../services/localizationService";

import { ROUTE_WEBSITE, ROUTE_LOGIN } from "../../../routes/routes";
import CookieBanner from "../../../components/CookieBanner";

const StyledLogoContainer = styled.div`
  background-color: ${theme.neutralBase};
  position: relative;
  text-align: center;
  vertical-align: middle;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const StyledMobileLogoContainer = styled(StyledLogoContainer)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1031;
  border-radius: 0;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const StyledLoginFormContainer = styled.div`
  margin-top: ${props => (props.isMobile ? "150px" : "80px")};
  margin-bottom: 30px;
`;

const StyledLoginFormWrapper = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledLogo = styled.img`
  position: ${props => (props.isIE ? "absolute" : "relative")};
  top: 45%;
  left: ${props => props.isIE && "25%"};
  width: ${props => props.isMobile && "55%"};
`;

const validateLogin = values => {
  return {
    email: validateEmail(values.email),
    password: validateLoginPassword(values.password)
  };
};

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentLocale: getApplicationLocale(),
      reCaptchaToken: null,
      showTwoStepVerification: false,
      twoStepVerificationCompleted: false,
      twoFactorEnabled: false,
      authToken: null,
      setup2FA: false,
    };
  }

  componentDidMount() {
    if (window.grecaptcha && typeof window.grecaptcha.execute === "function") {
      window.grecaptcha
        .execute(process.env.REACT_APP_MKHOST_GOOGLE_RECAPTCHA_KEY)
        .then(newToken => {
          this.onSetReCaptchaToken(newToken);
        });
    }
  }

  onSetReCaptchaToken = token => {
    this.setState({
      reCaptchaToken: token
    });
  };

  onLogin = values => {
    let redirectBack = null;

    try {
      redirectBack = sessionStorage.getItem("redirect_intended")
        ? sessionStorage.getItem("redirect_intended") !== ROUTE_LOGIN
          ? sessionStorage.getItem("redirect_intended")
          : null
        : null;
    } catch (e) {
      redirectBack = null;
    }

    if (this.props.location.search && this.props.location.search !== "") {
      let params = this.props.location.search.split("=");
      const redirectParam = params.shift();
      if (redirectParam === "?redirect") {
        redirectBack = params.join("=").replace("&", "?");
      }
    }

    return this.props
      .login(
        values.email,
        values.password,
        this.props.history.push,
        redirectBack,
        this.state.reCaptchaToken,
        null
      )
      .then(
        () => {
          if (this.props.isAuthenticated) {
            this.props.fetchCart();
            this.props.fetchCustomerPersonalInformation();
          }
        },
        error => {
          if (error && error.response && error.response.data) {
            const { messages } = error.response.data.data;

            if (
              window.grecaptcha &&
              typeof window.grecaptcha.execute === "function"
            ) {
              window.grecaptcha
                .execute(process.env.REACT_APP_MKHOST_GOOGLE_RECAPTCHA_KEY)
                .then(newToken => {
                  this.onSetReCaptchaToken(newToken);
                });
            }

            return {
              [FORM_ERROR]: messages[0]
            };
          }
        }
      );
  };

  changeLocale = locale => {
    this.props.setActiveLanguage(locale);
    setApplicationLocale(locale);

    this.setState({ currentLocale: getApplicationLocale() });
  };

  render() {
    const urlParams = new URLSearchParams(window.location.search);

    if (this.props.isPhoneNumberRequired && !this.props.isTwoFactorEnabled) {
      return (
        <TwoFactorAuthentication
          onVerifyOTP={this.handleTwoStepVerificationComplete}
          authToken={this.state.authToken}
          setup2FA={this.props.isTwoFactorEnabled}
          twoFactorPhoneNumber={this.props.twoFactorPhoneNumber}
        />
      );
    }

    if (this.props.isTwoFactorEnabled) {
      return (
        <TwoFactorAuthentication
          onVerifyOTP={this.handleTwoStepVerificationComplete}
          authToken={urlParams.get("code")}
          setup2FA={this.props.isTwoFactorEnabled}
         twoFactorPhoneNumber={this.props.twoFactorPhoneNumber}
        />
      );
    }

    return (
      <div className="row">
        <BrowserView viewClassName="col-6 offset-3 col-sm-6 offset-sm-3 col-md-6 offset-md-3">
          <StyledLoginFormContainer className="row no-gutters">
            <StyledLogoContainer className="col-6 col-sm-6 col-md-6">
              <a href={`${ROUTE_WEBSITE}/${this.state.currentLocale}`}>
                <StyledLogo src={whiteLogo} alt="MKHost" isIE={isIE} />
              </a>
            </StyledLogoContainer>
            <StyledLoginFormWrapper className="col-6 col-sm-6 col-md-6">
              <LoginForm
                onSubmit={this.onLogin}
                validate={validateLogin}
                isMobile={isMobile}
                changeLocale={this.changeLocale}
                currentLocale={this.state.currentLocale}
                onSetReCaptchaToken={this.onSetReCaptchaToken}
              />
            </StyledLoginFormWrapper>
          </StyledLoginFormContainer>
        </BrowserView>
        <MobileView viewClassName="col-12 col-sm-12 col-md-12">
          <StyledLoginFormContainer isMobile={isMobileOnly} className="row">
            <StyledMobileLogoContainer className="col-12 col-sm-12 col-md-12">
              <a href={`${ROUTE_WEBSITE}/${this.state.currentLocale}`}>
                <StyledLogo
                  isMobile={isMobileOnly}
                  isIE={isIE}
                  src={whiteLogo}
                  alt="MKHost"
                />
              </a>
            </StyledMobileLogoContainer>
            <StyledLoginFormWrapper className="col-12 col-sm-12 col-md-12">
              <LoginForm
                onSubmit={this.onLogin}
                validate={validateLogin}
                isMobile={isMobile}
                changeLocale={this.changeLocale}
                currentLocale={this.state.currentLocale}
                onSetReCaptchaToken={this.onSetReCaptchaToken}
              />
            </StyledLoginFormWrapper>
          </StyledLoginFormContainer>
        </MobileView>
        <CookieBanner />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state),
    isPhoneNumberRequired: isPhoneNumberRequired(state),
    isTwoFactorEnabled: isTwoFactorEnabled(state),
    twoFactorPhoneNumber: state.auth.twoFactorPhoneNumber,
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      login,
      fetchCart,
      fetchCustomerPersonalInformation
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Login));
