import styled from "styled-components";
import { theme } from "../../../design/Themes";
import Button from "../Button";

const ActionWarningButton = styled(Button)`
  background-color: ${theme.white};
  color: ${theme.redBase};
  border: solid 1px ${theme.neutral3};
  &:hover {
    background-color: ${theme.neutral1};
    border: 1px solid ${theme.neutral3};
  }

  &:focus {
    border: 1px solid ${theme.neutral3};
    bordbox-shadow: 0 0 0 2px ${theme.blue3};
  }

  &:active {
    background-color: ${theme.neutral1};
    border: 1px solid ${theme.neutral3};
    color: ${theme.red3};
  }

  &:disabled {
    background-color: ${theme.neutral2};
    color: ${theme.white};
    border: 1px solid ${theme.neutral2};
  }

  padding: 0 10px;
  margin-right: 0;
`;
ActionWarningButton.defaultProps = {
  className: "btn action-button"
};

export default ActionWarningButton;
