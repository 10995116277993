import axios from "axios";
import { sprintf } from "sprintf-js";

import {
  PRODUCTS_LISTING_API_ENDPOINT,
  PRODUCTS_LISTING_BY_GROUP_API_ENDPOINT,
  PRODUCTS_UPGRADES_LISTING,
  PRODUCTS_UPGRADE_PRODUCT,
  PRODUCTS_PRODUCT_REQUEST_RENEWAL,
  PRODUCTS_PRODUCT_CANCEL
} from "./endpoints";

export function all() {
  return axios.get(PRODUCTS_LISTING_API_ENDPOINT);
}

export function allByGroup() {
  return axios.get(PRODUCTS_LISTING_BY_GROUP_API_ENDPOINT);
}

export function fetchProductUpgrades(product) {
  return axios.get(sprintf(PRODUCTS_UPGRADES_LISTING, product));
}

export function upgradeProduct(currentProduct, upgradeProduct) {
  return axios.post(PRODUCTS_UPGRADE_PRODUCT, {
    hosting_id: currentProduct.id,
    catalog_id: upgradeProduct.id,
    billing_cycle: currentProduct.pricing.buy.billing_cycle.derivations[0].code
  });
}

export function requestRenewProduct(product) {
  return axios.put(sprintf(PRODUCTS_PRODUCT_REQUEST_RENEWAL, product));
}

export function cancelProduct(product, period) {
  return axios.put(sprintf(PRODUCTS_PRODUCT_CANCEL, product), {
    type: period
  });
}

export function fetchProductDetails(product) {
  return axios.get();
}
