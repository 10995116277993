import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { withLocalize } from "react-localize-redux";
import { isMobileOnly } from "react-device-detect";

import Icon from "../../../../../components/Icon";

const StyledGrayNameserver = styled.div`
  background-color: ${theme.neutral1};
  padding: 15px 10px;
  display: block;
  border-bottom: 1px solid ${theme.neutral3};
  &:last-child {
    border-bottom: 0;
  }
`;

const StyledBlueNameserver = styled.div`
  background-color: ${theme.blue1};
  padding: 15px 10px;
  display: block;
  border-bottom: 1px solid ${theme.neutral3};
  &:last-child {
    border-bottom: 0;
  }
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${theme.neutral5};
`;

const StyledValue = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${theme.neutralBase};
`;

const StyledArrowIcon = styled.span`
  border: 1px solid ${theme.neutral3};
  border-radius: 50%;
  color: ${theme.neutral3};
  display: inline-block;
  margin-top: 90px;
  width: 34px;
  height: 34px;
  & .icon {
    top: 3px;
  }
`;

const NameserversList = ({ nameservers, translate }) => (
  <div className="row">
    <div className="col-12 col-sm-12 col-md-12">
      <div className="row">
        <div className="col-12 col-sm-12 col-md-5">
          {nameservers &&
            nameservers.list &&
            Object.keys(nameservers.list).map((ns, index) => (
              <StyledGrayNameserver key={`${nameservers.list[ns]}`}>
                <StyledTitle>
                  {translate("domain.settings.nameserver")} {index + 1}
                </StyledTitle>
                <StyledValue>{nameservers.list[ns]}</StyledValue>
              </StyledGrayNameserver>
            ))}
        </div>
        {!isMobileOnly && (
          <div className="col-12 col-sm-12 col-md-2 text-center">
            <StyledArrowIcon>
              <Icon icon="arrow_right" />
            </StyledArrowIcon>
          </div>
        )}
        <div className="col-12 col-sm-12 col-md-5 ">
          {nameservers &&
            nameservers.default_name_servers &&
            Object.keys(nameservers.default_name_servers).map((ns, index) => (
              <StyledBlueNameserver
                key={`${nameservers.default_name_servers[ns]}`}
              >
                <StyledTitle>
                  {translate("domain.settings.nameserver")} {index + 1}
                </StyledTitle>
                <StyledValue>
                  {nameservers.default_name_servers[ns]}
                </StyledValue>
              </StyledBlueNameserver>
            ))}
        </div>
      </div>
    </div>
  </div>
);

export default withLocalize(NameserversList);
