import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

import FormGroup from "../FormGroup";
import Label from "../Label";
import InputFieldIcon from "../InputField/InputFieldIcon";

const StyledSearchField = styled.input`
  height: 40px;
  //min-width: 380px;
  font-size: 14px;
  background-color: ${theme.neutral1};
  border-radius: 3px;
  border: 1px solid transparent;
  color: ${theme.neutral5};
  line-height: 1.43;
  padding: 10px 10px 10px 10px;

  &:hover {
    border: 1px solid ${theme.neutral3};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${theme.blue3};
    border: 1px solid ${theme.blueBase};
  }
  &:active {
    background-color: ${theme.white};
    border: 1px solid ${theme.blueBase};
    outline: none;
    box-shadow: 0;
  }
  &:disabled {
    cursor: not-allowed;
    background-color: ${theme.neutral2};
  }

  & + span.input-icon {
    top: 20px;
    right: 10px;
  }
`;

const SearchField = props => {
  return (
    <FormGroup>
      {props.label.length > 0 && (
        <Label icon={props.labelIcon}>{props.label}</Label>
      )}
      <StyledSearchField {...props} type="text" />
      <InputFieldIcon icon="search" />
    </FormGroup>
  );
};

SearchField.defaultProps = {
  className: "form-control search-control",
  label: "",
  placeholder: ""
};

export default SearchField;
