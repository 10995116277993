import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import { isBrowser, isTablet, MobileOnlyView } from "react-device-detect";

//  border-top: ${props =>
// props.borderless ? "0" : `1px solid ${theme.neutral3}`};

const StyledDetailsPanelItem = styled.div`
  border-top: 1px solid ${theme.neutral3};
  padding: 15px 30px;
  &:nth-child(even) {
    background-color: ${props => props.striped && props.theme.blue1};
  }
  & .details-panel-item-label {
    font-size: 14px;
    color: ${theme.neutral5};
    font-weight: 600;
    line-height: 1.43;
  }

  & .details-panel-item-content {
    font-size: 16px;
    color: ${theme.neutralBase};
    line-height: 1.38;
  }
`;

const DetailsPanelItem = ({ children, label, ...rest }) => (
  <StyledDetailsPanelItem {...rest}>
    {label && (
      <div className="row">
        <MobileOnlyView renderWithFragment>
          <div className="col-12 col-sm-12 col-md-12">
            <span className="details-panel-item-label">{label}</span>
          </div>
          <div className="col-12 col-sm-12 col-md-12 text-left">
            <div className="details-panel-item-content ">{children}</div>
          </div>
        </MobileOnlyView>
        {(isBrowser || isTablet) && (
          <React.Fragment>
            <div className="col-12 col-sm-12 col-md-4">
              <span className="details-panel-item-label">{label}</span>
            </div>
            <div className="col-12 col-sm-12 col-md-8 text-right">
              <div className="details-panel-item-content ">{children}</div>
            </div>
          </React.Fragment>
        )}
      </div>
    )}
    {!label && (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">{children}</div>
      </div>
    )}
  </StyledDetailsPanelItem>
);

DetailsPanelItem.defaultProps = {
  borderless: false,
  label: null
};

export default DetailsPanelItem;
