import React from "react";

import Loader from "../Loader";

const TableContentLoader = () => (
  <Loader width="800" height="300">
    <rect x="10" y="10" width="20" height="20" rx="3" />
    <rect x="40" y="10" width="750" height="20" rx="3" />

    <rect x="10" y="55" width="20" height="20" rx="3" />
    <rect x="40" y="55" width="750" height="20" rx="3" />

    <rect x="10" y="100" width="20" height="20" rx="3" />
    <rect x="40" y="100" width="750" height="20" rx="3" />

    <rect x="10" y="145" width="20" height="20" rx="3" />
    <rect x="40" y="145" width="750" height="20" rx="3" />

    <rect x="10" y="190" width="20" height="20" rx="3" />
    <rect x="40" y="190" width="750" height="20" rx="3" />
  </Loader>
);

export default TableContentLoader;
