import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Icon from "../../Icon";

const StyledPaginationPrevious = styled.li`
  & .page-link {
    padding: 8px 8px;
    color: ${theme.neutral4};
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  & .page-link:hover {
    background-color: ${theme.white};
    border-color: ${theme.neutral3};
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.disabled .page-link {
    color: ${theme.neutral2};
    color: ${theme.neutral2};
    background-color: ${theme.white};
    cursor: not-allowed;
  }

  &.disabled .page-link:hover {
    background-color: ${theme.white};
    cursor: not-allowed;
  }
`;
const PaginationPrevious = props => (
  <StyledPaginationPrevious
    className={`page-item ${props.disabled ? "disabled" : ""} ${
      props.active ? "active" : ""
    }`}
  >
    <span className="page-link" onClick={e => props.linkHandler(e, props.page)}>
      <Icon icon="chevron_left" />
    </span>
  </StyledPaginationPrevious>
);

export default PaginationPrevious;
