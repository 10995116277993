import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

import InputHelpBlock from "../InputField/InputHelpBlock";

const StyledRadiobox = styled.div`
  & {
    cursor: pointer;
  }
  & .custom-control-label {
    color: ${theme.neutralBase};
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
  }
  & .custom-control-label:before {
    background-color: ${theme.white};
    border: 1px solid ${theme.neutral3};
    border-radius: 50%;
    width: 18px;
    height: 18px;
    top: 3px;
  }

  &:hover .custom-control-label:before {
    background-color: ${theme.neutral1};
    border: 1px solid ${theme.neutral3};
  }

  &:focus .custom-control-label:before {
    background-color: ${theme.white};
    border: 1px solid ${theme.neutral3};
    box-shadow: inset 0 0 0 2px ${theme.blue3};
  }

  & .custom-control-input:checked ~ .custom-control-label:after {
    background-image: none;
    font-family: "icomoon" !important;
    content: "\\e92b";
    font-size: 16px;
    color: ${theme.white};
    position: absolute;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    top: 4px;
    left: -22.9px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  //& .custom-control-input:indeterminate ~ .custom-control-label:after {
  //  content: "\\e92a";
  //}

  & .custom-control-input:checked ~ .custom-control-label:before {
    background-color: ${theme.blueBase};
    border: 1px solid ${theme.blueBase};
  }

  &:hover .custom-control-input:checked ~ .custom-control-label:before {
    background-color: ${theme.blue4};
  }

  & .custom-control-input:disabled,
  & .custom-control-input:disabled ~ .custom-control-label {
    cursor: not-allowed;
  }
  & .custom-control-input:disabled ~ .custom-control-label:before {
    background-color: ${theme.neutral1};
    border: 1px solid ${theme.neutral3};
    cursor: not-allowed;
  }

  &:disabled
    .custom-control-input:checked:disabled
    ~ .custom-control-label:before,
  & .custom-control-input:checked:disabled ~ .custom-control-label:before,
  &:disabled .custom-control-input:checked:disabled,
  & .custom-control-input:checked:disabled {
    background-color: ${theme.neutral2};
    border: 1px solid ${theme.neutral2};
    cursor: not-allowed;
  }

  &:disabled
    .custom-control-input:checked:disabled
    ~ .custom-control-label:after,
  & .custom-control-input:checked:disabled ~ .custom-control-label:after,
  &:disabled .custom-control-input:checked:disabled,
  & .custom-control-input:checked:disabled {
    cursor: not-allowed;
  }
`;

StyledRadiobox.defaultProps = {
  id: "",
  value: "",
  className: "",
  name: ""
};

class Radiobox extends React.Component {
  onChange = e => {
    if (this.props.disabled) return;
    if (typeof this.props.onChange === "function") {
      this.props.onChange(this.props.value);
    }
  };

  onToggle = e => {
    e.preventDefault();
    this.onChange(e);
  };

  render() {
    const { children, ...rest } = this.props;
    return (
      <StyledRadiobox
        onClick={e => this.onToggle(e)}
        className={`custom-control custom-radio ${rest.className}`}
      >
        <input
          className="custom-control-input"
          type="radio"
          name={rest.name}
          value={rest.value}
          checked={rest.checked}
          id={rest.id}
          onChange={this.onChange}
          disabled={rest.disabled}
        />
        <label
          className="custom-control-label"
          htmlFor={rest.id}
          onClick={e => this.onToggle(e)}
        >
          {children}
        </label>
        {rest.helpText && <InputHelpBlock>{rest.helpText}</InputHelpBlock>}
      </StyledRadiobox>
    );
  }
}

export default Radiobox;
