import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { withSizes } from "react-sizes";
import isLaptop from "../../../../utils/isLaptopScreen";
import { isMobile, isBrowser } from "react-device-detect";
import { Translate } from "react-localize-redux";

import { Form, Field } from "react-final-form";

import AmountBadge from "../../../../components/AmountBadge";
import InputField from "../../../../components/InputField";
import Label from "../../../../components/Label";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";

import withLoading from "../../../../components/Loaders/WithLoading";
import ApplyCreditLoader from "../../../../components/Loaders/ApplyCreditLoader";
import CustomTooltip from "../../../../components/CustomTooltip";
import Checkbox from "../../../../components/Checkbox";
import Icon from "../../../../components/Icon";

const StyledApplyCredit = styled.div`
  padding: 30px;
`;

const StyledAvailableCreditWrapper = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  color: ${theme.neutralBase};
  font-size: 16px;
  padding-bottom: 15px;
`;

const StyledApplyCreditFormWrapper = styled.div`
  padding-top: 20px;

  & .amount-label {
    padding-top: 8px;
  }
`;


const StyledApplyCreditWithLoading = withLoading(
  StyledApplyCredit,
  ApplyCreditLoader
);

const normalizeAmount = (value, currency) => {
  if (value === "" || value === 0) {
    return undefined;
  }

  if (currency !== "мкд") {
    if (!value.match(/^(([0-9])+([\.]){0,1}([0-9]){0,2})$/gm)) {
      if (isNaN(value)) {
        return undefined;
      }

      return parseFloat(value).toFixed(2);
    }
  } else {
    if (!value.match(/^[^\d]+$/gm)) {
      if (isNaN(value)) {
        return undefined;
      }

      return parseInt(value, 10);
    }
  }

  if (!value.match(/^(([0-9])+([\.]){0,1}([0-9]){0,2})$/gm)) {
    if (isNaN(value)) {
      return undefined;
    }

    if (currency !== "mk") {
      return parseFloat(value).toFixed(2);
    }
    return parseInt(value, 10);
  }
  return value;
};

const ApplyCredit = ({
  availableCredit,
  isFetchingCredit,
  currency,
  onApplyCredit,
  validate,
  applyCreditSubmitting,
  disableForm,
  useAmount,
  ...rest
}) => (
  <Translate>
    {({ translate }) => (
      <StyledApplyCreditWithLoading isLoading={isFetchingCredit}>
        <StyledAvailableCreditWrapper className="row">
          <div className="col-12 col-sm-12 col-md-6">
            {translate("pro-invoices.view.available-credit")}
          </div>
          <div className="col-12 col-sm-12 col-md-6">
            <AmountBadge
              className="text-md-right"
              currency={currency}
              amount={availableCredit}
            />
          </div>
        </StyledAvailableCreditWrapper>
        <Form
          onSubmit={onApplyCredit}
          validate={validate}
          initialValues={{
            credit:
              availableCredit > 0
                ? currency === "eur"
                  ? useAmount.toFixed(2)
                  : useAmount.toFixed(0)
                : undefined
          }}
          render={({
            handleSubmit,
            form,
            submitting,
            values,
            pristine,
            invalid
          }) => {
            let colLabelSize = "col-12 col-sm-12 col-md-12";
            let colInputSize = "col-12 col-sm-12 col-md-12";
            let colButtonSize = "col-12 col-sm-12 col-md-12 text-right";
            if (!isMobile && isBrowser && rest.isLaptop) {
              colLabelSize = "col-12 col-sm-12 col-md-12";
              colInputSize = "col-12 col-sm-12 col-md-12";
              colButtonSize = "col-12 col-sm-12 col-md-12 text-right";
            } else if (!isMobile && isBrowser && !rest.isLaptop) {
              colLabelSize = "col-12 col-sm-12 col-md-12";
              colInputSize = "col-12 col-sm-12 col-md-12";
              colButtonSize = "col-12 col-sm-12 col-md-12 text-right";
            }
            return (
              <form
                onSubmit={e => {
                  handleSubmit(e).then(form.reset);
                }}
              >
                <StyledApplyCreditFormWrapper className="row">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }} className={colLabelSize}>
                    <Label className="amount-label">
                      {translate("pro-invoices.view.amount")}
                    </Label>
                    {currency === "eur" &&  <CustomTooltip content={translate("credit-info")}>
                        <Icon size="l" icon="info" />
                      </CustomTooltip>
          }
                  </div>
                  <div className={colInputSize}>
                    <Field
                      component={InputField}
                      parse={value => normalizeAmount(value, currency)}
                      normalize={value => normalizeAmount(value, currency)}
                      name="credit"
                      placeholder={`${currency.toUpperCase()} ${
                        currency === "eur"
                          ? useAmount.toFixed(2)
                          : useAmount.toFixed(0)
                      }`}
                      disabled={disableForm}
                 
                    />
                    
                  </div>
                       
                  <div className={'checkbox-inline'}>
                    <SecondaryButton
                      disabled={submitting || invalid || disableForm}
                      submitting={submitting}
                      type="submit"
                    >
                      {translate("pro-invoices.view.apply-credit")}

                     
                    </SecondaryButton>
                 
                  </div>
             
                </StyledApplyCreditFormWrapper>
            
              </form>
            );
          }}
        />
      </StyledApplyCreditWithLoading>
    )}
  </Translate>
);

export default withSizes(isLaptop)(ApplyCredit);
