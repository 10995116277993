import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FORM_ERROR } from "final-form";
import styled from "styled-components";
import qs from "query-string";

import {
  isMobileOnly,
  MobileOnlyView,
  TabletView,
  BrowserView
} from "react-device-detect";
import withSizes from "react-sizes";
import isLaptop from "../../utils/isLaptopScreen";
import { Translate, withLocalize } from "react-localize-redux";

import {
  fetchCart,
  attachServiceToProduct,
  removeServiceFromProduct,
  removeProductFromCart,
  updateProduct,
  updateDomainEPPCode,
  changeDomainEPPCode,
  clearCart,
  applyPromotionCode,
  clearPromotionCode,
  applyReferrerCode,
  clearReferrerCode,
  addProductToCart,
  blockCheckoutByProduct,
  unblockCheckoutByProduct
} from "./actions/cartActions";
import {
  getItems,
  getCartId,
  isRequestingCart,
  getPromotionCode,
  getReferrerCode,
  getTotalDiscount,
  isApplyingPromotion,
  isCleaningPromotion,
  isApplyingReferrer,
  isCleaningReferrer,
  getTotal,
  isCheckoutBlocked,
  firstCheckoutBlockingProduct
} from "./reducers/cartReducer";

import { fetchDomains } from "../Domains/actions/domainsActions";
import { getDomainsPricing } from "../Domains/reducers/domainsReducer";

import { fetchProductsByGroup } from "../Products/actions/productsActions";
import {
  getProductsListingByGroup,
  isFetchingProducts
} from "../Products/reducers/productsReducer";

import {
  getAuthToken,
  getTwoFactorPhoneNumber,
  isAuthenticated,
  isPhoneNumberRequired,
  isTwoFactorEnabled
} from "../Authentication/reducers/authReducer";
import { getCustomerCurrencyCode } from "../Customer/reducers/customerReducer";

import { getCountriesFormatted } from "../Addressing/reducers/addressingReducer";

import { fetchCustomerPersonalInformation } from "../Customer/actions/customerActions";

import {
  login,
  register,
  updateRegisterDetails
} from "../Authentication/actions/authActions";
import {
  validateEmail,
  validatePassword,
  validateFirstName,
  validateLastName,
  validatePhoneNumber,
  validateMkPhoneNumber,
  validateTaxNumber,
  validateTermsAndConditions,
  validatePersonalDataIsCorrect,
  validateDomain,
  validateCheckbox,
  validateCountry,
  validateLoginPassword
} from "../../common/validationRules";

import { ROUTE_CART } from "../../routes/routes";

import Breadcrumb from "../../components/Breadcrumb";
import BreadcrumbItem from "../../components/Breadcrumb/BreadcrumbItem";
import Modal from "../../components/Modal";
import ActionModal from "../../components/ActionModal";
import Radiobox from "../../components/Radiobox";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

import CartProductsList from "./CartProductsList";
import CompactCartSummary from "./CompactCartSummary";
import WizardBreadcrumbs from "./CartWizard/WizardBreadcrumbs";
import CheckoutCartProductsList from "../Checkout/CheckoutCartProductsList";
import CartEmptyState from "./CartEmptyState";
import CartAddMoreDomains from "./CartAddMoreDomains";
import CartAddHostingToDomain from "./CartAddHostingToDomain";
import CartAddHostingToDomainHostingPlans from "./CartAddHostingToDomain/CartAddHostingToDomainHostingPlans";
import CartAddHostingForMultipleDomains from "./CartAddHostingForMultipleDomains";
import CartAddHostingForMultipleDomainsHostingPlans from "./CartAddHostingForMultipleDomains/CartAddHostingForMultipleDomainsHostingPlans";

import LoginRegister from "./CartWizard/LoginRegister";
import AccountDetails from "./CartWizard/AccountDetails";

import withLoading from "../../components/Loaders/WithLoading";
import ContentLoader from "../../components/Loaders/ContentLoader";

import {
  ROUTE_CART_CHECKOUT,
  ROUTE_SHOP_DOMAINS,
  ROUTE_SHOP_HOSTING,
  ROUTE_SHOP_SERVERS
} from "../../routes/routes";

import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";
import * as referralService from "../../services/referralService";

const WIZARD_STEP_VIEW_CART = "VIEW_CART";
const WIZARD_STEP_LOGIN = "LOGIN";
const WIZARD_STEP_DETAILS = "DETAILS";
const WIZARD_STEP_SUMMARY = "SUMMARY";
const WIZARD_STEP_CONFIRM = "CONFIRM";

const WIZARD_STEP_FROM_URL = {
  cart: 0,
  login: 1,
  details: 2,
  summary: 3
};

const StyledCartWrapper = styled.div`
  margin-bottom: ${props => (props.isMobile ? "100px" : "30px")};
`;
const StyledCart = styled.div`
  padding-top: 0px;
`;

const StyledFreeDomainsWrapper = styled.div`
  margin-bottom: 40px;
`;
const StyledRadiobox = styled(Radiobox)`
  margin-bottom: 10px;
`;

class Cart extends React.Component {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);

    const wizardStep = urlParams.get("step")
      ? WIZARD_STEP_FROM_URL[urlParams.get("step")]
      : 0;

    this.state = {
      loggedInThroughCart: false,
      customer: null,
      credentials: null,
      updatingDomain: false,
      updatingEPPCode: false,
      submitting: false,
      deletingProductId: 0,
      addingDomainFromUrl: false,
      updatingHostingPeriod: false,
      updateHostingPeriodId: 0,
      showAssignHostingToProduct: false,
      domainToAssignHostingTo: null,
      assignedHostingToDomain: false,
      showAssignHostingForMultipleDomains: false,
      hostingToAssignDomainTo: null,
      showFreeDomainsModal: false,
      availableDomains: null,
      hostings: null,
      reCaptchaToken: null,
      formsBlockingCheckout: [],
      authToken: null,
      wizard: {
        activeStep: wizardStep,
        steps: [
          {
            id: WIZARD_STEP_VIEW_CART,
            title: "cart.wizard.view-cart",
            component: () => (
              <CartProductsList
                products={this.props.items}
                onAttachServiceToProduct={this.onAttachServiceToProduct}
                onRemoveServiceFromProduct={this.onRemoveServiceFromProduct}
                onRemoveProductFromCart={this.onRemoveProductFromCart}
                onUpdateProductPeriod={this.onUpdateProductPeriod}
                onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                updatingHostingPeriod={this.state.updatingHostingPeriod}
                updatingHostingPeriodId={this.state.updatingHostingPeriodId}
                updatingDomain={this.state.updatingDomain}
                onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                updatingEppCode={this.state.updatingEppCode}
                onBlockCheckout={this.onBlockCheckout}
              />
            ),
            onNextStep: this.onNextStep,
            visible: true
          },
          {
            id: WIZARD_STEP_LOGIN,
            title: "cart.wizard.login-signup",
            component: () => (
              <LoginRegister
                twoFactorEnabled={this.props.twoFactorEnabled}
                twoFactorPhoneNumber={this.props.twoFactorPhoneNumber}
                phoneNumberRequired={this.props.isPhoneNumberRequired}
                authToken={this.state.authToken}
                onLogin={this.onLogin}
                onRegister={this.onRegister}
                validateLogin={this.validateLogin}
                validateRegister={this.validateRegister}
                onSetReCaptchaToken={this.onSetReCaptchaToken}
              />
            ),
            onNextStep:
              this.state && this.state.loggedInThroughCart
                ? this.onNextStep
                : false,
            visible: true
          },
          {
            id: WIZARD_STEP_DETAILS,
            title: "cart.wizard.account-details",
            component: () => (
              <AccountDetails
              activeLanguage={this.props.activeLanguage}
                contact={this.state.customer}
                validate={this.validateAccountDetails}
                onSubmit={this.onUpdateRegisterDetails}
                onGoBack={this.onPreviousStep}
                countries={this.props.countries}
                onSetReCaptchaToken={this.onSetReCaptchaToken}
              />
            ),
            onNextStep:
              this.state && this.state.loggedInThroughCart
                ? this.onNextStep
                : false,
            visible: true
          },
          {
            id: WIZARD_STEP_SUMMARY,
            title: "cart.wizard.cart-summary",
            component: () => (
              <CheckoutCartProductsList
                products={this.props.items}
                onAttachServiceToProduct={this.onAttachServiceToProduct}
                onRemoveServiceFromProduct={this.onRemoveServiceFromProduct}
                onRemoveProductFromCart={this.onRemoveProductFromCart}
                onUpdateProductPeriod={this.onUpdateProductPeriod}
                updatingDomain={this.state.updatingDomain}
                onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                updatingEppCode={this.state.updatingEppCode}
                onBlockCheckout={this.onBlockCheckout}
              />
            ),
            onNextStep: this.onNextStep,
            visible: true
          },
          {
            id: WIZARD_STEP_CONFIRM,
            title: "cart.wizard.confirm-order",
            component: () => <div>confirm order</div>,
            onNextStep: false,
            visible: true
          }
        ]
      }
    };
    this.quoteRef = null;
  }

  componentDidMount() {
    if (!this.props.items || !this.props.items.length) {
      this.props.fetchCart().then(() => {
        const params = qs.parse(this.props.location.search);
        if (params.buy && params.buy !== "") {
          if (validateDomain(params.buy) === undefined) {
            this.onAddToCartFromUrl(
              params.buy,
              params.period !== undefined ? parseInt(params.period) : 1,
              "register"
            );
          }
        }
      });
    }
    this.props.fetchDomains();

    if (
      (!this.props.hostings || this.props.hostings.length === 0) &&
      !this.props.isFetchingHostings
    ) {
      this.props.fetchProductsByGroup();
    }
    if (this.props.items.length) {
      if (referralService._getReferrerCode()) {
        this.applyReferrerCode(referralService._getReferrerCode());
      }
    }
  }

  onAddToCartFromUrl = (domain, period, type) => {
    this.setState({ addingDomainFromUrl: true });
    this.props
      .addProductToCart({
        type: "domain",
        domain_id: null,
        domain: domain,
        quantity: 1,
        config: {
          type: type || "register",
          period: period || 1
        }
      })
      .then(
        result => {
          displayToastMessageForResponse(
            this.props.translate("cart.shop"),
            result,
            ROUTE_CART,
            this.props.translate("cart.view-cart")
          );

          this.setState({ addingDomainFromUrl: false });
        },
        err => {
          this.setState({ addingDomainFromUrl: false });
        }
      )
      .catch(err => this.setState({ addingDomainFromUrl: false }));
  };

  onAssignHostingToDomain = hosting => {
    this.setState({
      assignedHostingToDomain: true
    });
    this.props
      .addProductToCart({
        cart: this.props.cart ? this.props.cart.id : null,
        type: "hosting",
        quantity: 1,
        config: {
          type: "buy",
          period: hosting.pricing.buy.billing_cycle.derivations[0].numeric_code
        },
        domain: this.state.domainToAssignHostingTo,
        hosting_id: hosting.id
      })
      .then(
        result => {
          this.onHideAssignHostingToDomain();
          this.setState({
            assignedHostingToDomain: false
          });

          displayToastMessageForResponse(
            this.props.translate("shop.shop"),
            result,
            ROUTE_CART,
            this.props.translate("shop.view-cart")
          );
        },
        error => {
          displayToastMessageForResponse(
            this.props.translate("shop.shop"),
            error
          );
          this.onHideAssignHostingToDomain();
          this.setState({
            assignedHostingToDomain: false
          });
        }
      )
      .catch(errorResult => {
        displayToastMessageForResponse(
          this.props.translate("shop.shop"),
          errorResult
        );
        this.onHideAssignHostingToDomain();
        this.setState({
          assignedHostingToDomain: false
        });
      });
  };

  onAssignDomainToHosting = domain => {
    this.setState({
      assignedHostingToDomain: true
    });
    this.props
      .addProductToCart({
        cart: this.props.cart ? this.props.cart.id : null,
        type: "hosting",
        quantity: 1,
        config: {
          type: "buy",
          period: this.state.hostingToAssignDomainTo.pricing.buy.billing_cycle
            .derivations[0].numeric_code
        },
        domain: domain,
        hosting_id: this.state.hostingToAssignDomainTo.id
      })
      .then(
        result => {
          this.onHideAssignHostingToDomain();
          this.setState({
            assignedHostingToDomain: false,
            showFreeDomainsModal: false,
            showAssignHostingForMultipleDomains: false
          });

          displayToastMessageForResponse(
            this.props.translate("shop.shop"),
            result,
            ROUTE_CART,
            this.props.translate("shop.view-cart")
          );
        },
        error => {
          displayToastMessageForResponse(
            this.props.translate("shop.shop"),
            error
          );
          this.onHideAssignHostingToDomain();
          this.setState({
            assignedHostingToDomain: false
          });
        }
      )
      .catch(errorResult => {
        displayToastMessageForResponse(
          this.props.translate("shop.shop"),
          errorResult
        );
        this.onHideAssignHostingToDomain();
        this.setState({
          assignedHostingToDomain: false
        });
      });
  };

  onAttachServiceToProduct = (service, product) => {
    this.props
      .attachServiceToProduct(service, product)
      .then(response =>
        displayToastMessageForResponse(
          this.props.translate("cart.title"),
          response
        )
      );
  };

  onRemoveServiceFromProduct = (service, product) => {
    this.props
      .removeServiceFromProduct(service, product)
      .then(response =>
        displayToastMessageForResponse(
          this.props.translate("cart.title"),
          response
        )
      );
  };

  onRemoveProductFromCart = product => {
    this.setState({
      deletingProductId: product.id
    });
    this.props
      .removeProductFromCart({
        cart: this.props.cartId,
        item_id: product.id
      })
      .then(response => {
        displayToastMessageForResponse(
          this.props.translate("cart.title"),
          response
        );
        this.setState({
          deletingProductId: 0
        });
      });
  };

  onUpdateProductPeriod = (product, values) => {
    this.setState({
      updatingDomain: true
    });
    this.props
      .updateProduct(product, product.config.type, values.code)
      .then(result => {
        this.setState({
          updatingDomain: false
        });
      });
  };

  onUpdateHostingPeriod = (hosting, period) => {
    this.setState({
      updatingHostingPeriod: true,
      updatingHostingPeriodId: hosting.id
    });
    this.props.updateProduct(hosting, hosting.config.type, period).then(() => {
      this.setState({
        updatingHostingPeriod: false,
        updatingHostingPeriodId: 0
      });
    });
  };

  onGoToCheckout = () => {
    if (this.props.isCheckoutBlocked) {
      displayToastMessageForResponse(
        this.props.translate("cart.checkout.title"),
        {
          error: true,
          messages: {
            0: this.props.translate("cart.checkout.products-require-data")
          }
        }
      );

      const form = document.getElementById(
        this.props.firstCheckoutBlockingProduct
      );

      try {
        form.scrollIntoView({
          behavior: "smooth",
          block: "center"
        });
      } catch (e) {
        form.scrollIntoView(false);
      }

      return;
    } else {
      this.props.history.push(ROUTE_CART_CHECKOUT);
    }
  };

  onClearCart = () => {
    this.props.clearCart();
  };

  onNextStep = () => {
    this.setState(
      {
        ...this.state,
        wizard: {
          ...this.state.wizard,
          activeStep:
            this.state.wizard.activeStep < this.state.wizard.steps.length - 1
              ? this.state.wizard.activeStep + 1
              : this.state.wizard.activeStep
        }
      },
      () => {
        // window.scrollTo({ top: 0, behavior: "smooth" });
        if (this.quoteRef) {
          try {
            this.quoteRef.scrollIntoView({
              behavior: "smooth",
              block: "center"
            });
          } catch (e) {
            this.quoteRef.scrollIntoView(false);
          }
        }
      }
    );
  };

  onPreviousStep = () => {
    this.setState({
      ...this.state,
      wizard: {
        ...this.state.wizard,
        activeStep:
          this.state.wizard.activeStep > 0
            ? this.state.wizard.activeStep - 1
            : this.state.wizard.activeStep
      }
    });
  };

  validateLogin = values => {
    return {
      email: validateEmail(values.email),
      password: validateLoginPassword(values.password)
    };
  };

  validateRegister = values => {
    return {
      email: validateEmail(values.email),
      password: validatePassword(values.password),
      pp: validateCheckbox(values.pp, "accept-privacy-policy-global"),
      pp2: validateCheckbox(values.pp2, "accept-privacy-policy-global")
    };
  };

  validateAccountDetails = values => {
    return {
      email: validateEmail(values.email),
      first_name: validateFirstName(values.first_name),
      last_name: validateLastName(values.last_name),
      tax_number: validateTaxNumber(values.tax_number),
      phone: validateMkPhoneNumber(values.phone),
      country: validateCountry(values.country.value)
      // terms: validateTermsAndConditions(values.terms),
      // valid_personal_data: validatePersonalDataIsCorrect(
      //   values.valid_personal_data
      // )
    };
  };

  onLogin = values => {
    const registerTrue = false;
    return this.props
      .login(
        values.email,
        values.password,
        null,
        null,
        this.state.reCaptchaToken,
        null
      )
      .then(
        result => {
          this.setState({ authToken: result.token });

          if (result.twoFactor) {
            this.props.fetchCart();

            this.props.history.push(ROUTE_CART + "?code=" + result.token);
          } else {
            // return this.props.fetchCustomerPersonalInformation().then(() => {
              this.props.fetchCart();
              this.props.history.push(ROUTE_CART);
            // });
  }
        },
        err => {
          const { messages } = err.response.data.data;
          if (window.grecaptcha) {
            window.grecaptcha
              .execute(process.env.REACT_APP_MKHOST_GOOGLE_RECAPTCHA_KEY)
              .then(newToken => {
                this.onSetReCaptchaToken(newToken);
              });
          }
          return {
            [FORM_ERROR]: messages[0]
          };
        }
      )
      .catch(error => {
        const { messages } = error.response.data.data;
        if (window.grecaptcha) {
          window.grecaptcha
            .execute(process.env.REACT_APP_MKHOST_GOOGLE_RECAPTCHA_KEY)
            .then(newToken => {
              this.onSetReCaptchaToken(newToken);
            });
        }
        return {
          [FORM_ERROR]: messages[0]
        };
      });
  };

  onRegister = values => {
    this.setState({
      submitting: true
    });
    return this.props
      .register(
        values.email,
        values.password,
        this.props.cartId,
        this.state.reCaptchaToken
      )
      .then(
        customer => {
          this.setState({
            submitting: false,
            customer: {
              terms: null,
              ...customer
            },
            credentials: {
              email: values.email,
              password: values.password
            },
            wizard: {
              activeStep: 1,
              steps: this.state.wizard.steps.filter(
                step => step.id !== WIZARD_STEP_LOGIN
              )
            }
          });
        },
        err => {
          if (err) {
            const { messages } = err.data.data;
            if (window.grecaptcha) {
              window.grecaptcha
                .execute(process.env.REACT_APP_MKHOST_GOOGLE_RECAPTCHA_KEY)
                .then(newToken => {
                  this.onSetReCaptchaToken(newToken);
                });
            }
            return {
              [FORM_ERROR]: messages[0]
            };
          }
        }
      );
  };

  onSetReCaptchaToken = token => {
    this.setState({
      ...this.state,
      reCaptchaToken: token
    });
  };

  onUpdateRegisterDetails = values => {
    this.setState({
      submitting: true
    });
    return this.props
      .updateRegisterDetails({ ...values, country_id: values.country.value })
      .then(customer => {
        const registerTrue=true;
        this.props
          .login(
            this.state.credentials.email,
            this.state.credentials.password,
            null,
            null,
            this.state.reCaptchaToken,
            registerTrue
          )
          .then(() => {
            this.props.fetchCustomerPersonalInformation();
            this.props.fetchCart();
            // this.props.history.push(ROUTE_CART);
          });
      });
  };

  onChangeDomainEPPCode = (domain, code) => {
    this.props.changeDomainEPPCode(domain, code);
  };

  onUpdateDomainEPPCode = (domain, code) => {
    this.setState({
      updatingEPPCode: true
    });
    return this.props
      .updateDomainEPPCode(domain, code)
      .then(
        result => {
          this.setState({
            updatingEPPCode: false
          });
          return result;
        },
        err => {
          const { error } = err.response.data;
          const { messages } = err.response.data.data;

          displayToastMessageForResponse(this.props.translate("cart.title"), {
            error,
            messages
          });
          this.setState({
            updatingEPPCode: false
          });

          return err;
        }
      )
      .catch(err => {
        const { error } = err.response.data;
        const { messages } = err.response.data.data;

        displayToastMessageForResponse(this.props.translate("cart.title"), {
          error,
          messages
        });
        this.setState({
          updatingEPPCode: false
        });

        return err;
      });
  };

  applyPromotionCode = value => {
    if (value !== "") {
      this.props
        .applyPromotionCode(value)
        .then(response =>
          displayToastMessageForResponse(
            <Translate>{({ translate }) => translate("cart.title")}</Translate>,
            response
          )
        );
    }
  };

  clearPromotionCode = value => {
    this.props
      .clearPromotionCode()
      .then(response =>
        displayToastMessageForResponse(
          <Translate>{({ translate }) => translate("cart.title")}</Translate>,
          response
        )
      );
  };
  applyReferrerCode = value => {
    if (value !== "") {
      this.props.applyReferrerCode(value).then(response => {
        displayToastMessageForResponse(
          <Translate>{({ translate }) => translate("cart.title")}</Translate>,
          response
        );
      });
    }
  };

  clearReferrerCode = value => {
    this.props
      .clearReferrerCode()
      .then(response =>
        displayToastMessageForResponse(
          <Translate>{({ translate }) => translate("cart.title")}</Translate>,
          response
        )
      );
  };

  onShowAssignHostingToDomain = domain => {
    this.setState({
      showAssignHostingToProduct: true,
      domainToAssignHostingTo: domain
    });
  };

  onShowAssignHostingForMultipleDomains = () => {
    this.setState({
      showAssignHostingForMultipleDomains: true
    });
  };

  onHideAssignHostingForMultipeDomains = () => {
    this.setState({
      showAssignHostingForMultipleDomains: false,
      showFreeDomainsModal: false
    });
  };

  onAssignHostingForMultipleDomains = hosting => {
    this.setState({
      showAssignHostingForMultipleDomains: false,
      hostingToAssignDomainTo: hosting,
      showFreeDomainsModal: true
    });
  };

  onHideAssignHostingToDomain = () => {
    this.setState({
      showAssignHostingToProduct: false,
      domainToAssignHostingTo: null
    });
  };

  onHideFreeDomainsModal = () => {
    this.setState({
      showFreeDomainsModal: false
    });
  };

  onSelectFreeDomain = domain => {
    this.setState({
      domainToAssignHostingTo: domain
    });
  };

  onBackToHostings = () => {
    this.setState({
      showFreeDomainsModal: false,
      showAssignHostingForMultipleDomains: true
    });
  };

  onBlockCheckout = ({ formId, invalid, valid, submitSucceeded }) => {
    if (invalid === true && valid === false && submitSucceeded === false) {
      if (
        this.state.formsBlockingCheckout.find(id => id === formId) === undefined
      ) {
        this.props.blockCheckoutByProduct(formId);
      }
    } else if (
      valid === true &&
      invalid === false &&
      submitSucceeded === true
    ) {
      this.props.unblockCheckoutByProduct(formId);
    }
  };

  render() {
    const urlParams = new URLSearchParams(window.location.search);

    const wizardStep = urlParams.get("step")
      ? WIZARD_STEP_FROM_URL[urlParams.get("step")]
      : 0;

    // console.log(
    //   "WIZARD STRASTE",
    //   wizardStep,
    //   urlParams.get("step"),
    //   this.state.wizard,
    //   this.state.wizard.activeStep
    // );

    const activeStep =
      this.state.wizard && this.state.wizard.activeStep
        ? this.state.wizard.activeStep
        : wizardStep;

    const ActiveStepPage =
      this.state.wizard && this.state.wizard.steps[activeStep].component;

    const onContinueToCheckout =
      this.state.wizard &&
      this.state.wizard.steps[this.state.wizard.activeStep].onNextStep;

    const CartWithLoader = withLoading(StyledCart, ContentLoader);
    const ActiveStepPageWithLoading = withLoading(
      ActiveStepPage,
      ContentLoader
    );

    let cartDomains =
      this.props.items &&
      this.props.items.filter(item => item.type === "domain").map(item => {
        return item.domain;
      });

    let usedDomains = this.props.items
      .filter(item => item.type === "hosting")
      .map(item => item.domain);

    let availableDomains = cartDomains.filter((value, index, self) => {
      return usedDomains.indexOf(value) === -1;
    });

    return (
      <StyledCartWrapper
        isMobile={isMobileOnly}
        ref={ref => (this.quoteRef = ref)}
      >
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            {this.props.isAuthenticated && (
              <Translate>
                {({ translate }) => (
                  <Breadcrumb>
                    <BreadcrumbItem
                      to="/"
                      title={translate("breadcrumbs.home")}
                    />
                    <BreadcrumbItem title={translate("breadcrumbs.cart")} />
                  </Breadcrumb>
                )}
              </Translate>
            )}
            {this.props.isAuthenticated && (
              <CartWithLoader
                isLoading={
                  this.props.isRequestingCart || this.state.addingDomainFromUrl
                }
              >
                {this.props.items.length > 0 &&
                  !this.state.addingDomainFromUrl ? (
                  <React.Fragment>
                    <MobileOnlyView>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <CartProductsList
                            products={this.props.items}
                            onAttachServiceToProduct={
                              this.onAttachServiceToProduct
                            }
                            onRemoveServiceFromProduct={
                              this.onRemoveServiceFromProduct
                            }
                            onRemoveProductFromCart={
                              this.onRemoveProductFromCart
                            }
                            onUpdateProductPeriod={this.onUpdateProductPeriod}
                            onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                            updatingHostingPeriod={
                              this.state.updatingHostingPeriod
                            }
                            updatingHostingPeriodId={
                              this.state.updatingHostingPeriodId
                            }
                            updatingDomain={this.state.updatingDomain}
                            onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                            onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                            updatingEPPCode={this.state.updatingEPPCode}
                            deletingProductId={this.state.deletingProductId}
                            onShowAssignHostingToDomain={
                              this.onShowAssignHostingToDomain
                            }
                            onBlockCheckout={this.onBlockCheckout}
                          />

                          {this.props.items.length === 1 &&
                            this.props.items[0].type === "domain" && (
                              <CartAddHostingToDomain
                                domain={this.props.items[0].domain}
                                onShowAssignHostingToDomain={
                                  this.onShowAssignHostingToDomain
                                }
                              />
                            )}
                          {this.props.items.length >= 2 &&
                            (!usedDomains || usedDomains.length === 0) &&
                            availableDomains &&
                            availableDomains.length >= 1 && (
                              <CartAddHostingForMultipleDomains
                                onShowAssignHostingForMultipleDomains={
                                  this.onShowAssignHostingForMultipleDomains
                                }
                              />
                            )}

                          {this.props.domainPrices && (
                            <CartAddMoreDomains
                              onSubmit={this.onSearchDomain}
                              extensions={this.props.domainPrices}
                            />
                          )}
                        </div>
                        <div className="col-12 col-sm-12 col-md-12">
                          <CompactCartSummary
                            onContinueCheckout={this.onGoToCheckout}
                            onClearCart={this.onClearCart}
                            customerCurrencyCode={
                              this.props.activeLanguage &&
                                this.props.customerCurrencyCode
                                ? this.props.customerCurrencyCode
                                : this.props.activeLanguage.code === "mk"
                                  ? "мкд"
                                  : "eur"
                            }
                            totalDiscount={this.props.totalDiscount}
                            promotionCode={this.props.promotionCode}
                            isApplyingPromotion={this.props.isApplyingPromotion}
                            applyPromotionCode={this.applyPromotionCode}
                            isCleaningPromotion={this.props.isCleaningPromotion}
                            clearPromotionCode={this.clearPromotionCode}
                            referral_code={this.props.referral_code}
                            isApplyingReferrer={this.props.isApplyingReferrer}
                            applyReferrerCode={this.applyReferrerCode}
                            isCleaningReferrer={this.props.isCleaningReferrer}
                            clearReferrerCode={this.clearReferrerCode}
                            total={this.props.total}
                            disableCheckoutButton={this.props.isCheckoutBlocked}
                          />
                        </div>
                      </div>
                    </MobileOnlyView>
                    <TabletView>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <CartProductsList
                            products={this.props.items}
                            onAttachServiceToProduct={
                              this.onAttachServiceToProduct
                            }
                            onRemoveServiceFromProduct={
                              this.onRemoveServiceFromProduct
                            }
                            onRemoveProductFromCart={
                              this.onRemoveProductFromCart
                            }
                            onUpdateProductPeriod={this.onUpdateProductPeriod}
                            onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                            updatingHostingPeriod={
                              this.state.updatingHostingPeriod
                            }
                            updatingHostingPeriodId={
                              this.state.updatingHostingPeriodId
                            }
                            updatingDomain={this.state.updatingDomain}
                            onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                            onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                            updatingEPPCode={this.state.updatingEPPCode}
                            deletingProductId={this.state.deletingProductId}
                            onShowAssignHostingToDomain={
                              this.onShowAssignHostingToDomain
                            }
                            onBlockCheckout={this.onBlockCheckout}
                          />

                          {this.props.items.length === 1 &&
                            this.props.items[0].type === "domain" && (
                              <CartAddHostingToDomain
                                domain={this.props.items[0].domain}
                                onShowAssignHostingToDomain={
                                  this.onShowAssignHostingToDomain
                                }
                              />
                            )}

                          {this.props.items.length >= 2 &&
                            (!usedDomains || usedDomains.length === 0) &&
                            availableDomains &&
                            availableDomains.length >= 1 && (
                              <CartAddHostingForMultipleDomains
                                onShowAssignHostingForMultipleDomains={
                                  this.onShowAssignHostingForMultipleDomains
                                }
                              />
                            )}
                          {this.props.domainPrices && (
                            <CartAddMoreDomains
                              onSubmit={this.onSearchDomain}
                              extensions={this.props.domainPrices}
                            />
                          )}
                        </div>
                        <div className="col-12 col-sm-12 col-md-12">
                          <CompactCartSummary
                            onContinueCheckout={this.onGoToCheckout}
                            onClearCart={this.onClearCart}
                            customerCurrencyCode={
                              this.props.activeLanguage &&
                                this.props.customerCurrencyCode
                                ? this.props.customerCurrencyCode
                                : this.props.activeLanguage.code === "mk"
                                  ? "мкд"
                                  : "eur"
                            }
                            totalDiscount={this.props.totalDiscount}
                            promotionCode={this.props.promotionCode}
                            isApplyingPromotion={this.props.isApplyingPromotion}
                            applyPromotionCode={this.applyPromotionCode}
                            isCleaningPromotion={this.props.isCleaningPromotion}
                            clearPromotionCode={this.clearPromotionCode}
                            referral_code={this.props.referral_code}
                            isApplyingReferrer={this.props.isApplyingReferrer}
                            applyReferrerCode={this.applyReferrerCode}
                            isCleaningReferrer={this.props.isCleaningReferrer}
                            clearReferrerCode={this.clearReferrerCode}
                            total={this.props.total}
                            disableCheckoutButton={this.props.isCheckoutBlocked}
                          />
                        </div>
                      </div>
                    </TabletView>
                    <BrowserView>
                      {this.props.isLaptop && (
                        <div className="row">
                          <div className="col-8 col-sm-8 col-md-8">
                            <CartProductsList
                              products={this.props.items}
                              onAttachServiceToProduct={
                                this.onAttachServiceToProduct
                              }
                              onRemoveServiceFromProduct={
                                this.onRemoveServiceFromProduct
                              }
                              onRemoveProductFromCart={
                                this.onRemoveProductFromCart
                              }
                              onUpdateProductPeriod={this.onUpdateProductPeriod}
                              onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                              updatingHostingPeriod={
                                this.state.updatingHostingPeriod
                              }
                              updatingHostingPeriodId={
                                this.state.updatingHostingPeriodId
                              }
                              updatingDomain={this.state.updatingDomain}
                              onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                              onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                              updatingEPPCode={this.state.updatingEPPCode}
                              deletingProductId={this.state.deletingProductId}
                              onShowAssignHostingToDomain={
                                this.onShowAssignHostingToDomain
                              }
                              onBlockCheckout={this.onBlockCheckout}
                            />

                            {this.props.items.length === 1 &&
                              this.props.items[0].type === "domain" && (
                                <CartAddHostingToDomain
                                  domain={this.props.items[0].domain}
                                  onShowAssignHostingToDomain={
                                    this.onShowAssignHostingToDomain
                                  }
                                />
                              )}
                            {this.props.items.length >= 2 &&
                              (!usedDomains || usedDomains.length === 0) &&
                              availableDomains &&
                              availableDomains.length >= 1 && (
                                <CartAddHostingForMultipleDomains
                                  onShowAssignHostingForMultipleDomains={
                                    this.onShowAssignHostingForMultipleDomains
                                  }
                                />
                              )}
                            {this.props.domainPrices && (
                              <CartAddMoreDomains
                                onSubmit={this.onSearchDomain}
                                extensions={this.props.domainPrices}
                              />
                            )}
                          </div>
                          <div className="col-4 col-sm-4 col-md-4">
                            <CompactCartSummary
                              onContinueCheckout={this.onGoToCheckout}
                              onClearCart={this.onClearCart}
                              customerCurrencyCode={
                                this.props.activeLanguage &&
                                  this.props.customerCurrencyCode
                                  ? this.props.customerCurrencyCode
                                  : this.props.activeLanguage.code === "mk"
                                    ? "мкд"
                                    : "eur"
                              }
                              totalDiscount={this.props.totalDiscount}
                              promotionCode={this.props.promotionCode}
                              isApplyingPromotion={
                                this.props.isApplyingPromotion
                              }
                              applyPromotionCode={this.applyPromotionCode}
                              isCleaningPromotion={
                                this.props.isCleaningPromotion
                              }
                              clearPromotionCode={this.clearPromotionCode}
                              referral_code={this.props.referral_code}
                              isApplyingReferrer={this.props.isApplyingReferrer}
                              applyReferrerCode={this.applyReferrerCode}
                              isCleaningReferrer={this.props.isCleaningReferrer}
                              clearReferrerCode={this.clearReferrerCode}
                              total={this.props.total}
                              disableCheckoutButton={
                                this.props.isCheckoutBlocked
                              }
                            />
                          </div>
                        </div>
                      )}
                      {!this.props.isLaptop && (
                        <div className="row">
                          <div className="col-12 col-sm-7 offset-sm-1 col-md-7 offset-md-1">
                            <CartProductsList
                              products={this.props.items}
                              onAttachServiceToProduct={
                                this.onAttachServiceToProduct
                              }
                              onRemoveServiceFromProduct={
                                this.onRemoveServiceFromProduct
                              }
                              onRemoveProductFromCart={
                                this.onRemoveProductFromCart
                              }
                              onUpdateProductPeriod={this.onUpdateProductPeriod}
                              onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                              updatingHostingPeriod={
                                this.state.updatingHostingPeriod
                              }
                              updatingHostingPeriodId={
                                this.state.updatingHostingPeriodId
                              }
                              updatingDomain={this.state.updatingDomain}
                              onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                              onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                              updatingEPPCode={this.state.updatingEPPCode}
                              deletingProductId={this.state.deletingProductId}
                              onShowAssignHostingToDomain={
                                this.onShowAssignHostingToDomain
                              }
                              onBlockCheckout={this.onBlockCheckout}
                            />

                            {this.props.items.length === 1 &&
                              this.props.items[0].type === "domain" && (
                                <CartAddHostingToDomain
                                  domain={this.props.items[0].domain}
                                  onShowAssignHostingToDomain={
                                    this.onShowAssignHostingToDomain
                                  }
                                />
                              )}

                            {this.props.items.length >= 2 &&
                              (!usedDomains || usedDomains.length === 0) &&
                              availableDomains &&
                              availableDomains.length >= 1 && (
                                <CartAddHostingForMultipleDomains
                                  onShowAssignHostingForMultipleDomains={
                                    this.onShowAssignHostingForMultipleDomains
                                  }
                                />
                              )}

                            {this.props.domainPrices && (
                              <CartAddMoreDomains
                                onSubmit={this.onSearchDomain}
                                extensions={this.props.domainPrices}
                              />
                            )}
                          </div>
                          <div className="col-12 col-sm-3 col-md-3">
                            <CompactCartSummary
                              onContinueCheckout={this.onGoToCheckout}
                              onClearCart={this.onClearCart}
                              customerCurrencyCode={
                                this.props.activeLanguage &&
                                  this.props.customerCurrencyCode
                                  ? this.props.customerCurrencyCode
                                  : this.props.activeLanguage.code === "mk"
                                    ? "мкд"
                                    : "eur"
                              }
                              totalDiscount={this.props.totalDiscount}
                              promotionCode={this.props.promotionCode}
                              isApplyingPromotion={
                                this.props.isApplyingPromotion
                              }
                              applyPromotionCode={this.applyPromotionCode}
                              isCleaningPromotion={
                                this.props.isCleaningPromotion
                              }
                              clearPromotionCode={this.clearPromotionCode}
                              referral_code={this.props.referral_code}
                              isApplyingReferrer={this.props.isApplyingReferrer}
                              applyReferrerCode={this.applyReferrerCode}
                              isCleaningReferrer={this.props.isCleaningReferrer}
                              clearReferrerCode={this.clearReferrerCode}
                              total={this.props.total}
                              disableCheckoutButton={
                                this.props.isCheckoutBlocked
                              }
                            />
                          </div>
                        </div>
                      )}
                    </BrowserView>
                  </React.Fragment>
                ) : (
                  <CartEmptyState
                    shopDomainsRoute={ROUTE_SHOP_DOMAINS}
                    shopHostingRoute={ROUTE_SHOP_HOSTING}
                    shopServersRoute={ROUTE_SHOP_SERVERS}
                  />
                )}
              </CartWithLoader>
            )}
            {!this.props.isAuthenticated &&
              this.props.items.length > 0 &&
              !this.state.addingDomainFromUrl && (
                <React.Fragment>
                  <MobileOnlyView>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12 mt-4">
                        <WizardBreadcrumbs
                          steps={this.state.wizard.steps}
                          active={this.state.wizard.activeStep}
                        />
                      </div>
                    </div>
                  </MobileOnlyView>
                  <TabletView>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12  mt-4">
                        <WizardBreadcrumbs
                          steps={this.state.wizard.steps}
                          active={this.state.wizard.activeStep}
                        />
                      </div>
                    </div>
                  </TabletView>
                  <BrowserView>
                    {this.props.isLaptop && (
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 mt-4">
                          <WizardBreadcrumbs
                            steps={this.state.wizard.steps}
                            active={this.state.wizard.activeStep}
                          />
                        </div>
                      </div>
                    )}
                    {!this.props.isLaptop && (
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-10 offset-md-1 mt-4">
                          <WizardBreadcrumbs
                            steps={this.state.wizard.steps}
                            active={this.state.wizard.activeStep}
                          />
                        </div>
                      </div>
                    )}
                  </BrowserView>
                </React.Fragment>
              )}

            {!this.props.isAuthenticated && (
              <React.Fragment>
                <MobileOnlyView>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {(this.props.isRequestingCart ||
                        this.state.addingDomainFromUrl) && (
                          <ActiveStepPageWithLoading
                            isLoading={
                              this.props.isRequestingCart ||
                              this.state.addingDomainFromUrl
                            }
                            products={this.props.items}
                            onAttachServiceToProduct={
                              this.onAttachServiceToProduct
                            }
                            onRemoveServiceFromProduct={
                              this.onRemoveServiceFromProduct
                            }
                            onRemoveProductFromCart={this.onRemoveProductFromCart}
                            onUpdateProductPeriod={this.onUpdateProductPeriod}
                            onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                            updatingHostingPeriod={
                              this.state.updatingHostingPeriod
                            }
                            updatingHostingPeriodId={
                              this.state.updatingHostingPeriodId
                            }
                            updatingDomain={this.state.updatingDomain}
                            onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                            onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                            updatingEPPCode={this.state.updatingEPPCode}
                            onShowAssignHostingToDomain={
                              this.onShowAssignHostingToDomain
                            }
                          />
                        )}
                      {!this.props.isRequestingCart &&
                        !this.state.addingDomainFromUrl &&
                        this.props.items.length > 0 && (
                          <ActiveStepPage
                            products={this.props.items}
                            onAttachServiceToProduct={
                              this.onAttachServiceToProduct
                            }
                            onRemoveServiceFromProduct={
                              this.onRemoveServiceFromProduct
                            }
                            onRemoveProductFromCart={
                              this.onRemoveProductFromCart
                            }
                            onUpdateProductPeriod={this.onUpdateProductPeriod}
                            onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                            updatingHostingPeriod={
                              this.state.updatingHostingPeriod
                            }
                            updatingHostingPeriodId={
                              this.state.updatingHostingPeriodId
                            }
                            updatingDomain={this.state.updatingDomain}
                            onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                            onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                            updatingEPPCode={this.state.updatingEPPCode}
                            onShowAssignHostingToDomain={
                              this.onShowAssignHostingToDomain
                            }
                          />
                        )}
                      {!this.props.isRequestingCart &&
                        !this.state.addingDomainFromUrl &&
                        this.props.items.length === 0 && (
                          <CartEmptyState
                            shopDomainsRoute={ROUTE_SHOP_DOMAINS}
                            shopHostingRoute={ROUTE_SHOP_HOSTING}
                            shopServersRoute={ROUTE_SHOP_SERVERS}
                          />
                        )}
                    </div>
                    {this.props.items.length > 0 &&
                      !this.state.addingDomainFromUrl && (
                        <div className="col-12 col-sm-3 col-md-3">
                          <CompactCartSummary
                            onContinueCheckout={onContinueToCheckout}
                            onClearCart={this.onClearCart}
                            customerCurrencyCode={
                              this.props.activeLanguage &&
                                this.props.customerCurrencyCode
                                ? this.props.customerCurrencyCode
                                : this.props.activeLanguage.code === "mk"
                                  ? "мкд"
                                  : "eur"
                            }
                            totalDiscount={this.props.totalDiscount}
                            promotionCode={this.props.promotionCode}
                            isApplyingPromotion={this.props.isApplyingPromotion}
                            applyPromotionCode={this.applyPromotionCode}
                            isCleaningPromotion={this.props.isCleaningPromotion}
                            clearPromotionCode={this.clearPromotionCode}
                            referral_code={this.props.referral_code}
                            isApplyingReferrer={this.props.isApplyingReferrer}
                            applyReferrerCode={this.applyReferrerCode}
                            isCleaningReferrer={this.props.isCleaningReferrer}
                            clearReferrerCode={this.clearReferrerCode}
                            total={this.props.total}
                            disableCheckoutButton={this.props.isCheckoutBlocked}
                          />
                        </div>
                      )}
                  </div>
                </MobileOnlyView>
                <TabletView>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {(this.props.isRequestingCart ||
                        this.state.addingDomainFromUrl) && (
                          <ActiveStepPageWithLoading
                            isLoading={
                              this.props.isRequestingCart ||
                              this.state.addingDomainFromUrl
                            }
                            products={this.props.items}
                            onAttachServiceToProduct={
                              this.onAttachServiceToProduct
                            }
                            onRemoveServiceFromProduct={
                              this.onRemoveServiceFromProduct
                            }
                            onRemoveProductFromCart={this.onRemoveProductFromCart}
                            onUpdateProductPeriod={this.onUpdateProductPeriod}
                            onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                            updatingHostingPeriod={
                              this.state.updatingHostingPeriod
                            }
                            updatingHostingPeriodId={
                              this.state.updatingHostingPeriodId
                            }
                            updatingDomain={this.state.updatingDomain}
                            onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                            onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                            updatingEPPCode={this.state.updatingEPPCode}
                            onShowAssignHostingToDomain={
                              this.onShowAssignHostingToDomain
                            }
                          />
                        )}
                      {!this.props.isRequestingCart &&
                        !this.state.addingDomainFromUrl &&
                        this.props.items.length > 0 && (
                          <ActiveStepPageWithLoading
                            products={this.props.items}
                            onAttachServiceToProduct={
                              this.onAttachServiceToProduct
                            }
                            onRemoveServiceFromProduct={
                              this.onRemoveServiceFromProduct
                            }
                            onRemoveProductFromCart={
                              this.onRemoveProductFromCart
                            }
                            onUpdateProductPeriod={this.onUpdateProductPeriod}
                            onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                            updatingHostingPeriod={
                              this.state.updatingHostingPeriod
                            }
                            updatingHostingPeriodId={
                              this.state.updatingHostingPeriodId
                            }
                            updatingDomain={this.state.updatingDomain}
                            onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                            onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                            updatingEPPCode={this.state.updatingEPPCode}
                            onShowAssignHostingToDomain={
                              this.onShowAssignHostingToDomain
                            }
                          />
                        )}
                      {!this.props.isRequestingCart &&
                        !this.state.addingDomainFromUrl &&
                        this.props.items.length === 0 && (
                          <CartEmptyState
                            shopDomainsRoute={ROUTE_SHOP_DOMAINS}
                            shopHostingRoute={ROUTE_SHOP_HOSTING}
                            shopServersRoute={ROUTE_SHOP_SERVERS}
                          />
                        )}
                    </div>
                    {this.props.items.length > 0 &&
                      !this.state.addingDomainFromUrl && (
                        <div className="col-12 col-sm-12 col-md-12">
                          <CompactCartSummary
                            onContinueCheckout={onContinueToCheckout}
                            onClearCart={this.onClearCart}
                            customerCurrencyCode={
                              this.props.activeLanguage &&
                                this.props.customerCurrencyCode
                                ? this.props.customerCurrencyCode
                                : this.props.activeLanguage.code === "mk"
                                  ? "мкд"
                                  : "eur"
                            }
                            totalDiscount={this.props.totalDiscount}
                            promotionCode={this.props.promotionCode}
                            isApplyingPromotion={this.props.isApplyingPromotion}
                            applyPromotionCode={this.applyPromotionCode}
                            isCleaningPromotion={this.props.isCleaningPromotion}
                            clearPromotionCode={this.clearPromotionCode}
                            referral_code={this.props.referral_code}
                            isApplyingReferrer={this.props.isApplyingReferrer}
                            applyReferrerCode={this.applyReferrerCode}
                            isCleaningReferrer={this.props.isCleaningReferrer}
                            clearReferrerCode={this.clearReferrerCode}
                            total={this.props.total}
                            disableCheckoutButton={this.props.isCheckoutBlocked}
                          />
                        </div>
                      )}
                  </div>
                </TabletView>
                <BrowserView>
                  {this.props.isLaptop && (
                    <div className="row">
                      <div
                        className={
                          this.props.items.length > 0
                            ? "col-12 col-sm-8 col-md-8"
                            : "col-6 offset-3 col-sm-6 offset-sm-3 col-md-6 offset-md-3"
                        }
                      >
                        {(this.props.isRequestingCart ||
                          this.state.addingDomainFromUrl) && (
                            <ActiveStepPageWithLoading
                              isLoading={
                                this.props.isRequestingCart ||
                                this.state.addingDomainFromUrl
                              }
                              products={this.props.items}
                              onAttachServiceToProduct={
                                this.onAttachServiceToProduct
                              }
                              onRemoveServiceFromProduct={
                                this.onRemoveServiceFromProduct
                              }
                              onRemoveProductFromCart={
                                this.onRemoveProductFromCart
                              }
                              onUpdateProductPeriod={this.onUpdateProductPeriod}
                              onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                              updatingHostingPeriod={
                                this.state.updatingHostingPeriod
                              }
                              updatingHostingPeriodId={
                                this.state.updatingHostingPeriodId
                              }
                              updatingDomain={this.state.updatingDomain}
                              onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                              onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                              updatingEPPCode={this.state.updatingEPPCode}
                              onShowAssignHostingToDomain={
                                this.onShowAssignHostingToDomain
                              }
                            />
                          )}
                        {!this.props.isRequestingCart &&
                          !this.state.addingDomainFromUrl &&
                          this.props.items.length > 0 && (
                            <ActiveStepPage
                              products={this.props.items}
                              onAttachServiceToProduct={
                                this.onAttachServiceToProduct
                              }
                              onRemoveServiceFromProduct={
                                this.onRemoveServiceFromProduct
                              }
                              onRemoveProductFromCart={
                                this.onRemoveProductFromCart
                              }
                              onUpdateProductPeriod={this.onUpdateProductPeriod}
                              onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                              updatingHostingPeriod={
                                this.state.updatingHostingPeriod
                              }
                              updatingHostingPeriodId={
                                this.state.updatingHostingPeriodId
                              }
                              updatingDomain={this.state.updatingDomain}
                              onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                              onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                              updatingEPPCode={this.state.updatingEPPCode}
                              onShowAssignHostingToDomain={
                                this.onShowAssignHostingToDomain
                              }
                            />
                          )}
                        {!this.props.isRequestingCart &&
                          !this.state.addingDomainFromUrl &&
                          this.props.items.length === 0 && (
                            <CartEmptyState
                              shopDomainsRoute={ROUTE_SHOP_DOMAINS}
                              shopHostingRoute={ROUTE_SHOP_HOSTING}
                              shopServersRoute={ROUTE_SHOP_SERVERS}
                            />
                          )}
                      </div>
                      {this.props.items.length > 0 &&
                        !this.state.addingDomainFromUrl && (
                          <div className="col-12 col-sm-4 col-md-4">
                            <CompactCartSummary
                              onContinueCheckout={onContinueToCheckout}
                              onClearCart={this.onClearCart}
                              customerCurrencyCode={
                                this.props.activeLanguage &&
                                  this.props.customerCurrencyCode
                                  ? this.props.customerCurrencyCode
                                  : this.props.activeLanguage.code === "mk"
                                    ? "мкд"
                                    : "eur"
                              }
                              totalDiscount={this.props.totalDiscount}
                              promotionCode={this.props.promotionCode}
                              isApplyingPromotion={
                                this.props.isApplyingPromotion
                              }
                              applyPromotionCode={this.applyPromotionCode}
                              isCleaningPromotion={
                                this.props.isCleaningPromotion
                              }
                              clearPromotionCode={this.clearPromotionCode}
                              referral_code={this.props.referral_code}
                              isApplyingReferrer={this.props.isApplyingReferrer}
                              applyReferrerCode={this.applyReferrerCode}
                              isCleaningReferrer={this.props.isCleaningReferrer}
                              clearReferrerCode={this.clearReferrerCode}
                              total={this.props.total}
                              disableCheckoutButton={
                                this.props.isCheckoutBlocked
                              }
                            />
                          </div>
                        )}
                    </div>
                  )}
                  {!this.props.isLaptop && (
                    <div className="row">
                      <div
                        className={
                          this.props.items.length > 0
                            ? "col-12 col-sm-9 col-md-9"
                            : "col-12 col-sm-8 offset-sm-2 col-md-8 offset-md-2"
                        }
                      >
                        {(this.props.isRequestingCart ||
                          this.state.addingDomainFromUrl) && (
                            <ActiveStepPageWithLoading
                              isLoading={
                                this.props.isRequestingCart ||
                                this.state.addingDomainFromUrl
                              }
                              products={this.props.items}
                              onAttachServiceToProduct={
                                this.onAttachServiceToProduct
                              }
                              onRemoveServiceFromProduct={
                                this.onRemoveServiceFromProduct
                              }
                              onRemoveProductFromCart={
                                this.onRemoveProductFromCart
                              }
                              onUpdateProductPeriod={this.onUpdateProductPeriod}
                              onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                              updatingHostingPeriod={
                                this.state.updatingHostingPeriod
                              }
                              updatingHostingPeriodId={
                                this.state.updatingHostingPeriodId
                              }
                              updatingDomain={this.state.updatingDomain}
                              onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                              onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                              updatingEPPCode={this.state.updatingEPPCode}
                              onShowAssignHostingToDomain={
                                this.onShowAssignHostingToDomain
                              }
                            />
                          )}
                        {!this.props.isRequestingCart &&
                          !this.state.addingDomainFromUrl &&
                          this.props.items.length > 0 && (
                            <ActiveStepPage
                              products={this.props.items}
                              onAttachServiceToProduct={
                                this.onAttachServiceToProduct
                              }
                              onRemoveServiceFromProduct={
                                this.onRemoveServiceFromProduct
                              }
                              onRemoveProductFromCart={
                                this.onRemoveProductFromCart
                              }
                              onUpdateProductPeriod={this.onUpdateProductPeriod}
                              onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                              updatingHostingPeriod={
                                this.state.updatingHostingPeriod
                              }
                              updatingHostingPeriodId={
                                this.state.updatingHostingPeriodId
                              }
                              updatingDomain={this.state.updatingDomain}
                              onChangeDomainEPPCode={this.onChangeDomainEPPCode}
                              onUpdateDomainEPPCode={this.onUpdateDomainEPPCode}
                              updatingEPPCode={this.state.updatingEPPCode}
                              onShowAssignHostingToDomain={
                                this.onShowAssignHostingToDomain
                              }
                            />
                          )}
                        {!this.props.isRequestingCart &&
                          !this.state.addingDomainFromUrl &&
                          this.props.items.length === 0 && (
                            <CartEmptyState
                              shopDomainsRoute={ROUTE_SHOP_DOMAINS}
                              shopHostingRoute={ROUTE_SHOP_HOSTING}
                              shopServersRoute={ROUTE_SHOP_SERVERS}
                            />
                          )}
                      </div>
                      {this.props.items.length > 0 &&
                        !this.state.addingDomainFromUrl && (
                          <div className="col-12 col-sm-3 col-md-3">
                            <CompactCartSummary
                              onContinueCheckout={onContinueToCheckout}
                              onClearCart={this.onClearCart}
                              customerCurrencyCode={
                                this.props.activeLanguage &&
                                  this.props.customerCurrencyCode
                                  ? this.props.customerCurrencyCode
                                  : this.props.activeLanguage.code === "mk"
                                    ? "мкд"
                                    : "eur"
                              }
                              totalDiscount={this.props.totalDiscount}
                              promotionCode={this.props.promotionCode}
                              isApplyingPromotion={
                                this.props.isApplyingPromotion
                              }
                              applyPromotionCode={this.applyPromotionCode}
                              isCleaningPromotion={
                                this.props.isCleaningPromotion
                              }
                              clearPromotionCode={this.clearPromotionCode}
                              referral_code={this.props.referral_code}
                              isApplyingReferrer={this.props.isApplyingReferrer}
                              applyReferrerCode={this.applyReferrerCode}
                              isCleaningReferrer={this.props.isCleaningReferrer}
                              clearReferrerCode={this.clearReferrerCode}
                              total={this.props.total}
                              disableCheckoutButton={
                                this.props.isCheckoutBlocked
                              }
                            />
                          </div>
                        )}
                    </div>
                  )}
                </BrowserView>
              </React.Fragment>
            )}
          </div>
        </div>

        {this.state.showAssignHostingToProduct && (
          <Modal
            title={this.props.translate("cart.choose-hosting-plan")}
            size={!isMobileOnly && "xxl"}
            onCloseModal={this.onHideAssignHostingToDomain}
            body={() => (
              <CartAddHostingToDomainHostingPlans
                availableDomains={availableDomains}
                hostings={this.props.hostings}
                onAddToCart={this.onAssignHostingToDomain}
                submitting={this.state.assignedHostingToDomain}
                disabled={this.state.assignedHostingToDomain}
              />
            )}
          />
        )}

        {this.state.showAssignHostingForMultipleDomains && (
          <Modal
            title={this.props.translate("cart.choose-hosting-plan")}
            size={!isMobileOnly && "xxl"}
            onCloseModal={this.onHideAssignHostingForMultipeDomains}
            body={() => (
              <CartAddHostingForMultipleDomainsHostingPlans
                availableDomains={availableDomains}
                hostings={this.props.hostings}
                onAddToCart={this.onAssignHostingForMultipleDomains}
                submitting={this.state.assignedHostingToDomain}
                disabled={this.state.assignedHostingToDomain}
              />
            )}
          />
        )}
        {this.state.showFreeDomainsModal && (
          <ActionModal
            onCloseActionModal={this.onHideFreeDomainsModal}
            title={this.props.translate(
              "cart.assign-primary-domain-to-your-hosting-plan"
            )}
            subTitle={
              this.state.hostingToAssignDomainTo &&
              this.state.hostingToAssignDomainTo.name
            }
            body={() => (
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledFreeDomainsWrapper>
                    {availableDomains &&
                      availableDomains.length > 0 &&
                      availableDomains.map(domain => (
                        <StyledRadiobox
                          key={`domain-${domain}`}
                          value={domain}
                          checked={
                            this.state.domainToAssignHostingTo === domain
                          }
                          onChange={() =>
                            this.onSelectFreeDomain(domain, "available")
                          }
                        >
                          {domain}
                        </StyledRadiobox>
                      ))}
                  </StyledFreeDomainsWrapper>
                </div>
                <div className="col-12 col-sm-12 col-md-12 text-right">
                  <SecondaryButton
                    disabled={this.state.assignedHostingToDomain}
                    onClick={() => this.onBackToHostings()}
                  >
                    {this.props.translate("general.back")}
                  </SecondaryButton>
                  <PrimaryButton
                    disabled={this.state.assignedHostingToDomain}
                    submitting={this.state.assignedHostingToDomain}
                    onClick={() =>
                      this.onAssignDomainToHosting(
                        this.state.domainToAssignHostingTo
                      )
                    }
                  >
                    {this.props.translate("cart.assign-domain")}
                  </PrimaryButton>
                </div>
              </div>
            )}
          />
        )}
      </StyledCartWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: getItems(state),
    cartId: getCartId(state),
    isAuthenticated: isAuthenticated(state),
    authToken: getAuthToken(state),
    twoFactorEnabled: isTwoFactorEnabled(state),
    twoFactorPhoneNumber: getTwoFactorPhoneNumber(state),
    isPhoneNumberRequired: isPhoneNumberRequired(state),
    isRequestingCart: isRequestingCart(state),
    customerCurrencyCode: getCustomerCurrencyCode(state),
    domainPrices: getDomainsPricing(state),
    totalDiscount: getTotalDiscount(state),
    promotionCode: getPromotionCode(state),
    isApplyingPromotion: isApplyingPromotion(state),
    isCleaningPromotion: isCleaningPromotion(state),
    referral_code: getReferrerCode(state),
    isApplyingReferrer: isApplyingReferrer(state),
    isCleaningReferrer: isCleaningReferrer(state),
    total: getTotal(state),
    countries: getCountriesFormatted(state),
    hostings: getProductsListingByGroup(state),
    isFetchingHostings: isFetchingProducts(state),
    isCheckoutBlocked: isCheckoutBlocked(state),
    firstCheckoutBlockingProduct: firstCheckoutBlockingProduct(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchCart,
      attachServiceToProduct,
      removeServiceFromProduct,
      removeProductFromCart,
      updateProduct,
      login,
      register,
      updateRegisterDetails,
      changeDomainEPPCode,
      updateDomainEPPCode,
      clearCart,
      fetchDomains,
      applyPromotionCode,
      clearPromotionCode,
      applyReferrerCode,
      clearReferrerCode,
      fetchCustomerPersonalInformation,
      addProductToCart,
      fetchProductsByGroup,
      blockCheckoutByProduct,
      unblockCheckoutByProduct
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(withLocalize(Cart)));
