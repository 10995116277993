import React from "react";

import DomainPriceTable from "../DomainPriceTable";

import { DOMAIN_PRICES_YEAR_LIMIT } from "../../DomainPriceConstants";

export class DomainPriceTableContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      duration: 1
    };
  }

  increaseDuration = () => {
    let preventIncrease = false;

    if (this.state.duration >= DOMAIN_PRICES_YEAR_LIMIT) return;

    Object.keys(this.props.tableItems).map(item => {
      if (
        this.state.duration ===
        this.props.tableItems[item][
          this.props.customerCurrencyCode.toLowerCase()
        ].register.length
      ) {
        preventIncrease = true;
      }
      return item;
    });

    if (preventIncrease) return false;

    let duration = this.state.duration;
    duration++;
    this.setState({
      ...this.state,
      duration: duration
    });
    return duration;
  };

  decreaseDuration = () => {
    if (this.state.duration === 1) {
      return;
    }
    let duration = this.state.duration;
    duration--;
    this.setState({
      ...this.state,
      duration: duration
    });
  };

  render() {
    return (
      <DomainPriceTable
        duration={this.state.duration}
        increaseDuration={this.increaseDuration}
        decreaseDuration={this.decreaseDuration}
        tableItems={this.props.tableItems}
        customerCurrencyCode={this.props.customerCurrencyCode}
      />
    );
  }
}

export default DomainPriceTableContainer;
