import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Icon from "../../Icon";

const StyledNotification = styled.div`
  border-radius: 3px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.43;
  position: relative;

  padding: 15px;
  padding-left: 45px;

  cursor: pointer;
  border-bottom: 1px solid ${theme.neutral3};
  &:last-child {
    border-bottom: 0;
  }

  background-color: ${theme.white};

  &:hover {
    background-color: ${theme.blue1};
  }

  & .icon {
    top: 0;

    color: ${theme.blueBase};
  }

  & a {
    font-weight: 600;
  }
  & .row {
    margin-bottom: 0;
  }
`;

const StyledTitle = styled.div`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.neutralBase};
  margin-bottom: 5px;
`;

const StyledDescription = styled.div`
  display: block;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.neutral6};
  margin-bottom: 20px;
  word-break: break-word;
`;

const StyledIcon = styled.div`
  display: inline-block;
  position: absolute;
  left: -35px;
  top: 2px;
  color: ${theme.blueBase};
`;

const StyledLink = styled.span`
  display: inline-block;
  float: left;
  font-size: 14px;
  font-weight: 600;
  color: ${theme.blueBase};
  line-height: 1.42;
  letter-spacing: 0.2px;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
`;

const StyledTime = styled.span`
  display: inline-block;
  float: right;
  font-size: 12px;
  color: ${theme.neutral5};
`;

const StyledContent = styled.div`
  display: block;
  position: relative;
`;

const StyledInfo = styled.div`
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
`;

const Notification = ({
  children,
  notification,
  title,
  link,
  linkTitle,
  timestamp,
  onMarkAsRead,
  ...rest
}) => (
  <StyledNotification
    className="notification"
    onClick={() => onMarkAsRead(notification, link)}
  >
    <StyledContent>
      <StyledIcon>
        <Icon icon={rest.icon || "info"} />
      </StyledIcon>
      <StyledTitle>{title}</StyledTitle>
      <StyledDescription>{children}</StyledDescription>
      <StyledInfo>
        {link && (
          <StyledLink onClick={() => onMarkAsRead(notification, link)}>
            {linkTitle}
          </StyledLink>
        )}
        <StyledTime>{timestamp}</StyledTime>
      </StyledInfo>
    </StyledContent>
  </StyledNotification>
);

Notification.defaultProps = {
  className: "notification"
};

export default Notification;
