import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../../../design/Themes";
import { withLocalize } from "react-localize-redux";

import Label from "../../../../../../../components/Label";

const StyledType = styled.span`
  display: inline-block;
  width: 150px;
  margin-right: 40px;
  vertical-align: top;

  & label {
    display: block;
  }

  & strong {
    color: ${theme.neutralBase};
    font-size: 16px;
    display: block;
    margin-top: 13px;
  }
`;

const RecordType = props => {
  return (
    <StyledType>
      <Label>{props.translate("domain.zone-file.type")}</Label>
      <strong>{props.input.value}</strong>
    </StyledType>
  );
};

export default withLocalize(RecordType);
