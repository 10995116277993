import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";

import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";
import CompactCartDomainProductAddon from "./CompactCartDomainProductAddon";

import { CART_DOMAIN_TYPE_REGISTER } from "../../../Cart/CartDomainTypes/CartDomainTypes";
import { Translate } from "react-localize-redux";

const StyledCompactCartService = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  padding: 30px;
`;

const StyledProductType = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${theme.neutralBase};
  margin-right: 20px;
`;
const StyledProductTitle = styled.span`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  word-break: break-word;
`;

const CompactCartService = ({ product }) => (
  <Translate>
    {({ translate }) => (
      <StyledCompactCartService>
        <div className="row">
          <div className="col-7 col-sm-8 col-md-8">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4">
                <StyledProductType>
                  {translate("cart.addon")} {product.item.name}
                </StyledProductType>
              </div>
              <div className="col-12 col-sm-12 col-md-8">
                <StyledProductTitle>
                  {product.domain} - 1 {translate("cart.year")}
                </StyledProductTitle>
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-4 text-right">
            <DiscountedAmountBadge
              amount={{
                currency: product.item.pricing.buy.billing_cycle.derivations.find(
                  derivation => derivation.numeric_code === 1
                ).price.currency.code,
                amount: product.total,
                discountedAmount:
                  product.total < product.price ? product.price : null
              }}
            />
          </div>
        </div>
      </StyledCompactCartService>
    )}
  </Translate>
);

CompactCartService.defaultProps = {
  domain: "Domain",
  price: {
    currency: "EUR",
    amount: 30.5
  },
  addons: null
};

export default CompactCartService;
