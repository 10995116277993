export const operatingSystemData = process.env
  .REACT_APP_MKHOST_CHECKOUT_CLOUD_SERVER_SETTINGS_OS
  ? process.env.REACT_APP_MKHOST_CHECKOUT_CLOUD_SERVER_SETTINGS_OS.split(
      ","
    ).map(item => {
      return {
        label: item,
        value: item
      };
    })
  : [];

export const platformData = process.env
  .REACT_APP_MKHOST_CHECKOUT_CLOUD_SERVER_SETTINGS_PLATFORM
  ? process.env.REACT_APP_MKHOST_CHECKOUT_CLOUD_SERVER_SETTINGS_PLATFORM.split(
      ","
    ).map(item => {
      return {
        label: item,
        value: item
      };
    })
  : [];

export const regionData = process.env
  .REACT_APP_MKHOST_CHECKOUT_CLOUD_SERVER_SETTINGS_REGION
  ? process.env.REACT_APP_MKHOST_CHECKOUT_CLOUD_SERVER_SETTINGS_REGION.split(
      ","
    ).map(item => {
      return {
        label: item,
        value: item
      };
    })
  : [];
