import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import parse from "html-react-parser";
import { Translate } from "react-localize-redux";

import Icon from "../Icon";

import UpdatingHostingLoader from "../../components/Loaders/UpdatingHostingLoader";

const StyledHostingPricePerYearSelectorWrapper = styled.div`
  width: 130px;
  display: inline-block;
  margin-right: 15px;
  cursor: pointer;
  position: relative;
`;

const StyledHostingPricePerYearSelector = styled.div`
  border: 1px solid
    ${props => (props.selected ? props.theme.blueBase : props.theme.neutral3)};
  background-color: ${props =>
    props.selected ? props.theme.blue1 : props.theme.white};

  padding: 20px 0;
  border-radius: 3px;
  display: block;
  width: 130px;
  position: relative;
`;

const StyledPeriodNumber = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 1.38;
  font-weight: ${props => (props.selected ? 600 : 400)};
  color: ${props =>
    props.selected ? props.theme.blueBase : props.theme.neutralBase};
  width: 130px;
  text-align: center;
`;
const StyledPeriodTime = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 1.38;
  font-weight: ${props => (props.selected ? 600 : 400)};
  color: ${props =>
    props.selected ? props.theme.blueBase : props.theme.neutralBase};
  margin-bottom: 10px;
  width: 130px;
  text-align: center;
`;

const StyledEmptyPeriodTime = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 1.38;
  font-weight: ${props => (props.selected ? 600 : 400)};
  color: transparent;
  margin-bottom: 10px;
  width: 130px;
  text-align: center;
`;

const StyledPrice = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 1.38;
  font-weight: 600;
  color: ${theme.neutralBase};
  margin-bottom: 10px;
  width: 130px;
  text-align: center;
`;
const StyledCurrency = styled.span`
  display: inline-block;
  font-size: 16px;
  line-height: 1.38;
  font-weight: 600;
  text-transform: uppercase;
  color: ${theme.neutral4};
`;
const StyledPerPeriod = styled.span`
  display: inline-block;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  width: 130px;
  text-align: center;
`;

const StyledSelectedIcon = styled.span`
  position: absolute;
  right: 5px;
  top: 5px;
  & .icon {
    color: ${theme.blueBase};
  }
`;

const StyledSavings = styled.span`
  display: block;
  width: 130px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.25;
  color: ${theme.greenBase};
  margin-bottom: 5px;
`;

const DisabledHosting = styled.div`
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const HostingPricePerYearSelector = ({
  product,
  derivation,
  firstYearPrice,
  priceMultiplier,
  currency,
  period,
  years,
  price,
  selected,
  onUpdateHostingPeriod,
  disabled
}) => {
  let fullPrice = 0;
  let finalPrice = 0;
  let pricePerMonth = 0;
  let savings = 0;

  if (years < 1) {
    fullPrice = 0;
    finalPrice = price;
    pricePerMonth = price;
    savings = 0;

    // Recalculate price per month, temp solution because of the 0.5 numeric code from API
    if( years > 0.1 ) {
      let periodInMonths = years * 10;

      // Temporary for semiannually, remove after fix in API
      if( years === 0.5 ){  
        periodInMonths = 0.6 * 10;
      }
      // END

      pricePerMonth = (price / periodInMonths);
    }
    // END

  } else {
    fullPrice = firstYearPrice * years;
    finalPrice = years === 1 ? price : fullPrice - (fullPrice - price);
    pricePerMonth = finalPrice / (years * 12);
    savings = fullPrice - finalPrice;
  }

  return (
    <Translate>
      {({ translate }) => (
        <StyledHostingPricePerYearSelectorWrapper
          onClick={() =>
            !disabled && onUpdateHostingPeriod(product, derivation.numeric_code)
          }
        >
          {disabled && (
            <DisabledHosting>
              <UpdatingHostingLoader />
            </DisabledHosting>
          )}
          <StyledSavings>
            {Math.abs(savings) > 0
              ? parse(`${translate(
                  "cart.you-save"
                )}<br />${currency.toUpperCase()}
          ${currency !== "мкд" ? savings.toFixed(2) : savings.toFixed(0)}`)
              : parse("&nbsp;<br />&nbsp;")}
          </StyledSavings>

          <StyledHostingPricePerYearSelector selected={selected}>
            {selected && (
              <StyledSelectedIcon>
                <Icon icon="success" />
              </StyledSelectedIcon>
            )}

            <StyledPeriodNumber selected={selected}>
              {years < 1
                ? translate("shop.hostings.derivations." + derivation.code)
                : years}
            </StyledPeriodNumber>

            {period ? (
              <StyledPeriodTime selected={selected}>{period}</StyledPeriodTime>
            ) : (
              <StyledEmptyPeriodTime>" "</StyledEmptyPeriodTime>
            )}

            <StyledPrice selected={selected}>
              <StyledCurrency selected={selected}>{currency}</StyledCurrency>{" "}
              {currency !== "мкд"
                ? pricePerMonth.toFixed(2)
                : pricePerMonth.toFixed(0)}
            </StyledPrice>
            <StyledPerPeriod selected={selected}>
              / {translate("shop.hostings.derivations.month")}
            </StyledPerPeriod>
          </StyledHostingPricePerYearSelector>
        </StyledHostingPricePerYearSelectorWrapper>
      )}
    </Translate>
  );
};

export default HostingPricePerYearSelector;
