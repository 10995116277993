export const DEFAULT_DNS_RECORD_TTL = "14400";
export const DEFAULT_DNS_RECORD_CAA_TTL = "3600";

export const zoneFileDefaultValues = record => {
  switch (record) {
    case "CAA":
      return DEFAULT_DNS_RECORD_CAA_TTL;

    default:
      return DEFAULT_DNS_RECORD_TTL;
  }
};
