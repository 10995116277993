import React from "react";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";

import InputField from "../../../../../../../components/InputField";

const StyledTTL = styled.div`
  display: inline-block;
  width: 150px;
  margin-right: 15px;
  vertical-align: top;
`;

const TTL = props => (
  <StyledTTL>
    <InputField label={props.translate("domain.zone-file.ttl")} {...props} />
  </StyledTTL>
);

export default withLocalize(TTL);
