import axios from "axios";
import { sprintf } from "sprintf-js";

import {
  HELPDESK_KNOWLEDGE_BASE_CATEGORIES_API_ENDPOINT,
  HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_API_ENDPOINT,
  HELPDESK_KNOWLEDGE_BASE_ARTICLE_API_ENDPOINT,
  HELPDESK_KNOWLEDGE_BASE_ARTICLES_SEARCH_BY_KEYWORD_API_ENDPOINT,
  HELPDESK_KNOWLEDGEBASE_VOTE_ARTICLE_API_ENDPOINT,
  HELPDESK_TICKETS_DEPARTMENTS_API_ENDPOINT,
  HELPDESK_TICKETS_RELATED_PRODUCTS_API_ENDPOINT,
  HELPDESK_TICKETS_CREATE_TICKET_API_ENDPOINT,
  HELPDESK_TICKETS_REPLY_TO_TICKET_API_ENDPOINT,
  HELPDESK_TICKETS_LIST_API_ENDPOINT,
  HELPDESK_TICKETS_TICKET_DETAILS,
  HELPDESK_TICKETS_TICKET_CLOSE,
  HELPDESK_TICKETS_TICKET_FEEDBACK_CREATE_API_ENDPOINT
} from "./endpoints";

import { SORT_ORDER_DESCENDING } from "../types/SortTypes";

export function fetchTickets(limit, page, sort, filters) {
  return axios.get(HELPDESK_TICKETS_LIST_API_ENDPOINT, {
    params: {
      limit: limit ? limit : 10,
      page: page ? page : 1,
      sort_by: sort ? sort.sort_by : "last_reply",
      order_by: sort ? sort.order_by : SORT_ORDER_DESCENDING,
      ...filters
    }
  });
}

export function fetchTicket(id) {
  return axios.get(sprintf(HELPDESK_TICKETS_TICKET_DETAILS, { id }));
}

export function closeTicket(id) {
  return axios.put(sprintf(HELPDESK_TICKETS_TICKET_CLOSE, { id }));
}

export function fetchAttachment(attachmentSource) {
  return axios.get(attachmentSource, {
    responseType: "arraybuffer"
  });
}

export function fetchRequestHelpDeskKnowledgeBaseCategories() {
  return axios.get(HELPDESK_KNOWLEDGE_BASE_CATEGORIES_API_ENDPOINT);
}

export function fetchRequestHelpDeskKnowledgeBaseMostPopularArticles() {
  return axios.get(HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_API_ENDPOINT);
}

export function fetchKnowledgeBaseArticle(article) {
  return axios.get(
    sprintf(HELPDESK_KNOWLEDGE_BASE_ARTICLE_API_ENDPOINT, article)
  );
}

export function fetchKnowledgeBaseArticlesByKeyword(keyword) {
  return axios.get(
    sprintf(HELPDESK_KNOWLEDGE_BASE_ARTICLES_SEARCH_BY_KEYWORD_API_ENDPOINT, {
      keyword
    })
  );
}

export function fetchTicketDepartments() {
  return axios.get(HELPDESK_TICKETS_DEPARTMENTS_API_ENDPOINT);
}

export function fetchTicketRelatedProducts() {
  return axios.get(HELPDESK_TICKETS_RELATED_PRODUCTS_API_ENDPOINT);
}

export function createTicket(ticket) {
  let normalizedTicket = {
    subject: ticket.subject,
    message: ticket.message,
    attachments: ticket.attachments ? ticket.attachments : [],
    priority: ticket.priority.value
  };

  if (ticket.department) {
    normalizedTicket = {
      ...normalizedTicket,
      support_department_name: ticket.department.label,
      support_department_id: ticket.department.value
    };
  }

  if (ticket.related_product_id && ticket.related_product_id.value) {
    normalizedTicket = {
      ...normalizedTicket,
      related_product_id: ticket.related_product_id.value
    };
  }

  let formData = new FormData();
  Object.keys(normalizedTicket).forEach(key => {
    formData.append(key, normalizedTicket[key]);
  });

  formData.append("attachments", normalizedTicket.attachments);

  return axios.post(HELPDESK_TICKETS_CREATE_TICKET_API_ENDPOINT, formData, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });
}

export function replyToTicket(ticket, reply) {
  const normalizedTicket = {
    ticket_id: ticket.id,
    message: reply.message,
    attachments: reply.attachments ? reply.attachments : []
  };

  let formData = new FormData();
  Object.keys(normalizedTicket).forEach(key => {
    formData.append(key, normalizedTicket[key]);
  });

  formData.append("attachments", normalizedTicket.attachments);

  return axios.post(HELPDESK_TICKETS_REPLY_TO_TICKET_API_ENDPOINT, formData, {
    headers: {
      "content-type": "multipart/form-data"
    }
  });
}

export function voteArticle(article) {
  return axios.post(
    sprintf(HELPDESK_KNOWLEDGEBASE_VOTE_ARTICLE_API_ENDPOINT, { id: article })
  );
}

export function createTicketFeedback(id, rating, comment, comment_admin) {
  return axios.post(
    sprintf(HELPDESK_TICKETS_TICKET_FEEDBACK_CREATE_API_ENDPOINT, { id: id }),
    {
      rating,
      comment,
      comment_admin
    }
  );
}
