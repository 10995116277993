import React from "react";
import { Translate, withLocalize } from "react-localize-redux";
import Panel from "../../components/Panel";
import * as referralService from "../../services/referralService";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import { ROUTE_WEBSITE } from "../../routes/routes";




class ReferralApplyCode extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            code: '',
            error: false
        }
    }


    componentDidMount() {

        const params = new URLSearchParams(window.location.search);
        const code = params.get('code');
        if (code) {
            this.setState({
                code,
                error: false
            })
            referralService._setReferrerCode(code)
        } else {
            this.setState({
                error: true
            })
        }
    }


    render() {
        return (
            <Translate>
                {({ translate }) => (
                    <>
                        {this.state.error &&
                            <p>{translate("referral-admin.ivalid-url")}  </p>
                        }
                        <Panel title={translate("referral-admin.referral-program")} >
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12">
                                    <h5>{translate("referral-admin.congrats-for-your-referral-code")}</h5>
                                    <h6>{translate("referral-admin.code-info")} <strong>{this.state.code}</strong></h6>
                                    <a href={`${ROUTE_WEBSITE}`}>    <PrimaryButton>
                                        {translate("referral-admin.order-now")}
                                    </PrimaryButton></a>
                                </div>
                            </div>
                        </Panel>
                    </>
                )}

            </Translate>
        );
    }
}
export default ReferralApplyCode;