import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withLocalize } from "react-localize-redux";
import { isMobileOnly } from "react-device-detect";
import parse from "html-react-parser";

import {
  fetchCustomerDomainZoneFile,
  createCustomerDomainZoneFile,
  deleteCustomerDomainZoneFile,
  resetCustomerDomainZoneFile,
  updateCustomerDomainDnsRecordType,
  updateCustomerDomainDnsField,
  updateAllCustomerDomainDnsFields,
  saveCustomerDnsZoneRecord,
  deleteCustomerDnsZoneRecord
} from "../../../Customer/actions/customerActions";
import {
  isFetchingDomainZoneFile,
  isFetchingDomainNameServers,
  getDomainToView
} from "../../../Customer/reducers/customerReducer";

import displayToastMessageForResponse from "../../../../utils/displayToastMessageForResponse";

import ContentLoader from "../../../../components/Loaders/TableLoader";
import withLoading from "../../../../components/Loaders/WithLoading";
import ActionModal from "../../../../components/ActionModal";
import Modal from "../../../../components/Modal";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import PrimaryWarningButton from "../../../../components/Buttons/PrimaryWarningButton";

import DnsZoneList from "./DnsZoneList";
import AddDnsRecordForm from "./AddDnsRecordForm";
import NoZoneFile from "./NoZoneFile";
import SetDefaultNameservers from "./SetDefaultNameservers";
import NameserversList from "./NameserversList";

const StyledZoneFileWrapper = styled.div`
  margin-bottom: ${props => (props.isMobile ? "100px" : "30px")};
`;

const StyledZoneFile = styled.div``;

const StyledZoneFileWithLoading = withLoading(StyledZoneFile, ContentLoader);

class ZoneFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDeleteRecordModal: false,
      selectedRecord: null,
      deletingRecord: false,
      showDeleteZoneModal: false,
      showResetZoneModal: false
    };
  }

  onCreateZoneFile = domain => {
    return this.props.createCustomerDomainZoneFile(domain).then(
      result => {
        displayToastMessageForResponse(
          this.props.translate("domain.zone-file.title"),
          result
        );
      },
      err => {
        displayToastMessageForResponse(
          this.props.translate("domain.zone-file.title"),
          err
        );
      }
    );
  };

  onDeleteZoneFile = domain => {
    this.setState({
      deletingRecord: true
    });
    return this.props.deleteCustomerDomainZoneFile(this.props.domain).then(
      result => {
        displayToastMessageForResponse(
          this.props.translate("domain.zone-file.title"),
          result
        );
        this.onHideDeleteZone();
        this.setState({
          deletingRecord: false
        });
      },
      err => {
        displayToastMessageForResponse(
          this.props.translate("domain.zone-file.title"),
          err
        );
        this.onHideDeleteZone();
        this.setState({
          deletingRecord: false
        });
      }
    );
  };

  onResetZoneFile = domain => {
    this.setState({
      deletingRecord: true
    });
    return this.props.resetCustomerDomainZoneFile(this.props.domain).then(
      result => {
        displayToastMessageForResponse(
          this.props.translate("domain.zone-file.title"),
          result
        );

        this.onHideResetZone();
        this.setState({
          deletingRecord: false
        });
      },
      err => {
        displayToastMessageForResponse(
          this.props.translate("domain.zone-file.title"),
          err
        );

        this.onHideResetZone();
        this.props.fetchCustomerDomainZoneFile(this.props.domain);
        this.setState({
          deletingRecord: false
        });
      }
    );
  };

  onUpdateRecordType = (domain, record, type, formValues) => {
    this.props.updateAllCustomerDomainDnsFields(domain, record, {
      ...formValues,
      type
    });
  };

  onUpdateRecordField = (domain, record, field, value, invalidForm) => {
    //this.props.updateCustomerDomainDnsField(domain, record, field, value);

    if (record[field] !== value) {
      document
        .getElementById(`dns-record-form-${record.line}`)
        .dispatchEvent(new Event("submit", { cancelable: true }));
    }
  };

  onSaveRecord = (values, record) => {
    return this.props.saveCustomerDnsZoneRecord(this.props.domain, values).then(
      result => {
        displayToastMessageForResponse(
          this.props.translate("domain.zone-file.title"),
          result
        );
      },
      err => {
        displayToastMessageForResponse(
          this.props.translate("domain.zone-file.title"),
          err
        );
      }
    );
  };

  onConfirmRecordDelete = record => {
    this.setState({
      selectedRecord: record,
      showDeleteRecordModal: true
    });
  };

  onHideRecordDelete = () => {
    this.setState({
      selectedRecord: null,
      showDeleteRecordModal: false
    });
  };

  onDeleteRecord = () => {
    this.setState({
      deletingRecord: true
    });
    return this.props
      .deleteCustomerDnsZoneRecord(this.props.domain, this.state.selectedRecord)
      .then(
        result => {
          displayToastMessageForResponse(
            this.props.translate("domain.zone-file.title"),
            result
          );
          this.onHideRecordDelete();
          this.setState({
            deletingRecord: false
          });
        },
        err => {
          displayToastMessageForResponse(
            this.props.translate("domain.zone-file.title"),
            err
          );
          this.onHideRecordDelete();
          this.setState({
            deletingRecord: false
          });
        }
      );
  };

  onShowDeleteZone = () => {
    this.setState({
      showDeleteZoneModal: true
    });
  };

  onShowResetZone = () => {
    this.setState({
      showResetZoneModal: true
    });
  };

  onHideDeleteZone = () => {
    this.setState({
      showDeleteZoneModal: false
    });
  };

  onHideResetZone = () => {
    this.setState({
      showResetZoneModal: false
    });
  };

  onShowSetDefaultNameservers = () => {
    this.setState({
      showSetDefaultNameserversModal: true
    });
  };

  onHideSetDefaultNameservers = () => {
    this.setState({
      showSetDefaultNameserversModal: false
    });
  };
  onSetDefaultNameservers = () => {
    return this.props
      .onSetDefaultNameservers()
      .then(
        res => this.onHideSetDefaultNameservers(),
        err => this.onHideSetDefaultNameservers()
      );
  };

  render() {
    return (
      <StyledZoneFileWrapper isMobile={isMobileOnly}>
        <StyledZoneFileWithLoading
          isLoading={
            this.props.isFetchingDomainZoneFile ||
            this.props.isFetchingDomainNameServers
          }
        >
          {this.props.domain &&
            !this.props.domain.zoneFile &&
            this.props.domain.name_servers &&
            this.props.domain.name_servers.type === "default" && (
              <NoZoneFile
                domain={this.props.domain}
                onCreateZoneFile={this.onCreateZoneFile}
              />
            )}
          {this.props.domain &&
            !this.props.domain.zoneFile &&
            this.props.domain.name_servers &&
            (this.props.domain.name_servers.type === "custom" ||
              this.props.domain.name_servers.type === "child") && (
              <SetDefaultNameservers
                domain={this.props.domain}
                onSetDefaultNameservers={this.onShowSetDefaultNameservers}
              />
            )}

          {this.props.domain && this.props.domain.zoneFile && (
            <DnsZoneList
              domain={this.props.domain}
              onUpdateRecordType={this.onUpdateRecordType}
              onUpdateRecordField={this.onUpdateRecordField}
              onUpdateRecord={this.onUpdateRecord}
              onSaveRecord={this.onSaveRecord}
              onDeleteRecord={this.onConfirmRecordDelete}
              deletingRecord={this.state.deletingRecord}
              onDeleteZoneFile={this.onShowDeleteZone}
              onResetZoneFile={this.onShowResetZone}
            />
          )}
          {this.props.domain && this.props.domain.zoneFile && (
            <AddDnsRecordForm domain={this.props.domain} />
          )}
        </StyledZoneFileWithLoading>

        {this.state.showDeleteRecordModal && (
          <ActionModal
            title={this.props.translate("domain.zone-file.delete-record-title")}
            onCloseActionModal={this.onHideRecordDelete}
            body={() => (
              <div>
                {parse(
                  this.props
                    .translate("domain.zone-file.delete-record-description")
                    .replace("{RECORD_TYPE}", this.state.selectedRecord.type)
                )}
              </div>
            )}
            footer={() => (
              <React.Fragment>
                <SecondaryButton
                  disabled={this.state.deletingRecord}
                  onClick={() => this.onHideRecordDelete()}
                >
                  {this.props.translate("general.cancel")}
                </SecondaryButton>
                <PrimaryWarningButton
                  disabled={this.state.deletingRecord}
                  submitting={this.state.deletingRecord}
                  onClick={() => this.onDeleteRecord()}
                >
                  {this.props.translate("general.delete")}
                </PrimaryWarningButton>
              </React.Fragment>
            )}
          />
        )}

        {this.state.showDeleteZoneModal && (
          <ActionModal
            title={this.props.translate("domain.zone-file.delete-dns-zone")}
            onCloseActionModal={this.onHideDeleteZone}
            body={() => (
              <div>
                {this.props.translate(
                  "domain.zone-file.delete-dns-zone-description"
                )}
              </div>
            )}
            footer={() => (
              <React.Fragment>
                <SecondaryButton
                  disabled={this.state.deletingRecord}
                  onClick={() => this.onHideDeleteZone()}
                >
                  {this.props.translate("general.cancel")}
                </SecondaryButton>
                <PrimaryWarningButton
                  disabled={this.state.deletingRecord}
                  submitting={this.state.deletingRecord}
                  onClick={() => this.onDeleteZoneFile()}
                >
                  {this.props.translate("general.delete")}
                </PrimaryWarningButton>
              </React.Fragment>
            )}
          />
        )}

        {this.state.showResetZoneModal && (
          <ActionModal
            title={this.props.translate(
              "domain.zone-file.reset-dns-zone-title"
            )}
            onCloseActionModal={this.onHideResetZone}
            body={() => (
              <div>
                {this.props.translate(
                  "domain.zone-file.reset-dns-zone-description"
                )}
              </div>
            )}
            footer={() => (
              <React.Fragment>
                <SecondaryButton
                  disabled={this.state.deletingRecord}
                  onClick={() => this.onHideResetZone()}
                >
                  {this.props.translate("general.cancel")}
                </SecondaryButton>
                <PrimaryWarningButton
                  disabled={this.state.deletingRecord}
                  submitting={this.state.deletingRecord}
                  onClick={() => this.onResetZoneFile()}
                >
                  {this.props.translate("general.reset")}
                </PrimaryWarningButton>
              </React.Fragment>
            )}
          />
        )}

        {this.state.showSetDefaultNameserversModal && (
          <Modal
            size="l"
            title={this.props.translate(
              "domain.zone-file.set-default-nameservers-modal-title"
            )}
            subTitle={this.props.translate(
              "domain.zone-file.set-default-nameservers-modal-description"
            )}
            onCloseModal={this.onHideSetDefaultNameservers}
            body={() => (
              <NameserversList nameservers={this.props.domain.name_servers} />
            )}
            footer={() => (
              <React.Fragment>
                <SecondaryButton
                  disabled={this.props.settingDefaultNameservers}
                  onClick={() => this.onHideSetDefaultNameservers()}
                >
                  {this.props.translate("general.cancel")}
                </SecondaryButton>
                <PrimaryButton
                  disabled={this.props.settingDefaultNameservers}
                  submitting={this.props.settingDefaultNameservers}
                  onClick={() => this.onSetDefaultNameservers()}
                >
                  {this.props.translate(
                    "domain.zone-file.set-default-nameservers"
                  )}
                </PrimaryButton>
              </React.Fragment>
            )}
          />
        )}
      </StyledZoneFileWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetchingDomainZoneFile: isFetchingDomainZoneFile(state),
    isFetchingDomainNameServers: isFetchingDomainNameServers(state),
    domain: getDomainToView(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCustomerDomainZoneFile,
      createCustomerDomainZoneFile,
      deleteCustomerDomainZoneFile,
      resetCustomerDomainZoneFile,
      updateCustomerDomainDnsRecordType,
      updateCustomerDomainDnsField,
      updateAllCustomerDomainDnsFields,
      saveCustomerDnsZoneRecord,
      deleteCustomerDnsZoneRecord
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(ZoneFile));
