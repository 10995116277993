import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { isMobile } from "react-device-detect";
import isLaptop from "../../../../../utils/isLaptopScreen";
import withSizes from "react-sizes";
import { Translate } from "react-localize-redux";

import AmountBadge from "../../../../../components/AmountBadge";
import DetailsPanelItem from "../../../../../components/DetailsPanel/DetailsPanelItem";
import Icon from "../../../../../components/Icon";

const StyledInvoicePaymentDetailsWrapper = styled.div`
  margin-left: ${props =>
    props.isMobile || props.isLaptop ? "-15px" : "-45px"};
  margin-right: ${props =>
    props.isMobile || props.isLaptop ? "-15px" : "-45px"};
  margin-top: ${props => (props.isMobile || props.isLaptop ? "15px" : "30px")};
  margin-bottom: ${props =>
    props.isMobile || props.isLaptop ? "15px" : "30px"};

  & .invoice-detail:last-child {
    border-bottom: 1px solid ${theme.neutral3};
  }
`;

const InvoicePaymentDetails = ({
  dateCreated,
  datePaid,
  proinvoiceNumber,
  currency,
  isLaptop
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <StyledInvoicePaymentDetailsWrapper className="row" isMobile={isMobile}>
          <div className="col-12 col-sm-12 col-md-12">
            <DetailsPanelItem
              label={translate("invoices.view.date-created")}
              className="invoice-detail"
            >
              {dateCreated}
            </DetailsPanelItem>
            <DetailsPanelItem
              label={translate("invoices.view.date-paid")}
              className="invoice-detail"
            >
              {datePaid}
            </DetailsPanelItem>
            <DetailsPanelItem
              label={translate("pro-invoices.view.title")}
              className="invoice-detail"
            >
              #{proinvoiceNumber}
            </DetailsPanelItem>
          </div>
        </StyledInvoicePaymentDetailsWrapper>
      )}
    </Translate>
  );
};

InvoicePaymentDetails.defaultProps = {
  amountDue: "0",
  dateCreated: "na",
  datePaid: "n/a",
  invoiceNumber: "n/a",
  transactionId: "n/a",
  currency: "n/a"
};

export default withSizes(isLaptop)(InvoicePaymentDetails);
