import * as creditService from "../../../services/creditService.js";

export const CREDIT_FEATURE = "features/Credit";
export const CREDIT_REQUEST = `${CREDIT_FEATURE}/CREDIT_REQUEST`;
export const CREDIT_RECEIVE = `${CREDIT_FEATURE}/CREDIT_RECEIVE`;
export const CREDIT_REDUCE_AMOUNT = `${CREDIT_FEATURE}/CREDIT_REDUCE_AMOUNT`;
export const CREDIT_PURCHASE_REQUEST = `${CREDIT_FEATURE}/CREDIT_PURCHASE_REQUEST`;
export const CREDIT_PURCHASE_RECEIVE = `${CREDIT_FEATURE}/CREDIT_PURCHASE_RECEIVE`;

export function fetchCredit() {
  return dispatch => {
    dispatch(requestCredit());
    return creditService
      .getAvailableCredit()
      .then(
        result => {
          if (result.data) {
            const { credit } = result.data.data;
            dispatch(receiveCredit(credit));
          }
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function purchaseCredit(amount) {
  return dispatch => {
    dispatch(purchaseCreditRequest());
    return creditService
      .purchaseCredit(amount)
      .then(
        result => {
          const { error } = result.data;
          const { messages, invoice_id } = result.data.data;

          if (error) {
            return { error, messages };
          }

          dispatch(purchaseCreditReceive());

          return invoice_id;
        },
        error => {
          dispatch(purchaseCreditReceive());
          return { error: true, messages: [error.message] };
        }
      )
      .catch(error => {
        dispatch(purchaseCreditReceive());
        return { error: true, messages: [error.message] };
      });
  };
}

export function reduceCredit(amount) {
  return {
    type: CREDIT_REDUCE_AMOUNT,
    payload: amount
  };
}

function requestCredit() {
  return {
    type: CREDIT_REQUEST
  };
}

export function purchaseCreditRequest() {
  return {
    type: CREDIT_PURCHASE_REQUEST
  };
}

function purchaseCreditReceive() {
  return {
    type: CREDIT_PURCHASE_RECEIVE
  };
}

function receiveCredit(credit) {
  return {
    type: CREDIT_RECEIVE,
    payload: credit
  };
}
