import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Translate } from "react-localize-redux";
import { withRouter } from "react-router-dom";

import Container from "../../components/Containers/Container";
import WidgetTitle from "../WidgetTitle";

import TicketsWidgetEmptyState from "./TicketsWidgetEmptyState";

import Ticket from "../../components/Ticket";

import withLoading from "../../components/Loaders/WithLoading";
import TicketsWidgetLoader from "../../components/Loaders/TicketsWidgetLoader";

import { compileRoute, ROUTE_HELPDESK_TICKET_VIEW } from "../../routes/routes";

const StyledTicketsWidget = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;

  & .widget-title {
    border-bottom: 1px solid ${theme.neutral3};
  }
`;

const StyledTicketsWidgetContent = styled.div`
  margin-top: -1px;
`;

const TicketsWidgetContentWithLoading = withLoading(
  StyledTicketsWidgetContent,
  TicketsWidgetLoader
);

class TicketsWidget extends React.Component {
  constructor(props) {
    super(props);
    this.viewTicketPath = compileRoute(ROUTE_HELPDESK_TICKET_VIEW);
  }

  onOpenTicket = (ticket, url) => {
    this.props.history.push(url);
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <Container className="widget-container">
            <StyledTicketsWidget className="widget tickets-widget">
              <WidgetTitle
                title={translate("home.widgets.tickets.title")}
                actionTo={this.props.ticketsRoute()}
                actionTitle={translate("home.widgets.tickets.view-all-tickets")}
              />
              <TicketsWidgetContentWithLoading
                isLoading={this.props.isFetchingTickets}
              >
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    {this.props.tickets.items.length > 0 ? (
                      this.props.tickets.items.map(ticket => (
                        <Ticket
                          key={`ticket-` + ticket.id}
                          ticket={ticket}
                          closed={ticket.closed}
                          url={this.viewTicketPath(ticket)}
                          onOpenTicket={this.onOpenTicket}
                          onCloseTicket={this.props.onCloseTicket}
                          closingTicket={this.props.closingTicket}
                          closingTicketId={this.props.closingTicketId}
                        />
                      ))
                    ) : (
                      <TicketsWidgetEmptyState />
                    )}
                  </div>
                </div>
              </TicketsWidgetContentWithLoading>
            </StyledTicketsWidget>
          </Container>
        )}
      </Translate>
    );
  }
}

TicketsWidget.defaultProps = {
  tickets: null,
  empty: true
};

export default withRouter(TicketsWidget);
