import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { MobileOnlyView, TabletView, BrowserView } from "react-device-detect";
import isLaptop from "../../../../utils/isLaptopScreen";
import withSizes from "react-sizes";
import { Translate } from "react-localize-redux";

import InputField from "../../../../components/InputField";
import TextArea from "../../../../components/TextArea";
import FileUpload from "../../../../components/FileUpload";
//import Form from "../../../../components/Forms/Form";
import SelectBox from "../../../../components/SelectBox";

import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";

const StyledOpenNewTicketForm = styled.div`
  padding: 30px;
  .form-group {
    width: 100%;
    margin-bottom: 25px;
    .btn.dropdown-toggle {
      width: 100%;
    }
  }
  .related-product-col {
    @media (min-width: 768px) and (max-width: 1199px) {
      padding-left: 5px;
      padding-right: 5px;
    }
    @media (min-width: 1441px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .dropdown-col {
    @media (min-width: 1200px) and (max-width: 1440px) {
      width: 100%;
      flex: 100%;
      max-width: 100%;
    }
    @media (max-width: 767px) {
      width: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }
  input {
    min-width: auto;
  }
`;

const priorityOptions = [
  { label: "Low", value: "Low" },
  { label: "Medium", value: "Medium" },
  { label: "High", value: "High" }
];

const acceptFileExtensions = [".jpg", ".gif", ".jpeg", ".png", ".txt", ".pdf"];

const OpenNewTicketForm = props => {
  const department = props.departments
    ? props.departments.find(department => department.value === parseInt(props.selectedDepartment, 10) )
    : false;
console.log(department);
  return (
    <Translate>
      {({ translate }) => (
        <StyledOpenNewTicketForm>
          <Form
            onSubmit={props.onSubmit}
            validate={props.validate}
            keepDirtyOnReinitialize={true}
            initialValues={{
              priority: {
                value: "Medium",
                label: translate("help-desk.ticket.priorities.Medium")
              },
              department
            }}
            render={({ handleSubmit, invalid }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <Field
                      name="subject"
                      component={InputField}
                      label={translate("help-desk.ticket.ticket-subject")}
                      placeholder={translate(
                        "help-desk.ticket.enter-ticket-subject"
                      )}
                    />
                  </div>
                </div>
                <MobileOnlyView renderWithFragment>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <Field
                        component={SelectBox}
                        name="department"
                        label={translate("help-desk.ticket.ticket-department")}
                        placeholder={translate(
                          "help-desk.ticket.choose-department"
                        )}
                        isSearchable={false}
                        async={true}
                        loadOptions={props.fetchDepartments}
                        isDisabled={props.isFetchingRelatedProducts}
                        defaultOptions
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                      <Field
                        component={SelectBox}
                        name="related_product_id"
                        label={translate(
                          "help-desk.ticket.ticket-related-product"
                        )}
                        placeholder={translate(
                          "help-desk.ticket.choose-related-product"
                        )}
                        isSearchable={true}
                        async={true}
                        loadOptions={props.fetchRelatedProducts}
                        defaultOptions
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                      <Field
                        component={SelectBox}
                        name="priority"
                        label={translate("help-desk.ticket.ticket-priority")}
                        placeholder={translate(
                          "help-desk.ticket.choose-priority"
                        )}
                        options={priorityOptions.map(option => {
                          return {
                            ...option,
                            label: translate(
                              "help-desk.ticket.priorities." + option.label
                            )
                          };
                        })}
                      />
                    </div>
                  </div>
                </MobileOnlyView>
                <TabletView>
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-4">
                      <Field
                        component={SelectBox}
                        name="department"
                        label={translate("help-desk.ticket.ticket-department")}
                        placeholder={translate(
                          "help-desk.ticket.choose-department"
                        )}
                        isSearchable={false}
                        async={true}
                        loadOptions={props.fetchDepartments}
                        defaultOptions
                      />
                    </div>
                    <div className="col-6 col-sm-6 col-md-5">
                      <Field
                        component={SelectBox}
                        name="related_product_id"
                        label={translate(
                          "help-desk.ticket.ticket-related-product"
                        )}
                        placeholder={translate(
                          "help-desk.ticket.choose-related-product"
                        )}
                        isSearchable={true}
                        async={true}
                        loadOptions={props.fetchRelatedProducts}
                        defaultOptions
                      />
                    </div>
                    <div className="col-6 col-sm-6 col-md-3">
                      <Field
                        component={SelectBox}
                        name="priority"
                        label={translate("help-desk.ticket.priority")}
                        placeholder={translate(
                          "help-desk.ticket.choose-priority"
                        )}
                        options={priorityOptions.map(option => {
                          return {
                            ...option,
                            label: translate(
                              "help-desk.ticket.priorities." + option.label
                            )
                          };
                        })}
                      />
                    </div>
                  </div>
                </TabletView>
                <BrowserView>
                  {props.isLaptop && (
                    <div className="row">
                      <div className="col-6 col-sm-6 col-md-6">
                        <Field
                          component={SelectBox}
                          name="department"
                          label={translate(
                            "help-desk.ticket.ticket-department"
                          )}
                          placeholder={translate(
                            "help-desk.ticket.choose-department"
                          )}
                          isSearchable={false}
                          async={true}
                          loadOptions={props.fetchDepartments}
                          defaultOptions
                        />
                      </div>
                      <div className="col-6 col-sm-6 col-md-6">
                        <Field
                          component={SelectBox}
                          name="priority"
                          label={translate("help-desk.ticket.priority")}
                          placeholder={translate(
                            "help-desk.ticket.choose-priority"
                          )}
                          options={priorityOptions.map(option => {
                            return {
                              ...option,
                              label: translate(
                                "help-desk.ticket.priorities." + option.label
                              )
                            };
                          })}
                        />
                      </div>
                      <div className="col-12 col-sm-12 col-md-12">
                        <Field
                          component={SelectBox}
                          name="related_product_id"
                          label={translate(
                            "help-desk.ticket.ticket-related-product"
                          )}
                          placeholder={translate(
                            "help-desk.ticket.choose-related-product"
                          )}
                          isSearchable={true}
                          async={true}
                          loadOptions={props.fetchRelatedProducts}
                          defaultOptions
                        />
                      </div>
                    </div>
                  )}
                  {!props.isLaptop && (
                    <div className="row">
                      <div className="col-6 col-sm-6 col-md-4">
                        <Field
                          component={SelectBox}
                          name="department"
                          label={translate(
                            "help-desk.ticket.ticket-department"
                          )}
                          placeholder={translate(
                            "help-desk.ticket.choose-department"
                          )}
                          isSearchable={false}
                          async={true}
                          loadOptions={props.fetchDepartments}
                          defaultOptions
                        />
                      </div>
                      <div className="col-6 col-sm-6 col-md-5">
                        <Field
                          component={SelectBox}
                          name="related_product_id"
                          label={translate(
                            "help-desk.ticket.ticket-related-product"
                          )}
                          placeholder={translate(
                            "help-desk.ticket.choose-related-product"
                          )}
                          isSearchable={true}
                          async={true}
                          loadOptions={props.fetchRelatedProducts}
                          defaultOptions
                        />
                      </div>
                      <div className="col-6 col-sm-6 col-md-3">
                        <Field
                          component={SelectBox}
                          name="priority"
                          label={translate("help-desk.ticket.priority")}
                          placeholder={translate(
                            "help-desk.ticket.choose-priority"
                          )}
                          options={priorityOptions.map(option => {
                            return {
                              ...option,
                              label: translate(
                                "help-desk.ticket.priorities." + option.label
                              )
                            };
                          })}
                        />
                      </div>
                    </div>
                  )}
                </BrowserView>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <Field
                      component={TextArea}
                      name="message"
                      label={translate("help-desk.ticket.message")}
                      placeholder={translate("help-desk.ticket.enter-message")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <Field
                      component={FileUpload}
                      acceptFileExtensions={acceptFileExtensions}
                      name="attachments"
                      label={translate("help-desk.ticket.attachments")}
                      helpText={acceptFileExtensions.join(", ")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <SecondaryButton onClick={() => props.onCancel()}>
                      {translate("help-desk.ticket.cancel")}
                    </SecondaryButton>{" "}
                    <PrimaryButton
                      disabled={props.submitting || invalid}
                      submitting={props.submitting}
                      type="submit"
                    >
                      {translate("help-desk.ticket.open-ticket")}
                    </PrimaryButton>
                  </div>
                </div>
              </form>
            )}
          />
        </StyledOpenNewTicketForm>
      )}
    </Translate>
  );
};

export default withSizes(isLaptop)(OpenNewTicketForm);
