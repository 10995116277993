import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Button from "../Button";

const PrimaryWarningButton = styled(Button)`
  background-color: ${theme.redBase};
  color: ${theme.white};
  border: solid 1px ${theme.redBase};

  &:hover {
    background-color: ${theme.red2};
    border: 1px solid ${theme.red2};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${theme.blue3};
  }

  &:active {
    border: 1px solid ${theme.red3};
    background-color: ${theme.red3};
    color: ${theme.white};
  }

  &:disabled {
    background-color: ${theme.neutral2};
    color: ${theme.white};
    border: 1px solid ${theme.neutral2};
  }
`;

PrimaryWarningButton.defaultProps = {
  className: "btn primary-warning-button"
};

export default PrimaryWarningButton;
