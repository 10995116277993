import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

const StyledGlobalSearchResultsGroup = styled.div`
  display: block;
  padding-top: 10px;
  border-bottom: 3px solid ${theme.blue2};
  &:last-child {
    border-bottom: 0;
  }
`;

const StyledRow = styled.span`
  display: block;
  font-size: 16px;
  padding: 13px 20px;
`;

const StyledTitle = styled(StyledRow)`
  font-size: 14px;
  font-weight: 600;
  color: ${theme.neutral5};
  text-transform: uppercase;
`;

const GlobalSearchResultsGroup = ({ title, children }) => (
  <StyledGlobalSearchResultsGroup>
    <StyledTitle>{title}</StyledTitle>
    {children}
  </StyledGlobalSearchResultsGroup>
);

export default GlobalSearchResultsGroup;
