import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Translate } from "react-localize-redux";

import {
  isTablet,
  MobileOnlyView,
  TabletView,
  BrowserView
} from "react-device-detect";
import isLaptop from "../../../../utils/isLaptopScreen";
import withSizes from "react-sizes";

import Container from "../../../../components/Containers/Container";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import Icon from "../../../../components/Icon";
import Label from "../../../../components/Label";
import Togglebox from "../../../../components/Togglebox";
import AmountBadge from "../../../../components/AmountBadge";
import CustomTooltip from "../../../../components/CustomTooltip";
import ContentLoader from "../../../../components/Loaders/ContentLoader";

import withLoading from "../../../../components/Loaders/WithLoading";

import Nameservers from "./Nameservers";

import getBadgeForStatus from "../../../../utils/getBadgeForStatus";

import { DOMAIN_STATUS_ACTIVE } from "../../DomainStatuses";

const StyledHeader = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${theme.neutral3};
`;

const StyledContent = styled.div`
  padding: 30px;
`;

const StyledDomainTitle = styled.h3`
  margin-bottom: 0;
  font-size: 30px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
`;

const StyledDetailsWrapper = styled.div`
  margin-bottom: 30px;
`;

const StyledDomainDetail = styled.div`
  vertical-align: top;
  position: relative;
  display: inline-block;
  margin-right: ${props => (props.isTablet ? "15px" : "50px")};
  margin-bottom: 30px;
  &:last-child {
    margin-right: 0;
  }
`;

const StyledMobileDomainDetail = styled.div`
  vertical-align: top;
  position: relative;
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
`;

const StyledValue = styled.div`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.neutralBase};
  margin-top: 8px;
`;

const StyledMobileValue = styled.div`
  display: block;
  font-size: 16px;
  font-weight: 600;
  text-align: right;
  color: ${theme.neutralBase};
`;
const StyledGeneralInfoWrapper = styled.div`
  margin-bottom: 30px;
`;

const StyledActionsWrapper = styled.div`
  margin-bottom: 30px;
  & .renew-button {
    width: 78%;
  }
`;

const NameServersWithLoader = withLoading(Nameservers, ContentLoader);

class GeneralInfo extends React.Component {
  render() {
    const { domain } = this.props;

    const StatusBadgeComponent = getBadgeForStatus(domain.status.state);

    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <MobileOnlyView viewClassName="w-100">
              <StyledGeneralInfoWrapper className="col-12 col-sm-12 col-md-12">
                <StyledGeneralInfoWrapper>
                  <Container>
                    <StyledHeader>
                      <StyledActionsWrapper className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <PrimaryButton
                            onClick={() => this.props.onRenewDomain(domain)}
                            disabled={
                              this.props.renewNowSubmitting ||
                              this.props.isFetchingDomains
                            }
                            submitting={this.props.renewNowSubmitting}
                            className="renew-button"
                          >
                            {translate("domain.general-info.renew-domain")}
                          </PrimaryButton>
                        </div>
                      </StyledActionsWrapper>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <StatusBadgeComponent>
                            {translate("domain.statuses." + domain.status.name)}
                          </StatusBadgeComponent>
                        </div>

                        <div className="col-12 col-sm-12 col-md-12">
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StyledDomainTitle>
                                {domain.domain_name}
                              </StyledDomainTitle>
                            </div>
                          </div>
                        </div>
                      </div>
                    </StyledHeader>
                    <StyledContent>
                      <StyledDetailsWrapper className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <StyledMobileDomainDetail>
                            <div className="row">
                              <div className="col-6 col-sm-6 col-md-6">
                                <Label>
                                  {translate(
                                    "domain.general-info.registration-date"
                                  )}
                                </Label>
                              </div>
                              <div className="col-6 col-sm-6 col-md-6">
                                <StyledMobileValue>
                                  {domain.purchase_date}
                                </StyledMobileValue>
                              </div>
                            </div>
                          </StyledMobileDomainDetail>
                          <StyledMobileDomainDetail>
                            <div className="row">
                              <div className="col-6 col-sm-6 col-md-6">
                                <Label>
                                  {translate(
                                    "domain.general-info.purchase-price"
                                  )}
                                </Label>
                              </div>
                              <div className="col-6 col-sm-6 col-md-6">
                                <StyledMobileValue>
                                  <AmountBadge
                                    amount={domain.purchase_price.value}
                                    currency={
                                      domain.purchase_price.currency.code
                                    }
                                    textAlign="right"
                                  />
                                </StyledMobileValue>
                              </div>
                            </div>
                          </StyledMobileDomainDetail>
                          <StyledMobileDomainDetail>
                            <div className="row">
                              <div className="col-6 col-sm-6 col-md-6">
                                <Label>
                                  {translate(
                                    "domain.general-info.expiration-date"
                                  )}
                                </Label>
                              </div>
                              <div className="col-6 col-sm-6 col-md-6">
                                <StyledMobileValue>
                                  {domain.expiry_date}
                                </StyledMobileValue>
                              </div>
                            </div>
                          </StyledMobileDomainDetail>
                          <StyledMobileDomainDetail>
                            <div className="row">
                              <div className="col-6 col-sm-6 col-md-6">
                                <Label>
                                  {translate(
                                    "domain.general-info.renewal-price"
                                  )}
                                </Label>
                              </div>
                              <div className="col-6 col-sm-6 col-md-6">
                                <StyledMobileValue>
                                  <AmountBadge
                                    amount={domain.renewal_price.value}
                                    currency={
                                      domain.renewal_price.currency.code
                                    }
                                    textAlign="right"
                                  />
                                </StyledMobileValue>
                              </div>
                            </div>
                          </StyledMobileDomainDetail>
                          <StyledMobileDomainDetail>
                            <div className="row">
                              <div className="col-6 col-sm-6 col-md-6">
                                <Label>
                                  {translate(
                                    "domain.general-info.renewal-period"
                                  )}
                                </Label>
                              </div>
                              <div className="col-6 col-sm-6 col-md-6">
                                <StyledMobileValue>
                                  {domain.renewal_period}{" "}
                                  {domain.renewal_period > 1
                                    ? translate("general.years")
                                    : translate("general.year")}
                                </StyledMobileValue>
                              </div>
                            </div>
                          </StyledMobileDomainDetail>
                        </div>
                      </StyledDetailsWrapper>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <StyledMobileDomainDetail>
                            <Label>
                              {translate("domain.general-info.auto-renew")}{" "}
                              <CustomTooltip
                                inline
                                content={translate(
                                  "domain.settings.auto-renew-description"
                                )}
                              >
                                <Icon icon="info" />
                              </CustomTooltip>
                            </Label>
                            <StyledMobileValue>
                              <Togglebox
                                toggled={!domain.is_auto_renew_disabled}
                                disabled={this.props.renewNowSubmitting}
                                submitting={this.props.renewNowSubmitting}
                                alignLoader="right"
                                onToggle={() =>
                                  !this.props.renewNowSubmitting &&
                                  domain.is_auto_renew_disabled
                                    ? this.props.onAutoRenewDomain(domain)
                                    : this.props.onShowDisableAutoRenewModal(
                                        domain
                                      )
                                }
                              />
                            </StyledMobileValue>
                          </StyledMobileDomainDetail>
                        </div>
                      </div>
                    </StyledContent>
                  </Container>
                </StyledGeneralInfoWrapper>
                <StyledGeneralInfoWrapper>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <NameServersWithLoader
                        isLoading={this.props.isFetchingDomainNameServers}
                        domain={domain}
                      />
                    </div>
                  </div>
                </StyledGeneralInfoWrapper>
              </StyledGeneralInfoWrapper>
            </MobileOnlyView>
            <TabletView viewClassName="w-100">
              <StyledGeneralInfoWrapper className="col-12 col-sm-12 col-md-12">
                <StyledGeneralInfoWrapper>
                  <Container>
                    <StyledHeader>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <StatusBadgeComponent>
                            {translate("domain.statuses." + domain.status.name)}
                          </StatusBadgeComponent>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-8 col-sm-8 col-md-8">
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StyledDomainTitle>
                                {domain.name}
                              </StyledDomainTitle>
                            </div>
                          </div>
                        </div>
                        <div className="col-4 col-sm-4 col-md-4 text-right">
                          <PrimaryButton
                            onClick={() => this.props.onRenewDomain(domain)}
                            disabled={
                              this.props.renewNowSubmitting ||
                              this.props.isFetchingDomains
                            }
                            submitting={this.props.renewNowSubmitting}
                            className="renew-button"
                          >
                            {translate("domain.general-info.renew-domain")}
                          </PrimaryButton>
                        </div>
                      </div>
                    </StyledHeader>
                    <StyledContent>
                      <StyledDetailsWrapper className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <StyledDomainDetail isTablet={isTablet}>
                            <Label>
                              {translate(
                                "domain.general-info.registration-date"
                              )}
                            </Label>
                            <StyledValue>{domain.purchase_date}</StyledValue>
                          </StyledDomainDetail>
                          <StyledDomainDetail isTablet={isTablet}>
                            <Label>
                              {translate("domain.general-info.purchase-price")}
                            </Label>
                            <StyledValue>
                              <AmountBadge
                                amount={domain.purchase_price.value}
                                currency={domain.purchase_price.currency.code}
                                textAlign="left"
                              />
                            </StyledValue>
                          </StyledDomainDetail>
                          <StyledDomainDetail isTablet={isTablet}>
                            <Label>
                              {translate("domain.general-info.expiration-date")}
                            </Label>
                            <StyledValue>{domain.expiry_date}</StyledValue>
                          </StyledDomainDetail>
                          <StyledDomainDetail isTablet={isTablet}>
                            <Label>
                              {translate("domain.general-info.renewal-price")}
                            </Label>
                            <StyledValue>
                              <AmountBadge
                                amount={domain.renewal_price.value}
                                currency={domain.renewal_price.currency.code}
                                textAlign="left"
                              />
                            </StyledValue>
                          </StyledDomainDetail>
                          <StyledDomainDetail isTablet={isTablet}>
                            <Label>
                              {translate("domain.general-info.renewal-period")}
                            </Label>
                            <StyledValue>
                              {domain.renewal_period}{" "}
                              {domain.renewal_period > 1
                                ? translate("general.years")
                                : translate("general.year")}
                            </StyledValue>
                          </StyledDomainDetail>
                        </div>
                      </StyledDetailsWrapper>
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          <StyledDomainDetail isTablet={isTablet}>
                            <Label>
                              {translate("domain.general-info.auto-renew")}{" "}
                              <CustomTooltip
                                inline
                                content={translate(
                                  "domain.settings.auto-renew-description"
                                )}
                              >
                                <Icon icon="info" />
                              </CustomTooltip>
                            </Label>
                            <StyledValue>
                              <Togglebox
                                toggled={!domain.is_auto_renew_disabled}
                                disabled={this.props.renewNowSubmitting}
                                submitting={this.props.renewNowSubmitting}
                                alignLoader="right"
                                onToggle={() =>
                                  !this.props.renewNowSubmitting &&
                                  domain.is_auto_renew_disabled
                                    ? this.props.onAutoRenewDomain(domain)
                                    : this.props.onShowDisableAutoRenewModal(
                                        domain
                                      )
                                }
                              />
                            </StyledValue>
                          </StyledDomainDetail>
                        </div>
                      </div>
                    </StyledContent>
                  </Container>
                </StyledGeneralInfoWrapper>
                <StyledGeneralInfoWrapper>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <NameServersWithLoader
                        isLoading={this.props.isFetchingDomainNameServers}
                        domain={domain}
                      />
                    </div>
                  </div>
                </StyledGeneralInfoWrapper>
              </StyledGeneralInfoWrapper>
            </TabletView>
            <BrowserView renderWithFragment>
              {this.props.isLaptop && (
                <React.Fragment>
                  <StyledGeneralInfoWrapper className="col-12 col-sm-12 col-md-12">
                    <StyledGeneralInfoWrapper>
                      <Container>
                        <StyledHeader>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StatusBadgeComponent>
                                {translate(
                                  "domain.statuses." + domain.status.name
                                )}
                              </StatusBadgeComponent>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8 col-sm-8 col-md-8">
                              <div className="row">
                                <div className="col-12 col-sm-12 col-md-12">
                                  <StyledDomainTitle>
                                    {domain.domain_name}
                                  </StyledDomainTitle>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 col-sm-4 col-md-4 text-right">
                              <PrimaryButton
                                onClick={() => this.props.onRenewDomain(domain)}
                                disabled={
                                  this.props.renewNowSubmitting ||
                                  this.props.isFetchingDomains
                                }
                                submitting={this.props.renewNowSubmitting}
                                className="renew-button"
                              >
                                {translate("domain.general-info.renew-domain")}
                              </PrimaryButton>
                            </div>
                          </div>
                        </StyledHeader>
                        <StyledContent>
                          <StyledDetailsWrapper className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StyledDomainDetail isTablet={isTablet}>
                                <Label>
                                  {translate(
                                    "domain.general-info.registration-date"
                                  )}
                                </Label>
                                <StyledValue>
                                  {domain.purchase_date}
                                </StyledValue>
                              </StyledDomainDetail>
                              <StyledDomainDetail isTablet={isTablet}>
                                <Label>
                                  {translate(
                                    "domain.general-info.purchase-price"
                                  )}
                                </Label>
                                <StyledValue>
                                  <AmountBadge
                                    amount={domain.purchase_price.value}
                                    currency={
                                      domain.purchase_price.currency.code
                                    }
                                    textAlign="left"
                                  />
                                </StyledValue>
                              </StyledDomainDetail>
                              <StyledDomainDetail isTablet={isTablet}>
                                <Label>
                                  {translate(
                                    "domain.general-info.expiration-date"
                                  )}
                                </Label>
                                <StyledValue>{domain.expiry_date}</StyledValue>
                              </StyledDomainDetail>
                              <StyledDomainDetail isTablet={isTablet}>
                                <Label>
                                  {translate(
                                    "domain.general-info.renewal-price"
                                  )}
                                </Label>
                                <StyledValue>
                                  <AmountBadge
                                    amount={domain.renewal_price.value}
                                    currency={
                                      domain.renewal_price.currency.code
                                    }
                                    textAlign="left"
                                  />
                                </StyledValue>
                              </StyledDomainDetail>
                              <StyledDomainDetail isTablet={isTablet}>
                                <Label>
                                  {translate(
                                    "domain.general-info.renewal-period"
                                  )}
                                </Label>
                                <StyledValue>
                                  {domain.renewal_period}{" "}
                                  {domain.renewal_period > 1
                                    ? translate("general.years")
                                    : translate("general.year")}
                                </StyledValue>
                              </StyledDomainDetail>
                            </div>
                          </StyledDetailsWrapper>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StyledDomainDetail isTablet={isTablet}>
                                <Label>
                                  {translate("domain.general-info.auto-renew")}{" "}
                                  <CustomTooltip
                                    inline
                                    content={translate(
                                      "domain.settings.auto-renew-description"
                                    )}
                                  >
                                    <Icon icon="info" />
                                  </CustomTooltip>
                                </Label>
                                <StyledValue>
                                  <Togglebox
                                    toggled={!domain.is_auto_renew_disabled}
                                    disabled={this.props.renewNowSubmitting}
                                    submitting={this.props.renewNowSubmitting}
                                    alignLoader="right"
                                    onToggle={() =>
                                      !this.props.renewNowSubmitting &&
                                      domain.is_auto_renew_disabled
                                        ? this.props.onAutoRenewDomain(domain)
                                        : this.props.onShowDisableAutoRenewModal(
                                            domain
                                          )
                                    }
                                  />
                                </StyledValue>
                              </StyledDomainDetail>
                            </div>
                          </div>
                        </StyledContent>
                      </Container>
                    </StyledGeneralInfoWrapper>

                    <StyledGeneralInfoWrapper>
                      <div className="row">
                        <div className="col-6 col-sm-6 col-md-6">
                          <NameServersWithLoader
                            isLoading={this.props.isFetchingDomainNameServers}
                            domain={domain}
                          />
                        </div>
                      </div>
                    </StyledGeneralInfoWrapper>
                  </StyledGeneralInfoWrapper>
                </React.Fragment>
              )}
              {!this.props.isLaptop && (
                <React.Fragment>
                  <StyledGeneralInfoWrapper className="col-8 col-sm-8 col-md-8">
                    <StyledGeneralInfoWrapper>
                      <Container>
                        <StyledHeader>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StatusBadgeComponent>
                                {translate(
                                  "domain.statuses." + domain.status.name
                                )}
                              </StatusBadgeComponent>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-8 col-sm-8 col-md-8">
                              <div className="row">
                                <div className="col-12 col-sm-12 col-md-12">
                                  <StyledDomainTitle>
                                    {domain.domain_name}
                                  </StyledDomainTitle>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 col-sm-4 col-md-4 text-right">
                              <PrimaryButton
                                onClick={() => this.props.onRenewDomain(domain)}
                                disabled={
                                  this.props.renewNowSubmitting ||
                                  this.props.isFetchingDomains
                                }
                                submitting={this.props.renewNowSubmitting}
                                className="renew-button"
                              >
                                {translate("domain.general-info.renew-domain")}
                              </PrimaryButton>
                            </div>
                          </div>
                        </StyledHeader>
                        <StyledContent>
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StyledDetailsWrapper className="row">
                                <div className="col-12 col-sm-12 col-md-12">
                                  <StyledDomainDetail isTablet={isTablet}>
                                    <Label>
                                      {translate(
                                        "domain.general-info.registration-date"
                                      )}
                                    </Label>
                                    <StyledValue>
                                      {domain.purchase_date}
                                    </StyledValue>
                                  </StyledDomainDetail>
                                  <StyledDomainDetail isTablet={isTablet}>
                                    <Label>
                                      {translate(
                                        "domain.general-info.purchase-price"
                                      )}
                                    </Label>
                                    <StyledValue>
                                      <AmountBadge
                                        amount={domain.purchase_price.value}
                                        currency={
                                          domain.purchase_price.currency.code
                                        }
                                        textAlign="left"
                                      />
                                    </StyledValue>
                                  </StyledDomainDetail>
                                  <StyledDomainDetail isTablet={isTablet}>
                                    <Label>
                                      {translate(
                                        "domain.general-info.expiration-date"
                                      )}
                                    </Label>
                                    <StyledValue>
                                      {domain.expiry_date}
                                    </StyledValue>
                                  </StyledDomainDetail>
                                  <StyledDomainDetail isTablet={isTablet}>
                                    <Label>
                                      {translate(
                                        "domain.general-info.renewal-price"
                                      )}
                                    </Label>
                                    <StyledValue>
                                      <AmountBadge
                                        amount={domain.renewal_price.value}
                                        currency={
                                          domain.renewal_price.currency.code
                                        }
                                        textAlign="left"
                                      />
                                    </StyledValue>
                                  </StyledDomainDetail>
                                  <StyledDomainDetail isTablet={isTablet}>
                                    <Label>
                                      {translate(
                                        "domain.general-info.renewal-period"
                                      )}
                                    </Label>
                                    <StyledValue>
                                      {domain.renewal_period}{" "}
                                      {domain.renewal_period > 1
                                        ? translate("general.years")
                                        : translate("general.year")}
                                    </StyledValue>
                                  </StyledDomainDetail>
                                </div>
                              </StyledDetailsWrapper>
                              {domain.status.state === DOMAIN_STATUS_ACTIVE && (
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-12">
                                    <StyledDomainDetail isTablet={isTablet}>
                                      <Label>
                                        {translate(
                                          "domain.general-info.auto-renew"
                                        )}{" "}
                                        <CustomTooltip
                                          inline
                                          content={translate(
                                            "domain.settings.auto-renew-description"
                                          )}
                                        >
                                          <Icon icon="info" />
                                        </CustomTooltip>
                                      </Label>
                                      <StyledValue>
                                        <Togglebox
                                          toggled={
                                            !domain.is_auto_renew_disabled
                                          }
                                          disabled={
                                            this.props.renewNowSubmitting
                                          }
                                          submitting={
                                            this.props.renewNowSubmitting
                                          }
                                          alignLoader="right"
                                          onToggle={() =>
                                            !this.props.renewNowSubmitting &&
                                            domain.is_auto_renew_disabled
                                              ? this.props.onAutoRenewDomain(
                                                  domain
                                                )
                                              : this.props.onShowDisableAutoRenewModal(
                                                  domain
                                                )
                                          }
                                        />
                                      </StyledValue>
                                    </StyledDomainDetail>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </StyledContent>
                      </Container>
                    </StyledGeneralInfoWrapper>
                  </StyledGeneralInfoWrapper>
                  <StyledGeneralInfoWrapper className="col-4 col-sm-4 col-md-4">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12">
                        <NameServersWithLoader
                          isLoading={this.props.isFetchingDomainNameServers}
                          domain={domain}
                        />
                      </div>
                    </div>
                  </StyledGeneralInfoWrapper>
                </React.Fragment>
              )}
            </BrowserView>
          </div>
        )}
      </Translate>
    );
  }
}

export default withSizes(isLaptop)(GeneralInfo);
