import React from "react";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";

import InputField from "../../../../../../../components/InputField";

const StyledPriority = styled.div`
  display: inline-block;
  width: 80px;
  margin-right: 15px;
  vertical-align: top;
`;

const Priority = props => (
  <StyledPriority>
    <InputField
      label={props.translate("domain.zone-file.priority")}
      {...props}
    />
  </StyledPriority>
);

export default withLocalize(Priority);
