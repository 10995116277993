import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Translate } from "react-localize-redux";

import SidebarNav from "./SidebarNav";
import SidebarHeading from "./SidebarHeading";
import SidebarNavItem from "./SidebarNavItem";

import {
  ROUTE_INDEX,
  ROUTE_HOSTING,
  ROUTE_SERVERS,
  ROUTE_BILLING,
  ROUTE_DOMAINS,
  ROUTE_HELPDESK,
  ROUTE_MY_ACCOUNT,
  ROUTE_SHOP_DOMAINS,
  ROUTE_SHOP_HOSTING,
  ROUTE_SHOP_SERVERS, ROUTE_REFERRAL_PROGRAM
} from "../../routes/routes.js";

const StyledSidebar = styled.nav`
  display: ${props => (props.showMobileMenu ? "block" : "none")};
  background-color: ${theme.blueBase};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 250px;
  z-index: 100;
  padding: 115px 0 0;

  & .sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: 0.5rem;
    padding-bottom: 50px;
    overflow-x: hidden;
    overflow-y: auto;
  }

  @supports ((position: -webkit-sticky) or (position: sticky)) {
    & .sidebar-sticky {
      position: -webkit-sticky;
      position: sticky;
    }
  }
`;

const Sidebar = props => (
  <Translate>
    {({ translate }) => (
      <StyledSidebar
        className="sidebar d-print-none"
        showMobileMenu={props.showMobileMenu}
      >
        <div className="sidebar-sticky">
          <SidebarHeading title={translate("sidebar.menu")} />

          <SidebarNav>
            <SidebarNavItem
              title={translate("sidebar.home")}
              to={ROUTE_INDEX}
              active
              icon="home"
              exact={true}
            />
            <SidebarNavItem
              title={translate("sidebar.billing")}
              to={ROUTE_BILLING}
              icon="billing"
              exact={false}
            />
            <SidebarNavItem
              title={translate("sidebar.domains")}
              to={ROUTE_DOMAINS}
              exact={false}
              icon="domains"
            />
            <SidebarNavItem
              title={translate("sidebar.hosting")}
              to={ROUTE_HOSTING}
              exact={false}
              icon="hosting"
            />
            <SidebarNavItem
              title={translate("sidebar.servers")}
              to={ROUTE_SERVERS}
              exact={false}
              icon="cpanel"
            />

            <SidebarNavItem
              title={translate("sidebar.help-desk")}
              to={ROUTE_HELPDESK}
              exact={false}
              icon="help_desk"
            />
            <SidebarNavItem
                title={translate("sidebar.referral-program")}
                to={ROUTE_REFERRAL_PROGRAM}
                exact={false}
                icon="add_user"
                isNew={translate("sidebar.referral-new")}
            />

            <SidebarNavItem
              title={translate("sidebar.my-account")}
              to={ROUTE_MY_ACCOUNT}
              exact={false}
              icon="settings"
            />
          </SidebarNav>

          <SidebarHeading title={translate("sidebar.shop")} />

          <SidebarNav>
            <SidebarNavItem
              title={translate("sidebar.domains")}
              to={ROUTE_SHOP_DOMAINS}
              exact={false}
              icon="domains"
            />
            <SidebarNavItem
              title={translate("sidebar.hosting")}
              to={ROUTE_SHOP_HOSTING}
              exact={false}
              icon="hosting"
            />
            {
              <SidebarNavItem
                title={translate("sidebar.servers")}
                to={ROUTE_SHOP_SERVERS}
                exact={false}
                icon="cpanel"
              />
            }
          </SidebarNav>
        </div>
      </StyledSidebar>
    )}
  </Translate>
);

export default Sidebar;
