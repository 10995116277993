import React from "react";
import {Translate, withLocalize} from "react-localize-redux";
import { Field, Form, FormSpy } from "react-final-form";
import styled from "styled-components";
import Panel from "../../../components/Panel";
import Icon from "../../../components/Icon";
import InputField from "../../../components/InputField";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import {Link, withRouter} from "react-router-dom";
import {fetchCustomerPersonalInformation} from "../../Customer/actions/customerActions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    getCustomerPersonalInformation,
    isFetchingCustomerPersonalInformation
} from "../../Customer/reducers/customerReducer";
import {toastDanger, toastSuccess} from "../../ToastMessage";
import {generateReferralCode, receiveCode} from "../actions/referralActions";
import {getReferralDetails} from "../reducers/referralReducer";
import Modal from "../../../components/Modal";
import { validateEmail } from "../../../common/validationRules";
import {sendEmailToFriend} from "../../../services/referralService";

const InputWrapper = styled.div`
  .form-group {
    margin: 0;
    }
  `;
const StyledButtonCopy = styled(SecondaryButton)`
  position: absolute;
    bottom: 15px;
    left: 0;
      @media (max-width: 767px) {
      position:relative;
          bottom: 0;
    left: 0;
    float:left;
}
`;
const StyledButton = styled(SecondaryButton)`
  margin-top: 25px;
  `;



class GenerateCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showShareModal: false,
            isSubmitting: false
        }
    }

    generateCodeAction = ()=> {
        this.props.generateReferralCode();
    }

    componentDidMount() {

        if(this.props.customerPersonalInformation.referral_code){
            this.props.receiveCode(this.props.customerPersonalInformation.referral_code);
        }
    }

    validateValidEmail = values => {
        return {
            email: validateEmail(values.email)
        };
    };

    copyText(type){
        if(type === 'code'){
            this.copyToClipboard(this.props.referral.referral_code);
        } else {
            this.copyToClipboard(this.props.referral.referral_url);
        }
        toastSuccess(this.props.translate("referral-admin.referral-notification-sucess-subject"), this.props.translate("referral-admin.referral-notificiation-sucess"))
    }

    copyToClipboard(textToCopy) {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res() : rej();
                textArea.remove();
            });
        }
    }

    toggleModal(){
        this.setState({
            showShareModal : !this.state.showShareModal
        })
    }

    async postForm(values){
        this.setState({
            isSubmitting : true
        })
        await sendEmailToFriend(values.email).then((e) =>{
            this.setState({
                showShareModal : false,
                isSubmitting : false
            })
        toastSuccess(this.props.translate("referral-admin.sent-email-done"), this.props.translate("referral-admin.sent-email-done"))

        }).catch(()=>{
            this.setState({
                showShareModal : false,
                isSubmitting : false
            })
            toastDanger(this.props.translate('problem'),this.props.translate('problem') )
        })


    }

    render() {
        let description = this.props.translate(
            "referral-admin.generate-code-desc"
        )
        return (
            <Translate>
                    {({ translate }) => (


                    <Panel title={translate("referral-admin.generate-code")} description={description}

                    >
                        <div className="row">
                            <InputWrapper className="col-12 col-md-12 col-lg-12 col-xl-8">

                                {this.props.referral.referral_code && (
                                <InputField
                                    style={{margin:0}}
                                    type="text"
                                    label={translate(
                                        "referral-admin.generate-code"
                                    )}
                                    placeholder={translate(
                                        "referral-admin.code"
                                    )}
                                    tooltip={translate(
                                        "referral-admin.info-code"
                                    )}
                                    labelIcon="info"
                                    disabled={true}
                                    value={this.props.referral.referral_code}
                                />
                                )}
                                {!this.props.referral.referral_code && (
                                    <StyledButton onClick={() => this.generateCodeAction()}
                                                  disabled={this.props.referral.referral_code}

                                    >
                                        <Icon icon="success" />
                                        {translate(
                                            "referral-admin.button-generate-code"
                                        )}
                                    </StyledButton>
                                )}

                            </InputWrapper>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-4" style={{    display: 'flex',
                                alignItems: 'end'}}>

                                {this.props.referral.referral_code && (
                                    <StyledButton onClick={() => {this.copyText('code')}}>
                                       {/* <Icon icon="success" />*/}
                                        {translate(
                                            "referral-admin.referral-copy"
                                        )}</StyledButton>

                                )}
                            </div>
                        </div>
                        <br/>

                        {this.props.referral.referral_code && (
                        <div className="row">

                                <InputWrapper className="col-12 col-md-12 col-lg-12 col-xl-8">

                                <InputField
                                    type="text"
                                    labelIcon="info"
                                    label={translate(
                                        "referral-admin.referral-url"
                                    )}
                                    tooltip={translate(
                                        "referral-admin.info-link"
                                    )}
                                    placeholder={translate(
                                        "referral-admin.referral-url"
                                    )}
                                  /*  labelIcon="info"*/
                                    disabled={true}
                                    value={this.props.referral.referral_url}
                                />
                                </InputWrapper>
                            <div className="col-12 col-md-12 col-lg-12 col-xl-4" style={{    display: 'flex',
                                alignItems: 'end'}}>
                                <StyledButton onClick={() => {this.copyText('url')}}

                                >
                                    <Icon icon="save" />
                                    {translate(
                                        "referral-admin.referral-copy"
                                    )}
                                </StyledButton>
                            </div>
                        </div>

                        )}

                        {this.props.referral.referral_code && (
                            <div className="row">
                                <div className="col-12 col-sm-12 col-md-12">


                                        <StyledButton onClick={() =>{this.toggleModal()}}>
                                        <Icon icon="email" />
                                        {translate(
                                            "referral-admin.referral-email"
                                        )}
                                    </StyledButton>

                                </div>
                            </div>

                        )}
                        {this.state.showShareModal && (
                            <Modal
                                title={translate("referral-admin.share-modal")}

                                onCloseModal={() => this.toggleModal()}
                                body={() => (
                                    <div className="row">

                                        <div className="col-12 col-sm-12 col-md-12">
                                            <Form
                                                onSubmit={(e) => this.postForm(e)}
                                                validate={this.validateValidEmail}
                                                render={({ handleSubmit }) => (
                                                    <form id="share-via-email" onSubmit={handleSubmit}>
                                                        <Field
                                                            component={InputField}
                                                            type={'email'}
                                                            label={translate("referral-admin.enter-friend-email")}
                                                            placeholder={translate("referral-admin.email")}
                                                            name="email"
                                                        />
                                                        <div className="row d-flex justify-content-center ">
                                                            <button className="cookie-btn col-sm-10"
                                                                    disabled={this.state.isSubmitting}
                                                                   >{translate("referral-admin.send-email"
                                                            )}
                                                            </button>
                                                        </div>


                                                    </form>
                                                )}
                                            />
                                        </div>
                                    </div>
                                )}
                            />
                        )}
                    </Panel>
                        )}
            </Translate>
        )
    }
}



const mapStateToProps = state => {
    return {
        customerPersonalInformation: getCustomerPersonalInformation(state),
        referral : getReferralDetails(state),
        isFetchingCustomerPersonalInformation: isFetchingCustomerPersonalInformation(
            state
        ),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { fetchCustomerPersonalInformation, generateReferralCode, receiveCode },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLocalize(GenerateCode)));