import styled from "styled-components";
import Button from "../Button";
const ActionButton = styled(Button)`
  padding: 0 10px;
  margin-right: 0;
`;
ActionButton.defaultProps = {
  className: "btn action-button"
};

export default ActionButton;
