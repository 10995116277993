import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { isMobileOnly } from "react-device-detect";
import { Translate } from "react-localize-redux";

const WizardBreadcrumbsContainer = styled.div`
  margin-bottom: 30px;
`;

const WizardStep = styled.div`
  display: inline-block;
  margin-right: ${props => (props.isMobile ? "10px" : "40px;")};
  vertical-align: top;
  width: ${props => props.isMobile && "100px"};
  margin-bottom: ${props => props.isMobile && "15px"};
`;
const WizardStepIndex = styled.div`
  display: block;
  width: 32px;

  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  border-radius: 5px;
  background-color: ${props =>
    props.active ? theme.blueBase : theme.neutral2};
  margin-bottom: 5px;
  color: ${props => (props.active ? theme.white : theme.neutral6)};
`;
const WizardStepTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${props => (props.active ? theme.blueBase : theme.neutral5)};
`;

const WizardBreadcrumbs = ({ steps, active }) => (
  <Translate>
    {({ translate }) => (
      <WizardBreadcrumbsContainer className="row">
        <div className="col-12 col-sm-12 col-md-12">
          {steps.filter(step => step.visible).map((step, index) => (
            <WizardStep
              key={`cart-wizard-step-${index}`}
              isMobile={isMobileOnly}
            >
              <WizardStepIndex active={active === index}>
                {active === index ? (
                  <strong>{index}</strong>
                ) : (
                  <span>{index}</span>
                )}
              </WizardStepIndex>
              <WizardStepTitle active={active === index}>
                {active === index ? (
                  <strong>
                    {isMobileOnly
                      ? translate(step.title).replace(" ", "\n")
                      : translate(step.title)}
                  </strong>
                ) : (
                  <span>
                    {isMobileOnly
                      ? translate(step.title).replace(" ", "\n")
                      : translate(step.title)}
                  </span>
                )}
              </WizardStepTitle>
            </WizardStep>
          ))}
        </div>
      </WizardBreadcrumbsContainer>
    )}
  </Translate>
);

export default WizardBreadcrumbs;
