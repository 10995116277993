import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Flag from "../Flag";

const StyledFlagNormalDanger = styled.div`
  & .icon:not(.icon-close) {
    color: ${theme.redBase};
  }
`;

const FlagNormalDanger = ({ children, ...rest }) => (
  <StyledFlagNormalDanger>
    <Flag icon={rest.icon || "clear"} {...rest}>
      {children}
    </Flag>
  </StyledFlagNormalDanger>
);

FlagNormalDanger.defaultProps = {
  onDismiss: () => {}
};

export default FlagNormalDanger;
