import React from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { withLocalize } from "react-localize-redux";

import InputField from "../../../../components/InputField";

const NewAppForm = ({ onSubmit, validate, translate, onSubmitting }) => (
  <Form
    validate={validate}
    onSubmit={onSubmit}
    initialValues={{ name: "" }}
    render={({ handleSubmit, submitting, invalid }) => (
      <form onSubmit={handleSubmit} id="new-app-form">
        <Field
          component={InputField}
          name="name"
          label={translate("my-account.apps.app-name")}
        />

        <FormSpy
          subscription={{ submitting: true, invalid: true }}
          onChange={formstate => onSubmitting({ ...formstate })}
        />
      </form>
    )}
  />
);

export default withLocalize(NewAppForm);
