import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Translate } from "react-localize-redux";
import Parser from "html-react-parser";

import Container from "../../../../components/Containers/Container";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import CustomTooltip from "../../../../components/CustomTooltip";

const StyledProductPlan = styled.div`
  padding: 30px;
`;
const StyledProductPlanWrapper = styled.div`
  margin-bottom: 30px;
`;
const StyledPlanName = styled.h3`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.42;
  color: ${theme.neutralBase};
`;

const StyledPlanDescription = styled.p`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  display: block;
`;

const StyledPlanExtra = styled.p`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  display: block;
`;

const StyledPlanUnderline = styled.p`
  display: block;
  width: 50px;
  height: 3px;
  background-color: ${theme.blueBase};
  margin-bottom: 30px;
`;

const StyledPlanServicesList = styled.ul`
  margin-bottom: 20px;
`;
const StyledPlanServicesListItem = styled.li`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutralBase};
`;

const StyledPlanServicesListItemExtra = styled.li`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutralBase};
  padding-bottom: 3px;
`;

const StyledPlanPriceWrapper = styled.div`
  margin-bottom: 30px;
`;
const StyledPlanPriceCurrency = styled.span`
  font-size: 16px;
  line-height: 1.38;
  color: ${theme.neutral4};
  margin-right: 5px;
  text-transform: uppercase;
`;
const StyledPlanPriceAmount = styled.span`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.13;
  color: ${theme.neutralBase};
  margin-right: 5px;
`;
const StyledPlanPricePeriod = styled.span`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutralBase};
`;

const StyledProductReadMore = styled.div`
  margin-top: 20px;
`;

const StyledReadMoreToggle = styled.span`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  cursor: pointer;
`;

const DISPLAY_NUMBER_OF_FEATURES = 5;

class ProductPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReadMore: false
    };
  }

  onToggleReadMore = () => {
    this.setState({
      showReadMore: !this.state.showReadMore
    });
  };

  render() {
    const { hosting, onAddToCart, submitting, disabled } = this.props;
    let firstThreeFeatures = null;
    let remainingFeatures = null;

    if (hosting.details && hosting.details.features) {
      if (
        hosting.details.features.length > 0 &&
        hosting.details.features.length <= DISPLAY_NUMBER_OF_FEATURES
      ) {
        firstThreeFeatures = hosting.details.features;
      } else if (
        hosting.details.features.length > 0 &&
        hosting.details.features.length > DISPLAY_NUMBER_OF_FEATURES
      ) {
        firstThreeFeatures = hosting.details.features.slice(
          0,
          DISPLAY_NUMBER_OF_FEATURES
        );
        remainingFeatures = hosting.details.features.slice(
          DISPLAY_NUMBER_OF_FEATURES,
          hosting.details.features.length
        );
      }
    }

    let detailExtra = hosting.details.extra;
    if(hosting.details.prices.domain) {
      detailExtra = hosting.details.prices.domain.register.discountType === "percentage" ? 
        detailExtra.replace('{ddr}', hosting.details.prices.domain.register.value + '%') : detailExtra.replace('{ddr}', hosting.details.prices.domain.register.value);

      detailExtra = hosting.details.prices.domain.transfer.discountType === "percentage" ? 
        detailExtra.replace('{ddt}', hosting.details.prices.domain.transfer.value + '%') : detailExtra.replace('{ddt}', hosting.details.prices.domain.transfer.value);
    }
    
    return (
      <Translate>
        {({ translate }) => {
          if (!hosting.pricing.buy.billing_cycle.derivations.length) {
            return null;
          }
          return (
            <StyledProductPlanWrapper className="product-plan-wrapper">
              <Container>
                <StyledProductPlan>
                  <StyledPlanName>{hosting.name}</StyledPlanName>
                  {hosting.details.description && (
                    <StyledPlanDescription>
                      {hosting.details.description}
                    </StyledPlanDescription>
                  )}
                  <StyledPlanUnderline />
                  <StyledPlanServicesList className="list-unstyled">
                    {firstThreeFeatures &&
                      firstThreeFeatures.length > 0 &&
                      firstThreeFeatures.map(feature => (
                        <StyledPlanServicesListItem
                          key={`hosting-feature-${Math.random()}`}
                        >
                          {Parser(feature)}
                        </StyledPlanServicesListItem>
                      ))}
                  </StyledPlanServicesList>
                  <StyledPlanPriceWrapper>
                    <StyledPlanPriceCurrency>
                      {
                        hosting.pricing.buy.billing_cycle.derivations[0].price
                          .currency.code
                      }
                    </StyledPlanPriceCurrency>
                    <StyledPlanPriceAmount>
                      {
                        hosting.pricing.buy.billing_cycle.derivations[0].price
                          .value
                      }
                    </StyledPlanPriceAmount>
                    {hosting.details.tooltips.price && (
                      <CustomTooltip
                        inline
                        content={hosting.details.tooltips.price}
                      >
                        <StyledPlanPricePeriod>
                          /
                          {translate(
                            `shop.hostings.derivations.${hosting.pricing.buy.billing_cycle.derivations[0].code}`
                          )}{" "}
                          *
                        </StyledPlanPricePeriod>
                      </CustomTooltip>
                    )}
                    {!hosting.details.tooltips.price && (
                      <StyledPlanPricePeriod>
                        /
                        {translate(
                          `shop.hostings.derivations.${hosting.pricing.buy.billing_cycle.derivations[0].code}`
                        )}{" "}
                        *
                      </StyledPlanPricePeriod>
                    )}

                    {detailExtra && hosting.details.tooltips.extra && (
                      <CustomTooltip
                        inline
                        content={hosting.details.tooltips.extra}
                      >
                        <StyledPlanExtra>
                          {detailExtra} *
                        </StyledPlanExtra>
                      </CustomTooltip>
                    )}
                    {detailExtra &&
                      !hosting.details.tooltips.extra && (
                        <StyledPlanExtra>
                          {detailExtra} *
                        </StyledPlanExtra>
                      )}
                  </StyledPlanPriceWrapper>
                  <PrimaryButton
                    onClick={e => onAddToCart(hosting)}
                    className="w-100"
                    disabled={submitting || disabled}
                    submitting={submitting}
                  >
                    {translate("shop.hostings.choose-plan")}
                  </PrimaryButton>

                  <StyledProductReadMore>
                    {remainingFeatures && this.state.showReadMore && (
                      <StyledPlanServicesList className="list-unstyled">
                        {remainingFeatures &&
                          remainingFeatures.length > 0 &&
                          remainingFeatures.map(feature => (
                            <StyledPlanServicesListItemExtra
                              key={`hosting-feature-${Math.random()}`}
                            >
                              {Parser(feature)}
                            </StyledPlanServicesListItemExtra>
                          ))}
                      </StyledPlanServicesList>
                    )}
                    {remainingFeatures && (
                      <StyledReadMoreToggle
                        onClick={e => this.onToggleReadMore()}
                      >
                        {this.state.showReadMore
                          ? `- ${translate("shop.hostings.read-less")}`
                          : `+ ${translate("shop.hostings.read-more")}`}
                      </StyledReadMoreToggle>
                    )}
                  </StyledProductReadMore>
                </StyledProductPlan>
              </Container>
            </StyledProductPlanWrapper>
          );
        }}
      </Translate>
    );
  }
}
export default ProductPlan;
