import React from "react";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";

import NoticeInfo from "../../../../../components/Notices/NoticeInfo";
import NoticeSuccess from "../../../../../components/Notices/NoticeSuccess";
import SmallLogoLoader from "../../../../../components/Loaders/SmallLogoLoader";

const StyledStrong = styled.strong`
  cursor: pointer;
`;

const DomainProtection = ({
  domain,
  services,
  onShowBuyDomainIdProtection,
  isSubmittingDomainIdProtection,
  translate
}) => {
  const IdProtection = services.find(service => service.name === "IdProtect");

  if (IdProtection && IdProtection.state.state === "active") {
    return (
      <NoticeSuccess className="w-100">
        {translate("domain.domain-contacts.id-protection-active")}
      </NoticeSuccess>
    );
  }

  if (IdProtection && IdProtection.state.state === "inactive") {
    return (
      <NoticeInfo className="w-100">
        {translate("domain.domain-contacts.id-protection-inactive")}{" "}
        <StyledStrong onClick={() => onShowBuyDomainIdProtection(IdProtection)}>
          {!isSubmittingDomainIdProtection && translate("general.order-now")}
          {isSubmittingDomainIdProtection && (
            <span className="ml-3">
              {" "}
              <SmallLogoLoader />
            </span>
          )}
        </StyledStrong>
      </NoticeInfo>
    );
  }
  return null;
};

export default withLocalize(DomainProtection);
