import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import graphicLikeActive from "../../../assets/images/graphic-like-active.svg";

const StyledArticleMeta = styled.div`
  padding-bottom: 5px;

  & .article-meta-like {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: ${theme.green1};
    display: inline-block;
    cursor: pointer;
  }

  & .article-meta-like .like {
    width: 20px;
    height: 20px;
    margin-right: 10px;
    margin-top: -3px;
  }

  & .article-meta-like .article-meta-votes {
    font-size: 14px;
    line-height: 1.43;
    color: ${theme.green2};
    display: inline-block;
  }

  & .article-meta-category {
    padding: 5px 10px;
    border-radius: 3px;
    background-color: ${theme.neutral1};
    display: inline-block;
    margin-left: 10px;
    text-align: center;
  }

  & .article-meta-category span {
    font-size: 14px;
    line-height: 1.43;
    text-align: center;
    color: ${theme.neutral6};
  }
`;

const KnowledgeBaseArticleMeta = ({
  article,
  articleId,
  votes,
  categoryName,
  onVoteArticle
}) => (
  <StyledArticleMeta>
    <div
      className="article-meta-like"
      onClick={() => onVoteArticle(articleId, votes)}
    >
      <img src={graphicLikeActive} className="like" alt="" />
      <div className="article-meta-votes">{votes}</div>
    </div>

    <div className="article-meta-category">
      <span>{categoryName}</span>
    </div>
  </StyledArticleMeta>
);

export default KnowledgeBaseArticleMeta;
