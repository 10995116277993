import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { theme } from "../../../design/Themes";
import { Translate } from "react-localize-redux";

import PrimaryButton from "../../../components/Buttons/PrimaryButton";

import graphicsSupport from "../../../assets/images/icon-illustration-support.png";

import { ROUTE_CREATE_TICKET } from "../../../routes/routes";

const StyledTicketsWidgetEmptyState = styled.div`
  padding: 50px 0 25px;

  & img {
    margin-bottom: 30px;
    width: 100px;
    height: 100px;
  }

  & h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    text-align: center;
    margin-bottom: 5px;
  }

  & p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    color: ${theme.neutral6};
    text-align: center;
    margin-bottom: 30px;
  }
`;

class TicketsWidgetEmptyState extends React.Component {
  openTicket = () => {
    this.props.history.push(ROUTE_CREATE_TICKET);
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <StyledTicketsWidgetEmptyState>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 text-center justify-content-center">
                <img src={graphicsSupport} alt="" />
                <h4>{translate("home.widgets.tickets.all-done")}</h4>
                <p>{translate("home.widgets.tickets.all-done-description")}</p>
                <PrimaryButton onClick={this.openTicket}>
                  {translate("home.widgets.tickets.open-ticket")}
                </PrimaryButton>
              </div>
            </div>
          </StyledTicketsWidgetEmptyState>
        )}
      </Translate>
    );
  }
}

export default withRouter(TicketsWidgetEmptyState);
