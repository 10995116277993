import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Translate } from "react-localize-redux";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { ROUTE_CART, ROUTE_CART_CHECKOUT } from "../../../../routes/routes.js";

import { ROUTE_FORGOT_PASSWORD } from "../../../../routes/routes.js";

import Container from "../../../../components/Containers/Container";
import InputField from "../../../../components/InputField";

import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import NoticeDanger from "../../../../components/Notices/NoticeDanger";
import Checkbox from "../../../../components/Checkbox";
import { validateCheckbox } from "../../../../common/validationRules";
import TwoFactorAuthentication from "../../../Authentication/TwoFactorAuthentication/TwoFactorAuthentication.js";
import TwoFactorForm from "../LoginRegister/TwoFactorForm.js";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as authService from "../../../../services/authService";
import {
  twoFactorLogin,
  twoFactorSetupLater
} from "../../../Authentication/actions/authActions";

import { fetchCart } from "../../actions/cartActions";
import { fetchCustomerPersonalInformation } from "../../../Customer/actions/customerActions";

const StyledLoginRegisterWrapper = styled.div`
  margin-bottom: ${props => props.isMobile && "30px"};
`;

const StyledLoginForm = styled.form`
  margin-bottom: ${props => props.isMobile && "30px"};

  &:before {
    content: " ";
    position: absolute;
    top: 10%;
    right: -35px;
    height: 80%;
    border: 0.5px solid ${theme.neutral3};
  }
  & h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
  }

  & span.not(.icon) {
    display: block;
    font-size: 14px;
    color: ${theme.neutral6};
    margin-bottom: 30px;
  }

  & .login-button {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
  }

  & .forgot-password-link {
    display: block;
    color: ${theme.blueBase};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: 0.2px;
    text-align: center;
  }
`;

const StyledModeTitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  margin-bottom: 10px;
`;
const StyledModeDescription = styled.span`
  display: block;
  font-size: 14px;
  color: ${theme.neutral6};
  margin-bottom: 30px;
`;
const CheckboxInline = styled.div`
  padding-top: 15px;
  .custom-checkbox {
    width: 100% !important;
  }
  .input-help-block {
    position: relative !important;
  }
`;

const CheckboxDiv = styled.div`
  padding-top: 20px;
`;

const LoginForm = ({
  title,
  onSubmit,
  validate,
  isMobile,
  onSetReCaptchaToken
}) => (
  <Translate>
    {({ translate }) => (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        validateOnBlur={true}
        render={({
          handleSubmit,
          submitting,
          pristine,
          invalid,
          submitError,
          form
        }) => (
          <StyledLoginForm
            onSubmit={e => {
              form.blur("email");
              form.blur("password");
              const formState = form.getState();
              const { values } = formState;
              console.log(values.pp, values.pp2, values.pp2);
              handleSubmit(e);
            }}
            isMobile={isMobileOnly}
          >
            <h3>{translate("cart.wizard.login")}</h3>
            <span>{translate("cart.wizard.login-text")}</span>
            <Field
              name="email"
              placeholder={translate("cart.wizard.enter-your-email-address")}
              label={translate("cart.wizard.email")}
              type="text"
              component={InputField}
            />
            <Field
              name="password"
              placeholder={translate("cart.wizard.enter-your-password")}
              label={translate("cart.wizard.password")}
              type="password"
              component={InputField}
            />

            <GoogleReCaptcha onVerify={token => onSetReCaptchaToken(token)} />
            {submitError && <NoticeDanger>{submitError}</NoticeDanger>}
            <PrimaryButton
              type="submit"
              className="login-button"
              disabled={submitting}
              submitting={submitting}
            >
              {translate("cart.wizard.login")}
            </PrimaryButton>
            <Link to={ROUTE_FORGOT_PASSWORD} className="forgot-password-link">
              {translate("cart.wizard.forgot-your-password")}?
            </Link>
          </StyledLoginForm>
        )}
      />
    )}
  </Translate>
);

LoginForm.defaultProps = {
  title: "Login"
};

const LoginRegisterWrapper = styled.div`
  padding: ${props => (props.isMobile ? "20px" : "50px")};
`;

const ModeToggleWrapper = styled.div`
  padding-left: ${props => (props.isMobile ? "0" : "60px")};
  padding-right: ${props => (props.isMobile ? "0" : "20px")};
`;

const RegisterForm = ({
  onSubmit,
  validate,
  isMobile,
  onSetReCaptchaToken
}) => (
  <Translate>
    {({ translate }) => (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        validateOnBlur={true}
        render={({
          handleSubmit,
          submitting,
          pristine,
          invalid,
          submitError,
          form
        }) => (
          <StyledLoginForm
            onSubmit={e => {
              const formState = form.getState();
              const { values } = formState;

              console.log(values.pp);

              if (values.pp === undefined) {
                form.change("pp", false, () => {
                  console.log(values.pp);
                });
              }

              if (values.pp2 === undefined) {
                form.change("pp2", false, () => {
                  console.log(values.pp2);
                });
              }

              form.blur("email");
              form.blur("password");

              handleSubmit(e);
            }}
            isMobile={isMobile}
          >
            <h3>{translate("cart.wizard.create-an-account")}</h3>
            <span>{translate("cart.wizard.it-only-takes-a-minute")}</span>
            <Field
              name="email"
              placeholder={translate("cart.wizard.enter-your-email-address")}
              label={translate("cart.wizard.email")}
              type="text"
              component={InputField}
            />
            <Field
              name="password"
              placeholder={translate("cart.wizard.enter-your-password")}
              label={translate("cart.wizard.password")}
              type="password"
              component={InputField}
            />
            <CheckboxDiv>
              <CheckboxInline>
                <Field
                  component={Checkbox}
                  value={true}
                  label={
                    <>
                      {translate("familiar-with")}
                      <a href="https://mkhost.com/mk/other/legal/">
                        {translate("mkhost-privacy-policy")}
                      </a>
                    </>
                  }
                  name="pp"
                />
              </CheckboxInline>
              <CheckboxInline>
                <Field
                  component={Checkbox}
                  value={true}
                  label={translate("agreement-with-my-privacy-policy")}
                  name="pp2"
                />
              </CheckboxInline>
            </CheckboxDiv>
            <GoogleReCaptcha onVerify={token => onSetReCaptchaToken(token)} />
            {submitError && <NoticeDanger>{submitError}</NoticeDanger>}
            <PrimaryButton
              type="submit"
              className="login-button"
              disabled={submitting}
              submitting={submitting}
            >
              {translate("cart.wizard.continue-checkout")}
            </PrimaryButton>
          </StyledLoginForm>
        )}
      />
    )}
  </Translate>
);

const MODE_LOGIN = 1;
const MODE_REGISTER = 2;

class LoginRegister extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: MODE_LOGIN
    };
  }

  switchToLogin = () => {
    this.setState({
      mode: MODE_LOGIN
    });
  };

  switchToRegister = () => {
    this.setState({
      mode: MODE_REGISTER
    });
  };

  onAuthSuccess = token => {
    authService.setToken(token);
    this.props.twoFactorLogin(token);
    this.props.fetchCustomerPersonalInformation();
    this.props.fetchCart();
  };

  render() {
    const urlParams = new URLSearchParams(window.location.search);
    return (
      <StyledLoginRegisterWrapper isMobile={isMobileOnly}>
        <Translate>
          {({ translate }) => (
            <Container>
              <LoginRegisterWrapper isMobile={isMobile} className="row">
                <div className="col-12 col-sm-6 col-md-6">
                  {this.state.mode === MODE_LOGIN &&
                    !this.props.twoFactorEnabled &&
                    !this.props.phoneNumberRequired ? (
                    <LoginForm
                      onSubmit={this.props.onLogin}
                      validate={this.props.validateLogin}
                      isMobile={isMobile}
                      onSetReCaptchaToken={this.props.onSetReCaptchaToken}
                      twoFactorLogin={this.props.twoFactorLogin}
                      twoFactorSetupLater={this.props.twoFactorSetupLater}
                    />
                  ) : this.state.mode === MODE_REGISTER ? null : (
                    <TwoFactorForm
                      isTwoFAEnabled={this.props.twoFactorEnabled}
                      authToken={
                        this.props.twoFactorEnabled
                          ? urlParams.get("code")
                          : this.props.authToken
                      }
                      twoFactorPhoneNumber={this.props.twoFactorPhoneNumber}
                      onAuthSuccess={this.onAuthSuccess}
                    />
                  )}
                  {this.state.mode === MODE_REGISTER && (
                    <RegisterForm
                      isMobile={isMobile}
                      validate={this.props.validateRegister}
                      onSubmit={this.props.onRegister}
                      onSetReCaptchaToken={this.props.onSetReCaptchaToken}
                    />
                  )}
                </div>
                <div className="col-12 col-sm-6 col-md-6">
                  {this.props.phoneNumberRequired ? null : (
                    <ModeToggleWrapper isMobile={isMobileOnly}>
                      <StyledModeTitle>
                        {this.state.mode === MODE_LOGIN
                          ? translate("cart.wizard.new-customer")
                          : translate("cart.wizard.existing-customer")}
                      </StyledModeTitle>
                      <StyledModeDescription>
                        {this.state.mode === MODE_LOGIN
                          ? translate("cart.wizard.create-an-account-now")
                          : translate("cart.wizard.log-in-to-your-account")}
                      </StyledModeDescription>

                      <PrimaryButton
                        className="w-100"
                        onClick={() =>
                          this.state.mode === MODE_LOGIN
                            ? this.switchToRegister()
                            : this.switchToLogin()
                        }
                      >
                        {this.state.mode === MODE_LOGIN
                          ? translate("cart.wizard.create-an-account")
                          : translate("cart.wizard.login")}
                      </PrimaryButton>
                    </ModeToggleWrapper>
                  )}
                </div>
              </LoginRegisterWrapper>
            </Container>
          )}
        </Translate>
      </StyledLoginRegisterWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      twoFactorLogin,
      twoFactorSetupLater,
      fetchCart,
      fetchCustomerPersonalInformation
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginRegister);
