import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { Translate } from "react-localize-redux";

import Container from "../../../../../components/Containers/Container";
import DropdownButton from "../../../../../components/DropdownButton";
import DropdownItem from "../../../../../components/DropdownItem";
import Icon from "../../../../../components/Icon";
import Avatar from "../../../../../components/Avatar";
//import BusinessAvatar from "../../../../../components/BusinessAvatar";
import Label from "../../../../../components/Label";
import ExternalLink from "../../../../../components/ExternalLink";
import CustomTooltip from "../../../../../components/CustomTooltip";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";

import { CONTACT_TYPE_REGISTRANT } from "../DomainContactTypes";

const StyledDomainContactWrapper = styled.div`
  margin-bottom: 30px;
`;

const StyledDomainContact = styled.div`
  padding: 35px 30px;
`;

const StyledContactHeader = styled.div`
  margin-bottom: 30px;
`;
const StyledContactType = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
`;

const StyledContactName = styled.span`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${theme.neutralBase};
  position: relative;
  top: 10px;
`;

const StyledContactDetail = styled.div`
  margin-bottom: 20px;
`;
const StyledContactValue = styled.div`
  font-size: 16px;
  color: ${theme.neutralBase};
`;

const StyledQuestionMark = styled(ExternalLink)`
  font-size: 24px;
  font-weight: bold;
  display: block;
  margin-top: -2px;
  color: ${theme.neutral5};
`;

const DomainContact = ({
  title,
  contact,
  onEditDomainContact,
  onChangeDomainContact,
  disableEdit,
  disableChange,
  isMkDomain,
  type
}) => (
  <Translate>
    {({ translate, activeLanguage }) => (
      <StyledDomainContactWrapper>
        <Container>
          <StyledDomainContact>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <StyledContactHeader className="row">
                  <div className="col-9 col-sm-9 col-md-9">
                    <StyledContactType>{title}</StyledContactType>
                  </div>
                  <div className="col-3 col-sm-3 col-md-3 text-right">
                    {isMkDomain &&
                      contact.type === CONTACT_TYPE_REGISTRANT &&
                      activeLanguage && (
                        <CustomTooltip
                          inline={true}
                          preventClick={false}
                          content={translate(
                            "domain.domain-contacts.how-to-change-domain-registrant"
                          )}
                        >
                          <StyledQuestionMark
                            href={`https://mkhost.com/${activeLanguage.code}/other/knowledgebase/58`}
                          >
                            <Icon icon="info" />
                          </StyledQuestionMark>
                        </CustomTooltip>
                      )}
                    {!disableEdit && !disableChange && (
                      <DropdownButton
                        size="s"
                        alignMenu="right"
                        text={() => <Icon icon="edit" />}
                      >
                        {!disableEdit && (
                          <DropdownItem
                            onClick={e => onEditDomainContact(contact, type)}
                          >
                            {translate(
                              "domain.domain-contacts.edit-contact-info"
                            )}
                          </DropdownItem>
                        )}
                        {!disableChange && (
                          <DropdownItem
                            onClick={e => onChangeDomainContact(contact, type)}
                          >
                            {translate("domain.domain-contacts.change-contact")}
                          </DropdownItem>
                        )}
                      </DropdownButton>
                    )}
                  </div>
                </StyledContactHeader>
                <StyledContactDetail className="row">
                  <div className="col-3 col-sm-3 col-md-3">
                    <Avatar />
                  </div>
                  <div className="col-8 col-sm-8 col-md-8">
                    <StyledContactName className="align-middle">
                      {contact.first_name} {contact.last_name}
                    </StyledContactName>
                  </div>
                </StyledContactDetail>
                {!isMkDomain && (
                  <StyledContactDetail className="row">
                    <div className="col-3 col-sm-3 col-md-3">
                      <Label>
                        {translate("domain.domain-contacts.address")}
                      </Label>
                    </div>
                    <div className="col-8 col-sm-8 col-md-8">
                      <StyledContactValue>
                        {contact.address1} {contact.address2} {contact.address3}{" "}
                      </StyledContactValue>
                      <StyledContactValue>
                        {contact.zip} {contact.city}
                      </StyledContactValue>
                      <StyledContactValue>
                        {(contact.country &&
                          contact.country !== "" &&
                          contact.country.name) ||
                          contact.country.label}
                      </StyledContactValue>
                    </div>
                  </StyledContactDetail>
                )}
                {contact.type === CONTACT_TYPE_REGISTRANT && isMkDomain && (
                  <StyledContactDetail className="row">
                    <div className="col-3 col-sm-3 col-md-3">
                      <Label>
                        {translate("domain.domain-contacts.address")}
                      </Label>
                    </div>
                    <div className="col-8 col-sm-8 col-md-8">
                      <StyledContactValue>
                        {contact.address1} {contact.address2} {contact.address3}{" "}
                      </StyledContactValue>
                      <StyledContactValue>
                        {contact.zip} {contact.city}
                      </StyledContactValue>
                      <StyledContactValue>
                        {(contact.country &&
                          contact.country !== "" &&
                          contact.country.name) ||
                          contact.country.label}
                      </StyledContactValue>
                    </div>
                  </StyledContactDetail>
                )}
                <StyledContactDetail className="row">
                  <div className="col-3 col-sm-3 col-md-3">
                    <Label>{translate("domain.domain-contacts.phone")}</Label>
                  </div>
                  <div className="col-8 col-sm-8 col-md-8">
                    <StyledContactValue>{contact.phone}</StyledContactValue>
                  </div>
                </StyledContactDetail>
                <StyledContactDetail className="row">
                  <div className="col-3 col-sm-3 col-md-3">
                    <Label>{translate("domain.domain-contacts.email")}</Label>
                  </div>
                  <div className="col-8 col-sm-8 col-md-8">
                    <StyledContactValue>{contact.email}</StyledContactValue>
                  </div>
                </StyledContactDetail>
              </div>
            </div>
          </StyledDomainContact>
        </Container>
      </StyledDomainContactWrapper>
    )}
  </Translate>
);

DomainContact.defaultProps = {
  disableEdit: false,
  disableChange: false,
  isMkDomain: false
};

export default DomainContact;
