import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";

import Breadcrumb from "../../../components/Breadcrumb";
import BreadcrumbItem from "../../../components/Breadcrumb/BreadcrumbItem";
import Tabs from "../../../components/Tabs";
import Tab from "../../../components/Tabs/Tab";
import TabContent from "../../../components/Tabs/TabContent";
import ContentLoader from "../../../components/Loaders/ContentLoader";
import ActionModal from "../../../components/ActionModal";
import PrimaryWarningButton from "../../../components/Buttons/PrimaryWarningButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import UnexpectedError from "../../../components/UnexpectedError/UnexpectedError";
import HostingPassword from "../../../components/HostingPassword/HostingPassword";

import GeneralInfo from "./GeneralInfo";
import Addons from "./Addons";
//import Contacts from "./Contacts";

import {
  fetchCustomerProductDetails,
  getHostingCPanelDirectLoginLink,
  changeHostingCPanelPassword
} from "../../Customer/actions/customerActions";

import {
  isGettingHostingCPanelDirectLoginLink,
  isChangingHostingCPanelPassword
} from "../../Customer/reducers/customerReducer";

import { requestRenewProduct } from "../../Products/actions/productsActions";

import generatePassword from "../../../utils/randomPasswordGenerator";

import {
  compileRoute,
  ROUTE_INDEX,
  ROUTE_HOSTING,
  ROUTE_VIEW_HOSTING_GENERAL_INFO,
  ROUTE_VIEW_HOSTING_ADDONS,
  ROUTE_BILLING_VIEW_PROINVOICE,
  ROUTE_VIEW_BACKUP_ADDONS
  // ROUTE_VIEW_HOSTING_CONTACTS
} from "../../../routes/routes";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import {
  validatePasswordWithConfirm,
  validatePassword
} from "../../../common/validationRules";

import {
  HOSTING_STATUS_ACTIVE,
  HOSTING_STATUS_TERMINATED
} from "../HostingStatuses/HostingStatuses";

import {
  HOSTING_PANEL_TYPE_CPANEL,
  HOSTING_PANEL_TYPE_PLESK
} from "./HostingPanelTypes";
import BackupRestore from "./BackupRestore";

class ViewHosting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentHosting: null,
      unexpectedError: null,
      quickLinks: {
        cPanel: null
      },
      showCPanelChangePasswordModal: false,
      renewNowSubmitting: false,
      renewNowSubmittingId: 0,
      invalidHostingChangePassword: false,
      generatedPassword: "",
      showRenewHostingModal: false
    };

    this.viewHostingGeneralInfoPath = compileRoute(
      ROUTE_VIEW_HOSTING_GENERAL_INFO
    );
    this.viewBackupRestorationPath = compileRoute(ROUTE_VIEW_BACKUP_ADDONS);
    this.viewHostingAddonsPath = compileRoute(ROUTE_VIEW_HOSTING_ADDONS);
  };

    // this.viewHostingContactsPath = compileRoute(ROUTE_VIEW_HOSTING_CONTACTS);
    // }



  componentDidMount() {
    this.doFetchHosting(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.doFetchHosting(this.props.match.params.id);
    }
  }

  doFetchHosting = id => {
    this.setState({ currentHosting: null, unexpectedError: null });
    this.props.fetchCustomerProductDetails(id).then(response => {
      this.setState({ currentHosting: response.hosting });

      if (response.error) {
        this.setState({ unexpectedError: true });
        return;
      }

      if (
        response.hosting.status.code === HOSTING_STATUS_ACTIVE &&
        response.hosting.type === HOSTING_PANEL_TYPE_CPANEL
      ) {
        this.props
          .getHostingCPanelDirectLoginLink(response.hosting)
          .then(response => {
            if (response.hasOwnProperty("error") && response.error) {
              displayToastMessageForResponse("Hosting", response);
            } else {
              this.setState({ quickLinks: { cPanel: response } });
            }
          });
      }
    });
  };

  onShowCPanelChangePasswordModal = () => {
    this.setState({
      showCPanelChangePasswordModal: true
    });
  };

  onHideCPanelChangePasswordModal = () => {
    this.setState({
      showCPanelChangePasswordModal: false
    });
  };

  onShowRenewNowModal = () => {
    this.setState({
      showRenewHostingModal: true
    });
  };

  onHideRenewNowModal = () => {
    this.setState({
      showRenewHostingModal: false
    });
  };

  changeHostingCPanelPassword = () => {
    document
      .getElementById("update-hosting-password-form")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  validateChangePasswordSubmit = values => {
    return {
      newPassword: validatePassword(values.newPassword),
      confirmNewPassword: validatePasswordWithConfirm(
        values.newPassword,
        values.confirmNewPassword
      )
    };
  };

  randomPassword = () => {
    const password = generatePassword(2, 2, 2, 8);
    this.setState({
      generatedPassword: password
    });
  };

  submitHostingCPanelPassword = values => {
    this.props
      .changeHostingCPanelPassword(
        this.state.currentHosting,
        values.newPassword,
        values.confirmNewPassword
      )
      .then(response => {
        this.onHideCPanelChangePasswordModal();

        displayToastMessageForResponse(
          this.props.translate("hosting.title"),
          response
        );
      });
  };

  onSubmittingHostingChangePassword = state => {
    this.setState({
      invalidHostingChangePassword: state.invalid
    });
  };

  onRenewNow = hosting => {
    this.setState({
      renewNowSubmitting: true,
      renewNowSubmittingId: hosting.id
    });

    this.props
      .requestRenewProduct(hosting)
      .then(({ error, invoice, messages }) => {
        if (!error) {
          this.props.history.push(
            compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)({ id: invoice.id })
          );
        }
        if (error) {
          this.onHideRenewNowModal();
          this.setState({
            renewNowSubmitting: false,
            renewNowSubmittingId: 0
          });
          displayToastMessageForResponse(
            this.props.translate("hosting.title"),
            { error, messages }
          );
        }
      })
      .catch(error => {
        this.setState({
          renewNowSubmitting: false,
          renewNowSubmittingId: 0
        });
        this.onHideRenewNowModal();
        displayToastMessageForResponse(
          this.props.translate("hosting.title"),
          error
        );
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          {!this.state.currentHosting && !this.state.unexpectedError && (
            <ContentLoader />
          )}
          {this.state.unexpectedError && <UnexpectedError />}
          {this.state.currentHosting && (
            <Breadcrumb>
              <BreadcrumbItem
                to={ROUTE_INDEX}
                title={this.props.translate("breadcrumbs.home")}
              />
              <BreadcrumbItem
                to={ROUTE_HOSTING}
                title={this.props.translate("breadcrumbs.hosting")}
              />
              {this.state.currentHosting && (
                <BreadcrumbItem
                  title={
                    this.state.currentHosting.name +
                    " - " +
                    this.state.currentHosting.domain
                  }
                />
              )}
            </Breadcrumb>
          )}

          {this.state.currentHosting && (
            <Tabs
              defaultTab="general-info"
              renderTabs={(active, onSelect) => [
                <Tab
                  key="tab-general-info"
                  label="general-info"
                  active={active}
                  onSelect={onSelect}
                  linkTo={this.viewHostingGeneralInfoPath({
                    id: this.state.currentHosting.id,
                    domain: this.state.currentHosting.domain
                  })}
                >
                  {this.props.translate("hosting.general-info.title")}
                </Tab>,
                this.state.currentHosting.status.code !==
                HOSTING_STATUS_TERMINATED ? (
                  <Tab
                    key="tab-addons"
                    label="addons"
                    active={active}
                    onSelect={onSelect}
                    linkTo={this.viewHostingAddonsPath({
                      id: this.state.currentHosting.id,
                      domain: this.state.currentHosting.domain
                    })}
                  >
                    {this.props.translate("hosting.addons.title")}
                  </Tab>
                ) : (
                  ""
                ),
                this.state.currentHosting.status.code === HOSTING_STATUS_ACTIVE &&
                this.state.currentHosting.type === 'cpanel' ? (
                <Tab
                    key="tab-backup"
                    label="backup"
                    active={active}
                    onSelect={onSelect}
                    linkTo={this.viewBackupRestorationPath({
                      id: this.state.currentHosting.id,
                      domain: this.state.currentHosting.domain
                    })}
                >
                  {this.props.translate("hosting.restoration-tool.restore-tool")}
                </Tab>
                ) : (
                    ""
                ),
              ]}
              renderTabContent={active => (
                <Switch>
                  <Route
                    path={ROUTE_VIEW_HOSTING_GENERAL_INFO}
                    exact
                    render={() => (
                      <TabContent
                        key="tab-content-general-info"
                        label="general-info"
                        active={true}
                      >
                        <GeneralInfo
                          hosting={this.state.currentHosting}
                          isGettingHostingCPanelDirectLoginLink={
                            this.props.isGettingHostingCPanelDirectLoginLink
                          }
                          quickLinks={this.state.quickLinks}
                          onShowCPanelChangePasswordModal={
                            this.onShowCPanelChangePasswordModal
                          }
                          onRenewNow={this.onShowRenewNowModal}
                          renewNowSubmitting={this.state.renewNowSubmitting}
                        />
                      </TabContent>
                    )}
                  />
                  <Route
                    path={ROUTE_VIEW_HOSTING_ADDONS}
                    exact
                    render={() =>
                      this.state.currentHosting.status.code !==
                      HOSTING_STATUS_TERMINATED ? (
                        <TabContent
                          key="tab-addons"
                          label="addons"
                          active={true}
                        >
                          <Addons hosting={this.state.currentHosting} />
                        </TabContent>
                      ) : (
                        ""
                      )
                    }
                  />

                  <Route
                      path={ROUTE_VIEW_BACKUP_ADDONS}
                      exact
                      render={() => this.state.currentHosting.type === 'cpanel' ? (

                              <TabContent
                                  key="tab-backup"
                                  label="backup"
                                  active={true}
                              >

                                <BackupRestore hosting={this.state.currentHosting} />
                              </TabContent>
                          ): (
                          ""
                      )
                      }
                  />

                  <Route
                      path={ROUTE_VIEW_HOSTING_GENERAL_INFO}
                      exact
                      render={() => (
                          <TabContent
                              key="tab-content-general-info"
                              label="general-info"
                              active={true}
                          >
                            <GeneralInfo
                                hosting={this.state.currentHosting}
                                isGettingHostingCPanelDirectLoginLink={
                                  this.props.isGettingHostingCPanelDirectLoginLink
                                }
                                quickLinks={this.state.quickLinks}
                                onShowCPanelChangePasswordModal={
                                  this.onShowCPanelChangePasswordModal
                                }
                                onRenewNow={this.onShowRenewNowModal}
                                renewNowSubmitting={this.state.renewNowSubmitting}
                            />
                          </TabContent>
                      )}
                  />
                </Switch>
              )}
            />
          )}
        </div>

        {this.state.showCPanelChangePasswordModal && (
          <ActionModal
            title={`${this.props.translate(
              `hosting.general-info.change-${this.state.currentHosting.type}-password-confirmation-text`
            )} ${this.state.currentHosting.domain}`}
            onCloseActionModal={this.onHideCPanelChangePasswordModal}
            body={() => (
              <div>
                <HostingPassword
                  validateChangePasswordSubmit={
                    this.validateChangePasswordSubmit
                  }
                  onChangePassword={this.submitHostingCPanelPassword}
                  onSubmitting={this.onSubmittingHostingChangePassword}
                />
              </div>
            )}
            footer={() => (
              <React.Fragment>
                <SecondaryButton
                  onClick={() => this.onHideCPanelChangePasswordModal()}
                >
                  {this.props.translate("hosting.general-info.cancel")}
                </SecondaryButton>
                <PrimaryWarningButton
                  disabled={
                    this.props.isChangingHostingCPanelPassword ||
                    this.state.invalidHostingChangePassword
                  }
                  submitting={this.props.isChangingHostingCPanelPassword}
                  onClick={() => this.changeHostingCPanelPassword()}
                >
                  {this.props.translate("hosting.general-info.change")}
                </PrimaryWarningButton>
              </React.Fragment>
            )}
          />
        )}

        {this.state.showRenewHostingModal && (
          <ActionModal
            title={`${this.props.translate(
              "hosting.general-info.renew-hosting-confirmation-modal-title"
            )}`}
            onCloseActionModal={this.onHideRenewNowModal}
            body={() => (
              <div>
                {this.props.translate(
                  "hosting.general-info.renew-hosting-confirmation-modal-description"
                )}
              </div>
            )}
            footer={() => (
              <React.Fragment>
                <SecondaryButton onClick={() => this.onHideRenewNowModal()}>
                  {this.props.translate("hosting.general-info.cancel")}
                </SecondaryButton>
                <PrimaryButton
                  disabled={this.state.renewNowSubmitting}
                  submitting={this.state.renewNowSubmitting}
                  onClick={() => this.onRenewNow(this.state.currentHosting)}
                >
                  {this.props.translate("hosting.general-info.renew-hosting")}
                </PrimaryButton>
              </React.Fragment>
            )}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isGettingHostingCPanelDirectLoginLink: isGettingHostingCPanelDirectLoginLink(
      state
    ),
    isChangingHostingCPanelPassword: isChangingHostingCPanelPassword(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCustomerProductDetails,
      getHostingCPanelDirectLoginLink,
      changeHostingCPanelPassword,
      requestRenewProduct
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocalize(ViewHosting)));
