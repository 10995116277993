import * as invoicesService from "../../../../services/invoicesService.js";
import axios from "axios";

export const INVOICES_FEATURE = "features/Invoices";
export const INVOICES_CLEAR_FILTERS = `${INVOICES_FEATURE}/INVOICES_CLEAR_FILTERS`;
export const INVOICES_REQUEST = `${INVOICES_FEATURE}/INVOICES_REQUEST`;
export const INVOICES_REQUEST_STATUS_COLLECTION = `${INVOICES_FEATURE}/INVOICES_REQUEST_STATUS_COLLECTION`;
export const INVOICES_RECEIVE = `${INVOICES_FEATURE}/INVOICES_RECEIVE`;
export const INVOICES_RECEIVE_STATUS_COLLECTION = `${INVOICES_FEATURE}/INVOICES_RECEIVE_STATUS_COLLECTION`;
export const INVOICES_UPDATE_INVOICE_PAYER = `${INVOICES_FEATURE}/INVOICES_UPDATE_INVOICE_PAYER`;
export const INVOICES_LIST_SELECT_TABLE_ROW = `${INVOICES_FEATURE}/INVOICES_LIST_SELECT_TABLE_ROW`;
export const INVOICES_LIST_DESELECT_TABLE_ROW = `${INVOICES_FEATURE}/INVOICES_LIST_DESELECT_TABLE_ROW`;
export const INVOICES_LIST_SELECT_ALL_TABLE_ROWS = `${INVOICES_FEATURE}/INVOICES_LIST_SELECT_ALL_TABLE_ROWS`;
export const INVOICES_LIST_DESELECT_ALL_TABLE_ROWS = `${INVOICES_FEATURE}/INVOICES_LIST_DESELECT_ALL_TABLE_ROWS`;
export const INVOICES_FOR_WIDGET_REQUEST = `${INVOICES_FEATURE}/INVOICES_FOR_WIDGET_REQUEST`;
export const INVOICES_FOR_WIDGET_RECEIVE = `${INVOICES_FEATURE}/INVOICES_FOR_WIDGET_RECEIVE`;
export const INVOICES_MASS_PAY_REQUEST = `${INVOICES_FEATURE}/INVOICES_MASS_PAY_REQUEST`;
export const INVOICES_MASS_PAY_RECEIVE = `${INVOICES_FEATURE}/INVOICES_MASS_PAY_RECEIVE`;
export const INVOICES_DIGITAL_INVOICES_SETTINGS_REQUEST = `${INVOICES_FEATURE}/INVOICES_DIGITAL_INVOICES_SETTINGS_REQUEST`;
export const INVOICES_DIGITAL_INVOICES_SETTINGS_RECEIVE = `${INVOICES_FEATURE}/INVOICES_DIGITAL_INVOICES_SETTINGS_RECEIVE`;

export function selectInvoicesListTableRow(invoice) {
  return dispatch => {
    dispatch(invoicesListSelectTableRow(invoice));
  };
}

export function deselectInvoicesListTableRow(invoice) {
  return dispatch => {
    dispatch(invoicesListDeselectTableRow(invoice));
  };
}

export function selectAllInvoicesListTableRows() {
  return dispatch => {
    dispatch(invoicesListSelectAllTableRows());
  };
}

export function deselectAllInvoicesListTableRows() {
  return dispatch => {
    dispatch(invoicesListDeselectAllTableRows());
  };
}

export function fetchInvoices(limit, page, sort, filters) {
  return dispatch => {
    dispatch(requestInvoices());
    return invoicesService
      .all(limit, page, sort, filters)
      .then(
        result => {
          const { receipts, paging, filters, sort } = result.data.data;
          dispatch(receiveInvoices(receipts, paging, filters, sort));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function clearInvoicesFilters() {
  return dispatch => {
    dispatch(dispatchClearInvoicesFilters());
  };
}

export function fetchInvoicesForWidget(limit, sort, filter) {
  return dispatch => {
    dispatch(requestInvoicesForWidget());
    return invoicesService
      .all(limit, 1, sort, filter)
      .then(
        result => {
          const { invoices } = result.data.data;
          dispatch(receiveInvoicesForWidget(invoices));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function doMassPay(invoiceIds) {
  return dispatch => {
    dispatch(requestMassPay());
    return invoicesService
      .massPay(invoiceIds)
      .then(
        result => {
          const { error } = result.data;
          const { messages, invoice } = result.data.data;

          dispatch(receiveMassPay());

          return { error, messages, invoice };
        },
        error => {
          dispatch(receiveMassPay());
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(receiveMassPay());
        const { messages } = error.response.data.data;
        return { error: true, messages: messages };
      });
  };
}

// export function fetchInvoicesStatusCollection() {
//   return dispatch => {
//     dispatch(requestInvoicesStatusCollection());
//     return invoicesService
//       .fetchInvoiceFilterStatusCollection()
//       .then(
//         result => {
//           const { statuses } = result.data.data;
//           dispatch(receiveInvoicesStatusCollection(statuses));
//         },
//         error => {
//           console.log("something happened", error);
//         }
//       )
//       .catch(error => {
//         console.log(error);
//       });
//   };
// }

export function fetchInvoice(id) {
  return dispatch => {
    return invoicesService
      .fetchInvoice(id)
      .then(
        result => {
          const {
            receipt,
            payment_methods,
            payment_gateway
          } = result.data.data;
          return {
            invoice: receipt,
            payment_methods,
            payment_gateway,
            found: true
          };
        },
        error => {
          if (error.response.status === 404) {
            return {
              invoice: null,
              payment_methods: null,
              payment_gateway: null,
              found: false
            };
          }
        }
      )
      .catch(error => {
        return {
          invoice: null,
          payment_methods: null,
          payment_gateway: null,
          found: false
        };
      });
  };
}

export function downloadInvoice(id) {
  return dispatch => {
    return invoicesService
      .downloadInvoice(id)
      .then(
        result => {
          const { error } = !result.status ? result.data : { error: false };
          const { messages } = !result.status
            ? result.data.data
            : { messages: [] };

          return { error, messages, file: result };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function previewInvoice(id) {
  return dispatch => {
    return invoicesService.previewInvoice(id).then(result => {
      return result.data;
    });
  };
}

export function applyCreditToInvoice(proinvoice, amount) {
  return dispatch => {
    return invoicesService
      .applyCreditToInvoice(proinvoice, amount)
      .then(
        result => {
          const { error } = result.data;
          const { messages, invoice } = result.data.data;

          return { error, messages, invoice };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        const { messages } = error.response.data.data;
        return { error: true, messages: messages };
      });
  };
}

export function forwardInvoice(invoice, email, message = "") {
  return dispatch => {
    return invoicesService
      .forwardInvoice(invoice, email, message)
      .then(result => {
        const { error } = result.data;
        const { messages, sent } = result.data.data;
        return { error, messages, sent };
      });
  };
}

export function sendViaPost(invoice, address, reason) {
  return dispatch => {
    return invoicesService
      .sendViaPost(invoice, address, reason)
      .then(result => {
        const { error } = result.data;
        const { messages, posted, post_info } = result.data.data;
        return { error, messages, posted, post_info };
      });
  };
}

export function updateInvoicePayment(proinvoice, paymentMethod) {
  return dispatch => {
    return invoicesService
      .updateInvoicePaymentMethod(proinvoice, paymentMethod)
      .then(
        result => {
          const { error } = result.data;
          const { messages } = result.data.data;

          if (error) {
            return { error: true, messages: messages };
          }

          const {
            invoice,
            payment_methods,
            payment_gateway
          } = result.data.data;

          return {
            invoice,
            payment_methods,
            payment_gateway
          };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function payInvoice(proinvoice, paymentGateways) {
  return dispatch => {
    return invoicesService.payInvoice(proinvoice, paymentGateways);
  };
}

export function assignInvoicePayer(proinvoice, payer) {
  return dispatch => {
    return invoicesService
      .assignInvoicePayer(proinvoice, payer)
      .then(
        result => {
          const { error } = result.data;
          const { messages } = result.data.data;

          dispatch(updateInvoicePayer(proinvoice, payer));

          return { error, messages, proinvoice, payer };
        },
        error => {
          const { messages } = error.response.data.data;
          dispatch(updateInvoicePayer(proinvoice, payer));
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(updateInvoicePayer(proinvoice, payer));
        return { error: true, messages: [error.message] };
      });
  };
}

export function fetchInvoicesSettings() {
  return dispatch => {
    dispatch(requestDigitalInvoicesSettings());
    return invoicesService
      .fetchInvoicesSettings()
      .then(
        result => {
          const { settings } = result.data.data;
          dispatch(receiveDigitalInvoicesSettings(settings));
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function updateInvoicesSettings(enabled) {
  return dispatch => {
    dispatch(requestDigitalInvoicesSettings());
    return invoicesService
      .updateInvoicesSettings(enabled)
      .then(
        result => {
          const { settings } = result.data.data;
          dispatch(receiveDigitalInvoicesSettings(settings));
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

function dispatchClearInvoicesFilters() {
  return {
    type: INVOICES_CLEAR_FILTERS
  };
}

function requestInvoices() {
  return {
    type: INVOICES_REQUEST
  };
}

function requestInvoicesStatusCollection() {
  return {
    type: INVOICES_REQUEST_STATUS_COLLECTION
  };
}

function receiveInvoices(invoices, paging, filters, sort) {
  return {
    type: INVOICES_RECEIVE,
    payload: { invoices, paging, filters, sort }
  };
}

// function receiveInvoicesStatusCollection(statuses) {
//   return {
//     type: INVOICES_RECEIVE_STATUS_COLLECTION,
//     payload: { statuses }
//   };
// }

function updateInvoicePayer(proinvoice, payer) {
  return {
    type: INVOICES_UPDATE_INVOICE_PAYER,
    payload: { proinvoice, payer }
  };
}

function invoicesListSelectTableRow(product) {
  return {
    type: INVOICES_LIST_SELECT_TABLE_ROW,
    payload: product
  };
}

function invoicesListDeselectTableRow(product) {
  return {
    type: INVOICES_LIST_DESELECT_TABLE_ROW,
    payload: product
  };
}

function invoicesListSelectAllTableRows() {
  return {
    type: INVOICES_LIST_SELECT_ALL_TABLE_ROWS
  };
}

function invoicesListDeselectAllTableRows() {
  return {
    type: INVOICES_LIST_DESELECT_ALL_TABLE_ROWS
  };
}

function requestInvoicesForWidget() {
  return {
    type: INVOICES_FOR_WIDGET_REQUEST
  };
}

function receiveInvoicesForWidget(proinvoices) {
  return {
    type: INVOICES_FOR_WIDGET_RECEIVE,
    payload: proinvoices
  };
}

function requestMassPay() {
  return {
    type: INVOICES_MASS_PAY_REQUEST
  };
}

function receiveMassPay() {
  return {
    type: INVOICES_MASS_PAY_RECEIVE
  };
}

function requestDigitalInvoicesSettings() {
  return {
    type: INVOICES_DIGITAL_INVOICES_SETTINGS_REQUEST
  };
}

function receiveDigitalInvoicesSettings(settings) {
  return {
    type: INVOICES_DIGITAL_INVOICES_SETTINGS_RECEIVE,
    payload: settings
  };
}
