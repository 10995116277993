import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";

import { theme } from "../../../../../design/Themes";
import Container from "../../../../../components/Containers/Container";

import graphicSuccessGreen from "../../../../../assets/images/graphic-success-green.svg";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import SecondaryWarningButton from "../../../../../components/Buttons/SecondaryWarningButton";

import getIconForHostingAddon from "../../../../../utils/getIconForHostingAddon";

const StyledDomainAddon = styled.div`
  margin-bottom: 30px;
`;

const StyledDomainContainer = styled.div`
  padding: 30px;

  & .graphic-service-item {
    width: 40px;
    height: 45px;
  }

  & .graphic-success-green {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: -3px;
  }
`;

const StyledDomainAddonHeader = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: ${theme.neutral6};
`;

const StyledDomainAddonDescription = styled.div`
  font-size: 14px;
  color: ${theme.neutral6};
`;

const StyledDomainAddonFooter = styled.div`
  padding-top: 25px;

  & .currency {
    color: ${theme.neutral4};
    font-size: 16px;
    font-weight: 600;
  }

  & .amount {
    color: ${theme.neutralBase};
    font-size: 16px;
    font-weight: 600;
  }

  & .price-container {
    padding-top: 6px;
  }
`;

const Hosting = ({
  service,
  cancelService,
  addToCartService,
  isCancellingHostingService,
  isStoringItemInCart,
  addToCartSubmitting,
  addToCartSubmittingId
}) => (
  <Translate>
    {({ translate }) => (
      <StyledDomainAddon>
        <Container>
          <StyledDomainContainer>
            <div className="row">
              <div className="col-12 col-sm-2 col-md-2">
                <img
                  src={getIconForHostingAddon(service.name)}
                  className="graphic-service-item"
                  alt=""
                />
              </div>
              <div className="col-12 col-sm-10 col-md-10">
                <StyledDomainAddonHeader>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {service.name}
                      {service.state.state === "active" && (
                        <img
                          src={graphicSuccessGreen}
                          className="graphic-success-green"
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </StyledDomainAddonHeader>
                <StyledDomainAddonDescription>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {service.description}
                    </div>
                  </div>
                </StyledDomainAddonDescription>
                <StyledDomainAddonFooter>
                  <div className="row">
                    {service.state.state === "active" ? (
                      <div className="col-12 col-sm-12 col-md-12">
                        <SecondaryWarningButton
                          onClick={e => cancelService(service)}
                          className=""
                          disabled={isCancellingHostingService}
                          submitting={isCancellingHostingService}
                        >
                          {translate("domain.addons.cancel-service")}
                        </SecondaryWarningButton>
                      </div>
                    ) : (
                      <div className="col-12 col-sm-12 col-md-12">
                        <div className="row">
                          <div className="col-12 col-sm-6 col-md-6 price-container">
                            <span className="currency">
                              {service.pricing.buy.billing_cycle.derivations[0].price.currency.code.toUpperCase()}
                            </span>
                            <span className="amount">
                              {" "}
                              {
                                service.pricing.buy.billing_cycle.derivations[0]
                                  .price.value
                              }
                              /
                              {translate(
                                "shop.hostings.derivations." +
                                  service.pricing.buy.billing_cycle.derivations[0].name.toLowerCase()
                              )}
                            </span>
                          </div>
                          <div className="col-12 col-sm-6 col-md-6">
                            <SecondaryButton
                              onClick={e => addToCartService(service)}
                              className=""
                              disabled={isStoringItemInCart}
                              submitting={
                                addToCartSubmittingId === service.id &&
                                isStoringItemInCart
                              }
                            >
                              {translate("domain.addons.add-to-cart")}
                            </SecondaryButton>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </StyledDomainAddonFooter>
              </div>
            </div>
          </StyledDomainContainer>
        </Container>
      </StyledDomainAddon>
    )}
  </Translate>
);

export default Hosting;
