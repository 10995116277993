import React from "react";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { debounce } from "throttle-debounce";

import withSizes from "react-sizes";
import isLaptop from "../../../utils/isLaptopScreen";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Container from "../../../components/Containers/Container";
import Breadcrumb from "../../../components/Breadcrumb";
import BreadcrumbItem from "../../../components/Breadcrumb/BreadcrumbItem";
import withLoading from "../../../components/Loaders/WithLoading";
import KnowledgeBaseCategoriesLoader from "../../../components/Loaders/KnowledgeBaseCategoriesLoader";
import KnowledgeBaseCategoryArticlesLoader from "../../../components/Loaders/KnowledgeBaseCategoryArticlesLoader";
import KnowledgeBaseArticlesCategoryPanel from "../../../components/KnowledgeBase/KnowledgeBaseArticlesCategoryPanel";
import KnowledgeBaseArticlesSearchByKeywordResultsContainer from "../../../components/KnowledgeBase/KnowledgeBaseArticlesSearchByKeywordResultsContainer";
import KnowledgeBaseArticle from "../../../components/KnowledgeBase/KnowledgeBaseArticle";

import Form from "../../../components/Forms/Form";
import SearchField from "../../../components/SearchField";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import {
  compileRoute,
  ROUTE_INDEX,
  ROUTE_HELPDESK,
  ROUTE_HELPDESK_KNOWLEDGE_BASE,
  ROUTE_HELPDESK_VIEW_KNOWLEDGE_BASE_ARTICLE,
  ROUTE_HELPDESK_KNOWLEDGE_BASE_CATEGORY
} from "../../../routes/routes";

import {
  fetchKnowledgeBaseArticle,
  viewArticle,
  fetchHelpDeskKnowledgeBaseCategories,
  searchKnowledgeBaseArticles,
  voteArticle
} from "../../HelpDesk/actions/helpdeskActions";

import {
  getKnowledgeBaseCategories,
  isFetchingKnowledgeBaseCategories,
  getKnowledgeBaseMostPopularArticles,
  isFetchingKnowledgeBaseMostPopularArticles,
  getKnowledgeBaseArticleToView,
  isFetchingKnowledgeBaseArticle,
  getFetchedArticles,
  getKnowledgeBaseSearchedArticles,
  isFetchingKnowledgeBaseArticleByKeyword
} from "../../HelpDesk/reducers/helpdeskReducer";

const StyledKnowledgeBaseContainer = styled.div`
  & a.tickets-type {
    font-size: 16px;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }
`;

const StyledKnowledgeBase = styled.div`
  & .knowledge-base-search {
    padding: 25px 25px 10px 25px;
    border-bottom: 1px solid ${theme.neutral3};
  }

  & .knowledge-base-categories-title {
    padding: 25px 25px 25px 25px;
    border-bottom: 1px solid ${theme.neutral3};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--neutral-5);
  }
`;

const StyledKnowledgeBaseContainerCategoryArticles = styled.div`
  padding: 30px 30px 15px 30px;

  & .category-name {
    font-size: 20px;
    font-weight: 600;
    color: ${theme.neutralBase};
    margin-bottom: 5px;
  }

  & .category-description {
    margin-bottom: 40px;
    font-size: 16px;
  }
`;

class ViewKnowledgeBaseCategory extends React.Component {
  constructor(props) {
    super(props);

    this.knowledgeArticlePath = compileRoute(
      ROUTE_HELPDESK_VIEW_KNOWLEDGE_BASE_ARTICLE
    );

    this.knowledgeCategoryPath = compileRoute(
      ROUTE_HELPDESK_KNOWLEDGE_BASE_CATEGORY
    );

    this.state = {
      searchQuery: "",
      selectedCategory: {
        name: "",
        description: "",
        id: 0,
        articles: []
      }
    };

    this.searchArticlesByKeywordThrottled = debounce(
      1000,
      this.searchArticlesByKeyword
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setSelectedCategory(nextProps);
  }

  componentDidMount() {
    if (!this.props.knowledgeBaseCategories.length)
      this.props.fetchHelpDeskKnowledgeBaseCategories();

    this.setSelectedCategory(this.props);
  }

  setSelectedCategory(props) {
    const category = props.knowledgeBaseCategories.find(
      category => category.id === parseInt(this.props.match.params.id, 10)
    );

    if (category)
      this.setState({
        selectedCategory: category
      });
  }

  searchArticlesByKeyword = value => {
    if (value.length > 0) this.props.searchKnowledgeBaseArticles(value);
  };

  onChangeKeywordSearch = event => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.searchArticlesByKeywordThrottled(this.state.searchQuery);
    });
  };

  onVoteArticle = article => {
    return this.props.voteArticle(article).then(result => {
      if (result.article.id === result.prevArticle) {
        displayToastMessageForResponse(
          this.props.translate("help-desk.knowledge-base.title"),
          {
            error: true,
            messages: {
              0: this.props.translate("help-desk.knowledge-base.already-voted")
            }
          }
        );
      } else if (result.article.id !== result.prevArticle) {
        displayToastMessageForResponse(
          this.props.translate("help-desk.knowledge-base.title"),
          {
            error: false,
            messages: {
              0: this.props.translate("help-desk.knowledge-base.vote-added")
            }
          }
        );
      }
    });
  };
  render() {
    const StyledKnowledgeBaseContainerCategoriesWithLoading = withLoading(
      StyledKnowledgeBaseContainer,
      KnowledgeBaseCategoriesLoader
    );

    const StyledKnowledgeBaseContainerCategoryArticlesWithLoading = withLoading(
      StyledKnowledgeBaseContainerCategoryArticles,
      KnowledgeBaseCategoryArticlesLoader
    );

    return (
      <Translate>
        {({ translate }) => (
          <StyledKnowledgeBase>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <Breadcrumb>
                  <BreadcrumbItem
                    to={ROUTE_INDEX}
                    title={translate("breadcrumbs.home")}
                  />
                  <BreadcrumbItem
                    to={ROUTE_HELPDESK}
                    title={translate("breadcrumbs.help-desk")}
                  />
                  <BreadcrumbItem
                    to={ROUTE_HELPDESK_KNOWLEDGE_BASE}
                    title={translate("breadcrumbs.knowledge-base")}
                  />

                  <BreadcrumbItem title={this.state.selectedCategory.name} />
                </Breadcrumb>

                <div className="row">
                  <div className="col-5 col-sm-5 col-md-5">
                    <Container>
                      <div className="knowledge-base-categories-title">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            {translate("help-desk.knowledge-base.categories")}
                          </div>
                        </div>
                      </div>
                      <div className="knowledge-base-search">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <Form>
                              <SearchField
                                onChange={this.onChangeKeywordSearch}
                                name="search"
                                placeholder={translate(
                                  "help-desk.knowledge-base.search-placeholder"
                                )}
                              />
                            </Form>
                          </div>
                        </div>
                      </div>
                      <KnowledgeBaseArticlesSearchByKeywordResultsContainer
                        searchQuery={this.state.searchQuery}
                        isFetchingKnowledgeBaseArticleByKeyword={
                          this.props.isFetchingKnowledgeBaseArticleByKeyword
                        }
                        searchedArticles={this.props.searchedArticles}
                        knowledgeArticlePath={this.knowledgeArticlePath}
                      />
                      <StyledKnowledgeBaseContainerCategoriesWithLoading
                        isLoading={this.props.isFetchingKnowledgeBaseCategories}
                      >
                        {this.props.knowledgeBaseCategories.map(category => {
                          return (
                            <KnowledgeBaseArticlesCategoryPanel
                              key={`${`helpdesk-knowledge-base-category-${category.id}`}`}
                              category={category}
                              knowledgeCategoryPath={this.knowledgeCategoryPath}
                              selected={
                                category.id === this.state.selectedCategory.id
                              }
                            />
                          );
                        })}
                      </StyledKnowledgeBaseContainerCategoriesWithLoading>
                    </Container>
                  </div>
                  <div className="col-7 col-sm-7 col-md-7">
                    <Container>
                      <StyledKnowledgeBaseContainerCategoryArticlesWithLoading
                        isLoading={this.props.isFetchingKnowledgeBaseCategories}
                      >
                        <p className="category-name">
                          {this.state.selectedCategory.name}
                        </p>
                        <p className="category-description">
                          {this.state.selectedCategory.description}
                        </p>

                        {this.state.selectedCategory.articles.map(article => {
                          return (
                            <KnowledgeBaseArticle
                              key={`${`helpdesk-knowledge-category-article-${article.id}`}`}
                              title={article.title}
                              votes={article.votes}
                              onVoteArticle={this.onVoteArticle}
                              categoryName={article.category_name}
                              showMeta={true}
                              articleLink={this.knowledgeArticlePath({
                                id: article.id
                              })}
                            />
                          );
                        })}
                      </StyledKnowledgeBaseContainerCategoryArticlesWithLoading>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </StyledKnowledgeBase>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetchingKnowledgeBaseCategories: isFetchingKnowledgeBaseCategories(state),
    knowledgeBaseCategories: getKnowledgeBaseCategories(state),
    isFetchingKnowledgeBaseMostPopularArticles: isFetchingKnowledgeBaseMostPopularArticles(
      state
    ),
    knowledgeBaseMostPopularArticles: getKnowledgeBaseMostPopularArticles(
      state
    ),
    currentArticle: getKnowledgeBaseArticleToView(state),
    isFetchingKnowledgeBaseArticle: isFetchingKnowledgeBaseArticle(state),
    articles: getFetchedArticles(state),
    searchedArticles: getKnowledgeBaseSearchedArticles(state),
    isFetchingKnowledgeBaseArticleByKeyword: isFetchingKnowledgeBaseArticleByKeyword(
      state
    )
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchKnowledgeBaseArticle,
      viewArticle,
      fetchHelpDeskKnowledgeBaseCategories,
      searchKnowledgeBaseArticles,
      voteArticle
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(ViewKnowledgeBaseCategory));
