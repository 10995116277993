import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

const StyledProgressBar = styled.div`
  background-color: ${theme.blue1};
  border: 1px solid ${theme.blueBase};
  border-radius: 3px;
  height: 40px;

  & .progress-bar {
    background-color: ${theme.blueBase};
    background-size: 1px 1px;
  }

  & .progress-bar-striped {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 10px 10px;
  }
`;

const ProgressBarWrapper = styled.div`
  position: relative;
  padding-right: ${props => (props.percentage !== null ? "100px" : "0")};

  & .progress-bar-percent {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 30px;
    font-weight: 600;
    line-height: 0.93;
    color: ${theme.blueBase};
  }
`;

const ProgressBar = ({ striped, value, minValue, maxValue, percentage }) => (
  <ProgressBarWrapper percentage={percentage}>
    <StyledProgressBar className="progress">
      <div
        className={`progress-bar ${striped ? "progress-bar-striped" : ""}`}
        style={{ width: `${value}%` }}
        aria-valuenow={value}
        aria-valuemin={minValue}
        aria-valuemax={maxValue}
      />
    </StyledProgressBar>
    {percentage !== null && (
      <div className="progress-bar-percent">{percentage}%</div>
    )}
  </ProgressBarWrapper>
);

ProgressBar.defaultProps = {
  striped: false,
  value: 0,
  minValue: 0,
  maxValue: 100,
  percentage: null
};

export default ProgressBar;
