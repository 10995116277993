import { combineReducers } from "redux";

import authReducer from "../features/Authentication/reducers/authReducer";
import cartReducer from "../features/Cart/reducers/cartReducer";
import domainsReducer from "../features/Domains/reducers/domainsReducer";
import productsReducer from "../features/Products/reducers/productsReducer";
import serversReducer from "../features/Servers/reducers/serversReducer";
import customerReducer from "../features/Customer/reducers/customerReducer";
import proInvoicesReducer from "../features/Billing/ProInvoices/reducers/proInvoicesReducer";
import referralReducer from "../features/ReferalProgram/reducers/referralReducer";
import invoicesReducer from "../features/Billing/Invoices/reducers/invoicesReducer";
import paymentMethodsReducer from "../features/PaymentMethods/reducers/paymentMethodsReducer";
import creditReducer from "../features/Credit/reducers/creditReducer";
import helpdeskReducer from "../features/HelpDesk/reducers/helpdeskReducer";
import securityReducer from "../features/Security/reducers/securityReducer";
import preferenceReducer from "../features/Preferences/reducers/preferenceReducer";
import addressingReducer from "../features/Addressing/reducers/addressingReducer";
import appsReducer from "../features/MyAccount/Apps/reducers/appsReducer";
import notificationsReducer from "../features/Notifications/reducers/notificationsReducer";
import verifyEmailReducer from "../features/EmailVerification/reducers/emailVerificationReducer";

export default combineReducers({
  auth: authReducer,
  cart: cartReducer,
  catalog: combineReducers({
    domains: domainsReducer,
    products: productsReducer,
    servers: serversReducer
  }),
  customer: customerReducer,
  proinvoices: proInvoicesReducer,
  referral : referralReducer,
  invoices: invoicesReducer,
  paymentMethods: paymentMethodsReducer,
  credit: creditReducer,
  helpdesk: helpdeskReducer,
  security: securityReducer,
  preferences: preferenceReducer,
  addressing: addressingReducer,
  apps: appsReducer,
  notifications: notificationsReducer,
  emailVerification : verifyEmailReducer
});
