import React from "react";

import Loader from "../Loader";

const DomainLockLoader = () => (
  <Loader width="300" height="320">
    <rect x="1" y="3" width="300" height="20" rx="1" />
    <rect x="1" y="40" width="300" height="50" rx="1" />
    <rect x="1" y="105" width="50" height="25" rx="1" />
    <rect x="250" y="105" width="50" height="25" rx="1" />
  </Loader>
);

export default DomainLockLoader;
