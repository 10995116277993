import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  isMobileOnly,
  isTablet,
  isBrowser,
  MobileOnlyView
} from "react-device-detect";
import withSizes from "react-sizes";
import isLaptop from "../../../utils/isLaptopScreen";

import styled from "styled-components";
import { theme } from "../../../design/Themes";

import { getItems } from "../../Cart/reducers/cartReducer";

import Container from "../../../components/Containers/Container";

import CompactCartProductsList from "./CompactCartProducstList";
import CartTotal from "../CartTotal";

import { Translate } from "react-localize-redux";

const StyledCartSummaryWrapper = styled.div`
  margin-bottom: 30px;
`;
const StyledCartSummary = styled.div`
  padding: ${props => (props.isMobile ? "20px" : "50px")};
  & .compact-cart-summary-header {
    margin-bottom: 50px;
  }

  & .compact-cart-summary-header h4 {
    color: ${theme.neutralBase};
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
  }

  & .compact-cart-summary-header span.compact-cart-summary-count {
    font-size: 12px;
    line-height: 1.33;
    background-color: ${theme.blueBase};
    color: ${theme.white};
    text-align: center;
    padding: 1px 6px;
    border-radius: 3px;
    margin-left: 5px;
  }

  & .compact-cart-summary-header span.compact-cart-summary-clear {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blueBase};
    line-height: 1.43;
    letter-spacing: 0.2px;
    cursor: pointer;
  }
`;

const StyledCartSummaryContainer = styled.div``;

const CartSummary = ({ title, children, ...rest }) => (
  <Translate>
    {({ translate }) => (
      <StyledCartSummaryWrapper>
        <Container>
          <StyledCartSummary {...rest} isMobile={isMobileOnly}>
            <div className="compact-cart-summary-header">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <h4>{translate("cart.cart-summary")}</h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <CompactCartProductsList products={rest.products} />
              </div>
            </div>
            <MobileOnlyView>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledCartSummaryContainer>
                    <CartTotal
                      customerCurrencyCode={rest.customerCurrencyCode}
                      products={rest.products}
                      total={rest.cartTotal}
                      totalDiscount={rest.cartTotalDiscount}
                    />
                  </StyledCartSummaryContainer>
                </div>
              </div>
            </MobileOnlyView>
            {(isTablet || isBrowser) && (
              <div className="row">
                <div
                  className={
                    isMobileOnly || isTablet || isLaptop
                      ? "col-7 col-sm-7 col-md-7"
                      : "col-5 col-sm-5 col-md-5"
                  }
                />
                <div
                  className={
                    isMobileOnly || isTablet || isLaptop
                      ? "col-5 col-sm-5 col-md-5"
                      : "col-6 col-sm-6 col-md-6"
                  }
                >
                  <StyledCartSummaryContainer>
                    <CartTotal
                      customerCurrencyCode={rest.customerCurrencyCode}
                      products={rest.products}
                      total={rest.cartTotal}
                      totalDiscount={rest.cartTotalDiscount}
                    />
                  </StyledCartSummaryContainer>
                </div>
              </div>
            )}
          </StyledCartSummary>
        </Container>
      </StyledCartSummaryWrapper>
    )}
  </Translate>
);

const mapStateToProps = state => {
  return {
    products: getItems(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch);
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(CartSummary));
