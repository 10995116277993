import React from "react";
import { Translate } from "react-localize-redux";

import Panel from "../../../../../components/Panel";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import CustomTooltip from "../../../../../components/CustomTooltip";

const DomainTransfer = ({
  onGetEppTransferCode,
  eppTransferCode,
  fetchingEppTransferCode,
  domain //@TODO, temp for rs and al tlds
}) => (
  <Translate>
    {({ translate }) => (
      <Panel
        title={translate("domain.settings.domain-transfer")}
        description={translate("domain.settings.domain-transfer-description")}
      >
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6">
            {translate("domain.settings.epp-code")}
            {eppTransferCode && `: ${eppTransferCode}`}
          </div>
          <div className="col-12 col-sm-6 col-md-6 text-right">
            {(domain.domain_name.endsWith(".al") || domain.domain_name.endsWith(".rs")) ? (
              <CustomTooltip
                trigger={["hover"]}
                content={translate("domain.settings.get-epp-code-not-supported-for-domain")}
              >
                <div className="d-inline-block">
                  <SecondaryButton
                    disabled={true}
                    submitting={fetchingEppTransferCode}
                    onClick={e => onGetEppTransferCode()}
                  >
                    {translate("domain.settings.get-code")}
                  </SecondaryButton>
                </div>
              </CustomTooltip>
            ) : (
                <div className="d-inline-block">
                  <SecondaryButton
                    disabled={fetchingEppTransferCode}
                    submitting={fetchingEppTransferCode}
                    onClick={e => onGetEppTransferCode()}
                  >
                    {translate("domain.settings.get-code")}
                  </SecondaryButton>
                </div>
              )}
          </div>
        </div>
      </Panel>
    )}
  </Translate>
);

export default DomainTransfer;
