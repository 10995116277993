import React from "react";
import styled from "styled-components";

const StyledTableHeading = styled.th`
  & span:hover,
  span:active {
    color: #7b8ba7;
    text-decoration: none;
  }

  & span.sort-by {
    position: relative;
    display: block;
  }

  & span.sort-by:before,
  span.sort-by:after {
    border: 4px solid transparent;
    content: "";
    display: block;
    height: 0;
    right: -10px;
    top: 50%;
    position: absolute;
    width: 0;
    opacity: 0.3;
  }

  & span.sort-by:before {
    border-bottom-color: #666666;
    margin-top: -9px;
  }

  & span.sort-by:after {
    border-top-color: #666666;
    margin-top: 1px;
  }

  & span.order-asc:before {
    border-bottom-color: #1b75bc;
    opacity: 1;
  }

  & span.order-desc:after {
    border-top-color: #1b75bc;
    opacity: 1;
  }

  & span.order-active {
    color: #1b75bc;
    opacity: 1;
  }
`;

const TableHeading = ({ children, ...rest }) => (
  <StyledTableHeading {...rest}>
    {rest.isSortable ? (
      <span
        onClick={e =>
          rest.changeListOrderTrigger(rest.sortBy, rest.sortDirection)
        }
        className={`sort-by ${
          rest.isDefaultSort
            ? rest.sortDirection === "DESC"
              ? "order-desc order-active"
              : "order-asc order-active"
            : ""
        }`}
      >
        {children}
      </span>
    ) : (
      children
    )}
  </StyledTableHeading>
);

export default TableHeading;
