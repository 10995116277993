import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Translate } from "react-localize-redux";

import generalErrorIcon from "../../assets/images/general-error.png";

import PrimaryButton from "../../components/Buttons/PrimaryButton";
import FeedbackForm from "../../features/FeedbackForm";

const StyledUnexpectedError = styled.div`
  padding: 50px 0 25px;
`;

const StyledUnexpectedErrorContainer = styled.div`
  background-color: ${theme.white};
  display: inline-block;
  margin-top: 100px;
  padding: 100px 80px;
  max-width: 600px;

  & img {
    margin-bottom: 30px;
    width: 120px;
    height: 120px;
  }

  & .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    text-align: center;
    margin-top: 5px;
  }
`;

class UnexpectedError extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate }) => (
          <StyledUnexpectedError>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 text-center justify-content-center">
                <StyledUnexpectedErrorContainer>
                  <img src={generalErrorIcon} alt="" />
                  <h4 className="title">{translate("error.title")}</h4>
                  <p className="empty-cart-text">{translate("error.text")}</p>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 text-center justify-content-center">
                      <FeedbackForm>
                        {onShow => (
                          <PrimaryButton size="l" onClick={() => onShow()}>
                            {translate("error.report-error")}
                          </PrimaryButton>
                        )}
                      </FeedbackForm>
                    </div>
                  </div>
                </StyledUnexpectedErrorContainer>
              </div>
            </div>
          </StyledUnexpectedError>
        )}
      </Translate>
    );
  }
}

export default UnexpectedError;
