import axios from "axios";
import Cookies from "universal-cookie";

import {
  ROOT_DOMAIN_NO_HTTPS,
  CART_API_ENDPOINT,
  CART_ADD_ITEM_API_ENDPOINT,
  CART_REMOVE_ITEM_API_ENDPOINT,
  CART_UPDATE_ITEM_API_ENDPOINT,
  CART_ATTACH_SERVICE_TO_PRODUCT_API_ENDPOINT,
  CART_DETACH_SERVICE_TO_PRODUCT_API_ENDPOINT,
  CART_ATTACH_ASSISTANT_TO_ORDER_ITEM,
  CART_UPDATE_ASSISTANT_TO_ORDER_ITEM,
  CART_CLEAR_API_ENDPOINT,
  CART_CHECKOUT_API_ENDPOINT,
  CART_APPLY_PROMOTION_CODE_API_ENDPOINT,
  CART_CLEAR_PROMOTION_CODE_API_ENDPOINT,
  CART_APPLY_REFERRER_CODE_API_ENDPOINT,
  CART_CLEAR_REFERRER_CODE_API_ENDPOINT
} from "./endpoints";

import {
  PRODUCT_DOMAIN,
  PRODUCT_PRODUCT,
  PRODUCT_CLOUD_SERVER,
  PRODUCT_DEDICATED_SERVER,
  DOMAIN_SERVICE,
  HOSTING_SERVICE,
  CLOUD_SERVER_SERVICE,
  DEDICATED_SERVER_SERVICE
} from "../features/Cart/Products/ProductTypes";

const serviceTypes = {
  [PRODUCT_DOMAIN]: DOMAIN_SERVICE,
  [PRODUCT_PRODUCT]: HOSTING_SERVICE,
  [PRODUCT_CLOUD_SERVER]: CLOUD_SERVER_SERVICE,
  [PRODUCT_DEDICATED_SERVER]: DEDICATED_SERVER_SERVICE
};

const cartName = "mkhostCart";

export function all() {
  return axios.get(CART_API_ENDPOINT, {
    params: {
      cart: getCartId()
    }
  });
}

export function applyPromotionCode(promotionCode) {
  return axios.post(CART_APPLY_PROMOTION_CODE_API_ENDPOINT, {
    promotion_code: promotionCode
  });
}

export function clearPromotionCode() {
  return axios.post(CART_CLEAR_PROMOTION_CODE_API_ENDPOINT);
}

export function applyReferrerCode(referral_code) {
  return axios.post(CART_APPLY_REFERRER_CODE_API_ENDPOINT, {
    referrerCode: referral_code
  });
}

export function clearReferrerCode() {
  return axios.post(CART_CLEAR_REFERRER_CODE_API_ENDPOINT);
}

export function add(product) {
  return axios.post(CART_ADD_ITEM_API_ENDPOINT, {
    ...product,
    cart: getCartId()
  });
}

export function remove(product) {
  return axios.delete(CART_REMOVE_ITEM_API_ENDPOINT, {
    data: {
      ...product,
      cart: getCartId()
    }
  });
}

export function update(product, type, period) {
  return axios.put(CART_UPDATE_ITEM_API_ENDPOINT, {
    cart: getCartId(),
    item_id: product.id,
    config: {
      type,
      period
    },
    quantity: 1
  });
}

export function updateSettings(server, settings) {
  return axios.put(CART_UPDATE_ITEM_API_ENDPOINT, {
    cart: getCartId(),
    item_id: server.id,
    ...server,
    ...settings
  });
}

export function updateDomainEPPCode(product, eppcode) {
  return axios.put(CART_UPDATE_ITEM_API_ENDPOINT, {
    cart: getCartId(),
    item_id: product.id,
    config: {
      ...product.config,
      eppcode
    },
    quantity: 1
  });
}

export function updateNameserversForDomainInCart(product, nameservers) {
  return axios.put(CART_UPDATE_ITEM_API_ENDPOINT, {
    cart: getCartId,
    item_id: product.id,
    config: {
      ...product.config,
      nameservers
    },
    quantity: 1
  });
}

export function checkoutCart(cart, paymentMethod) {
  return axios.post(CART_CHECKOUT_API_ENDPOINT, {
    cart,
    payment_method: paymentMethod
  });
}

export function attachServiceToProduct(service, product) {
  return axios.post(CART_ATTACH_SERVICE_TO_PRODUCT_API_ENDPOINT, {
    order_item_id: product.id,
    services: [
      {
        service_id: service.id,
        type: serviceTypes[product.type],
        billing_cycle: service.pricing.buy.billing_cycle.code
      }
    ],
    cart: getCartId()
  });
}

export function removeServiceFromProduct(service, product) {
  return axios.post(CART_DETACH_SERVICE_TO_PRODUCT_API_ENDPOINT, {
    order_item_id: product.id,
    detach_id: service.detach_id,
    cart: getCartId()
  });
}

export function attachDomainAssistantToOrderItem(domain, contactType, contact) {
  return axios.post(CART_ATTACH_ASSISTANT_TO_ORDER_ITEM, {
    order_item_id: domain.id,
    type: contactType,
    ...contact
  });
}

export function updateDomainAssistantToOrderItem(domain, contactType, contact) {
  return axios.put(CART_UPDATE_ASSISTANT_TO_ORDER_ITEM, {
    order_item_id: domain.id,
    type: contactType,
    ...contact
  });
}

export function clearCart() {
  return axios.put(CART_CLEAR_API_ENDPOINT, {
    cart: getCartId()
  });
}

export function storeCartId(id) {
  const cookies = new Cookies();
  try {
    localStorage.setItem(cartName, id);
    cookies.set(cartName, id, {
      path: "/",
      domain: "." + ROOT_DOMAIN_NO_HTTPS
    });
  } catch (e) {
    //@todo use sessionstorage if in incognito mode?
    console.log(e);
  }
}

export function removeCartId() {
  const cookies = new Cookies();
  try {
    localStorage.removeItem(cartName);
    cookies.remove(cartName, { path: "/", domain: "." + ROOT_DOMAIN_NO_HTTPS });
  } catch (e) {
    console.log(e);
  }
}

export function getCartId() {
  try {
    const cookies = new Cookies();
    if (localStorage.getItem(cartName)) {
      return localStorage.getItem(cartName);
    }
    if (cookies.get(cartName)) {
      return cookies.get(cartName);
    }
  } catch (e) {
    return null;
  }
}
