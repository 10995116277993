import axios from "axios";
import { sprintf } from "sprintf-js";

import {
  APPS_ALL_API_ENDPOINT,
  APPS_CREATE_API_ENDPOINT,
  APPS_UPDATE_API_ENDPOINT,
  APPS_DELETE_API_ENDPOINT,
  APPS_ALL_SCOPES_API_ENDPOINT,
  APPS_APP_SCOPE_TOGGLE_API_ENDPOINT
} from "./endpoints";

export function fetchAllApps() {
  return axios.get(APPS_ALL_API_ENDPOINT);
}

export function fetchAllScopes() {
  return axios.get(APPS_ALL_SCOPES_API_ENDPOINT);
}

export function createApp(name) {
  return axios.post(APPS_CREATE_API_ENDPOINT, { name });
}

export function updateApp(app, data) {
  return axios.put(`${APPS_UPDATE_API_ENDPOINT}/${app.id}`, data);
}

export function deleteApp(app) {
  return axios.delete(`${APPS_DELETE_API_ENDPOINT}/${app.id}`);
}

export function toggleAppScope(app, scope) {
  return axios.put(
    sprintf(APPS_APP_SCOPE_TOGGLE_API_ENDPOINT, { id: scope.id }),
    {
      client_id: app.id
    }
  );
}
