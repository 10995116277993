import styled from "styled-components";

const TableCell = styled.td`
  vertical-align: middle;
  font-weight: 400;
  width: ${props => (props.width ? props.width : "auto")};
  & .user-avatar {
    vertical-align: top;
  }
`;

export default TableCell;
