import React from "react";
import Table from "../../../components/Table";
import TableHeader from "../../../components/Table/TableHeader";
import TableRow from "../../../components/Table/TableRow";
import TableHeading from "../../../components/Table/TableHeading";
import TableBody from "../../../components/Table/TableBody";
import TableCell from "../../../components/Table/TableCell";
import {Link, withRouter} from "react-router-dom";
import { ROUTE_MY_ACCOUNT,} from "../../../routes/routes";
import {Translate, withLocalize} from "react-localize-redux";
import Pagination from "../../../components/Pagination";
import styled from "styled-components";
import {theme} from "../../../design/Themes";
import Container from "../../../components/Containers/Container";
import axios from "axios";
import {CREDIT_API_ENDPOINT} from "../../../services/endpoints";
import {
    getCustomerPersonalInformation,
    isFetchingCustomerPersonalInformation
} from "../../Customer/reducers/customerReducer";
import {getAllReferralInvoices, getTotalRevenue} from "../reducers/referralReducer";
import {bindActionCreators} from "redux";
import {fetchReferralInvoices} from "../actions/referralActions";
import {connect} from "react-redux";
import {SORT_ORDER_ASCENDING, SORT_ORDER_DESCENDING} from "../../../types/SortTypes";
import moment from "moment";
import {isBrowser} from "react-device-detect";

const StyledTableTitle = styled.h4`
  padding-left: 25px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
`;

const PointsLabel = styled.div`
     width:90%;
    border: 1px solid #1b75bc;
    color: #1b75bc;
    border-radius: 3px;
    padding: 5px;
    text-align: center;
    background-color:#f6fbff;

    &.green{
     border: 2px solid #45C28D;
    padding: 5px;
    text-align: center;
    width: 90%;
    color: white;
    font-weight: 700;
    background-color: #45C28D;
  
    }
`;


 class MyReferralInvoices extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            availableCredit: null,
            prefix: ''
        };
    }


     handlePaginationClick = (event, page) => {
         event.preventDefault();

         if (page <= 0 || page > this.props.paging.total_pages) {
             return;
         }

         this.props.fetchReferralInvoices(
             this.props.paging.limit,
             page,
             this.props.sort,
             this.props.filters
         );
     };

     onPerPageOptionChanged = value => {
         this.props.fetchReferralInvoices(
             value.value,
             1,
             this.props.sort,
             this.props.filters
         );
     };

     changeListOrderTrigger = (sortBy, orderBy) => {
         this.props.fetchReferralInvoices(
             this.props.paging.limit,
             this.props.paging.current_page,
             {
                 sort_by: sortBy,
                 order_by:
                     orderBy === SORT_ORDER_DESCENDING
                         ? SORT_ORDER_ASCENDING
                         : SORT_ORDER_DESCENDING
             },
             this.props.filters
         );
     };

    componentDidMount() {
        axios.get(CREDIT_API_ENDPOINT).then((response)=>{
            this.setState({
                availableCredit: response.data.data.credit.amount,
                prefix: response.data.data.credit.currency.prefix
            })
        })
    }

    render() {
        const invoices = this.props.invoices.invoices || [];

        return (
            <Translate>
                {({translate}) => (
                    <Container>
                    <div >
                        <div className={"row mt-4"}>
                            <div className={'col-11 mt-1 offset-1 offset-md-0 col-sm-12 col-md-4'}>
                                <StyledTableTitle>  {translate("referral-admin.credits-earn")}</StyledTableTitle>

                            </div>

                            <div className={'col-11 mt-1 offset-1 offset-md-0 col-sm-12 col-md-4'}>
                                <PointsLabel>
                                    {translate("referral-admin.preview-credits")} : {this.props.total_revenue + this.state.prefix}
                                </PointsLabel>

                            </div>
                            <div className={'col-11 mt-1 offset-1 offset-md-0 col-sm-12 col-md-4'}>
                                <Link to={ROUTE_MY_ACCOUNT} style={{textDecoration: "none"}}>
                             <PointsLabel className={'green'}>
                                   {translate("referral-admin.moments-credits")} {/*: {this.state.availableCredit} {this.state.prefix}*/}
                                </PointsLabel>
                                </Link>


                            </div>
                        </div>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    {isBrowser && (  <TableHeading
                                        width="10%"
                                        isSortable={true}
                                        sortBy="id"
                                        isDefaultSort={
                                            this.props.sort.sort_by === "id"
                                        }
                                        sortDirection={this.props.sort.order_by}
                                        changeListOrderTrigger={
                                            this.changeListOrderTrigger
                                        }
                                    >

                                        {translate("referral-admin.invoices-number")}
                                    </TableHeading> )}
                                    <TableHeading
                                        width="20%"
                                    >
                                        {translate("referral-admin.name-surname")}
                                    </TableHeading>
                                    <TableHeading
                                        width="10%"
                                        isSortable={false}
                                    >
                                        {translate("referral-admin.pay-voice")}
                                    </TableHeading>
                                    {isBrowser && ( <TableHeading
                                        width="10%"
                                        isSortable={true}
                                        sortBy="amount"
                                        isDefaultSort={
                                            this.props.sort.sort_by === "amount"
                                        }
                                        sortDirection={this.props.sort.order_by}
                                        changeListOrderTrigger={
                                            this.changeListOrderTrigger
                                        }

                                    >
                                        {translate("referral-admin.credits")}
                                    </TableHeading>)}
                                    {isBrowser && (   <TableHeading
                                        width="20%"
                                        isSortable={true}
                                        sortBy="created_at"
                                        isDefaultSort={
                                            this.props.sort.sort_by === "created_at"
                                        }
                                        sortDirection={this.props.sort.order_by}
                                        changeListOrderTrigger={
                                            this.changeListOrderTrigger
                                        }
                                    >
                                        {translate("referral-admin.date-invoice")}
                                    </TableHeading>)}
                                    {isBrowser && (    <TableHeading
                                        width="20%"
                                        isSortable={true}
                                        sortBy="paid_on_date"
                                        isDefaultSort={
                                            this.props.sort.sort_by === "paid_on_date"
                                        }
                                        sortDirection={this.props.sort.order_by}
                                        changeListOrderTrigger={
                                            this.changeListOrderTrigger
                                        }
                                    >
                                        {translate("referral-admin.pay-date-invoice")}
                                    </TableHeading>)}
                                </TableRow>

                            </TableHeader>

                            <TableBody>
                                {invoices.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan="6">
                                            {translate("referral-admin.table-empty")}
                                        </TableCell>
                                    </TableRow>
                                )}
                                {invoices.length > 0 &&
                                invoices.map(invoice => {
                                    return (
                                        <TableRow key={`customer-product-${invoice.whmcs_invoice_id}`}>
                                            {isBrowser && ( <TableCell>
                                                {invoice.whmcs_invoice_id}
                                            </TableCell>)}
                                            <TableCell>
                                                {invoice.new_customer.first_name} {invoice.new_customer.last_name}
                                            </TableCell>
                                            <TableCell>
                                                {invoice.amount}
                                            </TableCell>
                                            {isBrowser && ( <TableCell>
                                                {invoice.referrer.referrer_reward}
                                            </TableCell>)}
                                            {isBrowser && (  <TableCell>
                                                {moment(invoice.created_At.date).format("YYYY-MM-DD")}
                                            </TableCell>)}
                                            {isBrowser && (   <TableCell>
                                                {moment(invoice.paid_At.date).format("YYYY-MM-DD")}
                                            </TableCell>)}
                                        </TableRow>
                                    );

                                })}


                            </TableBody>

                        </Table>

                        <Pagination
                            itemsName={translate("referral-admin.invoices-list")}
                            paging={this.props.invoices.paging}
                            handlePaginationClick={this.handlePaginationClick}
                            onPerPageOptionChanged={this.onPerPageOptionChanged}
                        />

                    </div>
                    </Container>
                )}


            </Translate>
        )


    }
}


const mapStateToProps = state => {
    return {
        customerPersonalInformation: getCustomerPersonalInformation(state),
        revenue : getTotalRevenue(state),
        isFetchingCustomerPersonalInformation: isFetchingCustomerPersonalInformation(
            state
        )
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { fetchReferralInvoices, getTotalRevenue, getAllReferralInvoices },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLocalize(MyReferralInvoices)));

