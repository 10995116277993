import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Translate } from "react-localize-redux";

import NoticeWarning from "../../../components/Notices/NoticeWarning";
import Radiobox from "../../../components/Radiobox";

import { CANCEL_DOMAIN_EXPIRE } from "../CancellationPeriods/CancellationPeriods";

const StyledCancellationOptions = styled.div`
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid ${theme.neutral3};
  border-bottom: 1px solid ${theme.neutral3};
`;

const CancelDomain = ({
  domain,
  cancelDomainPeriod,
  onChangeCancelDomainPeriod
}) => (
  <Translate>
    {({ translate }) => (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <NoticeWarning>
            {translate("domains.table.disable-domain-explanation")}
          </NoticeWarning>

          <StyledCancellationOptions className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <Radiobox
                onClick={e => onChangeCancelDomainPeriod(CANCEL_DOMAIN_EXPIRE)}
                name="type"
                value={CANCEL_DOMAIN_EXPIRE}
                checked={CANCEL_DOMAIN_EXPIRE === cancelDomainPeriod}
                helpText={`${translate(
                  "domains.table.domain-will-not-be-auto-renewed"
                )} ${domain.expiry_date}`}
              >
                {translate("domains.table.let-the-domain-expire")}
              </Radiobox>
            </div>
          </StyledCancellationOptions>
        </div>
      </div>
    )}
  </Translate>
);

export default CancelDomain;
