import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

import Togglebox from "../Togglebox";

const StyledToggleBoxWithTitleAndDescription = styled.div`
  & .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: ${theme.neutralBase};
    margin-bottom: 5px;
  }

  & .description {
    font-size: 14px;
    line-height: 1.43;
    color: ${theme.neutral6};
    margin-bottom: 5px;
  }
`;

const ToggleboxWithTitleAndDescription = ({
  title,
  description,
  onToggle,
  submitting,
  ...rest
}) => (
  <StyledToggleBoxWithTitleAndDescription className="toggle-title-description">
    <div className="row">
      <div className="col-9 col-sm-9 col-md-9">
        <p className="title">{title}</p>
        {description && <p className="description">{description}</p>}
      </div>
      <div className="col-3 col-sm-3 col-md-3">
        <Togglebox submitting={submitting} onToggle={onToggle} {...rest} />
      </div>
    </div>
  </StyledToggleBoxWithTitleAndDescription>
);

ToggleboxWithTitleAndDescription.defaultProps = {
  title: "",
  description: null,
  onToggle: () => {}
};
export default ToggleboxWithTitleAndDescription;
