import styled from "styled-components";
import { theme } from "../../design/Themes";

const DropdownItemDescription = styled.p`
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 0;
  color: ${theme.neutral5};
  &:active {
    color: ${theme.white};
  }
`;

export default DropdownItemDescription;
