import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  isMobileOnly,
  isTablet,
  isBrowser,
  MobileOnlyView
} from "react-device-detect";
import { withSizes } from "react-sizes";
import isLaptop from "../../../utils/isLaptopScreen";

import {
  marketingNotificationsEnabled,
  isFetchingNotificationsConfig,
  isUpdatingNotificationConfig,
  NOTIFICATION_MARKETING_SUBSCRIPTION
} from "../../Notifications/reducers/notificationsReducer";

import {
  enableNotifications,
  fetchNotificationsConfig
} from "../../Notifications/actions/notificationsActions";

import EmailsList from "./EmailsList";
import ToggleboxWithTitleAndDescription from "../../../components/ToggleboxWithTitleAndDecription";
import Panel from "../../../components/Panel";

import withLoading from "../../../components/Loaders/WithLoading";
import FilterLoader from "../../../components/Loaders/FilterLoader";

const NotificationsPanelWithLoading = withLoading(Panel, FilterLoader);

const StyledNotificationsWrapper = styled.div`
  & .toggle-title-description {
    margin-bottom: 15px;
  }
`;

const StyledTabletToggleboxWithTitleAndDescription = styled(
  ToggleboxWithTitleAndDescription
)`
  right: 15px;
  & .togglebox-control {
    right: 15px;
  }
`;

class EmailsAndNotifications extends React.Component {
  onEnableMarketingNotification = notification => {
    return this.props.enableNotifications(
      notification,
      !this.props.isMarketingNotificationEnabled
    );
  };

  componentDidMount() {
    this.props.fetchNotificationsConfig();
  }

  render() {
    const {
      handlePaginationClick,
      onPerPageOptionChanged,
      changeListOrderTrigger,
      onChangeKeywordSearch,
      searchQuery,
      emails,
      isFetchingEmails,
      emailRoute,
      isLaptop
    } = this.props;

    return (
      <Translate>
        {({ translate }) => (
          <StyledNotificationsWrapper className="row">
            <MobileOnlyView renderWithFragment>
              <div className="col-12 col-sm-12 col-md-12">
                <NotificationsPanelWithLoading
                  isLoading={this.props.isFetchingNotificationsConfig}
                  title={translate(
                    "my-account.emails-and-notifications.email-notifications"
                  )}
                  description={translate(
                    "my-account.emails-and-notifications.description"
                  )}
                >
                  <ToggleboxWithTitleAndDescription
                    className="vlatko"
                    title={translate(
                      "my-account.emails-and-notifications.email"
                    )}
                    description={translate(
                      "my-account.emails-and-notifications.email-description"
                    )}
                    toggled={true}
                    disabled={true}
                  />
                  <ToggleboxWithTitleAndDescription
                    title={translate(
                      "my-account.emails-and-notifications.domains"
                    )}
                    description={translate(
                      "my-account.emails-and-notifications.domains-description"
                    )}
                    toggled={true}
                    disabled={true}
                  />
                  <ToggleboxWithTitleAndDescription
                    title={translate(
                      "my-account.emails-and-notifications.billing"
                    )}
                    description={translate(
                      "my-account.emails-and-notifications.billing-description"
                    )}
                    toggled={true}
                    disabled={true}
                  />
                  <ToggleboxWithTitleAndDescription
                    title={translate(
                      "my-account.emails-and-notifications.support"
                    )}
                    description={translate(
                      "my-account.emails-and-notifications.support-description"
                    )}
                    toggled={true}
                    disabled={true}
                  />
                  <ToggleboxWithTitleAndDescription
                    title={translate(
                      "my-account.emails-and-notifications.marketing"
                    )}
                    description={translate(
                      "my-account.emails-and-notifications.marketing-description"
                    )}
                    toggled={this.props.isMarketingNotificationEnabled}
                    submitting={
                      this.props[
                        `${NOTIFICATION_MARKETING_SUBSCRIPTION}_UPDATING`
                      ]
                    }
                    onToggle={() =>
                      this.onEnableMarketingNotification(
                        NOTIFICATION_MARKETING_SUBSCRIPTION
                      )
                    }
                  />
                </NotificationsPanelWithLoading>
              </div>
              <div className="col-12 col-sm-12 col-md-12">
                <EmailsList
                  handlePaginationClick={handlePaginationClick}
                  onPerPageOptionChanged={onPerPageOptionChanged}
                  changeListOrderTrigger={changeListOrderTrigger}
                  onChangeKeywordSearch={onChangeKeywordSearch}
                  searchQuery={searchQuery}
                  emails={emails}
                  isFetchingEmails={isFetchingEmails}
                  emailRoute={emailRoute}
                />
              </div>
            </MobileOnlyView>

            {(isTablet || isLaptop) && (
              <React.Fragment>
                <div className="col-4 col-sm-4 col-md-4">
                  <NotificationsPanelWithLoading
                    isLoading={this.props.isFetchingNotificationsConfig}
                    title={translate(
                      "my-account.emails-and-notifications.email-notifications"
                    )}
                    description={translate(
                      "my-account.emails-and-notifications.description"
                    )}
                  >
                    <StyledTabletToggleboxWithTitleAndDescription
                      title={translate(
                        "my-account.emails-and-notifications.email"
                      )}
                      description={translate(
                        "my-account.emails-and-notifications.email-description"
                      )}
                      toggled={true}
                      disabled={true}
                    />
                    <StyledTabletToggleboxWithTitleAndDescription
                      title={translate(
                        "my-account.emails-and-notifications.domains"
                      )}
                      description={translate(
                        "my-account.emails-and-notifications.domains-description"
                      )}
                      toggled={true}
                      disabled={true}
                    />
                    <StyledTabletToggleboxWithTitleAndDescription
                      title={translate(
                        "my-account.emails-and-notifications.billing"
                      )}
                      description={translate(
                        "my-account.emails-and-notifications.billing-description"
                      )}
                      toggled={true}
                      disabled={true}
                    />
                    <StyledTabletToggleboxWithTitleAndDescription
                      title={translate(
                        "my-account.emails-and-notifications.support"
                      )}
                      description={translate(
                        "my-account.emails-and-notifications.support-description"
                      )}
                      toggled={true}
                      disabled={true}
                    />
                    <StyledTabletToggleboxWithTitleAndDescription
                      title={translate(
                        "my-account.emails-and-notifications.marketing"
                      )}
                      description={translate(
                        "my-account.emails-and-notifications.marketing-description"
                      )}
                      toggled={this.props.isMarketingNotificationEnabled}
                      submitting={
                        this.props[
                          `${NOTIFICATION_MARKETING_SUBSCRIPTION}_UPDATING`
                        ]
                      }
                      onToggle={() =>
                        this.onEnableMarketingNotification(
                          NOTIFICATION_MARKETING_SUBSCRIPTION
                        )
                      }
                    />
                  </NotificationsPanelWithLoading>
                </div>
                <div className="col-8 col-sm-8 col-md-8">
                  <EmailsList
                    handlePaginationClick={handlePaginationClick}
                    onPerPageOptionChanged={onPerPageOptionChanged}
                    changeListOrderTrigger={changeListOrderTrigger}
                    onChangeKeywordSearch={onChangeKeywordSearch}
                    searchQuery={searchQuery}
                    emails={emails}
                    isFetchingEmails={isFetchingEmails}
                    emailRoute={emailRoute}
                  />
                </div>
              </React.Fragment>
            )}
            {isBrowser && !isLaptop && !isTablet && (
              <React.Fragment>
                <div className="col-4 col-sm-4 col-md-4">
                  <NotificationsPanelWithLoading
                    isLoading={this.props.isFetchingNotificationsConfig}
                    title={translate(
                      "my-account.emails-and-notifications.email-notifications"
                    )}
                    description={translate(
                      "my-account.emails-and-notifications.description"
                    )}
                  >
                    <ToggleboxWithTitleAndDescription
                      title={translate(
                        "my-account.emails-and-notifications.email"
                      )}
                      description={translate(
                        "my-account.emails-and-notifications.email-description"
                      )}
                      toggled={true}
                      disabled={true}
                      className="float-right"
                    />
                    <ToggleboxWithTitleAndDescription
                      title={translate(
                        "my-account.emails-and-notifications.domains"
                      )}
                      description={translate(
                        "my-account.emails-and-notifications.domains-description"
                      )}
                      toggled={true}
                      disabled={true}
                      className="float-right"
                    />
                    <ToggleboxWithTitleAndDescription
                      title={translate(
                        "my-account.emails-and-notifications.billing"
                      )}
                      description={translate(
                        "my-account.emails-and-notifications.billing-description"
                      )}
                      toggled={true}
                      disabled={true}
                      className="float-right"
                    />
                    <ToggleboxWithTitleAndDescription
                      title={translate(
                        "my-account.emails-and-notifications.support"
                      )}
                      description={translate(
                        "my-account.emails-and-notifications.support-description"
                      )}
                      toggled={true}
                      disabled={true}
                      className="float-right"
                    />
                    <ToggleboxWithTitleAndDescription
                      title={translate(
                        "my-account.emails-and-notifications.marketing"
                      )}
                      description={translate(
                        "my-account.emails-and-notifications.marketing-description"
                      )}
                      toggled={this.props.isMarketingNotificationEnabled}
                      className="float-right"
                      submitting={
                        this.props[
                          `${NOTIFICATION_MARKETING_SUBSCRIPTION}_UPDATING`
                        ]
                      }
                      onToggle={() =>
                        this.onEnableMarketingNotification(
                          NOTIFICATION_MARKETING_SUBSCRIPTION
                        )
                      }
                    />
                  </NotificationsPanelWithLoading>
                </div>
                <div className="col-8 col-sm-8 col-md-8">
                  <EmailsList
                    handlePaginationClick={handlePaginationClick}
                    onPerPageOptionChanged={onPerPageOptionChanged}
                    changeListOrderTrigger={changeListOrderTrigger}
                    onChangeKeywordSearch={onChangeKeywordSearch}
                    searchQuery={searchQuery}
                    emails={emails}
                    isFetchingEmails={isFetchingEmails}
                    emailRoute={emailRoute}
                  />
                </div>
              </React.Fragment>
            )}
          </StyledNotificationsWrapper>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    isMarketingNotificationEnabled: marketingNotificationsEnabled(state),
    isFetchingNotificationsConfig: isFetchingNotificationsConfig(state),
    [`${NOTIFICATION_MARKETING_SUBSCRIPTION}_UPDATING`]: isUpdatingNotificationConfig(
      state,
      NOTIFICATION_MARKETING_SUBSCRIPTION
    )
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { enableNotifications, fetchNotificationsConfig },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(EmailsAndNotifications));
