import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

import InputHelpBlock from "../InputField/InputHelpBlock";

const StyledCheckbox = styled.div`
  & {
    cursor: pointer;
    display: block;
    padding-left: 0;
    width: 10px;
  }

  & .custom-control-label {
    color: ${theme.neutralBase};
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    position: relative;
    margin-right: 5px;
    padding-left: 30px;
    top: -16px;
  }
  & .custom-control-label:before {
    background-color: ${theme.white};
    border: 1px solid ${theme.neutral3};
    width: 18px;
    height: 18px;
    top: 2px;
    left: 0;
  }

  &:hover .custom-control-label:before {
    background-color: ${theme.neutral1};
    border: 1px solid ${theme.neutral3};
  }

  &:focus .custom-control-label:before {
    background-color: ${theme.white};
    border: 1px solid ${theme.neutral3};
    box-shadow: inset 0 0 0 2px ${theme.blue3};
  }

  & .custom-control-label:after {
    width: 18px;
    height: 18px;
    left: 0;
  }

  & .custom-control-input:checked ~ .custom-control-label:after {
    background-image: none;
    font-family: "icomoon" !important;
    content: "\\e929";
    font-size: 16px;
    color: ${theme.white};
    position: absolute;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    top: 3px;
    left: 1px;
    //left: -22.9px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  & .custom-control-label-indeterminate:after {
    background-image: none;
    font-family: "icomoon" !important;
    content: "\\e92a";
    font-size: 16px;
    color: ${theme.white};
    position: absolute;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    top: 2px;
    //left: -22.9px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  & .custom-control-label-indeterminate:before {
    background-color: ${theme.blueBase};
    border: 1px solid ${theme.blueBase};
  }

  & .custom-control-input:indeterminate ~ .custom-control-label:after {
    content: "\\e92a";
  }

  & .custom-control-input:checked ~ .custom-control-label:before {
    background-color: ${theme.blueBase};
    border: 1px solid ${theme.blueBase};
  }

  &:hover .custom-control-input:checked ~ .custom-control-label:before {
    background-color: ${theme.blue4};
  }

  &:hover .custom-control-label-indeterminate:before {
    background-color: ${theme.blue4};
  }

  & .custom-control-input:disabled,
  & .custom-control-input:disabled ~ .custom-control-label {
    cursor: not-allowed;
  }
  & .custom-control-input:disabled ~ .custom-control-label:before {
    background-color: ${theme.neutral1};
    border: 1px solid ${theme.neutral3};
    cursor: not-allowed;
  }

  &:disabled
    .custom-control-input:checked:disabled
    ~ .custom-control-label:before,
  & .custom-control-input:checked:disabled ~ .custom-control-label:before,
  &:disabled .custom-control-input:checked:disabled,
  & .custom-control-input:checked:disabled {
    background-color: ${theme.neutral2};
    border: 1px solid ${theme.neutral2};
    cursor: not-allowed;
  }

  &:disabled
    .custom-control-input:checked:disabled
    ~ .custom-control-label:after,
  & .custom-control-input:checked:disabled ~ .custom-control-label:after,
  &:disabled .custom-control-input:checked:disabled,
  & .custom-control-input:checked:disabled {
    cursor: not-allowed;
  }

  & > .input-help-block {
    // display: block;
    // position: absolute;
    // top: 40px;

    @media (max-width: 425px) {
      top: 66px;
    }

    @media (max-width: 320px) {
      top: 87px;
    }
  }
`;

StyledCheckbox.defaultProps = {
  id: "",
  value: "",
  className: ""
};

class Checkbox extends React.Component {
  onChange = e => {
    if (this.props.disabled) return;
    if (typeof this.props.input.onChange === "function") {
      this.props.input.onChange(!this.props.input.value);
    }
  };

  onToggle = (e) => {
  // console.log(e.target,e.target.tagName)
   const targetTagName = e.target.tagName.toLowerCase();
    if (targetTagName !== 'a') {
      e.preventDefault();
      this.onChange(e);
    }
  
  }
  

  render() {
    return (
      <StyledCheckbox
        onClick={this.onToggle}
        className={`custom-control custom-checkbox ${this.props.className}`}
      >
        <input
          className="custom-control-input"
          type="checkbox"
          id={this.props.id}
          disabled={this.props.disabled}
          {...this.props.input}
          checked={this.props.input ? this.props.input.value : false}
        />
        <label
          className={`custom-control-label ${
            this.props.indeterminate && !this.props.input.value
              ? "custom-control-label-indeterminate"
              : ""
          }`}
          htmlFor={this.props.id}
          onClick={this.onToggle}
        >
          {this.props.label}
        </label>
        {this.props.meta && this.props.meta.invalid && !this.props.meta.valid && (
          <InputHelpBlock
            valid={this.props.meta.valid}
            invalid={this.props.meta.invalid}
          >
            {this.props.meta.error}
          </InputHelpBlock>
        )}
      </StyledCheckbox>
    );
  }
}

export default Checkbox;
