import React from "react";
import { Form, Field, FormSpy } from "react-final-form";
import { Translate } from "react-localize-redux";

import InputField from "../InputField";
import TextArea from "../TextArea";

const ForwardDialog = ({ onSubmit, onSubmitting, validate }) => (
  <Translate>
    {({ translate }) => (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
              <form id="forward-dialog-form" onSubmit={handleSubmit}>
                <Field
                  component={InputField}
                  label={translate("pro-invoices.view.recipient")}
                  placeholder={translate("pro-invoices.view.enter-email")}
                  name="email"
                />
                <Field
                  component={TextArea}
                  label={translate("pro-invoices.view.message-optional")}
                  placeholder={translate("pro-invoices.view.enter-message")}
                  name="message"
                />

                <FormSpy
                  subscription={{ submitting: true }}
                  onChange={formstate => onSubmitting({ ...formstate })}
                />
              </form>
            )}
          />
        </div>
      </div>
    )}
  </Translate>
);

export default ForwardDialog;
