import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Flag from "../Flag";

const StyledFlagNormalInfo = styled.div`
  & .icon:not(.icon-close) {
    color: ${theme.blueBase};
  }
`;

const FlagNormalInfo = ({ children, ...rest }) => (
  <StyledFlagNormalInfo>
    <Flag icon={rest.icon || "info"} {...rest}>
      {children}
    </Flag>
  </StyledFlagNormalInfo>
);

FlagNormalInfo.defaultProps = {
  onDismiss: () => {}
};

export default FlagNormalInfo;
