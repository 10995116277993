import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

const StyledPaginationItem = styled.li`
  & .page-link {
    padding: 8px 16px;
    color: ${theme.blueBase};
    border-color: ${theme.neutral3};
  }

  & .page-link:hover {
    background-color: ${theme.white};
    border-color: ${theme.neutral3};
  }

  &.disabled {
    cursor: not-allowed;
  }

  &.disabled .page-link {
    padding: 8px 16px;
    color: ${theme.neutral2};
    background-color: ${theme.white};
    cursor: not-allowed;
  }

  &.disabled .page-link:hover {
    background-color: ${theme.white};
  }
  &.active .page-link {
    padding: 8px 16px;
    background-color: ${theme.blueBase};
    border-color: ${theme.blueBase};
    color: ${theme.white};
  }
`;
const PaginationItem = ({ children, ...rest }) => (
  <StyledPaginationItem
    className={`page-item ${rest.disabled ? "disabled" : ""} ${
      rest.active ? "active" : ""
    }`}
  >
    <a
      className="page-link"
      href={rest.link}
      onClick={e => rest.linkHandler(e, rest.page)}
    >
      {children}
    </a>
  </StyledPaginationItem>
);

export default PaginationItem;
