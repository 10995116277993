import React from "react";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";

import empty from "../../../../assets/images/icon-illustration-empty.png";

import PrimaryButton from "../../../../components/Buttons/PrimaryButton";

const StyledImage = styled.img`
  margin: 0 auto;
  width: 50%;
  margin-bottom: 30px;
`;

const StyledDescription = styled.p`
  text-align: center;
`;

const StyledButton = styled(PrimaryButton)`
  margin: 0 auto;
`;

const NoApps = ({ translate, onShowCreateAppModal }) => (
  <div className="row">
    <div className="col-12 offset-0 col-sm-12 offset-sm-0 col-md-4 offset-md-4 text-center">
      <StyledImage className="img-fluid" src={empty} alt="" />
      <StyledDescription>
        {translate("my-account.apps.no-apps")}
      </StyledDescription>
      <StyledButton onClick={() => onShowCreateAppModal()}>
        {translate("my-account.apps.create-app")}
      </StyledButton>
    </div>
  </div>
);

export default withLocalize(NoApps);
