import axios from "axios";


import {
    EMAIL_SEND_VALIDATION_LINK,
    EMAIL_VERIFY
} from "./endpoints";


export function verifyEmailService(email, token) {
    return axios.post(EMAIL_VERIFY, {
        email,
        token
    });
}

export function requestEmailVerificationService() {
    return axios.post(EMAIL_SEND_VALIDATION_LINK);
}


