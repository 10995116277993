import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";
import { withSizes } from "react-sizes";
import isLaptop from "../../utils/isLaptopScreen";
import { isBrowser } from "react-device-detect";
import PdfPopup from "../../components/DomainPDF/PDFPopup";


import {
  fetchCart,
  attachServiceToProduct,
  removeServiceFromProduct,
  removeProductFromCart,
  updateProduct,
  updateServerSettings,
  changeProductContact,
  attachDomainAssistantToOrderItem,
  updateDomainAssistantToOrderItem,
  updateNameserversForDomainInCart,
  clearCart,
  checkoutCart,
  blockCheckoutByProduct,
  unblockCheckoutByProduct
} from "../Cart/actions/cartActions";

import {
  getItems,
  getCartId,
  isRequestingCart,
  getTotalDiscount,
  getTotal,
  isCheckoutBlocked,
  firstCheckoutBlockingProduct
} from "../Cart/reducers/cartReducer";

import { getCustomerCurrencyCode } from "../Customer/reducers/customerReducer";

import {
  searchContacts,
  changeCustomerDomainContact
} from "../Customer/actions/customerActions";
import { getCustomerPersonalInformation } from "../Customer/reducers/customerReducer";

import { fetchPaymentMethods } from "../PaymentMethods/actions/paymentMethodsActions";
import { getPaymentMethods } from "../PaymentMethods/reducers/paymentMethodsReducer";

import { getCountriesFormatted } from "../Addressing/reducers/addressingReducer";

import {
  validateFirstName,
  validateLastName,
  validateEmail,
  validatePhoneNumber,
  validateAddress,
  validateCompany,
  validateTaxNumber,
  validateCity,
  validateCountry,
  validateZipCode,
  validatePersonalDataIsCorrect,
  validateMkPhoneNumber
} from "../../common/validationRules";
import {
  CONTACT_DETAILS_TYPE_COMPANY,
  CONTACT_DETAILS_TYPE_INDIVIDUAL
} from "../Domains/ViewDomain/DomainContacts/ContactDetailsForm/ContactDetailsTypes";

import Breadcrumb from "../../components/Breadcrumb";
import BreadcrumbItem from "../../components/Breadcrumb/BreadcrumbItem";

import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

import Modal from "../../components/Modal";
import ContactDetailsForm from "./Products/Domain/ContactDetailsForm";
import ContactDetailsFormWithSearch from "./Products/Domain/ContactDetailsFormWithSearch";

import CheckoutCartProductsList from "./CheckoutCartProductsList";
import CartSummary from "./CartSummary";
import CheckoutPayment from "./CheckoutPayment";

import CartEmptyState from "../Cart/CartEmptyState";

import withLoading from "../../components/Loaders/WithLoading";
import ContentLoader from "../../components/Loaders/ContentLoader";

import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

import {
  compileRoute,
  ROUTE_INDEX,
  ROUTE_CART,
  ROUTE_BILLING_VIEW_PROINVOICE,
  ROUTE_SHOP_DOMAINS,
  ROUTE_SHOP_HOSTING,
  ROUTE_SHOP_SERVERS
} from "../../routes/routes";

import { PRODUCT_DOMAIN } from "./Products/ProductTypes";
import { CART_DOMAIN_TYPE_REGISTER } from "../Cart/CartDomainTypes/CartDomainTypes";

import { proInvoiceProductTypes } from "./Products/ProInvoiceProductTypes";

import * as googleAnalyticsService from "../../services/googleAnalyticsService";
import * as facebookAnalyticsService from "../../services/facebookAnalyticsService";
import {handleDomainRequest,updateDomainRequest} from "../../services/customerService";

const StyledCheckout = styled.div``;

const StyledCheckoutWithLoading = withLoading(StyledCheckout, ContentLoader);

class Checkout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedProduct: null,
      selectedContact: null,
      selectedContactType: "",
      showChangeContactModal: false,
      changeContactModalSubtitle: "",
      showCreateContactModal: false,
      createContactModalSubtitle: "",
      showEditContactModal: false,
      showEditContactModalSubtitle: "",
      submittingContactModal: false,
      invalidContact: false,
      contacts: null,
      selectedPayment: null,
      updatingDomain: false,
      disableCheckoutButton: false,
      deletingProductId: 0,
      updatingHostingPeriod: false,
      updateHostingPeriodId: 0,
      submittingDomainNameservers: false,
      invalidDomainNameservers: false,
      updatingServerSettings: false,
      updatingServerSettingsId: 0,
      formsBlockingCheckout: [],
      showPdfPopup: false,
  pdfUrls: [],
  pdfIds:[]
    };
  }

  componentDidMount() {
    //if (!this.props.items) {
    this.props.fetchCart();
    this.props.fetchPaymentMethods();

    //}
  }

  onAttachServiceToProduct = (service, product) => {
    this.props
      .attachServiceToProduct(service, product)
      .then(response =>
        displayToastMessageForResponse(
          this.props.translate("cart.title"),
          response
        )
      );
  };

  onRemoveServiceFromProduct = (service, product) => {
    this.props
      .removeServiceFromProduct(service, product)
      .then(response =>
        displayToastMessageForResponse(
          this.props.translate("cart.title"),
          response
        )
      );
  };

  onRemoveProductFromCart = product => {
    this.setState({
      deletingProductId: product.id
    });
    this.props
      .removeProductFromCart({
        cart: this.props.cartId,
        item_id: product.id
      })
      .then(response => {
        displayToastMessageForResponse(
          this.props.translate("cart.title"),
          response
        );
        this.setState({
          deletingProductId: 0
        });
      });
  };

  onUpdateProductPeriod = (product, values) => {
    this.setState({
      updatingDomain: true
    });
    this.props
      .updateProduct(product, product.config.type, values.code)
      .then(result => {
        this.setState({
          updatingDomain: false
        });
      });
  };

  onUpdateHostingPeriod = (hosting, period) => {
    this.setState({
      updatingHostingPeriod: true,
      updatingHostingPeriodId: hosting.id
    });
    this.props.updateProduct(hosting, hosting.config.type, period).then(() => {
      this.setState({
        updatingHostingPeriod: false,
        updatingHostingPeriodId: 0
      });
    });
  };

  onUpdateServerSettings = (server, settings) => {
    this.setState({
      updatingServerSettings: true,
      updatingServerSettingsId: server.id
    });
    this.props
      .updateServerSettings(server, {
        hostname: settings.hostname,
        operating_system: settings.operating_system.value,
        platform: settings.platform !== undefined ? settings.platform.value : "",
        region: settings.region !== undefined ? settings.region.value : ""
      })
      .then(() => {
        this.setState({
          updatingServerSettings: false,
          updatingServerSettingsId: 0
        });
      });
  };

  onChangeContact = (product, contact, type) => {
    this.setState({
      showChangeContactModal: true,
      showCreateContactModal: false,
      selectedProduct: product,
      selectedContact: {
        ...contact,
        country: contact.country.id
          ? {
            label: contact.country.name,
            value: contact.country.id
          }
          : contact.country,
        contact_type:
          contact.company === ""
            ? CONTACT_DETAILS_TYPE_INDIVIDUAL
            : CONTACT_DETAILS_TYPE_COMPANY
      },
      selectedContactType: type
    });
  };

  onTriggerUpdateContactFormSubmit = () => {
    document
      .getElementById("domain-contact-details-form")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  validateContact = values => {
    if (values.contact_type === CONTACT_DETAILS_TYPE_COMPANY) {
      return {
        email: validateEmail(values.email),
        first_name: validateFirstName(values.first_name),
        last_name: validateLastName(values.last_name),
        phone:
          this.state.selectedProduct &&
            this.state.selectedProduct.type === PRODUCT_DOMAIN &&
            (this.state.selectedProduct.domain.endsWith(".mk") ||
              this.state.selectedProduct.domain.endsWith(".мкд"))
            ? validateMkPhoneNumber(values.phone)
            : validatePhoneNumber(values.phone),
        country:
          values.country && values.country.id
            ? validateCountry(values.country.id)
            : undefined,
        address: validateAddress(values.address),
        city: validateCity(values.city),
        zip: validateZipCode(values.zip),
        tax_number: validateTaxNumber(values.tax_number),
        company: validateCompany(values.company),
        valid_personal_data: validatePersonalDataIsCorrect(
          values.valid_personal_data
        )
      };
    }
    return {
      email: validateEmail(values.email),
      first_name: validateFirstName(values.first_name),
      last_name: validateLastName(values.last_name),
      phone:
        this.state.selectedProduct &&
          this.state.selectedProduct.type === PRODUCT_DOMAIN &&
          (this.state.selectedProduct.domain.endsWith(".mk") ||
            this.state.selectedProduct.domain.endsWith(".мкд"))
          ? validateMkPhoneNumber(values.phone)
          : validatePhoneNumber(values.phone),
      country:
        values.country && values.country.id
          ? validateCountry(values.country.id)
          : undefined,
      address: validateAddress(values.address),
      city: validateCity(values.city),
      zip: validateZipCode(values.zip),
      valid_personal_data: validatePersonalDataIsCorrect(
        values.valid_personal_data
      )
    };
  };

  onUpdateContact = values => {
    return this.props
      .updateDomainAssistantToOrderItem(
        this.state.selectedProduct,
        this.state.selectedContactType,
        { ...values, country_id: values.country.value }
      )
      .then(() => {
        this.onHideChangeContactModal();
        this.onHideCreateContactModal();
      });
  };

  onSaveNewContact = values => {
    return this.props
      .attachDomainAssistantToOrderItem(
        this.state.selectedProduct,
        this.state.selectedContactType,
        values
      )
      .then(() => {
        this.onHideChangeContactModal();
        this.onHideCreateContactModal();
      });
  };

  onSubmittingContactModal = state => {
    this.setState({
      submittingContactModal: state.submitting,
      invalidContact: state.invalid
    });
  };

  onSearchContacts = (value, callback) => {
    this.props.searchContacts(value).then(contacts => {
      this.setState({
        ...this.state,
        contacts: contacts.map(contact => {
          return {
            ...contact,
            contact: {
              ...contact.contact,
              country: contact.contact.country.id
                ? {
                  label: contact.contact.country.name,
                  value: contact.contact.country.id
                }
                : contact.contact.country,
              contact_type:
                contact.contact.company === ""
                  ? CONTACT_DETAILS_TYPE_INDIVIDUAL
                  : CONTACT_DETAILS_TYPE_COMPANY,
              type: this.state.selectedContactType
            }
          };
        })
      });
      const reactSelectCompatibleContacts = contacts.map(contact => {
        const name =
          contact.contact.company !== ""
            ? contact.contact.company
            : `${contact.contact.first_name} ${contact.contact.last_name}`;
        const label =
          name !== ""
            ? `${name} (${contact.contact.email})`
            : contact.contact.email;
        return {
          label,
          email: contact.contact.email,
          name: name,
          value: contact.contact.id,
          type:
            contact.contact.company !== ""
              ? CONTACT_DETAILS_TYPE_COMPANY
              : CONTACT_DETAILS_TYPE_INDIVIDUAL
        };
      });
      callback(reactSelectCompatibleContacts);
    });
  };

  onSelectContact = selected => {
    if (!selected || selected.length === 0) return false;
    if (selected) {
      const selectedContact = this.state.contacts.find(contact => {
        if (contact.contact.email === selected.email) return contact;
        return false;
      });
      this.setState({
        ...this.state,
        selectedContact: {
          ...selectedContact.contact,
          country: selectedContact.contact.country.id
            ? {
              label: selectedContact.contact.country.name,
              value: selectedContact.contact.country.id
            }
            : selectedContact.contact.country,
          contact_type:
            selectedContact.company === ""
              ? CONTACT_DETAILS_TYPE_INDIVIDUAL
              : CONTACT_DETAILS_TYPE_COMPANY
        }
      });
    }
  };

  onCreateContact = () => {
    this.setState({
      selectedContact: null,
      showChangeContactModal: false,
      showCreateContactModal: true
    });
  };

  onHideCreateContactModal = () => {
    this.setState({
      showCreateContactModal: false,
      showEditContactModal: false,
      showChangeContactModal: false
    });
  };

  onHideChangeContactModal = () => {
    this.setState({
      showCreateContactModal: false,
      showEditContactModal: false,
      showChangeContactModal: false
    });
  };

  onEditDomainContact = contact => {
    this.setState({
      ...this.state,
      selectedContact: {
        ...contact,
        country: contact.country.id
          ? {
            label: contact.country.name,
            value: contact.country.id
          }
          : contact.country,
        contact_type:
          contact.company === ""
            ? CONTACT_DETAILS_TYPE_INDIVIDUAL
            : CONTACT_DETAILS_TYPE_COMPANY
      },
      showChangeContactModal: false,
      showCreateContactModal: false,
      showEditContactModal: true
    });
  };

  onHideEditContactModal = () => {
    this.setState({
      showEditContactModal: false,
      showCreateContactModal: false,
      showChangeContactModal: false
    });
  };

  onSelectPayment = module => {
    this.setState({
      selectedPayment: module,
      disableCheckoutButton: false
    });
  };

  onCheckout = () => {
   
    if (this.props.isCheckoutBlocked) {
 

      displayToastMessageForResponse(
        this.props.translate("cart.checkout.title"),
        {
          error: true,
          messages: {
            0: this.props.translate("cart.checkout.products-require-data")
          }
        }
      );

      const form = document.getElementById(
        this.props.firstCheckoutBlockingProduct
      );

      if (form) {
        try {
          form.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        } catch (e) {
          form.scrollIntoView(false);
        }
      }

      return;
    }

    if (this.validatePaymentSelection()) {
    
    const hasMkDomain = this.props.items.some(item =>
  item.type === "domain" && (item.domain.endsWith('.mk') || item.domain.endsWith('.мкд')) 

    )
    
      
    
      this.setState({ disableCheckoutButton: true });
      if (hasMkDomain) {
        this.specialMkCheckoutProcess();
return;
      } else {

      this.props
        .checkoutCart(this.props.cartId, this.state.selectedPayment)
        .then(({ error, messages, invoice }) => {
          if (error) {
            displayToastMessageForResponse(
              this.props.translate("cart.checkout.title"),
              { error, messages }
            );
            this.setState({ disableCheckoutButton: false });
            
            return;
          }
          
          this.props.clearCart().then(() => {
            const proinvoicePath = compileRoute(ROUTE_BILLING_VIEW_PROINVOICE);
            // if (process.env.NODE_ENV === "production") {
            googleAnalyticsService.trackPurchase(
              invoice,
              proInvoiceProductTypes
            );

            facebookAnalyticsService.trackPurchase(invoice);
            // }
            this.props.history.push(proinvoicePath({ id: invoice.id }));
          });
        });
    }
  }
  };

  specialMkCheckoutProcess = () => {
    const mkDomainItems = this.props.items.filter(item =>
      item.type === "domain" && item.isMkDomain === true && item.config.type !== "transfer"
    );
  
    const mkDomainTransfer = this.props.items.filter(item =>
      item.type === "domain" && item.isMkDomain === true && item.config.type === "transfer"
    );
  
    const domainRequests = mkDomainItems.map(item => {
      const techContact = item.register_contacts.find(contact => contact.type === 'technical');
      const adminContact = item.register_contacts.find(contact => contact.type === 'administrative');
      const regContact = item.register_contacts.find(contact => contact.type === 'registrant');
  
      const data = {
        requestType: 'registration',
        domain: item.domain,
        regOrg: regContact.company ? regContact.company :null, 
        regName: regContact.full_name,
        regStreet: regContact.address1,
        regStreetNo: "/",
        regPostalCode: regContact.zip,
        regCountry: regContact.country.name,
        regCountryCode: regContact.country.iso2,
        regCity: regContact.city,
        regDb: regContact.tax_number,
        regEmail: regContact.email,
        regPhone: regContact.phone,
        adminName: adminContact.full_name,
        adminEmail: adminContact.email,
        adminPhone: adminContact.phone,
        techName: techContact.full_name,
        techEmail: techContact.email,
        techPhone: techContact.phone,
        ns1: item.nameservers.ns1,
        ns2: item.nameservers.ns2,
        ns3: item.nameservers.ns3,
        ns4: item.nameservers.ns4,
        ns5: item.nameservers.ns5
      };
      return handleDomainRequest(data)
        .then(response => {
       
          return {
            documentUrl: response.data.data.document,
            id: response.data.data.id
          };
        })
        .catch(error => {
          console.error("Error in handleDomainRequest for domain", item.domain, ":", error);
          return null;
        });
    });
  
    const transferRequests = mkDomainTransfer.map(item => {
      const data2 = {
        requestType: 'transfer',
        domain: item.domain
      };
  
      return handleDomainRequest(data2)
        .then(response => {
        
          return {
            documentUrl: response.data.data.document,
            id: response.data.data.id
          };
        })
        .catch(error => {
          console.error("Error in handleDomainRequest for domain", item.domain, ":", error);
          return null;
        });
    });
  
    const allRequests = [...domainRequests, ...transferRequests];
  
    Promise.all(allRequests).then(results => {
      const successfulResults = results.filter(result => result !== null);
  
      const pdfUrls = successfulResults.map(result => result.documentUrl);
      const ids = successfulResults.map(result => result.id);
  
      this.setState({ pdfUrls: pdfUrls, showPdfPopup: true, pdfIds: ids });
    });
  };
  

handleAccept = () => {
  this.props
  .checkoutCart(this.props.cartId, this.state.selectedPayment)
  .then(({ error, messages, invoice }) => {
    if (error) {
      displayToastMessageForResponse(
        this.props.translate("cart.checkout.title"),
        { error, messages }
      );
      this.setState({ disableCheckoutButton: false });
     
      return;
    }
   
    this.props.clearCart().then(() => {
      const proinvoicePath = compileRoute(ROUTE_BILLING_VIEW_PROINVOICE);
      // if (process.env.NODE_ENV === "production") {
      googleAnalyticsService.trackPurchase(
        invoice,
        proInvoiceProductTypes
      );

      facebookAnalyticsService.trackPurchase(invoice);
      // }
      this.props.history.push(proinvoicePath({ id: invoice.id }));
    });
  });
  
  this.setState({ showPdfPopup: false });
  updateDomainRequest(this.state.pdfIds); 
};

handleClosePopup = () => {
  this.setState({ showPdfPopup: false, pdfUrl: '', disableCheckoutButton: false });
};


  validatePaymentSelection = () => {
    if (this.state.selectedPayment && this.state.selectedPayment !== "") {
      return true;
    }

    displayToastMessageForResponse(
      this.props.translate("cart.checkout.title"),
      {
        error: true,
        messages: ["Select payment method first!!!"]
      }
    );

    return false;
  };

  onSaveSingleDomainNameservers = values => {
    if (
      this.props.items.length === 1 &&
      this.props.items[0].type === PRODUCT_DOMAIN &&
      this.props.items[0].config.type === CART_DOMAIN_TYPE_REGISTER
    ) {
      return this.props
        .updateNameserversForDomainInCart(this.props.items[0], values)
        .then(result => {
          displayToastMessageForResponse(this.props.translate("cart.title"), {
            error: false,
            messages: {
              0: this.props.translate(
                "cart.checkout.nameservers-successfully-saved"
              )
            }
          });
          this.setState({
            submittingDomainNameservers: false,
            invalidDomainNameservers: false
          });
        });
    }
  };

  onSubmittingNamerservers = ({ submitting, invalid }) => {
    this.setState({
      submittingDomainNameservers: submitting,
      invalidDomainNameservers: invalid
    });
  };

  onBlockCheckout = ({ formId, invalid, valid, submitSucceeded }) => {
    if (invalid === true && valid === false && submitSucceeded === false) {
      if (
        this.state.formsBlockingCheckout.find(id => id === formId) === undefined
      ) {
        this.props.blockCheckoutByProduct(formId);
      }
    } else if (
      valid === true &&
      invalid === false &&
      submitSucceeded === true
    ) {
      this.props.unblockCheckoutByProduct(formId);
    }
  };

  render() {
    return (
      

      <div className="row">
        {this.state.showPdfPopup && (
  <PdfPopup
    isVisible={this.state.showPdfPopup}
    pdfUrls={this.state.pdfUrls}
    onAccept={this.handleAccept}
    onClose={this.handleClosePopup}
  />
)}

        <div className="col-12 col-sm-12 col-md-12">
          <Breadcrumb>
            <BreadcrumbItem
              to={ROUTE_INDEX}
              title={this.props.translate("breadcrumbs.home")}
            />
            <BreadcrumbItem
              to={ROUTE_CART}
              title={this.props.translate("breadcrumbs.cart")}
            />
            <BreadcrumbItem
              title={this.props.translate("breadcrumbs.checkout")}
            />
          </Breadcrumb>
          <StyledCheckoutWithLoading isLoading={this.props.isRequestingCart}>
            {this.props.items.length > 0 ? (
              <React.Fragment>
                <div className="row">
                  <div
                    className={
                      isBrowser && !this.props.isLaptop
                        ? "col-8 offset-2 col-sm-8 offset-sm-2 col-md-8 offset-md-2"
                        : "col-12 col-sm-12 col-md-12"
                    }
                  >
                    <CheckoutCartProductsList
                      products={this.props.items}
                      onAttachServiceToProduct={this.onAttachServiceToProduct}
                      onRemoveServiceFromProduct={
                        this.onRemoveServiceFromProduct
                      }
                      onRemoveProductFromCart={this.onRemoveProductFromCart}
                      onChangeContact={this.onChangeContact}
                      onUpdateProductPeriod={this.onUpdateProductPeriod}
                      onUpdateHostingPeriod={this.onUpdateHostingPeriod}
                      updatingHostingPeriod={this.state.updatingHostingPeriod}
                      updatingHostingPeriodId={
                        this.state.updatingHostingPeriodId
                      }
                      updatingDomain={this.state.updatingDomain}
                      onUpdateServerSettings={this.onUpdateServerSettings}
                      updatingServerSettings={this.state.updatingServerSettings}
                      updatingServerSettingsId={
                        this.state.updatingServerSettingsId
                      }
                      deletingProductId={this.state.deletingProductId}
                      onSaveSingleDomainNameservers={
                        this.onSaveSingleDomainNameservers
                      }
                      onSubmittingDomainNameservers={
                        this.onSubmittingNamerservers
                      }
                      submittingDomainNameservers={
                        this.state.submittingDomainNameservers
                      }
                      invalidDomainNameservers={
                        this.state.invalidDomainNameservers
                      }
                      onBlockCheckout={this.onBlockCheckout}
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      isBrowser && !this.props.isLaptop
                        ? "col-8 offset-2 col-sm-8 offset-sm-2 col-md-8 offset-md-2"
                        : "col-12 col-sm-12 col-md-12"
                    }
                  >
                    <CartSummary
                      customerCurrencyCode={this.props.customerCurrencyCode}
                      products={this.props.items}
                      cartTotalDiscount={this.props.cartTotalDiscount}
                      cartTotal={this.props.cartTotal}
                    />
                  </div>
                </div>
                <div className="row">
                  <div
                    className={
                      isBrowser && !this.props.isLaptop
                        ? "col-8 offset-2 col-sm-8 offset-sm-2 col-md-8 offset-md-2"
                        : "col-12 col-sm-12 col-md-12"
                    }
                  >
                    {this.props.paymentMethods && (
                      <CheckoutPayment
                        customer={this.props.customer}
                        paymentMethods={this.props.paymentMethods}
                        selectedPayment={this.state.selectedPayment}
                        onSelectPayment={this.onSelectPayment}
                        onCheckout={this.onCheckout}
                        disableCheckoutButton={this.state.disableCheckoutButton}
                      />
                    )}
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <CartEmptyState
                shopDomainsRoute={ROUTE_SHOP_DOMAINS}
                shopHostingRoute={ROUTE_SHOP_HOSTING}
                shopServersRoute={ROUTE_SHOP_SERVERS}
              />
            )}
          </StyledCheckoutWithLoading>
        </div>

        {this.state.showCreateContactModal && (
          <Modal
            title={this.props.translate(
              "domain.domain-contacts.create-contact"
            )}
            onCloseModal={this.onHideCreateContactModal}
            body={() => (
              <ContactDetailsForm
                onSubmit={this.onUpdateContact}
                onSubmitting={this.onSubmittingContactModal}
                validate={this.validateContact}
                showPayerNotice={true}
                countries={this.props.countries}
              />
            )}
            footer={() => (
              <Fragment>
                <SecondaryButton
                  onClick={() => this.onHideCreateContactModal()}
                >
                  {this.props.translate("domain.domain-contacts.cancel")}
                </SecondaryButton>
                <PrimaryButton
                  disabled={
                    this.state.submittingContactModal ||
                    this.state.invalidContact
                  }
                  submitting={this.state.submittingContactModal}
                  onClick={() => this.onTriggerUpdateContactFormSubmit()}
                >
                  {this.props.translate(
                    "domain.domain-contacts.create-and-select-contact"
                  )}
                </PrimaryButton>
              </Fragment>
            )}
          />
        )}

        {this.state.showChangeContactModal && (
          <Modal
            title={this.props.translate(
              "domain.domain-contacts.change-contact"
            )}
            onCloseModal={this.onHideChangeContactModal}
            body={() => (
              <ContactDetailsFormWithSearch
                contact={this.state.selectedContact}
                onSubmit={this.onUpdateContact}
                onSubmitting={this.onSubmittingContactModal}
                onCancel={this.onHideChangeContactModal}
                onSearchContacts={this.onSearchContacts}
                onSelectContact={this.onSelectContact}
                onCreateContact={this.onCreateContact}
                validate={this.validateSearhContact}
                isMkDomain={this.state.selectedProduct.isMkDomain}
                countries={this.props.countries}
              />
            )}
            footer={() => (
              <Fragment>
                {this.state.selectedProduct.isMkDomain && (
                  <SecondaryButton
                    onClick={() => {
                      this.onEditDomainContact(
                        this.state.selectedContact,
                        this.state.selectedProduct,
                        this.state.selectedContact.type
                      );
                    }}
                  >
                    {this.props.translate(
                      "domain.domain-contacts.change-contact"
                    )}
                  </SecondaryButton>
                )}
                {!this.state.selectedProduct.isMkDomain && (
                  <SecondaryButton
                    onClick={() => this.onHideChangeContactModal()}
                  >
                    {this.props.translate("domain.domain-contacts.cancel")}
                  </SecondaryButton>
                )}
                <PrimaryButton
                  disabled={
                    !this.state.selectedContact ||
                    this.state.submittingContactModal ||
                    this.state.invalidContact
                  }
                  submitting={this.state.submittingContactModal}
                  onClick={() => this.onTriggerUpdateContactFormSubmit()}
                >
                  {this.props.translate(
                    "domain.domain-contacts.select-contact"
                  )}
                </PrimaryButton>
              </Fragment>
            )}
          />
        )}

        {this.state.showEditContactModal && (
          <Modal
            title={this.props.translate(
              "domain.domain-contacts.edit-contact-info"
            )}
            onCloseModal={this.onHideEditContactModal}
            body={() => (
              <ContactDetailsForm
                contact={this.state.selectedContact}
                onSubmit={this.onUpdateContact}
                onSubmitting={this.onSubmittingContactModal}
                validate={this.validateContact}
                countries={this.props.countries}
                disabledFields={{ contact_type: true }}
              />
            )}
            footer={() => (
              <Fragment>
                <SecondaryButton onClick={() => this.onHideEditContactModal()}>
                  {this.props.translate("domain.domain-contacts.cancel")}
                </SecondaryButton>
                <PrimaryButton
                  disabled={
                    this.state.submittingContactModal ||
                    this.state.invalidDomainContact ||
                    this.state.invalidContact
                  }
                  submitting={this.state.submittingContactModal}
                  onClick={() => this.onTriggerUpdateContactFormSubmit()}
                >
                  {this.props.translate("domain.domain-contacts.save-changes")}
                </PrimaryButton>
              </Fragment>
            )}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    items: getItems(state),
    cartId: getCartId(state),
    paymentMethods: getPaymentMethods(state),
    customer: getCustomerPersonalInformation(state),
    isRequestingCart: isRequestingCart(state),
    customerCurrencyCode: getCustomerCurrencyCode(state),
    cartTotalDiscount: getTotalDiscount(state),
    cartTotal: getTotal(state),
    countries: getCountriesFormatted(state),
    isCheckoutBlocked: isCheckoutBlocked(state),
    firstCheckoutBlockingProduct: firstCheckoutBlockingProduct(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchCart,
      attachServiceToProduct,
      removeServiceFromProduct,
      removeProductFromCart,
      changeProductContact,
      changeCustomerDomainContact,
      searchContacts,
      attachDomainAssistantToOrderItem,
      updateDomainAssistantToOrderItem,
      updateNameserversForDomainInCart,
      clearCart,
      fetchPaymentMethods,
      checkoutCart,
      updateProduct,
      updateServerSettings,
      blockCheckoutByProduct,
      unblockCheckoutByProduct
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(withSizes(isLaptop)(Checkout)));
