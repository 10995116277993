import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

const StyledTable = styled.table`
  background-color: ${theme.white};
  //border: 1px solid ${theme.neutral3};
  border-radius: 5px !important;
  border-collapse: unset;
  border-spacing: 0;

  & thead th {
    border-bottom: 0;
    border-top: 0;
    padding: 15px 15px;
    color: ${theme.neutral5};
    font-size: 14px;
    line-height: 1.43;
    font-weight: 600;
    vertical-align: middle;
  }

    & thead th.row-selector{
    padding: 15px 15px;
    padding-left: 25px;
  }

  & thead th:first-child {
    padding-left :30px;
  }
  & thead th:last-child {
    padding-right: 30px;
  }

  & tbody td {
    padding: 15px 15px;
    font-size: 16px;
    line-height: 1.43;
    color: ${theme.neutralBase};
    vertical-align: middle;
    border-top: 1px solid ${theme.neutral3};
  }

  & tbody td.row-selector{
    padding: 15px 15px;
    padding-left: 25px;
  }

  & tbody td:first-child {
    padding-left: 30px;
  }

  & tbody td:last-child {
    padding-right: 30px;
  }

  & tbody td.actions{
    padding: 0;
    padding-right: 25px;

    .btn {
      padding: 0 10px;
    }

    .btn.dropdown-toggle {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  & tbody td a {
    color: ${theme.blueBase};
  }
`;

const Table = ({ children, ...rest }) => (
  <StyledTable {...rest} className="table">
    {children}
  </StyledTable>
);

export default Table;
