import React from "react";

import Loader from "../Loader";

const KnowledgeBaseCategoriesLoader = () => (
  <Loader width="280" height="300">
    <rect x="5" y="10" width="270" height="20" rx="3" />
    <rect x="5" y="40" width="270" height="20" rx="3" />
    <rect x="5" y="70" width="270" height="20" rx="3" />
    <rect x="5" y="100" width="270" height="20" rx="3" />
  </Loader>
);

export default KnowledgeBaseCategoriesLoader;
