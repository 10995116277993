import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Badge from "../Badge";

const BadgeTerminate = styled(Badge)`
  background-color: ${theme.neutral1}
  color: ${theme.neutral6};
`;

export default BadgeTerminate;
