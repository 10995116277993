import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import Button from "../Buttons/Button";
import DropdownMenu from "../DropdowMenu";

const StyledButtonGroupDropdown = styled.div`
  background-color: ${theme.white};
  border-color: ${theme.neutral3};

  & > button {
    margin-right: 0;
  }

  & > button:disabled {
    background-color: ${theme.white};
    border-color: ${theme.neutral3};
    color: ${theme.neutral4};
  }

  & > .btn.dropdown-toggle {
    padding-left: 20px;
    padding-right: 20px;
  }

  & > .btn.dropdown-toggle:after {
    border-top: 6px solid;
    border-right: 6px solid transparent;
    border-bottom: 0;
    border-left: 6px solid transparent;
    vertical-align: middle;
  }
`;

class ButtonGroupDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };

    this.dropdownRef = null;
  }

  setDropdownRef = ref => (this.dropdownRef = ref);

  onShowMenu = e => {
    e.preventDefault();
    this.setState({ opened: true }, () => {
      document.addEventListener("click", this.onCloseMenu);
    });
  };

  onCloseMenu = e => {
    e.preventDefault();
    if (this.dropdownRef) {
      this.setState({ opened: false }, () => {
        document.removeEventListener("click", this.onCloseMenu);
      });
    }
  };

  componentWillUnmout() {
    if (this.dropdownRef) {
      document.removeEventListener("click", this.onCloseMenu);
    }
  }

  render() {
    return (
      <StyledButtonGroupDropdown
        ref={this.setDropdownRef}
        className={`btn-group ${this.state.opened ? "show" : ""} ${
          this.props.className
        }`}
        {...this.props}
      >
        <Button
          className={`btn dropdown-toggle ${this.props.buttonClassName}`}
          size={this.props.size}
          onClick={this.onShowMenu}
          disabled={this.props.disabled}
        >
          {this.props.text}
        </Button>
        <DropdownMenu
          className={`${this.state.opened ? "show" : ""}`}
          alignMenu={this.props.alignMenu}
        >
          {this.props.children}
        </DropdownMenu>
      </StyledButtonGroupDropdown>
    );
  }
}

ButtonGroupDropdown.defaultProps = {
  alignMenu: "right"
};

export default ButtonGroupDropdown;
