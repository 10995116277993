import React from "react";
import styled from "styled-components";

const StyledSidebarNav = styled.ul`
  margin-bottom: 30px;
  list-style: none;
`;

const SidebarNav = props => (
  <StyledSidebarNav
    {...props}
    className={`${props.className} nav flex-column`}
  />
);

SidebarNav.defaultProps = {
  className: "nav flex-column"
};
export default SidebarNav;
