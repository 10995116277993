export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';


export const verifyEmailRequest = () => ({
  type: VERIFY_EMAIL_REQUEST,
});

export const verifyEmailSuccess = () => ({
  type: VERIFY_EMAIL_SUCCESS,
});

export const verifyEmailFailure = () => ({
  type: VERIFY_EMAIL_FAILURE,
});
