import * as notificationsService from "../../../services/notificationsService.js";

export const NOTIFICATIONS_FEATURE = "features/Notifications";
export const NOTIFICATIONS_REQUEST = `${NOTIFICATIONS_FEATURE}/NOTIFICATIONS_REQUEST`;
export const NOTIFICATIONS_RECEIVE = `${NOTIFICATIONS_FEATURE}/NOTIFICATIONS_RECEIVE`;
export const NOTIFICATIONS_LISTEN = `${NOTIFICATIONS_FEATURE}/NOTIFICATIONS_LISTEN`;
export const NOTIFICATIONS_STOP_LISTEN = `${NOTIFICATIONS_FEATURE}/NOTIFICATIONS_STOP_LISTEN`;
export const NOTIFICATIONS_ENABLE_REQUEST = `${NOTIFICATIONS_FEATURE}/NOTIFICATIONS_ENABLE_REQUEST`;
export const NOTIFICATIONS_ENABLE_RECEIVE = `${NOTIFICATIONS_FEATURE}/NOTIFICATIONS_ENABLE_RECEIVE`;
export const NOTIFICATIONS_CONFIG_REQUEST = `${NOTIFICATIONS_FEATURE}/NOTIFICATIONS_CONFIG_REQUEST`;
export const NOTIFICATIONS_CONFIG_RECEIVE = `${NOTIFICATIONS_FEATURE}/NOTIFICATIONS_CONFIG_RECEIVE`;
export const NOTIFICATIONS_MARK_AS_READ = `${NOTIFICATIONS_FEATURE}/NOTIFICATIONS_MARK_AS_READ`;

let notificationsInterval = null;

export function fetchNotifications() {
  return dispatch => {
    dispatch(requestNotifications());
    return notificationsService
      .fetchUnreadNotifications()
      .then(
        result => {
          if (result) {
            const { notifications } = result.data.data;
            dispatch(receiveNotifications(notifications));
            return result;
          }
        },
        error => {
          console.log("something happened", error);
          return error;
        }
      )
      .catch(error => {
        console.log(error);
        return error;
      });
  };
}

export function listenForNotifications() {
  return dispatch => {
    dispatch({ type: NOTIFICATIONS_LISTEN });
  };
}

export function stopListeningForNotifications() {
  return dispatch => {
    dispatch({ type: NOTIFICATIONS_STOP_LISTEN });
  };
}

export function markNotificationsAsRead(notifications) {
  return dispatch => {
    const ids = notifications.map(n => n.id);
    return notificationsService.markAsRead(ids).then(result => {
      const { error } = result.data;
      if (!error) {
        notifications.forEach(notification =>
          dispatch(notificationMarkAsRead(notification))
        );
      }
    });
  };
}

function notificationMarkAsRead(notification) {
  return {
    type: NOTIFICATIONS_MARK_AS_READ,
    payload: notification
  };
}

export function enableNotifications(notification, enabled) {
  return dispatch => {
    dispatch(enableNotificationsRequest(notification));
    return notificationsService
      .enableNotifications(notification, enabled)
      .then(result => {
        dispatch(enableNotificationsReceive(notification, enabled));
      });
  };
}

function enableNotificationsRequest(notification) {
  return {
    type: NOTIFICATIONS_ENABLE_REQUEST,
    payload: {
      notification,
      enable: true
    }
  };
}

function enableNotificationsReceive(notification, enabled) {
  return {
    type: NOTIFICATIONS_ENABLE_RECEIVE,
    payload: {
      notification,
      enabled
    }
  };
}

export function fetchNotificationsConfig() {
  return dispatch => {
    dispatch(requestNotificationsConfig());
    return notificationsService.fetchNotificationsConfig().then(result => {
      const { error } = result.data;
      const { email_notification } = result.data.data;

      if (!error) {
        dispatch(receiveNotificationsConfig(email_notification));
      }
    });
  };
}

function requestNotificationsConfig() {
  return {
    type: NOTIFICATIONS_CONFIG_REQUEST
  };
}

function receiveNotificationsConfig(config) {
  return {
    type: NOTIFICATIONS_CONFIG_RECEIVE,
    payload: config
  };
}

function requestNotifications() {
  return {
    type: NOTIFICATIONS_REQUEST
  };
}

function receiveNotifications(notifications) {
  return {
    type: NOTIFICATIONS_RECEIVE,
    payload: notifications
  };
}
