import * as PA from "../actions/productsActions";

import handleReducerActions from "../../../core/handleReducerActions";

import { APP_STATE_COMPLETE_RESET } from "../../AppState/actions/appStateActions";

const initialState = {
  listing: [],
  listingByGroup: [],
  isFetchingProduct: false
};

const productsStateHandlers = {
  [PA.PRODUCTS_LISTING_REQUEST]: productsListingRequest,
  [PA.PRODUCTS_LISTING_RECEIVE]: productsListingReceive,
  [PA.PRODUCTS_LISTING_BY_GROUP_REQUEST]: productsListingByGroupRequest,
  [PA.PRODUCTS_LISTING_BY_GROUP_RECEIVE]: productsListingByGroupReceive,
  [APP_STATE_COMPLETE_RESET]: resetState
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(productsStateHandlers, state, action);
}

function productsListingRequest(state, action) {
  return { ...state, isFetchingProducts: true };
}

function productsListingReceive(state, action) {
  return {
    ...state,
    listing: action.payload,
    isFetchingProducts: false
  };
}

function productsListingByGroupRequest(state, action) {
  return { ...state, isFetchingProducts: true };
}

function productsListingByGroupReceive(state, action) {
  return {
    ...state,
    listingByGroup: action.payload,
    isFetchingProducts: false
  };
}

function resetState(state) {
  return { ...initialState };
}

// this is a selector
export function getProductsListing(state) {
  return state.catalog.products.listing;
}

export function getProductsListingByGroup(state) {
  return state.catalog.products.listingByGroup;
}

export function isFetchingProducts(state) {
  return state.catalog.products.isFetchingProducts;
}
