import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { withLocalize } from "react-localize-redux";
import { debounce } from "throttle-debounce";
import { withRouter } from "react-router-dom";

import * as searchService from "../../services/searchService";

import ContainerInlineDialog from "../Containers/ContainerInlineDialog";
import SearchField from "../SearchField";

import GlobalSearchResultsGroup from "./GlobalSearchResultsGroup";
import GlobalSearchResult from "./GlobalSearchResult";

import loading from "../../assets/images/loading.gif";

import {
  compileRoute,
  ROUTE_VIEW_DOMAIN_GENERAL_INFO,
  ROUTE_BILLING_VIEW_PROINVOICE,
  ROUTE_VIEW_HOSTING_GENERAL_INFO,
  ROUTE_CONTACTS_VIEW_CONTACT,
  ROUTE_BILLING_PROINVOICES,
  ROUTE_DOMAINS,
  ROUTE_HOSTING,
  ROUTE_CONTACTS
} from "../../routes/routes";

const StyledGlobalSearchWrapper = styled.div`
  position: relative;
`;

const StyledResultsWrapper = styled(ContainerInlineDialog)`
  display: block;
  position: absolute;
  left: 0;
  top: 64px;
  z-index: 1;
  width: 100%;
  max-height: 500px;
  overflow: hidden;
  overflow-y: auto;
`;

const SearchingIndicator = styled.div`
  padding: 15px;
  text-align: center;
  background-color: ${theme.white};
  display: block;
`;

const SearchResults = styled.div`
  display: block;
`;

const SearchResultsNoResultsFound = styled.div`
  padding: 15px;
  display: block;
`;

class GlobalSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isFetching: false,
      fetched: false,
      keyword: "",
      results: [],
      hasResults: false,
      selectedIndex: -1
    };
    this.indexCount = 0; // here so it doesn't trigger rerenders
    this.resultsRef = null;
    this.setResultsRef = element => (this.resultsRef = element);

    this.onSearchThrottled = debounce(100, this.onSearch);
    this.viewSingleRecordRoutes = {
      contact: compileRoute(ROUTE_CONTACTS_VIEW_CONTACT),
      domain: compileRoute(ROUTE_VIEW_DOMAIN_GENERAL_INFO),
      hosting: compileRoute(ROUTE_VIEW_HOSTING_GENERAL_INFO),
      invoice: compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)
    };
    this.viewAllRecordsRoutes = {
      view_all_domains: ROUTE_DOMAINS,
      view_all_hostings: ROUTE_HOSTING,
      view_all_invoices: ROUTE_BILLING_PROINVOICES,
      view_all_contacts: ROUTE_CONTACTS
    };
  }

  componentDidMount() {
    document.addEventListener("onkeydown", this.onKeyDown);
    document.addEventListener("click", this.onOutsideClick);
  }

  componentWillUmount() {
    document.removeEventListener("onkeydown", this.onKeyDown);
    document.removeEventListener("click", this.onOutsideClick);
  }

  onOutsideClick = e => {
    if (e.target.id !== "global-search-input") {
      this.setState({
        show: false,
        keyword: "",
        selectedIndex: -1
      });
    }
  };

  onKeyDown = e => {
    if (!this.state.show) {
      return;
    }

    if (e.keyCode === 13) {
      const record = this.state.results.find(
        result => result.index === this.state.selectedIndex
      );
      this.setState({
        show: false,
        keyword: "",
        selectedIndex: -1
      });

      if (record && record.type) {
        if (record.type.indexOf("view_all") > -1) {
          this.props.history.push(this.viewAllRecordsRoutes[record.type]);
        } else {
          this.props.history.push(
            this.viewSingleRecordRoutes[record.type](record)
          );
        }
      }
    }

    // Escape
    if (e.keyCode === 27) {
      this.setState({
        show: false,
        selectedIndex: -1
      });
    }

    // Up
    if (e.keyCode === 38) {
      e.preventDefault();
      if (this.state.selectedIndex === 0) {
        return;
      }

      this.setState(
        {
          selectedIndex: this.state.selectedIndex - 1
        },
        () => {
          const record = this.state.results.find(
            result => result.index === this.state.selectedIndex
          );
          this.scrollToRecord(record);
        }
      );
    }

    // Down
    if (e.keyCode === 40) {
      if (this.state.selectedIndex + 1 >= this.indexCount) {
        return;
      }

      this.setState(
        {
          selectedIndex: this.state.selectedIndex + 1
        },
        () => {
          const record = this.state.results.find(
            result => result.index === this.state.selectedIndex
          );
          this.scrollToRecord(record);
        }
      );
    }
  };

  scrollToRecord = record => {
    if (!record) return;
    const element = document.getElementById(record.type + record.id);
    if (element) {
      this.resultsRef.scrollTop =
        record.index + element.offsetTop - element.offsetHeight;
    }
  };

  onClick = (e, type, result) => {
    const record = this.state.results.find(
      resultFromList => resultFromList.index === result.index
    );

    this.setState({
      show: false,
      keyword: "",
      selectedIndex: -1
    });

    if (record.type.indexOf("view_all") > -1) {
      this.props.history.push(this.viewAllRecordsRoutes[record.type]);
    } else {
      this.props.history.push(this.viewSingleRecordRoutes[record.type](record));
    }
  };

  onChange = e => {
    const keyword = e.target.value;

    if (keyword.length > 0 && !this.state.show) {
      this.setState({
        show: true
      });
    }

    if ((!keyword || keyword.length === 0) && this.state.show) {
      this.setState({
        show: false,
        isFetching: false,
        fetched: false
      });
    }
    this.setState(
      {
        keyword
      },
      () => {
        if (keyword.length > 2) {
          this.onSearchThrottled();
        }
      }
    );
  };

  onSearch = () => {
    if (this.state.keyword.length > 0) {
      this.setState({
        isFetching: true,
        fetched: false,
        results: []
      });
      searchService.globalSearch(this.state.keyword).then(results => {
        this.indexCount = 0;

        let domains = results
          .filter(result => result.type === "domain")
          .map(result => {
            return {
              ...result,
              index: this.indexCount++
            };
          });

        if (domains.length > 0) {
          domains = domains.concat({
            id: "view_all_domains",
            name: this.props.translate("global.search.view-all-domains"),
            type: "view_all_domains",
            index: this.indexCount++
          });
        }

        let hostings = results
          .filter(result => result.type === "hosting")
          .map(result => {
            return {
              ...result,
              index: this.indexCount++
            };
          });

        if (hostings.length > 0) {
          hostings = hostings.concat({
            id: "view_all_hostings",
            name: this.props.translate("global.search.view-all-hostings"),
            type: "view_all_hostings",
            index: this.indexCount++
          });
        }

        let invoices = results
          .filter(result => result.type === "invoice")
          .map(result => {
            return {
              ...result,
              index: this.indexCount++
            };
          });

        if (invoices.length > 0) {
          invoices = invoices.concat({
            id: "view_all_invoices",
            name: this.props.translate("global.search.view-all-invoices"),
            type: "view_all_invoices",
            index: this.indexCount++
          });
        }

        let contacts = results
          .filter(result => result.type === "contact")
          .map(result => {
            return {
              ...result,
              index: this.indexCount++
            };
          });

        if (contacts.length > 0) {
          contacts = contacts.concat({
            id: "view_all_contacts",
            name: this.props.translate("global.search.view-all-contacts"),
            email: "",
            type: "view_all_contacts",
            index: this.indexCount++
          });
        }

        if (results.length > 0) {
          const all = domains.concat(hostings, invoices, contacts);
          this.setState({
            isFetching: false,
            fetched: true,
            results: all,
            hasResults: results.length > 0
          });
        } else if (results.length === 0) {
          this.setState({
            isFetching: false,
            fetched: true,
            results: [],
            hasResuls: false
          });
        }
      });
    }
  };

  render() {
    const domains = this.state.results.filter(
      result => result.type === "domain" || result.type === "view_all_domains"
    );
    const hostings = this.state.results.filter(
      result => result.type === "hosting" || result.type === "view_all_hostings"
    );
    const invoices = this.state.results.filter(
      result => result.type === "invoice" || result.type === "view_all_invoices"
    );
    const contacts = this.state.results.filter(
      result => result.type === "contact" || result.type === "view_all_contacts"
    );

    let hasResultsFromApi =
      domains.length > 0 ||
      hostings.length > 0 ||
      invoices.length > 0 ||
      contacts.length > 0;

    return (
      <StyledGlobalSearchWrapper className="form-inline">
        <SearchField
          id="global-search-input"
          placeholder={this.props.translate("navigation.search-placeholder")}
          className="form-control nav-search"
          onChange={this.onChange}
          value={this.state.keyword}
          onKeyDown={this.onKeyDown}
        />
        {this.state.show && (
          <StyledResultsWrapper ref={this.setResultsRef}>
            {this.state.isFetching && (
              <SearchingIndicator>
                {this.state.isFetching && !this.state.fetched && (
                  <img src={loading} alt="" />
                )}
                {!this.state.isFetching &&
                  this.state.fetched &&
                  !this.state.hasResults && <p>No results...</p>}
              </SearchingIndicator>
            )}
            {!this.state.isFetching && !this.state.hasResults && (
              <SearchResultsNoResultsFound>
                {this.props.translate("navigation.no-results-found")}
              </SearchResultsNoResultsFound>
            )}

            {!this.state.isFetching &&
              this.state.fetched &&
              this.state.hasResults && (
                <SearchResults>
                  {!hasResultsFromApi && (
                    <SearchResultsNoResultsFound>
                      {this.props.translate("navigation.no-results-found")}
                    </SearchResultsNoResultsFound>
                  )}
                  {domains && domains.length > 0 && (
                    <GlobalSearchResultsGroup
                      key="domains"
                      title={this.props.translate("global.search.domains")}
                    >
                      {domains.map(result => (
                        <GlobalSearchResult
                          id={result.type + result.id}
                          key={result.type + result.id}
                          type={result.type}
                          result={result}
                          keyword={this.state.keyword}
                          active={this.state.selectedIndex}
                          index={result.index}
                          onClick={e => this.onClick(e, "domain", result)}
                        />
                      ))}
                    </GlobalSearchResultsGroup>
                  )}

                  {hostings && hostings.length > 0 && (
                    <GlobalSearchResultsGroup
                      key="hostings"
                      title={this.props.translate("global.search.hostings")}
                    >
                      {hostings.map(result => (
                        <GlobalSearchResult
                          id={result.type + result.id}
                          key={result.type + result.id}
                          type={result.type}
                          result={result}
                          keyword={this.state.keyword}
                          active={this.state.selectedIndex}
                          index={result.index}
                          onClick={e => this.onClick(e, "hosting", result)}
                        />
                      ))}
                    </GlobalSearchResultsGroup>
                  )}

                  {invoices && invoices.length > 0 && (
                    <GlobalSearchResultsGroup
                      key="invoices"
                      title={this.props.translate("global.search.invoices")}
                    >
                      {invoices.map(result => (
                        <GlobalSearchResult
                          id={result.type + result.id}
                          key={result.type + result.id}
                          type={result.type}
                          result={result}
                          keyword={this.state.keyword}
                          active={this.state.selectedIndex}
                          index={result.index}
                          onClick={e => this.onClick(e, "invoice", result)}
                        />
                      ))}
                    </GlobalSearchResultsGroup>
                  )}

                  {contacts && contacts.length > 0 && (
                    <GlobalSearchResultsGroup
                      key="contacts"
                      title={this.props.translate("global.search.contacts")}
                    >
                      {contacts.map(result => (
                        <GlobalSearchResult
                          id={result.type + result.id}
                          key={result.type + result.id}
                          type={result.type}
                          result={result}
                          keyword={this.state.keyword}
                          active={this.state.selectedIndex}
                          index={result.index}
                          onClick={e => this.onClick(e, "contact", result)}
                        />
                      ))}
                    </GlobalSearchResultsGroup>
                  )}
                </SearchResults>
              )}
          </StyledResultsWrapper>
        )}
      </StyledGlobalSearchWrapper>
    );
  }
}
export default withRouter(withLocalize(GlobalSearch));
