import React from "react";
import {Link, withRouter} from "react-router-dom";
import {Translate, withLocalize} from "react-localize-redux";
import MyReferralInvoiceFilterList from "../MyReferralInvoceFilterList";
import MyReferralInvoiceTable from "../MyReferralInvoceTable";
import {bindActionCreators} from "redux";
import {fetchCustomerPersonalInformation} from "../../Customer/actions/customerActions";
import {fetchReferralInvoices} from "../actions/referralActions";
import {connect} from "react-redux";
import {
    getCustomerPersonalInformation,
    isFetchingCustomerPersonalInformation
} from "../../Customer/reducers/customerReducer";
import {getReferralDetails} from "../reducers/referralReducer";
import withLoading from "../../../components/Loaders/WithLoading";
import FilterLoader from "../../../components/Loaders/FilterLoader";
import {MobileOnlyView, TabletView, BrowserView} from "react-device-detect";


class MyReferralInvoices extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchReferralInvoices(null, null, this.props.referral.sort, this.props.referral.filters);
    }

    render() {

        const MyReferralInvoiceFilterListWithLoading = withLoading(
            MyReferralInvoiceFilterList,
            FilterLoader
        );
        return (

            <Translate>
                {({translate}) => (
                    <div className={'row'}>
                        <div className="col-12 col-sm-12 col-md-12">
                            <MobileOnlyView>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <MyReferralInvoiceFilterListWithLoading
                                            paging={this.props.referral.paging}
                                            sort={this.props.referral.sort}
                                            filters={this.props.referral.filters}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <MyReferralInvoiceTable
                                            paging={this.props.referral.paging}
                                            sort={this.props.referral.sort}
                                            filters={this.props.referral.filters}
                                            total_revenue={this.props.referral.total_revenue}
                                            invoices={this.props.referral.all}
                                        />
                                    </div>
                                </div>
                            </MobileOnlyView>
                            <TabletView>
                                <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <MyReferralInvoiceFilterListWithLoading
                                            paging={this.props.referral.paging}
                                            sort={this.props.referral.sort}
                                            filters={this.props.referral.filters}
                                        />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                        <MyReferralInvoiceTable
                                            paging={this.props.referral.paging}
                                            sort={this.props.referral.sort}
                                            filters={this.props.referral.filters}
                                            total_revenue={this.props.referral.total_revenue}
                                            invoices={this.props.referral.all}
                                        />
                                    </div>
                                </div>
                            </TabletView>
                            <BrowserView>
                                <div className="row">

                                <div className="col-md-4 col-sm-4">
                                    <MyReferralInvoiceFilterListWithLoading
                                        paging={this.props.referral.paging}
                                        sort={this.props.referral.sort}
                                        filters={this.props.referral.filters}
                                    />
                                </div>
                                <div className="col-md-8 col-sm-8">
                                    <MyReferralInvoiceTable
                                        paging={this.props.referral.paging}
                                        sort={this.props.referral.sort}
                                        filters={this.props.referral.filters}
                                        total_revenue={this.props.referral.total_revenue}
                                        invoices={this.props.referral.all}
                                    />
                                </div>
                                </div>
                            </BrowserView>

                        </div>
                    </div>
                    )}
            </Translate>
        )

    }
}

const mapStateToProps = state => {
    return {
        customerPersonalInformation: getCustomerPersonalInformation(state),
        referral: getReferralDetails(state),
        isFetchingCustomerPersonalInformation: isFetchingCustomerPersonalInformation(
            state
        ),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {fetchCustomerPersonalInformation, fetchReferralInvoices},
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withLocalize(MyReferralInvoices)));