import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { withLocalize } from "react-localize-redux";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import isLaptop from "../../../../../../utils/isLaptopScreen";
import { withSizes } from "react-sizes";

import {
  validateDnsRecordHostname,
  validateDnsRecordTTL,
  validateDnsIP6Address
} from "../../../../../../common/validationRules";

import RecordTypeSelect from "../Fields/RecordTypeSelect";
import Hostname from "../Fields/Hostname";
import TTL from "../Fields/TTL";
import AddressValue from "../Fields/AddressValue";

import PrimaryButton from "../../../../../../components/Buttons/PrimaryButton";

const StyledWrapper = styled.div`
  vertical-align: top;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  top: ${props => {
    if (props.isTablet) return "23px";
  }};
`;

const validate = values => {
  return {
    name: validateDnsRecordHostname(values.name),
    ttl: validateDnsRecordTTL(values.ttl),
    ip_v6_address: validateDnsIP6Address(values.ip_v6_address)
  };
};
class AAAARecordForm extends React.Component {
  render() {
    return (
      <StyledWrapper className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <Form
            onSubmit={this.props.onSubmit}
            validate={validate}
            initialValues={this.props.initialValues || {}}
            render={({
              handleSubmit,
              submitting,
              valid,
              invalid,
              values,
              form
            }) => (
              <form
                id={
                  this.props.record &&
                  `dns-record-form-${this.props.record.line}`
                }
                onSubmit={e => {
                  form.blur("name");
                  form.blur("ttl");
                  form.blur("ip_v6_address");
                  handleSubmit(e);
                }}
              >
                <Field
                  component={RecordTypeSelect}
                  name="type"
                  domain={this.props.domain}
                  record={this.props.record}
                  disabled={submitting}
                  onChange={this.props.onChange}
                  formType={this.props.formType}
                />
                <Field component={Hostname} name="name" disabled={submitting} />
                <Field component={TTL} name="ttl" disabled={submitting} />
                <Field
                  component={AddressValue}
                  name="ip_v6_address"
                  disabled={submitting}
                />
                <StyledPrimaryButton
                  type="submit"
                  disabled={submitting || invalid}
                  submitting={submitting}
                  isMobile={isMobileOnly}
                  isLaptop={this.props.isLaptop}
                  isTablet={isTablet}
                  isBrowser={isBrowser}
                >
                  {this.props.translate("domain.zone-file.add-dns-record")}
                </StyledPrimaryButton>
              </form>
            )}
          />
        </div>
      </StyledWrapper>
    );
  }
}

export default withLocalize(withSizes(isLaptop)(AAAARecordForm));
