import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

import Container from "../../components/Containers/Container";

const StyledDetailsPanelWrapper = styled.div`
  margin-bottom: 30px;
`;
const StyledDetailsPanel = styled.div``;

const StyledDetailsPanelHeader = styled.div`
  & .details-panel-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    padding: 30px;
    margin-bottom: 20px;
  }

  & .details-panel-action {
    padding: 30px 15px;
    text-align: right;
  }
`;
const StyledDetailsContent = styled.div``;

const DetailsPanel = ({ children, title, action }) => (
  <StyledDetailsPanelWrapper>
    <Container>
      <StyledDetailsPanel>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <StyledDetailsPanelHeader>
              <div className="row">
                <div className="col-8 col-sm-8 col-md-8">
                  <h3 className="details-panel-title">{title}</h3>
                </div>
                <div className="col-4 col-sm-2 col-md-4">
                  <div className="details-panel-action">{action}</div>
                </div>
              </div>
            </StyledDetailsPanelHeader>
            <StyledDetailsContent>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">{children}</div>
              </div>
            </StyledDetailsContent>
          </div>
        </div>
      </StyledDetailsPanel>
    </Container>
  </StyledDetailsPanelWrapper>
);

DetailsPanel.defaultProps = {
  title: "",
  action: ""
};

export default DetailsPanel;
