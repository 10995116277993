import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Field, Form } from "react-final-form";
import { Translate } from "react-localize-redux";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useState, useEffect } from "react";
import isEmpty from "lodash/isEmpty";

import Container from "../../../../components/Containers/Container";
import InputField from "../../../../components/InputField";
import BlockRadiobox from "../../../../components/BlockRadiobox";
import Checkbox from "../../../../components/Checkbox";
import SelectBox from "../../../../components/SelectBox";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import { transliterateToCyrilic } from "../../../../services/transliterateService";


import graphicIndividualDefault from "../../../../assets/images/graphic_individual.svg";
import graphicIndividualActive from "../../../../assets/images/graphic_individual_active.svg";
import graphicCompanyDefault from "../../../../assets/images/graphic_company.svg";
import graphicCompanyActive from "../../../../assets/images/graphic_company_active.svg";

import { CONTACT_DETAILS_TYPE_COMPANY } from "../../../Domains/ViewDomain/DomainContacts/ContactDetailsForm/ContactDetailsTypes";

const StyledAccountDetails = styled.div`
  padding: 30px;
`;

const StyledHelp = styled.div`
min-width: 250px;
font-size: 12px;
line-height: 1.5;
font-weight:bold;
margin-bottom: 0;
margin-top:30px;`

const StyledTitle = styled.h3`
  font-size: 20px; 
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
`;
const StyledSubtitle = styled.span`
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.neutral6};
`;

const InputFieldInput = styled.input`
  &:disabled {
   color: #7B8BA7;
  }
`;

const StyledHeader = styled.div`
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid ${theme.neutral3};
  margin-bottom: 30px;
  margin-left: -30px;
  margin-right: -30px;
`;

const StyledCyrillic = styled.div`
    min-width: 250px;
    font-size: 12px;
    line-height: 1.5;
    font-weight:bold;
    margin-bottom: 0;`




const StyledTerms = styled.div`
  margin-top: 30px;
  margin-left: -30px;
  margin-right: -30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 40px;
  border-bottom: 1px solid ${theme.neutral3};

  & .custom-checkbox {
    width: auto;
  }

  @media (max-width: 425px) {
    padding-bottom: 80px;
  }
`;

const StyledFooter = styled.div`
  padding-top: 30px;
`;

const StyledLabel = styled.label`
margin-right:2px;`

const AccountDetails = ({
  onSubmit,
  onCancel,
  validate,
  contact,
  disabledFields,
  onGoBack,
  countries,
  onSetReCaptchaToken, activeLanguage
}) => {

  const [showIdNumberField, setShowIdNumberField] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');

  const [shouldValidateMacedonian, setShouldValidateMacedonian] = useState(false);

  const handleTaxNumberCheckboxChange = (e) => {
    setShowIdNumberField(e.target.checked);
  };

  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setShouldValidateMacedonian(selectedOption.label === "Macedonia");
  };



  const minLength = min => (value, translate) =>
    value && value.length < min
      ? translate("validation.enter-tax-number")
      : undefined;


  const validateTaxNumber = (value, values, translate) => {
    if (values.contact_type === "company") {
      if (!values.tax_number || isEmpty(values.tax_number)) {
        return translate("validation.enter-tax-number");
      }
      if (!/^\d+$/.test(values.tax_number)) {
        return translate("validation.tax-number-numeric");
      }
    }
    return minLength(3)(values.tax_number, translate);;
  };
  useEffect(() => {
    if (activeLanguage.code === 'mk') {
      const macedonia = countries.find(country => country.label === 'Macedonia');
      if (macedonia) {
        setSelectedCountry(macedonia);
        setShouldValidateMacedonian(true);
      }
    }
  }, [activeLanguage, countries]);


  return (
    <Translate>
      {({ translate }) => (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <Container>
              <StyledAccountDetails>
                <StyledHeader className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <StyledTitle>
                      {translate("cart.wizard.account-details")}
                    </StyledTitle>
                    <StyledSubtitle>
                      {translate("cart.wizard.choose-account-type")}
                    </StyledSubtitle>
                  </div>
                </StyledHeader>
                <Form
                  onSubmit={onSubmit}
                  validate={validate}
                  initialValues={{ ...contact, country: selectedCountry }}
                  render={({ handleSubmit, values, submitting, invalid, form }) => {
                    const handleResetField = (fieldName1, fieldName2) => {
                      form.change(fieldName1, "");
                      form.change(fieldName2, "");
                    };
                    return (
                      <form
                        id="domain-contact-details-form"
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <div className="row">
                              <div className="col-12 col-sm-6 col-md-6" onClick={() => handleResetField("company", "tax_number")}>
                                <Field
                                  component={BlockRadiobox}
                                  activeIcon={graphicIndividualActive}
                                  defaultIcon={graphicIndividualDefault}
                                  name="contact_type"
                                  value="individual"
                                  type="radio"
                                >
                                  {translate("cart.wizard.individual")}
                                </Field>
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                <Field
                                  component={BlockRadiobox}
                                  activeIcon={graphicCompanyActive}
                                  defaultIcon={graphicCompanyDefault}
                                  name="contact_type"
                                  value="company"
                                  type="radio"
                                >
                                  {translate("cart.wizard.company")}
                                </Field>
                              </div>
                            </div>
                            <div className="row">
                              <Field
                                component="input"
                                value={`${values.first_name} ${values.last_name}`}
                                name="full_name"
                                type="hidden"
                              />
                              <div className="col-12 col-sm-12 col-md-12">
                                <Field
                                  component={SelectBox}
                                  label={translate("domain.domain-contacts.country")}
                                  options={countries}
                                  isSearchable={true}
                                  name="country"
                                  required
                                  onChange={handleCountryChange}
                                  value={selectedCountry} // Ensure the entire object is passed
                                />
                                {shouldValidateMacedonian && (
                                  <StyledCyrillic>{translate("domain.domain-contacts.must-be-cyrillic")}</StyledCyrillic>
                                )}
                              </div>


                              <div className="col-12 col-sm-6 col-md-6">
                                <Field
                                  component={InputField}
                                  label={translate("cart.wizard.first-name")}
                                  name="first_name"
                                  readOnly={
                                    disabledFields && !!disabledFields.first_name
                                  }
                                  required
                                  validate={shouldValidateMacedonian ? (value) => {
                                    if (!value) {
                                      return translate("validation.enter-tax-number");
                                    }
                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                      return translate("domain.domain-contacts.must-be-cyrillic");
                                    }
                                  } : undefined}
                                  parse={shouldValidateMacedonian && transliterateToCyrilic}
                                />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                <Field
                                  component={InputField}
                                  label={translate("cart.wizard.last-name")}
                                  name="last_name"
                                  readOnly={
                                    disabledFields && !!disabledFields.last_name
                                  }
                                  required
                                  validate={shouldValidateMacedonian ? (value) => {
                                    if (!value) {
                                      return translate("validation.enter-tax-number");
                                    }
                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                      return translate("domain.domain-contacts.must-be-cyrillic");
                                    }
                                  } : undefined}
                                  parse={shouldValidateMacedonian && transliterateToCyrilic}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-sm-6 col-md-6">
                                {" "}
                                <Field
                                  component={InputField}
                                  label={translate("cart.wizard.email")}
                                  name="email"
                                  readOnly={
                                    disabledFields && !!disabledFields.email
                                  }
                                  disabled
                                  required
                                />
                              </div>
                              <div className="col-12 col-sm-6 col-md-6">
                                {" "}
                                <Field
                                  component={InputField}
                                  label={translate("cart.wizard.phone-number")}
                                  name="phone"
                                  required
                                />
                              </div>
                            </div>
                            {values.contact_type ===
                              CONTACT_DETAILS_TYPE_COMPANY && (
                                <div className="row">
                                  <div className="col-12 col-sm-6 col-md-6">
                                    <Field
                                      component={InputField}
                                      label={translate("cart.wizard.company")}
                                      name="company"
                                      required
                                      validate={shouldValidateMacedonian ? (value) => {
                                        if (!value) {
                                          return translate("validation.enter-tax-number");
                                        }
                                        if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                          return translate("domain.domain-contacts.must-be-cyrillic");
                                        }
                                      } : undefined}
                                      parse={shouldValidateMacedonian && transliterateToCyrilic}
                                    />
                                  </div>
                                  <div className="col-12 col-sm-6 col-md-6">
                                    <Field
                                      component={InputField}
                                      label={translate("cart.wizard.tax-number")}
                                      name={showIdNumberField ? "no-tax_number" : "tax_number"}
                                      disabled={showIdNumberField}
                                      readOnly={showIdNumberField}
                                      as={InputFieldInput}
                                      required
                                      validate={value => validateTaxNumber(value, values, translate)}

                                    />
                                    <StyledLabel>
                                      <input
                                        type="checkbox"
                                        checked={showIdNumberField}
                                        onChange={handleTaxNumberCheckboxChange}
                                        style={{ cursor: 'pointer', marginRight: '4px' }}
                                      />
                                      {translate("pro-invoices.view.dont-have-tax-number")}
                                    </StyledLabel>
                                    {showIdNumberField && (

                                      <Field
                                        component={InputField}
                                        label={translate("pro-invoices.view.id-number")}
                                        name="tax_number"
                                        required
                                        validate={value => validateTaxNumber(value, values, translate)}
                                      />
                                    )}
                                  </div>
                                </div>
                              )}
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12">
                                {" "}
                                <Field
                                  component={InputField}
                                  label={translate("cart.wizard.address")}
                                  name="address1"
                                  required
                                  validate={shouldValidateMacedonian ? (value) => {
                                    if (!value) {
                                      return translate("validation.enter-tax-number");
                                    }
                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                      return translate("domain.domain-contacts.must-be-cyrillic");
                                    }
                                  } : undefined}
                                  parse={shouldValidateMacedonian && transliterateToCyrilic}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-6 col-sm-6 col-md-6">
                                {" "}
                                <Field
                                  component={InputField}
                                  label={translate("cart.wizard.city")}
                                  name="city"
                                  required
                                  validate={shouldValidateMacedonian ? (value) => {
                                    if (!value) {
                                      return translate("validation.enter-tax-number");
                                    }
                                    if (!/^[\u0400-\u04FF\s.,;'/:"\-\d]*$/.test(value)) {
                                      return translate("domain.domain-contacts.must-be-cyrillic");
                                    }
                                  } : undefined}
                                  parse={shouldValidateMacedonian && transliterateToCyrilic}
                                />
                              </div>
                              <div className="col-6 col-sm-6 col-md-6">
                                <Field
                                  component={InputField}
                                  label={translate("cart.wizard.zip-code")}
                                  name="zip"
                                  required
                                  validate={shouldValidateMacedonian ? (value) => {
                                    if (!value) {
                                      return translate("validation.enter-tax-number");
                                    }
                                  } : undefined}
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-12 col-sm-6 col-md-6">
                                <Field
                                  component={InputField}
                                  label={translate("cart.wizard.state-region")}
                                  name="state_region"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12">
                            <StyledTerms className="row">
                              {/*<div className="col-12 col-sm-12 col-md-12">*/}
                              {/*  <Field*/}
                              {/*    component={Checkbox}*/}
                              {/*    label={translate("cart.wizard.terms")}*/}
                              {/*    name="terms"*/}
                              {/*  />*/}
                              {/*</div>*/}
                              {/*<div className="col-12 col-sm-12 col-md-12">*/}
                              {/*  <Field*/}
                              {/*    component={Checkbox}*/}
                              {/*    label={translate("cart.wizard.valid-info")}*/}
                              {/*    name="valid_personal_data"*/}
                              {/*  />*/}
                              {/*</div>*/}
                              <div className="col-12 col-sm-12 col-md-12">
                                <GoogleReCaptcha
                                  onVerify={token => onSetReCaptchaToken(token)}
                                />
                              </div>
                            </StyledTerms>
                          </div>
                        </div>
                        <StyledFooter className="row">
                          <div className="col-12 col-sm-12 col-md-12 text-right">
                            <SecondaryButton style={{ marginRight: '15px' }} onClick={e => onGoBack()}>
                              {translate("cart.wizard.back-to-cart")}
                            </SecondaryButton>
                            <PrimaryButton
                              type="submit"
                              disabled={submitting || invalid}
                              submitting={submitting}
                            >
                              {translate("cart.wizard.save-details-and-continue")}
                            </PrimaryButton>
                          </div>
                          <div className="col-12 col-sm-12 col-md-12 text-right">
                            <StyledHelp>{translate("cart.wizard.contact-us")}</StyledHelp>
                          </div>

                        </StyledFooter>
                      </form>
                    );
                  }}
                />
              </StyledAccountDetails>
            </Container>
          </div>
        </div>
      )}
    </Translate>
  );
};
AccountDetails.defaultProps = {
  showContactNotice: false
};

export default AccountDetails;
