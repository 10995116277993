import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Translate } from "react-localize-redux";
import { isMobileOnly } from "react-device-detect";
import { Link } from "react-router-dom";

import Container from "../../../../components/Containers/Container";
import DetailsPanelItem from "../../../../components/DetailsPanel/DetailsPanelItem";
import getBadgeForStatus from "../../../../utils/getBadgeForStatus";

import attachmentIcon from "../../../../assets/images/attachment-icon.svg";

const StyledTicketDetails = styled.div`
  margin-bottom: 30px;
  margin-top: ${props => props.isMobile && "30px"};
`;

const StyledHeader = styled.div`
  padding-left: ${props => (props.isMobile ? "15px" : "30px")};
  padding-right: ${props => (props.isMobile ? "15px" : "30px")};
  padding-top: ${props => (props.isMobile ? "15px" : "20px")};
  padding-bottom: 15px;

  & .badge {
    margin-left: 0;
    margin-right: 10px;
    margin-top: 10px;
  }
`;

const StyledContent = styled.div`
  padding: 15px;
  border-top: 1px solid ${theme.neutral3};
`;

const StyledTicketDetailBlock = styled.div`
  display: ${props => (props.isMobile ? "block" : "inline-block")};
  padding-right: ${props => !props.isMobile && "15px"};
  border-right: ${props => !props.isMobile && `1px solid ${theme.neutral3}`};
  &:last-child {
    border-right: 0;
    margin-right: 0;
  }
`;

const StyledTitle = styled.span`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  display: block;
`;

const StyledLabel = styled.span`
  font-size: 14px;
  color: ${theme.neutral5};
  font-weight: 600;
  line-height: 1.43;
  display: inline-block;
  margin: 15px;
`;

const StyledValue = styled.span`
  display: inline-block;
  font-size: 14px;
  color: ${theme.neutralBase};
  line-height: 1.38;
  text-align: ${props => props.isMobile && "right"};
`;

const StyledAttachmentContainer = styled.div`
  display: inline-block;

  & img {
    margin-top: -4px;
    margin-left: -4px;
  }
`;

const TicketDetails = ({ ticket, showAttachmentModal }) => {
  const BadgeComponent = getBadgeForStatus(ticket.status.toLowerCase());
  const PriorityBadgeComponent = getBadgeForStatus(
    ticket.priority.toLowerCase()
  );
  return (
    <Translate>
      {({ translate }) => (
        <StyledTicketDetails className="ticket-details" isMobile={isMobileOnly}>
          <Container>
            <StyledHeader isMobile={isMobileOnly}>
              <StyledTitle>
                #{ticket.id} - {ticket.subject}
              </StyledTitle>
              <BadgeComponent>
                {translate(
                  "help-desk.ticket.statuses." + ticket.status
                ).toLowerCase()}
              </BadgeComponent>
              <PriorityBadgeComponent>
                {translate(
                  "help-desk.ticket.priorities." + ticket.priority
                ).toLowerCase()}{" "}
                {translate("help-desk.ticket.priority").toLowerCase()}
              </PriorityBadgeComponent>
            </StyledHeader>
            <StyledContent>
              <StyledTicketDetailBlock isMobile={isMobileOnly}>
                <StyledLabel>
                  {translate("help-desk.ticket.department")}:
                </StyledLabel>
                <StyledValue isMobile={isMobileOnly}>
                  {ticket.department}
                </StyledValue>
              </StyledTicketDetailBlock>
              {ticket.related_product && ticket.related_product.name && (
                <StyledTicketDetailBlock isMobile={isMobileOnly}>
                  <StyledLabel>
                    {translate("help-desk.ticket.product")}:
                  </StyledLabel>
                  <StyledValue isMobile={isMobileOnly}>
                    {ticket.related_product.name}
                  </StyledValue>
                </StyledTicketDetailBlock>
              )}
              <StyledTicketDetailBlock isMobile={isMobileOnly}>
                <StyledLabel>
                  {translate("help-desk.ticket.submitted")}:
                </StyledLabel>
                <StyledValue isMobile={isMobileOnly}>
                  {ticket.created_date}
                </StyledValue>
              </StyledTicketDetailBlock>
              <StyledTicketDetailBlock isMobile={isMobileOnly}>
                <StyledLabel>
                  {translate("help-desk.ticket.last-updated")}:
                </StyledLabel>
                <StyledValue isMobile={isMobileOnly}>
                  {ticket.updated_date}
                </StyledValue>
              </StyledTicketDetailBlock>
            </StyledContent>
            {ticket.has_attachments && (
              <StyledContent>
                <StyledLabel>
                  {translate("help-desk.ticket.attachments")}:
                </StyledLabel>
                {ticket.replies.map(reply =>
                  reply.attachments.map((attachment, index) => (
                    <StyledAttachmentContainer key={index}>
                      <img src={attachmentIcon} alt="" />
                      <Link
                        key={`${attachment.name}-${index}`}
                        onClick={e => {
                          e.preventDefault();
                          showAttachmentModal(attachment.link);
                        }}
                        to=""
                      >
                        {attachment.name}
                      </Link>
                    </StyledAttachmentContainer>
                  ))
                )}
              </StyledContent>
            )}
          </Container>
        </StyledTicketDetails>
      )}
    </Translate>
  );
};
export default TicketDetails;
