import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import Panel from "../Panel";
import InputField from "../InputField";
import PrimaryButton from "../Buttons/PrimaryButton";
import PrimarySuccessButton from "../Buttons/PrimarySuccessButton";
import { SECURITY_CODES_GET, SECURITY_CODES_GENERATE } from "../../services/endpoints";
import ContentLoader from "../Loaders/ContentLoader";

const SecurityCodes = () => {
  const [securityCodes, setSecurityCodes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isCodesVisible, setIsCodesVisible] = useState(false); // New state to control visibility

  const fetchSecurityCodes = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(SECURITY_CODES_GET);
      setSecurityCodes(response.data.data.backup_codes);
    } catch (error) {
      console.error("Failed to fetch security codes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateNewCodes = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(SECURITY_CODES_GENERATE);
      setSecurityCodes(response.data.data.backup_codes);
    } catch (error) {
      console.error("Failed to generate new security codes:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCopyCodes = () => {
    const codesText = securityCodes.map(codeObj => codeObj.code).join("\n");
    navigator.clipboard.writeText(codesText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); 
      })
      .catch(err => console.error("Failed to copy security codes:", err));
  };

  useEffect(() => {
    if (isCodesVisible) {
      fetchSecurityCodes();
    }
  }, [isCodesVisible]); // Fetch codes only when the section is visible

  return (
    <Translate>
      {({ translate }) => (
        <Panel
          title={translate("my-account.security.security-codes-title")}
          description={translate("my-account.security.security-codes-description")}
        >
          {!isCodesVisible ? (
            <PrimaryButton onClick={() => setIsCodesVisible(true)}>
              {translate("my-account.security.show-security-codes")}
            </PrimaryButton>
          ) : (
            <>
              <div>
                {isLoading ? (
                  <ContentLoader />
                ) : (
                  securityCodes.map((codeObj, index) => (
                    <InputField
                      key={index}
                      name={`securityCode${index}`}
                      value={codeObj.code}
                      disabled
                    />
                  ))
                )}
              </div>

              <FlexDiv className="text-right mt-2">
                {securityCodes.length > 0 && (
                  isCopied ? (
                    <PrimarySuccessButton>
                      {translate("my-account.security.copied")}
                    </PrimarySuccessButton>
                  ) : (
                    <PrimaryButton
                      onClick={handleCopyCodes}
                      disabled={isLoading}
                    >
                      {translate("my-account.security.copy-codes")}
                    </PrimaryButton>
                  )
                )}
                <PrimaryButton
                  onClick={handleGenerateNewCodes}
                  disabled={isLoading}
                >
                  {translate("my-account.security.generate-new-security-codes")}
                </PrimaryButton>
              </FlexDiv>
            </>
          )}
        </Panel>
      )}
    </Translate>
  );
};

export default SecurityCodes;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between; 
  align-items: center;
`;
