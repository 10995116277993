import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Link } from "react-router-dom";

import withSizes from "react-sizes";
import isLaptop from "../../utils/isLaptopScreen";
import { isMobile, isBrowser } from "react-device-detect";
import { Translate } from "react-localize-redux";

import Container from "../../components/Containers/Container";
import Label from "../../components/Label";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import FormGroup from "../../components/FormGroup";

const StyledHostingPlansInfoWidget = styled.div`
  padding: 30px;
  & label {
    font-size: 16px;
    line-height: 22px;
    color: ${theme.neutralBase};
  }

  & p {
    font-weight: 400;
    font-size: 14px;
    color: ${theme.neutral6};
  }

  @media screen and (min-width: 1440px) and (max-width: 1600px) {
    .action-button {
      width: 100% !important;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .widget-info {
      width: 90% !important;
      flex: 0 0 90%;
      max-width: 90%;
    }
  }
`;

const HostingPlansInfoWidget = props => {
  let colSizeInfo = "col-12 col-sm-12 col-md-12";
  let colSizeButton = "col-12 col-sm-12 col-md-12 text-right";

  if (!isMobile && isBrowser && props.isLaptop) {
    colSizeInfo = "col-12 col-sm-12 col-md-12";
    colSizeButton = "col-12 col-sm-12 col-md-12";
  } else if (!isMobile && isBrowser && !props.isLaptop) {
    colSizeInfo = "col-12 col-sm-12 col-md-8";
    colSizeButton = "col-12 col-sm-12 col-md-4";
  }
  return (
    <Translate>
      {({ translate }) => (
        <Container className="widget-container">
          <StyledHostingPlansInfoWidget className="widget domain-search-widget">
            <div className="row align-items-center">
              <div className={`${colSizeInfo} widget-info`}>
                <Label>
                  {translate("home.widgets.additional-hosting.label")}
                </Label>
                <p>
                  {translate("home.widgets.additional-hosting.description")}
                </p>
              </div>
              <div className={`${colSizeButton} action-button`}>
                <FormGroup className="align-content-center">
                  <Link to={props.shopHostingRoute()}>
                    <PrimaryButton size="m">
                      {translate("home.widgets.additional-hosting.button")}
                    </PrimaryButton>
                  </Link>
                </FormGroup>
              </div>
            </div>
          </StyledHostingPlansInfoWidget>
        </Container>
      )}
    </Translate>
  );
};

export default withSizes(isLaptop)(HostingPlansInfoWidget);
