import React from "react";
import {
    compileRoute, ROUTE_BILLING_VIEW_PROINVOICE, ROUTE_HOSTING,
    ROUTE_INDEX,
    ROUTE_VIEW_HOSTING_UPGRADE
} from "../../../../routes/routes";

import UpgradeDowngradeHosting from "../../UpgradeDowngradeHosting";
import Breadcrumb from "../../../../components/Breadcrumb";
import BreadcrumbItem from "../../../../components/Breadcrumb/BreadcrumbItem";
import {Translate, withLocalize} from "react-localize-redux";
import {bindActionCreators} from "redux";
import {
    fetchCustomerProductDetails
} from "../../../Customer/actions/customerActions";
import {
    fetchProductUpgrades,
    upgradeProduct
} from "../../../Products/actions/productsActions";
import {connect} from "react-redux";
import displayToastMessageForResponse from "../../../../utils/displayToastMessageForResponse";
import {withRouter} from "react-router-dom";
import withLoading from "../../../../components/Loaders/WithLoading";
import ContentLoader from "../../../../components/Loaders/ContentLoader";

const UpgradeDowngradeHostingWithLoading = withLoading(
    UpgradeDowngradeHosting,
    ContentLoader
);

class UpgradeHosting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            currentHosting: null,
            productUpgrades: null,
            currentPlan: null
        }
    }

    onUpgradeProduct = upgradeProduct => {
        this.setState({
            isLoading: true
        });
        this.props
            .upgradeProduct(this.state.currentPlan, upgradeProduct)
            .then(result => {
                const {error, messages, invoice} = result;

                displayToastMessageForResponse(this.props.translate("hosting.title"), {
                    error,
                    messages
                });
                if (!error) {
                    this.props.history.push(
                        compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)({id: invoice.id})
                    );
                }
            })
            .catch(err => {
                this.setState({
                    isLoading: false
                });
                displayToastMessageForResponse(
                    this.props.translate("hosting.title"),
                    err
                );
            });
    };

    componentDidMount() {
        this.doFetchHosting(this.props.match.params.id, parseInt(this.props.match.params.hosting_id));
    }


    doFetchHosting = (id, suggested) => {
        this.setState({currentHosting: null, unexpectedError: null});
        this.props.fetchCustomerProductDetails(id).then(response => {
            this.setState({currentHosting: response.hosting});

            this.props.fetchProductUpgrades(response.hosting).then(result => {
                const {hostings} = result.data.data;
                const currentPlan = hostings.find(
                    plan => plan.id === response.hosting.catalog_id
                );
                hostings.map((hs) => {
                    if(hs.id === suggested){
                        hs.isSuggested = true;
                    }
                })
                this.setState({
                    productUpgrades: hostings,
                    currentPlan: {
                        ...currentPlan,
                        ...response.hosting
                    },
                    isLoading: false
                });
            });

        });
    };


    render() {
        return <Translate>
            {({translate}) => (
                <divs class={'row'}>
                    <div className="col-12 col-sm-12 col-md-12">
                        <div className="row">
                            <Breadcrumb>
                                <BreadcrumbItem
                                    to={ROUTE_INDEX}
                                    title={translate("breadcrumbs.home")}
                                />
                                <BreadcrumbItem
                                    to={ROUTE_HOSTING}
                                    title={translate("breadcrumbs.hosting")}
                                />
                                <BreadcrumbItem
                                    to={ROUTE_VIEW_HOSTING_UPGRADE}
                                    title={translate("breadcrumbs.upgrade")}
                                />

                            </Breadcrumb>
                        </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-12">
                        <div className={'container'}>
                            <div className={'row'}>
                                <div className={'col-12'}>
                                    <UpgradeDowngradeHostingWithLoading
                                        isLoading={this.state.isLoading}
                                        currentPlan={this.state.currentPlan}
                                        productUpgrades={this.state.productUpgrades}
                                        onUpgradeProduct={this.onUpgradeProduct}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </divs>
            )}
        </Translate>

    }
}


const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchCustomerProductDetails,
            fetchProductUpgrades,
            upgradeProduct,
        },
        dispatch
    );

export default connect(
    null,
    mapDispatchToProps
)(withRouter(withLocalize(UpgradeHosting)));

