import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Notification from "../Notification";

const StyledNotificationSuccess = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${theme.blue1};
  }

  & .icon {
    color: ${theme.greenBase};
  }
`;

const NotificationSuccess = ({ children, ...rest }) => (
  <StyledNotificationSuccess className="notice-success">
    <Notification icon="paid" {...rest}>
      {children}
    </Notification>
  </StyledNotificationSuccess>
);

NotificationSuccess.defaultProps = {
  className: "notification-success"
};

export default NotificationSuccess;
