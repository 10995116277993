import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Link } from "react-router-dom";

import Badge from "../../Badges/Badge";

const StyledKnowledgeBaseArticlesCategoryContent = styled.div`
  padding: 15px 25px 0px 25px;

  background-color: ${props => props.selected && theme.blue1};

  border-bottom: 1px solid ${theme.neutral3};
  & .icon-wrapper .icon {
    top: 20px;
  }

  cursor: pointer;

  & a:hover {
    text-decoration: none;
  }
`;

const StyledTitle = styled.p`
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${theme.neutralBase};

  & .articles-count {
    float: right;
  }

  & .articles-count-selected {
    background-color: ${theme.blueBase};
    color: ${theme.white};
  }
`;

const StyledDescription = styled.p`
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
`;

const KnowledgeBaseArticlesCategoryPanel = ({ ...props }) => (
  <StyledKnowledgeBaseArticlesCategoryContent selected={props.selected}>
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12">
        <Link to={props.knowledgeCategoryPath({ id: props.category.id })}>
          <StyledTitle>
            {props.category.name}{" "}
            <Badge
              className={`articles-count ${
                props.selected ? "articles-count-selected" : ""
              }`}
            >
              {props.category.number_of_articles} Articles
            </Badge>
          </StyledTitle>
          <StyledDescription>{props.category.description}</StyledDescription>
        </Link>
      </div>
    </div>
  </StyledKnowledgeBaseArticlesCategoryContent>
);

export default KnowledgeBaseArticlesCategoryPanel;
