import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Button from "../Button";

const GhostButton = styled(Button)`
  background-color: transparent;
  color: ${theme.blueBase}
  border: solid 1px transparent;

    &:hover {
      background-color: transparent;
      color: ${theme.blue5};
    }

    &:focus {
      color: ${theme.blueBase};
      box-shadow: 0 0 0 2px ${theme.blue3};
    }

    &:active {
      color: ${theme.blue5}
      outline: none;
    }

    &:disabled {
      color: ${theme.neutral2}
      border: solid 1px transparent;
    }
`;

GhostButton.defaultProps = {
  className: "btn ghost-button"
};

export default GhostButton;
