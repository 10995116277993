import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { Form, Field } from "react-final-form";

import Panel from "../Panel";
import FormGroup from "../FormGroup";
import InputField from "../InputField";
import SelectBox from "../SelectBox";
import PrimaryButton from "../Buttons/PrimaryButton";
import withLoading from "../../components/Loaders/WithLoading";
import SecurityQuestionLoader from "../../components/Loaders/SecurityQuestionLoader";

const StyledSecurityQuestionPanel = styled.div`
  margin-bottom: 30px;
`;

const SecurityQuestion = ({
  securityQuestionOptions,
  isFetchingSecurityQuestions,
  validateSecurityQuestionSubmit,
  onSaveSecurityQuestion,
  isUpdatingSecurityQuestion
}) => {
  const SecurityQuestionsPanelWithLoading = withLoading(
    StyledSecurityQuestionPanel,
    SecurityQuestionLoader
  );

  return (
    <Translate>
      {({ translate }) => (
        <Panel
          title={translate("my-account.security.security-question")}
          description={translate(
            "my-account.security.security-question-description-text"
          )}
        >
          <Form
            onSubmit={onSaveSecurityQuestion}
            validate={validateSecurityQuestionSubmit}
            render={({ handleSubmit, submitting, invalid, form }) => (
              <form
                id="update-security-question-form"
                onSubmit={event => {
                  handleSubmit(event);
                  form.reset();
                }}
              >
                <SecurityQuestionsPanelWithLoading
                  isLoading={isFetchingSecurityQuestions}
                >
                  <Field
                    name="question"
                    label={translate("filters.security-question")}
                    placeholder={translate(
                      "filters.security-question-placeholder"
                    )}
                    async={true}
                    loadOptions={securityQuestionOptions}
                    defaultOptions
                    isSearchable={false}
                    component={SelectBox}
                  />

                  <Field
                    name="answer"
                    label={translate("my-account.security.answer")}
                    placeholder={translate("my-account.security.enter-answer")}
                    component={InputField}
                  />

                  <Field
                    name="confirmAnswer"
                    label={translate("my-account.security.confirm-answer")}
                    placeholder={translate(
                      "my-account.security.confirm-answer-placeholder"
                    )}
                    component={InputField}
                  />

                  <FormGroup className="text-right">
                    <PrimaryButton
                      disabled={
                        submitting || invalid || isUpdatingSecurityQuestion
                      }
                      submitting={submitting || isUpdatingSecurityQuestion}
                      type="submit"
                      key="update-security-question"
                    >
                      {translate("my-account.security.save-changes")}
                    </PrimaryButton>
                  </FormGroup>
                </SecurityQuestionsPanelWithLoading>
              </form>
            )}
          />
        </Panel>
      )}
    </Translate>
  );
};

export default SecurityQuestion;
