import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Badge from "../Badge";

const BadgeWarning = styled(Badge)`
  background-color: ${theme.yellow1}
  color: ${theme.yellow2};
`;

export default BadgeWarning;
