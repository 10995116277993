import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "../../../design/Themes";
import { Translate } from "react-localize-redux";

import Container from "../../../components/Containers/Container";
import Form from "../../../components/Forms/Form";
import SearchField from "../../../components/SearchField";

import withLoading from "../../../components/Loaders/WithLoading";
import KnowledgeBaseCategoriesLoader from "../../../components/Loaders/KnowledgeBaseCategoriesLoader";

import KnowledgeBaseArticlesPanel from "../../../components/KnowledgeBase/KnowledgeBaseArticlesPanel";
import KnowledgeBaseArticle from "../../../components/KnowledgeBase/KnowledgeBaseArticle";

import MostPopularArticles from "../../../components/KnowledgeBase/MostPopularArticles";
import KnowledgeBaseArticlesSearchByKeywordResultsContainer from "../../../components/KnowledgeBase/KnowledgeBaseArticlesSearchByKeywordResultsContainer";

const StyledKnowledgeBase = styled.div`
  padding-bottom: 25px;

  & .tickets-list-header {
    padding: 30px 25px 25px 25px;
    border-bottom: 1px solid ${theme.neutral3};
  }

  & .tickets-list-search {
    padding: 25px 25px 10px 25px;
  }

  & .tickets-list-header button.open-new-ticket {
    margin-right: 0;
  }

  & .tickets-list-header .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    color: ${theme.neutral5};
    text-transform: uppercase;
  }

  & .tickets-list-header span.knowledge-base-view-all-articles {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blueBase};
    line-height: 1.43;
    letter-spacing: 0.2px;
    cursor: pointer;
  }
`;

const StyledKnowledgeBaseContainer = styled.div`
  & a.tickets-type {
    font-size: 16px;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }
`;

const KnowledgeBasePanel = props => {
  const StyledKnowledgeBaseContainerCategoriesWithLoading = withLoading(
    StyledKnowledgeBaseContainer,
    KnowledgeBaseCategoriesLoader
  );

  const StyledKnowledgeBaseContainerMostPopularArticlesWithLoading = withLoading(
    StyledKnowledgeBaseContainer,
    KnowledgeBaseCategoriesLoader
  );

  return (
    <Translate>
      {({ translate }) => (
        <Container>
          <StyledKnowledgeBase>
            <div className="tickets-list-header">
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6">
                  <span className="title">
                    {translate("help-desk.knowledge-base.title")}
                  </span>
                </div>
                <div className="col-6 col-sm-6 col-md-6 text-right">
                  <Link to={props.knowledgeBasePath()}>
                    <span className="knowledge-base-view-all-articles">
                      {translate("help-desk.knowledge-base.view-all-articles")}
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <div className="tickets-list-search">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <SearchField
                    onChange={props.onChangeKeywordSearch}
                    name="search"
                    placeholder={translate(
                      "help-desk.knowledge-base.search-placeholder"
                    )}
                  />
                </div>
              </div>
              <KnowledgeBaseArticlesSearchByKeywordResultsContainer
                searchQuery={props.searchQuery}
                isFetchingKnowledgeBaseArticleByKeyword={
                  props.isFetchingKnowledgeBaseArticleByKeyword
                }
                searchedArticles={props.searchedArticles}
                knowledgeArticlePath={props.knowledgeArticlePath}
              />
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <StyledKnowledgeBaseContainerCategoriesWithLoading
                  isLoading={props.isLoadingKnowledgeBaseCategories}
                >
                  {props.knowledgeBaseCategories.map(category => {
                    return (
                      <KnowledgeBaseArticlesPanel
                        key={`${`helpdesk-knowledge-base-category-${
                          category.id
                        }`}`}
                        title={category.name}
                        description={category.description}
                        numberOfArticles={category.number_of_articles}
                      >
                        {category.articles.map(article => {
                          return (
                            <KnowledgeBaseArticle
                              key={`${`helpdesk-knowledge-base-article-${
                                article.id
                              }`}`}
                              article={article}
                              title={article.title}
                              votes={article.votes}
                              categoryName={article.category_name}
                              articleLink={props.articleLink({
                                id: article.id
                              })}
                            />
                          );
                        })}
                      </KnowledgeBaseArticlesPanel>
                    );
                  })}
                </StyledKnowledgeBaseContainerCategoriesWithLoading>

                <StyledKnowledgeBaseContainerMostPopularArticlesWithLoading
                  isLoading={props.isLoadingKnowledgeBaseMostPopularArticles}
                >
                  <MostPopularArticles>
                    {props.knowledgeBaseMostPopularArticles.map(article => {
                      return (
                        <KnowledgeBaseArticle
                          key={`${`helpdesk-knowledge-most-populararticle-${
                            article.id
                          }`}`}
                          article={article}
                          title={article.title}
                          votes={article.votes}
                          categoryName={article.category_name}
                          articleLink={props.articleLink({
                            id: article.id
                          })}
                        />
                      );
                    })}
                  </MostPopularArticles>
                </StyledKnowledgeBaseContainerMostPopularArticlesWithLoading>
              </div>
            </div>
          </StyledKnowledgeBase>
        </Container>
      )}
    </Translate>
  );
};

export default KnowledgeBasePanel;
