import React from "react";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";
import { theme } from "../../../design/Themes";

import ImageEmpty from "../../../assets/images/icon-illustration-empty.png";

const StyledNoNotifications = styled.div`
  padding: 50px;
  text-align: center;
`;

const StyledImage = styled.img`
  width: 70px;
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 600;
  color: ${theme.neutralBase};
  text-align: center;
`;
const StyledDescription = styled.span`
  display: block;
  font-size: 16px;
  color: ${theme.neutral6};
  text-align: center;
`;

const NoNotifications = props => (
  <StyledNoNotifications>
    <StyledImage src={ImageEmpty} className="img-fluid" alt="" />
    <StyledTitle>
      {props.translate("notifications.no-notifications-title")}
    </StyledTitle>
    <StyledDescription>
      {props.translate("notifications.no-notifications-description")}
    </StyledDescription>
  </StyledNoNotifications>
);

export default withLocalize(NoNotifications);
