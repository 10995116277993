import * as CA from "../actions/cartActions";

import handleReducerActions from "../../../core/handleReducerActions";

import { APP_STATE_COMPLETE_RESET } from "../../AppState/actions/appStateActions";

import {
  PRODUCT_DOMAIN,
  PRODUCT_PRODUCT,
  PRODUCT_DEDICATED_SERVER,
  PRODUCT_CLOUD_SERVER
} from "../Products/ProductTypes";
import * as referralService from "../../../services/referralService";

const initialState = {
  id: null,
  adjustments_total: 0,
  total_discount: 0,
  promotion_code: "",
  referral_code: "",
  total: 0,

  items: [],
  isStoringItemInCart: false,
  isRequestingCart: false,
  isApplyingPromotion: false,
  isCleaningPromotion: false,
  isApplyingReferrer: false,
  isCleaningReferrer: false,
  checkoutBlockingProducts: []
};

const cartStateHandlers = {
  [CA.CART_REQUEST]: cartRequest,
  [CA.CART_RECEIVE]: cartReceive,
  [CA.CART_ADD_PRODUCT]: cartAddProduct,
  [CA.CART_ADD_PRODUCT_REQUEST]: cartAddProductRequest,
  [CA.CART_ADD_PRODUCT_RECEIVE]: cartAddProductReceive,
  [CA.CART_REMOVE_PRODUCT]: cartRemoveProduct,
  [CA.CART_ASSIGN_DOMAIN_TO_PRODUCT]: cartAssignDomainToProduct,
  [CA.CART_ATTACH_SERVICE_TO_PRODUCT]: cartAttachServiceToProduct,
  [CA.CART_REMOVE_SERVICE_FROM_PRODUCT]: cartRemoveServiceFromProduct,
  [CA.CART_CHANGE_PRODUCT_CONTACT]: cartChangeProductContact,
  [CA.CART_CHANGE_DOMAIN_EPP_CODE]: cartChangeDomainEPPCode,
  [CA.CART_UPDATE_PRODUCT]: cartUpdatePeriod,
  [CA.CART_CLEAR]: cartClear,
  [CA.CART_APPLY_PROMOTION_REQUEST]: cartApplyPromotionRequest,
  [CA.CART_APPLY_PROMOTION_RECEIVE]: cartApplyPromotionReceive,
  [CA.CART_CLEAR_PROMOTION_REQUEST]: cartClearPromotionRequest,
  [CA.CART_CLEAR_PROMOTION_RECEIVE]: cartClearPromotionReceive,
  [CA.CART_APPLY_REFERRER_REQUEST]: cartApplyReferrerRequest,
  [CA.CART_APPLY_REFERRER_RECEIVE]: cartApplyReferrerReceive,
  [CA.CART_CLEAR_REFERRER_REQUEST]: cartClearReferrerRequest,
  [CA.CART_CLEAR_REFERRER_RECEIVE]: cartClearReferrerReceive,
  [CA.CART_BLOCK_CHECKOUT_BY_PRODUCT]: cartBlockCheckoutByProduct,
  [CA.CART_UNBLOCK_CHECKOUT_BY_PRODUCT]: cartUnblockCheckoutByProduct,
  [APP_STATE_COMPLETE_RESET]: resetState
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(cartStateHandlers, state, action);
}

function cartRequest(state, action) {
  return {
    ...state,
    referral_code: "",
    isRequestingCart: true
  };
}

function cartReceive(state, action) {
  const cart = action.payload;
  return {
    ...state,
    id: cart.id,
    adjustments_total: cart.adjustments_total,
    total: cart.total,
    total_discount: cart.total_discount,
    promotion_code: cart.promotion_code,
    referral_code : cart.referral_code || referralService._getReferrerCode(),
    items: cart.items.map(item => {
      if (item.type === PRODUCT_DOMAIN) {
        return {
          ...item,
          assigned: false,
          isMkDomain:
            item.domain.endsWith(".mk") || item.domain.endsWith(".мкд")
        };
      }
      return item;
    }),
    isRequestingCart: false
  };
}

function cartAddProduct(state, action) {
  const product = action.payload;
  return {
    ...state,
    items: state.cart.items.concat(product)
  };
}

function cartAddProductRequest(state, action) {
  return {
    ...state,
    isStoringItemInCart: true
  };
}

function cartAddProductReceive(state, action) {
  return {
    ...state,
    isStoringItemInCart: false
  };
}

function cartApplyPromotionRequest(state, action) {
  return {
    ...state,
    isApplyingPromotion: true,
    promotion_code: action.payload
  };
}

function cartApplyPromotionReceive(state, action) {
  return {
    ...state,
    isApplyingPromotion: false
  };
}

function cartClearPromotionRequest(state, action) {
  return {
    ...state,
    isCleaningPromotion: true
  };
}

function cartClearPromotionReceive(state, action) {
  return {
    ...state,
    isCleaningPromotion: false,
    promotion_code: ""
  };
}


function cartApplyReferrerRequest(state, action) {
  referralService._setReferrerCode(action.payload)
  return {
    ...state,
    isApplyingReferrer: true,
    referral_code: action.payload
  };
}

function cartApplyReferrerReceive(state, action) {
  return {
    ...state,
    isApplyingReferrer: false
  };
}

function cartClearReferrerRequest(state, action) {
  return {
    ...state,
    isCleaningReferrer: true
  };
}

function cartClearReferrerReceive(state, action) {
  referralService._removeReferrerCode()
  return {
    ...state,
    isCleaningReferrer: false,
    referral_code: ""
  };
}

function cartRemoveProduct(state, action) {
  const product = action.payload;
  return {
    ...state,
    items: state.cart.items.filter(current => current.id !== product.id)
  };
}

function cartAssignDomainToProduct(state, action) {
  const { domain } = action.payload;
  return {
    ...state,
    items: state.items.map(item => {
      if (item.type !== PRODUCT_DOMAIN) {
        return item;
      }

      if (item.type === PRODUCT_DOMAIN) {
        if (item.domain === domain) {
          return {
            ...item,
            assigned: true
          };
        }
        return item;
      }

      return item;
    })
  };
}

function cartAttachServiceToProduct(state, action) {
  const { service, product, detachId, updating } = action.payload;

  return {
    ...state,
    total: detachId
      ? service.pricing.buy.billing_cycle.derivations[0].price.value +
        state.total
      : state.total,
    items: state.items.map(currentProduct => {
      if (currentProduct.id !== product.id) {
        return currentProduct;
      }

      return {
        ...currentProduct,
        item: {
          ...currentProduct.item,
          services: currentProduct.item.services.map(currenctService => {
            if (currenctService.id !== service.id) {
              return currenctService;
            }

            return {
              ...currenctService,
              detach_id: detachId,
              activated: !updating && detachId ? true : false,
              updating: updating
            };
          })
        }
      };
    })
  };
}

function cartRemoveServiceFromProduct(state, action) {
  const { service, product, success, updating } = action.payload;

  return {
    ...state,
    total: success
      ? state.total -
        service.pricing.buy.billing_cycle.derivations[0].price.value
      : state.total,
    items: state.items.map(currentProduct => {
      if (currentProduct.id !== product.id) {
        return currentProduct;
      }

      return {
        ...currentProduct,
        item: {
          ...currentProduct.item,
          services: currentProduct.item.services.map(currenctService => {
            if (currenctService.id !== service.id) {
              return currenctService;
            }

            return {
              ...currenctService,
              detach_id: false,
              activated: !updating && success ? false : true,
              updating: updating
            };
          })
        }
      };
    })
  };
}

function cartChangeProductContact(state, action) {
  const { product, contact } = action.payload;

  return {
    ...state,
    items: state.items.map(currentProduct => {
      if (currentProduct.id !== product.id) {
        return currentProduct;
      }

      const registerContacts =
        currentProduct.item.register_contacts &&
        currentProduct.item.register_contacts.length > 0
          ? currentProduct.register_contacts
          : [contact];

      return {
        ...currentProduct,
        item: {
          ...currentProduct.item,
          register_contacts: registerContacts.map(currentContact => {
            if (currentContact.type !== contact.type) {
              return currentContact;
            }
            return {
              ...currentContact,
              ...contact
            };
          })
        }
      };
    })
  };
}

function cartChangeDomainEPPCode(state, action) {
  const { domain, code } = action.payload;

  if (!domain || !code) return { ...state };

  return {
    ...state,
    items: state.items.map(item => {
      if (item.id !== domain.id) return { ...item };

      return {
        ...item,
        ...domain,
        config: {
          ...domain.config,
          eppcode: code
        }
      };
    })
  };
}

function cartUpdatePeriod(state, action) {
  const { product, period } = action.payload;

  let firstYearPrice = null;
  let selectedYearPrice = null;
  let years = null;
  let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];

  if (product.type !== PRODUCT_DOMAIN) {
    // firstYearPrice = product.item.pricing.buy.billing_cycle.derivations.find(
    //   d => d.numeric_code === 1
    // );

    // Temporary solution, should be sync with api derivation nnumeric codes
    possiblePeriodNumericCodes.some(numericCode => {
      firstYearPrice = product.item.pricing.buy.billing_cycle.derivations.find(
        d => d.numeric_code === numericCode
      );

      if (typeof(firstYearPrice) !== 'undefined') {
        console.log(numericCode);
        return true;
      }
    });
    // END

    selectedYearPrice = product.item.pricing.buy.billing_cycle.derivations.find(
      d => d.numeric_code === period
    );
    years = period;
  }

  return {
    ...state,
    items: state.items.map(item => {
      if (item.id !== product.id) return { ...item };

      let fullPrice = null;
      let finalPrice = null;

      if (product.type !== PRODUCT_DOMAIN) {
        fullPrice = firstYearPrice.price.value * years;
        finalPrice =
          years === 1
            ? firstYearPrice.price.value
            : fullPrice - (fullPrice - selectedYearPrice.price.value);
      }

      return {
        ...item,
        ...product,
        total: product.type !== PRODUCT_DOMAIN ? finalPrice : product.total,
        config: {
          ...item.config,
          period
        }
      };
    })
  };
}

function cartClear(state) {
  return {
    ...initialState
  };
}

function resetState(state) {
  return { ...initialState };
}

function cartBlockCheckoutByProduct(state, action) {
  return {
    ...state,
    checkoutBlockingProducts: state.checkoutBlockingProducts.concat([
      action.payload
    ])
  };
}

function cartUnblockCheckoutByProduct(state, action) {
  return {
    ...state,
    checkoutBlockingProducts: state.checkoutBlockingProducts.filter(
      id => id !== action.payload
    )
  };
}

export function getCart(state) {
  return state.cart;
}

export function getCartId(state) {
  return state.cart.id;
}

export function getItems(state) {
  return state.cart.items;
}

export function getTotal(state) {
  return state.cart.total;
}

export function getItemsCount(state) {
  return state.cart.items.length > 0 ? state.cart.items.length : null;
}

export function getDomainsInCart(state) {
  return state.cart.items.filter(item => item.type === PRODUCT_DOMAIN);
}

export function isStoringItemInCart(state) {
  return state.cart.isStoringItemInCart;
}

export function isRequestingCart(state) {
  return state.cart.isRequestingCart;
}

export function isApplyingPromotion(state) {
  return state.cart.isApplyingPromotion;
}

export function isCleaningPromotion(state) {
  return state.cart.isCleaningPromotion;
}

export function getTotalDiscount(state) {
  return state.cart.total_discount;
}

export function getPromotionCode(state) {
  return state.cart.promotion_code;
}


export function isApplyingReferrer(state) {
  return state.cart.isApplyingReferrer;
}

export function isCleaningReferrer(state) {
  return state.cart.isCleaningReferrer;
}

export function getReferrerCode(state) {
  return state.cart.referral_code;
}

export function isCheckoutBlocked(state) {
  return state.cart.checkoutBlockingProducts.length > 0;
}

export function firstCheckoutBlockingProduct(state) {
  return (
    state.cart.checkoutBlockingProducts.length > 0 &&
    state.cart.checkoutBlockingProducts[0]
  );
}
