import React from "react";

import Loader from "../Loader";

const ProInvoicesWidgetLoader = () => (
  <Loader width="380" height="120">
    <rect x="10" y="10" width="360" height="20" rx="3" />
    <rect x="10" y="40" width="360" height="20" rx="3" />
    <rect x="10" y="70" width="360" height="20" rx="3" />
    <rect x="10" y="100" width="360" height="20" rx="3" />
  </Loader>
);

export default ProInvoicesWidgetLoader;
