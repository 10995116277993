import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";
import Container from "../../../components/Containers/Container";
import Filter from "../../../components/Filter";
import Form from "../../../components/Forms/Form";
import DateRangePicker from "../../../components/DateRangePicker";
import {fetchReferralInvoices} from "../actions/referralActions";


const StyledMyReferralInvoiceListContainer = styled.div`
  margin-bottom: ${props => props.isMobile && "15px"};
`;

class MyReferralInvoiceList extends React.Component {

    onClear = () => {
        this.props.fetchReferralInvoices(this.props.paging.limit, 1, this.props.sort);
    };

    dateCreatedRangeSelected = (startDate, endDate) => {
        this.props.fetchReferralInvoices(this.props.paging.limit, 1, this.props.sort, {
            ...this.props.filters,
            date_created_range:
                startDate.format("YYYY-MM-DD") + "." + endDate.format("YYYY-MM-DD")
        });
    };


    paidOnDateRangeSelected = (startDate, endDate) => {
        this.props.fetchReferralInvoices(this.props.paging.limit, 1, this.props.sort, {
            ...this.props.filters,
            date_paid_on_range:
                startDate.format("YYYY-MM-DD") + "." + endDate.format("YYYY-MM-DD")
        });
    };


    render() {
        const [
            startDateCreatedInitial,
            endDateCreatedInitial
        ] = this.props.filters.date_created_range.split(".");

        const [
            startPaidOnDateInitial,
            endPaidOnDateInitial
        ] = this.props.filters.date_paid_on_range.split(".");


        const count = this.props.filters.date_created_range.length && this.props.filters.date_paid_on_range.length ? 2 : this.props.filters.date_created_range.length || this.props.filters.date_paid_on_range.length ? 1 : 0;

        return (

            <StyledMyReferralInvoiceListContainer isMobile={isMobileOnly}>
                <Translate>
                    {({ translate }) => (

                        <Container>
                            <Filter
                                title={translate("pro-invoices.filter.title")}
                                clearTitle={translate("pro-invoices.filter.clear-all")}
                                count={count}
                                onClear={this.onClear}
                            >
                                <Form name={'testForm'}>

                                    <DateRangePicker
                                        name="date-created"
                                        label={translate("referral-admin.filter-create-date")}
                                        dateRangeSelected={this.dateCreatedRangeSelected}
                                        initialStartDate={startDateCreatedInitial}
                                        initialEndDate={endDateCreatedInitial}
                                        startDatePlaceholderText={translate("filters.start-date")}
                                        endDatePlaceholderText={translate("filters.end-date")}
                                    />


                                    <DateRangePicker
                                        name="paid-on-date"
                                        label={translate("referral-admin.filter-date-pay")}
                                        dateRangeSelected={this.paidOnDateRangeSelected}
                                        initialStartDate={startPaidOnDateInitial}
                                        initialEndDate={endPaidOnDateInitial}
                                        startDatePlaceholderText={translate("filters.start-date")}
                                        endDatePlaceholderText={translate("filters.end-date")}
                                    />
                                </Form>
                            </Filter>

                        </Container>


                        )}
                </Translate>
            </StyledMyReferralInvoiceListContainer>
        );
    }
}

MyReferralInvoiceList.defaultProps = {
    count: 0,
    onClear: () => {},
    statusCollection: []
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            fetchReferralInvoices,
        },
        dispatch
    );

export default connect(
    null,
    mapDispatchToProps
)(MyReferralInvoiceList);

