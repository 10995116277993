import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Translate } from "react-localize-redux";
import Icon from "../../components/Icon";

const StyledBlockRadiobox = styled.div`
  position: relative;
  border: 1px solid;
  border-color: ${props => (props.selected ? theme.blueBase : theme.neutral3)};
  background-color: ${props => (props.selected ? theme.blue1 : `inherit`)};

  border-radius: 3px;
  padding: 20px;
  padding-left: 40px;
  cursor: pointer;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 15px;
  }
`;

const StyledSelectedIcon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  color: ${theme.blueBase};
`;
const StyledCreditCardIcon = styled.img`
  position: absolute;
  left: 20px;
  top: 10px;
  width: 40px;
`;

const StyledMethodDetails = styled.div`
  padding-left: 60px;
`;

const StyledMethodTitle = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.3;
  color: ${props => (props.selected ? theme.blueBase : theme.neutralBase)};
  margin-bottom: 2px;
`;
const StyledMethodDescription = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
`;

const StyledHiddenRadiobox = styled.input`
  display: none;
`;

const BlockRadiobox = props => {
  return (
    <Translate>
      {({ translate }) => (
        <StyledBlockRadiobox
          selected={props.input.checked}
          onClick={e =>
            !props.readOnly &&
            !props.disabled &&
            props.input.onChange(props.input.value)
          }
        >
          {props.input.checked && (
            <StyledSelectedIcon>
              <Icon icon="success" />
            </StyledSelectedIcon>
          )}

          <StyledMethodDetails>
            <StyledCreditCardIcon
              className="img-fluid"
              src={props.input.checked ? props.activeIcon : props.defaultIcon}
            />
            <StyledMethodTitle>{props.title}</StyledMethodTitle>
            {props.children &&
              props.children.length > 0 && (
                <StyledMethodDescription>
                  {props.children}
                </StyledMethodDescription>
              )}
          </StyledMethodDetails>
          <StyledHiddenRadiobox
            onChange={props.input.onChange}
            checked={props.input.checked}
            type="radio"
            name={props.input.name}
            value={props.input.value}
          />
        </StyledBlockRadiobox>
      )}
    </Translate>
  );
};

BlockRadiobox.defaultProps = {
  selected: false
};

export default BlockRadiobox;
