import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Translate } from "react-localize-redux";
import { isMobileOnly, isTablet } from "react-device-detect";

import ProfileNavItem from "./ProfileNavItem";
import NotificationsNavItem from "./NotificationsNavItem";
import NavItem from "./NavItem";
import Icon from "../Icon";

import GlobalSearch from "../GlobalSearch";

import logo from "./logo.svg";
import logoWithoutName from "./logoWithoutName.svg";
import hamburger_menu from "./menu.svg";

import { ROUTE_CART, ROUTE_LOGIN, ROUTE_WEBSITE } from "../../routes/routes";

import { getItemsCount } from "../../features/Cart/reducers/cartReducer";

const StyledNavigation = styled.nav`
  background-color: ${theme.white};
  border-bottom: 1px solid ${theme.neutral3};
  padding: 0;
  height: 74px;
  max-height: 74px;
  max-width: 100%;
  width: 100%;
  & .form-inline {
    margin-left: 30px;
  }
  & .form-inline .form-group {
    position: relative;
  }

  & .form-inline .form-group .nav-search {
    min-width: 380px;
  }
`;

const StyledBrandImage = styled.img`
  width: ${props => {
    if (props.isMobile || props.isTablet) {
      return "30px";
    }
    return "150px";
  }};
`;

const StyledNavbarToggler = styled.button`
  display: ${props => {
    if (props.isMobile || props.isTablet) {
      return "block !important";
    }
    return "none";
  }}
    & .navbar-toggler-icon {
    background-image: url(${hamburger_menu});
  }
`;

const StyledNumber = styled.div`
  position: absolute;
  right: 4px;
  top: 12px;
  background-color: ${theme.redBase};
  color: ${theme.white};
  width: 5px;
  height: 5px;
  border-radius: 50%;
  font-size: 12px;
  text-align: center;
  padding-top: 3px;
  font-weight: 600;
  line-height: 1;
  letter-spacing: normal;
  width: 18px;
  height: 18px;
  border-radius: 12px;
`;

class Navigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      customerPersonalInformation: null,
    };
  }

  componentDidMount() {
    this.setState({
      customerPersonalInformation: this.props.customerPersonalInformation,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.customerPersonalInformation !== prevProps.customerPersonalInformation) {
      this.setState({
        customerPersonalInformation: this.props.customerPersonalInformation,
      });
    }
  }


  render() {
    return (
      <Translate>
        {({ translate, activeLanguage }) => (
          <StyledNavigation
            className={`navbar fixed-top ${isMobileOnly || isTablet ? "navbar-expand-sm" : "navbar-expand-md"
              }`}
          >
            <a
              className={`navbar-brand ${isMobileOnly || isTablet ? "ml-3 mr-2" : "mx-5"
                }`}
              href={
                !this.props.isAuthenticated && activeLanguage
                  ? `${ROUTE_WEBSITE}/${activeLanguage.code}`
                  : "/"
              }
            >
              <StyledBrandImage
                isMobile={isMobileOnly}
                isTablet={isTablet}
                src={isMobileOnly || isTablet ? logoWithoutName : logo}
              />
            </a>
            {this.props.isAuthenticated && (
              <StyledNavbarToggler
                onClick={() => this.props.onToggleMobileMenu()}
                isMobile={isMobileOnly}
                isTablet={isTablet}
                className="btn navbar-toggler mr-auto"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon" />
              </StyledNavbarToggler>
            )}
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              {this.props.isAuthenticated && <GlobalSearch />}
              <ul className="navbar-nav ml-auto">
                {this.props.isAuthenticated && <NotificationsNavItem />}
                {this.props.isAuthenticated &&
                  !this.props.isFetchingCustomerPersonalInformation &&
                  this.props.customerPersonalInformation && (
                    <ProfileNavItem
                      username={
                        this.props.customerPersonalInformation.first_name +
                        " " +
                        this.props.customerPersonalInformation.last_name
                      }
                      emailVerified={this.props.customerPersonalInformation.email_verified}
                      email={this.props.customerPersonalInformation.email}
                      onLogout={this.props.onLogout}
                      redirectToMyAccount={this.props.redirectToMyAccount}
                    />
                  )}
                {this.props.isAuthenticated && (
                  <NavItem to={ROUTE_CART}>
                    <Icon size="l" icon="cart" />
                    {this.props.cartItemsCount > 0 && (
                      <StyledNumber>{this.props.cartItemsCount}</StyledNumber>
                    )}
                  </NavItem>
                )}
                {!this.props.isAuthenticated && (
                  <NavItem to={ROUTE_LOGIN}>
                    {translate("navigation.login")}
                  </NavItem>
                )}
              </ul>
            </div>
            {isMobileOnly && (
              <ul className="navbar-nav ml-auto">
                {this.props.isAuthenticated &&
                  !this.props.isFetchingCustomerPersonalInformation && (
                    <ProfileNavItem
                      username={
                        this.props.customerPersonalInformation.first_name +
                        " " +
                        this.props.customerPersonalInformation.last_name
                      }
                      emailVerified={this.props.customerPersonalInformation.email_verified}
                      email={this.props.customerPersonalInformation.email}
                      onLogout={this.props.onLogout}
                      redirectToMyAccount={this.props.redirectToMyAccount}
                    />
                  )}
                {!this.props.isAuthenticated && (
                  <NavItem to={ROUTE_LOGIN}>
                    {translate("navigation.login")}
                  </NavItem>
                )}
              </ul>
            )}
          </StyledNavigation>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    cartItemsCount: getItemsCount(state)
  };
};

export default connect(
  mapStateToProps,
  null
)(Navigation);
