import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Translate } from "react-localize-redux";
import { withSizes } from "react-sizes";
import {
  MobileOnlyView,
  isTablet,
  isBrowser,
  isMobileOnly
} from "react-device-detect";
import isLaptop from "../../../utils/isLaptopScreen";

import Container from "../../../components/Containers/Container";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import Form from "../../../components/Forms/Form";
import SearchField from "../../../components/SearchField";
import { Link } from "react-router-dom";

import { ROUTE_HELPDESK_TICKETS } from "../../../routes/routes";

const StyledTicketsListWrapper = styled.div`
  margin-bottom: 30px;
`;
const StyledTicketsList = styled.div`
  padding-bottom: 25px;

  & .tickets-list-header {
    padding: 30px 25px 25px 25px;
    border-bottom: 1px solid ${theme.neutral3};
  }

  & .tickets-list-search {
    padding: 25px 25px 10px 25px;
    border-bottom: 1px solid ${theme.neutral3};
  }

  & .tickets-list-header button.open-new-ticket {
    margin-right: 0;
  }

  & .tickets-list-header .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    color: ${theme.neutral5};
    text-transform: uppercase;
  }
  & .tickets-list-header span.knowledge-base-view-all-tickets {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.blueBase};
    line-height: 1.43;
    letter-spacing: 0.2px;
    cursor: pointer;
    margin-right: 15px;
  }
`;

const StyledTicketsListContainer = styled.div`
  & a.tickets-type {
    font-size: 16px;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }
`;

const TicketsList = props => (
  <Translate>
    {({ translate }) => (
      <StyledTicketsListWrapper>
        <Container>
          <StyledTicketsList>
            <MobileOnlyView>
              <div className="tickets-list-header">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <PrimaryButton
                      onClick={() => props.onOpenNewTicket()}
                      className="open-new-ticket w-100"
                    >
                      {translate("help-desk.support-tickets.open-new-ticket")}
                    </PrimaryButton>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 text-right mt-3">
                    <Link to={ROUTE_HELPDESK_TICKETS}>
                      <span className="knowledge-base-view-all-tickets">
                        {translate("help-desk.tickets.view-all-tickets")}
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </MobileOnlyView>
            {(isTablet || isBrowser) && (
              <div className="tickets-list-header">
                <div className="row">
                  <div className="col-6 col-sm-6 col-md-6">
                    <span className="title">
                      {translate("help-desk.support-tickets.title")}
                    </span>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 text-right">
                    {isBrowser &&
                      !props.isLaptop &&
                      !isTablet &&
                      !isMobileOnly && (
                        <Link to={ROUTE_HELPDESK_TICKETS}>
                          <span className="knowledge-base-view-all-tickets">
                            {translate("help-desk.tickets.view-all-tickets")}
                          </span>
                        </Link>
                      )}
                    <PrimaryButton
                      onClick={() => props.onOpenNewTicket()}
                      className="open-new-ticket"
                    >
                      {translate("help-desk.support-tickets.open-new-ticket")}
                    </PrimaryButton>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12 text-right mt-3">
                    {(props.isLaptop || isTablet) && (
                      <Link to={ROUTE_HELPDESK_TICKETS}>
                        <span className="knowledge-base-view-all-tickets">
                          {translate("help-desk.tickets.view-all-tickets")}
                        </span>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            )}
            <div className="tickets-list-search">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <SearchField
                    name="search"
                    placeholder={translate(
                      "help-desk.support-tickets.search-placeholder"
                    )}
                    onChange={props.onSearch}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <StyledTicketsListContainer>
                  {props.children}
                </StyledTicketsListContainer>
              </div>
            </div>
          </StyledTicketsList>
        </Container>
      </StyledTicketsListWrapper>
    )}
  </Translate>
);

export default withSizes(isLaptop)(TicketsList);
