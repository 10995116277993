import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { isMobileOnly } from "react-device-detect";
import { withLocalize } from "react-localize-redux";

import Container from "../../../../../components/Containers/Container";

import PrimaryWarningButton from "../../../../../components/Buttons/PrimaryWarningButton";

import ARecord from "../Records/ARecord";
import AAAARecord from "../Records/AAAARecord";
import CNAMERecord from "../Records/CNAMERecord";
import MXRecord from "../Records/MXRecord";
import NSRecord from "../Records/NSRecord";
import PTRRecord from "../Records/PTRRecord";
import SRVRecord from "../Records/SRVRecord";
import TXTRecord from "../Records/TXTRecord";
import CAARecord from "../Records/CAARecord";

const StyledDnsZoneListWrapper = styled.div`
  margin-bottom: 30px;
`;

const StyledDnsZoneList = styled(Container)`
  padding-top: 30px;
  padding-bottom: 30px;

  & .row-selector {
    vertical-align: middle;
  }

  & .actions button {
    vertical-align: top;
    float: right;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
  margin-left: 25px;
  margin-right: 25px;
`;

const StyledDescription = styled.span`
  display: block;
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.neutral6};
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 40px;
`;

const StyledZoneActions = styled.div`
  padding: 0 10px;

  & > button {
    padding: ${props => (props.isMobile ? "0px 9px !important" : "0px 15px")};
  }
`;

const StyledRecordsWrapper = styled.div`
  border-top: 1px solid ${theme.neutral3};
  padding: 25px;
`;

const StyledRecordRow = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  padding-bottom: 15px;
  margin-bottom: 15px;
`;

const RecordTypes = {
  A: ARecord,
  AAAA: AAAARecord,
  CNAME: CNAMERecord,
  MX: MXRecord,
  NS: NSRecord,
  PTR: PTRRecord,
  TXT: TXTRecord,
  SRV: SRVRecord,
  CAA: CAARecord
};

class DnsZoneList extends React.Component {
  render() {
    return (
      <StyledDnsZoneListWrapper className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <StyledDnsZoneList>
            <StyledTitle>
              {this.props.translate("domain.zone-file.dns-zone")}
            </StyledTitle>
            <StyledDescription>
              {this.props.translate("domain.zone-file.manage-dns-zone")}
            </StyledDescription>

            <StyledRecordsWrapper>
              {this.props.domain &&
                this.props.domain.zoneFile &&
                this.props.domain.zoneFile.records &&
                this.props.domain.zoneFile.records.map((record, index) => {
                  const RecordComponent = RecordTypes[record.type]
                    ? RecordTypes[record.type]
                    : null;
                  return (
                    <div key={`${record.type}-${index}`}>
                      {RecordTypes[record.type] && (
                        <StyledRecordRow>
                          <RecordComponent
                            domain={this.props.domain}
                            record={record}
                            onUpdateRecordType={this.props.onUpdateRecordType}
                            onUpdateRecordField={this.props.onUpdateRecordField}
                            onSaveRecord={this.props.onSaveRecord}
                            onDeleteRecord={this.props.onDeleteRecord}
                            deletingRecord={this.props.deletingRecord}
                          />
                        </StyledRecordRow>
                      )}
                    </div>
                  );
                })}
            </StyledRecordsWrapper>
            <StyledZoneActions className="text-right" isMobile={isMobileOnly}>
              <PrimaryWarningButton
                onClick={e => {
                  e.preventDefault();
                  this.props.onDeleteZoneFile(this.props.domain);
                }}
              >
                {this.props.translate("domain.zone-file.delete-dns-zone")}
              </PrimaryWarningButton>
              <PrimaryWarningButton
                onClick={() => this.props.onResetZoneFile(this.props.domain)}
              >
                {this.props.translate("domain.zone-file.reset-dns-zone")}
              </PrimaryWarningButton>
            </StyledZoneActions>
          </StyledDnsZoneList>
        </div>
      </StyledDnsZoneListWrapper>
    );
  }
}

export default withLocalize(DnsZoneList);
