import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Link } from "react-router-dom";

const StyledBreadcrumbItem = styled.li`
  font-size: 14px;
  line-height: 1.43;
  font-weight: normal;
  color: ${theme.neutral6};

  & + &:before {
    font-size: 14px;
  }

  & a {
    color: ${theme.blueBase};
  }
`;

const BreadcrumbItem = props => (
  <StyledBreadcrumbItem
    {...props}
    className={`${props.className} ${
      props.active ? "breadcrumd-item active" : "breadcrumb-item"
    }`}
  >
    {props.to ? <Link to={props.to}>{props.title}</Link> : props.title}
  </StyledBreadcrumbItem>
);

BreadcrumbItem.defaultProps = {
  to: null,
  title: ""
};
export default BreadcrumbItem;
