import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route } from "react-router-dom";
import { withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";

import Breadcrumb from "../../../components/Breadcrumb";
import BreadcrumbItem from "../../../components/Breadcrumb/BreadcrumbItem";
import Tabs from "../../../components/Tabs";
import Tab from "../../../components/Tabs/Tab";
import TabContent from "../../../components/Tabs/TabContent";
import ContentLoader from "../../../components/Loaders/ContentLoader";
import ActionModal from "../../../components/ActionModal";
import PrimaryWarningButton from "../../../components/Buttons/PrimaryWarningButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import UnexpectedError from "../../../components/UnexpectedError/UnexpectedError";

import GeneralInfo from "./GeneralInfo";
import Addons from "./Addons";
//import Contacts from "./Contacts";

import { fetchCustomerServerDetails } from "../../Customer/actions/customerActions";

import {} from "../../Customer/reducers/customerReducer";

import { requestRenewProduct } from "../../Products/actions/productsActions";

import {
  compileRoute,
  ROUTE_INDEX,
  ROUTE_SERVERS,
  ROUTE_VIEW_SERVER_GENERAL_INFO,
  ROUTE_VIEW_SERVER_ADDONS,
  ROUTE_BILLING_VIEW_PROINVOICE
  // ROUTE_VIEW_SERVER_CONTACTS
} from "../../../routes/routes";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import {
  validatePasswordWithConfirm,
  validatePassword
} from "../../../common/validationRules";

import {
  SERVER_STATUS_ACTIVE,
  SERVER_STATUS_TERMINATED
} from "../ServersStatuses/ServersStatuses";

import {
  SERVER_PANEL_TYPE_CPANEL,
  SERVER_PANEL_TYPE_PLESK
} from "./ServerPanelTypes";

class ViewServer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentServer: null,
      unexpectedError: null,
      quickLinks: {
        cPanel: null
      },
      renewNowSubmitting: false,
      renewNowSubmittingId: 0,
      showRenewServerModal: false
    };

    this.viewServerGeneralInfoPath = compileRoute(
      ROUTE_VIEW_SERVER_GENERAL_INFO
    );
    this.viewServerAddonsPath = compileRoute(ROUTE_VIEW_SERVER_ADDONS);
    // this.viewServerContactsPath = compileRoute(ROUTE_VIEW_SERVER_CONTACTS);
  }

  componentDidMount() {
    this.doFetchServer(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.doFetchServer(this.props.match.params.id);
    }
  }

  doFetchServer = id => {
    this.setState({ currentServer: null, unexpectedError: null });
    this.props.fetchCustomerServerDetails(id).then(response => {
      this.setState({ currentServer: response.server });

      if (response.error) {
        this.setState({ unexpectedError: true });
        return;
      }
    });
  };

  onShowRenewNowModal = () => {
    this.setState({
      showRenewServerModal: true
    });
  };

  onHideRenewNowModal = () => {
    this.setState({
      showRenewServerModal: false
    });
  };

  onRenewNow = hosting => {
    this.setState({
      renewNowSubmitting: true,
      renewNowSubmittingId: hosting.id
    });

    this.props
      .requestRenewProduct(hosting)
      .then(({ error, invoice, messages }) => {
        if (!error) {
          this.props.history.push(
            compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)({ id: invoice.id })
          );
        }
        if (error) {
          this.onHideRenewNowModal();
          this.setState({
            renewNowSubmitting: false,
            renewNowSubmittingId: 0
          });
          displayToastMessageForResponse(
            this.props.translate("hosting.title"),
            { error, messages }
          );
        }
      })
      .catch(error => {
        this.setState({
          renewNowSubmitting: false,
          renewNowSubmittingId: 0
        });
        this.onHideRenewNowModal();
        displayToastMessageForResponse(
          this.props.translate("hosting.title"),
          error
        );
      });
  };

  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          {!this.state.currentServer && !this.state.unexpectedError && (
            <ContentLoader />
          )}
          {this.state.unexpectedError && <UnexpectedError />}
          {this.state.currentServer && (
            <Breadcrumb>
              <BreadcrumbItem
                to={ROUTE_INDEX}
                title={this.props.translate("breadcrumbs.home")}
              />
              <BreadcrumbItem
                to={ROUTE_SERVERS}
                title={this.props.translate("breadcrumbs.hosting")}
              />
              {this.state.currentServer && (
                <BreadcrumbItem
                  title={
                    this.state.currentServer.name +
                    " - " +
                    this.state.currentServer.domain
                  }
                />
              )}
            </Breadcrumb>
          )}

          {this.state.currentServer && (
            <Tabs
              defaultTab="general-info"
              renderTabs={(active, onSelect) => [
                <Tab
                  key="tab-general-info"
                  label="general-info"
                  active={active}
                  onSelect={onSelect}
                  linkTo={this.viewServerGeneralInfoPath({
                    id: this.state.currentServer.id,
                    domain: this.state.currentServer.domain
                  })}
                >
                  {this.props.translate("hosting.general-info.title")}
                </Tab>,
                this.state.currentServer.status.code !==
                SERVER_STATUS_TERMINATED ? (
                  <Tab
                    key="tab-addons"
                    label="addons"
                    active={active}
                    onSelect={onSelect}
                    linkTo={this.viewServerAddonsPath({
                      id: this.state.currentServer.id,
                      domain: this.state.currentServer.domain
                    })}
                  >
                    {this.props.translate("hosting.addons.title")}
                  </Tab>
                ) : (
                  ""
                )
              ]}
              renderTabContent={active => (
                <Switch>
                  <Route
                    path={ROUTE_VIEW_SERVER_GENERAL_INFO}
                    exact
                    render={() => (
                      <TabContent
                        key="tab-content-general-info"
                        label="general-info"
                        active={true}
                      >
                        <GeneralInfo
                          server={this.state.currentServer}
                          isGettingServerCPanelDirectLoginLink={
                            this.props.isGettingServerCPanelDirectLoginLink
                          }
                          quickLinks={this.state.quickLinks}
                          onShowCPanelChangePasswordModal={
                            this.onShowCPanelChangePasswordModal
                          }
                          onRenewNow={this.onShowRenewNowModal}
                          renewNowSubmitting={this.state.renewNowSubmitting}
                        />
                      </TabContent>
                    )}
                  />
                  <Route
                    path={ROUTE_VIEW_SERVER_ADDONS}
                    exact
                    render={() =>
                      this.state.currentServer.status.code !==
                      SERVER_STATUS_TERMINATED ? (
                        <TabContent
                          key="tab-addons"
                          label="addons"
                          active={true}
                        >
                          <Addons server={this.state.currentServer} />
                        </TabContent>
                      ) : (
                        ""
                      )
                    }
                  />
                </Switch>
              )}
            />
          )}
        </div>

        {this.state.showRenewServerModal && (
          <ActionModal
            title={`${this.props.translate(
              "hosting.general-info.renew-hosting-confirmation-modal-title"
            )}`}
            onCloseActionModal={this.onHideRenewNowModal}
            body={() => (
              <div>
                {this.props.translate(
                  "hosting.general-info.renew-hosting-confirmation-modal-description"
                )}
              </div>
            )}
            footer={() => (
              <React.Fragment>
                <SecondaryButton onClick={() => this.onHideRenewNowModal()}>
                  {this.props.translate("hosting.general-info.cancel")}
                </SecondaryButton>
                <PrimaryButton
                  disabled={this.state.renewNowSubmitting}
                  submitting={this.state.renewNowSubmitting}
                  onClick={() => this.onRenewNow(this.state.currentServer)}
                >
                  {this.props.translate("hosting.general-info.renew-hosting")}
                </PrimaryButton>
              </React.Fragment>
            )}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCustomerServerDetails,
      requestRenewProduct
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocalize(ViewServer)));
