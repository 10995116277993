import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { Translate } from "react-localize-redux";

import Container from "../../../../../components/Containers/Container";
import DetailsPanelItem from "../../../../../components/DetailsPanel/DetailsPanelItem";
import ExternalLink from "../../../../../components/ExternalLink";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";

import { HOSTING_PANEL_TYPE_CPANEL } from "../../../ViewHosting/HostingPanelTypes";

const StyledHeader = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
`;

const StyledContent = styled.div``;

const StyledTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  color: ${theme.neutral5};
`;

const CpanelAccess = ({
  hosting,
  onShowCPanelChangePasswordModal,
  cpanelLoginLink
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <Container>
          <StyledHeader>
            <StyledTitle>
              {translate(`hosting.general-info.${hosting.type}-access`)}
            </StyledTitle>
          </StyledHeader>
          <StyledContent>
            <DetailsPanelItem
              label={translate("hosting.general-info.username")}
            >
              {hosting.username}
            </DetailsPanelItem>
            <DetailsPanelItem
              label={translate("hosting.general-info.password")}
            >
              <SecondaryButton
                onClick={e => {
                  onShowCPanelChangePasswordModal();
                }}
                size="s"
              >
                {translate("hosting.general-info.change")}
              </SecondaryButton>
            </DetailsPanelItem>
            <DetailsPanelItem
              label={translate("hosting.general-info.login-url")}
            >
              {hosting.type === HOSTING_PANEL_TYPE_CPANEL ? (
                <ExternalLink
                  href={
                    cpanelLoginLink && cpanelLoginLink !== ""
                      ? cpanelLoginLink
                      : "https://" + hosting.domain + "/cpanel"
                  }
                >
                  {"https://" + hosting.domain + "/cpanel"}
                </ExternalLink>
              ) : (
                <ExternalLink
                  href={
                    hosting.server &&
                    hosting.server.hostname &&
                    "https://" + hosting.server.hostname + ":8443"
                  }
                >
                  {"https://" + hosting.server.hostname + ":8443"}
                </ExternalLink>
              )}
            </DetailsPanelItem>
          </StyledContent>
        </Container>
      )}
    </Translate>
  );
};
export default CpanelAccess;
