import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";
import { MobileOnlyView, TabletView, BrowserView } from "react-device-detect";
import { theme } from "../../../design/Themes";

import DetailsPanel from "../../../components/DetailsPanel";
import DetailsPanelItem from "../../../components/DetailsPanel/DetailsPanelItem";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import Icon from "../../../components/Icon";

import AvailableCredit from "../../../components/Credit/AvailableCredit";
import PurchaseCredit from "../../../components/Credit/PurchaseCredit";
import DigitalInvoices from "../../../components/DigitalInvoices";
import EmailVerificationLink from "../../EmailVerification/EmailVerificationLink";
import CustomTooltip from "../../../components/CustomTooltip/CustomTooltip";



const UnverifiedText = styled.span`
color:#fe9b34;
`

const MediumText = styled.div`
font-size:14px;

& .click-here-to-verify {
  font-weight: 600;
  color: ${theme.blueBase};
  line-height: 1.43;
  letter-spacing: 0.2px;
  cursor: pointer;
  text-decoration:underline;
}`


const VerifiedEmailSection = styled.div`
display:flex;
align-items:center;
justify-content: flex-end;`

const GeneralInfo = ({
  availableCredit,
  customerPersonalInformation,
  onShowUpdateGeneralInfoModal,
  onPurchaseCredit,
  addFundsMinimum,
  addFundsMaximum,
  validatePurchaseCredit,
  isFetchingInvoicesSettings,
  invoicesSettings,
  onToggleDigitalInvoices,
  handleSendVerificationEmail
}) => (

  <Translate>
    {({ translate }) => (
      <div className="row">
        <MobileOnlyView renderWithFragment>
          <div className="col-12 col-sm-12 col-md-12">
            {customerPersonalInformation && (
              <DetailsPanel
                title={
                  customerPersonalInformation.first_name +
                  " " +
                  customerPersonalInformation.last_name
                }
                action={
                  <SecondaryButton
                    onClick={e => onShowUpdateGeneralInfoModal()}
                  >
                    <Icon icon="settings" />
                  </SecondaryButton>
                }
              >
                <DetailsPanelItem
                  label={translate("my-account.general-info.details.email")}
                >   {!customerPersonalInformation.email_verified ? (
                  <Icon icon="warning" color="#fe9b34" />
                ) : (
                  <CustomTooltip
                  content={translate(
                    "email-verification.verified-email"
                  )}
                >
                  <Icon icon="success" color="#3ab27f" />
                  </CustomTooltip>
                )}
                  {customerPersonalInformation.email}
                  {!customerPersonalInformation.email_verified ? (
                    <MediumText><UnverifiedText>{translate("my-account.general-info.details.unverified")} </UnverifiedText>
                      <EmailVerificationLink />{translate("my-account.general-info.details.to-verify")}</MediumText>
                  ) : null}
                </DetailsPanelItem>
                {customerPersonalInformation.company &&
                  customerPersonalInformation.company.length > 0 && (
                    <DetailsPanelItem
                      label={translate(
                        "my-account.general-info.details.company"
                      )}
                    >
                      {customerPersonalInformation.company}
                    </DetailsPanelItem>
                  )}
                {customerPersonalInformation.company &&
                  customerPersonalInformation.company.length > 0 && (
                    <DetailsPanelItem
                      label={translate(
                        "my-account.general-info.details.tax-number"
                      )}
                    >
                      #{customerPersonalInformation.tax_number}
                    </DetailsPanelItem>
                  )}
                <DetailsPanelItem
                  label={translate("my-account.general-info.details.phone")}
                >
                  {customerPersonalInformation.phone}
                </DetailsPanelItem>
                <DetailsPanelItem
                  label={translate("my-account.general-info.details.address")}
                >
                  <p>{customerPersonalInformation.address1}</p>
                  <p>
                    {customerPersonalInformation.zip},
                    {customerPersonalInformation.city}
                  </p>
                  {customerPersonalInformation.country && (
                    <p>{customerPersonalInformation.country.name}</p>
                  )}
                </DetailsPanelItem>
              </DetailsPanel>
            )}
          </div>
          <div className="col-12 col-sm-12 col-md-12">
            {invoicesSettings.digital_invoices_available && (
              <DigitalInvoices
                title={translate(
                  "my-account.general-info.digital-invoices.title"
                )}
                description={translate(
                  "my-account.general-info.digital-invoices.description"
                )}
                isLoading={isFetchingInvoicesSettings}
                settings={invoicesSettings}
                onToggleDigitalInvoices={onToggleDigitalInvoices}
              />
            )}
          </div>
          <div className="col-12 col-sm-12 col-md-12">
            {availableCredit && (
              <AvailableCredit
                currency={availableCredit.currency.code}
                amount={availableCredit.amount}
                title={translate(
                  "my-account.general-info.available-credit.title"
                )}
                description={translate(
                  "my-account.general-info.available-credit.description"
                )}
              />
            )}
            <PurchaseCredit
              currency={availableCredit.currency.code}
              title={translate("my-account.general-info.purchase-credit.title")}
              description={
                translate(
                  "my-account.general-info.purchase-credit.description"
                ) +
                " " +
                availableCredit.currency.code.toUpperCase() +
                " " +
                addFundsMinimum
              }
              onPurchaseCredit={onPurchaseCredit}
              addFundsMinimum={addFundsMinimum}
              validatePurchaseCredit={validatePurchaseCredit}
            />
          </div>
        </MobileOnlyView>
        <TabletView renderWithFragment>
          <div className="col-6 col-sm-6 col-md-6">
            {customerPersonalInformation && (
              <DetailsPanel
                title={
                  customerPersonalInformation.first_name +
                  " " +
                  customerPersonalInformation.last_name
                }
                action={
                  <SecondaryButton
                    onClick={e => onShowUpdateGeneralInfoModal()}
                  >
                    <Icon icon="settings" />
                  </SecondaryButton>
                }
              >
                <DetailsPanelItem
                  label={translate("my-account.general-info.details.email")}
                >
                  <VerifiedEmailSection>
                    {!customerPersonalInformation.email_verified ? (
                      <Icon icon="warning" color="#fe9b34" />
                    ) : (
                      <CustomTooltip
                      content={translate(
                        "email-verification.verified-email"
                      )}
                    >
                      <Icon icon="success" color="#3ab27f" />
                      </CustomTooltip>
                    )}
                    {customerPersonalInformation.email}
                  </VerifiedEmailSection>

                  {!customerPersonalInformation.email_verified ? (
                    <MediumText><UnverifiedText>{translate("my-account.general-info.details.unverified")}</UnverifiedText>
                      <EmailVerificationLink />{translate("my-account.general-info.details.to-verify")}</MediumText>
                  ) : null}
                </DetailsPanelItem>
                {customerPersonalInformation.company &&
                  customerPersonalInformation.company.length > 0 && (
                    <DetailsPanelItem
                      label={translate(
                        "my-account.general-info.details.company"
                      )}
                    >
                      {customerPersonalInformation.company}
                    </DetailsPanelItem>
                  )}
                {customerPersonalInformation.company &&
                  customerPersonalInformation.company.length > 0 && (
                    <DetailsPanelItem
                      label={translate(
                        "my-account.general-info.details.tax-number"
                      )}
                    >
                      #{customerPersonalInformation.tax_number}
                    </DetailsPanelItem>
                  )}
                <DetailsPanelItem
                  label={translate("my-account.general-info.details.phone")}
                >
                  {customerPersonalInformation.phone}
                </DetailsPanelItem>
                <DetailsPanelItem
                  label={translate("my-account.general-info.details.address")}
                >
                  <p>{customerPersonalInformation.address1}</p>
                  <p>
                    {customerPersonalInformation.zip},
                    {customerPersonalInformation.city}
                  </p>
                  {customerPersonalInformation.country && (
                    <p>{customerPersonalInformation.country.name}</p>
                  )}
                </DetailsPanelItem>
              </DetailsPanel>
            )}
          </div>
          <div className="col-6 col-sm-6 col-md-6">
            {invoicesSettings.digital_invoices_available && (
              <DigitalInvoices
                title={translate(
                  "my-account.general-info.digital-invoices.title"
                )}
                description={translate(
                  "my-account.general-info.digital-invoices.description"
                )}
                isLoading={isFetchingInvoicesSettings}
                settings={invoicesSettings}
                onToggleDigitalInvoices={onToggleDigitalInvoices}
              />
            )}
          </div>
          <div className="col-6 col-sm-6 col-md-6">
            {availableCredit && (
              <AvailableCredit
                currency={availableCredit.currency.code}
                amount={availableCredit.amount}
                title={translate(
                  "my-account.general-info.available-credit.title"
                )}
                description={translate(
                  "my-account.general-info.available-credit.description"
                )}
              />
            )}
            <PurchaseCredit
              currency={availableCredit.currency.code}
              title={translate("my-account.general-info.purchase-credit.title")}
              description={
                translate(
                  "my-account.general-info.purchase-credit.description"
                ) +
                " " +
                availableCredit.currency.code.toUpperCase() +
                " " +
                addFundsMinimum
              }
              addFundsMinimum={addFundsMinimum}
              validatePurchaseCredit={validatePurchaseCredit}
            />
          </div>
        </TabletView>
        <BrowserView renderWithFragment>
          <div className="col-6 col-sm-6 col-md-6">
            {customerPersonalInformation && (
              <DetailsPanel
                title={
                  customerPersonalInformation.first_name +
                  " " +
                  customerPersonalInformation.last_name
                }
                action={
                  <SecondaryButton
                    onClick={e => onShowUpdateGeneralInfoModal()}
                  >
                    <Icon icon="settings" />
                  </SecondaryButton>
                }
              >
                <DetailsPanelItem
                  label={translate("my-account.general-info.details.email")}
                >
                  <VerifiedEmailSection>
                    {!customerPersonalInformation.email_verified ? (
                      <Icon icon="warning" color="#fe9b34" />
                    ) : (
                      <CustomTooltip
                      content={translate(
                        "email-verification.verified-email"
                      )}
                    >
                      <Icon icon="success" color="#3ab27f" />
                      </CustomTooltip>
                    )}
                    {customerPersonalInformation.email}
                  </VerifiedEmailSection>

                  {!customerPersonalInformation.email_verified ? (
                    <MediumText><UnverifiedText>{translate("my-account.general-info.details.unverified")}</UnverifiedText>
                      <EmailVerificationLink />{translate("my-account.general-info.details.to-verify")}</MediumText>
                  ) : null}
                </DetailsPanelItem>
                {customerPersonalInformation.company &&
                  customerPersonalInformation.company.length > 0 && (
                    <DetailsPanelItem
                      label={translate(
                        "my-account.general-info.details.company"
                      )}
                    >
                      {customerPersonalInformation.company}
                    </DetailsPanelItem>
                  )}
                {customerPersonalInformation.company &&
                  customerPersonalInformation.company.length > 0 && (
                    <DetailsPanelItem
                      label={translate(
                        "my-account.general-info.details.tax-number"
                      )}
                    >
                      #{customerPersonalInformation.tax_number}
                    </DetailsPanelItem>
                  )}
                <DetailsPanelItem
                  label={translate("my-account.general-info.details.phone")}
                >
                  {customerPersonalInformation.phone}
                </DetailsPanelItem>
                <DetailsPanelItem
                  label={translate("my-account.general-info.details.address")}
                >
                  <p>{customerPersonalInformation.address1}</p>
                  <p>
                    {customerPersonalInformation.zip},
                    {customerPersonalInformation.city}
                  </p>
                  {customerPersonalInformation.country && (
                    <p>{customerPersonalInformation.country.name}</p>
                  )}
                </DetailsPanelItem>
              </DetailsPanel>
            )}
            {invoicesSettings.digital_invoices_available && (
              <DigitalInvoices
                title={translate(
                  "my-account.general-info.digital-invoices.title"
                )}
                description={translate(
                  "my-account.general-info.digital-invoices.description"
                )}
                isLoading={isFetchingInvoicesSettings}
                settings={invoicesSettings}
                onToggleDigitalInvoices={onToggleDigitalInvoices}
              />
            )}
          </div>
          <div className="col-6 col-sm-6 col-md-6">
            {availableCredit && (
              <AvailableCredit
                currency={availableCredit.currency.code}
                amount={availableCredit.amount}
                title={translate(
                  "my-account.general-info.available-credit.title"
                )}
                description={translate(
                  "my-account.general-info.available-credit.description"
                )}
              />
            )}
            <PurchaseCredit
              currency={availableCredit.currency.code}
              title={translate("my-account.general-info.purchase-credit.title")}
              description={
                translate(
                  "my-account.general-info.purchase-credit.description"
                ) +
                " " +
                availableCredit.currency.code.toUpperCase() +
                " " +
                addFundsMinimum
              }
              onPurchaseCredit={onPurchaseCredit}
              addFundsMinimum={addFundsMinimum}
              validatePurchaseCredit={validatePurchaseCredit}
            />
          </div>
        </BrowserView>
      </div>
    )}
  </Translate>
);

export default GeneralInfo;
