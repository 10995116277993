import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import withSizes from "react-sizes";
import isLaptop from "../../utils/isLaptopScreen";
import {
  MobileOnlyView,
  BrowserView,
  TabletView,
  isMobileOnly
} from "react-device-detect";
import { Translate, withLocalize } from "react-localize-redux";
import { debounce } from "throttle-debounce";

import Breadcrumb from "../../components/Breadcrumb";
import BreadcrumbItem from "../../components/Breadcrumb/BreadcrumbItem";
import PrimaryWarningButton from "../../components/Buttons/PrimaryWarningButton";
import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";

import withLoading from "../../components/Loaders/WithLoading";
import FilterLoader from "../../components/Loaders/FilterLoader";
import ContentLoader from "../../components/Loaders/ContentLoader";
//import TableLoader from "../../components/Loaders/TableLoader";

import ServersFilter from "./ServersFilter";
import ServersList from "./ServersList";

import Modal from "../../components/Modal";
import ActionModal from "../../components/ActionModal";
import UpgradeDowngradeServer from "./UpgradeDowngradeServer";

import {
  fetchCustomerServers,
  fetchCustomerServersFiltersStatusCollection,
  fetchCustomerServersFiltersServersCollection,
  selectCustomerProductsListTableRow,
  deselectCustomerProductsListTableRow,
  selectAllCustomerProductsListTableRows,
  deselectAllCustomerProductsListTableRows
} from "../Customer/actions/customerActions";
import {
  getCustomerServers,
  getServersListFilters,
  getProductListSelectedRowsCount,
  isFetchingServers
} from "../Customer/reducers/customerReducer";

import {
  fetchServerUpgrades,
  upgradeServer,
  requestRenewServer,
  cancelServer
} from "../Servers/actions/serversActions";

import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

import { getCartId } from "../Cart/reducers/cartReducer";

import {
  ROUTE_INDEX,
  ROUTE_BILLING_VIEW_PROINVOICE,
  compileRoute
} from "../../routes/routes";

import CancelServer from "./CancelServer";

import {
  SORT_ORDER_DESCENDING,
  SORT_ORDER_ASCENDING
} from "../../types/SortTypes";

const StyledServersWrapper = styled.div`
  margin-bottom: ${props => props.isMobile && "100px"};
`;

const UpgradeDowngradeServerWithLoading = withLoading(
  UpgradeDowngradeServer,
  ContentLoader
);

class Servers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpgradePlanModal: false,
      fetchingPlanUpgrades: false,
      upgradingProduct: false,
      currentPlan: null,
      productUpgrades: null,
      showCancelProductModal: false,
      cancelProductPeriod: null,
      cancelProductSubmitting: false,
      renewProductSubmitting: false,
      renewProductSubmittingId: 0,
      showRenewNowModal: false,
      selectedProduct: null
    };

    this.state = {
      searchQuery: props.servers.filters.keyword
    };

    this.searchServersByKeywordThrottled = debounce(
      1000,
      this.searchServersByKeyword
    );
  }

  componentDidMount() {
    this.props.fetchCustomerServers(
      this.props.servers.paging.limit,
      1,
      this.props.servers.sort,
      this.props.servers.filters
    );

    this.props.fetchCustomerServersFiltersStatusCollection();
    // this.props.fetchCustomerServersFiltersServersCollection();
  }

  onShowUpgradePlanModal = server => {
    this.setState(
      {
        showUpgradePlanModal: true,
        fetchingPlanUpgrades: true,
        currentPlan: {
          ...server
        }
      },
      () => {
        this.props.fetchServerUpgrades(server).then(result => {
          const { servers } = result.data.data;
          const currentPlan = servers.find(
            plan => plan.id === server.catalog_id
          );
          this.setState({
            productUpgrades: servers,
            currentPlan: {
              ...currentPlan,
              ...server
            },
            fetchingPlanUpgrades: false
          });
        });
      }
    );
  };

  onHideUpgradePlanModal = () => {
    this.setState({
      showUpgradePlanModal: false,
      currentPlan: null,
      productUpgrades: null
    });
  };

  onUpgradeProduct = upgradeServer => {
    this.setState({
      upgradingProduct: true
    });
    this.props
      .upgradeServer(this.state.currentPlan, upgradeServer)
      .then(result => {
        const { error, messages, invoice } = result;
        this.setState({
          upgradingProduct: false
        });
        this.onHideUpgradePlanModal();
        displayToastMessageForResponse(this.props.translate("servers.title"), {
          error,
          messages
        });
        if (!error) {
          this.props.history.push(
            compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)({ id: invoice.id })
          );
        }
      })
      .catch(err => {
        this.setState({
          upgradingProduct: false
        });
        this.onHideUpgradePlanModal();
        displayToastMessageForResponse(
          this.props.translate("servers.title"),
          err
        );
      });
  };

  onRenewProduct = server => {
    this.setState({
      renewProductSubmitting: true,
      renewProductSubmittingId: server.id
    });
    this.props
      .requestRenewServer(server)
      .then(({ error, invoice, messages }) => {
        if (!error) {
          this.props.history.push(
            compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)({ id: invoice.id })
          );
        }
        if (error) {
          this.onHideRenewNowModal();
          this.setState({
            renewProductSubmitting: false,
            renewProductSubmittingId: 0
          });
          displayToastMessageForResponse(
            this.props.translate("servers.title"),
            {
              error,
              messages
            }
          );
        }
      })
      .catch(error => {
        this.onHideRenewNowModal();
        this.setState({
          renewProductSubmitting: false,
          renewProductSubmittingId: 0
        });
        displayToastMessageForResponse(
          this.props.translate("servers.title"),
          error
        );
      });
  };

  onShowRenewNowModal = server => {
    this.setState({
      selectedProduct: server,
      showRenewNowModal: true
    });
  };

  onHideRenewNowModal = () => {
    this.setState({
      showRenewNowModal: false
    });
  };

  onShowCancelProductModal = server => {
    this.setState({
      showCancelProductModal: true,
      currentPlan: server
    });
  };

  onHideCancelProductModal = () => {
    this.setState({
      showCancelProductModal: false,
      currentPlan: null,
      cancelProductPeriod: undefined,
      cancelProductSubmitting: false
    });
  };

  onChangeCancelProductPeriod = period => {
    this.setState({
      cancelProductPeriod: period
    });
  };

  onCancelServer = () => {
    if (this.state.currentPlan) {
      this.setState({
        cancelProductSubmitting: true
      });
      return this.props
        .cancelServer(this.state.currentPlan, this.state.cancelProductPeriod)
        .then(
          cancelled => {
            if (!cancelled.error) {
              this.setState({
                cancelProductSubmitting: true
              });
              let messages = {};
              messages[0] = this.props.translate(
                "servers.server-successfully-cancelled"
              );
              displayToastMessageForResponse(
                this.props.translate("servers.title"),
                {
                  error: false,
                  messages
                }
              );
            }

            if (cancelled.error) {
              this.setState({
                cancelProductSubmitting: true
              });
              displayToastMessageForResponse(
                this.props.translate("servers.title"),
                cancelled
              );
            }

            this.onHideCancelProductModal();
          },
          error => {
            this.onHideCancelProductModal();
            this.setState({
              cancelProductSubmitting: true
            });
            displayToastMessageForResponse(
              this.props.translate("servers.title"),
              error
            );
          }
        )
        .catch(error => {
          this.onHideCancelProductModal();
          this.setState({
            cancelProductSubmitting: true
          });
          displayToastMessageForResponse(
            this.props.translate("servers.title"),
            error
          );
        });
    }
  };

  handlePaginationClick = (event, page) => {
    event.preventDefault();

    if (page <= 0 || page > this.props.servers.paging.total_pages) {
      return;
    }

    this.props.fetchCustomerServers(
      this.props.servers.paging.limit,
      page,
      this.props.servers.sort,
      this.props.servers.filters
    );
  };

  onPerPageOptionChanged = value => {
    this.props.fetchCustomerServers(
      value.value,
      1,
      this.props.servers.sort,
      this.props.servers.filters
    );
  };

  changeListOrderTrigger = (sortBy, orderBy) => {
    this.props.fetchCustomerServers(
      this.props.servers.paging.limit,
      this.props.servers.paging.current_page,
      {
        sort_by: sortBy,
        order_by:
          orderBy === SORT_ORDER_DESCENDING
            ? SORT_ORDER_ASCENDING
            : SORT_ORDER_DESCENDING
      },
      this.props.servers.filters
    );
  };

  onChangeKeywordSearch = event => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.searchServersByKeywordThrottled(this.state.searchQuery);
    });
  };

  searchServersByKeyword = value => {
    this.props.fetchCustomerServers(
      this.props.servers.paging.limit,
      1,
      this.props.servers.sort,
      {
        ...this.props.servers.filters,
        keyword: value
      }
    );
  };

  onStatusFilterOptionChange = selectedOptionValue => {
    this.props.fetchCustomerServers(
      this.props.servers.paging.limit,
      1,
      this.props.servers.sort,
      {
        ...this.props.servers.filters,
        status: selectedOptionValue.value
      }
    );
  };

  onProductsFilterOptionChange = selectedOptionValue => {
    this.props.fetchCustomerServers(
      this.props.servers.paging.limit,
      1,
      this.props.servers.sort,
      {
        ...this.props.servers.filters,
        server: selectedOptionValue.value
      }
    );
  };

  onFiltersClear = () => {
    this.props.fetchCustomerServers(
      this.props.servers.paging.limit,
      1,
      this.props.servers.sort,
      {}
    );
  };

  countFiltersApplied = () => {
    return Object.entries(this.props.servers.filters).reduce(
      (accumulator, item) => {
        return item[0] !== "keyword" && item[1] !== ""
          ? ++accumulator
          : accumulator;
      },
      0
    );
  };

  remapFilterStatusesCollection = (value, callback) => {
    const options = this.props.serversListFilters.statuses.map(status => {
      return {
        label: (
          <Translate>
            {({ translate }) =>
              translate("servers.filter.filter-statuses." + status.code)
            }
          </Translate>
        ),
        value: status.code
      };
    });

    callback(options);
  };

  remapFilterProductsCollection = (value, callback) => {
    const options = this.props.serversListFilters.servers.map(productGroup => {
      return {
        label: productGroup.name,
        options: productGroup.servers.map(server => {
          return { label: server.name, value: server.id };
        })
      };
    });

    callback(options);
  };

  onRowSelectionChanged = server => {
    server.selected
      ? this.props.deselectCustomerProductsListTableRow(server)
      : this.props.selectCustomerProductsListTableRow(server);
  };

  selectAllRows = () => {
    this.props.selectAllCustomerProductsListTableRows();
  };

  deselectRows = () => {
    this.props.deselectAllCustomerProductsListTableRows();
  };

  render() {
    const ServersFilterWithLoading = withLoading(ServersFilter, FilterLoader);

    return (
      <Translate>
        {({ translate }) => (
          <StyledServersWrapper isMobile={isMobileOnly} className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <Breadcrumb separator={true}>
                <BreadcrumbItem
                  to={ROUTE_INDEX}
                  title={translate("breadcrumbs.home")}
                />
                <BreadcrumbItem title={translate("servers.title")} />
              </Breadcrumb>

              <MobileOnlyView>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <ServersFilterWithLoading
                      serversListFilters={this.props.serversListFilters}
                      onStatusFilterOptionChange={
                        this.onStatusFilterOptionChange
                      }
                      onProductsFilterOptionChange={
                        this.onProductsFilterOptionChange
                      }
                      onFiltersClear={this.onFiltersClear}
                      countFiltersApplied={this.countFiltersApplied}
                      filterStatusOptions={this.remapFilterStatusesCollection}
                      filterProductsOptions={this.remapFilterProductsCollection}
                      servers={this.props.servers}
                      selectedRowsCount={this.props.selectedRowsCount}
                      onRowSelectionChanged={this.onRowSelectionChanged}
                      selectAllRows={this.selectAllRows}
                      deselectRows={this.deselectRows}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12">
                    {this.props.servers && (
                      <ServersList
                        isLoading={this.props.isFetching}
                        isLaptop={this.props.isLaptop}
                        servers={this.props.servers}
                        onUpgradePlan={this.onShowUpgradePlanModal}
                        onRenewProduct={this.onShowRenewNowModal}
                        onCancelServer={this.onShowCancelProductModal}
                        handlePaginationClick={this.handlePaginationClick}
                        onPerPageOptionChanged={this.onPerPageOptionChanged}
                        changeListOrderTrigger={this.changeListOrderTrigger}
                        onChangeKeywordSearch={this.onChangeKeywordSearch}
                        searchQuery={this.state.searchQuery}
                        serversListFilters={this.props.serversListFilters}
                        onStatusFilterOptionChange={
                          this.onStatusFilterOptionChange
                        }
                        onProductsFilterOptionChange={
                          this.onProductsFilterOptionChange
                        }
                        onFiltersClear={this.onFiltersClear}
                        countFiltersApplied={this.countFiltersApplied}
                        filterStatusOptions={this.remapFilterStatusesCollection}
                        filterProductsOptions={
                          this.remapFilterProductsCollection
                        }
                        selectedRowsCount={this.props.selectedRowsCount}
                        onRowSelectionChanged={this.onRowSelectionChanged}
                        selectAllRows={this.selectAllRows}
                        deselectRows={this.deselectRows}
                        renewProductSubmitting={
                          this.state.renewProductSubmitting
                        }
                        renewProductSubmittingId={
                          this.state.renewProductSubmittingId
                        }
                      />
                    )}
                  </div>
                </div>
              </MobileOnlyView>
              <TabletView>
                <div className="row">
                  <div className="col-4 col-sm-4 col-md-4">
                    <ServersFilterWithLoading
                      serversListFilters={this.props.serversListFilters}
                      onStatusFilterOptionChange={
                        this.onStatusFilterOptionChange
                      }
                      onPlanTypesFilterOptionChange={
                        this.onPlanTypesFilterOptionChange
                      }
                      onFiltersClear={this.onFiltersClear}
                      countFiltersApplied={this.countFiltersApplied}
                      filterStatusOptions={this.remapFilterStatusesCollection}
                      filterPlanTypesOptions={
                        this.remapFilterPlanTypesCollection
                      }
                      servers={this.props.servers}
                      selectedRowsCount={this.props.selectedRowsCount}
                      onRowSelectionChanged={this.onRowSelectionChanged}
                      selectAllRows={this.selectAllRows}
                      deselectRows={this.deselectRows}
                    />
                  </div>
                  <div className="col-8 col-sm-8 col-md-8">
                    {this.props.servers && (
                      <ServersList
                        isLoading={this.props.isFetching}
                        isLaptop={this.props.isLaptop}
                        servers={this.props.servers}
                        onUpgradePlan={this.onShowUpgradePlanModal}
                        onRenewProduct={this.onShowRenewNowModal}
                        onCancelServer={this.onShowCancelProductModal}
                        handlePaginationClick={this.handlePaginationClick}
                        onPerPageOptionChanged={this.onPerPageOptionChanged}
                        changeListOrderTrigger={this.changeListOrderTrigger}
                        onChangeKeywordSearch={this.onChangeKeywordSearch}
                        searchQuery={this.state.searchQuery}
                        serversListFilters={this.props.serversListFilters}
                        onStatusFilterOptionChange={
                          this.onStatusFilterOptionChange
                        }
                        onPlanTypesFilterOptionChange={
                          this.onPlanTypesFilterOptionChange
                        }
                        onFiltersClear={this.onFiltersClear}
                        countFiltersApplied={this.countFiltersApplied}
                        filterStatusOptions={this.remapFilterStatusesCollection}
                        filterPlanTypesOptions={
                          this.remapFilterPlanTypesCollection
                        }
                        selectedRowsCount={this.props.selectedRowsCount}
                        onRowSelectionChanged={this.onRowSelectionChanged}
                        selectAllRows={this.selectAllRows}
                        deselectRows={this.deselectRows}
                        renewProductSubmitting={
                          this.state.renewProductSubmitting
                        }
                        renewProductSubmittingId={
                          this.state.renewProductSubmittingId
                        }
                      />
                    )}
                  </div>
                </div>
              </TabletView>
              <BrowserView>
                {this.props.isLaptop && (
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {this.props.servers && (
                        <ServersList
                          isLoading={this.props.isFetching}
                          isLaptop={this.props.isLaptop}
                          servers={this.props.servers}
                          onUpgradePlan={this.onShowUpgradePlanModal}
                          onRenewProduct={this.onShowRenewNowModal}
                          onCancelServer={this.onShowCancelProductModal}
                          handlePaginationClick={this.handlePaginationClick}
                          onPerPageOptionChanged={this.onPerPageOptionChanged}
                          changeListOrderTrigger={this.changeListOrderTrigger}
                          onChangeKeywordSearch={this.onChangeKeywordSearch}
                          searchQuery={this.state.searchQuery}
                          serversListFilters={this.props.serversListFilters}
                          onStatusFilterOptionChange={
                            this.onStatusFilterOptionChange
                          }
                          onProductsFilterOptionChange={
                            this.onProductsFilterOptionChange
                          }
                          onFiltersClear={this.onFiltersClear}
                          countFiltersApplied={this.countFiltersApplied}
                          filterStatusOptions={
                            this.remapFilterStatusesCollection
                          }
                          filterProductsOptions={
                            this.remapFilterProductsCollection
                          }
                          selectedRowsCount={this.props.selectedRowsCount}
                          onRowSelectionChanged={this.onRowSelectionChanged}
                          selectAllRows={this.selectAllRows}
                          deselectRows={this.deselectRows}
                          renewProductSubmitting={
                            this.state.renewProductSubmitting
                          }
                          renewProductSubmittingId={
                            this.state.renewProductSubmittingId
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
                {!this.props.isLaptop && (
                  <div className="row">
                    <div className="col-12 col-sm-3 col-md-3">
                      <ServersFilterWithLoading
                        serversListFilters={this.props.serversListFilters}
                        onStatusFilterOptionChange={
                          this.onStatusFilterOptionChange
                        }
                        onProductsFilterOptionChange={
                          this.onProductsFilterOptionChange
                        }
                        onFiltersClear={this.onFiltersClear}
                        countFiltersApplied={this.countFiltersApplied}
                        filterStatusOptions={this.remapFilterStatusesCollection}
                        filterProductsOptions={
                          this.remapFilterProductsCollection
                        }
                        servers={this.props.servers}
                        selectedRowsCount={this.props.selectedRowsCount}
                        onRowSelectionChanged={this.onRowSelectionChanged}
                        selectAllRows={this.selectAllRows}
                        deselectRows={this.deselectRows}
                      />
                    </div>
                    <div className="col-12 col-sm-9 col-md-9">
                      {this.props.servers && (
                        <ServersList
                          isLoading={this.props.isFetching}
                          isLaptop={this.props.isLaptop}
                          servers={this.props.servers}
                          onUpgradePlan={this.onShowUpgradePlanModal}
                          onRenewProduct={this.onShowRenewNowModal}
                          onCancelServer={this.onShowCancelProductModal}
                          handlePaginationClick={this.handlePaginationClick}
                          onPerPageOptionChanged={this.onPerPageOptionChanged}
                          changeListOrderTrigger={this.changeListOrderTrigger}
                          onChangeKeywordSearch={this.onChangeKeywordSearch}
                          searchQuery={this.state.searchQuery}
                          serversListFilters={this.props.serversListFilters}
                          onStatusFilterOptionChange={
                            this.onStatusFilterOptionChange
                          }
                          onProductsFilterOptionChange={
                            this.onProductsFilterOptionChange
                          }
                          onFiltersClear={this.onFiltersClear}
                          countFiltersApplied={this.countFiltersApplied}
                          filterStatusOptions={
                            this.remapFilterStatusesCollection
                          }
                          filterProductsOptions={
                            this.remapFilterProductsCollection
                          }
                          selectedRowsCount={this.props.selectedRowsCount}
                          onRowSelectionChanged={this.onRowSelectionChanged}
                          selectAllRows={this.selectAllRows}
                          deselectRows={this.deselectRows}
                          renewProductSubmitting={
                            this.state.renewProductSubmitting
                          }
                          renewProductSubmittingId={
                            this.state.renewProductSubmittingId
                          }
                        />
                      )}
                    </div>
                  </div>
                )}
              </BrowserView>
            </div>
            {this.state.showUpgradePlanModal && (
              <Modal
                title={translate("servers.upgrade-your-server-plan")}
                subTitle={`${this.state.currentPlan.name} - ${this.state.currentPlan.domain}`}
                size="xl"
                onCloseModal={this.onHideUpgradePlanModal}
                currentPlan={this.state.currentPlan}
                body={() => {
                  return (
                    this.state.currentPlan && (
                      <UpgradeDowngradeServerWithLoading
                        isLoading={this.state.fetchingPlanUpgrades}
                        currentPlan={this.state.currentPlan}
                        productUpgrades={this.state.productUpgrades}
                        onUpgradeProduct={this.onUpgradeProduct}
                        upgradingProduct={this.state.upgradingProduct}
                      />
                    )
                  );
                }}
              />
            )}
            {this.state.showCancelProductModal && (
              <Modal
                title={translate("servers.cancel-server")}
                subTitle={`${this.state.currentPlan.name} ${this.state
                  .currentPlan.domain !== "" && this.state.currentPlan.domain}`}
                onCloseModal={this.onHideCancelProductModal}
                currentPlan={this.state.currentPlan}
                body={() => {
                  return (
                    <div>
                      {this.state.currentPlan && (
                        <CancelServer
                          server={this.state.currentPlan}
                          cancelProductPeriod={this.state.cancelProductPeriod}
                          onChangeCancelProductPeriod={
                            this.onChangeCancelProductPeriod
                          }
                        />
                      )}
                    </div>
                  );
                }}
                footer={() => [
                  <SecondaryButton
                    key="dont-cancel-server"
                    onClick={e => this.onHideCancelProductModal()}
                  >
                    {translate("servers.dont-cancel")}
                  </SecondaryButton>,
                  <PrimaryWarningButton
                    key="cancel-product"
                    disabled={
                      this.state.cancelProductPeriod === undefined ||
                      this.state.cancelProductSubmitting
                    }
                    submitting={this.state.cancelProductSubmitting}
                    onClick={e => this.onCancelServer()}
                  >
                    {translate("servers.cancel-server")}
                  </PrimaryWarningButton>
                ]}
              />
            )}

            {this.state.showRenewNowModal && (
              <ActionModal
                title={`${translate(
                  "servers.general-info.renew-server-confirmation-modal-title"
                )}`}
                onCloseActionModal={this.onHideRenewNowModal}
                body={() => (
                  <div>
                    {translate(
                      "servers.general-info.renew-server-confirmation-modal-description"
                    )}
                  </div>
                )}
                footer={() => (
                  <React.Fragment>
                    <SecondaryButton onClick={() => this.onHideRenewNowModal()}>
                      {translate("servers.general-info.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={this.state.renewProductSubmitting}
                      submitting={this.state.renewProductSubmitting}
                      onClick={() =>
                        this.onRenewProduct(this.state.selectedProduct)
                      }
                    >
                      {translate("servers.general-info.renew-server")}
                    </PrimaryButton>
                  </React.Fragment>
                )}
              />
            )}
          </StyledServersWrapper>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    servers: getCustomerServers(state),
    serversListFilters: getServersListFilters(state),
    cartId: getCartId(state),
    isFetching: isFetchingServers(state),
    selectedRowsCount: getProductListSelectedRowsCount(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchCustomerServers,
      fetchServerUpgrades,
      fetchCustomerServersFiltersStatusCollection,
      fetchCustomerServersFiltersServersCollection,
      upgradeServer,
      requestRenewServer,
      cancelServer,
      selectCustomerProductsListTableRow,
      deselectCustomerProductsListTableRow,
      selectAllCustomerProductsListTableRows,
      deselectAllCustomerProductsListTableRows
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(withLocalize(Servers)));
