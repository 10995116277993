import qs from "query-string";

import {
  loginReceive,
  loginRequest,
  loginFailed,
  logout,
  authFetchFinish
} from "../features/Authentication/actions/authActions";
import * as authService from "../services/authService";
import * as cartService from "../services/cartService";

import { fetchCart } from "../features/Cart/actions/cartActions";
import { fetchCredit } from "../features/Credit/actions/creditActions";
import {
  fetchCustomerPersonalInformation,
  receiveCustomerPersonalInformation,
  requestCustomerPersonalInformation
} from "../features/Customer/actions/customerActions";
import { fetchSystemPreferences } from "../features/Preferences/actions/preferenceActions";
import { receiveCustomerPersonalInformationFailed } from "../features/Customer/actions/customerActions";

export default function bootApplication(dispatch) {
  const token = authService.getToken();
  if (token) {
    dispatch(loginReceive(token));
    authService.setToken(token);
    dispatch(requestCustomerPersonalInformation());
  }

  const params = qs.parse(window.location.search);
  if (params.cart && params.cart.length > 0) {
    cartService.storeCartId(params.cart);
  }
  // dispatch(loginReceive(token));
  // authService.setToken(token);
  // dispatch(requestCustomerPersonalInformation());
  //dispatch(loginRequest());

  Promise.resolve(dispatch(fetchCustomerPersonalInformation())).then(
    result => {
      if (result) {
        dispatch(receiveCustomerPersonalInformation(result));
        const token = authService.getToken();
        dispatch(loginReceive(token));
        authService.setToken(token);
        dispatch(authFetchFinish());
        dispatch(fetchCredit());
        dispatch(fetchCart());
        dispatch(fetchSystemPreferences());
      } else {
        dispatch(loginFailed());
        dispatch(receiveCustomerPersonalInformationFailed());
      }
    },
    error => {
      dispatch(loginFailed());
      dispatch(receiveCustomerPersonalInformationFailed());
    }
  );
}
