import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { withLocalize, Translate } from "react-localize-redux";
import { isMobileOnly, isTablet } from "react-device-detect";
import * as localizationService from "../../../services/localizationService";
import moment from "moment";
import "moment/min/locales";
import { connect } from 'react-redux';
import { getCustomerPersonalInformation } from "../../../features/Customer/reducers/customerReducer";



import DropdownMenu from "../../DropdowMenu";
import DropdownItem from "../../DropdownItem";

import UserWithEmail from "../../UserWithEmail";

import Avatar from "../../Avatar";
import InputIcon from "../../InputIcon";
import Icon from "../../Icon";


import FeedbackForm from "../../../features/FeedbackForm";
import feedback from "./feedback.svg";
import EmailVerificationLink from "../../../features/EmailVerification/EmailVerificationLink";


const StyledProfileNavItem = styled.li`

  position: relative;
  border-right: ${props =>
    props.isMobile ? "none" : `1px solid ${props.theme.neutral3}`};
  border-left: ${props =>
    props.isMobile ? "none" : `1px solid ${props.theme.neutral3}`};
  max-height: 74px;
  display:flex;
  align-items:center;
  justify-content:space-between;

  & > a {
    color: ${theme.neutral4};
    padding-top: ${props => (props.isTablet ? "23px" : "13px")};
    padding-bottom: 13px;
  }

  & > a:hover {
    color: ${theme.neutral5};
  }

  & .user-avatar {
    vertical-align: top;
  }

  & .user-avatar .icon {
    top: -3px;
  }

  & .dropdown-toggle:after {
    vertical-align: ${props => {
    if (props.isMobile || props.isTablet) {
      return "middle";
    }
    return "50%";
  }};
  }

  & .dropdown-menu {
    position: ${props => {
    if (props.isMobile || props.isTablet) {
      return "absolute !important";
    }

    return "static";
  }};
  }

  & #navbarDropdown{
    display:flex;
    align-items:center;
    padding-left: 0! important;
  }

  & #navbarDropdown.verified {
      padding-left: 1.5rem !important;
    }


  }
`;

const StyledLanguageTitle = styled.span`
  display: block;
  text-transform: uppercase;
  padding: 0;
  padding-left: 24px;
  padding-right: 25px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  color: ${theme.neutral5};
`;


const PopupContainer = styled.div`
  position: relative;
  padding-left: 1.5rem!important;
  padding-right: 0.5rem!important;
`;

const PopupContent = styled.div`
  position: absolute;
  visibility: hidden;
  background-color: #fff;
  padding: 20px;
  border-radius: 3px;
  box-shadow: 0 3px 5px 0 rgba(123, 139, 167, 0.25);
  border: solid 1px #d8dde6;
  display:flex;
  flex-direction:column;
  gap:7px;
  width:50vh;
  right:-25vh;
 

  & .click-here-to-verify {
    font-weight: 600;
    color: ${theme.blueBase};
    line-height: 1.43;
    letter-spacing: 0.2px;
    cursor: pointer;
    text-decoration:underline;
  }

  & .email-not-verified{
    color: ${theme.neutralBase};
    font-weight:600;
  }

  & .link-text{
    font-size:14px;
    color:#5E6C83;
  }

  
`;

const PopupContentMobile = styled(PopupContent)`
  width:36vh;
 right:0;
`;

const PopupContainerHover = styled(PopupContainer)`
  &:hover ${PopupContent} {
    visibility: visible; /* Show the pop-up on hover */
  }
`;
const StyledLanguagePick = styled.span`
  display: block;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 35px;
  padding-right: 24px;

  &:hover {
    background-color: ${theme.neutral1};
  }

  &:active {
    background-color: ${theme.blueBase};
    color: ${theme.white} !important;
  }
`;

const StyledSelectedLanguageIcon = styled.span`
  position: absolute;
  left: 15px;
`;

const CustomDropdownItem = styled.a`
  position: relative;
  font-size: 14px;
  line-height: 1.43;
  cursor: pointer;
  padding: 0;
  padding-top: 15px;

  padding-bottom: 8px;
  color: ${theme.neutralBase};

  &:hover {
    background-color: ${theme.white};
  }

  &:active {
    background-color: ${theme.white};
    color: ${theme.neutralBase} !important;
  }
`;

const FeedbackMenuItem = styled(DropdownItem)`
  background-color: ${theme.green1};
  padding-top: 15px;
  padding-bottom: 15px;
`;

const FeedbackIcon = styled.img`
  display: inline-block;
  margin-right: 15px;
  margin-top: -3px;
  width: 24px;
  height: 24px;
`;

class ProfileNavItem extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };
  }


  onShowMenu = e => {
    e.preventDefault();
    this.setState({ opened: true }, () => {
      document.addEventListener("click", this.onCloseMenu);
    });
  };

  onCloseMenu = e => {
    e.preventDefault();
    this.setState({ opened: false }, () => {
      document.removeEventListener("click", this.onCloseMenu);
    });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.onCloseMenu);
  }

  onSelectLanguage = (e, language) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.setActiveLanguage(language);
    moment.locale(language);
    localizationService.setApplicationLocale(language);
  };



  render() {


    return (
      <Translate>
        {({ translate }) => (

          <StyledProfileNavItem
            isMobile={isMobileOnly}
            isTablet={isTablet}
            className={`nav-item dropdown ${this.props.active ? "active" : ""
              } ${this.props.disabled ? "disabled" : ""} ${isMobileOnly &&
              "mr-3"}`}
          >
            {!this.props.emailVerified && (
              <PopupContainerHover >
                <Icon icon="warning" color="#fe9b34" />
                {(isMobileOnly || isTablet) && (
                  <PopupContentMobile>
                    <div>
                      <Icon icon="warning" color="#fe9b34" />{' '}
                      <span className="email-not-verified">{translate('navigation.profile.email-not-verified')}</span>
                    </div>
                    <div onClick={this.onClickVerification}>
                      <EmailVerificationLink /> {translate('my-account.general-info.details.click-here-to-verify')}
                      {translate('navigation.profile.to-send-verification-email')}
                    </div>
                  </PopupContentMobile>
                )}
                {!isMobileOnly && !isTablet && (
                  <PopupContent>
                    <div>
                      <Icon icon="warning" color="#fe9b34" />{' '}
                      <span className="email-not-verified">{translate('navigation.profile.email-not-verified')}</span>
                    </div>
                    <div className="link-text">
                      <EmailVerificationLink /> {translate('navigation.profile.to-send-verification-email')}
                    </div>
                  </PopupContent>
                )}
              </PopupContainerHover>
            )}


            <a
              className={`nav-link dropdown-toggle px-4 ${this.state.opened ? "show" : ""} ${this.props.emailVerified ? "verified" : ""}`}

              href="/"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={this.onShowMenu}
            >



              {!isMobileOnly && !isTablet && (
                <UserWithEmail
                  user={this.props.username}
                  email={this.props.email}
                />
              )}

              {!isMobileOnly && !isTablet && <Avatar />}
              {(isMobileOnly || isTablet) && <Icon icon="user" />}


            </a>

            <DropdownMenu
              alignMenu="right"
              className={`dropdown-menu ${this.state.opened ? "show" : ""}`}
              aria-labelledby="navbarDropdown"
            >

              {(isMobileOnly || isTablet) && (
                <div className="dropdown-divider" />
              )}
              <DropdownItem onClick={e => this.props.redirectToMyAccount()}>
                <Icon icon="settings" />{" "}
                {translate("navigation.profile.my-account")}
              </DropdownItem>
              <div className="dropdown-divider" />
              <CustomDropdownItem className="dropdown-item">
                <StyledLanguageTitle className="dropdown-header text-left">
                  {translate("navigation.profile.language")}
                </StyledLanguageTitle>
                {this.props.languages.map(lang => (
                  <StyledLanguagePick
                    key={`lang-${lang.code}`}
                    onClick={e => this.onSelectLanguage(e, lang.code)}
                  >
                    {lang.code === this.props.activeLanguage.code && (
                      <StyledSelectedLanguageIcon>
                        {" "}
                        <InputIcon
                          valid={true}
                          invalid={false}
                          icon="input-checkbox"
                        />
                      </StyledSelectedLanguageIcon>
                    )}{" "}
                    {lang.name}
                  </StyledLanguagePick>
                ))}
              </CustomDropdownItem>

              <div className="dropdown-divider mb-0" />
              <FeedbackForm>
                {onShow => (
                  <FeedbackMenuItem onClick={() => onShow()}>
                    <FeedbackIcon src={feedback} />
                    {translate("navigation.profile.feedback")}
                  </FeedbackMenuItem>
                )}
              </FeedbackForm>

              <div className="dropdown-divider mt-0" />
              <DropdownItem onClick={e => this.props.onLogout(e)}>
                {translate("navigation.profile.logout")}
              </DropdownItem>
            </DropdownMenu>
          </StyledProfileNavItem>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = (state) => {
  const customerPersonalInformation = getCustomerPersonalInformation(state);
  const emailVerified = customerPersonalInformation.email_verified;
  return {
    customerPersonalInformation,
    emailVerified
  };
};


export default connect(mapStateToProps)(withLocalize(ProfileNavItem));
