import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import Panel from "../../../../../components/Panel";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";



const StyledButton = styled(SecondaryButton)`
  margin-top: 25px;
`;

const BackupText= styled.div`
font-size: 16px;
    line-height: 1.5;
    color: #5E6C83;
     padding-bottom:10px;
`
const BackupTextAdmin= styled.div`
font-size: 16px;
    line-height: 1.5;
    color: #5E6C83;
    padding-bottom:10px;
`
const BackupTextRules= styled.div`
font-size: 16px;
    line-height: 1.5;
    color: #5E6C83;
    padding-bottom:10px;
`


const BackupInfoPanel = ({
                                 title,
                                 description,

                                 validatePurchaseCredit
                             }) => (




    <Translate>

        {({ translate }) => (

                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                        <Panel title={translate("backup.info-backup")} >
                        <BackupText>{translate("backup.backup-info-1")}</BackupText>

                        <BackupTextAdmin> {translate("backup.backup-info-2")}</BackupTextAdmin>

                        <BackupTextRules> {translate("backup.backup-info-3")}</BackupTextRules>
                        </Panel>
                    </div>
                </div>


        )}
    </Translate>
);


export default BackupInfoPanel;
