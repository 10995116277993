import React from "react";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import isLaptop from "../../../../../../../utils/isLaptopScreen";
import { withSizes } from "react-sizes";

import InputField from "../../../../../../../components/InputField";

const StyledPort = styled.div`
  display: inline-block;
  width: ${props => {
    if (props.isMobile) return "100%";
    if (props.isTablet) return "350px";
    if (props.isLaptop) return "50px;";
    if (!props.isLaptop && props.isBrowser) return "100px";
  }};
   margin-right: ${props => {
     if (props.isLaptop) return "20px";
     return "30px";
   }}
  vertical-align: top;
  margin-left: ${props => {
    if (props.isTablet) return "190px";
    return 0;
  }};
`;

const Port = props => (
  <StyledPort
    isMobile={isMobileOnly}
    isTablet={isTablet}
    isLaptop={props.isLaptop}
    isBrowser={isBrowser}
  >
    <InputField label={props.translate("domain.zone-file.port")} {...props} />
  </StyledPort>
);

export default withLocalize(withSizes(isLaptop)(Port));
