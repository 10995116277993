import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";

import graphicsNoDNS from "../../../../../assets/images/icon-illustration-lock.svg";

import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import Container from "../../../../../components/Containers/Container";

import { Link } from "react-router-dom";
import {
  compileRoute,
  ROUTE_CREATE_TICKET
} from "../../../../../routes/routes";

const StyledSetDefaultNameservers = styled(Container)`
  padding: 80px;

  margin-top: 10%;
  & img {
    margin-bottom: 30px;
    width: 120px;
    height: 120px;
  }

  & h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    text-align: center;
    margin-bottom: 5px;
  }

  & p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    color: ${theme.neutral6};
    text-align: center;
    margin-bottom: 30px;
  }
`;

const SetDefaultNameservers = ({ domain, onSetDefaultNameservers }) => (
  <Translate>
    {({ translate }) => (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 offset-md-3">
          <StyledSetDefaultNameservers>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 text-center justify-content-center">
                <img src={graphicsNoDNS} alt="" />
                <h4>{translate("domain.zone-file.cannot-create-dns-zone")}</h4>
                <p>
                  {translate(
                    "domain.zone-file.cannot-create-dns-zone-description"
                  )}
                </p>
                { ( !domain.domain_name.endsWith(".al") && !domain.domain_name.endsWith(".rs") ) ? (
                  <PrimaryButton onClick={() => onSetDefaultNameservers(domain)}>
                    {translate("domain.zone-file.set-default-nameservers")}
                  </PrimaryButton>
                ) : (
                  <Link to={ROUTE_CREATE_TICKET}>
                    <PrimaryButton>
                      {translate("help-desk.ticket.open-new-ticket")}
                    </PrimaryButton>
                  </Link>
                )}
              </div>
            </div>
          </StyledSetDefaultNameservers>
        </div>
      </div>
    )}
  </Translate>
);

export default SetDefaultNameservers;
