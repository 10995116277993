import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import DomainLock from "./DomainLock";
import DomainTransfer from "./DomainTransfer";
import Nameservers from "./Nameservers";
import DomainRedirect from "./DomainRedirect";

import {
  getDomainSettings,
  isFetchingDomainNameServers,
  isFetchingDomainSettings
} from "../../../Customer/reducers/customerReducer";
import { fetchDomainSettings } from "../../../Customer/actions/customerActions";

import DomainLockLoader from "../../../../components/Loaders/DomainLockLoader";
import ContentLoader from "../../../../components/Loaders/ContentLoader";
import withLoading from "../../../../components/Loaders/WithLoading";

const NameServersWithLoader = withLoading(Nameservers, ContentLoader);
const DomainRedirectWithLoading = withLoading(DomainRedirect, ContentLoader);

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetchingDomainSettings: false
    };
  }

  componentDidMount() {
    if (!this.props.settings && !this.props.isFetchingDomainSettings) {
      this.setState({ isFetchingDomainSettings: true });
      this.props
        .fetchDomainSettings(this.props.domain)
        .finally(() => this.setState({ isFetchingDomainSettings: false }));
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-4">
          <NameServersWithLoader
            isLoading={this.props.isFetchingDomainNameServers}
            domain={this.props.domain}
            onUseDefaultNameservers={this.props.onUseDefaultNameservers}
            onUseCustomNameservers={this.props.onUseCustomNameservers}
            onUseChildNameservers={this.props.onUseChildNameservers}
            onSetNameserversType={this.props.onSetNameserversType}
            onSaveNameservers={this.props.onSaveNameservers}
            onTriggerNameserversForm={this.props.onTriggerNameserversForm}
            onSubmittingNameservers={this.props.onSubmittingNameservers}
            validateNameservers={this.props.validateNameservers}
            submittingNameservers={this.props.submittingNameservers}
            nameserversInvalid={this.props.nameserversInvalid}
            nameservers={this.props.nameservers}
            previousNameservers={this.props.previousNameservers}
            onNameServersKeyPress={this.props.onNameServersKeyPress}
          />
        </div>
        <div className="col-12 col-sm-12 col-md-4">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              {(this.state.isFetchingDomainSettings ||
                this.props.isFetchingDomainSettings) && <DomainLockLoader />}
              {!this.state.isFetchingDomainSettings &&
                !this.props.isFetchingDomainSettings &&
                this.props.settings && (
                  <DomainLock
                    domainLocked={this.props.settings.is_locked}
                    onUnlockDomain={this.props.onUnlockDomain}
                    onLockDomain={this.props.onLockDomain}
                    disableDomainUnlocking={
                      this.props.isMkDomain || this.props.disableDomainUnlocking
                    }
                    submitting={this.props.disableDomainUnlocking}
                    isMkDomain={this.props.isMkDomain}
                    domain={this.props.domain}
                  />
                )}
            </div>
            <div className="col-12 col-sm-12 col-md-12">
              <DomainTransfer
                onGetEppTransferCode={this.props.onGetEppTransferCode}
                eppTransferCode={this.props.eppTransferCode}
                fetchingEppTransferCode={this.props.fetchingEppTransferCode}
                domain={this.props.domain}
              />
            </div>
          </div>
        </div>

        <div className="col-12 col-sm-12 col-md-4">
          <DomainRedirectWithLoading
            domain={this.props.domain}
            isLoading={
              this.props.isFetchingDomainRedirectionAvailable ||
              this.props.isFetchingDomainRedirection
            }
            isDomainRedirectionAvailable={
              this.props.isDomainRedirectionAvailable
            }
            domainRedirectionReason={this.props.domainRedirectionReason}
            domainRedirection={this.props.domainRedirection}
            validate={this.props.validateDomainRedirect}
            onUpdateDomainRedirect={this.props.onUpdateDomainRedirect}
            onRemoveDomainRedirect={this.props.onRemoveDomainRedirect}
            isUpdatingDomainRedirection={this.props.isUpdatingDomainRedirection}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    settings: getDomainSettings(state, ownProps.domain),
    isFetchingDomainNameServers: isFetchingDomainNameServers(state),
    isFetchingDomainSettings: isFetchingDomainSettings(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ fetchDomainSettings }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
