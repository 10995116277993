import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { withLocalize } from "react-localize-redux";
import parse from "html-react-parser";

import InputField from "../../../../components/InputField";
import NoticeWarning from "../../../../components/Notices/NoticeWarning";

const StyledAppName = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  color: ${theme.neutral6};
  margin-bottom: 30px;
`;

const StyledFieldsWrapper = styled.div`
  margin-bottom: 20px;
`;

const NewAppOneTimeDetails = ({
  clientId,
  clientSecret,
  client,
  translate
}) => (
  <div className="row">
    <div className="col-12 col-sm-12 col-md-12">
      <StyledAppName>{client.name}</StyledAppName>
      <StyledFieldsWrapper>
        <InputField
          name="client_id"
          label="ID"
          onChange={() => {}}
          value={clientId}
        />
        <InputField
          name="client_secret"
          label="Secret"
          onChange={() => {}}
          value={clientSecret}
        />
      </StyledFieldsWrapper>
      <NoticeWarning>
        {parse(translate("my-account.apps.new-app-notice"))}
      </NoticeWarning>
    </div>
  </div>
);

export default withLocalize(NewAppOneTimeDetails);
