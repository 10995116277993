import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Link } from "react-router-dom";

const StyledTab = styled.li`
  cursor: pointer;

  & .nav-link {
    font-size: 14px;
    font-weight: 600;
    color: ${theme.neutral5};
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
    margin-left: 15px;
    margin-right: 15px;
    border: 0;
    text-transform: uppercase;
    border-bottom: 2px solid transparent;
  }

  & .nav-link:hover {
    border-bottom: 2px solid ${theme.neutral3};
  }
  & .nav-link.active {
    color: ${theme.neutralBase};
    background-color: transparent;
    border-bottom: 2px solid ${theme.blueBase};
  }

  &:first-child .nav-link {
    margin-left: 0;
  }
`;

const Tab = ({ children, label, active, onSelect, linkTo }) => (
  <StyledTab className="nav-item">
    {onSelect && typeof onSelect === "function" && !linkTo ? (
      <span
        className={`nav-link ${active === label ? "active" : ""}`}
        onClick={e => onSelect(label)}
      >
        {children}
      </span>
    ) : (
      <Link
        to={linkTo}
        className={`nav-link ${active === linkTo ? "active" : ""}`}
      >
        {children}
      </Link>
    )}
  </StyledTab>
);

export default Tab;
