import React from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";

import graphicsNoDNS from "../../../../../assets/images/icon-illustration-empty.png";

import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import Container from "../../../../../components/Containers/Container";

const StyledNoZoneFile = styled(Container)`
  padding: 80px;

  margin-top: 10%;
  & img {
    margin-bottom: 30px;
    width: 120px;
    height: 120px;
  }

  & h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    text-align: center;
    margin-bottom: 5px;
  }

  & p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    color: ${theme.neutral6};
    text-align: center;
    margin-bottom: 30px;
  }
`;

const NoZoneFile = ({ domain, onCreateZoneFile }) => (
  <Translate>
    {({ translate }) => (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-6 offset-md-3">
          <StyledNoZoneFile>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 text-center justify-content-center">
                <img src={graphicsNoDNS} alt="" />
                <h4>{translate("domain.zone-file.no-dns-zone")}</h4>
                <p>{translate("domain.zone-file.no-dns-zone-description")}</p>
                <PrimaryButton onClick={() => onCreateZoneFile(domain)}>
                  {translate("domain.zone-file.create-dns-zone")}
                </PrimaryButton>
              </div>
            </div>
          </StyledNoZoneFile>
        </div>
      </div>
    )}
  </Translate>
);

export default NoZoneFile;
