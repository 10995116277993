import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { debounce } from "throttle-debounce";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";

import withLoading from "../../../../components/Loaders/WithLoading";
import TableContentLoader from "../../../../components/Loaders/TableContentLoader";
import Container from "../../../../components/Containers/Container";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import Form from "../../../../components/Forms/Form";
import SearchField from "../../../../components/SearchField";
import Checkbox from "../../../../components/Checkbox";
import AmountBadge from "../../../../components/AmountBadge";
import ButtonGroupContentDropdown from "../../../../components/ButtonGroupContentDropdown";

import Table from "../../../../components/Table";
import TableHeader from "../../../../components/Table/TableHeader";
import TableHeading from "../../../../components/Table/TableHeading";
import TableBody from "../../../../components/Table/TableBody";
import TableRow from "../../../../components/Table/TableRow";
import TableCell from "../../../../components/Table/TableCell";

import ProInvoicesFilter from "../ProInvoicesFilter";
import { fetchProInvoices } from "../../ProInvoices/actions/proInvoicesActions";

import {
  compileRoute,
  ROUTE_BILLING_VIEW_PROINVOICE
} from "../../../../routes/routes";

import getBadgeForStatus from "../../../../utils/getBadgeForStatus";
import getStatusTextForStatus from "../../../../utils/getStatusTextForStatus";

import Pagination from "../../../../components/Pagination";
import {
  SORT_ORDER_DESCENDING,
  SORT_ORDER_ASCENDING
} from "../../../../types/SortTypes";

import { PROINVOICE_UNPAID } from "../../ProInvoices/ProInvoicesStatus/ProInvoicesStatus";

const StyledProInvoicesListWrapper = styled.div`
  margin-bottom: ${props => (props.isMobile ? "50px" : "30px")};
`;

const StyledProInvoicesList = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;

  & .hosting-list-header {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
  }

  //& .hosting-list-header button.purchase-hosting {
  //  margin-right: 0;
  //}
`;

const StyledProInvoicesListContainer = styled.div`
  margin-bottom: 50px;
  & a.proinvoice-link {
    font-size: 16px;
  }

  & a.proinvoice-link-block {
    display: block;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }
`;

const StyledTableTitle = styled.h4`
  padding-left: 25px;
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
`;

class ProInvoicesList extends React.Component {
  constructor(props) {
    super(props);

    this.state = { searchQuery: props.filters.keyword };

    this.viewProInvoicePath = compileRoute(ROUTE_BILLING_VIEW_PROINVOICE);
    this.searchProInvoicesByKeywordThrottled = debounce(
      1000,
      this.searchProInvoicesByKeyword
    );
  }

  handlePaginationClick = (event, page) => {
    event.preventDefault();

    if (page <= 0 || page > this.props.paging.total_pages) {
      return;
    }

    this.props.fetchProInvoices(
      this.props.paging.limit,
      page,
      this.props.sort,
      this.props.filters
    );
  };

  onPerPageOptionChanged = value => {
    this.props.fetchProInvoices(
      value.value,
      1,
      this.props.sort,
      this.props.filters
    );
  };

  searchProInvoicesByKeyword = value => {
    this.props.fetchProInvoices(this.props.paging.limit, 1, this.props.sort, {
      ...this.props.filters,
      keyword: value
    });
  };

  onChangeKeywordSearch = event => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.searchProInvoicesByKeywordThrottled(this.state.searchQuery);
    });
  };

  changeListOrderTrigger = (sortBy, orderBy) => {
    this.props.fetchProInvoices(
      this.props.paging.limit,
      this.props.paging.current_page,
      {
        sort_by: sortBy,
        order_by:
          orderBy === SORT_ORDER_DESCENDING
            ? SORT_ORDER_ASCENDING
            : SORT_ORDER_DESCENDING
      },
      this.props.filters
    );
  };

  render() {
    const StyledProInvoicesTableWithLoading = withLoading(
      StyledProInvoicesListContainer,
      TableContentLoader
    );

    const searchLayout = isMobileOnly
      ? "col-12 col-sm-12 col-md-12"
      : "col-6 col-sm-6 col-md-6";

    const filtersApplied = this.props.countFiltersApplied();

    return (
      <Translate>
        {({ translate }) => (
          <StyledProInvoicesListWrapper>
            <Container>
              <StyledProInvoicesList>
                <div className="hosting-list-header">
                  <div className="row">
                    <div className={searchLayout}>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                        }}
                      >
                        <SearchField
                          onChange={this.onChangeKeywordSearch}
                          name="search"
                          value={this.state.searchQuery}
                          placeholder={translate(
                            "pro-invoices.table.search-placeholder"
                          )}
                        />
                      </Form>
                    </div>
                    <div className={`${searchLayout} text-right`}>
                      {!isMobileOnly && (
                        <PrimaryButton
                          disabled={this.props.isPerformingMasspay}
                          submitting={this.props.isPerformingMasspay}
                          onClick={e => this.props.payAllUnpaid()}
                          className="purchase-hosting"
                        >
                          {translate("pro-invoices.table.pay-all-unpaid")}
                        </PrimaryButton>
                      )}

                      {this.props.isLaptop && (
                        <ButtonGroupContentDropdown
                          text={`${translate("pro-invoices.filter.title")}  ${
                            filtersApplied > 0 ? " - " + filtersApplied : ""
                          }`}
                          size="m"
                          alignMenu="right"
                        >
                          <ProInvoicesFilter
                            paging={this.props.paging}
                            statusCollection={this.props.statusCollection}
                            sort={this.props.sort}
                            filters={this.props.filters}
                            payersForFilter={this.props.payersForFilter}
                            filterPayerOptions={this.props.filterPayerOptions}
                            onPayerFilterOptionChange={
                              this.props.onPayerFilterOptionChange
                            }
                            countFiltersApplied={this.props.countFiltersApplied}
                          />
                        </ButtonGroupContentDropdown>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <StyledTableTitle>
                      {translate("pro-invoices.table.pro-invoices")}
                    </StyledTableTitle>
                    <StyledProInvoicesTableWithLoading
                      isLoading={this.props.isLoading}
                    >
                      <Table>
                        {isBrowser && (
                          <TableHeader>
                            {this.props.selectedRowsCount === 0 ? (
                              <TableRow>
                                <TableHeading
                                  className="row-selector"
                                  width="5%"
                                >
                                  <Checkbox
                                    input={{
                                      onChange: e => this.props.selectAllRows()
                                    }}
                                  />
                                </TableHeading>
                                <TableHeading
                                  width="21%"
                                  isSortable={true}
                                  sortBy="id"
                                  isDefaultSort={
                                    this.props.sort.sort_by === "id"
                                  }
                                  sortDirection={this.props.sort.order_by}
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("pro-invoices.table.pro-invoice")}
                                </TableHeading>
                                <TableHeading
                                  width="13%"
                                  isSortable={true}
                                  sortBy="created_at"
                                  isDefaultSort={
                                    this.props.sort.sort_by === "created_at"
                                  }
                                  sortDirection={this.props.sort.order_by}
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("pro-invoices.table.created")}
                                </TableHeading>
                                <TableHeading
                                  width="13%"
                                  isSortable={true}
                                  sortBy="due_date"
                                  isDefaultSort={
                                    this.props.sort.sort_by === "due_date"
                                  }
                                  sortDirection={this.props.sort.order_by}
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("pro-invoices.table.due")}
                                </TableHeading>
                                <TableHeading
                                  width="13%"
                                  isSortable={true}
                                  sortBy="paid_on_date"
                                  isDefaultSort={
                                    this.props.sort.sort_by === "paid_on_date"
                                  }
                                  sortDirection={this.props.sort.order_by}
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("pro-invoices.table.paid-on")}
                                </TableHeading>
                                <TableHeading
                                  width="15%"
                                  isSortable={true}
                                  sortBy="status"
                                  isDefaultSort={
                                    this.props.sort.sort_by === "status"
                                  }
                                  sortDirection={this.props.sort.order_by}
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("pro-invoices.table.status")}
                                </TableHeading>
                                <TableHeading
                                  className="text-right"
                                  width="15%"
                                  isSortable={true}
                                  sortBy="amount"
                                  isDefaultSort={
                                    this.props.sort.sort_by === "amount"
                                  }
                                  sortDirection={this.props.sort.order_by}
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("pro-invoices.table.amount")}
                                </TableHeading>
                                <TableHeading width="10%" />
                              </TableRow>
                            ) : (
                              <TableRow>
                                <TableHeading
                                  className="row-selector"
                                  width="5%"
                                >
                                  <Checkbox
                                    input={{
                                      onChange: e => this.props.deselectRows(),
                                      value:
                                        this.props.selectedRowsCount ===
                                        this.props.all.length
                                    }}
                                    indeterminate={
                                      this.props.selectedRowsCount !==
                                      this.props.all.length
                                    }
                                  />
                                </TableHeading>
                                <TableHeading colSpan={5}>
                                  <span>
                                    {this.props.selectedRowsCount}{" "}
                                    {translate("pro-invoices.table.selected")}
                                  </span>
                                  <SecondaryButton
                                    style={{ marginLeft: "10px" }}
                                    size="s"
                                    disabled={this.props.isPerformingMasspay}
                                    submitting={this.props.isPerformingMasspay}
                                    onClick={e => this.props.massPay()}
                                  >
                                    {translate("pro-invoices.table.pay-now")}
                                  </SecondaryButton>
                                </TableHeading>
                              </TableRow>
                            )}
                          </TableHeader>
                        )}
                        <TableBody>
                          {this.props.all.map(proinvoice => {
                            const StatusComponent = getStatusTextForStatus(
                              proinvoice.status.state
                            );
                            const BadgeComponent = getBadgeForStatus(
                              proinvoice.status.state
                            );
                            return (
                              <TableRow key={`proinvoice-${proinvoice.id}`}>
                                {isBrowser && (
                                  <TableCell
                                    className="row-selector"
                                    width="5%"
                                  >
                                    <Checkbox
                                      input={{
                                        onChange: e =>
                                          this.props.onRowSelectionChanged(
                                            proinvoice
                                          ),
                                        value: proinvoice.selected
                                      }}
                                    />
                                  </TableCell>
                                )}
                                <TableCell width="21%">
                                  <Link
                                    className={
                                      isTablet || isMobileOnly
                                        ? "proinvoice-link proinvoice-link-block"
                                        : "proinvoice-link"
                                    }
                                    to={this.viewProInvoicePath({
                                      id: proinvoice.id
                                    })}
                                  >
                                    #{proinvoice.id}
                                  </Link>{" "}
                                  {(isTablet || isMobileOnly) && (
                                    <StatusComponent>
                                      {proinvoice.status.code ===
                                      PROINVOICE_UNPAID
                                        ? proinvoice.status.overdue_days > 0
                                          ? translate(
                                              "pro-invoices.table.invoice-statuses.not-paid-matures",
                                              {
                                                days: Math.abs(
                                                  proinvoice.status.overdue_days
                                                )
                                              }
                                            )
                                          : translate(
                                              "pro-invoices.table.invoice-statuses.not-paid",
                                              {
                                                days: Math.abs(
                                                  proinvoice.status.overdue_days
                                                )
                                              }
                                            )
                                        : translate(
                                            "pro-invoices.table.invoice-statuses." +
                                              proinvoice.status.code
                                          )}
                                    </StatusComponent>
                                  )}
                                </TableCell>
                                {isBrowser && (
                                  <TableCell width="13%">
                                    {proinvoice.created_at}
                                  </TableCell>
                                )}
                                {isBrowser && (
                                  <TableCell width="13%">
                                    {proinvoice.due_date}
                                  </TableCell>
                                )}
                                {isBrowser && (
                                  <TableCell width="13%">
                                    {proinvoice.date_paid}
                                  </TableCell>
                                )}
                                {isBrowser && (
                                  <TableCell width="15%">
                                    <BadgeComponent>
                                      {proinvoice.status.code ===
                                      PROINVOICE_UNPAID
                                        ? proinvoice.status.overdue_days > 0
                                          ? translate(
                                              "pro-invoices.table.invoice-statuses.not-paid-matures",
                                              {
                                                days: Math.abs(
                                                  proinvoice.status.overdue_days
                                                )
                                              }
                                            )
                                          : translate(
                                              "pro-invoices.table.invoice-statuses.not-paid",
                                              {
                                                days: Math.abs(
                                                  proinvoice.status.overdue_days
                                                )
                                              }
                                            )
                                        : translate(
                                            "pro-invoices.table.invoice-statuses." +
                                              proinvoice.status.code
                                          )}
                                    </BadgeComponent>
                                  </TableCell>
                                )}
                                <TableCell width="15%">
                                  <AmountBadge
                                    currency={proinvoice.currency.code}
                                    amount={proinvoice.total}
                                  />
                                </TableCell>

                                {!isMobileOnly && (
                                  <TableCell width="10%" className="text-right">
                                    {proinvoice.status.code === "unpaid" &&
                                      isBrowser && (
                                        <Link
                                          to={this.viewProInvoicePath({
                                            id: proinvoice.id
                                          })}
                                        >
                                          <SecondaryButton
                                            size="s"
                                            to={this.viewProInvoicePath({
                                              id: proinvoice.id
                                            })}
                                          >
                                            {translate(
                                              "pro-invoices.table.pay-now"
                                            )}
                                          </SecondaryButton>
                                        </Link>
                                      )}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </StyledProInvoicesTableWithLoading>
                  </div>
                </div>
                <Pagination
                  itemsName={translate("pro-invoices.table.pro-invoices")}
                  paging={this.props.paging}
                  handlePaginationClick={this.handlePaginationClick}
                  onPerPageOptionChanged={this.onPerPageOptionChanged}
                />
              </StyledProInvoicesList>
            </Container>
          </StyledProInvoicesListWrapper>
        )}
      </Translate>
    );
  }
}

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProInvoices
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(ProInvoicesList);
