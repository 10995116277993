import { ROUTE_LOGIN } from "../../../routes/routes";
import * as authService from "../../../services/authService";
import * as cartService from "../../../services/cartService";
import * as localizationService from "../../../services/localizationService";

import { appStateCompleteReset } from "../../AppState/actions/appStateActions";

export const AUTH_FEATURE = "features/Auth";
export const AUTH_LOGIN_REQUEST = `${AUTH_FEATURE}/AUTH_LOGIN_REQUEST`;
export const AUTH_LOGIN_RECEIVE = `${AUTH_FEATURE}/AUTH_LOGIN_RECEIVE`;
export const AUTH_LOGIN_FAILURE = `${AUTH_FEATURE}/AUTH_LOGIN_FAILURE`;
export const AUTH_SET_USER = `${AUTH_FEATURE}/AUTH_SET_USER`;
export const AUTH_VALIDATE_RESET_TOKEN = `${AUTH_FEATURE}/AUTH_VALIDATE_RESET_TOKEN`;
export const AUTH_LOGOUT = `${AUTH_FEATURE}/AUTH_LOGOUT`;
export const AUTH_FETCH_FINISH = `${AUTH_FEATURE}/AUTH_FETCH_FINISH`;

export const AUTH_REQUEST_PHONE_NUMBER = `${AUTH_FEATURE}/AUTH_REQUEST_PHONE_NUMBER`;
export const AUTH_TWOFACTOR_ENABLED = `${AUTH_FEATURE}/AUTH_TWOFACTOR_ENABLED`;

export const AUTH_TWOFACTOR_SETUP_LATER = `${AUTH_FEATURE}/AUTH_TWOFACTOR_SETUP_LATER`;

export function login(
  email,
  password,
  onSuccessRedirectTo,
  onSucessRedirectBack,
  recaptcha = null,
  register
) {
  return dispatch => {
    dispatch(loginRequest());
    return authService
      .login(email, password, recaptcha)
      .then(
        res => {
         
          if(register){
            const {
              token,
              "2fa": twoFactor,
              "2fa_phone_number": twoFactorPhoneNumber
            } = res.data.data;
            authService.setToken(token);
            dispatch(loginReceive(token));

          }else{
            const {
              token,
              "2fa": twoFactor,
              "2fa_phone_number": twoFactorPhoneNumber
            } = res.data.data;
  
            dispatch(loginTwoFactorEnabled(twoFactor, twoFactorPhoneNumber));
  
            localizationService.setApplicationLocale(
              localizationService.getApplicationLocale()
            );
  
            if (!twoFactor) {
              authService.setToken(token);
  
              dispatch(loginRequestPhoneNumber(token));
            }
  
            // only applies to default Login flow
            if (twoFactor && onSuccessRedirectTo) {
              onSuccessRedirectTo(`${ROUTE_LOGIN}?code=${token}`);
            }
  
            return {
              token,
              twoFactor,
              twoFactorPhoneNumber
            };
          }


          // if (onSucessRedirectBack) {
          //   window.location.href = "/two-factor-authentication";
          // }
          //if (onSuccessRedirectTo) {
          //  onSuccessRedirectTo("/");
          //}
        },
        err => {
          dispatch(loginFailed());
          throw err;
        }
      )
      .catch(err => {
        dispatch(loginFailed());
        throw err;
      });
  };
}

export function twoFactorLogin(token) {
  return dispatch => dispatch(loginReceive(token));
}

export function twoFactorSetupLater() {
  return dispatch => dispatch(loginTwoFactorSetupLater());
}

export function deferredLogin(email, password) {
  return dispatch => {
    dispatch(loginRequest());
    return authService
      .login(email, password)
      .then(res => {
        const { token } = res.data.data;
        authService.setToken(token);
        localizationService.setApplicationLocale(
          localizationService.getApplicationLocale()
        );
        dispatch(loginReceive(token));
      })
      .catch(err => {
        throw err.response;
      });
  };
}

export function register(email, password, cart, recaptcha = null) {
  return dispatch => {
    return authService
      .register(email, password, cart, recaptcha)
      .then(result => {
        const { customer, token } = result.data.data;
        authService.setAuthorizationToken(token);
        return customer;
      })
      .catch(err => {
        throw err.response;
      });
  };
}

export function validateResetToken(token) {
  return dispatch => {
    return authService
      .validateResetToken(token)
      .then(result => {
        return !result.data.error;
      })
      .catch(error => {
        return false;
      });
  };
}

export function updateRegisterDetails(details) {
  return dispatch => {
    return authService.updateRegisterDetails(details).then(result => {
      const { customer } = result.data.data;
      return customer;
    });
  };
}

export function logout() {
  return dispatch => {
    try {
      authService.removeToken();
      // cartService.removeCartId();
      authService.setAuthorizationToken(null);
      dispatch(logoutUser());
      dispatch(appStateCompleteReset());
      //window.location.reload(true);
    } catch (e) {
      authService.setAuthorizationToken(null);
    }
  };
}

export function forgotPassword(email, recaptcha) {
  return dispatch => {
    return authService.forgotPassword(email, recaptcha);
  };
}

export function resetPassword(password, confirm_password, token, recaptcha) {
  return dispatch => {
    return authService.resetPassword(
      token,
      password,
      confirm_password,
      recaptcha
    );
  };
}

export function refreshToken() {
  return dispatch => {
    //dispatch(loginRequest());
    //const token = authService.getRefreshToken();
    //if (token) {
    //  authService
    //    .refreshToken(token)
    //    .then(res => {
    //      const { token, refresh_token } = res.data;
    //      authService.setToken({ token, refresh_token });
    //      getMe()
    //        .then(res => {
    //          const { user } = res.data;
    //          dispatch(authSetUser(user));
    //          dispatch(loginReceive());
    //        })
    //        .catch(err => {
    //          dispatch(
    //            loginFailed("Your session has expired. Please log in again.")
    //          );
    //          dispatch(logout());
    //        });
    //    })
    //    .catch(err => {
    //      dispatch(
    //        loginFailed("Your session has expired. Please log in again.")
    //      );
    //      dispatch(logout());
    //    });
    //}
  };
}

export function getMe() {
  //return new Promise(resolve => {
  //  resolve(usersService.me());
  //});
}

export function authSetUser(user) {
  return {
    type: AUTH_SET_USER,
    payload: user
  };
}

export function authFetchFinish() {
  return {
    type: AUTH_FETCH_FINISH
  };
}

export function loginRequest() {
  return {
    type: AUTH_LOGIN_REQUEST
  };
}

export function loginReceive(token) {
  return {
    type: AUTH_LOGIN_RECEIVE,
    payload: token
  };
}

export function loginRequestPhoneNumber(token) {
  return {
    type: AUTH_REQUEST_PHONE_NUMBER,
    payload: {
      token
    }
  };
}

export function loginTwoFactorEnabled(enabled, twoFactorPhoneNumber) {
  return {
    type: AUTH_TWOFACTOR_ENABLED,
    payload: { enabled, twoFactorPhoneNumber }
  };
}

export function loginTwoFactorSetupLater() {
  return {
    type: AUTH_TWOFACTOR_SETUP_LATER
  };
}

export function logoutUser() {
  return {
    type: AUTH_LOGOUT
  };
}

export function loginFailed(err) {
  return {
    type: AUTH_LOGIN_FAILURE,
    payload: err
  };
}
