import * as helpdeskService from "../../../services/helpdeskService.js";

export const HELPDESK_FEATURE = "features/HelpDesk";

export const HELPDESK_KNOWLEDGE_BASE_CATEGORIES_REQUEST = `${HELPDESK_FEATURE}/HELPDESK_KNOWLEDGE_BASE_CATEGORIES_REQUEST`;
export const HELPDESK_KNOWLEDGE_BASE_CATEGORIES_RECEIVE = `${HELPDESK_FEATURE}/HELPDESK_KNOWLEDGE_BASE_CATEGORIES_RECEIVE`;

export const HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_REQUEST = `${HELPDESK_FEATURE}/HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_REQUEST`;
export const HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_RECEIVE = `${HELPDESK_FEATURE}/HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_RECEIVE`;

export const HELPDESK_KNOWLEDGE_BASE_ARTICLE_REQUEST = `${HELPDESK_FEATURE}/HELPDESK_KNOWLEDGE_BASE_ARTICLE_REQUEST`;
export const HELPDESK_KNOWLEDGE_BASE_ARTICLE_RECEIVE = `${HELPDESK_FEATURE}/HELPDESK_KNOWLEDGE_BASE_ARTICLE_RECEIVE`;
export const HELPDESK_KNOWLEDGE_BASE_VIEW_ARTICLE = `${HELPDESK_FEATURE}/HELPDESK_KNOWLEDGE_BASE_VIEW_ARTICLE`;
export const HELPDESK_KNOWLEDGE_BASE_SEARCH_ARTICLES_BY_KEYWORD_REQUEST = `${HELPDESK_FEATURE}/HELPDESK_KNOWLEDGE_BASE_SEARCH_ARTICLES_BY_KEYWORD_REQUEST`;
export const HELPDESK_KNOWLEDGE_BASE_SEARCH_ARTICLES_BY_KEYWORD_RECEIVE = `${HELPDESK_FEATURE}/HELPDESK_KNOWLEDGE_BASE_SEARCH_ARTICLES_BY_KEYWORD_RECEIVE`;

export const HELPDESK_TICKETS_REQUEST_DEPARTMENTS = `${HELPDESK_FEATURE}/TICKETS_REQUEST_DEPARTMENTS`;
export const HELPDESK_TICKETS_RECEIVE_DEPARTMENTS = `${HELPDESK_FEATURE}/TICKETS_RECEIVE_DEPARTMENTS`;

export const HELPDESK_TICKETS_REQUEST_RELATED_PRODUCTS = `${HELPDESK_FEATURE}/TICKETS_REQUEST_RELATED_PRODUCTS`;
export const HELPDESK_TICKETS_RECEIVE_RELATED_PRODUCTS = `${HELPDESK_FEATURE}/TICKETS_RECEIVE_RELATED_PRODUCTS`;

export const HELPDESK_TICKETS_REQUEST = `${HELPDESK_FEATURE}/HELPDESK_TICKETS_REQUEST`;
export const HELPDESK_TICKETS_RECEIVE = `${HELPDESK_FEATURE}/HELPDESK_TICKETS_RECEIVE`;
export const HELPDESK_TICKET_RECEIVE = `${HELPDESK_FEATURE}/HELPDESK_TICKET_RECEIVE`;
export const HELPDESK_TICKETS_VIEW_TICKET = `${HELPDESK_FEATURE}/HELPDESK_TICKETS_VIEW_TICKET`;
export const HELPDESK_TICKETS_UPDATE_TICKET = `${HELPDESK_FEATURE}/HELPDESK_TICKETS_UPDATE_TICKET`;
export const HELPDESK_TICKETS_TICKET_NOT_FOUND = `${HELPDESK_FEATURE}/HELPDESK_TICKETS_TICKET_NOT_FOUND`;

export function fetchKnowledgeBaseArticle(id) {
  return dispatch => {
    dispatch(requestKnowledgeBaseArticle());
    return helpdeskService.fetchKnowledgeBaseArticle({ id }).then(result => {
      const { article } = result.data.data;
      dispatch(receiveKnowledgeBaseArticle(article));
      return article;
    });
  };
}

export function searchKnowledgeBaseArticles(keyword) {
  return dispatch => {
    dispatch(requestKnowledgeBaseArticlesByKeyword());
    return helpdeskService
      .fetchKnowledgeBaseArticlesByKeyword(keyword)
      .then(result => {
        const { articles } = result.data.data;
        dispatch(receiveKnowledgeBaseArticlesByKeyword(articles));
      });
  };
}

export function fetchHelpDeskKnowledgeBaseCategories() {
  return dispatch => {
    dispatch(requestHelpDeskKnowledgeBaseCategories());
    return helpdeskService
      .fetchRequestHelpDeskKnowledgeBaseCategories()
      .then(
        result => {
          const { categories } = result.data.data;
          dispatch(receiveHelpDeskKnowledgeBaseCategories(categories));
        },

        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchHelpDeskKnowledgeBaseMostPopularArticles() {
  return dispatch => {
    dispatch(requestHelpDeskKnowledgeBaseMostPopularArticles());
    return helpdeskService
      .fetchRequestHelpDeskKnowledgeBaseMostPopularArticles()
      .then(
        result => {
          const { articles } = result.data.data;
          dispatch(receiveHelpDeskKnowledgeBaseMostPopularArticles(articles));
        },

        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchTickets(limit, page, sort, filters) {
  return dispatch => {
    dispatch(requestTickets());
    return helpdeskService
      .fetchTickets(limit, page, sort, filters)
      .then(result => {
        if (result && result.data && result.data.data) {
          const { tickets, paging, filters, sort } = result.data.data;
          dispatch(receiveTickets(tickets, paging, filters, sort));
        }
      });
  };
}

export function fetchAttachment(attachmentSource, avoidBuffering) {
  return dispatch => {
    return helpdeskService.fetchAttachment(attachmentSource).then(result => {
      if (!avoidBuffering) {
        let buffer = new Buffer(result.data, "binary");
        return buffer.toString("base64");
      }

      return result.data;
    });
  };
}

export function fetchTicket(id) {
  return dispatch => {
    dispatch(requestTickets());
    return helpdeskService
      .fetchTicket(id)
      .then(result => {
        const { ticket } = result.data.data;
        dispatch(receiveTickets([ticket]));
        return ticket;
      })
      .catch(error => {
        if (
          error.response.status === 404 &&
          error.response.data.error === true
        ) {
          dispatch(ticketNotFound());
        }
      });
  };
}

export function closeTicket(id) {
  return dispatch => {
    return helpdeskService
      .closeTicket(id)
      .then(
        result => {
          const { error } = result.data;
          const { messages, ticket } = result.data.data;

          if (!error) {
            dispatch(receiveTicket(ticket));
          }

          return { error, messages, ticket };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

function requestTickets() {
  return {
    type: HELPDESK_TICKETS_REQUEST
  };
}

function receiveTickets(tickets, paging, filters, sort) {
  return {
    type: HELPDESK_TICKETS_RECEIVE,
    payload: { items: tickets, paging, filters, sort }
  };
}

function receiveTicket(ticket) {
  return {
    type: HELPDESK_TICKET_RECEIVE,
    payload: ticket
  };
}

function ticketNotFound() {
  return {
    type: HELPDESK_TICKETS_TICKET_NOT_FOUND
  };
}

function requestHelpDeskKnowledgeBaseCategories() {
  return {
    type: HELPDESK_KNOWLEDGE_BASE_CATEGORIES_REQUEST
  };
}

function receiveHelpDeskKnowledgeBaseCategories(categories) {
  return {
    type: HELPDESK_KNOWLEDGE_BASE_CATEGORIES_RECEIVE,
    payload: categories
  };
}

function requestHelpDeskKnowledgeBaseMostPopularArticles() {
  return {
    type: HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_REQUEST
  };
}

function receiveHelpDeskKnowledgeBaseMostPopularArticles(articles) {
  return {
    type: HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_RECEIVE,
    payload: articles
  };
}

function requestKnowledgeBaseArticle() {
  return {
    type: HELPDESK_KNOWLEDGE_BASE_ARTICLE_REQUEST
  };
}

function receiveKnowledgeBaseArticle(article) {
  return {
    type: HELPDESK_KNOWLEDGE_BASE_ARTICLE_RECEIVE,
    payload: article
  };
}

export function viewArticle(article) {
  return { type: HELPDESK_KNOWLEDGE_BASE_VIEW_ARTICLE, payload: article };
}

function requestKnowledgeBaseArticlesByKeyword() {
  return {
    type: HELPDESK_KNOWLEDGE_BASE_SEARCH_ARTICLES_BY_KEYWORD_REQUEST
  };
}

function receiveKnowledgeBaseArticlesByKeyword(articles) {
  return {
    type: HELPDESK_KNOWLEDGE_BASE_SEARCH_ARTICLES_BY_KEYWORD_RECEIVE,
    payload: articles
  };
}

export function fetchTicketDepartments() {
  return dispatch => {
    dispatch(requestTicketDepartments());
    return helpdeskService.fetchTicketDepartments().then(result => {
      const { support_departments } = result.data.data;
      dispatch(receiveTicketDepartments(support_departments));
    });
  };
}

export function requestTicketDepartments() {
  return {
    type: HELPDESK_TICKETS_REQUEST_DEPARTMENTS
  };
}
export function receiveTicketDepartments(departments) {
  return {
    type: HELPDESK_TICKETS_RECEIVE_DEPARTMENTS,
    payload: departments
  };
}

export function fetchTicketRelatedProducts() {
  return dispatch => {
    dispatch(requestTicketRelatedProducts());
    return helpdeskService.fetchTicketRelatedProducts().then(result => {
      const { related_products } = result.data.data;
      dispatch(receiveTicketRelatedProducts(related_products));
    });
  };
}

export function requestTicketRelatedProducts() {
  return {
    type: HELPDESK_TICKETS_REQUEST_RELATED_PRODUCTS
  };
}
export function receiveTicketRelatedProducts(related_products) {
  return {
    type: HELPDESK_TICKETS_RECEIVE_RELATED_PRODUCTS,
    payload: related_products
  };
}

export function createTicket(ticket) {
  return dispatch => {
    return helpdeskService
      .createTicket(ticket)
      .then(
        result => {
          const { error } = result.data;
          const { messages, ticket } = result.data.data;
          dispatch(receiveTicket(ticket));
          return { error, messages, ticket };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function replyToTicket(ticket, reply) {
  return dispatch => {
    return helpdeskService.replyToTicket(ticket, reply).then(result => {
      const { ticket } = result.data.data;
      dispatch(updateTicket(ticket));
      return ticket;
    });
  };
}

export function voteArticle(article, votes) {
  const prevArticle = article;
  return dispatch => {
    return helpdeskService.voteArticle(article).then(result => {
      const { article } = result.data.data;
      dispatch(receiveKnowledgeBaseArticle(article));
      dispatch(viewArticle(article));
      return {
        alreadyVoted: article.votes === votes
      };
    });
  };
}

export function createTicketFeedback(id, rating, comment, comment_admin) {
  return dispatch => {
    return helpdeskService
      .createTicketFeedback(id, rating, comment, comment_admin)
      .then(
        result => {
          const { error } = result.data;
          const { messages } = result.data.data;
          return { error, messages };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function viewTicket(ticket) {
  return {
    type: HELPDESK_TICKETS_VIEW_TICKET,
    payload: ticket
  };
}

export function updateTicket(ticket) {
  return {
    type: HELPDESK_TICKETS_UPDATE_TICKET,
    payload: ticket
  };
}
