import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";

import Breadcrumb from "../../components/Breadcrumb";
import BreadcrumbItem from "../../components/Breadcrumb/BreadcrumbItem";

import Tabs from "../../components/Tabs";
import Tab from "../../components/Tabs/Tab";
import TabContent from "../../components/Tabs/TabContent";

import ProInvoices from "./ProInvoices";
import Invoices from "./Invoices";

import {
  compileRoute,
  ROUTE_BILLING_VIEW_PROINVOICE,
  ROUTE_BILLING_PROINVOICES,
  ROUTE_BILLING_INVOICES,
  ROUTE_BILLING_VIEW_INVOICE
} from "../../routes/routes";

import {
  fetchProInvoices,
  clearProInvoicesFilters,
  fetchProInvoicesStatusCollection,
  deselectProInvoicesListTableRow,
  selectProInvoicesListTableRow,
  selectAllProInvoicesListTableRows,
  deselectAllProInvoicesListTableRows,
  doMassPay
} from "./ProInvoices/actions/proInvoicesActions";
import {
  getAllProInvoices,
  getProInvoicesPaging,
  getProInvoicesSort,
  getProInvoicesFilters,
  getProInvoicesStatusCollection,
  isFetchingProInvoices,
  isFetchingProInvoicesFilters,
  getProInvoicesListSelectedRowsCount,
  getProInvoicesSelectedRowsIds,
  isPerformingMasspay,
  getAllUnpaidIds
} from "./ProInvoices/reducers/proInvoicesReducer";

import {
  fetchInvoices,
  clearInvoicesFilters,
  deselectInvoicesListTableRow,
  selectInvoicesListTableRow,
  selectAllInvoicesListTableRows,
  deselectAllInvoicesListTableRows
} from "./Invoices/actions/invoicesActions";
import {
  getAllInvoices,
  getInvoicesPaging,
  getInvoicesSort,
  getInvoicesFilters,
  getInvoicesStatusCollection,
  isFetchingInvoices,
  isFetchingInvoicesFilters,
  getInvoicesListSelectedRowsCount,
  getInvoicesSelectedRowsIds
} from "./Invoices/reducers/invoicesReducer";

import { fetchCustomerPayersForFilter } from "../Customer/actions/customerActions";
import { getCustomerPayersForFilter } from "../Customer/reducers/customerReducer";

import displayToastMessageForResponse from "../../utils/displayToastMessageForResponse";

class Billing extends React.Component {
  componentDidMount() {
    this.props.clearProInvoicesFilters();
    this.props.clearInvoicesFilters();

    this.props.fetchProInvoices(
      this.props.pagingProInvoices.limit,
      1,
      this.props.sortProInvoices,
      {}
    );

    if (this.props.allInvoices.length === 0 && !this.props.isFetchingInvoices) {
      this.props.fetchInvoices(
        this.props.pagingInvoices.limit,
        1,
        this.props.sortInvoices,
        {}
      );
    }

    this.props.fetchProInvoicesStatusCollection();
    this.props.fetchCustomerPayersForFilter();

    this.proinvoiceViewRoute = compileRoute(ROUTE_BILLING_VIEW_PROINVOICE);
    this.invoiceViewRoute = compileRoute(ROUTE_BILLING_VIEW_INVOICE);
  }

  onViewProInvoice = id => {
    this.props.history.push(this.proinvoiceViewRoute({ id: id }));
  };

  onViewInvoice = id => {
    this.props.history.push(this.invoiceViewRoute({ id: id }));
  };

  onRowSelectionChanged = proInvoice => {
    proInvoice.selected
      ? this.props.deselectProInvoicesListTableRow(proInvoice)
      : this.props.selectProInvoicesListTableRow(proInvoice);
  };

  selectAllRows = () => {
    this.props.selectAllProInvoicesListTableRows();
  };

  deselectRows = () => {
    this.props.deselectAllProInvoicesListTableRows();
  };

  remapFilterPayers = (value, callback) => {
    const options = this.props.payersForFilter.map(payer => {
      return { label: payer.name, value: payer.code };
    });

    callback(options);
  };

  onPayerFilterOptionChange = selectedOptionValue => {
    this.props.fetchProInvoices(
      this.props.pagingProInvoices.limit,
      1,
      this.props.sortProInvoices,
      {
        ...this.props.filtersProInvoices,
        payer: selectedOptionValue.value
      }
    );
  };

  countFiltersApplied = () => {
    return Object.entries(this.props.filtersProInvoices).reduce(
      (accumulator, item) => {
        return item[0] !== "keyword" && item[1] !== ""
          ? ++accumulator
          : accumulator;
      },
      0
    );
  };

  countInvoiceFiltersApplied = () => {
    return Object.entries(this.props.filtersInvoices).reduce(
      (accumulator, item) => {
        return item[0] !== "keyword" && item[1] !== ""
          ? ++accumulator
          : accumulator;
      },
      0
    );
  };

  massPay = () => {
    if (this.props.selectedRowsCount > 0) {
      this.props.doMassPay(this.props.selectedInvoicesIds).then(response => {
        displayToastMessageForResponse(
          this.props.translate("pro-invoices.pro-invoice"),
          response
        );

        if (response.invoice && response.invoice.id > 0) {
          this.props.history.push(
            this.proinvoiceViewRoute({ id: response.invoice.id })
          );
        }
      });

      return;
    }

    displayToastMessageForResponse(
      this.props.translate("pro-invoices.pro-invoice"),
      {
        error: true,
        messages: ["Please select items from list!"]
      }
    );
  };

  payAllUnpaid = () => {
    if (this.props.allUnpaidIds.length > 0) {
      this.props.doMassPay(this.props.allUnpaidIds).then(response => {
        displayToastMessageForResponse(
          this.props.translate("pro-invoices.pro-invoice"),
          response
        );

        if (response.invoice && response.invoice.id > 0) {
          this.props.history.push(
            this.proinvoiceViewRoute({ id: response.invoice.id })
          );
        }
      });

      return;
    }

    displayToastMessageForResponse(
      this.props.translate("pro-invoices.pro-invoice"),
      {
        error: true,
        messages: [
          <Translate>
            {({ translate }) => translate("pro-invoices.no-unpaid-invoices")}
          </Translate>
        ]
      }
    );
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <Breadcrumb>
                <BreadcrumbItem to="/" title={translate("breadcrumbs.home")} />
                <BreadcrumbItem title={translate("breadcrumbs.billing")} />
              </Breadcrumb>
              <Tabs
                defaultTab="proinvoices"
                renderTabs={(active, onSelect) => [
                  <Tab
                    key="tab-pro-invoices"
                    label="proinvoices"
                    active={active}
                    onSelect={onSelect}
                    linkTo={ROUTE_BILLING_PROINVOICES}
                  >
                    {translate("pro-invoices.title")}
                  </Tab>,
                  <Tab
                    key="tab-invoices"
                    label="invoices"
                    active={active}
                    onSelect={onSelect}
                    linkTo={ROUTE_BILLING_INVOICES}
                  >
                    {translate("invoices.title")}
                  </Tab>
                ]}
                renderTabContent={active => (
                  <Switch>
                    <Route
                      path={ROUTE_BILLING_PROINVOICES}
                      exact
                      render={() => (
                        <TabContent
                          key="tab-content-proinvoices"
                          label="proinvoices"
                          active={true}
                        >
                          <ProInvoices
                            isLoading={this.props.isFetching}
                            isFilterLoading={this.props.isFetchingFilters}
                            all={this.props.allProInvoices}
                            paging={this.props.pagingProInvoices}
                            sort={this.props.sortProInvoices}
                            statusCollection={
                              this.props.statusCollectionProInvoices
                            }
                            filters={this.props.filtersProInvoices}
                            onViewProInvoice={this.onViewProInvoice}
                            onRowSelectionChanged={this.onRowSelectionChanged}
                            selectAllRows={this.selectAllRows}
                            deselectRows={this.deselectRows}
                            selectedRowsCount={this.props.selectedRowsCount}
                            payersForFilter={this.props.payersForFilter}
                            filterPayerOptions={this.remapFilterPayers}
                            onPayerFilterOptionChange={
                              this.onPayerFilterOptionChange
                            }
                            countFiltersApplied={this.countFiltersApplied}
                            massPay={this.massPay}
                            payAllUnpaid={this.payAllUnpaid}
                            isPerformingMasspay={this.props.isPerformingMasspay}
                          />
                        </TabContent>
                      )}
                    />

                    <Route
                      path={ROUTE_BILLING_INVOICES}
                      exact
                      render={() => (
                        <TabContent
                          key="tab-content-invoices"
                          label="invoices"
                          active={true}
                        >
                          <Invoices
                            isLoading={this.props.isFetchingInvoices}
                            isFilterLoading={
                              this.props.isFetchingInvoiceFilters
                            }
                            all={this.props.allInvoices}
                            paging={this.props.pagingInvoices}
                            sort={this.props.sortInvoices}
                            statusCollection={
                              this.props.statusCollectionInvoices
                            }
                            filters={this.props.filtersInvoices}
                            onViewInvoice={this.onViewInvoice}
                            onRowSelectionChanged={this.onRowSelectionChanged}
                            selectAllRows={this.selectAllRows}
                            deselectRows={this.deselectRows}
                            selectedRowsCount={this.props.selectedRowsCount}
                            payersForFilter={this.props.payersForFilter}
                            filterPayerOptions={this.remapFilterPayers}
                            onPayerFilterOptionChange={
                              this.onPayerFilterOptionChange
                            }
                            countFiltersApplied={
                              this.countInvoiceFiltersApplied
                            }
                            massPay={this.massPay}
                            payAllUnpaid={this.payAllUnpaid}
                            isPerformingMasspay={this.props.isPerformingMasspay}
                          />
                        </TabContent>
                      )}
                    />
                  </Switch>
                )}
              />
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    allProInvoices: getAllProInvoices(state),
    pagingProInvoices: getProInvoicesPaging(state),
    sortProInvoices: getProInvoicesSort(state),
    statusCollectionProInvoices: getProInvoicesStatusCollection(state),
    filtersProInvoices: getProInvoicesFilters(state),
    isFetchingProInvoices: isFetchingProInvoices(state),
    isFetchingProInvoicesFilters: isFetchingProInvoicesFilters(state),
    selectedRowsCount: getProInvoicesListSelectedRowsCount(state),
    selectedInvoicesIds: getProInvoicesSelectedRowsIds(state),
    payersForFilter: getCustomerPayersForFilter(state),
    isPerformingMasspay: isPerformingMasspay(state),
    allUnpaidIds: getAllUnpaidIds(state),
    allInvoices: getAllInvoices(state),
    pagingInvoices: getInvoicesPaging(state),
    sortInvoices: getInvoicesSort(state),
    statusCollectionInvoices: getInvoicesStatusCollection(state),
    filtersInvoices: getInvoicesFilters(state),
    isFetchingInvoices: isFetchingInvoices(state),
    isFetchingInvoicesFilters: isFetchingInvoicesFilters(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProInvoices,
      fetchProInvoicesStatusCollection,
      deselectProInvoicesListTableRow,
      selectProInvoicesListTableRow,
      selectAllProInvoicesListTableRows,
      deselectAllProInvoicesListTableRows,
      fetchCustomerPayersForFilter,
      clearProInvoicesFilters,
      doMassPay,
      fetchInvoices,
      clearInvoicesFilters
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(Billing));
