import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { Form, Field, FormSpy } from "react-final-form";
import { isMobile, isMobileOnly, isBrowser } from "react-device-detect";
import isLaptop from "../../../../../utils/isLaptopScreen";
import withSizes from "react-sizes";
import { Translate } from "react-localize-redux";

import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import Label from "../../../../../components/Label";
import InputField from "../../../../../components/InputField";
import SelectBox from "../../../../../components/SelectBox";

import { validateDnsRecordHostname } from "../../../../../common/validationRules";

const StyledSettingsWrapper = styled.div`
  padding-top: 20px;
`;

const StyledSettingsOption = styled.div`
  margin-bottom: 20px;
`;

const operatingSystems = [
  {
    label: "Windows",
    value: "windows"
  },
  {
    label: "Linux",
    value: "linux"
  }
];

class Settings extends React.Component {
  validate = values => {
    return {
      hostname: validateDnsRecordHostname(values.hostname, false)
    };
  };

  render() {
    const { product, onSaveSettings, isLaptop } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <Form
            onSubmit={onSaveSettings}
            validate={this.validate}
            initialValues={{}}
            render={({ handleSubmit }) => (
              <StyledSettingsWrapper>
                <form
                  id={`dedicated-server-form-${product.id}`}
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <StyledSettingsOption className="col-12 col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                          <Field
                            component={InputField}
                            name="hostname"
                            label={translate("cart.hostname")}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-6">
                          <Field
                            component={SelectBox}
                            name="operating_system"
                            label={translate("cart.os")}
                            options={operatingSystems}
                          />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 text-right">
                          <PrimaryButton
                            type="submit"
                            onClick={e =>
                              document
                                .getElementById(
                                  `dedicated-server-form-${product.id}`
                                )
                                .dispatchEvent(
                                  new Event("submit", { cancelable: true })
                                )
                            }
                            key="save-ns"
                          >
                            {translate("domain.settings.save-changes")}
                          </PrimaryButton>
                        </div>
                      </div>
                    </StyledSettingsOption>
                  </div>
                </form>
              </StyledSettingsWrapper>
            )}
          />
        )}
      </Translate>
    );
  }
}

export default withSizes(isLaptop)(Settings);
