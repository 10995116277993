import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Form, Field } from "react-final-form";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

import { ROUTE_LOGIN } from "../../../../routes/routes";

import InputField from "../../../../components/InputField";

import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import NoticeDanger from "../../../../components/Notices/NoticeDanger";

import { LOCALE_EN, LOCALE_MK } from "../../../../types/LocaleTypes";

const StyledForgotPasswordForm = styled.form`
  padding: 50px;
  background-color: ${theme.white};
  border: 1px solid ${theme.neutral3};
  border-left: ${props =>
    props.isMobile ? `1px solid ${theme.neutral3}` : "0"};
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-left-radius: ${props => (props.isMobile ? `5px` : "0")}
  border-bottom-left-radius: ${props => (props.isMobile ? `5px` : "0")}

  & h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    margin-bottom: 30px;
  }

  & .login-button {
    margin-top: 30px;
    margin-bottom: 20px;
    width: 100%;
  }

  & .forgot-password-link {
    display: block;
    color: ${theme.blueBase};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    letter-spacing: 0.2px;
    text-align: center;
  }
`;

const StyledLanguageIndicator = styled.span`
  width: 22px;
  height: 20px;
  font-size: 14px;
  color: ${theme.neutral5};
  cursor: pointer;
  text-transform: uppercase;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

const ForgotPasswordForm = ({
  onSubmit,
  validate,
  resetEmailSent,
  isMobile,
  changeLocale,
  currentLocale,
  onSetReCaptchaToken
}) => (
  <Translate>
    {({ translate }) => (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        validateOnBlur={true}
        render={({
          handleSubmit,
          submitting,
          pristine,
          invalid,
          submitError,
          form
        }) => (
          <StyledForgotPasswordForm
            onSubmit={e => {
              form.blur("email");
              handleSubmit(e);
            }}
            isMobile={isMobile}
          >
            <div className="row">
              <div className="col-9 col-sm-9 col-md-9">
                <h3>
                  {!resetEmailSent
                    ? translate("security.authentication.forgot-your-password")
                    : translate("security.authentication.check-email")}
                </h3>
              </div>
              <div className="col-3 col-sm-3 col-md-3 text-right">
                <StyledLanguageIndicator
                  onClick={e =>
                    changeLocale(
                      currentLocale === LOCALE_EN ? LOCALE_MK : LOCALE_EN
                    )
                  }
                >
                  {currentLocale === LOCALE_EN ? LOCALE_MK : LOCALE_EN}
                </StyledLanguageIndicator>
              </div>
            </div>
            <p>
              {!resetEmailSent
                ? translate("security.authentication.enter-email-instructions")
                : translate("security.authentication.follow-instructions")}
            </p>
            {!resetEmailSent && (
              <Field
                name="email"
                placeholder={translate(
                  "security.authentication.enter-your-email-address"
                )}
                label={translate("security.authentication.email")}
                type="text"
                component={InputField}
              />
            )}
            {submitError && <NoticeDanger>{submitError}</NoticeDanger>}
            <GoogleReCaptcha onVerify={token => onSetReCaptchaToken(token)} />
            {!resetEmailSent && (
              <PrimaryButton
                type="submit"
                className="login-button"
                submitting={submitting}
                disabled={submitting}
              >
                {translate("security.authentication.reset-password")}
              </PrimaryButton>
            )}
            {!resetEmailSent && (
              <Link to={ROUTE_LOGIN} className="forgot-password-link">
                {translate("security.authentication.back-to-login")}
              </Link>
            )}
          </StyledForgotPasswordForm>
        )}
      />
    )}
  </Translate>
);

ForgotPasswordForm.defaultProps = {
  resetEmailSent: false
};

export default ForgotPasswordForm;
