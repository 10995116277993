import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Translate } from "react-localize-redux";

import NoticeWarning from "../../../components/Notices/NoticeWarning";
import Radiobox from "../../../components/Radiobox";

import {
  CANCEL_PRODUCT_EXPIRE,
  CANCEL_PRODUCT_IMMEDIATELY
} from "../CancellationPeriods/CancellationPeriods";

const StyledCancellationOptions = styled.div`
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid ${theme.neutral3};
  border-bottom: 1px solid ${theme.neutral3};
`;

const CancelHosting = ({
  hosting,
  cancelProductPeriod,
  onChangeCancelProductPeriod
}) => (
  <Translate>
    {({ translate }) => (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <NoticeWarning>
            {translate("hosting.cancel-hosting-notice-warning")}
          </NoticeWarning>

          <StyledCancellationOptions className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <Radiobox
                onChange={e =>
                  onChangeCancelProductPeriod(CANCEL_PRODUCT_IMMEDIATELY)
                }
                name="type"
                value={CANCEL_PRODUCT_IMMEDIATELY}
                helpText={translate("hosting.cancel-hosting-info-immediately")}
                checked={CANCEL_PRODUCT_IMMEDIATELY === cancelProductPeriod}
              >
                {translate("hosting.cancel-immediately")}
              </Radiobox>
              <Radiobox
                onChange={e =>
                  onChangeCancelProductPeriod(CANCEL_PRODUCT_EXPIRE)
                }
                name="type"
                value={CANCEL_PRODUCT_EXPIRE}
                checked={CANCEL_PRODUCT_EXPIRE === cancelProductPeriod}
                helpText={`${translate(
                  "hosting.cancel-hosting-info-expires"
                )} ${hosting.renewal_date}`}
              >
                {translate("hosting.let-the-hosting-expire")}
              </Radiobox>
            </div>
          </StyledCancellationOptions>
        </div>
      </div>
    )}
  </Translate>
);

export default CancelHosting;
