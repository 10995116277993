import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { isMobile, isMobileOnly } from "react-device-detect";
import { Translate } from "react-localize-redux";
import isLaptop from "../../../../utils/isLaptopScreen";
import { withSizes } from "react-sizes";

import Container from "../../../../components/Containers/Container";
import Icon from "../../../../components/Icon";
import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";

import DeletingRecordLoader from "../../../../components/Loaders/DeletingRecordLoader";

const StyledService = styled.div`
  padding: ${props => {
    if (props.isMobile) return "30px 20px";
    if (props.isLaptop) return "25px";
    return "50px";
  }};
`;

const StyledRow = styled.div`
  margin-bottom: 30px;
`;

const StyledTypeTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${theme.neutral4};
  margin-bottom: 5px;
  text-transform: uppercase;

  & .domain {
    text-transform: lowercase;
  }
`;

const StyledServiceName = styled.h4`
  font-weight: 20px;
  font-weight: 600;
  font-size: 1.4;
  color: ${theme.neutralBase};
`;

const StyledDeleteIcon = styled.span`
  position: absolute;
  right: 0;
  top: ${props => (props.isMobile ? "-10px" : "-20px")};
  cursor: ${props => (props.isDeleting ? "default" : "pointer")};
`;

const StyledPriceBadge = styled.strong`
  text-transform: uppercase;
`;

const StyledPriceWrapper = styled.div`
  display: inline-block;
  vertical-align: bottom;
  margin-left: 25px;
`;

const Service = ({ product, onRemoveProductFromCart, deletingProductId }) => {
  
  let firstYearDerivation = null;
  let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];

  // const firstYearDerivation = product.item.pricing.buy.billing_cycle.derivations.find(
  //   d => d.numeric_code === 1
  // );

  // Temporary solution, should be sync with api derivation numeric codes
  possiblePeriodNumericCodes.some(numericCode => {
    firstYearDerivation = product.item.pricing.buy.billing_cycle.derivations.find(
      d => d.numeric_code === numericCode
    );

    if (typeof(firstYearDerivation) !== 'undefined') {
      console.log(numericCode);
      return true;
    }
  });
  // END

  return (
    <Translate>
      {({ translate }) => (
        <StyledRow className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <Container>
              <StyledService isMobile={isMobileOnly}>
                <StyledRow className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <StyledDeleteIcon
                      isMobile={isMobileOnly}
                      isDeleting={deletingProductId === product.id}
                      onClick={e =>
                        deletingProductId !== product.id &&
                        onRemoveProductFromCart(product)
                      }
                    >
                      {deletingProductId !== product.id && (
                        <Icon size="l" icon="delete" />
                      )}

                      {deletingProductId === product.id && (
                        <DeletingRecordLoader />
                      )}
                    </StyledDeleteIcon>
                    <StyledTypeTitle>
                      {translate("cart.addon")}{" "}
                      <span className="domain">({product.domain})</span>
                    </StyledTypeTitle>
                    <StyledServiceName>{product.item.name}</StyledServiceName>
                  </div>
                </StyledRow>
                <StyledRow className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <DiscountedAmountBadge
                      label={translate("cart.item-price")}
                      amount={{
                        amount: firstYearDerivation.price.value,
                        currency: firstYearDerivation.price.currency.code
                      }}
                    />
                  </div>
                </StyledRow>
              </StyledService>
            </Container>
          </div>
        </StyledRow>
      )}
    </Translate>
  );
};

Service.defaultProps = {
  product: {
    domainName: "sanjazakovska",
    type: "domain"
  }
};

export default withSizes(isLaptop)(Service);
