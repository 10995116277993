import React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { components } from "react-select";
import { Translate } from "react-localize-redux";

import SelectBox from "../../../../../components/SelectBox";
import NoticeInfo from "../../../../../components/Notices/NoticeInfo";
import Icon from "../../../../../components/Icon";

import {
  PAYER_DETAILS_TYPE_COMPANY,
  PAYER_DETAILS_TYPE_INDIVIDUAL
} from "../PayerDetailsForm/PayerDetailsTypes";

const StyledFormSeparator = styled.div`
  margin-left: -30px;
  margin-right: -30px;
`;

const StyledPaddedContainer = styled.div`
  padding: 30px;
  padding-top: 0;
  padding-bottom: 15px;
`;

const StyledTitle = styled.span`
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.neutralBase};
  margin-bottom: 20px;
`;

const StyledEnableEditing = styled.div`
  display: inline-block;

  font-size: 16px;
  color: ${theme.neutralBase};
  & span {
    color: ${theme.blueBase};
    cursor: pointer;
  }
`;

const StyledSelectOption = styled.div`
  padding-left: 10px;
  padding-right: 10px;

  & span.payer-wrapper {
    display: inline-block;
    vertical-align: top;
  }
  & span.payer-name {
    display: block;
  }
  & span.payer-email {
    display: block;
  }
`;

const StyledPayerType = styled.span`
  display: inline-block;
  vertical-align: top;
  padding: 5px;
  width: 40px;
  height: 40px;
  top: 3px;
  margin-right: 20px;
  border-radius: 50%;
  background-color: ${theme.neutral1};
  & .icon {
    color: ${theme.neutral4};
    top: 3px;
    left: 3px;
  }
`;

const PayerDetailsFormWithSearch = ({
  onSubmit,
  onCancel,
  contact,
  onSubmitting,
  onSearchContacts,
  onSelectContact,
  onCreateContact,
  countries,
  validate,
  showPayerNotice
}) => (
  <Translate>
    {({ translate }) => (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={contact}
        render={({ handleSubmit }) => {
          return (
            <form id="proinvoice-payer-details-form" onSubmit={handleSubmit}>
              <StyledFormSeparator className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <div className="row">
                    <StyledPaddedContainer className="col-12 col-sm-12 col-md-12">
                      <StyledTitle>
                        {translate(
                          "pro-invoices.view.use-an-existing-contact-or"
                        )}{" "}
                        <StyledEnableEditing onClick={() => onCreateContact()}>
                          <span>
                            {translate("pro-invoices.view.create-new")}.
                          </span>
                        </StyledEnableEditing>
                      </StyledTitle>
                      <SelectBox
                        async={true}
                        defaultOptions
                        loadOptions={onSearchContacts}
                        onInputChange={newValue => newValue}
                        onChange={onSelectContact}
                        defaultValue={
                          contact
                            ? {
                                label:
                                  contact.contact_type ===
                                  PAYER_DETAILS_TYPE_COMPANY
                                    ? `${contact.company} (${contact.email})`
                                    : `${contact.first_name} ${
                                        contact.last_name
                                      } (${contact.email})`,
                                value: contact.id
                              }
                            : false
                        }
                        components={{
                          Option: ({ children, ...rest }) => {
                            return (
                              <components.Option {...rest}>
                                <StyledSelectOption>
                                  {rest.data.type &&
                                  rest.data.type ===
                                    PAYER_DETAILS_TYPE_COMPANY ? (
                                    <StyledPayerType>
                                      <Icon icon="company" />
                                    </StyledPayerType>
                                  ) : (
                                    <StyledPayerType>
                                      <Icon icon="user" />
                                    </StyledPayerType>
                                  )}
                                  <span className="payer-wrapper">
                                    <span className="payer-name">
                                      {rest.data.name}
                                    </span>
                                    <span className="payer-email">
                                      {rest.data.email}
                                    </span>
                                  </span>
                                </StyledSelectOption>
                              </components.Option>
                            );
                          }
                        }}
                      />
                    </StyledPaddedContainer>
                  </div>
                </div>
                {contact &&
                  Object.keys(contact).map(data => (
                    <Field
                      component="input"
                      key={data}
                      type="hidden"
                      name={data}
                      value={contact[data]}
                    />
                  ))}

                {showPayerNotice && (
                  <StyledPaddedContainer className="col-12 col-sm-12 col-md-12">
                    <NoticeInfo>
                      {translate("pro-invoices.view.change-payer-notice-info")}.
                    </NoticeInfo>
                  </StyledPaddedContainer>
                )}
              </StyledFormSeparator>

              <FormSpy
                subscription={{ submitting: true }}
                onChange={formstate => onSubmitting({ ...formstate })}
              />
            </form>
          );
        }}
      />
    )}
  </Translate>
);

export default PayerDetailsFormWithSearch;
