import * as HDA from "../actions/helpdeskActions";

import handleReducerActions from "../../../core/handleReducerActions";

import { APP_STATE_COMPLETE_RESET } from "../../AppState/actions/appStateActions";
import { SORT_ORDER_DESCENDING } from "../../../types/SortTypes";

const initialState = {
  knowledgeBaseCategories: [],
  isFetchingKnowledgeBaseCategories: false,
  knowledgeBaseMostPopularArticles: [],
  isFetchingKnowledgeBaseMostPopularArticles: false,
  articles: [],
  currentArticle: {
    title: "",
    category_name: "",
    category_id: 0,
    votes: 0,
    article: ""
  },
  isFetchingKnowledgeBaseArticle: false,
  searchedArticles: [],
  isFetchingKnowledgeBaseArticleByKeyword: false,
  tickets: {
    items: [],
    paging: {
      limit: 10,
      current_page: 0,
      next_page: 0,
      previous_page: 0,
      total_pages: 0,
      total: 0
    },
    filters: {
      status: "",
      keyword: "",
      department: "",
      product: ""
    },
    sort: {
      sort_by: "status", // Remove magic variable implementation
      order_by: SORT_ORDER_DESCENDING
    }
  },
  isFetchingTickets: false,
  ticketDepartments: [],
  isFetchingTicketDepartments: false,
  isFetchingTickedRelatedProducts: false,
  ticketRelatedProducts: [],
  ticketsListFilters: []
};

const helpdeskStateHandlers = {
  [HDA.HELPDESK_KNOWLEDGE_BASE_CATEGORIES_REQUEST]: helpdeskKnowledgeBaseCategoriesRequest,
  [HDA.HELPDESK_KNOWLEDGE_BASE_CATEGORIES_RECEIVE]: helpdeskKnowledgeBaseCategoriesReceive,
  [HDA.HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_REQUEST]: helpdeskKnowledgeBaseMostPopularArticlesRequest,
  [HDA.HELPDESK_KNOWLEDGE_BASE_MOST_POPULAR_ARTICLES_RECEIVE]: helpdeskKnowledgeBaseMostPopularArticlesReceive,
  [HDA.HELPDESK_KNOWLEDGE_BASE_ARTICLE_REQUEST]: helpdeskKnowledgeArticleRequest,
  [HDA.HELPDESK_KNOWLEDGE_BASE_ARTICLE_RECEIVE]: helpdeskKnowledgeArticleReceive,
  [HDA.HELPDESK_KNOWLEDGE_BASE_VIEW_ARTICLE]: helpdeskKnowledgeBaseViewArticle,
  [HDA.HELPDESK_KNOWLEDGE_BASE_SEARCH_ARTICLES_BY_KEYWORD_REQUEST]: helpdeskKnowledgeBaseSearchArticlesByKeywordRequest,
  [HDA.HELPDESK_KNOWLEDGE_BASE_SEARCH_ARTICLES_BY_KEYWORD_RECEIVE]: helpdeskKnowledgeBaseSearchArticlesByKeywordReceive,
  [HDA.HELPDESK_TICKETS_REQUEST_DEPARTMENTS]: helpdeskTicketDepartmentsRequest,
  [HDA.HELPDESK_TICKETS_RECEIVE_DEPARTMENTS]: helpdeskTicketDepartmentsReceive,
  [HDA.HELPDESK_TICKETS_REQUEST_RELATED_PRODUCTS]: helpdeskTicketRelatedProductsRequest,
  [HDA.HELPDESK_TICKETS_RECEIVE_RELATED_PRODUCTS]: helpdeskTicketRelatedProductsReceive,
  [HDA.HELPDESK_TICKETS_REQUEST]: helpdeskTicketsRequest,
  [HDA.HELPDESK_TICKETS_RECEIVE]: helpdeskTicketsReceive,
  [HDA.HELPDESK_TICKET_RECEIVE]: helpdeskTicketReceive,
  [HDA.HELPDESK_TICKETS_TICKET_NOT_FOUND]: helpdeskTicketNotFound,
  [HDA.HELPDESK_TICKETS_VIEW_TICKET]: helpdeskTicketsViewTicket,
  [HDA.HELPDESK_TICKETS_UPDATE_TICKET]: helpdeskTicketsUpdateTicket,
  [APP_STATE_COMPLETE_RESET]: resetState
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(helpdeskStateHandlers, state, action);
}

function helpdeskKnowledgeBaseCategoriesRequest(state, action) {
  return {
    ...state,
    isFetchingKnowledgeBaseCategories: true
  };
}

function helpdeskKnowledgeBaseCategoriesReceive(state, action) {
  return {
    ...state,
    knowledgeBaseCategories: action.payload,
    isFetchingKnowledgeBaseCategories: false
  };
}

function helpdeskKnowledgeBaseMostPopularArticlesRequest(state, action) {
  return {
    ...state,
    isFetchingKnowledgeBaseMostPopularArticles: true
  };
}

function helpdeskKnowledgeBaseMostPopularArticlesReceive(state, action) {
  return {
    ...state,
    knowledgeBaseMostPopularArticles: action.payload,
    isFetchingKnowledgeBaseMostPopularArticles: false
  };
}

function helpdeskKnowledgeArticleRequest(state, action) {
  return {
    ...state,
    isFetchingKnowledgeBaseArticle: true
  };
}

function helpdeskKnowledgeArticleReceive(state, action) {
  return {
    ...state,
    articles: state.articles.concat(action.payload),
    isFetchingKnowledgeBaseArticle: false
  };
}

function helpdeskKnowledgeBaseViewArticle(state, action) {
  return {
    ...state,
    currentArticle: action.payload
  };
}

function helpdeskKnowledgeBaseSearchArticlesByKeywordRequest(state, action) {
  return {
    ...state,
    isFetchingKnowledgeBaseArticleByKeyword: true
  };
}

function helpdeskKnowledgeBaseSearchArticlesByKeywordReceive(state, action) {
  return {
    ...state,
    searchedArticles: action.payload,
    isFetchingKnowledgeBaseArticleByKeyword: false
  };
}

function helpdeskTicketDepartmentsRequest(state) {
  return {
    ...state,
    isFetchingTickedDepartments: true
  };
}

function helpdeskTicketDepartmentsReceive(state, action) {
  return {
    ...state,
    ticketDepartments: [...action.payload],
    isFetchingTickedDepartments: false
  };
}

function helpdeskTicketRelatedProductsRequest(state) {
  return {
    ...state,
    isFetchingTickedRelatedProducts: true
  };
}

function helpdeskTicketRelatedProductsReceive(state, action) {
  return {
    ...state,
    ticketRelatedProducts: [...action.payload],
    isFetchingTickedRelatedProducts: false
  };
}

function helpdeskTicketsRequest(state) {
  return {
    ...state,
    isFetchingTickets: true
  };
}

function helpdeskTicketsReceive(state, action) {
  return {
    ...state,
    tickets: {
      ...action.payload,
      items: action.payload.items.map(ticket => {
        return {
          ...ticket,
          view: false,
          closed: ticket.status === "Closed"
        };
      })
    },
    isFetchingTickets: false
    //ticketsListFilters: [...action.payload.filters.products,...action.payload.filters.department]
  };
}

function helpdeskTicketReceive(state, action) {
  const tickets = state.tickets;

  var foundIndex = tickets.items.findIndex(
    ticket => ticket.id === action.payload.id
  );

  if (foundIndex >= 0) {
    tickets.items[foundIndex] = action.payload;
  } else {
    tickets.items.concat([action.payload]);
  }

  return {
    ...state,
    tickets: {
      ...state.tickets,
      items: tickets.items.map(ticket => {
        return {
          ...ticket,
          view: false,
          closed: ticket.status === "Closed"
        };
      })
    },
    isFetchingTickets: false
  };
}

function helpdeskTicketsViewTicket(state, action) {
  return {
    ...state,
    tickets: {
      ...state.tickets,
      items: state.tickets.items.map(ticket => {
        if (ticket.id !== action.payload.id) {
          return {
            ...ticket,
            view: false
          };
        }
        return {
          ...ticket,
          view: true
        };
      })
    }
  };
}

function helpdeskTicketsUpdateTicket(state, action) {
  return {
    ...state,
    tickets: {
      ...state.tickets,
      items: state.tickets.items.map(ticket => {
        if (ticket.id !== action.payload.id) {
          return {
            ...ticket
          };
        }
        return {
          ...ticket,
          ...action.payload
        };
      })
    }
  };
}

function helpdeskTicketNotFound(state) {
  return {
    ...initialState
  };
}

function resetState(state) {
  return { ...initialState };
}

// Selectors
export function getKnowledgeBaseCategories(state) {
  return state.helpdesk.knowledgeBaseCategories;
}

export function getKnowledgeBaseMostPopularArticles(state) {
  return state.helpdesk.knowledgeBaseMostPopularArticles;
}

export function isFetchingKnowledgeBaseCategories(state) {
  return state.helpdesk.isFetchingKnowledgeBaseCategories;
}

export function isFetchingKnowledgeBaseMostPopularArticles(state) {
  return state.helpdesk.isFetchingKnowledgeBaseMostPopularArticles;
}

export function getKnowledgeBaseArticleToView(state) {
  return state.helpdesk.currentArticle;
}

export function isFetchingKnowledgeBaseArticle(state) {
  return state.helpdesk.isFetchingKnowledgeBaseArticle;
}

export function getFetchedArticles(state) {
  return state.helpdesk.articles;
}

export function getKnowledgeBaseSearchedArticles(state) {
  return state.helpdesk.searchedArticles;
}

export function isFetchingKnowledgeBaseArticleByKeyword(state) {
  return state.helpdesk.isFetchingKnowledgeBaseArticleByKeyword;
}

export function getTicketDepartments(state) {
  return state.helpdesk.ticketDepartments;
}

export function isFetchingTicketDepartments(state) {
  return state.helpdesk.isFetchingTicketDepartments;
}

export function isFetchingTicketRelatedProducts(state) {
  return state.helpdesk.isFetchingTicketRelatedProducts;
}

export function getTicketRelatedProducts(state) {
  return state.helpdesk.ticketRelatedProducts;
}

export function getTickets(state) {
  return state.helpdesk.tickets;
}

export function isFetchingTickets(state) {
  return state.helpdesk.isFetchingTickets;
}

export function getSelectedTicket(state) {
  return state.helpdesk.tickets.items.find(ticket => ticket.view);
}

export function getTicketsListFilters(state) {
  return state.helpdesk.tickets.ticketsListFilters;
}
