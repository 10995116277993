import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { isMobileOnly } from "react-device-detect";
import { Translate } from "react-localize-redux";

import Container from "../../../../../components/Containers/Container";

import emailSvg from "./email.svg";
import cpanelSvg from "./cpanel.svg";
import setupSvg from "./setup.svg";

const StyledHeader = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid ${theme.neutral3};
`;

const StyledContent = styled.div`
  padding: 30px;
  vertical-align: top;
`;

const StyledTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  color: ${theme.neutral5};
`;

const StyledQuickLink = styled.a`
  display: inline-block;
  position: relative;
  width: ${props => (props.isMobile ? "100%" : "45%")};
  margin-right: 15px;
  margin-bottom: 15px;
  border-radius: 3px;
  border: 1px solid ${theme.neutral3};
  padding: 15px;
  padding-left: 45px;
  color: ${theme.blueBase};
  font-weight: 600;
  vertical-align: top;

  &:hover {
    text-decoration: none;
  }
`;

const StyledQuickLinkIcon = styled.img`
  display: inline-block;
  position: absolute;
  left: 11px;
  top: 13px;
  color: ${theme.blueBase};
  margin-right: 15px;
`;

const QuickLinks = ({
  hosting,
  quickLinks,
  isGettingHostingCPanelDirectLoginLink
}) => {
  return (
    <Translate>
      {({ translate, activeLanguage }) => (
        <Container>
          <StyledHeader>
            <StyledTitle>
              {translate("hosting.general-info.quick-links")}
            </StyledTitle>
          </StyledHeader>
          <StyledContent>
            <StyledQuickLink
              href={quickLinks.cPanel}
              onClick={e => {
                if (
                  isGettingHostingCPanelDirectLoginLink ||
                  quickLinks.cPanel === ""
                )
                  e.preventDefault();
              }}
              target="_blank"
              rel="noreferrer noopener"
              isMobile={isMobileOnly}
              disabled={
                isGettingHostingCPanelDirectLoginLink ||
                quickLinks.cPanel === ""
              }
            >
              <StyledQuickLinkIcon src={cpanelSvg} />
              {translate("hosting.general-info.login-to-cpanel")}
            </StyledQuickLink>

            <StyledQuickLink
              href={`https://${hosting.domain + "/webmail"}`}
              target="_blank"
              rel="noreferrer noopener"
              isMobile={isMobileOnly}
            >
              <StyledQuickLinkIcon src={emailSvg} />
              {translate("hosting.general-info.login-to-webmail")}
            </StyledQuickLink>

            <StyledQuickLink
              href={
                activeLanguage &&
                activeLanguage.code &&
                `https://mkhost.com/${activeLanguage.code}/other/email`
              }
              target="_blank"
              rel="noreferrer noopener"
              isMobile={isMobileOnly}
            >
              <StyledQuickLinkIcon src={setupSvg} />
              {translate("hosting.general-info.setup-email-client")}
            </StyledQuickLink>
          </StyledContent>
        </Container>
      )}
    </Translate>
  );
};
export default QuickLinks;
