import * as domainsService from "../../../services/domainsService.js";
import * as customerService from "../../../services/customerService.js";

export const DOMAINS_FEATURE = "features/Domains";
export const DOMAINS_REQUEST = `${DOMAINS_FEATURE}/DOMAINS_REQUEST`;
export const DOMAINS_RECEIVE = `${DOMAINS_FEATURE}/DOMAINS_RECEIVE`;

export const DOMAINS_FOR_WIDGET_REQUEST = `${DOMAINS_FEATURE}/DOMAINS_FOR_WIDGET_REQUESTT`;
export const DOMAINS_FOR_WIDGET_RECEIVE = `${DOMAINS_FEATURE}/DOMAINS_FOR_WIDGET_RECEIVE`;

export const CUSTOMER_DOMAINS_REQUEST = `${DOMAINS_FEATURE}/CUSTOMER_DOMAINS_REQUEST`;
export const CUSTOMER_DOMAINS_RECEIVE = `${DOMAINS_FEATURE}/CUSTOMER_DOMAINS_RECEIVE`;

export const TOP_LEVEL_DOMAINS_GROUPED_REQUEST = `${DOMAINS_FEATURE}/TOP_LEVEL_DOMAINS_GROUPED_REQUEST`;
export const TOP_LEVEL_DOMAINS_GROUPED_RECEIVE = `${DOMAINS_FEATURE}/TOP_LEVEL_DOMAINS_GROUPED_RECEIVE`;

export function fetchDomains() {
  return dispatch => {
    dispatch(requestDomains());
    return domainsService
      .fetchDomainsPrices()
      .then(
        result => {
          const { tld } = result.data.data;
          dispatch(receiveDomains(tld));
          return result;
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchDomainsForWidget(limit, sort, filters = {}) {
  return dispatch => {
    dispatch(requestDomainsForWidget());
    return customerService
      .fetchCustomerDomains(limit, 1, sort, filters)
      .then(
        result => {
          const { domains } = result.data.data;
          dispatch(receiveDomainsForWidget(domains));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchTopLevelGroupedDomains() {
  return dispatch => {
    dispatch(requestTopLevelGroupedDomains());
    return domainsService
      .fetchGroupedTopLevelDomains()
      .then(
        result => {
          const { error } = result.data;
          const { messages, top_level_domains } = result.data.data;

          if (!error) {
            dispatch(receiveTopLevelGroupedDomains(top_level_domains));
          }

          return { error, messages };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

function requestDomains() {
  return {
    type: DOMAINS_REQUEST
  };
}

function receiveDomains(domains) {
  return {
    type: DOMAINS_RECEIVE,
    payload: domains
  };
}

function requestDomainsForWidget() {
  return {
    type: DOMAINS_FOR_WIDGET_REQUEST
  };
}

function receiveDomainsForWidget(domains) {
  return {
    type: DOMAINS_FOR_WIDGET_RECEIVE,
    payload: domains
  };
}

function requestTopLevelGroupedDomains() {
  return {
    type: TOP_LEVEL_DOMAINS_GROUPED_REQUEST
  };
}

function receiveTopLevelGroupedDomains(topLevelDomains) {
  return {
    type: TOP_LEVEL_DOMAINS_GROUPED_RECEIVE,
    payload: topLevelDomains
  };
}
