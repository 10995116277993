import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { isMobileOnly } from "react-device-detect";

import Filter from "../../../../components/Filter";
import DateRangePicker from "../../../../components/DateRangePicker";
import Container from "../../../../components/Containers/Container";
import Form from "../../../../components/Forms/Form";
import SelectBox from "../../../../components/SelectBox";
import { fetchInvoices } from "../actions/invoicesActions";

const StyledInvoicesFilterContainer = styled.div`
  margin-bottom: ${props => props.isMobile && "15px"};
`;

class InvoicesFilter extends React.Component {
  // onStatusFilterOptionChange = selectedOptionValue => {
  //   this.props.fetchInvoicesFilter(
  //     this.props.paging.limit,
  //     1,
  //     this.props.sort,
  //     {
  //       ...this.props.filters,
  //       status: selectedOptionValue.value
  //     }
  //   );
  // };

  onClear = () => {
    this.props.fetchInvoices(this.props.paging.limit, 1, this.props.sort, {
      keyword: this.props.filters.keyword
    });
  };

  dateCreatedRangeSelected = (startDate, endDate) => {
    this.props.fetchInvoices(this.props.paging.limit, 1, this.props.sort, {
      ...this.props.filters,
      date_range:
        startDate.format("YYYY-MM-DD") + "." + endDate.format("YYYY-MM-DD")
    });
  };

  // dueDateRangeSelected = (startDate, endDate) => {
  //   this.props.fetchInvoicesFilter(
  //     this.props.paging.limit,
  //     1,
  //     this.props.sort,
  //     {
  //       ...this.props.filters,
  //       date_due_range:
  //         startDate.format("YYYY-MM-DD") + "." + endDate.format("YYYY-MM-DD")
  //     }
  //   );
  // };
  //
  // paidOnDateRangeSelected = (startDate, endDate) => {
  //   this.props.fetchInvoicesFilter(
  //     this.props.paging.limit,
  //     1,
  //     this.props.sort,
  //     {
  //       ...this.props.filters,
  //       date_paid_on_range:
  //         startDate.format("YYYY-MM-DD") + "." + endDate.format("YYYY-MM-DD")
  //     }
  //   );
  // };

  render() {
    let startDateRange = null;
    let endDateRange = null;
    if (this.props.filters && this.props.filters.date_range.length) {
      startDateRange = this.props.filters.date_range.split(".")[0];
      endDateRange = this.props.filters.date_range.split(".")[1];
    }

    return (
      <StyledInvoicesFilterContainer isMobile={isMobileOnly}>
        <Translate>
          {({ translate }) => (
            <Container>
              <Filter
                title={translate("pro-invoices.filter.title")}
                clearTitle={translate("pro-invoices.filter.clear-all")}
                count={this.props.countFiltersApplied()}
                onClear={this.onClear}
              >
                <Form>
                  <DateRangePicker
                    name="date_range"
                    label={translate("filters.date-created-range")}
                    dateRangeSelected={this.dateCreatedRangeSelected}
                    initialStartDate={startDateRange}
                    initialEndDate={endDateRange}
                    startDatePlaceholderText={translate("filters.start-date")}
                    endDatePlaceholderText={translate("filters.end-date")}
                  />
                </Form>
              </Filter>
            </Container>
          )}
        </Translate>
      </StyledInvoicesFilterContainer>
    );
  }
}

InvoicesFilter.defaultProps = {
  count: 0,
  onClear: () => {}
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchInvoices
    },
    dispatch
  );

export default connect(
  null,
  mapDispatchToProps
)(InvoicesFilter);
