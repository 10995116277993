import React from "react";
import { Translate } from "react-localize-redux";
import * as Sentry from "@sentry/browser";
import { withRouter } from "react-router-dom";

import UnexpectedError from "../UnexpectedError";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    this.historyUnlisten = this.props.history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({
          hasError: false
        });
      }
    });
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    Sentry.withScope(scope => {
      scope.setExtras(info);
      Sentry.captureException(error);
    });
  }

  componentWillUnmount() {
    this.historyUnlisten();
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !this.state.error || nextState.error;
  }

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              {this.state.hasError ? <UnexpectedError /> : this.props.children}
            </div>
          </div>
        )}
      </Translate>
    );
  }
}

export default withRouter(ErrorBoundary);
