import React from "react";
import { Translate } from "react-localize-redux";
import { Field, Form, FormSpy } from "react-final-form";
import styled from "styled-components";
import Panel from "../../../components/Panel";
import Icon from "../../../components/Icon";
import InputField from "../../../components/InputField";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";



const StyledButton = styled(SecondaryButton)`
  margin-top: 25px;
`;

const ReferralText= styled.div`
font-size: 16px;
    line-height: 1.5;
    color: #5E6C83;
     padding-bottom:10px;
`
const ReferralTextAdmin= styled.div`
font-size: 16px;
    line-height: 1.5;
    color: #5E6C83;
    padding-bottom:10px;
`
const ReferralTextRules= styled.div`
font-size: 16px;
    line-height: 1.5;
    color: #5E6C83;
    padding-bottom:10px;
`


const GenerateLinkContent = ({
                            title,
                            description,

                            validatePurchaseCredit
                        }) => (




    <Translate>

        {({ translate }) => (
            <Panel title={translate("referral-admin.referral-program")} >
                <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                        <ReferralText>{translate("referral-admin.referral-rule-desc")}</ReferralText>

                        <ReferralTextAdmin> {translate("referral-admin.referral-rules")}</ReferralTextAdmin>

                        <ReferralTextRules> {translate("referral-admin.referral-rules-second")}</ReferralTextRules>

                    </div>
                </div>
            </Panel>

        )}
    </Translate>
);

GenerateLinkContent.defaultProps = {
    amount: "0.00",
    currency: "MKD"
};

export default GenerateLinkContent;
