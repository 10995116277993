import React from "react";
import styled from "styled-components";

import loading from "../../../../../../../assets/images/logomark-blue.svg";

const StyledSavingRecordLoader = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  left: -40px;
  top: 3px;
  & .loading-wrapper {
    position: absolute;
    z-index: 1;
    background-color: transparent;
    width: 24px;
    height: 24px;
  }

  & .btn-loading-img {
    position: relative;
    top: 4px;
    width: 24px;
    height: 24px;
    animation: btn-loading-img 1.2s linear infinite;
  }

  @keyframes btn-loading-img {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const SavingRecordLoader = () => (
  <StyledSavingRecordLoader>
    <div className="loading-wrapper">
      <img src={loading} className="btn-loading-img" alt="" />
    </div>
  </StyledSavingRecordLoader>
);

export default SavingRecordLoader;
