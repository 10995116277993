import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import "moment/min/locales";
import Cookies from "universal-cookie";

import { ThemeProvider } from "styled-components";
import styled from "styled-components";
import { Switch, Route, withRouter } from "react-router-dom";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import isLaptop from "./utils/isLaptopScreen";
import withSizes from "react-sizes";
import { withLocalize } from "react-localize-redux";
import { renderToStaticMarkup } from "react-dom/server";
import {
  getApplicationLocale,
  setApplicationLocale,
  getLocaleKey
} from "./services/localizationService";

import { fetchUnreadNotifications } from "./services/notificationsService";

import { DefaultTheme, theme } from "./design/Themes";

import Navigation from "./components/Navigation";
import Sidebar from "./components/Sidebar";
import MobileSubNavigation from "./components/Navigation/MobileSubNavigation";
import ErrorBoundary from "./components/ErrorBoundary/ErrorBoundary";
import { ToastContainer } from "react-toastify";

import {
  authenticatedRoutes,
  guestRoutes,
  updatePersonalInformationRoutes,
  ROUTE_UPDATE_CUSTOMER_INFORMATION,
  ROUTE_EMAIL_VERIFY,
  ROUTE_REFERRAL_PROGRAM_APPLY
} from "./routes/routes.js";

import { logout } from "./features/Authentication/actions/authActions";
import {
  isAuthenticated,
  isFetching as isFetchingAuthentication
} from "./features/Authentication/reducers/authReducer";

import {
  fetchCustomerPersonalInformation,
  updateCustomerLanguage
} from "./features/Customer/actions/customerActions";

import {
  getCustomerPersonalInformation,
  isFetchingCustomerPersonalInformation,
  isCustomerPersonalInformationComplete
} from "./features/Customer/reducers/customerReducer";

import { fetchCountries } from "./features/Addressing/actions/addressingActions";

import {
  fetchNotifications,
  listenForNotifications,
  stopListeningForNotifications
} from "./features/Notifications/actions/notificationsActions";

import { fetchSystemPreferences } from "./features/Preferences/actions/preferenceActions";
import { fetchCredit } from "./features/Credit/actions/creditActions";

import {
  ROUTE_LOGIN,
  ROUTE_MY_ACCOUNT,
  ROUTE_CART,
  ROUTE_CART_CHECKOUT,
  ROUTE_SHOP_DOMAINS,
  ROUTE_SHOP_HOSTING
} from "./routes/routes";

import englishTranslations from "./translations/en.json";
import macedonianTranslations from "./translations/mk.json";
import CookieBanner from "./components/CookieBanner";

const localeShortCodes = {
  english: "en",
  macedonian: "mk"
};

const StyledApp = styled.div`
  background-color: ${theme.blue2};
  & {
    @media print {
      background-color: transparent;
    }
  }
`;

const StyledMain = styled.div`
  position: fixed;
  top: ${props => {
    if (props.isMobile && !props.showGuestNavigation && !props.isAuthenticated)
      return "0px";
    if (props.isMobile && !props.showGuestNavigation && props.isAuthenticated)
      return "74px;";
    return "74px";
  }}
  left: ${props => {
    if (!props.isAuthenticated) {
      if (props.isMobile || props.isTablet || props.isLaptop) {
        return "0";
      }
      return "265px";
    }

    if (props.isAuthenticated) {
      if (props.isMobile || props.isTablet) {
        return "7.5px";
      }

      return "265px";
    }
  }};
  width: ${props => {
    if (!props.isAuthenticated) {
      if (props.isMobile || props.isTablet || props.isLaptop) {
        return "100%";
      }
      return `calc(100vw - 250px)`;
    }

    if (props.isAuthenticated) {
      if (props.isMobile || props.isTablet) {
        return "100%";
      }

      return `calc(100vw - 250px)`;
    }
  }};

  height: ${props => (props.isMobile ? "100%" : `calc(100vh - 74px)`)};
  padding: 0 15px;
  overflow-y: auto;
  & .widget-container {
    margin-bottom: 30px;
  }

  & {
    @media print {
      margin-top: 30px;
      top: 0;
      left: 15px;
      width: 100% !important;
      min-width: 100% !important;
      max-width: 100% !important;
      width: 100% !important;
      height: 100%;
      position: static;
      display: inline-block;
      vertical-align: top;
      @page {
        size: A4;
        margin: 0;
      }
    }
  }
`;

const AuthenticatedRoutes = (
  isAuthenticated,
  isFetchingCustomerPersonalInformation,
  onLogout,
  customerPersonalInformation,
  redirectToMyAccount,
  onToggleMobileMenu,
  showMobileMenu
) => (
  <div className="row h-100">
    <div className="col-12 col-sm-12 col-md-12">
      <ToastContainer
        position="top-right"
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <Navigation
        isAuthenticated={isAuthenticated}
        isFetchingCustomerPersonalInformation={
          isFetchingCustomerPersonalInformation
        }
        onLogout={onLogout}
        customerPersonalInformation={customerPersonalInformation}
        redirectToMyAccount={redirectToMyAccount}
        onToggleMobileMenu={onToggleMobileMenu}
      />

      {isMobileOnly && (
        <MobileSubNavigation isAuthenticated={isAuthenticated} />
      )}
      <Sidebar
        isAuthenticated={isAuthenticated}
        showMobileMenu={showMobileMenu}
      />
      <ErrorBoundary>
        <StyledMain
          className="row main"
          isMobile={isMobileOnly}
          isTablet={isTablet}
          isLaptop={isLaptop}
          isAuthenticated={isAuthenticated}
        >
          <div className="col-12 col-sm-12 col-md-12">
            <Switch>
              {authenticatedRoutes.map(
                (route, index) =>
                  route.path ? (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  ) : (
                    <Route key={index} component={route.component} />
                  )
              )}
            </Switch>
          </div>
        </StyledMain>
      </ErrorBoundary>
      <CookieBanner />
    </div>
  </div>
);

const GuestRoutes = (
  isAuthenticated,
  onLogout,
  onToggleMobileMenu,
  showGuestNavigation,
  location
) => (
  <div className="row h-100">
    <ToastContainer
      position="top-right"
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnVisibilityChange
      draggable
      pauseOnHover
    />
    {showGuestNavigation && (
      <Navigation
        isAuthenticated={isAuthenticated}
        onLogout={onLogout}
        onToggleMobileMenu={onToggleMobileMenu}
      />
    )}

    <StyledMain
      className="col-12 col-sm-12 col-md-12 main"
      isMobile={isMobileOnly}
      isTablet={isTablet}
      isLaptop={isLaptop}
      isAuthenticated={isAuthenticated}
      showGuestNavigation={showGuestNavigation}
    >
      <Switch>
        {guestRoutes.map(
          (route, index) =>
            route.path ? (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ) : (
              <Route key={index} component={route.component} />
            )
        )}
      </Switch>
    </StyledMain>
  </div>
);

const UpdatePersonalInformationRoutes = customerPersonalInformation => (
  <div className="row h-100">
    <div className="col-12 col-sm-12 col-md-12 main">
      <Switch>
        {updatePersonalInformationRoutes.map(
          (route, index) =>
            route.path ? (
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                component={route.component}
              />
            ) : (
              <Route key={index} component={route.component} />
            )
        )}
      </Switch>
    </div>
  </div>
);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMobileMenu: isMobile ? false : true
      //showUpdatePersonalInformation: false
    };

    this.props.initialize({
      languages: [
        {
          name: "English",
          code: "en"
        },
        {
          name: "Македонски",
          code: "mk"
        }
      ],
      options: { renderToStaticMarkup }
    });
    this.props.addTranslationForLanguage(englishTranslations, "en");
    this.props.addTranslationForLanguage(macedonianTranslations, "mk");

    const cookies = new Cookies();
    const locale = cookies.get(getLocaleKey());

    if (locale) {
      this.props.setActiveLanguage(locale);
      moment.locale(locale);
    } else {
      this.props.setActiveLanguage(getApplicationLocale());
      moment.locale(getApplicationLocale());
    }

    this.notificationsInterval = null;
  }

  componentDidUpdate(prevProps) {
    this.removeReCaptcha();

    if (prevProps.activeLanguage && this.props.activeLanguage) {
      if (prevProps.activeLanguage.code !== this.props.activeLanguage.code) {
        setApplicationLocale(this.props.activeLanguage.code);
        moment.locale(this.props.activeLanguage.code);
        if (
          this.props.customerPersonalInformation &&
          this.props.customerPersonalInformation.email !== ""
        ) {
          this.props.updateCustomerLanguage(
            this.props.activeLanguage.code === "en" ? "english" : "macedonian",
            this.props.customerPersonalInformation
          );
        }
      }

      // if local language and api language are different then update to api langauge

      if (
        !prevProps.customerPersonalInformation &&
        this.props.customerPersonalInformation
      ) {
        if (
          localeShortCodes[this.props.customerPersonalInformation.language] !==
          this.props.activeLanguage.code
        ) {
          this.props.setActiveLanguage(
            localeShortCodes[this.props.customerPersonalInformation.language]
          );
          setApplicationLocale(
            localeShortCodes[this.props.customerPersonalInformation.language]
          );
          moment.locale(
            localeShortCodes[this.props.customerPersonalInformation.language]
          );
        }
      }
    }

    if (process.env.NODE_ENV === "production") {
      const gtag = window.gtag;

      if (prevProps.location.pathname !== this.props.location.pathname) {
        if (
          this.props.history.action === "PUSH" &&
          typeof gtag === "function"
        ) {
          gtag(
            "config",
            process.env.REACT_APP_MKHOST_GOOGLE_ANALYTICS_TRACKING_ID,
            {
              page_location: window.location.href,
              page_path: this.props.location.pathname
            }
          );
        }
      }
    }

    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.props
        .fetchNotifications()
        .then(
          result => {
            if (!this.notificationsInterval) {
              this.notificationsInterval = setInterval(
                this.props.fetchNotifications,
                60 * 1000
              );
            }
          },
          err => {
            if (this.notificationsInterval) {
              clearInterval(this.notificationsInterval);
              this.props.stopListeningForNotifications();
            }
          }
        )
        .catch(err => {
          if (this.notificationsInterval) {
            clearInterval(this.notificationsInterval);
            this.props.stopListeningForNotifications();
          }
        });
      //
      this.props.fetchSystemPreferences();
      this.props.fetchCredit();
    }

    if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
      if (this.notificationsInterval) {
        clearInterval(this.notificationsInterval);
        this.props.stopListeningForNotifications();
      }
    }
  }

  componentDidMount() {
    this.props.fetchCountries();
    if (this.props.isAuthenticated) {
      this.props
        .fetchNotifications()
        .then(
          result => {
            if (!this.notificationsInterval) {
              this.notificationsInterval = setInterval(
                this.props.fetchNotifications,
                60 * 1000
              );
            }
          },
          err => {
            if (this.notificationsInterval) {
              clearInterval(this.notificationsInterval);
              this.props.stopListeningForNotifications();
            }
          }
        )
        .catch(err => {
          if (this.notificationsInterval) {
            clearInterval(this.notificationsInterval);
            this.props.stopListeningForNotifications();
          }
        });
    }

    if (!this.props.isAuthenticated) {
      sessionStorage.setItem("redirect_intended", this.props.location.pathname);
      if (this.notificationsInterval) {
        clearInterval(this.notificationsInterval);
        this.props.stopListeningForNotifications();
      }
    }

    this.removeReCaptcha();
  }

  componentWillUnmount() {
    if (this.notificationsInterval) {
      clearInterval(this.notificationsInterval);
      this.props.stopListeningForNotifications();
    }
  }

  removeReCaptcha = () => {
    // Google reCaptcha is injected outside of the react app and we don't want it shown
    // when the user is logged in
    const recaptcha = document.getElementsByClassName("grecaptcha-badge");
    if (recaptcha && recaptcha.length > 0) {
      recaptcha[0].style.visibility = this.props.isAuthenticated
        ? "hidden"
        : "visible";
    }
  };

  onLogout = e => {
    this.props.logout();
    this.props.history.push(ROUTE_LOGIN);
  };

  redirectToMyAccount = e => {
    this.props.history.push(ROUTE_MY_ACCOUNT);
  };

  onToggleMobileMenu = () => {
    this.setState({
      showMobileMenu: !this.state.showMobileMenu
    });
    document.addEventListener("click", this.onHideMobileMenu);
  };

  onHideMobileMenu = () => {
    this.setState({
      showMobileMenu: false
    });
    document.removeEventListener("click", this.onHideMobileMenu);
  };

  render() {
    console.log(this.props.location.pathname);
    const showGuestNavigation =
      this.props.location.pathname === ROUTE_CART + "/" ||
      this.props.location.pathname === ROUTE_CART_CHECKOUT ||
      this.props.location.pathname === ROUTE_SHOP_DOMAINS ||
      this.props.location.pathname.startsWith(ROUTE_SHOP_HOSTING) ||
      this.props.location.pathname === ROUTE_REFERRAL_PROGRAM_APPLY ||
      this.props.location.pathname === ROUTE_EMAIL_VERIFY;

    // const showGuestNavigation = true;
    return (
      <ThemeProvider theme={DefaultTheme}>
        <StyledApp className="App h-100 w-100">
          {!this.props.isAuthenticated &&
            !this.props.isFetchingCustomerPersonalInformation &&
            GuestRoutes(
              this.props.isAuthenticated,
              this.onLogout,
              this.onToggleMobileMenu,
              showGuestNavigation
            )}
          {this.props.isAuthenticated &&
            !this.props.isFetchingCustomerPersonalInformation &&
            this.props.isCustomerPersonalInformationComplete &&
            AuthenticatedRoutes(
              this.props.isAuthenticated,
              this.props.isFetchingCustomerPersonalInformation,
              this.onLogout,
              this.props.customerPersonalInformation,
              this.redirectToMyAccount,
              this.onToggleMobileMenu,
              this.state.showMobileMenu
            )}

          {this.props.isAuthenticated &&
            !this.props.isFetchingCustomerPersonalInformation &&
            !this.props.isCustomerPersonalInformationComplete &&
            UpdatePersonalInformationRoutes(
              this.props.customerPersonalInformation
            )}

          {!this.props.isAuthenticated &&
            !this.props.isFetchingCustomerPersonalInformation &&
            !this.props.isCustomerPersonalInformationComplete &&
            null}
        </StyledApp>
      </ThemeProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state),
    isFetchingAuthentication: isFetchingAuthentication(state),
    customerPersonalInformation: getCustomerPersonalInformation(state),
    isFetchingCustomerPersonalInformation: isFetchingCustomerPersonalInformation(
      state
    ),
    isCustomerPersonalInformationComplete: isCustomerPersonalInformationComplete(
      state
    )
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      logout,
      fetchCountries,
      fetchCustomerPersonalInformation,
      updateCustomerLanguage,
      fetchNotifications,
      listenForNotifications,
      stopListeningForNotifications,
      fetchSystemPreferences,
      fetchCredit
    },
    dispatch
  );
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withLocalize(withSizes(isLaptop)(App)))
);
