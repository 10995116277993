import React from "react";
import {Translate} from "react-localize-redux";
import GenerateCode from "../GenerateCode";
import GenerateLinkContent from "../GenerateLinkContent";

export default class MyReferralCode extends React.Component{

    render() {
        return(
            <Translate>
                {({translate}) => (
                    <div className="row">
                        <div className="col-md-5 col-sm-5 col-md-5">
                            <GenerateCode/>
                        </div>
                        <div className="col-md-7 col-sm-7 col-md-7">
                             <GenerateLinkContent/>
                        </div>
                    </div>
                )}
            </Translate>
        )
    }
}