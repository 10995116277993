import React from "react";

import Loader from "../Loader";

const FilterLoader = () => (
  <Loader width="380" height="380">
    <rect x="0" y="0" width="100" height="30" rx="3" />
    <rect x="0" y="65" width="380" height="40" rx="3" />
    <rect x="0" y="135" width="380" height="40" rx="3" />
    <rect x="0" y="205" width="380" height="40" rx="3" />
    <rect x="0" y="275" width="380" height="40" rx="3" />
  </Loader>
);

export default FilterLoader;
