import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";

import Filter from "../../../components/Filter";
import Container from "../../../components/Containers/Container";
import Form from "../../../components/Forms/Form";
import SelectBox from "../../../components/SelectBox";

const StyledServersFilter = styled.div`
  margin-bottom: 30px;
`;

class ServersFilter extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate }) => (
          <StyledServersFilter>
            <Container>
              <Filter
                title={translate("servers.filter.title")}
                clearTitle={translate("servers.filter.clear-all")}
                count={this.props.countFiltersApplied()}
                onClear={this.props.onFiltersClear}
              >
                <Form>
                  <SelectBox
                    name="status"
                    label={translate("filters.status")}
                    placeholder={translate("filters.status-placeholder")}
                    async={true}
                    loadOptions={this.props.filterStatusOptions}
                    defaultOptions
                    isSearchable={false}
                    input={{ onChange: this.props.onStatusFilterOptionChange }}
                    value={
                      this.props.servers.filters.status !== ""
                        ? {
                            value: this.props.servers.filters.status,
                            label: translate(
                              "servers.filter.filter-statuses." +
                                this.props.servers.filters.status
                            )
                          }
                        : false
                    }
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue &&
                      translate("servers.filter.no-status-options")
                    }
                  />
                </Form>
              </Filter>
            </Container>
          </StyledServersFilter>
        )}
      </Translate>
    );
  }
}

ServersFilter.defaultProps = {
  count: 0,
  onClear: () => {}
};

export default ServersFilter;
