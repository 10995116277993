import * as proInvoicesService from "../../../../services/proInvoicesService.js";

export const PROINVOICES_FEATURE = "features/ProInvoices";
export const PROINVOICES_CLEAR_FILTERS = `${PROINVOICES_FEATURE}/PROINVOICES_CLEAR_FILTERS`;
export const PROINVOICES_REQUEST = `${PROINVOICES_FEATURE}/PROINVOICES_REQUEST`;
export const PROINVOICES_REQUEST_STATUS_COLLECTION = `${PROINVOICES_FEATURE}/PROINVOICES_REQUEST_STATUS_COLLECTION`;
export const PROINVOICES_RECEIVE = `${PROINVOICES_FEATURE}/PROINVOICES_RECEIVE`;
export const PROINVOICES_RECEIVE_STATUS_COLLECTION = `${PROINVOICES_FEATURE}/PROINVOICES_RECEIVE_STATUS_COLLECTION`;
export const PROINVOICES_UPDATE_PROINVOICE_PAYER = `${PROINVOICES_FEATURE}/PROINVOICES_UPDATE_PROINVOICE_PAYER`;
export const PROINVOICES_LIST_SELECT_TABLE_ROW = `${PROINVOICES_FEATURE}/PROINVOICES_LIST_SELECT_TABLE_ROW`;
export const PROINVOICES_LIST_DESELECT_TABLE_ROW = `${PROINVOICES_FEATURE}/PROINVOICES_LIST_DESELECT_TABLE_ROW`;
export const PROINVOICES_LIST_SELECT_ALL_TABLE_ROWS = `${PROINVOICES_FEATURE}/PROINVOICES_LIST_SELECT_ALL_TABLE_ROWS`;
export const PROINVOICES_LIST_DESELECT_ALL_TABLE_ROWS = `${PROINVOICES_FEATURE}/PROINVOICES_LIST_DESELECT_ALL_TABLE_ROWS`;
export const PROINVOICES_FOR_WIDGET_REQUEST = `${PROINVOICES_FEATURE}/PROINVOICES_FOR_WIDGET_REQUEST`;
export const PROINVOICES_FOR_WIDGET_RECEIVE = `${PROINVOICES_FEATURE}/PROINVOICES_FOR_WIDGET_RECEIVE`;
export const PROINVOICES_MASS_PAY_REQUEST = `${PROINVOICES_FEATURE}/PROINVOICES_MASS_PAY_REQUEST`;
export const PROINVOICES_MASS_PAY_RECEIVE = `${PROINVOICES_FEATURE}/PROINVOICES_MASS_PAY_RECEIVE`;
export const PROINVOICES_CANCEL_REQUEST = `${PROINVOICES_FEATURE}/PROINVOICES_CANCEL_PROINVOICE_REQUEST`;
export const PROINVOICES_CANCEL_RECEIVE = `${PROINVOICES_FEATURE}/PROINVOICES_CANCEL_PROINVOICE_RECEIVE`;

export function selectProInvoicesListTableRow(proInvoice) {
  return dispatch => {
    dispatch(proInvoicesListSelectTableRow(proInvoice));
  };
}

export function deselectProInvoicesListTableRow(proInvoice) {
  return dispatch => {
    dispatch(proInvoicesListDeselectTableRow(proInvoice));
  };
}

export function selectAllProInvoicesListTableRows() {
  return dispatch => {
    dispatch(proInvoicesListSelectAllTableRows());
  };
}

export function deselectAllProInvoicesListTableRows() {
  return dispatch => {
    dispatch(proInvoicesListDeselectAllTableRows());
  };
}

export function fetchProInvoices(limit, page, sort, filters) {
  return dispatch => {
    dispatch(requestProInvoices());
    return proInvoicesService
      .all(limit, page, sort, filters)
      .then(
        result => {
          if (result && result.data) {
            const { invoices, paging, filters, sort } = result.data.data;
            dispatch(receiveProInvoices(invoices, paging, filters, sort));
          }
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function clearProInvoicesFilters() {
  return dispatch => {
    dispatch(dispatchClearProInvoicesFilters());
  };
}

export function fetchProInvoicesForWidget(limit, sort, filter) {
  return dispatch => {
    dispatch(requestProInvoicesForWidget());
    return proInvoicesService
      .all(limit, 1, sort, filter)
      .then(
        result => {
          const { invoices } = result.data.data;
          dispatch(receiveProInvoicesForWidget(invoices));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function doMassPay(invoiceIds) {
  return dispatch => {
    dispatch(requestMassPay());
    return proInvoicesService
      .massPay(invoiceIds)
      .then(
        result => {
          const { error } = result.data;
          const { messages, invoice } = result.data.data;

          dispatch(receiveMassPay());

          return { error, messages, invoice };
        },
        error => {
          dispatch(receiveMassPay());
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(receiveMassPay());
        const { messages } = error.response.data.data;
        return { error: true, messages: messages };
      });
  };
}

export function fetchProInvoicesStatusCollection() {
  return dispatch => {
    dispatch(requestProInvoicesStatusCollection());
    return proInvoicesService
      .fetchProInvoiceFilterStatusCollection()
      .then(
        result => {
          const { statuses } = result.data.data;
          dispatch(receiveProInvoicesStatusCollection(statuses));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchProInvoice(id) {
  return dispatch => {
    return proInvoicesService.fetchProInvoice(id).then(result => {
      const { invoice, payment_methods, payment_gateway } = result.data.data;
      return {
        proinvoice: invoice,
        payment_methods,
        payment_gateway
      };
    });
  };
}

export function downloadProInvoice(id) {
  return dispatch => {
    return proInvoicesService
      .downloadProInvoice(id)
      .then(
        result => {
          const { error } = !result.status ? result.data : { error: false };
          const { messages } = !result.status
            ? result.data.data
            : { messages: [] };

          return { error, messages, file: result };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function applyCreditToProInvoice(proinvoice, amount) {
  return dispatch => {
    return proInvoicesService
      .applyCreditToProInvoice(proinvoice, amount)
      .then(
        result => {
          const { error } = result.data;
          const { messages, invoice } = result.data.data;

          return { error, messages, invoice };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        const { messages } = error.response.data.data;
        return { error: true, messages: messages };
      });
  };
}

export function forwardProInvoice(proinvoice, email, message = "") {
  return dispatch => {
    return proInvoicesService
      .forwardProInvoice(proinvoice, email, message)
      .then(result => {
        const { error } = result.data;
        const { messages } = result.data.data;
        return { error, messages };
      });
  };
}

export function updateProInvoicePayment(proinvoice, paymentMethod) {
  return dispatch => {
    return proInvoicesService
      .updateProInvoicePaymentMethod(proinvoice, paymentMethod)
      .then(
        result => {
          const { error } = result.data;
          const { messages } = result.data.data;

          if (error) {
            return { error: true, messages: messages };
          }

          const {
            invoice,
            payment_methods,
            payment_gateway
          } = result.data.data;

          return {
            invoice,
            payment_methods,
            payment_gateway
          };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function payProInvoice(proinvoice, paymentGateways) {
  return dispatch => {
    return proInvoicesService.payProInvoice(proinvoice, paymentGateways);
  };
}

export function assignProInvoicePayer(proinvoice, payer) {
  return dispatch => {
    return proInvoicesService
      .assignProInvoicePayer(proinvoice, payer)
      .then(
        result => {
          const { error } = result.data;
          const { messages } = result.data.data;

          dispatch(updateProInvoicePayer(proinvoice, payer));

          return { error, messages, proinvoice, payer };
        },
        error => {
          const { messages } = error.response.data.data;
          dispatch(updateProInvoicePayer(proinvoice, payer));
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(updateProInvoicePayer(proinvoice, payer));
        return { error: true, messages: [error.message] };
      });
  };
}

export function cancelProInvoice(proinvoice) {
  return dispatch => {
    dispatch(requestCancelProInvoice(proinvoice));
    return proInvoicesService
      .cancelProInvoice(proinvoice)
      .then(
        result => {
          const { messages, invoice } = result.data.data;
          const { error } = result.data;
          dispatch(receiveCancelProInvoice(invoice));
          return {
            messages,
            invoice,
            error
          };
        },
        err => {
          const { error } = err.response.data;
          const { messages } = err.response.data.data;
          return {
            error,
            messages
          };
        }
      )
      .catch(err => {
        const { error } = err.response.data;
        const { messages } = err.response.data.data;
        return {
          error,
          messages
        };
      });
  };
}

function dispatchClearProInvoicesFilters() {
  return {
    type: PROINVOICES_CLEAR_FILTERS
  };
}

function requestProInvoices() {
  return {
    type: PROINVOICES_REQUEST
  };
}

function requestProInvoicesStatusCollection() {
  return {
    type: PROINVOICES_REQUEST_STATUS_COLLECTION
  };
}

function receiveProInvoices(proinvoices, paging, filters, sort) {
  return {
    type: PROINVOICES_RECEIVE,
    payload: { proinvoices, paging, filters, sort }
  };
}

function receiveProInvoicesStatusCollection(statuses) {
  return {
    type: PROINVOICES_RECEIVE_STATUS_COLLECTION,
    payload: { statuses }
  };
}

function updateProInvoicePayer(proinvoice, payer) {
  return {
    type: PROINVOICES_UPDATE_PROINVOICE_PAYER,
    payload: { proinvoice, payer }
  };
}

function proInvoicesListSelectTableRow(product) {
  return {
    type: PROINVOICES_LIST_SELECT_TABLE_ROW,
    payload: product
  };
}

function proInvoicesListDeselectTableRow(product) {
  return {
    type: PROINVOICES_LIST_DESELECT_TABLE_ROW,
    payload: product
  };
}

function proInvoicesListSelectAllTableRows() {
  return {
    type: PROINVOICES_LIST_SELECT_ALL_TABLE_ROWS
  };
}

function proInvoicesListDeselectAllTableRows() {
  return {
    type: PROINVOICES_LIST_DESELECT_ALL_TABLE_ROWS
  };
}

function requestProInvoicesForWidget() {
  return {
    type: PROINVOICES_FOR_WIDGET_REQUEST
  };
}

function receiveProInvoicesForWidget(proinvoices) {
  return {
    type: PROINVOICES_FOR_WIDGET_RECEIVE,
    payload: proinvoices
  };
}

function requestMassPay() {
  return {
    type: PROINVOICES_MASS_PAY_REQUEST
  };
}

function receiveMassPay() {
  return {
    type: PROINVOICES_MASS_PAY_RECEIVE
  };
}

function requestCancelProInvoice(proinvoice) {
  return {
    type: PROINVOICES_CANCEL_REQUEST,
    payload: proinvoice
  };
}

function receiveCancelProInvoice(proinvoice) {
  return {
    type: PROINVOICES_CANCEL_RECEIVE,
    payload: proinvoice
  };
}
