import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";

import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";

const StyledCompactCartProductAddon = styled.div`
  padding-left: 10px;
  padding-top: 15px;
`;

const StyledAddonTitle = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutralBase};
`;

const CompactCartProductAddon = ({ service }) => (
  <StyledCompactCartProductAddon>
    <div className="row">
      <div className="col-6 col-sm-6 col-md-6">
        <StyledAddonTitle>+ {service.name}</StyledAddonTitle>
      </div>
      <div className="col-6 col-sm-6 col-md-6 text-right">
        <DiscountedAmountBadge
          amount={{
            amount:
              service.pricing.buy.billing_cycle.derivations[0].price.value,
            currency:
              service.pricing.buy.billing_cycle.derivations[0].price.currency
                .code
          }}
        />
      </div>
    </div>
  </StyledCompactCartProductAddon>
);

CompactCartProductAddon.defaultProps = {
  type: "",
  price: {
    currency: "EUR",
    amount: 10
  }
};

export default CompactCartProductAddon;
