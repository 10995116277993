import * as cartService from "../../../services/cartService.js";
import * as referralService from "../../../services/referralService";

export const CART_FEATURE = "features/Cart";
export const CART_REQUEST = `${CART_FEATURE}/CART_REQUEST`;
export const CART_RECEIVE = `${CART_FEATURE}/CART_RECEIVE`;
export const CART_ADD_PRODUCT = `${CART_FEATURE}/CART_ADD_PRODUCT`;
export const CART_ADD_PRODUCT_REQUEST = `${CART_FEATURE}/CART_ADD_PRODUCT_REQUEST`;
export const CART_ADD_PRODUCT_RECEIVE = `${CART_FEATURE}/CART_ADD_PRODUCT_RECEIVE`;
export const CART_REMOVE_PRODUCT = `${CART_FEATURE}/CART_REMOVE_PRODUCT`;
export const CART_ASSIGN_DOMAIN_TO_PRODUCT = `${CART_FEATURE}/CART_ASSIGN_DOMAIN_TO_PRODUCT`;
export const CART_ATTACH_SERVICE_TO_PRODUCT = `${CART_FEATURE}/CART_ATTACH_SERVICE_TO_PRODUCT`;
export const CART_REMOVE_SERVICE_FROM_PRODUCT = `${CART_FEATURE}/CART_REMOVE_SERVICE_FROM_PRODUCT`;
export const CART_CHANGE_PRODUCT_CONTACT = `${CART_FEATURE}/CART_CHANGE_PRODUCT_CONTACT`;
export const CART_CHANGE_DOMAIN_EPP_CODE = `${CART_FEATURE}/CART_CHANGE_DOMAIN_EPP_CODE`;
export const CART_UPDATE_PRODUCT = `${CART_FEATURE}/CART_UPDATE_PRODUCT`;
export const CART_CLEAR = `${CART_FEATURE}/CART_CLEAR`;
export const CART_APPLY_PROMOTION_REQUEST = `${CART_FEATURE}/CART_APPLY_PROMOTION_REQUEST`;
export const CART_APPLY_PROMOTION_RECEIVE = `${CART_FEATURE}/CART_APPLY_PROMOTION_RECEIVE`;
export const CART_CLEAR_PROMOTION_REQUEST = `${CART_FEATURE}/CART_CLEAR_PROMOTION_REQUEST`;
export const CART_CLEAR_PROMOTION_RECEIVE = `${CART_FEATURE}/CART_CLEAR_PROMOTION_RECEIVE`;


export const CART_APPLY_REFERRER_REQUEST = `${CART_FEATURE}/CART_APPLY_REFERRER_REQUEST`;
export const CART_APPLY_REFERRER_RECEIVE = `${CART_FEATURE}/CART_APPLY_REFERRER_RECEIVE`;
export const CART_CLEAR_REFERRER_REQUEST = `${CART_FEATURE}/CART_CLEAR_REFERRER_REQUEST`;
export const CART_CLEAR_REFERRER_RECEIVE = `${CART_FEATURE}/CART_CLEAR_REFERRER_RECEIVE`;

export const CART_BLOCK_CHECKOUT_BY_PRODUCT = `${CART_FEATURE}/CART_BLOCK_CHECKOUT_BY_PRODUCT`;
export const CART_UNBLOCK_CHECKOUT_BY_PRODUCT = `${CART_FEATURE}/CART_UNBLOCK_CHECKOUT_BY_PRODUCT`;

export function fetchCart() {
  return dispatch => {
    dispatch(requestCart());
    return cartService
      .all()
      .then(
        result => {
          const { cart } = result.data.data;
          cartService.storeCartId(cart.id);
          dispatch(receiveCart(cart));
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(applyPromotionCodeReceive());
        dispatch(applyReferrerCodeReceive());
        return { error: true, messages: [error.message] };
      });
  };
}

export function addProductToCart(product) {
  return dispatch => {
    dispatch(addProductToCartRequest());
    return cartService
      .add(product)
      .then(
        result => {
          const { error } = result.data;
          const { messages, cart } = result.data.data;

          cartService.storeCartId(cart.id);
          dispatch(receiveCart(cart));

          dispatch(addProductToCartReceive());

          return { error, messages };
        },
        error => {
          const { messages } = error.response.data.data;
          dispatch(addProductToCartReceive());
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(addProductToCartReceive());
        return { error: true, messages: [error.message] };
      });
  };
}

export function applyPromotionCode(promotionCode) {
  return dispatch => {
    dispatch(applyPromotionCodeRequest(promotionCode));
    return cartService
      .applyPromotionCode(promotionCode)
      .then(
        result => {
          const { error } = result.data;
          const { messages, cart } = result.data.data;

          cartService.storeCartId(cart.id);
          dispatch(receiveCart(cart));

          dispatch(applyPromotionCodeReceive());

          return { error, messages };
        },
        error => {
          const { messages } = error.response.data.data;

          dispatch(applyPromotionCodeReceive());
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(applyPromotionCodeReceive());
        return { error: true, messages: [error.message] };
      });
  };
}

export function clearPromotionCode() {
  return dispatch => {
    dispatch(clearPromotionCodeRequest());
    return cartService
      .clearPromotionCode()
      .then(
        result => {
          const { error } = result.data;
          const { messages, cart } = result.data.data;

          cartService.storeCartId(cart.id);
          dispatch(receiveCart(cart));

          dispatch(clearPromotionCodeReceive());

          return { error, messages };
        },
        error => {
          const { messages } = error.response.data.data;

          dispatch(clearPromotionCodeReceive());
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        dispatch(clearPromotionCodeReceive());
        return { error: true, messages: [error.message] };
      });
  };
}

export function applyReferrerCode(referral_code) {
    return dispatch => {
        dispatch(applyReferrerCodeRequest(referral_code));
        return cartService
            .applyReferrerCode(referral_code)
            .then(
                result => {
                    referralService._setReferrerCode(referral_code)
                    const { error } = result.data;
                    const { messages, cart } = result.data.data;

                    dispatch(applyReferrerCodeReceive());
                    dispatch(fetchCart());

                    return { error, messages };
                },
                error => {
                    const { messages } = error.response.data.data;
                    dispatch(applyReferrerCodeReceive());
                    referralService._removeReferrerCode();


                    dispatch(clearReferrerCodeReceive());
                    return { error: true, messages: messages };
                }
            )
            .catch(error => {
                dispatch(applyReferrerCodeReceive());
                referralService._removeReferrerCode();


                dispatch(clearReferrerCodeReceive());
                return { error: true, messages: [error.message] };
            });
    };
}

export function clearReferrerCode() {
    return dispatch => {
        dispatch(clearReferrerCodeRequest());
        return cartService
            .clearReferrerCode()
            .then(
                result => {
                    const { error } = result.data;
                    const { messages, cart } = result.data.data;
                    referralService._removeReferrerCode();


                    dispatch(clearReferrerCodeReceive());

                    dispatch(fetchCart());

                    return { error, messages };
                },
                error => {
                    const { messages } = error.response.data.data;

                    dispatch(clearReferrerCodeReceive());
                    return { error: true, messages: messages };
                }
            )
            .catch(error => {
                dispatch(clearReferrerCodeReceive());
                return { error: true, messages: [error.message] };
            });
    };
}

export function removeProductFromCart(product) {
  return dispatch => {
    return cartService
      .remove(product)

      .then(
        result => {
          const { error } = result.data;
          const { messages, cart } = result.data.data;

          cartService.storeCartId(cart.id);
          dispatch(receiveCart(cart));

          return { error, messages };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function updateProduct(product, type, period) {
  return dispatch => {
    dispatch(productUpdate(product, type, period));
    return cartService.update(product, type, period).then(result => {
      const { cart } = result.data.data;
      cartService.storeCartId(cart.id);
      dispatch(receiveCart(cart));
      return result;
    });
  };
}

export function updateServerSettings(server, settings) {
  return dispatch => {
    // dispatch(productUpdate(server, type, period));
    return cartService.updateSettings(server, settings).then(result => {
      const { cart } = result.data.data;
      cartService.storeCartId(cart.id);
      dispatch(receiveCart(cart));
      return result;
    });
  };
}

export function changeDomainEPPCode(domain, code) {
  return {
    type: CART_CHANGE_DOMAIN_EPP_CODE,
    payload: { domain, code }
  };
}

export function updateDomainEPPCode(domain, code) {
  return dispatch => {
    dispatch({
      type: CART_CHANGE_DOMAIN_EPP_CODE,
      payload: { domain, code }
    });
    return cartService.updateDomainEPPCode(domain, code).then(result => {
      dispatch({
        type: CART_CHANGE_DOMAIN_EPP_CODE,
        payload: { domain, code }
      });
      return result;
    });
  };
}

export function updateNameserversForDomainInCart(domain, nameservers) {
  return dispatch => {
    return cartService
      .updateNameserversForDomainInCart(domain, nameservers)
      .then(result => {
        const { cart } = result.data.data;
        cartService.storeCartId(cart.id);
        dispatch(receiveCart(cart));
        return result;
      });
  };
}

export function checkoutCart(cart, paymentMethod) {
  return dispatch => {
    return cartService
      .checkoutCart(cart, paymentMethod)
      .then(
        result => {
          const { error } = result.data;
          const { messages, invoice } = result.data.data;

          return { error, messages, invoice };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

export function assignDomainToProduct(domain, product) {
  return dispatch => dispatch(cartAssignDomainToProduct(domain, product));
}

export function attachServiceToProduct(service, product) {
  return dispatch => {
    dispatch(cartAttachServiceToProduct(service, product, null, true));
    return cartService
      .attachServiceToProduct(service, product)
      .then(
        result => {
          const { error } = result.data;
          const { messages, detach_id } = result.data.data;

          dispatch(
            cartAttachServiceToProduct(service, product, detach_id, false)
          );

        dispatch(fetchCart());

          return { error, messages };
        },
        error => {
          dispatch(cartAttachServiceToProduct(service, product, null, false));
          return { error: true, messages: [error.message] };
        }
      )
      .catch(error => {
        dispatch(cartAttachServiceToProduct(service, product, null, false));
        return { error: true, messages: [error.message] };
      });
  };
}

export function removeServiceFromProduct(service, product) {
  return dispatch => {
    dispatch(cartRemoveServiceFromProduct(service, product, false, true));
    return cartService
      .removeServiceFromProduct(service, product)
      .then(
        result => {
          const { error } = result.data;
          const { messages } = result.data.data;

          dispatch(
            cartRemoveServiceFromProduct(service, product, !error, false)
          );
            dispatch(fetchCart());

          return { error, messages };
        },
        error => {
          dispatch(
            cartRemoveServiceFromProduct(service, product, false, false)
          );
          return { error: true, messages: [error.message] };
        }
      )
      .catch(error => {
        dispatch(cartRemoveServiceFromProduct(service, product, false, false));
        return { error: true, messages: [error.message] };
      });
  };
}

export function clearCart() {
  return dispatch => {
    return cartService.clearCart().then(result => {
      dispatch(cartClear());
      cartService.removeCartId();
      return result;
    });
  };
}

export function changeProductContact(product, contact) {
  return dispatch => {
    dispatch(cartChangeProductContact(product, contact));
  };
}

export function attachDomainAssistantToOrderItem(domain, contactType, contact) {
  return dispatch => {
    return cartService
      .attachDomainAssistantToOrderItem(domain, contactType, contact)
      .then(result => {
        const { cart } = result.data.data;
        cartService.storeCartId(cart.id);
        dispatch(receiveCart(cart));
      });
  };
}

export function updateDomainAssistantToOrderItem(domain, contactType, contact) {
  return dispatch => {
    return cartService
      .updateDomainAssistantToOrderItem(domain, contactType, contact)
      .then(result => {
        const { cart } = result.data.data;
        cartService.storeCartId(cart.id);
        dispatch(receiveCart(cart));
      });
  };
}

export function blockCheckoutByProduct(productId) {
  return dispatch => {
    dispatch(cartBlockCheckoutByProduct(productId));
  };
}

export function unblockCheckoutByProduct(productId) {
  return dispatch => {
    dispatch(cartUnblockCheckoutByProduct(productId));
  };
}

function requestCart() {
  return {
    type: CART_REQUEST
  };
}

function receiveCart(cart) {
  return {
    type: CART_RECEIVE,
    payload: cart
  };
}
//
//function cartAddProduct(product) {
//  return {
//    type: CART_ADD_PRODUCT,
//    payload: product
//  };
//}

//function cartRemoveProduct(product) {
//  return {
//    type: CART_REMOVE_PRODUCT,
//    payload: product
//  };
//}

function cartAssignDomainToProduct(domain, product) {
  return {
    type: CART_ASSIGN_DOMAIN_TO_PRODUCT,
    payload: {
      domain,
      product
    }
  };
}

function cartAttachServiceToProduct(service, product, detachId, updating) {
  return {
    type: CART_ATTACH_SERVICE_TO_PRODUCT,
    payload: {
      service,
      product,
      detachId,
      updating
    }
  };
}

function cartRemoveServiceFromProduct(service, product, success, updating) {
  return {
    type: CART_REMOVE_SERVICE_FROM_PRODUCT,
    payload: {
      service,
      product,
      success,
      updating
    }
  };
}

function cartChangeProductContact(product, contact) {
  return {
    type: CART_CHANGE_PRODUCT_CONTACT,
    payload: { product, contact }
  };
}

function cartClear() {
  return {
    type: CART_CLEAR
  };
}

function addProductToCartRequest() {
  return {
    type: CART_ADD_PRODUCT_REQUEST
  };
}

function addProductToCartReceive() {
  return {
    type: CART_ADD_PRODUCT_RECEIVE
  };
}

function applyPromotionCodeRequest(promotionCode) {
  return {
    type: CART_APPLY_PROMOTION_REQUEST,
    payload: promotionCode
  };
}

function applyPromotionCodeReceive() {
  return {
    type: CART_APPLY_PROMOTION_RECEIVE
  };
}

function clearPromotionCodeRequest() {
  return {
    type: CART_CLEAR_PROMOTION_REQUEST
  };
}

function clearPromotionCodeReceive() {
  return {
    type: CART_CLEAR_PROMOTION_RECEIVE
  };
}


function applyReferrerCodeRequest(referral_code) {
    return {
        type: CART_APPLY_REFERRER_REQUEST,
        payload: referral_code
    };
}

function applyReferrerCodeReceive() {
    return {
        type: CART_APPLY_REFERRER_RECEIVE
    };
}

function clearReferrerCodeRequest() {
    return {
        type: CART_CLEAR_REFERRER_REQUEST
    };
}

function clearReferrerCodeReceive() {
    return {
        type: CART_CLEAR_REFERRER_RECEIVE
    };
}

function productUpdate(product, type, period) {
  return {
    type: CART_UPDATE_PRODUCT,
    payload: {
      product,
      type,
      period
    }
  };
}

function cartBlockCheckoutByProduct(productId) {
  return {
    type: CART_BLOCK_CHECKOUT_BY_PRODUCT,
    payload: productId
  };
}

function cartUnblockCheckoutByProduct(productId) {
  return {
    type: CART_UNBLOCK_CHECKOUT_BY_PRODUCT,
    payload: productId
  };
}
