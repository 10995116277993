import * as preferencesService from "../../../services/preferencesService.js";

export const SYSTEM_PREFERENCE_FEATURE = "features/Preference";

export const SYSTEM_PREFERENCES_FETCH_REQUEST = `${SYSTEM_PREFERENCE_FEATURE}/SYSTEM_PREFERENCES_FETCH_REQUEST`;
export const SYSTEM_PREFERENCES_FETCH_RECEIVE = `${SYSTEM_PREFERENCE_FEATURE}/SYSTEM_PREFERENCES_FETCH_RECEIVE`;

export function fetchSystemPreferences() {
  return dispatch => {
    dispatch(requestSystemPreferences());
    return preferencesService
      .fetchSystemPreferences()
      .then(
        result => {
          const { error } = result.data;
          const { messages, preferences } = result.data.data;

          if (!error) {
            dispatch(receiveSystemPreferences(preferences));
          }

          return { error, messages };
        },
        error => {
          const { messages } = error.response.data.data;
          return { error: true, messages: messages };
        }
      )
      .catch(error => {
        return { error: true, messages: [error.message] };
      });
  };
}

function requestSystemPreferences() {
  return {
    type: SYSTEM_PREFERENCES_FETCH_REQUEST
  };
}

function receiveSystemPreferences(preferences) {
  return {
    type: SYSTEM_PREFERENCES_FETCH_RECEIVE,
    payload: preferences
  };
}
