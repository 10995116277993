import * as SA from "../actions/securityActions"; //

import handleReducerActions from "../../../core/handleReducerActions";

import { APP_STATE_COMPLETE_RESET } from "../../AppState/actions/appStateActions";

const initialState = {
  questions: [],
  isFetchingSecurityQuestions: false,
  isUpdatingSecurityQuestion: false,
  isChangingPassword: false
};

const securityStateHandlers = {
  [SA.SECURITY_QUESTIONS_FETCH_REQUEST]: fetchSecurityQuestionsRequest,
  [SA.SECURITY_QUESTIONS_FETCH_RECEIVE]: fetchSecurityQuestionsReceive,
  [SA.SECURITY_QUESTION_UPDATE_REQUEST]: updateSecurityQuestionRequest,
  [SA.SECURITY_QUESTION_UPDATE_RECEIVE]: updateSecurityQuestionReceive,
  [SA.SECURITY_PASSWORD_UPDATE_REQUEST]: changePasswordRequest,
  [SA.SECURITY_PASSWORD_UPDATE_RECEIVE]: changePasswordReceive,
  [APP_STATE_COMPLETE_RESET]: resetState
};

export default function(state = initialState, action) {
  return handleReducerActions(securityStateHandlers, state, action);
}

function fetchSecurityQuestionsRequest(state, action) {
  return {
    ...state,
    isFetchingSecurityQuestions: true
  };
}

function fetchSecurityQuestionsReceive(state, action) {
  return {
    ...state,
    questions: action.payload,
    isFetchingSecurityQuestions: false
  };
}

function updateSecurityQuestionRequest(state, action) {
  return {
    ...state,
    isUpdatingSecurityQuestion: true
  };
}

function updateSecurityQuestionReceive(state, action) {
  return {
    ...state,
    isUpdatingSecurityQuestion: false
  };
}

function changePasswordRequest(state, action) {
  return {
    ...state,
    isChangingPassword: true
  };
}

function changePasswordReceive(state, action) {
  return {
    ...state,
    isChangingPassword: false
  };
}

function resetState(state) {
  return { ...initialState };
}

export function getSecurityQuestions(state) {
  return state.security.questions;
}

export function isFetchingSecurityQuestions(state) {
  return state.security.isFetchingSecurityQuestions;
}

export function isUpdatingSecurityQuestion(state) {
  return state.security.isUpdatingSecurityQuestion;
}

export function isChangingPassword(state) {
  return state.security.isChangingPassword;
}
