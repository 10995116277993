import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { withRouter } from "react-router-dom";

const StyledTabs = styled.nav`
  &.nav-tabs {
    border-bottom: 1px solid ${theme.neutral3};
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
`;
const StyledTabsContent = styled.div`
  height: 100%;
`;

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    const { pathname } = this.props.location;
    this.state = {
      activeTab: this.props.withoutRouter
        ? this.props.defaultTab
        : pathname || this.props.defaultTab
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({
        activeTab: nextProps.location.pathname
      });
    }
  }
  onSelect = tab => {
    this.setState({ activeTab: tab });
  };

  render() {
    return (
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <StyledTabs className="nav nav-tabs">
            {this.props.renderTabs(this.state.activeTab, this.onSelect)}
          </StyledTabs>
          <StyledTabsContent className="tab-content">
            {this.props.renderTabContent(this.state.activeTab)}
          </StyledTabsContent>
        </div>
      </div>
    );
  }
}

export default withRouter(Tabs);
