import axios from "axios";
import {REFERRAL_GET_CODE, REFERRAL_GET_INVOICES, REFERRAL_SEND_EMAIL} from "./endpoints";

export function generateCode(){
  return axios.get(REFERRAL_GET_CODE)
}

export function fetchInvoices(limit, page, sort, filters){
  return axios.get(REFERRAL_GET_INVOICES, {
        params: {
         limit: limit ? limit : 10,
          page: page ? page : 1,
          sort_by: sort ? sort.sort_by : "",
          order_by: sort ? sort.order_by : "",
          ...filters
    }
  })
}

export function sendEmailToFriend(email){
  return axios.post(REFERRAL_SEND_EMAIL, {
    email
  })
}

export function _getReferrerCode(){
  return localStorage.getItem('referral_code') || "";
}

export function _setReferrerCode(referralCode){
  localStorage.setItem('referral_code', referralCode)
}

export function _removeReferrerCode(){
  localStorage.removeItem('referral_code')
}

