import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

const StyledExternalLink = styled.a`
  font-size: 16px;
  font-weight: 400;
  line-height: 1.38;
  color: ${theme.blueBase};
`;

const ExternalLink = ({ children, ...rest }) => (
  <StyledExternalLink {...rest} target="_blank" rel="noreferrer noopener">
    {children}
  </StyledExternalLink>
);

export default ExternalLink;
