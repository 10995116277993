import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Link } from "react-router-dom";

const StyledDropdownItem = styled.a`
  font-size: 14px;
  line-height: 1.43;
  cursor: pointer;
  color: ${theme.neutralBase};

  &:hover {
    background-color: ${theme.neutral1};
  }

  &:active:not(.disabled) {
    background-color: ${theme.blueBase};
    color: ${theme.white} !important;
  }

  &.disabled {
    color: ${theme.neutral4} !important;
  }
`;

const StyledRouterLink = styled(Link)`
  font-size: 14px;
  line-height: 1.43;
  cursor: pointer;
  color: ${theme.neutralBase};

  &:hover {
    background-color: ${theme.neutral1};
  }

  &:active {
    background-color: ${theme.blueBase};
    color: ${theme.white} !important;
  }

  &.disabled {
    color: ${theme.neutral4} !important;
  }
`;

const DropdownItem = props =>
  props.to ? (
    <Link
      {...props}
      className={`dropdown-item ${props.className} ${
        props.disabled ? "disabled" : ""
      }`}
    />
  ) : !props.disabled ? (
    <StyledDropdownItem
      {...props}
      className={`dropdown-item ${props.className} ${
        props.disabled ? "disabled" : ""
      }`}
    />
  ) : (
    <StyledDropdownItem
      {...props}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
      }}
      className={`dropdown-item ${props.className} ${
        props.disabled ? "disabled" : ""
      }`}
    />
  );
DropdownItem.defaultProps = {
  disabled: false,
  selected: false
};

export default DropdownItem;
