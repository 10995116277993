import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../../../design/Themes";
import { withLocalize } from "react-localize-redux";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import isLaptop from "../../../../../../../utils/isLaptopScreen";
import { withSizes } from "react-sizes";

import Label from "../../../../../../../components/Label";

const StyledType = styled.span`
  display: inline-block;
  width: ${props => {
    if (props.isLaptop) return "80px";
    return "150px";
  }}
  margin-right: ${props => {
    if (props.isLaptop) return "20px";
    return "40px";
  }}
  vertical-align: top;

  & label {
    display: block;
  }

  & strong {
    color: ${theme.neutralBase};
    font-size: 16px;
    display: block;
    margin-top: 13px;
  }
`;

const RecordType = props => {
  return (
    <StyledType
      isMobile={isMobileOnly}
      isTablet={isTablet}
      isLaptop={props.isLaptop}
      isBrowser={isBrowser}
    >
      <Label>{props.translate("domain.zone-file.type")}</Label>
      <strong>{props.input.value}</strong>
    </StyledType>
  );
};

export default withLocalize(withSizes(isLaptop)(RecordType));
