import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Translate } from "react-localize-redux";

import { withRouter } from "react-router-dom";

import OpenNewTicketForm from "./OpenNewTicketForm";

import { FilesEncoder } from "../../../common/filesEncoder";

import {
  fetchTicketDepartments,
  createTicket,
  fetchHelpDeskKnowledgeBaseMostPopularArticles,
  fetchTicketRelatedProducts
} from "../actions/helpdeskActions";
import {
  getTicketDepartments,
  isFetchingTicketDepartments,
  getKnowledgeBaseMostPopularArticles,
  isFetchingKnowledgeBaseMostPopularArticles,
  isFetchingTicketRelatedProducts,
  getTicketRelatedProducts
} from "../reducers/helpdeskReducer";

import {
  validateSubject,
  validateMessage,
  validateDepartment,
  validatePriority,
  validateRelatedProduct
} from "../../../common/validationRules";

import Breadcrumb from "../../../components/Breadcrumb";
import BreadcrumbItem from "../../../components/Breadcrumb/BreadcrumbItem";

import Container from "../../../components/Containers/Container";

import SectionHeader from "../../../components/SectionHeader";

import MostPopularArticles from "../../../components/KnowledgeBase/MostPopularArticles";

import {
  compileRoute,
  ROUTE_INDEX,
  ROUTE_HELPDESK,
  ROUTE_HELPDESK_VIEW_KNOWLEDGE_BASE_ARTICLE,
  ROUTE_HELPDESK_TICKET_VIEW
} from "../../../routes/routes";

import KnowledgeBaseCategoriesLoader from "../../../components/Loaders/KnowledgeBaseCategoriesLoader";
import withLoading from "../../../components/Loaders/WithLoading";
import KnowledgeBaseArticle from "../../../components/KnowledgeBase/KnowledgeBaseArticle";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

const StyledOpenNewTicket = styled.div`
  padding-bottom: 40px;
`;

const StyledMostPopularArticlesHolder = styled.div`
  @media (max-width: 1199px) {
    margin-top: 40px;
  }
`;

const StyledKnowledgeBaseContainer = styled.div``;

class OpenNewTicket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      relatedProducts: null,
      fetchingRelatedProducts: false,
      departments: null
    };

    this.knowledgeArticlePath = compileRoute(
      ROUTE_HELPDESK_VIEW_KNOWLEDGE_BASE_ARTICLE
    );

    this.viewTicketPath = compileRoute(ROUTE_HELPDESK_TICKET_VIEW);
  }

  componentDidMount() {
    const selectedDepartment = this.props.match.params.id ? this.props.match.params.id : 3;
    this.setState({
      selectedDepartment
    });

    if (!this.props.knowledgeBaseMostPopularArticles.length)
      this.props.fetchHelpDeskKnowledgeBaseMostPopularArticles();
  }

  remapTicketDepartments = (value, callback) => {
    this.props.fetchTicketDepartments().then(() => {
      const departments = this.props.departments.map(department => {
        return {
          label: department.name,
          value: department.id
        };
      });
      callback(departments);
      this.setState({ departments });
    });
  };

  remapTicketRelatedProducts = (value, callback) => {
    if (!this.state.relatedProducts) {
      this.setState({
        fetchingRelatedProducts: true
      });
      this.props.fetchTicketRelatedProducts().then(() => {
        const relatedProducts = this.props.relatedProducts.map(product => {
          return {
            label: product.name,
            value: product.id
          };
        });
        this.setState({
          relatedProducts,
          fetchingRelatedProducts: false
        });
        callback(relatedProducts);
      });
    } else {
      const relatedProducts = this.state.relatedProducts.filter(
        product => product.label.indexOf(value) > -1
      );
      callback(relatedProducts);
    }
  };

  validateTicket = values => {
    return {
      subject: validateSubject(values.subject, true),
      message: validateMessage(values.message, true),
      department: validateDepartment(values.department, true),
      related_product_id: validateRelatedProduct(
        values.related_product_id,
        false
      ),
      priority: validatePriority(values.priority, true)
    };
  };

  onSubmit = values => {
    this.setState({ submitting: true });
    if (values.attachments) {
      return this.prepareAttachments(values.attachments).then(attachments => {
        const ticket = {
          ...values,
          attachments
        };
        return this.doCreateTicket(ticket);
      });
    }
    return this.doCreateTicket(values);
  };

  doCreateTicket = values => {
    return this.props
      .createTicket(values)
      .then(({ ticket, error, messages }) => {
        displayToastMessageForResponse("Help Desk", { error, messages });

        this.setState({ submitting: false });

        if (!error) {
          this.props.history.push(this.viewTicketPath(ticket));
        }
      });
  };

  prepareAttachments = files => {
    return new Promise((resolve, reject) => {
      FilesEncoder.createAttachments(files).then(result => {
        resolve(result);
      });
    });
  };

  goToHelpdesk = () => {
    this.props.history.push(ROUTE_HELPDESK);
  };

  render() {
    const StyledKnowledgeBaseContainerMostPopularArticlesWithLoading = withLoading(
      StyledKnowledgeBaseContainer,
      KnowledgeBaseCategoriesLoader
    );

    return (
      <Translate>
        {({ translate }) => (
          <StyledOpenNewTicket className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <Breadcrumb>
                <BreadcrumbItem
                  to={ROUTE_INDEX}
                  title={translate("breadcrumbs.home")}
                />
                <BreadcrumbItem
                  to={ROUTE_HELPDESK}
                  title={translate("breadcrumbs.help-desk")}
                />
                <BreadcrumbItem
                  title={translate("breadcrumbs.open-new-ticket")}
                />
              </Breadcrumb>
            </div>
            <div className="col-12 col-xl-6">
              <Container className="open-ticket-main">
                <SectionHeader
                  title={translate("help-desk.ticket.open-new-ticket")}
                  subtitle={translate(
                    "help-desk.ticket.open-new-ticket-description"
                  )}
                />
                <OpenNewTicketForm
                  onSubmit={this.onSubmit}
                  validate={this.validateTicket}
                  fetchDepartments={this.remapTicketDepartments}
                  fetchRelatedProducts={this.remapTicketRelatedProducts}
                  isFetchingRelatedProducts={this.state.fetchingRelatedProducts}
                  onCancel={this.goToHelpdesk}
                  submitting={this.state.submitting}
                  departments={this.state.departments}
                  selectedDepartment={this.state.selectedDepartment}
                />
              </Container>
            </div>
            <StyledMostPopularArticlesHolder className="col-12 col-xl-6">
              <Container>
                <StyledKnowledgeBaseContainerMostPopularArticlesWithLoading
                  isLoading={
                    this.props.isFetchingKnowledgeBaseMostPopularArticles
                  }
                >
                  <MostPopularArticles>
                    {this.props.knowledgeBaseMostPopularArticles.map(
                      article => {
                        return (
                          <KnowledgeBaseArticle
                            key={`${`helpdesk-knowledge-most-populararticle-${article.id}`}`}
                            article={article}
                            title={article.title}
                            votes={article.votes}
                            categoryName={article.category_name}
                            showMeta={true}
                            articleLink={this.knowledgeArticlePath({
                              id: article.id
                            })}
                          />
                        );
                      }
                    )}
                  </MostPopularArticles>
                </StyledKnowledgeBaseContainerMostPopularArticlesWithLoading>
              </Container>
            </StyledMostPopularArticlesHolder>
          </StyledOpenNewTicket>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    departments: getTicketDepartments(state),
    isFetchingDepartments: isFetchingTicketDepartments(state),
    knowledgeBaseMostPopularArticles: getKnowledgeBaseMostPopularArticles(
      state
    ),
    isFetchingKnowledgeBaseMostPopularArticles: isFetchingKnowledgeBaseMostPopularArticles(
      state
    ),
    relatedProducts: getTicketRelatedProducts(state),
    isFetchingTicketRelatedProducts: isFetchingTicketRelatedProducts(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTicketDepartments,
      createTicket,
      fetchHelpDeskKnowledgeBaseMostPopularArticles,
      fetchTicketRelatedProducts
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OpenNewTicket));
