import React from "react";
import { Translate } from "react-localize-redux";
import { Form, Field, FormSpy } from "react-final-form";

import InputField from "../InputField";

const HostingPassword = ({
  validateChangePasswordSubmit,
  onChangePassword,
  onSubmitting,
  generatedPassword
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <Form
          onSubmit={onChangePassword}
          validate={validateChangePasswordSubmit}
          initialValues={{
            newPassword: generatedPassword ? generatedPassword : "",
            confirmNewPassword: generatedPassword ? generatedPassword : ""
          }}
          render={({ handleSubmit, submitting, invalid, form }) => (
            <form
              id="update-hosting-password-form"
              onSubmit={event => {
                form.blur("password");
                form.blur("confirmNewPassword");
                handleSubmit(event);
              }}
            >
              <Field
                name="newPassword"
                label={translate("my-account.security.new-password")}
                placeholder={translate(
                  "my-account.security.new-password-placeholder"
                )}
                type={generatedPassword ? "text" : "password"}
                component={InputField}
              />

              <Field
                name="confirmNewPassword"
                label={translate("my-account.security.confirm-new-password")}
                placeholder={translate(
                  "my-account.security.confirm-new-password-placeholder"
                )}
                type={generatedPassword ? "text" : "password"}
                component={InputField}
              />

              <FormSpy
                subscription={{ submitting: true, invalid: true }}
                onChange={formstate => onSubmitting({ ...formstate })}
              />
            </form>
          )}
        />
      )}
    </Translate>
  );
};

export default HostingPassword;
