import React from "react";
import styled from "styled-components";

const StyledInputFieldIcon = styled.span`
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  font-size: 24px;
  color: ${props => props => {
    const validColor = props.theme.greenBase;
    const invalidColor = props.theme.redBase;
    const normalColor = props.theme.neutral4;
    if (!props.valid && !props.invalid) return normalColor;
    if (props.valid && !props.invalid) return validColor;
    if (!props.valid && props.invalid) return invalidColor;
  }};
  right: 10px;
  top: 50%;
  z-index: 10;
`;

const InputFieldIcon = props => (
  <StyledInputFieldIcon
    className={`input-icon icon-${props.icon}`}
    {...props}
  />
);

export default InputFieldIcon;
