import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Translate } from "react-localize-redux";
import Parser from "html-react-parser";
import moment from "moment";

import Container from "../../../../components/Containers/Container";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import CustomTooltip from "../../../../components/CustomTooltip";

const StyledProductPlan = styled.div`
  padding: 30px;
`;
const StyledProductPlanWrapper = styled.div`
  margin-bottom: 30px;
`;
const StyledPlanName = styled.h3`
  font-size: 24px;
  font-weight: 600;
  line-height: 1.42;
  color: ${theme.neutralBase};
`;

const StyledPlanDescription = styled.p`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  display: block;
`;

const StyledPlanUnderline = styled.p`
  display: block;
  width: 50px;
  height: 3px;
  background-color: ${theme.blueBase};
  margin-bottom: 30px;
`;

const StyledPlanServicesList = styled.ul`
  margin-bottom: 20px;
`;
const StyledPlanServicesListItem = styled.li`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutralBase};
`;

const StyledPlanServicesListItemExtra = styled.li`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutralBase};
  padding-bottom: 3px;
`;

const StyledPlanPriceWrapper = styled.div`
  margin-bottom: 30px;
`;
const StyledPlanPriceCurrency = styled.span`
  font-size: 16px;
  line-height: 1.38;
  color: ${theme.neutral4};
  margin-right: 5px;
  text-transform: uppercase;
`;
const StyledPlanPriceAmount = styled.span`
  font-size: 30px;
  font-weight: 600;
  line-height: 1.13;
  color: ${theme.neutralBase};
  margin-right: 5px;
`;
const StyledPlanPricePeriod = styled.span`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutralBase};
`;

const StyledExpirationDaysLeft = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutralBase};
  margin-bottom: 30px;
`;

const StyledProductReadMore = styled.div`
  margin-top: 20px;
`;

const StyledReadMoreToggle = styled.span`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  cursor: pointer;
`;

const DISPLAY_NUMBER_OF_FEATURES = 5;

class ProductPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showReadMore: false
    };
  }

  onToggleReadMore = () => {
    this.setState({
      showReadMore: !this.state.showReadMore
    });
  };

  render() {
    const {
      server,
      currentProduct,
      onUpgradeProduct,
      upgradingProduct
    } = this.props;
    let firstThreeFeatures = null;
    let remainingFeatures = null;

    if (server.details && server.details.features) {
      if (
        server.details.features.length > 0 &&
        server.details.features.length <= DISPLAY_NUMBER_OF_FEATURES
      ) {
        firstThreeFeatures = server.details.features;
      } else if (
        server.details.features.length > 0 &&
        server.details.features.length > DISPLAY_NUMBER_OF_FEATURES
      ) {
        firstThreeFeatures = server.details.features.slice(
          0,
          DISPLAY_NUMBER_OF_FEATURES
        );
        remainingFeatures = server.details.features.slice(
          DISPLAY_NUMBER_OF_FEATURES,
          server.details.features.length
        );
      }
    }

    let upgradeAmount =
      server.pricing.buy.billing_cycle.derivations[0].price.value -
      currentProduct.pricing.buy.billing_cycle.derivations[0].price.value;
    upgradeAmount =
      server.pricing.buy.billing_cycle.derivations[0].price.currency.code.toLowerCase() ===
      "мкд"
        ? upgradeAmount.toFixed(0)
        : upgradeAmount.toFixed(2);

    const renewDaysLeft = moment(currentProduct.renewal_date).diff(
      moment.now(),
      "days"
    );

    const currentPlanDerivation = currentProduct.pricing.buy.billing_cycle.derivations.find(
      d => d.numeric_code === 0.1
    );

    const upgradePlanDerivation = server.pricing.buy.billing_cycle.derivations.find(
      d => d.numeric_code === 0.1
    );

    const amountCredited =
      (currentPlanDerivation.price.value / 365) * parseInt(renewDaysLeft, 10);
    const amountDebited =
      (upgradePlanDerivation.price.value / 365) * parseInt(renewDaysLeft, 10);
    let totalPayable =
      currentPlanDerivation.price.currency.code.toLowerCase() === "мкд"
        ? (amountDebited - amountCredited).toFixed(0)
        : (amountDebited - amountCredited).toFixed(2);

    return (
      <Translate>
        {({ translate }) => {
          return (
            <StyledProductPlanWrapper>
              <Container>
                <StyledProductPlan>
                  <StyledPlanName>{server.name}</StyledPlanName>
                  {server.details.description && (
                    <StyledPlanDescription>
                      {server.details.description}
                    </StyledPlanDescription>
                  )}
                  <StyledPlanUnderline />
                  <StyledPlanServicesList className="list-unstyled">
                    {firstThreeFeatures &&
                      firstThreeFeatures.length > 0 &&
                      firstThreeFeatures.map(feature => (
                        <StyledPlanServicesListItem
                          key={`server-feature-${Math.random()}`}
                        >
                          {Parser(feature)}
                        </StyledPlanServicesListItem>
                      ))}
                  </StyledPlanServicesList>
                  {server.id !== currentProduct.catalog_id && (
                    <StyledPlanPriceWrapper>
                      <StyledPlanPriceCurrency>
                        {
                          server.pricing.buy.billing_cycle.derivations[0].price
                            .currency.code
                        }
                      </StyledPlanPriceCurrency>
                      <StyledPlanPriceAmount>
                        {totalPayable}
                      </StyledPlanPriceAmount>
                      {server &&
                        server.details &&
                        server.details.length > 0 &&
                        server.details.tooltips.price && (
                          <CustomTooltip
                            inline
                            content={server.details.tooltips.price}
                          >
                            <StyledPlanPricePeriod>
                              * {translate("shop.hostings.to-upgrade")}
                            </StyledPlanPricePeriod>
                          </CustomTooltip>
                        )}
                      {server.details.length === 0 ||
                        (!server.details.tooltips.price && (
                          <StyledPlanPricePeriod>
                            * {translate("shop.hostings.to-upgrade")}
                          </StyledPlanPricePeriod>
                        ))}
                    </StyledPlanPriceWrapper>
                  )}

                  {server.id !== currentProduct.catalog_id && (
                    <StyledExpirationDaysLeft>
                      <strong>
                        {
                          server.pricing.buy.billing_cycle.derivations[0].price
                            .currency.code
                        }{" "}
                        {
                          server.pricing.buy.billing_cycle.derivations[0].price
                            .value
                        }
                        /{translate("general.year")}
                      </strong>{" "}
                      {translate("shop.servers.server-renews-in-days").replace(
                        "{RENEW_DAYS_LEFT}",
                        renewDaysLeft
                      )}
                    </StyledExpirationDaysLeft>
                  )}

                  {server.id === currentProduct.catalog_id && (
                    <PrimaryButton disabled={true} className="w-100">
                      {translate("shop.servers.current-plan")}
                    </PrimaryButton>
                  )}

                  {server.id !== currentProduct.catalog_id && (
                    <PrimaryButton
                      onClick={e => onUpgradeProduct(server)}
                      disabled={upgradingProduct}
                      submitting={upgradingProduct}
                      className="w-100"
                    >
                      {translate("shop.servers.choose-plan")}
                    </PrimaryButton>
                  )}

                  <StyledProductReadMore>
                    {remainingFeatures && this.state.showReadMore && (
                      <StyledPlanServicesList className="list-unstyled">
                        {remainingFeatures &&
                          remainingFeatures.length > 0 &&
                          remainingFeatures.map(feature => (
                            <StyledPlanServicesListItemExtra
                              key={`hosting-feature-${Math.random()}`}
                            >
                              {Parser(feature)}
                            </StyledPlanServicesListItemExtra>
                          ))}
                      </StyledPlanServicesList>
                    )}
                    {remainingFeatures && (
                      <StyledReadMoreToggle
                        onClick={e => this.onToggleReadMore()}
                      >
                        {this.state.showReadMore
                          ? `- ${translate("shop.hostings.read-less")}`
                          : `+ ${translate("shop.hostings.read-more")}`}
                      </StyledReadMoreToggle>
                    )}
                  </StyledProductReadMore>
                </StyledProductPlan>
              </Container>
            </StyledProductPlanWrapper>
          );
        }}
      </Translate>
    );
  }
}
export default ProductPlan;
