import * as CA from "../actions/creditActions";

import handleReducerActions from "../../../core/handleReducerActions";

import { APP_STATE_COMPLETE_RESET } from "../../AppState/actions/appStateActions";

const initialState = {
  amount: 0,
  currency: {
    code: ""
  },
  isPurchasingCredit: false,
  isFetchingCredit: false
};

const creditStateHandlers = {
  [CA.CREDIT_REQUEST]: creditRequest,
  [CA.CREDIT_RECEIVE]: creditReceive,
  [CA.CREDIT_PURCHASE_REQUEST]: creditPurchaseRequest,
  [CA.CREDIT_PURCHASE_RECEIVE]: creditPurchaseReceive,
  [CA.CREDIT_REDUCE_AMOUNT]: creditReduceAmount,
  [APP_STATE_COMPLETE_RESET]: resetState
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(creditStateHandlers, state, action);
}

function creditRequest(state, action) {
  return { ...state, isFetchingCredit: true };
}

function creditReceive(state, action) {
  return {
    ...state,
    ...action.payload,
    isFetchingCredit: false
  };
}

function creditReduceAmount(state, action) {
  return {
    ...state,
    amount: state.amount - action.payload
  };
}

function creditPurchaseRequest(state, action) {
  return {
    ...state,
    isPurchasingCredit: true
  };
}

function creditPurchaseReceive(state, action) {
  return {
    ...state,
    isPurchasingCredit: false
  };
}

function resetState(state) {
  return { ...initialState };
}

// this is a selector
export function getAvailableCredit(state) {
  return state.credit;
}

export function isPurchasingCredit(state) {
  return state.credit.isPurchasingCredit;
}

export function isFetchingCredit(state) {
  return state.credit.isFetchingCredit;
}
