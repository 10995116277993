import React from "react";
import styled from "styled-components";

const ICON_SIZE_SMALL = "20px";
const ICON_SIZE_MEDIUM = "24px";
const ICON_SIZE_LARGE = "30px";

const iconSize = size => {
  if (!size) return ICON_SIZE_MEDIUM;
  switch (size.toLowerCase()) {
    case "s":
    case "small":
      return ICON_SIZE_SMALL;

    case "m":
    case "medium":
    case "d":
    case "default":
      return ICON_SIZE_MEDIUM;

    case "l":
    case "large":
      return ICON_SIZE_LARGE;

    default:
      return ICON_SIZE_MEDIUM;
  }
};

const StyledIcon = styled.span`
  display: inline-block;
  position: relative;
  margin: 0;
  padding: 0;
  font-size: ${props => iconSize(props.size)};
  top: -1px;
  vertical-align: middle;
  color: ${props => (props.color ? props.color : "inherit")};
`;

const Icon = props => (
  <StyledIcon
    {...props}
    className={`icon icon-${props.icon} ${props.className}`}
  />
);
export default Icon;
