import * as referralService from "../../../services/referralService";

export const REFERRAL_FEATURE = "features/REFERRAL";


export const REFERRAL_CODE_RECEIVE = `${REFERRAL_FEATURE}/CODE_RECEIVE`;
export const REFERRAL_GENERATE = `${REFERRAL_FEATURE}/CODE_GENERATE`;
export const REFERRAL_FETCH_INVOICES = `${REFERRAL_FEATURE}/REFERRAL_FETCH_INVOICES`;




export function generateReferralCode(){
    return dispatch => {
        return referralService.generateCode()
            .then(
                result => {
                    const { referral_code } = result.data.data;
                    dispatch(receiveCode(referral_code));
                },
                error => {
                    console.log("something happened", error);
                }
            )
            .catch(error => {
                console.log(error);
            });
    };
}

export function fetchReferralInvoices(limit, page, sort, filters){
    return dispatch => {
        return referralService.fetchInvoices(limit, page, sort, filters)
            .then(
                result => {
                    dispatch(receiveInvoices({...result.data.data}));
                },
                error => {
                    console.log("something happened", error);
                }
            )
            .catch(error => {
                console.log(error);
            });
    };
}

export function receiveInvoices(data) {
    return {
        type: REFERRAL_FETCH_INVOICES,
        all : data,
        paging : data.paging,
        sort : data.sort,
        filters : data.filters,
        total_revenue : data.total_revenue
    };
}

export function receiveCode(code) {
    return {
        type: REFERRAL_CODE_RECEIVE,
        referral_code: code,
        referral_url: `${window.location.origin}/referral-program/apply-code?code=${code}`
    };
}