import React, { Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route, Link } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import { withRouter } from "react-router-dom";
import parse from "html-react-parser";
import PdfPopup from "../../../components/DomainPDF/PDFPopup";
import { handleDomainRequest, updateDomainRequest } from "../../../services/customerService";


import Breadcrumb from "../../../components/Breadcrumb";
import BreadcrumbItem from "../../../components/Breadcrumb/BreadcrumbItem";

import Tabs from "../../../components/Tabs";
import Tab from "../../../components/Tabs/Tab";
import TabContent from "../../../components/Tabs/TabContent";

import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import PrimaryWarningButton from "../../../components/Buttons/PrimaryWarningButton";

import ContentLoader from "../../../components/Loaders/ContentLoader";

import Modal from "../../../components/Modal";
import ActionModal from "../../../components/ActionModal";
import UnexpectedError from "../../../components/UnexpectedError";

import GeneralInfo from "./GeneralInfo";
import Settings from "./Settings";
import Contacts from "./Contacts";
import DomainContacts from "./DomainContacts";
import Addons from "./Addons";
import ZoneFile from "./ZoneFile";
import ContactDetailsForm from "./DomainContacts/ContactDetailsForm";
import ContactDetailsFormWithSearch from "./DomainContacts/ContactDetailsFormWithSearch";

import RenewDomainWithPeriod from "../../Domains/RenewDomainWithPeriod";

import {
  fetchDomain,
  fetchDomainNameServers,
  fetchDomainRegisterContacts,
  unlockDomain,
  lockDomain,
  fetchDomainLockStatus,
  fetchEppTransferCode,
  viewDomain,
  updateDomainNameservers,
  changeCustomerDomainContact,
  searchContacts,
  cancelDomain,
  autoRenewDomain,
  requestRenewDomain,
  fetchCustomerDomainZoneFile,
  requestRenewDomainWithPeriod,
  fetchCustomerDomainRedirectionAvailable,
  fetchDomainRedirection,
  updateCustomerDomainRedirection,
  removeCustomerDomainRedirection,
  fetchDomainServices,
  fetchDomainSettings,
  buyDomainIdProtection
} from "../../Customer/actions/customerActions";

import {
  getDomainToView,
  isFetchingEppTransferCode,
  isFetchingDomainRegisterContacts,
  isFetchingDomainNameServers,
  isFetchingDomainRedirectionAvailable,
  isDomainRedirectionAvailable,
  isFetchingDomainRedirection,
  getDomainRedirection,
  isUpdatingDomainRedirection,
  getDomainRedirectionReason,
  getCustomerCurrencyCode,
  getDomainServices
} from "../../Customer/reducers/customerReducer";

import { getCountriesFormatted } from "../../Addressing/reducers/addressingReducer";

import { requestRenewProduct } from "../../Products/actions/productsActions";

import { fetchDomains } from "../../Domains/actions/domainsActions";
import {
  getDomainsPricing,
  isFetchingDomains
} from "../../Domains/reducers/domainsReducer";

import { addProductToCart } from "../../Cart/actions/cartActions";
import { isStoringItemInCart } from "../../Cart/reducers/cartReducer";

import parseDomain from "../../../utils/parseDomain";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import {
  ROUTE_INDEX,
  ROUTE_DOMAINS,
  ROUTE_VIEW_DOMAIN_GENERAL_INFO,
  ROUTE_VIEW_DOMAIN_SETTINGS,
  ROUTE_VIEW_DOMAIN_DOMAIN_CONTACTS,
  ROUTE_VIEW_DOMAIN_ZONE_FILE,
  ROUTE_VIEW_DOMAIN_ADDONS,
  ROUTE_VIEW_DOMAIN_CONTACTS,
  ROUTE_BILLING_VIEW_PROINVOICE,
  compileRoute,
  ROUTE_CART,
  ROUTE_CREATE_TICKET,
  ROUTE_CREATE_TICKET_WITH_PARAMS
} from "../../../routes/routes";

import {
  NAMESERVERS_DEFAULT,
  NAMESERVERS_CUSTOM,
  NAMESERVERS_CHILD
} from "./Settings/Nameservers/NameserverTypes";

import {
  CONTACT_TYPE_ADMINISTRATIVE,
  CONTACT_TYPE_REGISTRANT,
  CONTACT_TYPE_TECHNICAL,
  CONTACT_TYPE_DEFAULT
} from "./DomainContacts/DomainContactTypes";

import {
  validateNameserver,
  validateFirstName,
  validateLastName,
  validateEmail,
  validateAddress,
  validatePhoneNumber,
  validateCountry,
  validateDomain,
  validateDomainWithHttpOrHttps,
  validateMkPhoneNumber
} from "../../../common/validationRules";

import {
  CONTACT_DETAILS_TYPE_COMPANY,
  CONTACT_DETAILS_TYPE_INDIVIDUAL
} from "./DomainContacts/ContactDetailsForm/ContactDetailsTypes";
import NoticeInfo from "../../../components/Notices/NoticeInfo";

const contactDisplayNames = {
  [CONTACT_TYPE_ADMINISTRATIVE]:
    "domain.domain-contacts.administrative-contact",
  [CONTACT_TYPE_REGISTRANT]: "domain.domain-contacts.domain-registrant",
  [CONTACT_TYPE_TECHNICAL]: "domain.domain-contacts.technical-contact"
};

const contactTypeToDisplayName = type =>
  contactDisplayNames[type] || CONTACT_TYPE_DEFAULT;

class ViewDomain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentDomain: this.props.currentDomain || null,
      domainLocked: true,
      fetchingLockStatus: false,
      eppTransferCode: null,
      showEditDomainContactModal: false,
      domainContactModalSubtitle: "",
      selectedDomainContact: null,
      selectedDomainContactType: null,
      submittingDomainContact: false,
      invalidDomainContact: false,
      showChangeDomainContactModal: false,
      contacts: null,
      contactEditingDisabled: false,
      selectedChangeContact: null,
      renewNowSubmitting: false,
      submittingNameservers: false,
      nameserversInvalid: false,
      unexpectedError: false,
      showRenewNowModal: false,
      settingDefaultNameservers: false,
      renewDomainSubmitting: false,
      renewDomainSubmittingId: 0,
      showSelectRenewalPeriodDomainModal: false,
      domainRenewalPeriod: 1,
      showDisableAutoRenewModal: false,
      buyDomainIdProtectionSubmitting: false,
      showBuyDomainIdProtectionModal: false,
      domainIdProtectionService: null,
      showPdfPopup: false,
      pdfUrls: [],
      values2: "",
      pdfIds: "",
      showPdfPopup2: false,
      pdfUrls2: [],
      pdfIds2: "",
      values3: ""
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.currentDomain !== nextProps.currentDomain) {
      this.setState({
        ...this.state,
        currentDomain: nextProps.currentDomain
      });
    }
  }

  componentDidMount() {
    if (!this.props.currentDomain) {
      this.doFetchDomain(this.props.match.params.id);
    }

    if (
      this.props.currentDomain &&
      this.props.match.params.id &&
      this.props.currentDomain.id !== parseInt(this.props.match.params.id, 10)
    ) {
      this.doFetchDomain(this.props.match.params.id);
    }

    this.props.fetchDomains();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.doFetchDomain(this.props.match.params.id);
    }
  }

  doFetchDomain = id => {
    this.setState({
      currentDomain: null
    });

    this.props.fetchDomain(id).then(({ error, domain }) => {
      if (error) {
        this.setState({ unexpectedError: true });
        return;
      }

      this.fetchDomainNameServers(domain);
      this.fetchDomainRegisterContacts(domain);
      this.props.fetchCustomerDomainZoneFile(domain);
      this.props
        .fetchCustomerDomainRedirectionAvailable(domain)
        .then(result => {
          if (result) {
            this.props.fetchDomainRedirection(domain);
          }
        });

      this.props.fetchDomainServices(domain);
      this.props.fetchDomainSettings(domain);

      this.props.viewDomain({ id: domain.id });
    });
  };

  fetchDomainNameServers = domain => {
    this.props.fetchDomainNameServers(domain);
  };

  fetchDomainRegisterContacts = domain => {
    this.props.fetchDomainRegisterContacts(domain);
  };

  onUnlockDomain = () => {
    this.setState({
      ...this.state,
      fetchingLockStatus: true,
      domainLocked: !this.state.domainLocked
    });
    this.props.unlockDomain(this.state.currentDomain).then(result => {
      this.setState({
        ...this.state,
        domainLocked: result.locked,
        fetchingLockStatus: false
      });

      displayToastMessageForResponse(
        this.props.translate("general.domain"),
        result
      );
    });
  };

  onLockDomain = () => {
    this.setState({
      ...this.state,
      fetchingLockStatus: true,
      domainLocked: !this.state.domainLocked
    });
    this.props.lockDomain(this.state.currentDomain).then(result => {
      this.setState({
        ...this.state,
        domainLocked: result.locked,
        fetchingLockStatus: false
      });
      displayToastMessageForResponse(
        this.props.translate("general.domain"),
        result
      );
    });
  };

  onRenewDomain = domain => {
    this.setState({
      renewNowSubmitting: true
    });

    this.props.requestRenewDomain(domain).then(({ error, invoice }) => {
      if (!error) {
        this.props.history.push(
          compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)({ id: invoice.id })
        );
      }
    });
  };

  onGetEppTransferCode = () => {
    if (!this.state.currentDomain) return;

    this.props.fetchEppTransferCode(this.state.currentDomain).then(response => {
      if (!response.error) {
        this.setState({
          ...this.state,
          eppTransferCode: response.epp_transfer_code
        });
      }

      displayToastMessageForResponse(
        this.props.translate("general.domain"),
        response
      );
    });
  };

  onUseDefaultNameservers = () => {
    this.setState({
      ...this.state,
      nameserversType: NAMESERVERS_DEFAULT
    });
  };

  onUseCustomNameservers = () => {
    this.setState({
      ...this.state,
      nameserversType: NAMESERVERS_CUSTOM
    });
  };

  onUseChildNameservers = () => {
    this.setState({
      ...this.state,
      nameserversType: NAMESERVERS_CHILD
    });
  };

  onSetNameserversType = selectedType => {
    let type = NAMESERVERS_DEFAULT;
    switch (selectedType) {
      case NAMESERVERS_DEFAULT:
        type = NAMESERVERS_DEFAULT;
        break;

      case NAMESERVERS_CUSTOM:
        type = NAMESERVERS_CUSTOM;
        break;

      case NAMESERVERS_CHILD:
        type = NAMESERVERS_CHILD;
        break;
      default:
        type = NAMESERVERS_DEFAULT;
        break;
    }

    this.setState({
      ...this.state,
      currentDomain: {
        ...this.state.currentDomain,
        name_servers: {
          ...this.state.currentDomain.name_servers,
          type: type
        }
      }
    });
  };

  validateNameservers = values => {
    return {
      ns1: validateNameserver(values.ns1, true),
      ns2: validateNameserver(values.ns2, true),
      ns3: validateNameserver(values.ns3, false),
      ns4: validateNameserver(values.ns4, false),
      ns5: validateNameserver(values.ns5, false)
    };
  };

  onTriggerNameserversForm = () => {
    document
      .getElementById("domain-nameservers-form")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  onSubmittingNamerservers = ({ submitting, invalid }) => {

    this.setState({
      submittingNameservers: submitting,
      nameserversInvalid: invalid
    });
  };

  onNameServersKeyPress = e => {
    if (e.keyCode === 13) {
      document
        .getElementById("domain-nameservers-form")
        .dispatchEvent(new Event("submit", { cancelable: true }));
    }
  };

  onSetDefaultNameservers = () => {
    this.setState({
      settingDefaultNameservers: true
    });

    return this.props
      .updateDomainNameservers(
        this.state.currentDomain,
        {
          ns1: this.state.currentDomain.name_servers.default_name_servers.ns1,
          ns2: this.state.currentDomain.name_servers.default_name_servers.ns2
        },
        NAMESERVERS_DEFAULT
      )
      .then(
        response => {
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            response
          );
          this.setState({
            settingDefaultNameservers: false
          });
          return response;
        },
        err => {
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            err
          );
          this.setState({
            settingDefaultNameservers: false
          });
          return err;
        }
      );
  };

  onSaveNameservers = (values, type) => {

    this.setState({ values2: values });
    if (this.state.currentDomain.isMkDomain) {

      this.specialMkCheckoutProcess(values);
    } else {
      if (this.state.currentDomain.name_servers.type === NAMESERVERS_DEFAULT) {
        return this.props
          .updateDomainNameservers(
            this.state.currentDomain,
            {
              ns1: this.state.currentDomain.name_servers.default_name_servers.ns1,
              ns2: this.state.currentDomain.name_servers.default_name_servers.ns2
            },
            NAMESERVERS_DEFAULT
          )
          .then(response => {
            displayToastMessageForResponse(
              this.props.translate("general.domain"),
              response
            );
            this.props.fetchCustomerDomainZoneFile(this.state.currentDomain);
          });
      }
      if (this.state.currentDomain.name_servers.type === NAMESERVERS_CUSTOM) {
        return this.props
          .updateDomainNameservers(
            this.state.currentDomain,
            values,
            NAMESERVERS_CUSTOM
          )
          .then(response => {
            displayToastMessageForResponse(
              this.props.translate("general.domain"),
              response
            );
            this.props.fetchCustomerDomainZoneFile(this.state.currentDomain);
          });
      }
      if (this.state.currentDomain.name_servers.type === NAMESERVERS_CHILD) {
        let ns1 = values.ns1;
        if (values.ip_address_1 && values.ip_address_1.length > 0) {
          ns1 = ns1 + "-" + values.ip_address_1;
        }
        let ns2 = values.ns2;
        if (values.ip_address_2 && values.ip_address_2.length > 0) {
          ns2 = ns2 + "-" + values.ip_address_2;
        }
        let ns3 = values.ns3;
        if (values.ip_address_3 && values.ip_address_3.length > 0) {
          ns3 = ns3 + "-" + values.ip_address_3;
        }
        let ns4 = values.ns4;
        if (values.ip_address_4 && values.ip_address_4.length > 0) {
          ns4 = ns4 + "-" + values.ip_address_4;
        }

        let ns5 = values.ns5;
        if (values.ip_address_5 && values.ip_address_5.length > 0) {
          ns5 = ns5 + "-" + values.ip_address_5;
        }

        return this.props
          .updateDomainNameservers(
            this.state.currentDomain,
            {
              ns1,
              ns2,
              ns3,
              ns4,
              ns5
            },
            NAMESERVERS_CHILD
          )
          .then(response => {
            displayToastMessageForResponse(
              this.props.translate("general.domain"),
              response
            );
            this.props.fetchCustomerDomainZoneFile(this.state.currentDomain);
          });
      }
    };
  }

  specialMkCheckoutProcess = (values) => {
  


    const domain = this.state.currentDomain;


    const data1 = {
      requestType: "updateNs",
      domain: domain.domain_name,
      ns1: this.state.currentDomain.name_servers.default_name_servers.ns1,
      ns2: this.state.currentDomain.name_servers.default_name_servers.ns2
    }

    const data2 = {
      requestType: "updateNs",
      domain: domain.domain_name,
      ns1: values.ns1,
      ns2: values.ns2,
      ns3: values.ns3,
      ns4: values.ns4,
      ns5: values.ns5
    };

    const data3 = {
      requestType: "updateNs",
      domain: domain.domain_name,
    }



    if (this.state.currentDomain.name_servers.type === NAMESERVERS_DEFAULT) {
      handleDomainRequest(data1)
        .then(response => {
   
          const pdfUrl = response.data.data.document;
          const pdfId = response.data.data.id;
          this.setState({ pdfUrls: [pdfUrl], showPdfPopup: true, pdfIds: pdfId });
        })
        .catch(error => {
          console.error("Error in handleDomainRequest for domain", domain.domain_name, ":", error);
        });
    } else if (this.state.currentDomain.name_servers.type === NAMESERVERS_CUSTOM) {
      handleDomainRequest(data2)
        .then(response => {
   
          const pdfUrl = response.data.data.document;
          const pdfId = response.data.data.id;
          this.setState({ pdfUrls: [pdfUrl], showPdfPopup: true, pdfIds: pdfId });
        })
        .catch(error => {
          console.error("Error in handleDomainRequest for domain", domain.domain_name, ":", error);
        });
    } else if (this.state.currentDomain.name_servers.type === NAMESERVERS_CHILD) {
      
     
     const data3 = {
      requestType: "updateNs",
      domain: domain.domain_name,
      ns1: values.ns1+"-"+values.ip_address_1,
      ns2: values.ns2+"-"+values.ip_address_2,
      ns3: values.ns3+"-"+values.ip_address_3,
      ns4: values.ns4+"-"+values.ip_address_4,
      ns5: values.ns5+"-"+values.ip_address_5
    };

    
      handleDomainRequest(data3)
        .then(response => {
         
          const pdfUrl = response.data.data.document;
          const pdfId = response.data.data.id;
          this.setState({ pdfUrls: [pdfUrl], showPdfPopup: true, pdfIds: pdfId });
        })
        .catch(error => {
          console.error("Error in handleDomainRequest for domain", domain.domain_name, ":", error);
        });
    }

  };

  handleClosePopup = () => {
    this.setState({ showPdfPopup: false, pdfUrl: '' });
  };

  handleAccept = () => {
   
    updateDomainRequest([this.state.pdfIds]);
    this.setState({ showPdfPopup: false });


    if (this.state.currentDomain.name_servers.type === NAMESERVERS_DEFAULT) {
      return this.props
        .updateDomainNameservers(
          this.state.currentDomain,
          {
            ns1: this.state.currentDomain.name_servers.default_name_servers.ns1,
            ns2: this.state.currentDomain.name_servers.default_name_servers.ns2
          },
          NAMESERVERS_DEFAULT
        )
        .then(response => {
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            response
          );
          this.props.fetchCustomerDomainZoneFile(this.state.currentDomain);
        });
    }
    if (this.state.currentDomain.name_servers.type === NAMESERVERS_CUSTOM) {
      return this.props
        .updateDomainNameservers(
          this.state.currentDomain,
          this.state.values2,
          NAMESERVERS_CUSTOM
        )
        .then(response => {
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            response
          );
          this.props.fetchCustomerDomainZoneFile(this.state.currentDomain);
        });
    }
    if (this.state.currentDomain.name_servers.type === NAMESERVERS_CHILD) {
      let ns1 = this.state.values2.ns1;
      if (this.state.values2.ip_address_1 && this.state.values2.ip_address_1.length > 0) {
        ns1 = ns1 + "-" + this.state.values2.ip_address_1;
      }
      let ns2 = this.state.values2.ns2;
      if (this.state.values2.ip_address_2 && this.state.values2.ip_address_2.length > 0) {
        ns2 = ns2 + "-" + this.state.values2.ip_address_2;
      }
      let ns3 = this.state.values2.ns3;
      if (this.state.values2.ip_address_3 && this.state.values2.ip_address_3.length > 0) {
        ns3 = ns3 + "-" + this.state.values2.ip_address_3;
      }
      let ns4 = this.state.values2.ns4;
      if (this.state.values2.ip_address_4 && this.state.values2.ip_address_4.length > 0) {
        ns4 = ns4 + "-" + this.state.values2.ip_address_4;
      }

      let ns5 = this.state.values2.ns5;
      if (this.state.values2.ip_address_5 && this.state.values2.ip_address_5.length > 0) {
        ns5 = ns5 + "-" + this.state.values2.ip_address_5;
      }

      return this.props
        .updateDomainNameservers(
          this.state.currentDomain,
          {
            ns1,
            ns2,
            ns3,
            ns4,
            ns5
          },
          NAMESERVERS_CHILD
        )
        .then(response => {
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            response
          );
        
          this.props.fetchCustomerDomainZoneFile(this.state.currentDomain);
        });
    }


  };

  onEditDomainContact = (contact, type) => {
    this.setState({
      ...this.state,
      selectedDomainContactType: type,
      selectedDomainContact: {
        ...contact,
        type: type,
        contact_type:
          contact.company === ""
            ? CONTACT_DETAILS_TYPE_INDIVIDUAL
            : CONTACT_DETAILS_TYPE_COMPANY,
        country: contact.country.id
          ? { label: contact.country.name, value: contact.country.id }
          : contact.country
      },
      showEditDomainContactModal: true,
      domainContactModalSubtitle:
        this.props.translate(contactTypeToDisplayName(contact.type)) +
        " - " +
        this.state.currentDomain.domain_name
    });
  };

  onTriggerDomainContactFormSubmit = () => {
   
    document
      .getElementById("domain-contact-details-form")
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  validateDomainContact = values => {
    return {
      first_name: validateFirstName(values.first_name),
      last_name: validateLastName(values.last_name),
      email: validateEmail(values.email),
      phone:
        this.state.currentDomain.domain_name.endsWith(".mk") ||
          this.state.currentDomain.domain_name.endsWith(".мкд")
          ? validateMkPhoneNumber(values.phone)
          : validatePhoneNumber(values.phone),
      address1: validateAddress(values.address1),
      country:
        values.country && values.country.id
          ? validateCountry(values.country.id)
          : undefined
    };
  };


  onUpdateDomainContact = values => {
    this.setState({ values3: values });
  
    if (this.state.currentDomain.isMkDomain) {
      this.specialMkCheckoutProcess2(values);
    } else {
      this.executeDomainContactChange(values);
    }
  };
  
  specialMkCheckoutProcess2 = values => {
    this.setState({ showEditDomainContactModal: false })
    const domain = this.state.currentDomain;
    let data = {
          requestType: "",
          domain: domain.domain_name
        };
    
        if (this.state.selectedDomainContactType === 'administrative') {
          data.requestType = "updateAdminContact";
          data.adminName = values.first_name + " " + values.last_name;
          data.adminEmail = values.email;
          data.adminPhone = values.phone;
        } else if (this.state.selectedDomainContactType === 'technical') {
          data.requestType = "updateTechContact";
          data.techName = values.first_name + " " + values.last_name;
          data.techEmail = values.email;
          data.techPhone = values.phone;
        }
  

    handleDomainRequest(data)
      .then(response => {
        const pdfUrl = response.data.data.document;
        const pdfId = response.data.data.id;
  
     
        this.setState({ pdfUrls2: [pdfUrl], showPdfPopup2: true, pdfIds2: [pdfId] });
      })
      .catch(error => {
        console.error("Error in handleDomainRequest for domain", domain.domain_name, ":", error);
      });
  };
  
  handleAccept2 = () => {

    updateDomainRequest(this.state.pdfIds2)
      .then(() => {
      
        this.setState({ showPdfPopup2: false, pdfUrl2: '' }, () => {
         
          this.executeDomainContactChange(this.state.values3);
        });
      })
      .catch(error => {
        console.error("Error updating domain request:", error);
      });
  };
  
  executeDomainContactChange = values => {
    return this.props
      .changeCustomerDomainContact(
        this.state.currentDomain,
        this.state.selectedDomainContactType,
        { ...values, country_id: values.country.value }
      )
      .then(({ error, messages }) => {
        this.onHideDomainContactModal();
        displayToastMessageForResponse(this.props.translate("general.domain"), { error, messages });
      })
      .catch(error => {
        console.error("Error updating domain contact:", error);
      });
  };
  
  
  // onUpdateDomainContact = values => {
  //   this.setState({ values3: values });

  //   if (this.state.currentDomain.isMkDomain) {
  //     this.specialMkCheckoutProcess2(values);
  //   } else {


  //     return this.props
  //       .changeCustomerDomainContact(
  //         this.state.currentDomain,
  //         this.state.selectedDomainContactType,
  //         { ...values, country_id: values.country.value }
  //       )
  //       .then(({ error, messages }) => {
  //         this.onHideDomainContactModal();

  //         displayToastMessageForResponse(this.props.translate("general.domain"), {
  //           error,
  //           messages
  //         });
  //       });
  //   }
  // };

  // specialMkCheckoutProcess2 = (values) => {
  //   this.setState({ showEditDomainContactModal: false })



  //   const domain = this.state.currentDomain;


  //   let data = {
  //     requestType: "",
  //     domain: domain.domain_name
  //   };

  //   if (this.state.selectedDomainContactType === 'administrative') {
  //     data.requestType = "updateAdminContact";
  //     data.adminName = values.full_name;
  //     data.adminEmail = values.email;
  //     data.adminPhone = values.phone;
  //   } else if (this.state.selectedDomainContactType === 'technical') {
  //     data.requestType = "updateTechContact";
  //     data.techName = values.full_name;
  //     data.techEmail = values.email;
  //     data.techPhone = values.phone;
  //   }

   

  //   handleDomainRequest(data)
  //     .then(response => {
     
  //       const pdfUrl = response.data.data.document;
  //       const pdfId = response.data.data.id;
  //       this.setState({ pdfUrls2: [pdfUrl], showPdfPopup2: true, pdfIds2: pdfId });
  //     })
  //     .catch(error => {
  //       console.error("Error in handleDomainRequest for domain", domain.domain_name, ":", error);
  //     });
  // };

  handleClosePopup2 = () => {
    this.setState({ showPdfPopup2: false, pdfUrl2: '' });
  };

  // handleAccept2 = () => {

  //   updateDomainRequest([this.state.pdfIds2]);
  //   this.setState({ showPdfPopup2: false, pdfUrl2: '' });


  //   return this.props
  //     .changeCustomerDomainContact(
  //       this.state.currentDomain,
  //       this.state.selectedDomainContactType,
  //       { ...this.state.values3, country_id: this.state.values3.country.value }
  //     )
  //     .then(({ error, messages }) => {
  //       this.onHideDomainContactModal();

  //       displayToastMessageForResponse(this.props.translate("general.domain"), {
  //         error,
  //         messages
  //       });

  //     });


  // }




  onAssignDomainContact = values => {
    return this.props
      .changeCustomerDomainContact(
        this.state.currentDomain,
        this.state.selectedDomainContact.type,
        { ...values, country_id: values.country_id.value }
      )
      .then(({ error, messages }) => {
        this.onHideDomainContactModal();

        displayToastMessageForResponse(this.props.translate("general.domain"), {
          error,
          messages
        });
      });
  };

  onSubmittingDomainContact = state => {
    this.setState({
      submittingDomainContact: state.submitting,
      invalidDomainContact: state.invalid
    });
  };

  onHideDomainContactModal = () => {
   
    this.setState({
      selectDomainContact: null,
      showEditDomainContactModal: false,
      showChangeDomainContactModal: false,
      domainContactModalSubtitle: "",
      contactEditingDisabled: false
    });
  };

  onChangeDomainContact = (contact, type) => {
   

    this.setState({
      ...this.state,
      selectedDomainContact: {},
      selectedChangeContact: {},
      selectedDomainContactType: type,
      showChangeDomainContactModal: true,
      domainContactModalSubtitle:
        this.props.translate(contactTypeToDisplayName(contact.type)) +
        " - " +
        this.state.currentDomain.domain_name
    });
  };


  onSearchContacts = (value, callback) => {
    this.props.searchContacts(value).then(contacts => {
      this.setState({
        ...this.state,
        contacts: contacts.map(contact => {
          return {
            ...contact,
            contact: {
              ...contact.contact,
              type: this.state.selectedDomainContactType,

              contact_type:
                contact.contact.company === ""
                  ? CONTACT_DETAILS_TYPE_INDIVIDUAL
                  : CONTACT_DETAILS_TYPE_COMPANY
            }
          };
        })
      });
      const reactSelectCompatibleContacts = contacts.map(contact => {
        const name =
          contact.contact.full_name && contact.contact.full_name.trim();
        const label =
          name !== ""
            ? `${name} (${contact.contact.email})`
            : contact.contact.email;
        return {
          label,
          email: contact.contact.email,
          name: contact.contact.full_name,
          value: contact.id
        };
      });
      callback(reactSelectCompatibleContacts);
    });
  };

  onSelectContact = selected => {
    if (!selected || selected.length === 0) {
      this.setState({
        ...this.state,
        selectedChangeContact: null
      });
    } else {
      const selectedContact = this.state.contacts.find(
        contact => contact.id === selected.value
      );
      this.setState({
        ...this.state,
        selectedChangeContact: {
          ...selectedContact.contact,
          type: this.state.selectDomainContactType,
          country: selectedContact.contact.country.id
            ? {
              label: selectedContact.contact.country.name,
              value: selectedContact.contact.country.id
            }
            : selectedContact.contact.country
        },
        contactEditingDisabled: true
      });
    }
  };

  onEnableContactEditing = () => {
    this.setState({
      ...this.state,
      contactEditingDisabled: false
    });
  };

  onCancelDomain = () => {
    if (this.state.currentDomain) {
      this.setState({
        renewNowSubmitting: true
      });
      return this.props
        .cancelDomain(this.state.currentDomain, this.state.cancelDomainPeriod)
        .then(cancelled => {
          this.setState({
            renewNowSubmitting: false
          });
          this.onHideDisableAutoRenewModal();
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            cancelled
          );
        })
        .catch(error => {
          this.setState({
            renewNowSubmitting: false
          });
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            error
          );

          this.onHideDisableAutoRenewModal();
        });
    }
  };

  onRenewProduct = domain => {
    this.setState({
      renewDomainSubmitting: true,
      renewDomainSubmittingId: domain.id
    });
    this.props
      .requestRenewDomain(domain)
      .then(({ error, invoice, messages }) => {
        if (!error) {
          this.props.history.push(
            compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)({ id: invoice.id })
          );
        }

        if (error) {
          this.onHideRenewNowModal();
          this.setState({
            renewDomainSubmitting: false
          });
          displayToastMessageForResponse(
            this.props.translate("domains.title"),
            { error, messages }
          );
        }
      })
      .catch(error => {
        this.onHideRenewNowModal();
        this.setState({
          renewDomainSubmitting: false
        });
        displayToastMessageForResponse(
          this.props.translate("domains.title"),
          error
        );
      });
  };

  onAutoRenewDomain = domain => {
    if (this.state.currentDomain) {
      this.setState({
        renewNowSubmitting: true
      });
      return this.props
        .autoRenewDomain(this.state.currentDomain)
        .then(autorenew => {
          this.setState({
            renewNowSubmitting: false
          });
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            autorenew
          );
        })
        .catch(error => {
          this.setState({
            renewNowSubmitting: false
          });
          displayToastMessageForResponse(
            this.props.translate("general.domain"),
            error
          );
        });
    }
  };

  onShowRenewNowModal = () => {
    this.setState({
      showRenewNowModal: true
    });
  };

  onHideRenewNowModal = () => {
    this.setState({
      showRenewNowModal: false
    });
  };

  onShowRenewalPeriodModal = domain => {
    this.setState({
      showSelectRenewalPeriodDomainModal: true,
      currentDomain: domain
    });
  };

  onHideRenewalPeriodModal = () => {
    this.setState({
      showSelectRenewalPeriodDomainModal: false
    });
  };

  onChangeRenewalPeriod = period => {
    this.setState({
      domainRenewalPeriod: period ? period.value : 1
    });
  };

  onRenewDomainWithPeriod = domain => {
    this.setState({
      renewDomainSubmitting: true,
      renewDomainSubmittingId: domain.id
    });
    this.props
      .requestRenewDomainWithPeriod(domain, this.state.domainRenewalPeriod)
      .then(({ error, invoice, messages }) => {
        if (!error) {
          this.props.history.push(
            compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)({ id: invoice.id })
          );
        }
        if (error) {
          this.onHideRenewalPeriodModal();
          displayToastMessageForResponse(
            this.props.translate("domains.title"),
            { error, messages }
          );
          this.setState({
            renewDomainSubmitting: false,
            renewDomainSubmittingId: 0
          });
        }
      })
      .catch(error => {
        this.onHideRenewalPeriodModal();
        displayToastMessageForResponse(this.props.translate("domains.title"), {
          error
        });
        this.setState({
          renewDomainSubmitting: false,
          renewDomainSubmittingId: 0
        });
      });
  };

  validateDomainRedirect = values => {
    return {
      redirect_to: validateDomainWithHttpOrHttps(values.redirect_to)
    };
  };

  onUpdateDomainRedirect = values => {
    return this.props
      .updateCustomerDomainRedirection(
        this.state.currentDomain,
        values.redirect_to
      )
      .then(result => {
        if (!result.error) {
          displayToastMessageForResponse(
            this.props.translate("domains.title"),
            {
              error: false,
              messages: {
                0: this.props.translate(
                  "domain.settings.domain-redirect-success"
                )
              }
            }
          );
        }
      });
  };

  onRemoveDomainRedirect = () => {
    return this.props
      .removeCustomerDomainRedirection(this.state.currentDomain)
      .then(result => {
        if (!result.error) {
          displayToastMessageForResponse(
            this.props.translate("domains.title"),
            {
              error: false,
              messages: {
                0: this.props.translate(
                  "domain.settings.domain-redirect-remove-success"
                )
              }
            }
          );
        }
      });
  };

  onShowDisableAutoRenewModal = () => {
    this.setState({
      ...this.state,
      showDisableAutoRenewModal: true
    });
  };

  onHideDisableAutoRenewModal = () => {
    this.setState({
      ...this.state,
      showDisableAutoRenewModal: false
    });
  };

  addToCartDomainService = service => {
    this.props
      .addProductToCart({
        type: "domain_service",
        quantity: 1,
        domain: this.state.currentDomain.domain_name,
        purchased_item: {
          id: this.state.currentDomain.id,
          type: "domain",
          name: this.state.currentDomain.domain_name
        },
        service_id: service.id
      })
      .then(response => {
        displayToastMessageForResponse(
          this.props.translate("cart.title"),
          response,
          ROUTE_CART,
          this.props.translate("cart.view-cart")
        );
      });
  };

  enableIdProtection = domain => {
    this.setState({
      buyDomainIdProtectionSubmitting: true
    });

    this.props.buyDomainIdProtection(domain.domain_name).then(result => {
      const { error, invoice_id } = result;
      if (!error) {
        this.setState({
          buyDomainIdProtectionSubmitting: false
        });
        this.props.history.push(
          compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)({ id: invoice_id })
        );
      }
    });
  };

  onShowBuyDomainIdProtection = service => {
    this.setState({
      showBuyDomainIdProtectionModal: true,
      domainIdProtectionService: service
    });
  };

  onHideBuyDomainIdProtectionModal = () => {
    this.setState({
      showBuyDomainIdProtectionModal: false,
      domainIdProtectionService: null
    });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              {this.state.showPdfPopup && (
                <PdfPopup
                  isVisible={this.state.showPdfPopup}
                  pdfUrls={this.state.pdfUrls}
                  onAccept={this.handleAccept}
                  onClose={this.handleClosePopup}
                />
              )}

              {this.state.showPdfPopup2 && (
                <PdfPopup
                  isVisible={this.state.showPdfPopup2}
                  pdfUrls={this.state.pdfUrls2}
                  onAccept={this.handleAccept2}
                  onClose={this.handleClosePopup2}
                />
              )}
              {this.state.unexpectedError && <UnexpectedError />}
              {!this.state.currentDomain && !this.state.unexpectedError && (
                <ContentLoader />
              )}
              {this.state.currentDomain && !this.state.unexpectedError && (
                <Breadcrumb>
                  <BreadcrumbItem
                    to={ROUTE_INDEX}
                    title={translate("breadcrumbs.home")}
                  />
                  <BreadcrumbItem
                    to={ROUTE_DOMAINS}
                    title={translate("breadcrumbs.domains")}
                  />
                  <BreadcrumbItem
                    title={this.state.currentDomain.domain_name}
                  />
                </Breadcrumb>
              )}

              {!this.state.unexpectedError &&
                this.state.currentDomain &&
                this.props.currentDomain && (
                  <Tabs
                    defaultTab="general-info"
                    renderTabs={(active, onSelect) => [
                      <Tab
                        key="tab-general-info"
                        label="general-info"
                        active={active}
                        onSelect={onSelect}
                        linkTo={decodeURIComponent(
                          compileRoute(ROUTE_VIEW_DOMAIN_GENERAL_INFO)({
                            id: this.state.currentDomain.id,
                            name: this.state.currentDomain.domain_name
                          })
                        )}
                      >
                        {translate("domain.general-info.title")}
                      </Tab>,
                      <Tab
                        key="tab-settings"
                        label="settings"
                        active={active}
                        onSelect={onSelect}
                        linkTo={decodeURIComponent(
                          compileRoute(ROUTE_VIEW_DOMAIN_SETTINGS)({
                            id: this.state.currentDomain.id,
                            name: this.state.currentDomain.domain_name
                          })
                        )}
                      >
                        {translate("domain.settings.title")}
                      </Tab>,
                      <Tab
                        key="tab-domain-contacts"
                        label="domain-contacts"
                        active={active}
                        onSelect={onSelect}
                        linkTo={decodeURIComponent(
                          compileRoute(ROUTE_VIEW_DOMAIN_DOMAIN_CONTACTS)({
                            id: this.state.currentDomain.id,
                            name: this.state.currentDomain.domain_name
                          })
                        )}
                      >
                        {translate("domain.domain-contacts.title")}
                      </Tab>,
                      <Tab
                        key="tab-zone-file"
                        label="zone-file"
                        active={active}
                        onSelect={onSelect}
                        linkTo={decodeURIComponent(
                          compileRoute(ROUTE_VIEW_DOMAIN_ZONE_FILE)({
                            id: this.state.currentDomain.id,
                            name: this.state.currentDomain.domain_name
                          })
                        )}
                      >
                        {translate("domain.zone-file.title")}
                      </Tab>,
                      <Tab
                        key="tab-addons"
                        label="addons"
                        active={active}
                        onSelect={onSelect}
                        linkTo={decodeURIComponent(
                          compileRoute(ROUTE_VIEW_DOMAIN_ADDONS)({
                            id: this.state.currentDomain.id,
                            name: this.state.currentDomain.domain_name
                          })
                        )}
                      >
                        {translate("domain.addons.title")}
                      </Tab>
                    ]}
                    renderTabContent={active => (
                      <Switch>
                        <Route
                          path={ROUTE_VIEW_DOMAIN_GENERAL_INFO}
                          exact
                          render={() => (
                            <TabContent
                              key="tab-content-general-info"
                              label="general-info"
                              active={true}
                            >
                              {this.state.currentDomain && (
                                <GeneralInfo
                                  domain={this.state.currentDomain}
                                  onRenewDomain={this.onShowRenewalPeriodModal}
                                  renewNowSubmitting={
                                    this.state.renewNowSubmitting
                                  }
                                  onCancelDomain={this.onCancelDomain}
                                  onShowDisableAutoRenewModal={
                                    this.onShowDisableAutoRenewModal
                                  }
                                  onAutoRenewDomain={this.onAutoRenewDomain}
                                  isFetchingDomainNameServers={
                                    this.props.isFetchingDomainNameServers
                                  }
                                  isFetchingDomains={
                                    this.props.isFetchingDomains
                                  }
                                />
                              )}
                            </TabContent>
                          )}
                        />
                        <Route
                          path={ROUTE_VIEW_DOMAIN_SETTINGS}
                          exact
                          render={() => (
                            <TabContent
                              key="tab-settings"
                              label="settings"
                              active={true}
                            >
                              {this.state.currentDomain && (!this.state.currentDomain.domain_name.endsWith(".al") && !this.state.currentDomain.domain_name.endsWith(".rs") || true) ? (
                                <Settings
                                  domain={this.state.currentDomain}
                                  isMkDomain={
                                    this.state.currentDomain.isMkDomain
                                  }
                                  onUnlockDomain={this.onUnlockDomain}
                                  onLockDomain={this.onLockDomain}
                                  disableDomainUnlocking={
                                    this.state.fetchingLockStatus
                                  }
                                  eppTransferCode={this.state.eppTransferCode}
                                  onGetEppTransferCode={
                                    this.onGetEppTransferCode
                                  }
                                  fetchingEppTransferCode={
                                    this.props.isFetchingEppTransferCode
                                  }
                                  nameservers={
                                    this.state.currentDomain.name_servers
                                  }
                                  previousNameservers={
                                    this.props.currentDomain.name_servers
                                  }
                                  onUseDefaultNameservers={
                                    this.onUseDefaultNameservers
                                  }
                                  onUseCustomNameservers={
                                    this.onUseCustomNameservers
                                  }
                                  onUseChildNameservers={
                                    this.onUseChildNameservers
                                  }
                                  onSetNameserversType={
                                    this.onSetNameserversType
                                  }
                                  validateNameservers={this.validateNameservers}
                                  onSaveNameservers={this.onSaveNameservers}
                                  onNameServersKeyPress={
                                    this.onNameServersKeyPress
                                  }
                                  onTriggerNameserversForm={
                                    this.onTriggerNameserversForm
                                  }
                                  onSubmittingNameservers={
                                    this.onSubmittingNamerservers
                                  }
                                  submittingNameservers={
                                    this.state.submittingNameservers
                                  }
                                  nameserversInvalid={
                                    this.state.nameserversInvalid
                                  }
                                  isFetchingDomainRedirectionAvailable={
                                    this.props
                                      .isFetchingDomainRedirectionAvailable
                                  }
                                  isDomainRedirectionAvailable={
                                    this.props.isDomainRedirectionAvailable
                                  }
                                  domainRedirectionReason={
                                    this.props.domainRedirectionReason
                                  }
                                  isFetchingDomainRedirection={
                                    this.props.isFetchingDomainRedirection
                                  }
                                  domainRedirection={
                                    this.props.domainRedirection
                                  }
                                  validateDomainRedirect={
                                    this.validateDomainRedirect
                                  }
                                  onUpdateDomainRedirect={
                                    this.onUpdateDomainRedirect
                                  }
                                  onRemoveDomainRedirect={
                                    this.onRemoveDomainRedirect
                                  }
                                  isUpdatingDomainRedirection={
                                    this.props.isUpdatingDomainRedirection
                                  }
                                />
                              ) : (
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-4">
                                    <NoticeInfo>
                                      {translate(
                                        "domain.general-info.unavailable-for-al-and-rs-tlds"
                                      )}
                                      <div className="d-flex text-right pt-3">
                                        <Link
                                          to={ROUTE_CREATE_TICKET}
                                          className="ml-auto"
                                        >
                                          <SecondaryButton>
                                            {translate(
                                              "help-desk.ticket.open-new-ticket"
                                            )}
                                          </SecondaryButton>
                                        </Link>
                                      </div>
                                    </NoticeInfo>
                                  </div>
                                </div>
                              )}
                            </TabContent>
                          )}
                        />
                        <Route
                          path={ROUTE_VIEW_DOMAIN_DOMAIN_CONTACTS}
                          exact
                          render={() => (
                            <TabContent
                              key="tab-domain-contacts"
                              label="domain-contacts"
                              active={true}
                            >
                              {this.state.currentDomain &&
                                !this.state.currentDomain.domain_name.endsWith(
                                  ".al"
                                ) &&
                                !this.state.currentDomain.domain_name.endsWith(
                                  ".rs"
                                ) ? (
                                <DomainContacts
                                  domain={this.state.currentDomain}
                                  contacts={
                                    this.state.currentDomain.register_contacts
                                  }
                                  onEditDomainContact={this.onEditDomainContact}
                                  validateDomainContact={
                                    this.validateDomainContact
                                  }
                                  onChangeDomainContact={
                                    this.onChangeDomainContact
                                  }
                                  isMkDomain={
                                    this.state.currentDomain.isMkDomain
                                  }
                                  contactType={this.state.type}
                                  isFetchingDomainRegisterContacts={
                                    this.props.isFetchingDomainRegisterContacts
                                  }
                                  domainServices={this.props.domainServices}
                                  addToCartDomainService={
                                    this.addToCartDomainService
                                  }
                                  onShowBuyDomainIdProtection={
                                    this.onShowBuyDomainIdProtection
                                  }
                                  isSubmittingDomainIdProtection={
                                    this.props.buyDomainIdProtectionSubmitting
                                  }
                                />
                              ) : (
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-4">
                                    <NoticeInfo>
                                      {translate(
                                        "domain.domain-contacts.update-unavailable-for-al-and-rs-tlds"
                                      )}
                                      <div className="d-flex text-right pt-3">
                                        <Link
                                          to={compileRoute(ROUTE_CREATE_TICKET_WITH_PARAMS)({ id: 12 })}
                                          className="ml-auto"
                                        >
                                          <SecondaryButton>
                                            {translate(
                                              "help-desk.ticket.open-new-ticket"
                                            )}
                                          </SecondaryButton>
                                        </Link>
                                      </div>
                                    </NoticeInfo>
                                  </div>
                                </div>
                              )}
                            </TabContent>
                          )}
                        />
                        <Route
                          path={ROUTE_VIEW_DOMAIN_ZONE_FILE}
                          exact
                          render={() => (
                            <TabContent
                              key="tab-zone-file"
                              label="zone-file"
                              active={true}
                            >
                              {this.state.currentDomain && (!this.state.currentDomain.domain_name.endsWith(".al") && !this.state.currentDomain.domain_name.endsWith(".rs") || true) ? (
                                <ZoneFile
                                  domain={this.state.currentDomain}
                                  onUseDefaultNameservers={
                                    this.onUseDefaultNameservers
                                  }
                                  onSetDefaultNameservers={
                                    this.onSetDefaultNameservers
                                  }
                                  settingDefaultNameservers={
                                    this.state.settingDefaultNameservers
                                  }
                                />
                              ) : (
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-4">
                                    <NoticeInfo>
                                      {translate(
                                        "domain.general-info.unavailable-for-al-and-rs-tlds"
                                      )}
                                      <div className="d-flex text-right pt-3">
                                        <Link
                                          to={ROUTE_CREATE_TICKET}
                                          className="ml-auto"
                                        >
                                          <SecondaryButton>
                                            {translate(
                                              "help-desk.ticket.open-new-ticket"
                                            )}
                                          </SecondaryButton>
                                        </Link>
                                      </div>
                                    </NoticeInfo>
                                  </div>
                                </div>
                              )}
                            </TabContent>
                          )}
                        />

                        <Route
                          path={ROUTE_VIEW_DOMAIN_ADDONS}
                          exact
                          render={() => (
                            <TabContent
                              key="tab-addons"
                              label="addons"
                              active={true}
                            >
                              <Addons
                                addons={[]}
                                domain={this.state.currentDomain}
                                onShowBuyDomainIdProtection={
                                  this.onShowBuyDomainIdProtection
                                }
                                isSubmittingEnableIdProtection={
                                  this.state.buyDomainIdProtectionSubmitting
                                }
                              />
                            </TabContent>
                          )}
                        />
                        <Route
                          path={ROUTE_VIEW_DOMAIN_CONTACTS}
                          exact
                          render={() => (
                            <TabContent
                              key="tab-contacts"
                              label="contacts"
                              active={true}
                            >
                              <Contacts />
                            </TabContent>
                          )}
                        />
                      </Switch>
                    )}
                  />
                )}
            </div>

            {this.state.showEditDomainContactModal && (
              <Modal
                title={translate("domain.domain-contacts.edit-contact-info")}
                subTitle={this.state.domainContactModalSubtitle}
                onCloseModal={this.onHideDomainContactModal}
                body={() => (
                  <ContactDetailsForm
                    contact={this.state.selectedDomainContact}
                    onSubmit={this.onUpdateDomainContact}
                    onSubmitting={this.onSubmittingDomainContact}
                    validate={this.validateDomainContact}
                    countries={this.props.countries}
                    domain={this.state.currentDomain}
                  />
                )}
                footer={() => (
                  <Fragment>
                    <SecondaryButton
                      onClick={() => this.onHideDomainContactModal()}
                    >
                      {translate("domain.domain-contacts.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={
                        this.state.submittingDomainContact ||
                        this.state.invalidDomainContact
                      }
                      submitting={this.state.submittingDomainContact}
                      onClick={() => this.onTriggerDomainContactFormSubmit()}
                    >
                      {translate("domain.domain-contacts.save-changes")}
                    </PrimaryButton>
                  </Fragment>
                )}
              />
            )}

            {this.state.showChangeDomainContactModal && (
              <Modal
                title={translate("domain.domain-contacts.change-contact")}
                subTitle={this.state.domainContactModalSubtitle}
                onCloseModal={this.onHideDomainContactModal}
                body={() => (
                  <ContactDetailsFormWithSearch
                    contact={this.state.selectedChangeContact}
                    onSubmit={this.onUpdateDomainContact}
                    onSubmitting={this.onSubmittingDomainContact}
                    validate={this.validateDomainContact}
                    onSearchContacts={this.onSearchContacts}
                    onSelectContact={this.onSelectContact}
                    contactEditingDisabled={this.state.contactEditingDisabled}
                    onEnableContactEditing={this.onEnableContactEditing}
                    countries={this.props.countries}
                    domain={this.state.currentDomain}
                  />
                )}
                footer={() => (
                  <Fragment>
                    <SecondaryButton
                      onClick={() => this.onHideDomainContactModal()}
                    >
                      {translate("domain.domain-contacts.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={
                        this.state.submittingDomainContact ||
                        this.state.invalidDomainContact
                      }
                      submitting={this.state.submittingDomainContact}
                      onClick={() => this.onTriggerDomainContactFormSubmit()}
                    >
                      {this.state.selectedChangeContact &&
                        !this.state.contactEditingDisabled &&
                        translate("domain.domain-contacts.assign-contact")}
                      {!this.state.selectedChangeContact &&
                        !this.state.contactEditingDisabled &&
                        translate(
                          "domain.domain-contacts.update-and-assign-contact"
                        )}
                      {this.state.selectedChangeContact &&
                        this.state.contactEditingDisabled &&
                        translate(
                          "domain.domain-contacts.update-and-assign-contact"
                        )}
                      {!this.state.selectedChangeContact &&
                        this.state.contactEditingDisabled &&
                        translate("domain.domain-contacts.assign-contact")}
                    </PrimaryButton>
                  </Fragment>
                )}
              />
            )}

            {this.state.showRenewNowModal && (
              <ActionModal
                title={`${translate(
                  "domain.general-info.renew-domain-confirmation-modal-title"
                ).replace(
                  "{DOMAIN_NAME}",
                  this.state.currentDomain.domain_name
                )}`}
                onCloseActionModal={this.onHideRenewNowModal}
                body={() => (
                  <div>
                    {translate(
                      "domain.general-info.renew-domain-confirmation-modal-description"
                    )}
                  </div>
                )}
                footer={() => (
                  <React.Fragment>
                    <SecondaryButton onClick={() => this.onHideRenewNowModal()}>
                      {translate("domain.general-info.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={this.state.renewDomainSubmitting}
                      submitting={this.state.renewDomainSubmitting}
                      onClick={() =>
                        this.onRenewProduct(this.state.currentDomain)
                      }
                    >
                      {translate("domain.general-info.renew-domain")}
                    </PrimaryButton>
                  </React.Fragment>
                )}
              />
            )}

            {this.state.showSelectRenewalPeriodDomainModal && (
              <ActionModal
                title={translate("domains.renewal-period")}
                onCloseActionModal={this.onHideRenewalPeriodModal}
                body={() => (
                  <RenewDomainWithPeriod
                    domain={this.state.currentDomain}
                    domainPrices={this.props.domainPricing}
                    parsedDomain={parseDomain(
                      this.state.currentDomain.domain_name
                    )}
                    onChange={this.onChangeRenewalPeriod}
                    customerCurrencyCode={this.props.customerCurrencyCode}
                  />
                )}
                footer={() => (
                  <React.Fragment>
                    <SecondaryButton
                      onClick={() => this.onHideRenewalPeriodModal()}
                    >
                      {translate("domain.general-info.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      disabled={this.state.renewDomainSubmitting}
                      submitting={this.state.renewDomainSubmitting}
                      onClick={() =>
                        this.onRenewDomainWithPeriod(this.state.currentDomain)
                      }
                    >
                      {translate("domain.general-info.renew-domain")}
                    </PrimaryButton>
                  </React.Fragment>
                )}
              />
            )}

            {this.state.showDisableAutoRenewModal && (
              <ActionModal
                title={`${translate("domain.general-info.auto-renew")}`}
                onCloseActionModal={this.onHideDisableAutoRenewModal}
                body={() => (
                  <div>
                    {translate("domain.settings.auto-renew-description")}
                  </div>
                )}
                footer={() => (
                  <React.Fragment>
                    <SecondaryButton
                      onClick={() => this.onHideDisableAutoRenewModal()}
                    >
                      {translate("domain.general-info.cancel")}
                    </SecondaryButton>
                    <PrimaryWarningButton
                      disabled={this.state.renewNowSubmitting}
                      submitting={this.state.renewNowSubmitting}
                      onClick={() => this.onCancelDomain()}
                    >
                      {translate("domain.general-info.disable")}
                    </PrimaryWarningButton>
                  </React.Fragment>
                )}
              />
            )}

            {this.state.showBuyDomainIdProtectionModal &&
              this.state.domainIdProtectionService && (
                <ActionModal
                  title={translate("domain.addons.id-protect.confirm-title")}
                  onCloseActionModal={this.onHideBuyDomainIdProtectionModal}
                  body={() => (
                    <div>
                      {parse(
                        translate(
                          "domain.addons.id-protect.confirm-description"
                        )
                          .replace(
                            "${PRICE}",
                            this.props.customerCurrencyCode === "мкд"
                              ? parseFloat(
                                this.state.domainIdProtectionService.pricing
                                  .buy.billing_cycle.derivations[0].price
                                  .value
                              ).toFixed(0)
                              : parseFloat(
                                this.state.domainIdProtectionService.pricing
                                  .buy.billing_cycle.derivations[0].price
                                  .value
                              ).toFixed(2)
                          )
                          .replace(
                            "${CURRENCY}",
                            this.props.customerCurrencyCode.toUpperCase()
                          )
                      )}
                    </div>
                  )}
                  footer={() => (
                    <React.Fragment>
                      <SecondaryButton
                        onClick={() => this.onHideBuyDomainIdProtectionModal()}
                      >
                        {translate("domain.general-info.cancel")}
                      </SecondaryButton>
                      <PrimaryButton
                        disabled={this.state.buyDomainIdProtectionSubmitting}
                        submitting={this.state.buyDomainIdProtectionSubmitting}
                        onClick={() =>
                          this.enableIdProtection(this.state.currentDomain)
                        }
                      >
                        {translate("domain.addons.id-protect.confirm")}
                      </PrimaryButton>
                    </React.Fragment>
                  )}
                />
              )}
          </div>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  const currentDomain = getDomainToView(state);
  return {
    currentDomain,
    isFetchingEppTransferCode: isFetchingEppTransferCode(state),
    isFetchingDomainRegisterContacts: isFetchingDomainRegisterContacts(state),
    isFetchingDomainNameServers: isFetchingDomainNameServers(state),
    isFetchingDomainRedirectionAvailable: isFetchingDomainRedirectionAvailable(
      state
    ),
    isDomainRedirectionAvailable: isDomainRedirectionAvailable(state),
    domainRedirectionReason: getDomainRedirectionReason(state),
    isFetchingDomainRedirection: isFetchingDomainRedirection(state),
    domainRedirection: getDomainRedirection(state),
    isUpdatingDomainRedirection: isUpdatingDomainRedirection(state),
    countries: getCountriesFormatted(state),
    domainPricing: getDomainsPricing(state),
    customerCurrencyCode: getCustomerCurrencyCode(state),
    isFetchingDomains: isFetchingDomains(state),
    domainServices: currentDomain && getDomainServices(state, currentDomain),
    isStoringItemInCart: isStoringItemInCart(state)
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchDomain,
      fetchDomainNameServers,
      fetchDomainRegisterContacts,
      unlockDomain,
      lockDomain,
      fetchDomainLockStatus,
      fetchEppTransferCode,
      viewDomain,
      updateDomainNameservers,
      changeCustomerDomainContact,
      searchContacts,
      requestRenewProduct,
      cancelDomain,
      autoRenewDomain,
      requestRenewDomain,
      fetchCustomerDomainZoneFile,
      requestRenewDomainWithPeriod,
      fetchCustomerDomainRedirectionAvailable,
      fetchDomainRedirection,
      updateCustomerDomainRedirection,
      removeCustomerDomainRedirection,
      fetchDomains,
      fetchDomainServices,
      addProductToCart,
      fetchDomainSettings,
      buyDomainIdProtection
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withLocalize(ViewDomain)));
