import React from "react";
import styled from "styled-components";

const StyledInputIcon = styled.span`
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 16px;
  color: ${props => {
    const validColor = props.theme.greenBase;
    const invalidColor = props.theme.redBase;
    const normalColor = props.theme.neutral4;
    if (!props.valid && !props.invalid) return normalColor;
    if (props.valid && !props.invalid) return validColor;
    if (!props.valid && props.invalid) return invalidColor;
  }};
`;

const InputIcon = props => (
  <StyledInputIcon className={`input-icon icon-${props.icon}`} {...props} />
);

export default InputIcon;
