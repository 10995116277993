import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { withLocalize } from "react-localize-redux";
import { isMobileOnly, isTablet } from "react-device-detect";

import Container from "../../../../components/Containers/Container";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";

import withLoading from "../../../../components/Loaders/WithLoading";
import ContentLoader from "../../../../components/Loaders/ContentLoader";

import BadgeSuccess from "../../../../components/Badges/BadgeSuccess";
import Badge from "../../../../components/Badges/Badge";
import { isMobile } from "react-sizes/src/presets";

const StyledContainer = styled(Container)`
  margin-bottom: 30px;
`;

const StyledHeader = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  padding: ${props => (props.isMobile ? "15px" : "30px")};
`;

const StyledTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  color: ${theme.neutral5};
  text-transform: uppercase;
  display: inline-block;
  float: left;
  margin-top: 10px;
`;

const StyledAddNewButton = styled(PrimaryButton)`
  display: inline-block;
  float: right;
`;

const StyledAppListWrapper = styled.div``;

const StyledAppList = styled.div`
  overflow-y: auto;
  overflow-x: none;
  max-height: ${props => (!props.isMobile ? "700px" : "250px")};
`;

const StyledAppListItem = styled.div`
  padding: 30px;
  border-bottom: 1px solid ${theme.neutral3};
  background-color: ${props => props.selected && props.theme.blue1};

  &:last-child {
    border-bottom: 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:hover {
    background-color: ${theme.blue1};
    cursor: pointer;
  }

  & .badge {
    float: right;
    margin-top: -3px;
  }
`;

const StyledContainerWithLoading = withLoading(StyledContainer, ContentLoader);

const AppsList = ({
  translate,
  apps,
  isFetchingApps,
  onShowCreateAppModal,
  onSelectApp,
  isFetchingScopes
}) => (
  <div className="row">
    <div className="col-12 col-sm-12 col-md-12">
      <StyledContainerWithLoading
        isLoading={isFetchingApps || isFetchingScopes}
      >
        {!isMobile &&
          !isTablet && (
            <StyledHeader isMobile={isMobileOnly}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-6">
                  <StyledTitle>
                    {translate("my-account.apps.title")}
                  </StyledTitle>
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <StyledAddNewButton onClick={() => onShowCreateAppModal()}>
                    {translate("my-account.apps.create")}
                  </StyledAddNewButton>
                </div>
              </div>
            </StyledHeader>
          )}
        {(isMobile || isTablet) && (
          <StyledHeader isMobile={isMobileOnly}>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <StyledTitle>{translate("my-account.apps.title")}</StyledTitle>

                <StyledAddNewButton onClick={() => onShowCreateAppModal()}>
                  {translate("my-account.apps.create")}
                </StyledAddNewButton>
              </div>
            </div>
          </StyledHeader>
        )}
        <StyledAppList isMobile={isMobileOnly}>
          {(!apps || apps.length === 0) && (
            <StyledAppListItem>
              {translate("my-account.apps.no-apps")}
            </StyledAppListItem>
          )}
          {apps &&
            apps.length > 0 && (
              <StyledAppList>
                {apps.map(app => {
                  const BadgeComponent = app.is_active ? BadgeSuccess : Badge;
                  const status = app.is_active
                    ? translate("my-account.apps.active")
                    : translate("my-account.apps.inactive");
                  return (
                    <StyledAppListItem
                      key={app.id}
                      onClick={() => onSelectApp(app)}
                      selected={app.selected}
                    >
                      {app.name} <BadgeComponent>{status}</BadgeComponent>
                    </StyledAppListItem>
                  );
                })}
              </StyledAppList>
            )}
        </StyledAppList>
      </StyledContainerWithLoading>
    </div>
  </div>
);

export default withLocalize(AppsList);
