import * as AA from "../actions/addressingActions"; // EA = Example Actions

import { APP_STATE_COMPLETE_RESET } from "../../AppState/actions/appStateActions";

import handleReducerActions from "../../../core/handleReducerActions";

const initialState = {
  items: null,
  itemsFormatted: null,
  itemsIsoFormatted: null,
  isFetchingCountries: false
};

const addressingStateHandlers = {
  [AA.ADDRESSING_REQUEST]: addressingRequest,
  [AA.ADDRESSING_RECEIVE]: addressingReceive,
  [APP_STATE_COMPLETE_RESET]: resetState
};

// this is the actual reducer function
export default function(state = initialState, action) {
  return handleReducerActions(addressingStateHandlers, state, action);
}

function addressingRequest(state, action) {
  return { ...state, isFetchingCountries: true };
}

function addressingReceive(state, action) {
  const itemsFormatted = action.payload.map(item => {
    return {
      label: item.name,
      value: item.id
    };
  });

  const itemsIsoFormatted = action.payload.map(item => {
    return {
      label: item.name,
      value: item.iso2
    };
  });
  return {
    items: action.payload,
    itemsFormatted,
    itemsIsoFormatted,
    isFetchingCountries: false
  };
}

function resetState(state) {
  return { ...initialState };
}

// this is a selector
export function getCountries(state) {
  return state.addressing.items;
}

export function getCountriesFormatted(state) {
  return state.addressing.itemsFormatted;
}

export function getCountriesWithISOFormatted(state) {
  return state.addressing.itemsIsoFormatted;
}

export function isFetchingCountries(state) {
  return state.addressing.isFetchingCountries;
}
