import React from "react";
import { Translate, withLocalize } from "react-localize-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withSizes from "react-sizes";
import { debounce } from "throttle-debounce";
import styled from "styled-components";

import isLaptop from "../../../utils/isLaptopScreen";
import { theme } from "../../../design/Themes";

import Container from "../../../components/Containers/Container";
import Breadcrumb from "../../../components/Breadcrumb";
import BreadcrumbItem from "../../../components/Breadcrumb/BreadcrumbItem";
import withLoading from "../../../components/Loaders/WithLoading";
import Form from "../../../components/Forms/Form";
import SearchField from "../../../components/SearchField";
import KnowledgeBaseArticlesCategoryPanel from "../../../components/KnowledgeBase/KnowledgeBaseArticlesCategoryPanel";
import KnowledgeBaseArticle from "../../../components/KnowledgeBase/KnowledgeBaseArticle";
import MostPopularArticles from "../../../components/KnowledgeBase/MostPopularArticles";
import KnowledgeBaseCategoriesLoader from "../../../components/Loaders/KnowledgeBaseCategoriesLoader";
import KnowledgeBaseArticlesSearchByKeywordResultsContainer from "../../../components/KnowledgeBase/KnowledgeBaseArticlesSearchByKeywordResultsContainer";

import {
  compileRoute,
  ROUTE_INDEX,
  ROUTE_HELPDESK,
  ROUTE_HELPDESK_VIEW_KNOWLEDGE_BASE_ARTICLE,
  ROUTE_HELPDESK_KNOWLEDGE_BASE_CATEGORY
} from "../../../routes/routes";

import {
  fetchHelpDeskKnowledgeBaseCategories,
  fetchHelpDeskKnowledgeBaseMostPopularArticles,
  searchKnowledgeBaseArticles,
  voteArticle
} from "../../HelpDesk/actions/helpdeskActions";

import {
  getKnowledgeBaseCategories,
  isFetchingKnowledgeBaseCategories,
  getKnowledgeBaseMostPopularArticles,
  isFetchingKnowledgeBaseMostPopularArticles,
  isFetchingKnowledgeBaseArticleByKeyword,
  getKnowledgeBaseSearchedArticles
} from "../../HelpDesk/reducers/helpdeskReducer";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

const StyledKnowledgeBaseContainer = styled.div`
  & a.tickets-type {
    font-size: 16px;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }
`;

const StyledKnowledgeBase = styled.div`
  & .knowledge-base-search {
    padding: 25px 25px 10px 25px;
    border-bottom: 1px solid ${theme.neutral3};
  }

  & .knowledge-base-categories-title {
    padding: 25px 25px 25px 25px;
    border-bottom: 1px solid ${theme.neutral3};
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: var(--neutral-5);
  }
`;

class KnowledgeBase extends React.Component {
  constructor(props) {
    super(props);

    this.knowledgeArticlePath = compileRoute(
      ROUTE_HELPDESK_VIEW_KNOWLEDGE_BASE_ARTICLE
    );

    this.knowledgeCategoryPath = compileRoute(
      ROUTE_HELPDESK_KNOWLEDGE_BASE_CATEGORY
    );

    this.state = {
      searchQuery: ""
    };

    this.searchArticlesByKeywordThrottled = debounce(
      1000,
      this.searchArticlesByKeyword
    );
  }

  componentDidMount() {
    if (!this.props.knowledgeBaseCategories.length)
      this.props.fetchHelpDeskKnowledgeBaseCategories();

    if (!this.props.knowledgeBaseMostPopularArticles.length)
      this.props.fetchHelpDeskKnowledgeBaseMostPopularArticles();
  }

  searchArticlesByKeyword = value => {
    if (value.length > 0) this.props.searchKnowledgeBaseArticles(value);
  };

  onChangeKeywordSearch = event => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.searchArticlesByKeywordThrottled(this.state.searchQuery);
    });
  };

  onVoteArticle = (article, votes) => {
    return this.props.voteArticle(article, votes).then(result => {
      if (result.alreadyVoted) {
        displayToastMessageForResponse(
          this.props.translate("help-desk.knowledge-base.title"),
          {
            error: true,
            messages: {
              0: this.props.translate("help-desk.knowledge-base.already-voted")
            }
          }
        );
      } else if (!result.alreadyVoted) {
        displayToastMessageForResponse(
          this.props.translate("help-desk.knowledge-base.title"),
          {
            error: false,
            messages: {
              0: this.props.translate("help-desk.knowledge-base.vote-added")
            }
          }
        );
      }
    });
  };

  render() {
    const StyledKnowledgeBaseContainerCategoriesWithLoading = withLoading(
      StyledKnowledgeBaseContainer,
      KnowledgeBaseCategoriesLoader
    );

    const StyledKnowledgeBaseContainerMostPopularArticlesWithLoading = withLoading(
      StyledKnowledgeBaseContainer,
      KnowledgeBaseCategoriesLoader
    );

    return (
      <Translate>
        {({ translate }) => (
          <StyledKnowledgeBase>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                <Breadcrumb>
                  <BreadcrumbItem
                    to={ROUTE_INDEX}
                    title={translate("breadcrumbs.home")}
                  />
                  <BreadcrumbItem
                    to={ROUTE_HELPDESK}
                    title={translate("breadcrumbs.help-desk")}
                  />
                  <BreadcrumbItem
                    title={translate("breadcrumbs.knowledge-base")}
                  />
                </Breadcrumb>

                <div className="row">
                  <div className="col-5 col-sm-5 col-md-5">
                    <Container>
                      <div className="knowledge-base-categories-title">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            {translate("help-desk.knowledge-base.categories")}
                          </div>
                        </div>
                      </div>
                      <div className="knowledge-base-search">
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            <SearchField
                              onChange={this.onChangeKeywordSearch}
                              name="search"
                              placeholder={translate(
                                "help-desk.knowledge-base.search-placeholder"
                              )}
                            />
                          </div>
                        </div>
                      </div>
                      <KnowledgeBaseArticlesSearchByKeywordResultsContainer
                        searchQuery={this.state.searchQuery}
                        isFetchingKnowledgeBaseArticleByKeyword={
                          this.props.isFetchingKnowledgeBaseArticleByKeyword
                        }
                        searchedArticles={this.props.searchedArticles}
                        knowledgeArticlePath={this.knowledgeArticlePath}
                      />
                      <StyledKnowledgeBaseContainerCategoriesWithLoading
                        isLoading={this.props.isFetchingKnowledgeBaseCategories}
                      >
                        {this.props.knowledgeBaseCategories.map(category => {
                          return (
                            <KnowledgeBaseArticlesCategoryPanel
                              key={`${`helpdesk-knowledge-base-category-${category.id}`}`}
                              category={category}
                              knowledgeCategoryPath={this.knowledgeCategoryPath}
                            />
                          );
                        })}
                      </StyledKnowledgeBaseContainerCategoriesWithLoading>
                    </Container>
                  </div>
                  <div className="col-7 col-sm-7 col-md-7">
                    <Container>
                      <StyledKnowledgeBaseContainerMostPopularArticlesWithLoading
                        isLoading={
                          this.props.isFetchingKnowledgeBaseMostPopularArticles
                        }
                      >
                        <MostPopularArticles>
                          {this.props.knowledgeBaseMostPopularArticles.map(
                            article => {
                              return (
                                <KnowledgeBaseArticle
                                  key={`${`helpdesk-knowledge-most-populararticle-${article.id}`}`}
                                  title={article.title}
                                  votes={article.votes}
                                  article={article}
                                  onVoteArticle={this.onVoteArticle}
                                  categoryName={article.category_name}
                                  showMeta={true}
                                  articleLink={this.knowledgeArticlePath({
                                    id: article.id
                                  })}
                                />
                              );
                            }
                          )}
                        </MostPopularArticles>
                      </StyledKnowledgeBaseContainerMostPopularArticlesWithLoading>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </StyledKnowledgeBase>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    isFetchingKnowledgeBaseCategories: isFetchingKnowledgeBaseCategories(state),
    knowledgeBaseCategories: getKnowledgeBaseCategories(state),
    isFetchingKnowledgeBaseMostPopularArticles: isFetchingKnowledgeBaseMostPopularArticles(
      state
    ),
    knowledgeBaseMostPopularArticles: getKnowledgeBaseMostPopularArticles(
      state
    ),
    searchedArticles: getKnowledgeBaseSearchedArticles(state),
    isFetchingKnowledgeBaseArticleByKeyword: isFetchingKnowledgeBaseArticleByKeyword(
      state
    )
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchHelpDeskKnowledgeBaseCategories,
      fetchHelpDeskKnowledgeBaseMostPopularArticles,
      searchKnowledgeBaseArticles,
      voteArticle
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(withLocalize(KnowledgeBase)));
