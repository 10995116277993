import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { Form, Field, FormSpy } from "react-final-form";
import { isMobile, isMobileOnly, isBrowser } from "react-device-detect";
import isLaptop from "../../../../../utils/isLaptopScreen";
import withSizes from "react-sizes";
import { Translate } from "react-localize-redux";

import Panel from "../../../../../components/Panel";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import Radiobox from "../../../../../components/Radiobox";
import Label from "../../../../../components/Label";
import InputField from "../../../../../components/InputField";
import CustomTooltip from "../../../../../components/CustomTooltip";

import {
  validateDnsIP4Address,
  validateNameserver
} from "../../../../../common/validationRules";
import {
  NAMESERVERS_DEFAULT,
  NAMESERVERS_CUSTOM,
  NAMESERVERS_CHILD
} from "./NameserverTypes";

import { Link } from "react-router-dom";
import {
  compileRoute,
  ROUTE_CREATE_TICKET_WITH_PARAMS
} from "../../../../../routes/routes";

const StyledNameserversOption = styled.div`
  margin-bottom: 20px;
`;

const StyledNameserversOptionContent = styled.div`
  padding: 15px;
  padding-left: 25px;

  & label {
    margin-right: 20px;
  }
`;

const StyledRequiredFieldAsteriks = styled.span`
  color: ${theme.redBase};
`;

const StyledNameserverAddress = styled.p`
  display: inline-block;
  font-size: 16px;
  line-height: 1.38;
  font-weight: 600;
  color: ${theme.neutralBase};
`;

const StyledNameserversOptionContentBrowser = styled.div`
  padding: 15px;
  padding-left: 25px;

  & label {
    margin-right: 0px;
    margin-top: 9px;
  }
`;

const StyledIpAddressWrapper = styled.div`
  width: ${props => (props.isMobile ? "100%" : "50%")};
`;

const formatNameservers = nameservers => {
  let ns1 = nameservers.list["ns1"] || "";
  let ip_address_1 = "";
  if (ns1 !== "") {
    ip_address_1 = ns1.split(/[- ]+/).pop();
    ns1 = nameservers.list["ns1"].slice(
      0,
      nameservers.list["ns1"].length - ip_address_1.length - 1
    );

    if (validateDnsIP4Address(ip_address_1) !== undefined) {
      ip_address_1 = "";
      ns1 = nameservers.list["ns1"];
    }
  }

  let ns2 = nameservers.list["ns2"] || "";
  let ip_address_2 = "";
  if (ns2 !== "") {
    ip_address_2 = ns2.split(/[- ]+/).pop();
    ns2 = nameservers.list["ns2"].slice(
      0,
      nameservers.list["ns2"].length - ip_address_2.length - 1
    );
    if (validateDnsIP4Address(ip_address_2) !== undefined) {
      ip_address_2 = "";
      ns2 = nameservers.list["ns2"];
    }
  }

  let ns3 = nameservers.list["ns3"] || "";
  let ip_address_3 = "";
  if (ns3 !== "") {
    ip_address_3 = ns3.split(/[- ]+/).pop();
    ns3 = nameservers.list["ns3"].slice(
      0,
      nameservers.list["ns3"].length - ip_address_3.length - 1
    );
    if (validateDnsIP4Address(ip_address_3) !== undefined) {
      ip_address_3 = "";
      ns3 = nameservers.list["ns3"];
    }
  }

  let ns4 = nameservers.list["ns4"] || "";
  let ip_address_4 = "";
  if (ns4 !== "") {
    ip_address_4 = ns4.split(/[- ]+/).pop();
    ns4 = nameservers.list["ns4"].slice(
      0,
      nameservers.list["ns4"].length - ip_address_4.length - 1
    );
    if (validateDnsIP4Address(ip_address_4) !== undefined) {
      ip_address_4 = "";
      ns4 = nameservers.list["ns4"];
    }
  }

  let ns5 = nameservers.list["ns5"] || "";
  let ip_address_5 = "";
  if (ns5 !== "") {
    ip_address_5 = ns5.split(/[- ]+/).pop();
    ns5 = nameservers.list["ns5"].slice(
      0,
      nameservers.list["ns5"].length - ip_address_5.length - 1
    );
    if (validateDnsIP4Address(ip_address_5) !== undefined) {
      ip_address_5 = "";
      ns5 = nameservers.list["ns5"];
    }
  }

  return {
    ns1,
    ns2,
    ns3,
    ns4,
    ns5,
    ip_address_1,
    ip_address_2,
    ip_address_3,
    ip_address_4,
    ip_address_5
  };
};

const initForm = nameservers => {
  if (nameservers.type === NAMESERVERS_DEFAULT) {
    return {
      ...nameservers.default_name_servers
    };
  } else if (nameservers.type === NAMESERVERS_CUSTOM) {
    const { ns1, ns2, ns3, ns4, ns5 } = formatNameservers(nameservers);
    return {
      ns1,
      ns2,
      ns3,
      ns4,
      ns5
    };
  } else if (nameservers.type === NAMESERVERS_CHILD) {
    const {
      ns1,
      ns2,
      ns3,
      ns4,
      ns5,
      ip_address_1,
      ip_address_2,
      ip_address_3,
      ip_address_4,
      ip_address_5
    } = formatNameservers(nameservers);

    return {
      ns1,
      ns2,
      ns3,
      ns4,
      ns5,
      ip_address_1,
      ip_address_2,
      ip_address_3,
      ip_address_4,
      ip_address_5
    };
  }
};

class Nameservers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type:
        this.props.nameservers && this.props.nameservers.type
          ? this.props.nameservers.type
          : NAMESERVERS_DEFAULT
    };
  }

  onSetNameserversType = type => {
    this.setState({ type });
  };

  validateNameservers = values => {
    if (this.state.type === NAMESERVERS_DEFAULT) {
      return undefined;
    }

    if (this.state.type === NAMESERVERS_CUSTOM) {
      return {
        ns1: validateNameserver(values.ns1, true),
        ns2: validateNameserver(values.ns2, true),
        ns3: validateNameserver(values.ns3),
        ns4: validateNameserver(values.ns4),
        ns5: validateNameserver(values.ns5)
      };
    }

    if (this.state.type === NAMESERVERS_CHILD) {
      return {
        ns1: validateNameserver(values.ns1, true),
        ns2: validateNameserver(values.ns2, true),
        ns3: validateNameserver(values.ns3),
        ns4: validateNameserver(values.ns4),
        ns5: validateNameserver(values.ns5),
        ip_address_1: validateDnsIP4Address(values.ip_address_1),
        ip_address_2: validateDnsIP4Address(values.ip_address_2),
        ip_address_3: validateDnsIP4Address(values.ip_address_3, false),
        ip_address_4: validateDnsIP4Address(values.ip_address_4, false),
        ip_address_5: validateDnsIP4Address(values.ip_address_5, false)
      };
    }
  };

  render() {
    const {
      nameservers,
      onSetNameserversType,
      onSaveNameservers,
      onSubmittingNameservers,
      submittingNameservers,
      nameserversInvalid,
      isLaptop,
      onNameServersKeyPress
    } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <Panel
            title={translate("domain.settings.nameservers")}
            description={translate("domain.settings.nameservers-description")}
            footer={() => (!this.props.domain.domain_name.endsWith(".rs") && !this.props.domain.domain_name.endsWith(".al") ) ? [
              <SecondaryButton key="cancel-ns">
                {translate("domain.settings.cancel")}
              </SecondaryButton>,
              <PrimaryButton
                type="submit"
                disabled={submittingNameservers || nameserversInvalid}
                submitting={submittingNameservers}
                onClick={e =>
                  document
                    .getElementById("domain-nameservers-form")
                    .dispatchEvent(new Event("submit", { cancelable: true }))
                }
                key="save-ns"
              >
                {translate("domain.settings.save-changes")}
              </PrimaryButton>
            ] : [
                <CustomTooltip
                  trigger={["hover"]}
                  content={translate("domain.general-info.update-dns-for-al-rs-tlds-open-ticket-info")}
                >
                  <div className="d-inline-block">
                    <Link to={compileRoute(ROUTE_CREATE_TICKET_WITH_PARAMS)({id: 12})}>
                      <PrimaryButton>
                        {translate("help-desk.ticket.open-new-ticket")}
                      </PrimaryButton>
                    </Link>
                  </div>
                </CustomTooltip>
            ]}
          >
            <Form
              onSubmit={onSaveNameservers}
              validate={this.validateNameservers}
              initialValues={initForm(nameservers)}
              render={({ handleSubmit }) => (
                <form id="domain-nameservers-form" onSubmit={handleSubmit}>
                  <div className="row">
                    <StyledNameserversOption className="col-12 col-sm-12 col-md-12">
                      <Field
                        component={Radiobox}
                        checked={this.state.type === NAMESERVERS_DEFAULT}
                        onChange={e => {
                          this.onSetNameserversType(NAMESERVERS_DEFAULT);
                          onSetNameserversType(NAMESERVERS_DEFAULT);
                        }}
                        name={NAMESERVERS_DEFAULT}
                        value={NAMESERVERS_DEFAULT}
                        indeterminate={false}
                      >
                        {translate(
                          "domain.settings.use-our-default-nameservers"
                        )}
                      </Field>
                      {this.state.type === NAMESERVERS_DEFAULT &&
                        nameservers.default_name_servers && (
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              {(isLaptop || isMobile) && (
                                <StyledNameserversOptionContent className="row">
                                  <div className="col-12 col-sm-12 col-md-12">
                                    <div className="row">
                                      <div className="col-12 col-sm-12 col-md-12">
                                        <Label>
                                          {translate(
                                            "domain.settings.nameserver"
                                          )}{" "}
                                          1
                                        </Label>
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-12">
                                        <StyledNameserverAddress>
                                          {nameservers.default_name_servers.ns1}
                                        </StyledNameserverAddress>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 col-sm-12 col-md-12">
                                        <Label>
                                          {translate(
                                            "domain.settings.nameserver"
                                          )}{" "}
                                          2
                                        </Label>
                                      </div>
                                      <div className="col-12 col-sm-12 col-md-12">
                                        <StyledNameserverAddress>
                                          {nameservers.default_name_servers.ns2}
                                        </StyledNameserverAddress>
                                      </div>
                                    </div>
                                  </div>
                                </StyledNameserversOptionContent>
                              )}
                              {!isLaptop && !isMobile && isBrowser && (
                                <StyledNameserversOptionContent className="row">
                                  <div className="col-12 col-sm-12 col-md-12">
                                    <div className="row">
                                      <div className="col-12 col-sm-12 col-md-12">
                                        <Label>
                                          {translate(
                                            "domain.settings.nameserver"
                                          )}{" "}
                                          1
                                        </Label>
                                        <StyledNameserverAddress>
                                          {nameservers.default_name_servers.ns1}
                                        </StyledNameserverAddress>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-12 col-sm-12 col-md-12">
                                        <Label>
                                          {translate(
                                            "domain.settings.nameserver"
                                          )}{" "}
                                          2
                                        </Label>

                                        <StyledNameserverAddress>
                                          {nameservers.default_name_servers.ns2}
                                        </StyledNameserverAddress>
                                      </div>
                                    </div>
                                  </div>
                                </StyledNameserversOptionContent>
                              )}
                            </div>
                          </div>
                        )}
                    </StyledNameserversOption>
                    <StyledNameserversOption className="col-12 col-sm-12 col-md-12">
                      <Field
                        component={Radiobox}
                        checked={this.state.type === NAMESERVERS_CUSTOM}
                        onChange={e => {
                          this.onSetNameserversType(NAMESERVERS_CUSTOM);
                          onSetNameserversType(NAMESERVERS_CUSTOM);
                        }}
                        name={NAMESERVERS_CUSTOM}
                        value={NAMESERVERS_CUSTOM}
                        indeterminate={false}
                      >
                        {translate("domain.settings.use-custom-nameservers")}
                      </Field>
                      {this.state.type === NAMESERVERS_CUSTOM && (
                        <div className="row">
                          <div className="col-12 col-sm-12 col-md-12">
                            {(isLaptop || isMobile) && (
                              <StyledNameserversOptionContent className="row">
                                <div className="col-12 col-sm-12 col-md-12">
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Label>
                                        {translate(
                                          "domain.settings.nameserver"
                                        )}{" "}
                                        1
                                        <StyledRequiredFieldAsteriks>
                                          *
                                        </StyledRequiredFieldAsteriks>
                                      </Label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Field
                                        component={InputField}
                                        onKeyDown={onNameServersKeyPress}
                                        name="ns1"
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 1`}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Label>
                                        {translate(
                                          "domain.settings.nameserver"
                                        )}{" "}
                                        2
                                        <StyledRequiredFieldAsteriks>
                                          *
                                        </StyledRequiredFieldAsteriks>
                                      </Label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Field
                                        component={InputField}
                                        name="ns2"
                                        onKeyDown={onNameServersKeyPress}
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 2`}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Label>
                                        {translate(
                                          "domain.settings.nameserver"
                                        )}{" "}
                                        3
                                      </Label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Field
                                        component={InputField}
                                        name="ns3"
                                        onKeyDown={onNameServersKeyPress}
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 3`}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Label>
                                        {translate(
                                          "domain.settings.nameserver"
                                        )}{" "}
                                        4
                                      </Label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Field
                                        component={InputField}
                                        name="ns4"
                                        onKeyDown={onNameServersKeyPress}
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 4`}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Label>
                                        {translate(
                                          "domain.settings.nameserver"
                                        )}{" "}
                                        5
                                      </Label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Field
                                        component={InputField}
                                        name="ns5"
                                        onKeyDown={onNameServersKeyPress}
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 5`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </StyledNameserversOptionContent>
                            )}
                            {!isLaptop && !isMobile && isBrowser && (
                              <StyledNameserversOptionContentBrowser className="row">
                                <div className="col-12 col-sm-12 col-md-12">
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-4">
                                      <Label>
                                        {translate(
                                          "domain.settings.nameserver"
                                        )}{" "}
                                        1
                                        <StyledRequiredFieldAsteriks>
                                          *
                                        </StyledRequiredFieldAsteriks>
                                      </Label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-8">
                                      <Field
                                        component={InputField}
                                        name="ns1"
                                        onKeyDown={onNameServersKeyPress}
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 1`}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-4">
                                      <Label>
                                        {translate(
                                          "domain.settings.nameserver"
                                        )}{" "}
                                        2
                                        <StyledRequiredFieldAsteriks>
                                          *
                                        </StyledRequiredFieldAsteriks>
                                      </Label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-8">
                                      <Field
                                        component={InputField}
                                        name="ns2"
                                        onKeyDown={onNameServersKeyPress}
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 2`}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-4">
                                      <Label>
                                        {translate(
                                          "domain.settings.nameserver"
                                        )}{" "}
                                        3
                                      </Label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-8">
                                      <Field
                                        component={InputField}
                                        name="ns3"
                                        onKeyDown={onNameServersKeyPress}
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 3`}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-4">
                                      <Label>
                                        {translate(
                                          "domain.settings.nameserver"
                                        )}{" "}
                                        4
                                      </Label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-8">
                                      <Field
                                        component={InputField}
                                        name="ns4"
                                        onKeyDown={onNameServersKeyPress}
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 4`}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-4">
                                      <Label>
                                        {translate(
                                          "domain.settings.nameserver"
                                        )}{" "}
                                        5
                                      </Label>
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-8">
                                      <Field
                                        component={InputField}
                                        name="ns5"
                                        onKeyDown={onNameServersKeyPress}
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 5`}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </StyledNameserversOptionContentBrowser>
                            )}
                          </div>
                        </div>
                      )}
                    </StyledNameserversOption>
                    {(this.props.domain.domain_name.endsWith(".mk") ||
                      this.props.domain.domain_name.endsWith(".мкд")) && (
                      <StyledNameserversOption className="col-12 col-sm-12 col-md-12">
                        <Field
                          component={Radiobox}
                          checked={this.state.type === NAMESERVERS_CHILD}
                          onChange={e => {
                            this.onSetNameserversType(NAMESERVERS_CHILD);
                            onSetNameserversType(NAMESERVERS_CHILD);
                          }}
                          name={NAMESERVERS_CHILD}
                          value={NAMESERVERS_CHILD}
                          indeterminate={false}
                        >
                          {translate(
                            "domain.settings.create-child-nameservers"
                          )}
                        </Field>
                        {this.state.type === NAMESERVERS_CHILD && (
                          <div className="row">
                            <div className="col-12 col-sm-12 col-md-12">
                              <StyledNameserversOptionContent className="row">
                                <div className="col-12 col-sm-12 col-md-12">
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Label>
                                        {translate(
                                          "domain.settings.nameserver"
                                        )}{" "}
                                        1
                                        <StyledRequiredFieldAsteriks>
                                          *
                                        </StyledRequiredFieldAsteriks>
                                      </Label>
                                      <Field
                                        component={InputField}
                                        onKeyDown={onNameServersKeyPress}
                                        name="ns1"
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 1`}
                                      />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <StyledIpAddressWrapper
                                        isMobile={isMobileOnly}
                                      >
                                        <Field
                                          component={InputField}
                                          onKeyDown={onNameServersKeyPress}
                                          label={translate(
                                            "domain.settings.ip-address"
                                          )}
                                          name="ip_address_1"
                                          placeholder={`${translate(
                                            "domain.settings.ip-address"
                                          )} 1`}
                                        />
                                      </StyledIpAddressWrapper>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Label>
                                        {translate(
                                          "domain.settings.nameserver"
                                        )}{" "}
                                        2
                                        <StyledRequiredFieldAsteriks>
                                          *
                                        </StyledRequiredFieldAsteriks>
                                      </Label>
                                      <Field
                                        component={InputField}
                                        onKeyDown={onNameServersKeyPress}
                                        name="ns2"
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 2`}
                                      />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <StyledIpAddressWrapper
                                        isMobile={isMobileOnly}
                                      >
                                        <Field
                                          component={InputField}
                                          name="ip_address_2"
                                          label={translate(
                                            "domain.settings.ip-address"
                                          )}
                                          onKeyDown={onNameServersKeyPress}
                                          placeholder={`${translate(
                                            "domain.settings.ip-address"
                                          )} 2`}
                                        />
                                      </StyledIpAddressWrapper>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Field
                                        component={InputField}
                                        label={`${translate(
                                          "domain.settings.nameserver"
                                        )} 3`}
                                        name="ns3"
                                        onKeyDown={onNameServersKeyPress}
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 3`}
                                      />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <StyledIpAddressWrapper
                                        isMobile={isMobileOnly}
                                      >
                                        <Field
                                          component={InputField}
                                          label={`${translate(
                                            "domain.settings.ip-address"
                                          )} 3`}
                                          name="ip_address_3"
                                          onKeyDown={onNameServersKeyPress}
                                          placeholder={`${translate(
                                            "domain.settings.ip-address"
                                          )} 3`}
                                        />
                                      </StyledIpAddressWrapper>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Field
                                        component={InputField}
                                        label={`${translate(
                                          "domain.settings.nameserver"
                                        )} 4`}
                                        name="ns4"
                                        onKeyDown={onNameServersKeyPress}
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 4`}
                                      />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <StyledIpAddressWrapper
                                        isMobile={isMobileOnly}
                                      >
                                        <Field
                                          component={InputField}
                                          name="ip_address_4"
                                          label={`${translate(
                                            "domain.settings.ip-address"
                                          )} 4`}
                                          onKeyDown={onNameServersKeyPress}
                                          placeholder={`${translate(
                                            "domain.settings.ip-address"
                                          )} 4`}
                                        />
                                      </StyledIpAddressWrapper>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <Field
                                        component={InputField}
                                        label={`${translate(
                                          "domain.settings.nameserver"
                                        )} 5`}
                                        name="ns5"
                                        onKeyDown={onNameServersKeyPress}
                                        placeholder={`${translate(
                                          "domain.settings.nameserver"
                                        )} 5`}
                                      />
                                    </div>
                                    <div className="col-12 col-sm-12 col-md-12">
                                      <StyledIpAddressWrapper
                                        isMobile={isMobileOnly}
                                      >
                                        <Field
                                          component={InputField}
                                          name="ip_address_5"
                                          label={`${translate(
                                            "domain.settings.ip-address"
                                          )} 5`}
                                          onKeyDown={onNameServersKeyPress}
                                          placeholder={`${translate(
                                            "domain.settings.ip-address"
                                          )} 5`}
                                        />
                                      </StyledIpAddressWrapper>
                                    </div>
                                  </div>
                                </div>
                              </StyledNameserversOptionContent>
                            </div>
                          </div>
                        )}
                      </StyledNameserversOption>
                    )}
                  </div>
                  <FormSpy
                    subscription={{ submitting: true, invalid: true }}
                    onChange={formstate =>
                      onSubmittingNameservers({ ...formstate })
                    }
                  />
                </form>
              )}
            />
          </Panel>
        )}
      </Translate>
    );
  }
}

export default withSizes(isLaptop)(Nameservers);
