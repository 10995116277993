import React, { useEffect, useState } from "react";
import { Translate } from "react-localize-redux";
import * as emailService from "../../services/emailVerificationService";
import EmailVerificationError from "../EmailVerification/EmailVerificationError/EmailVerificationError";
import EmailVerificationSuccess from "../EmailVerification/EmailVerificationSuccess/EmailVerificationSuccess";
import { fetchCustomerPersonalInformation } from "../../services/customerService";
import { connect } from "react-redux";
import { isAuthenticated } from "../Authentication/reducers/authReducer";
import ContentLoader from "../../components/Loaders/ContentLoader";
import { useSelector } from 'react-redux';

const EmailVerification = () => {
  const [verifiedEmail, setVerifiedEmail] = useState("");
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);

  const isLoggedIn = useSelector(isAuthenticated);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const email = params.get("email");
    const token = params.get("token");

    if (email && token) {
      setVerifiedEmail(email);

      if (isLoggedIn) {
        fetchCustomerPersonalInformation()
          .then((response) => {
            console.log(response);
            if (response.data.data.customer.email_verified) {
              setIsVerified(true);
              setLoading(false);
            } else {
              emailService
                .verifyEmailService(email, token)
                .then((response) => {
                  if (response.error) {
                    setError(true);
                    setLoading(false);
                  } else {
                    fetchCustomerPersonalInformation()
                      .then((response) => {
                        setLoading(false);
                        setIsVerified(true);
                      })
                      .catch((error) => {
                        setError(true);
                        setLoading(false);
                      });
                  }
                })
                .catch((error) => {
                  setError(true);
                  setLoading(false);
                });
            }
          });
      } else {
        emailService
          .verifyEmailService(email, token)
          .then((response) => {
            if (response.error) {
              setError(true);
              setLoading(false);
            } else {
              setLoading(false);
              setIsVerified(true);
              console.log({ ...response });
            }
          })
          .catch((error) => {
            setError(true);
            setLoading(false);
          });
      }
    }
  }, [isLoggedIn]);

  if (loading) {
    return <ContentLoader />;
  }

  if (isVerified) {
    return <EmailVerificationSuccess />;
  }

  return <EmailVerificationError />;
};

const mapDispatchToProps = {
  fetchCustomerPersonalInformation,
};

export default connect(null, mapDispatchToProps)(EmailVerification);
