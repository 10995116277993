import React from "react";
import axios from "axios";
import { withRouter } from 'react-router-dom';
import { CUSTOMER_DOMAINS_BULLK_RENEW } from "../../../services/endpoints";
import { ROUTE_BILLING_VIEW_PROINVOICE } from "../../../routes/routes";
import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import ExternalLink from "../../../components/ExternalLink";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import withSizes from "react-sizes";
import isLaptop from "../../../utils/isLaptopScreen";
import { debounce } from "throttle-debounce";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import moment from "moment";
import parseDomain from "../../../utils/parseDomain";
import parse from "html-react-parser";
import Container from "../../../components/Containers/Container";
import ButtonGroup from "../../../components/ButtonGroup";
import ButtonGroupContentDropdown from "../../../components/ButtonGroupContentDropdown";
import ButtonGroupDropdown from "../../../components/ButtonGroupDropdown";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import ActionModal from "../../../components/ActionModal";
import { getCustomerCurrencyCode } from "../../Customer/reducers/customerReducer";
import SelectBox from "../../../components/SelectBox";
import NoticeInfo from "../../../components/Notices/NoticeInfo";

import Form from "../../../components/Forms/Form";
import SearchField from "../../../components/SearchField";
import Checkbox from "../../../components/Checkbox";
import AmountBadge from "../../../components/AmountBadge";
import Icon from "../../../components/Icon";
import DropdownItem from "../../../components/DropdownItem";
import DropdownItemDescription from "../../../components/DropdownItemDescription";
import { getDomainsPricing } from "../reducers/domainsReducer";

import Table from "../../../components/Table";
import TableHeader from "../../../components/Table/TableHeader";
import TableHeading from "../../../components/Table/TableHeading";
import TableBody from "../../../components/Table/TableBody";
import TableRow from "../../../components/Table/TableRow";
import TableCell from "../../../components/Table/TableCell";

import Pagination from "../../../components/Pagination";

import DropdownButton from "../../../components/DropdownButton";

import DomainsFilter from "../DomainsFilter";

import getBadgeForStatus from "../../../utils/getBadgeForStatus";
import getStatusTextForStatus from "../../../utils/getStatusTextForStatus";
import {
  DOMAIN_STATUS_ACTIVE,
  DOMAIN_STATUS_PENDING,
  DOMAIN_STATUS_CANCELLED,
  DOMAIN_STATUS_REDEMPTION,
  DOMAIN_STATUS_GRACE_PERIOD,
  DOMAIN_STATUS_PENDING_TRANSFER
} from "../DomainStatuses";

import { fetchCustomerDomains } from "../../Customer/actions/customerActions";
import {
  getDomainListSelectedRowsCount,
  getCustomerDomains
} from "../../Customer/reducers/customerReducer";

import withLoading from "../../../components/Loaders/WithLoading";
import TableContentLoader from "../../../components/Loaders/TableContentLoader";


import {
  compileRoute,
  ROUTE_VIEW_DOMAIN_GENERAL_INFO,
  ROUTE_SHOP_DOMAINS
} from "../../../routes/routes";

import {
  SORT_ORDER_DESCENDING,
  SORT_ORDER_ASCENDING
} from "../../../types/SortTypes";

const StyledDomainsListWrapper = styled.div`
  margin-bottom: ${props => (props.isMobile ? "50px" : "30px")};
`;


const StyledDomainsList = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;

  & .hosting-list-header {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
  }

  & .hosting-list-header button.purchase-hosting {
    margin-right: 0;
  }

  & .hosting-list-header-action {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid ${theme.neutral3};
  }
`;

const StyledDomainsListContainer = styled.div`
  & a.domain-link {
    font-size: 16px;
  }

  & a.domain-link-block {
    display: block;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }
`;

const StyledCancelDomain = styled.span`
  color: #be2b16;
`;

const StyledAutoRenewDomain = styled.span`
  color: ${theme.greenBase};
`;

class DomainsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery: props.searchQuery,
      selectedDomains: [],
      domainRenewalPeriods: {},
      showSelectRenewalPeriodDomainModal: false,
    };
    console.log(this.props.domains, this.state.domain);

    this.viewDomainPath = compileRoute(ROUTE_VIEW_DOMAIN_GENERAL_INFO);

    this.searchDomainsByKeywordThrottled = debounce(
      1000,
      this.searchDomainsByKeyword
    );
  }

  handlePaginationClick = (event, page) => {
    event.preventDefault();

    if (page <= 0 || page > this.props.domains.paging.total_pages) {
      return;
    }

    this.props.fetchCustomerDomains(
      this.props.domains.paging.limit,
      page,
      this.props.domains.sort,
      this.props.domains.filters
    );
  };

  onPerPageOptionChanged = value => {
    this.props.fetchCustomerDomains(
      value.value,
      1,
      this.props.domains.sort,
      this.props.domains.filters
    );
  };

  handleRenewDomains = async () => {
    const { selectedDomains, domainRenewalPeriods } = this.state;

    const filteredDomains = selectedDomains.filter(domain => !domain.renewal_invoice_generated);

    const renewalData = filteredDomains.reduce((acc, domain) => {
      const renewalPeriod = domainRenewalPeriods[domain.id] ? domainRenewalPeriods[domain.id].value : 1;
      acc[domain.id] = renewalPeriod;
      return acc;
    }, {});

    console.log("Renewal Data to be sent:", renewalData);

    try {
      const response = await axios.put(CUSTOMER_DOMAINS_BULLK_RENEW, renewalData);

      const { error, data } = response.data || {};
      if (data && data.invoice) {
        const invoiceId = data.invoice.id;

        console.log(this.props.history);
        const route = compileRoute(ROUTE_BILLING_VIEW_PROINVOICE)({ id: invoiceId });
        console.log(route);
        this.props.history.push(route);

      } else if (error) {
        this.onHideRenewalPeriodModal();
        displayToastMessageForResponse(data.messages[1]);
      }
    } catch (error) {
      this.onHideRenewalPeriodModal();
    } finally {
      this.setState({
        showSelectRenewalPeriodDomainModal: false,
      });
    }
  };







  changeListOrderTrigger = (sortBy, orderBy) => {
    this.props.fetchCustomerDomains(
      this.props.domains.paging.limit,
      this.props.domains.paging.current_page,
      {
        sort_by: sortBy,
        order_by:
          orderBy === SORT_ORDER_DESCENDING
            ? SORT_ORDER_ASCENDING
            : SORT_ORDER_DESCENDING
      },
      this.props.domains.filters
    );
  };

  searchDomainsByKeyword = value => {
    this.props.fetchCustomerDomains(
      this.props.domains.paging.limit,
      1,
      this.props.domains.sort,
      {
        ...this.props.domains.filters,
        keyword: value
      }
    );
  };

  onChangeKeywordSearch = event => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.searchDomainsByKeywordThrottled(this.state.searchQuery);
    });
  };
  handleRenewSelectedDomains = () => {
    console.log(this.state.selectedDomains);
    this.setState({
      showSelectRenewalPeriodDomainModal: true,
    });
  };

  onRowSelectionChanged = (domain) => {
    const { selectedDomains } = this.state;
    const isSelected = selectedDomains.some((d) => d.id === domain.id);

    if (isSelected) {
      this.setState({
        selectedDomains: selectedDomains.filter((d) => d.id !== domain.id),
      });
    } else {
      this.setState({
        selectedDomains: [...selectedDomains, domain],
      });
    }
    domain.selected
      ? this.props.deselectCustomerDomainsListTableRow(domain)
      : this.props.selectCustomerDomainsListTableRow(domain);
  };



  onChangeRenewalPeriod = (domainId, period) => {
    this.setState(prevState => ({
      domainRenewalPeriods: {
        ...prevState.domainRenewalPeriods,
        [domainId]: period || 1,
      },
    }));
    console.log(this.state);
  };



  onHideRenewalPeriodModal = () => {
    console.log("This is triggered");
    this.setState({
      showSelectRenewalPeriodDomainModal: false,
    });
  };


  openPopup = () => {
    this.setState({ showPopup: true });
  };


  closePopup = () => {
    this.setState({ showPopup: false });
  };


  selectAllRows = () => {
    this.props.selectAllCustomerDomainsListTableRows();
  };

  deselectRows = () => {
    this.props.deselectAllCustomerDomainsListTableRows();
  };

  render() {
    const { selectedDomains, showPopup } = this.state;
    const StyledDomainsTableWithLoading = withLoading(
      StyledDomainsListContainer,
      TableContentLoader
    );

    const searchLayout = isMobileOnly
      ? "col-12 col-sm-12 col-md-12"
      : "col-6 col-sm-6 col-md-6";

    const filtersApplied = this.props.countFiltersApplied();
    const { domainPricing: domainPrices, customerCurrencyCode, translate } = this.props;



    return (
      <Translate>
        {({ translate }) => (

          <StyledDomainsListWrapper
            className="domains-list-wrapper"
            isMobile={isMobileOnly}
          >
            <Container>
              <StyledDomainsList>
                {isMobileOnly && (
                  <div className="hosting-list-header-action">
                    <ButtonGroup className="btn-group w-100">
                      <ButtonGroupDropdown
                        size="m"
                        text={translate("domains.table.add-new-domain")}
                        alignMenu="right"
                        buttonClassName="d-block w-100"
                        className="w-100"
                      >
                        <DropdownItem>
                          <Link to={ROUTE_SHOP_DOMAINS}>
                            {translate("domains.table.purchase-domain")}
                          </Link>
                        </DropdownItem>
                        <DropdownItem
                          onClick={e => this.props.onShowTransferDomain()}
                        >
                          {translate("domains.table.transfer-domain")}
                          <DropdownItemDescription>
                            {parse(
                              translate(
                                "domains.table.transfer-domain-description"
                              )
                            )}
                          </DropdownItemDescription>
                        </DropdownItem>
                      </ButtonGroupDropdown>

                    </ButtonGroup>

                  </div>
                )}
                <div className="hosting-list-header">
                  <div className="row">
                    <div className={searchLayout}>
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                        }}
                      >
                        <SearchField
                          onChange={this.onChangeKeywordSearch}
                          name="search"
                          value={this.state.searchQuery}
                          placeholder={translate(
                            "domains.table.search-placeholder"
                          )}
                        />

                        {this.state.showSelectRenewalPeriodDomainModal && (
                          <ActionModal
                            title={translate("domains.renewal-period")}
                            onCloseActionModal={this.onHideRenewalPeriodModal}
                            body={() => (
                              <div>
                                {this.state.selectedDomains.map((domain) => {
                                  const parsedDomain = parseDomain(domain.domain_name);
                                  let renewYears = [];

                                  for (let i = 1; i < 11; i++) {
                                    let renewPrice =
                                      domainPrices &&
                                      domainPrices[parsedDomain.tld] &&
                                      domainPrices[parsedDomain.tld][customerCurrencyCode] &&
                                      domainPrices[parsedDomain.tld][customerCurrencyCode].renew &&
                                      domainPrices[parsedDomain.tld][customerCurrencyCode].renew[i] ||
                                      undefined;

                                    if (renewPrice === "0.00") {
                                      renewPrice = translate("general.free");
                                    }

                                    if (renewPrice && renewPrice > "0.00") {
                                      renewPrice = `${i} ${translate(
                                        "general.years"
                                      )} (${customerCurrencyCode.toUpperCase()} ${customerCurrencyCode === "eur"
                                        ? parseFloat(renewPrice).toFixed(2)
                                        : parseFloat(renewPrice).toFixed(0)
                                        })`;
                                    }

                                    if (renewPrice === undefined) {
                                      renewPrice = `${i} ${translate("general.years")}`;
                                    }

                                    renewYears.push({
                                      label: renewPrice,
                                      value: i,
                                    });
                                  }

                                  const selectedValue = renewYears.find(
                                    item => item.value === domain.renewal_period
                                  );

                                  return (
                                    <div key={domain.id}>
                                      <span>{domain.domain_name}</span>

                                      {domain.renewal_invoice_generated === true ? (
                                        <>

                                          <NoticeInfo>
                                            {translate(
                                              "domains.table.cannot-renew"
                                            ).replace(
                                              "{DOMAIN_NAME}",
                                              domain.domain_name
                                            )}                 </NoticeInfo>
                                          <SelectBox
                                            label={translate("domains.chose-renewal-period")}
                                            options={renewYears}
                                            defaultValue={selectedValue}
                                            disabled
                                          />
                                        </>

                                      ) : (

                                        <SelectBox
                                          label={translate("domains.chose-renewal-period")}
                                          options={renewYears}
                                          defaultValue={selectedValue}
                                          input={{
                                            onChange: (value) => this.onChangeRenewalPeriod(domain.id, value),
                                          }}
                                        />

                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                            footer={() => (

                              <PrimaryButton
                                onClick={this.handleRenewDomains}
                              >
                                {translate("domains.table.renew-domains")}
                              </PrimaryButton>
                            )}
                          />
                        )}
                      </Form>
                    </div>
                    <div className={`${searchLayout} text-right`}>
                      {!isMobileOnly && (
                        <ButtonGroup className="btn-group">
                          <ButtonGroupDropdown
                            size="m"
                            text={translate("domains.table.add-new-domain")}
                            alignMenu="right"
                          >
                            <DropdownItem to={ROUTE_SHOP_DOMAINS}>
                              {translate("domains.table.purchase-domain")}
                            </DropdownItem>
                            <DropdownItem
                              onClick={e => this.props.onShowTransferDomain()}
                            >
                              {translate("domains.table.transfer-domain")}
                              <DropdownItemDescription>
                                {parse(
                                  translate(
                                    "domains.table.transfer-domain-description"
                                  )
                                )}
                              </DropdownItemDescription>
                            </DropdownItem>
                          </ButtonGroupDropdown>
                        </ButtonGroup>
                      )}

                      {this.props.isLaptop && (
                        <ButtonGroupContentDropdown
                          text={`${translate("domains.filter.title")}  ${filtersApplied > 0 ? " - " + filtersApplied : ""
                            }`}
                          size="m"
                          alignMenu="right"
                        >
                          <DomainsFilter
                            filterStatusOptions={this.props.filterStatusOptions}
                            filterExtensionsOptions={
                              this.props.filterExtensionsOptions
                            }
                            filterRegisterContactsOptions={
                              this.props.filterRegisterContactsOptions
                            }
                            fetchCustomerDomains={
                              this.props.fetchCustomerDomains
                            }
                            domains={this.props.domains}
                            domainsListFilters={this.props.domainsListFilters}
                            countFiltersApplied={this.props.countFiltersApplied}
                          />
                        </ButtonGroupContentDropdown>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <StyledDomainsTableWithLoading
                      isLoading={this.props.isLoading}
                    >
                      <Table>
                        {isBrowser && (
                          <TableHeader>
                            {this.props.selectedRowsCount === 0 ? (
                              <TableRow>
                                <TableHeading
                                  className="row-selector"
                                  width="5%"
                                ></TableHeading>
                                <TableHeading
                                  width="26%"
                                  isSortable={true}
                                  sortBy="name"
                                  isDefaultSort={
                                    this.props.domains.sort.sort_by === "name"
                                  }
                                  sortDirection={
                                    this.props.domains.sort.order_by
                                  }
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("domains.table.name")}
                                </TableHeading>
                                <TableHeading
                                  width="17%"
                                  isSortable={true}
                                  sortBy="expiry_date"
                                  isDefaultSort={
                                    this.props.domains.sort.sort_by ===
                                    "expiry_date"
                                  }
                                  sortDirection={
                                    this.props.domains.sort.order_by
                                  }
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("domains.table.expiration-date")}
                                </TableHeading>
                                <TableHeading
                                  className="text-right"
                                  width="17%"
                                  isSortable={true}
                                  sortBy="renewal_price"
                                  isDefaultSort={
                                    this.props.domains.sort.sort_by ===
                                    "renewal_price"
                                  }
                                  sortDirection={
                                    this.props.domains.sort.order_by
                                  }
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("domains.table.renewal-price")}
                                </TableHeading>
                                <TableHeading
                                  width="10%"
                                  isSortable={true}
                                  sortBy="status"
                                  isDefaultSort={
                                    this.props.domains.sort.sort_by === "status"
                                  }
                                  sortDirection={
                                    this.props.domains.sort.order_by
                                  }
                                  changeListOrderTrigger={
                                    this.changeListOrderTrigger
                                  }
                                >
                                  {translate("domains.table.status")}
                                </TableHeading>

                                <TableHeading width="20%" />
                              </TableRow>
                            ) : (
                              <TableRow>
                                <TableHeading colSpan={5}>
                                  <span style={{ marginRight: "10px" }}>
                                    {this.props.selectedRowsCount}{" "}
                                    {translate("domains.table.selected")}
                                  </span>
                                  <SecondaryButton size="s" onClick={() => {
                                    console.log(
                                      "Selected Domains and Periods:",
                                      this.state.domainRenewalPeriods
                                    );
                                    this.setState({
                                      showSelectRenewalPeriodDomainModal: true,
                                    });
                                  }} >
                                    {translate("domains.table.renew")}
                                  </SecondaryButton>
                                </TableHeading>
                              </TableRow>
                            )}
                          </TableHeader>
                        )}
                        <TableBody>
                          {this.props.domains.length === 0 && (
                            <TableRow>
                              <TableCell colSpan="6">
                                {translate("domains.table.empty")}
                              </TableCell>
                            </TableRow>
                          )}
                          {this.props.domains.items.length > 0 &&
                            this.props.domains.items.map(domain => {
                              const BadgeComponent = getBadgeForStatus(
                                domain.status.state
                              );

                              const StatusComponent = getStatusTextForStatus(
                                domain.status.state
                              );

                              return (
                                <TableRow
                                  key={`${`customer-domain-${domain.id}`}`}
                                >
                                  {isBrowser && (
                                    <TableCell className="row-selector" width="5%">
                                      {
                                        domain.status.code === DOMAIN_STATUS_ACTIVE && domain.renewal_invoice_generated === false ? (
                                          <Checkbox
                                            input={{
                                              onChange: e => this.onRowSelectionChanged(domain),
                                              value: domain.selected
                                            }}
                                          />
                                        ) : (
                                          <Checkbox disabled />
                                        )
                                      }

                                    </TableCell>)}

                                  <TableCell width="26%">
                                    {domain.status.code !==
                                      DOMAIN_STATUS_PENDING &&
                                      domain.status.code !==
                                      DOMAIN_STATUS_CANCELLED &&
                                      domain.status.code !==
                                      DOMAIN_STATUS_PENDING_TRANSFER ? (
                                      <Link
                                        className={
                                          isTablet || isMobileOnly
                                            ? "domain-link domain-link-block"
                                            : "domain-link"
                                        }
                                        to={this.viewDomainPath({
                                          id: domain.id,
                                          name: domain.domain_name
                                        })}
                                      >
                                        {domain.domain_name}
                                      </Link>
                                    ) : (
                                      domain.domain_name
                                    )}
                                    {(isTablet || isMobileOnly) && (
                                      <StatusComponent className="d-block">
                                        {translate(
                                          "domains.table.statuses." +
                                          domain.status.code
                                        )}
                                      </StatusComponent>
                                    )}
                                  </TableCell>

                                  {isBrowser && (
                                    <TableCell width="17%">
                                      {domain.status.code !==
                                        DOMAIN_STATUS_PENDING &&
                                        domain.status.code !==
                                        DOMAIN_STATUS_CANCELLED &&
                                        domain.status.code !==
                                        DOMAIN_STATUS_PENDING_TRANSFER &&
                                        domain.expiry_date}
                                    </TableCell>
                                  )}
                                  <TableCell width="17%" className="text-right">
                                    <AmountBadge
                                      currency={
                                        domain.renewal_price.currency.code
                                      }
                                      amount={domain.renewal_price.value}
                                    />
                                  </TableCell>
                                  {isBrowser && (
                                    <TableCell width="10%">
                                      <BadgeComponent>
                                        {translate(
                                          "domains.table.statuses." +
                                          domain.status.code
                                        )}
                                      </BadgeComponent>
                                    </TableCell>
                                  )}
                                  {!isMobileOnly && (
                                    <TableCell
                                      width="25%"
                                      className="text-right"
                                    >
                                      {isBrowser &&
                                        ((domain.status.code ===
                                          DOMAIN_STATUS_ACTIVE &&
                                          moment()
                                            .add(2, "M")
                                            .isAfter(
                                              moment(domain.expiry_date),
                                              "day"
                                            )) ||
                                          domain.status.code ===
                                          DOMAIN_STATUS_GRACE_PERIOD ||
                                          domain.status.code ===
                                          DOMAIN_STATUS_REDEMPTION) && (
                                          <SecondaryButton
                                            disabled={
                                              this.props
                                                .renewDomainSubmittingId ===
                                              domain.id &&
                                              this.props.renewDomainSubmitting
                                            }
                                            submitting={
                                              this.props
                                                .renewDomainSubmittingId ===
                                              domain.id &&
                                              this.props.renewDomainSubmitting
                                            }
                                            onClick={e =>
                                              this.props.onRenewProduct(domain)
                                            }
                                            size="s"
                                          >
                                            {translate(
                                              "domains.table.renew-now"
                                            )}
                                          </SecondaryButton>
                                        )}
                                      {domain.status.state ===
                                        DOMAIN_STATUS_ACTIVE && (
                                          <DropdownButton
                                            size="s"
                                            text={() => (
                                              <Icon icon="dots_horizontal" />
                                            )}
                                            alignMenu="right"
                                          >
                                            <DropdownItem
                                              onClick={e =>
                                                this.props.onChangeNameserversDomain(
                                                  domain
                                                )
                                              }
                                            >
                                              {translate(
                                                "domains.table.change-nameservers"
                                              )}
                                            </DropdownItem>

                                            {!domain.is_auto_renew_disabled && (
                                              <DropdownItem
                                                onClick={e =>
                                                  this.props.onCancelDomain(
                                                    domain
                                                  )
                                                }
                                              >
                                                <StyledCancelDomain>
                                                  {translate(
                                                    "domains.table.disable-auto-renew-domain"
                                                  )}
                                                </StyledCancelDomain>
                                              </DropdownItem>
                                            )}

                                            {domain.is_auto_renew_disabled &&
                                              domain.status.code !==
                                              DOMAIN_STATUS_PENDING &&
                                              domain.status.code !==
                                              DOMAIN_STATUS_CANCELLED &&
                                              domain.status.code !==
                                              DOMAIN_STATUS_PENDING_TRANSFER && (
                                                <DropdownItem
                                                  onClick={e =>
                                                    this.props.onAutoRenewDomain(
                                                      domain
                                                    )
                                                  }
                                                >
                                                  <StyledAutoRenewDomain>
                                                    {translate(
                                                      "domains.table.auto-renew-domain"
                                                    )}
                                                  </StyledAutoRenewDomain>
                                                </DropdownItem>
                                              )}
                                          </DropdownButton>
                                        )}
                                    </TableCell>
                                  )}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </StyledDomainsTableWithLoading>
                  </div>
                </div>
                <Pagination
                  itemsName={translate("domains.table.domains")}
                  paging={this.props.domains.paging}
                  handlePaginationClick={this.handlePaginationClick}
                  onPerPageOptionChanged={this.onPerPageOptionChanged}
                />
              </StyledDomainsList>
            </Container>
          </StyledDomainsListWrapper>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    selectedRowsCount: getDomainListSelectedRowsCount(state),
    domains: getCustomerDomains(state),
    domainPricing: getDomainsPricing(state),
    customerCurrencyCode: getCustomerCurrencyCode(state),
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      fetchCustomerDomains,

    },
    dispatch
  );
};
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(DomainsList)));