import axios from "axios";
import { sprintf } from "sprintf-js";
import * as authService from "./authService";

import {
  INVOICES_API_ENDPOINT,
  INVOICES_FILTER_STATUS_COLLECTION_API_ENDPOINT,
  INVOICES_DOWNLOAD_INVOICE_API_ENDPOINT,
  INVOICES_PREVIEW_INVOICE_API_ENDPOINT,
  INVOICES_APPLY_CREDIT_API_ENDPOINT,
  INVOICES_FORWARD_INVOICE,
  INVOICES_SEND_VIA_POST_INVOICE,
  INVOICES_UPDATE_INVOICE,
  INVOICES_ASSIGN_INVOICE_PAYER,
  INVOICES_MASS_PAY_ENDPOINT,
  INVOICES_DIGITAL_INVOICES_SETTINGS_ENDPOINT
} from "./endpoints";

export function all(limit, page, sort, filters) {
  return axios.get(INVOICES_API_ENDPOINT, {
    params: {
      limit: limit ? limit : 10,
      page: page ? page : 1,
      sort_by: sort ? sort.sort_by : "",
      order_by: sort ? sort.order_by : "",
      ...filters
    }
  });
}

export function fetchInvoiceFilterStatusCollection() {
  return axios.get(INVOICES_FILTER_STATUS_COLLECTION_API_ENDPOINT, {});
}

export function fetchInvoice(id) {
  return axios.get(`${INVOICES_API_ENDPOINT}/${id}`);
}

export function downloadInvoice(id) {
  return axios.get(
    sprintf(INVOICES_DOWNLOAD_INVOICE_API_ENDPOINT, { id: id }),
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf"
      }
    }
  );
}

export function previewInvoice(id) {
  return axios.get(sprintf(INVOICES_PREVIEW_INVOICE_API_ENDPOINT, { id: id }), {
    responseType: "arraybuffer",
    headers: {
      Accept: "application/pdf"
    }
  });
}

export function applyCreditToInvoice(invoice, amount) {
  return axios.post(sprintf(INVOICES_APPLY_CREDIT_API_ENDPOINT, invoice), {
    credit_amount: amount
  });
}

export function forwardInvoice(invoice, email, message = "") {
  return axios.post(sprintf(INVOICES_FORWARD_INVOICE, { id: invoice }), {
    email: email,
    message
  });
}

export function sendViaPost(invoice, address, reason) {
  return axios.post(sprintf(INVOICES_SEND_VIA_POST_INVOICE, { id: invoice }), {
    invoice,
    address: {
      ...address,
      country:
        address.country && address.country.value
          ? address.country.value
          : address.country
    },
    reason
  });
}

export function updateInvoicePaymentMethod(invoice, paymentMethod) {
  return axios.put(sprintf(INVOICES_UPDATE_INVOICE, invoice), {
    payment_method: paymentMethod
  });
}

export function massPay(invoiceIds) {
  return axios.put(INVOICES_MASS_PAY_ENDPOINT, {
    invoices: invoiceIds
  });
}

export function payInvoice(invoice, paymentGateway) {
  return axios.post(
    paymentGateway.processUrl,
    {
      ...paymentGateway
    },
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );
}

export function assignInvoicePayer(invoice, payer) {
  return axios.post(INVOICES_ASSIGN_INVOICE_PAYER, {
    invoice_id: invoice.id,
    payer_id: payer.id,
    ...payer
  });
}

export function fetchInvoicesSettings() {
  return axios.get(INVOICES_DIGITAL_INVOICES_SETTINGS_ENDPOINT);
}

export function updateInvoicesSettings(enabled) {
  return axios.put(INVOICES_DIGITAL_INVOICES_SETTINGS_ENDPOINT, {
    digitally_signed: enabled
  });
}
