import * as appsService from "../../../../services/appsService.js";

export const APPS_FEATURE = "features/Apps";
export const APPS_REQUEST = `${APPS_FEATURE}/APPS_REQUEST`;
export const APPS_RECEIVE = `${APPS_FEATURE}/APPS_RECEIVE`;
export const APPS_APP_REQUEST = `${APPS_FEATURE}/APPS_APP_REQUEST`;
export const APPS_APP_RECEIVE = `${APPS_FEATURE}/APPS_APP_RECEIVE`;
export const APPS_APP_SELECT = `${APPS_FEATURE}/APPS_APP_SELECT`;

export const APPS_APP_CREATE_REQUEST = `${APPS_FEATURE}/APPS_APP_CREATE_REQUEST`;
export const APPS_APP_CREATE_RECEIVE = `${APPS_FEATURE}/APPS_APP_CREATE_RECEIVE`;

export const APPS_SCOPES_REQUEST = `${APPS_FEATURE}/APPS_SCOPES_REQUEST`;
export const APPS_SCOPES_RECEIVE = `${APPS_FEATURE}/APPS_SCOPES_RECEIVE`;

export const APPS_APP_SCOPE_TOGGLE_REQUEST = `${APPS_FEATURE}/APPS_APP_SCOPE_TOGGLE_REQUEST`;
export const APPS_APP_SCOPE_TOGGLE_RECEIVE = `${APPS_FEATURE}/APPS_APP_SCOPE_TOGGLE_RECEIVE`;

export const APPS_APP_UPDATE_REQUEST = `${APPS_FEATURE}/APPS_APP_UPDATE_REQUEST`;
export const APPS_APP_UPDATE_RECEIVE = `${APPS_FEATURE}/APPS_APP_UPDATE_RECEIVE`;

export const APPS_APP_DELETE_REQUEST = `${APPS_FEATURE}/APPS_APP_DELETE_REQUEST`;
export const APPS_APP_DELETE_RECEIVE = `${APPS_FEATURE}/APPS_APP_DELETE_RECEIVE`;

export function fetchAllApps() {
  return dispatch => {
    dispatch(requestApps());
    return appsService
      .fetchAllApps()
      .then(
        result => {
          const { clients } = result.data.data;
          dispatch(receiveApps(clients));
        },
        error => {
          console.log("something happened", error);
        }
      )
      .catch(error => {
        console.log(error);
      });
  };
}

export function fetchAllScopes() {
  return dispatch => {
    dispatch(requestScopes());
    return appsService.fetchAllScopes().then(result => {
      const { scopes } = result.data.data;
      dispatch(receiveScopes(scopes));
    });
  };
}

export function createApp(name) {
  return dispatch => {
    dispatch(createAppRequest());
    return appsService.createApp(name).then(
      result => {
        const { client_id, client_secret, client } = result.data.data;
        dispatch(createAppReceive(client));
        dispatch(selectApp(client));
        return {
          client_id,
          client_secret,
          client
        };
      },
      err => {
        console.log(err);
      }
    );
  };
}

export function updateApp(app, data) {
  return dispatch => {
    dispatch(updateAppRequest());
    return appsService.updateApp(app, data).then(result => {
      const { client } = result.data.data;
      dispatch(updateAppReceive(client));
    });
  };
}

export function deleteApp(app) {
  return dispatch => {
    dispatch(deleteAppRequest());
    return appsService.deleteApp(app).then(result => {
      const { error } = result.data;
      const { messages } = result.data.data;
      dispatch(deleteAppReceive(app));
      return { error, messages };
    });
  };
}

export function toggleAppScope(app, scope) {
  return dispatch => {
    dispatch(toggleAppScopeRequest());
    return appsService.toggleAppScope(app, scope).then(result => {
      const { client } = result.data.data;
      dispatch(toggleAppScopeReceive(client));
    });
  };
}

export function selectApp(app) {
  return dispatch => {
    dispatch({
      type: APPS_APP_SELECT,
      payload: app
    });
  };
}

function requestApps() {
  return {
    type: APPS_REQUEST
  };
}

function receiveApps(apps) {
  return {
    type: APPS_RECEIVE,
    payload: apps
  };
}

function requestApp() {
  return {
    type: APPS_APP_REQUEST
  };
}

function receiveApp() {
  return {
    type: APPS_APP_RECEIVE
  };
}

function createAppRequest() {
  return {
    type: APPS_APP_CREATE_REQUEST
  };
}
function createAppReceive(app) {
  return {
    type: APPS_APP_CREATE_RECEIVE,
    payload: app
  };
}

function requestScopes() {
  return {
    type: APPS_SCOPES_REQUEST
  };
}

function receiveScopes(scopes) {
  return {
    type: APPS_SCOPES_RECEIVE,
    payload: scopes
  };
}

function updateAppRequest() {
  return {
    type: APPS_APP_UPDATE_REQUEST
  };
}

function updateAppReceive(app) {
  return {
    type: APPS_APP_UPDATE_RECEIVE,
    payload: app
  };
}

function deleteAppRequest(app) {
  return {
    type: APPS_APP_DELETE_REQUEST,
    payload: app
  };
}

function deleteAppReceive(app) {
  return {
    type: APPS_APP_DELETE_RECEIVE,
    payload: app
  };
}

function toggleAppScopeRequest() {
  return {
    type: APPS_APP_UPDATE_REQUEST
  };
}

function toggleAppScopeReceive(app) {
  return {
    type: APPS_APP_UPDATE_RECEIVE,
    payload: app
  };
}
