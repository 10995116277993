import React from "react";
import { Form, Field } from "react-final-form";
import { withLocalize } from "react-localize-redux";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import moment from "moment";
import { withRouter } from "react-router-dom";

import Container from "../../../../components/Containers/Container";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../components/Buttons/SecondaryButton";
import TextArea from "../../../../components/TextArea";
import Radiobox from "../../../../components/Radiobox";

import { validateRequired } from "../../../../common/validationRules";

import { ROUTE_HELPDESK } from "../../../../routes/routes";

const StyledWrapper = styled(Container)`
  padding: 30px;
`;

const StyledTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 1.4;
  color: ${theme.neutralBase};
`;

const StyledSubtitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.38;
  color: ${theme.neutralBase};
  margin-bottom: 20px;
`;

const StyledDescription = styled.p`
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 1.5;
  color: ${theme.neutral6};
  margin-bottom: 30px;
`;

const StyledReviewTheTicketButton = styled(SecondaryButton)`
  margin-bottom: 30px;
`;

const TicketDetails = styled.div`
  margin-bottom: 30px;
  display: block;
  margin-left: -30px;
  margin-right: -30px;
`;
const TicketDetail = styled.div`
  display: block;
  width: 100%;
  padding: 10px 30px;
  border-top: 1px solid ${theme.neutral3};
  &:last-child {
    border-bottom: 1px solid ${theme.neutral3};
  }
  & span {
    display: inline-block;
  }

  & span.name {
    font-weight: 600;
  }

  & span.value {
    float: right;
  }
`;

const TicketRatings = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  text-align: center;
`;

const TicketRating = styled.div`
  display: inline-block;
  width: 15px;
  margin-left: 10px;
  margin-right: 10px;

  & .custom-control-label::before {
    top: -10px;
  }
  & .custom-control-label::after {
    top: -10px;
  }
  & .custom-control-input:checked ~ .custom-control-label::after {
    top: -9px;
  }
`;

const StyledWorst = styled.div`
  width: 40px;
  margin-right: 20px;
  position: absolute;
  left: 0;
  top: 0;
`;
const StyledBest = styled.div`
  width: 40px;
  margin-left: 20px;
  position: absolute;
  right: 0;
  top: 0;
`;

class TicketFeedbackForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: 5
    };
  }

  onSelectRating = rating => {
    this.setState({
      rating
    });
  };

  validate = values => {
    return {
      comment: validateRequired(values.comment),
      comment_admin: validateRequired(values.comment_admin)
    };
  };

  onSubmit = values => {
    return this.props.onSubmit({ ...values, rating: this.state.rating });
  };

  onCancel = () => {
    this.props.history.push(ROUTE_HELPDESK);
  };

  render() {
    const duration = moment.duration(
      moment(this.props.ticket.updated_date).diff(
        this.props.ticket.created_date
      )
    );
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    return (
      <StyledWrapper>
        <StyledTitle>
          {this.props.translate("help-desk.ticket.feedback.title")} #
          {this.props.ticket.id}
        </StyledTitle>
        <StyledDescription>
          {this.props.translate("help-desk.ticket.feedback.description")}
        </StyledDescription>
        <TicketDetails>
          <TicketDetail>
            <span className="name">
              {this.props.translate("help-desk.ticket.feedback.subject")}
            </span>
            <span className="value">{this.props.ticket.subject}</span>
          </TicketDetail>
          <TicketDetail>
            <span className="name">
              {this.props.translate("help-desk.ticket.feedback.opened")}
            </span>
            <span className="value">{this.props.ticket.created_date}</span>
          </TicketDetail>
          <TicketDetail>
            <span className="name">
              {this.props.translate("help-desk.ticket.feedback.last-reply")}
            </span>
            <span className="value">{this.props.ticket.updated_date}</span>
          </TicketDetail>
          <TicketDetail>
            <span className="name">
              {this.props.translate("help-desk.ticket.feedback.total-duration")}
            </span>
            <span className="value">
              {days > 0
                ? days > 1
                  ? `${days} ${this.props.translate("general.days")} `
                  : `${days} ${this.props.translate("general.day")} `
                : ""}

              {hours > 0
                ? hours > 1
                  ? `${hours} ${this.props.translate("general.hours")} `
                  : `${hours} ${this.props.translate("general.hour")} `
                : ""}

              {minutes > 0
                ? minutes > 1
                  ? `${minutes} ${this.props.translate("general.minutes")}`
                  : `${minutes} ${this.props.translate("general.minute")}`
                : ""}
            </span>
          </TicketDetail>
        </TicketDetails>
        <StyledSubtitle>
          {this.props.translate("help-desk.ticket.feedback.rate-admin-support")}
        </StyledSubtitle>
        <TicketRatings>
          <StyledWorst>
            {this.props.translate("help-desk.ticket.feedback.worst")}
          </StyledWorst>
          <TicketRating>
            <span className="value">1</span>
            <Radiobox
              name="rating[]"
              value={1}
              onChange={this.onSelectRating}
              checked={this.state.rating === 1}
            />
          </TicketRating>
          <TicketRating>
            <span className="value">2</span>
            <Radiobox
              name="rating[]"
              value={2}
              onChange={this.onSelectRating}
              checked={this.state.rating === 2}
            />
          </TicketRating>
          <TicketRating>
            <span className="value">3</span>
            <Radiobox
              name="rating[]"
              value={3}
              onChange={this.onSelectRating}
              checked={this.state.rating === 3}
            />
          </TicketRating>
          <TicketRating>
            <span className="value">4</span>
            <Radiobox
              name="rating[]"
              value={4}
              onChange={this.onSelectRating}
              checked={this.state.rating === 4}
            />
          </TicketRating>
          <TicketRating>
            <span className="value">5</span>
            <Radiobox
              name="rating[]"
              value={5}
              onChange={this.onSelectRating}
              checked={this.state.rating === 5}
            />
          </TicketRating>
          <TicketRating>
            <span className="value">6</span>
            <Radiobox
              name="rating[]"
              value={6}
              onChange={this.onSelectRating}
              checked={this.state.rating === 6}
            />
          </TicketRating>
          <TicketRating>
            <span className="value">7</span>
            <Radiobox
              name="rating[]"
              value={7}
              onChange={this.onSelectRating}
              checked={this.state.rating === 7}
            />
          </TicketRating>
          <TicketRating>
            <span className="value">8</span>
            <Radiobox
              name="rating[]"
              value={8}
              onChange={this.onSelectRating}
              checked={this.state.rating === 8}
            />
          </TicketRating>
          <TicketRating>
            <span className="value">9</span>
            <Radiobox
              name="rating[]"
              value={9}
              onChange={this.onSelectRating}
              checked={this.state.rating === 9}
            />
          </TicketRating>
          <TicketRating>
            <span className="value">10</span>
            <Radiobox
              name="rating[]"
              value={10}
              onChange={this.onSelectRating}
              checked={this.state.rating === 10}
            />
          </TicketRating>

          <StyledBest>
            {this.props.translate("help-desk.ticket.feedback.best")}
          </StyledBest>
        </TicketRatings>
        <Form
          onSubmit={this.onSubmit}
          validate={this.validate}
          initialValues={{
            rating: 0,
            comment: "",
            comment_admin: ""
          }}
          render={({ handleSubmit, invalid, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  label={this.props.translate(
                    "help-desk.ticket.feedback.how-did-admin-handle-the-ticket"
                  )}
                  component={TextArea}
                  name="comment_admin"
                />
                <Field
                  label={this.props.translate(
                    "help-desk.ticket.feedback.how-can-we-improve"
                  )}
                  component={TextArea}
                  name="comment"
                />
                <div className="row mt-3">
                  <div className="col-12 col-sm-12 col-md-12">
                    <SecondaryButton onClick={() => this.onCancel()}>
                      {this.props.translate("general.cancel")}
                    </SecondaryButton>
                    <PrimaryButton
                      type="submit"
                      disabled={invalid || submitting}
                      submitting={submitting}
                    >
                      {this.props.translate(
                        "help-desk.ticket.feedback.submit-feedback"
                      )}
                    </PrimaryButton>
                  </div>
                </div>
              </form>
            );
          }}
        />
      </StyledWrapper>
    );
  }
}

export default withRouter(withLocalize(TicketFeedbackForm));
