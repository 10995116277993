import React from "react";
import { Translate } from "react-localize-redux";

import Panel from "../Panel";
import Togglebox from "../Togglebox";
import CustomTooltip from "../CustomTooltip";

const DigitalInvoices = ({
  title,
  description,
  isLoading,
  settings,
  onToggleDigitalInvoices
}) => (
  <Translate>
    {({ translate }) => (
      <Panel title={title} description={description}>
        <div className="row">
          <div className="col-6 col-sm-6 col-md-6">
            <span className="title">{title}</span>
          </div>
          <div className="col-6 col-sm-6 col-md-6 text-right">
            <CustomTooltip
              content={translate(
                "my-account.general-info.digital-invoices.covid-info"
              )}
            >
              <Togglebox
                className="ml-auto"
                disabled={true}
                submitting={isLoading}
                toggled={true}
                onToggle={onToggleDigitalInvoices}
              />
            </CustomTooltip>
          </div>
        </div>
      </Panel>
    )}
  </Translate>
);

export default DigitalInvoices;
