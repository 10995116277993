import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

import Container from "../../../../../components/Containers/Container";
import ButtonGroup from "../../../../../components/ButtonGroup";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import Checkbox from "../../../../../components/Checkbox";
import Icon from "../../../../../components/Icon";
import DropdownItem from "../../../../../components/DropdownItem";

import Table from "../../../../../components/Table";
import TableHeader from "../../../../../components/Table/TableHeader";
import TableHeading from "../../../../../components/Table/TableHeading";
import TableBody from "../../../../../components/Table/TableBody";
import TableRow from "../../../../../components/Table/TableRow";
import TableCell from "../../../../../components/Table/TableCell";

import Pagination from "../../../../../components/Pagination";
import PaginationItem from "../../../../../components/Pagination/PaginationItem";
import PaginationNext from "../../../../../components/Pagination/PaginationNext";
import PaginationPrevious from "../../../../../components/Pagination/PaginationPrevious";

import DropdownButton from "../../../../../components/DropdownButton";
import DropdownItemDescription from "../../../../../components/DropdownItemDescription";

import Badge from "../../../../../components/Badges/Badge";

import getBadgeForStatus from "../../../../../utils/getBadgeForStatus";

const StyledContactsList = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;

  & .hosting-list-header {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
  }

  & .hosting-list-header button.purchase-hosting {
    margin-right: 0;
  }
`;

const StyledContactsListContainer = styled.div`
  & a.hosting-type {
    font-size: 16px;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }
`;

const StyledTableTitle = styled.h3`
  font-size: 20px;
  font-weight: 600;
  line-height: 1.4;
  color: ${theme.neutralBase};
`;

const StyledTableDescription = styled.p`
  font-size: 16px;
  line-height: 1.5;
  color: ${theme.neutral6};
`;

class ContactsList extends React.Component {
  render() {
    return (
      <Translate>
        {({ translate }) => (
          <Container>
            <StyledContactsList>
              <div className="hosting-list-header">
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-6">
                    <StyledTableTitle>
                      {translate("domain.contacts.table.title")}
                    </StyledTableTitle>
                    <StyledTableDescription>
                      {translate("domain.contacts.table.description")}
                    </StyledTableDescription>
                  </div>
                  <div className="col-12 col-sm-6 col-md-6 text-right">
                    <ButtonGroup className="btn-group">
                      <SecondaryButton size="m">
                        {translate("domain.contacts.table.assign-new-contact")}
                      </SecondaryButton>
                    </ButtonGroup>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledContactsListContainer>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHeading>
                            <Checkbox />
                          </TableHeading>
                          <TableHeading>
                            {translate("domain.contacts.table.contact")}
                          </TableHeading>
                          <TableHeading>
                            {translate("domain.contacts.table.status")}
                          </TableHeading>
                          <TableHeading>
                            {translate("domain.contacts.table.assigned")}
                          </TableHeading>
                          <TableHeading>
                            {translate("domain.contacts.table.roles")}
                          </TableHeading>
                          <TableHeading />
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {this.props.contacts.length > 0 &&
                          this.props.contacts.map(contact => {
                            const BadgeComponent = getBadgeForStatus(
                              contact.status.state
                            );

                            return (
                              <TableRow
                                key={`${`customer-contact-${contact.id}`}`}
                              >
                                <TableCell>
                                  <Checkbox />
                                </TableCell>
                                <TableCell>
                                  <Link className="hosting-type" to="/">
                                    {contact.firstName}
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <BadgeComponent>
                                    {contact.status}
                                  </BadgeComponent>
                                </TableCell>
                                <TableCell>{contact.assigned}</TableCell>
                                <TableCell>
                                  {contact.roles.map(role => (
                                    <Badge key={`role-${Math.random()}`}>
                                      {role.name}
                                    </Badge>
                                  ))}
                                </TableCell>
                                <TableCell className="text-right">
                                  <DropdownButton
                                    size="s"
                                    text={() => <Icon icon="dots_horizontal" />}
                                    alignMenu="right"
                                  >
                                    <DropdownItem>
                                      Action
                                      <DropdownItemDescription>
                                        Action
                                      </DropdownItemDescription>
                                    </DropdownItem>
                                  </DropdownButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </StyledContactsListContainer>
                </div>
              </div>
              <Pagination>
                <PaginationPrevious disabled />
                <PaginationItem active>1</PaginationItem>
                <PaginationItem>2</PaginationItem>
                <PaginationNext />
              </Pagination>
            </StyledContactsList>
          </Container>
        )}
      </Translate>
    );
  }
}

export default ContactsList;
