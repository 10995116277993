import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import moment from "moment";
import parse from "html-react-parser";

import { BrowserView, isMobileOnly, isTablet } from "react-device-detect";
import isLaptop from "../../utils/isLaptopScreen";
import withSizes from "react-sizes";

import PrimaryButton from "../Buttons/PrimaryButton";
import SecondaryButton from "../Buttons/SecondaryButton";

import Badge from "../Badges/Badge";
import BadgeSucces from "../Badges/BadgeSuccess";

import Avatar from "../Avatar";
import UserWithEmail from "../UserWithEmail";

import Icon from "../Icon";
import getBadgeForStatus from "../../utils/getBadgeForStatus";

import mkhostLogo from "../../assets/images/logomark-blue.svg";

import employeesPictures from "../../features/HelpDesk/employeesPictures";
import { ROUTE_WEBSITE } from "../../routes/routes";

const StyledTicket = styled.div`
  padding: 25px 25px 0 25px;
  border-bottom: 1px solid ${theme.neutral3};

  &:last-child {
    border-bottom: 0;
  }

  & .ticket-details-wrapper {
    margin-bottom: 20px;
    vertical-align: top;
  }

  & .heading {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    color: ${theme.neutral5};
  }

  & .ticket-link {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: ${theme.blueBase};
    margin-right: 10px;
  }

  & .ticket-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: ${theme.neutralBase};
  }

  & .user-avatar {
  }

  & div.reply-message {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${theme.neutral6};
  }

  & div.reply-message pre {
    white-space: pre-line;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${theme.neutral6};
  }

  & div.reply-message.admin pre {
    white-space: pre-line;
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: ${theme.neutral6};
    background-color: ${theme.green1};
    border-radius: 4px;
    padding: 15px;
  }

  & .ticketButtons button {
    @media (max-width: 375px) {
      padding: 0px 5px;
      font-size: 13px;
    }

    @media (max-width: 360px) {
      font-size: 12px;
    }
  }

  & .ticketButtons button:first-child {
    @media (max-width: 375px) {
      margin-right: 10px;
    }
  }
`;

const Ticket = ({
  ticket,
  isLaptop,
  url,
  onOpenTicket,
  onCloseTicket,
  closingTicketId
}) => (
  <Translate>
    {({ translate }) => {
      const BadgeComponent = getBadgeForStatus(ticket.status.toLowerCase());

      const lastReply =
        ticket.replies.length > 0 && ticket.replies[ticket.replies.length - 1];

      let lastReplyEmployeeImage = mkhostLogo;
      if (lastReply.is_from_admin && employeesPictures[lastReply.name]) {
        lastReplyEmployeeImage = `${ROUTE_WEBSITE}${
          employeesPictures[lastReply.name]
        }`;
      }

      return (
        <StyledTicket>
          {(isMobileOnly || isTablet) && (
            <React.Fragment>
              <div className="row ticket-details-wrapper">
                <div className="col-12 col-sm-12 col-md-12">
                  <Link className="ticket-link" to={url || ""}>
                    #{ticket.id}
                  </Link>{" "}
                  <span className="ticket-title">{ticket.subject}</span>
                </div>
              </div>
              <div className="row ticket-details-wrapper">
                <div className="col-12 col-sm-12 col-md-12">
                  <BadgeSucces>open</BadgeSucces>

                  <BadgeComponent>
                    {translate("help-desk.ticket.statuses." + ticket.status)}
                  </BadgeComponent>

                  <Badge>{ticket.department}</Badge>
                </div>
                <div className="col-12 col-sm-12 col-md-12">
                  <Badge>
                    {translate("help-desk.ticket.updated")}:{" "}
                    {moment(ticket.updated_date).fromNow()}
                  </Badge>
                </div>
              </div>
              {!ticket.closed && (
                <div className="row ticket-details-wrapper">
                  <div className="col-2 col-sm-2 col-md-2">
                    <Avatar
                      image={lastReply.is_from_admin && lastReplyEmployeeImage}
                    />
                  </div>
                  <div className="col-10 col-sm-10 col-md-10">
                    <UserWithEmail
                      user={ticket.replies.length > 0 && lastReply.name}
                      email={ticket.replies.length > 0 && lastReply.email}
                    />
                  </div>
                </div>
              )}
              {!ticket.closed && (
                <div className="row ticket-details-wrapper">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div
                      className={`reply-message ${lastReply.is_from_admin &&
                        "admin"}`}
                    >
                      <pre>
                        {ticket.replies.length > 0 && parse(lastReply.message)}
                      </pre>
                    </div>
                  </div>
                </div>
              )}
              {!ticket.closed && (
                <div className="row ticket-details-wrapper">
                  <div className="col-12 col-sm-12 col-md-12 ticketButtons">
                    <SecondaryButton
                      size="s"
                      disabled={closingTicketId === ticket.id}
                      submitting={closingTicketId === ticket.id}
                      onClick={() => onCloseTicket(ticket)}
                    >
                      {translate("help-desk.ticket.close-ticket")}
                    </SecondaryButton>
                    <PrimaryButton
                      size="s"
                      onClick={() => onOpenTicket(ticket, url)}
                    >
                      <Icon icon="reply" />{" "}
                      {translate("help-desk.ticket.reply-to-ticket")}
                    </PrimaryButton>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}

          <BrowserView>
            {isLaptop && (
              <React.Fragment>
                <div className="row ticket-details-wrapper">
                  <div className="col-12 col-sm-12 col-md-12">
                    <Link className="ticket-link" to={url || ""}>
                      #{ticket.id}
                    </Link>{" "}
                    <span className="ticket-title">{ticket.subject}</span>
                  </div>
                </div>
                <div className="row ticket-details-wrapper">
                  <div className="col-12 col-sm-12 col-md-12">
                    <BadgeComponent>
                      {translate("help-desk.ticket.statuses." + ticket.status)}
                    </BadgeComponent>
                    <Badge>{ticket.department}</Badge>
                  </div>
                </div>
                {!ticket.closed && (
                  <div className="row ticket-details-wrapper">
                    <div className="col-2 col-sm-2 col-md-2">
                      <Avatar
                        image={
                          lastReply.is_from_admin && lastReplyEmployeeImage
                        }
                      />
                    </div>
                    <div className="col-10 col-sm-10 col-md-10">
                      <UserWithEmail
                        user={ticket.replies.length > 0 && lastReply.name}
                        email={ticket.replies.length > 0 && lastReply.email}
                      />
                    </div>
                  </div>
                )}
                {!ticket.closed && (
                  <div className="row ticket-details-wrapper">
                    <div className="col-12 col-sm-12 col-md-12">
                      <div
                        className={`reply-message ${lastReply.is_from_admin &&
                          "admin"}`}
                      >
                        <pre>
                          {ticket.replies.length > 0 &&
                            parse(lastReply.message)}
                        </pre>
                      </div>
                    </div>
                  </div>
                )}
                {!ticket.closed && (
                  <div className="row ticket-details-wrapper">
                    <div className="col-12 col-sm-12 col-md-12">
                      <SecondaryButton
                        size="s"
                        disabled={closingTicketId === ticket.id}
                        submitting={closingTicketId === ticket.id}
                        onClick={() => onCloseTicket(ticket)}
                      >
                        {translate("help-desk.ticket.close-ticket")}
                      </SecondaryButton>
                      <PrimaryButton
                        size="s"
                        onClick={() => onOpenTicket(ticket, url)}
                      >
                        <Icon icon="reply" />{" "}
                        {translate("help-desk.ticket.reply-to-ticket")}
                      </PrimaryButton>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            {!isLaptop && (
              <React.Fragment>
                <div className="row ticket-details-wrapper">
                  <div className="col-2 col-sm-2 col-md-2">
                    <span className="heading">
                      {translate("help-desk.ticket.title")}
                    </span>
                  </div>
                  <div className="col-10 col-sm-10 col-md-10">
                    <Link className="ticket-link" to={url || ""}>
                      #{ticket.id}
                    </Link>{" "}
                    <span className="ticket-title">{ticket.subject}</span>
                  </div>
                </div>
                <div className="row ticket-details-wrapper">
                  <div className="col-5 offset-2 col-sm-5 offset-sm-2 col-md-5 offset-md-2">
                    <BadgeComponent>
                      {translate("help-desk.ticket.statuses." + ticket.status)}
                    </BadgeComponent>
                    <Badge>{ticket.department}</Badge>
                  </div>
                  <div className="col-5 col-sm-5 col-md-5 text-right">
                    <Badge>
                      {translate("help-desk.ticket.updated")}:{" "}
                      {moment(ticket.updated_date).fromNow()}
                    </Badge>
                  </div>
                </div>
                {!ticket.closed && (
                  <div className="row ticket-details-wrapper">
                    <div className="col-2 col-sm-2 col-md-2">
                      <Avatar
                        image={
                          lastReply.is_from_admin && lastReplyEmployeeImage
                        }
                      />
                    </div>
                    <div className="col-10 col-sm-10 col-md-10">
                      <UserWithEmail
                        user={ticket.replies.length > 0 && lastReply.name}
                        email={ticket.replies.length > 0 && lastReply.email}
                      />
                    </div>
                  </div>
                )}
                {!ticket.closed && (
                  <div className="row ticket-details-wrapper">
                    <div className="col-2 col-sm-2 col-md-2">
                      <span className="heading">
                        {translate("help-desk.ticket.reply")}
                      </span>
                    </div>
                    <div className="col-10 col-sm-10 col-md-10">
                      <div
                        className={`reply-message ${lastReply.is_from_admin &&
                          "admin"}`}
                      >
                        <pre>
                          {ticket.replies.length > 0 &&
                            parse(lastReply.message)}
                        </pre>
                      </div>
                    </div>
                  </div>
                )}
                {!ticket.closed && (
                  <div className="row ticket-details-wrapper">
                    <div className="col-10 offset-2 col-sm-10 offset-sm-2 col-md-10 offset-md-2">
                      <SecondaryButton
                        size="s"
                        disabled={closingTicketId === ticket.id}
                        submitting={closingTicketId === ticket.id}
                        onClick={() => onCloseTicket(ticket)}
                      >
                        {translate("help-desk.ticket.close-ticket")}
                      </SecondaryButton>
                      <PrimaryButton
                        size="s"
                        onClick={() => onOpenTicket(ticket, url)}
                      >
                        <Icon icon="reply" />{" "}
                        {translate("help-desk.ticket.reply-to-ticket")}
                      </PrimaryButton>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </BrowserView>
        </StyledTicket>
      );
    }}
  </Translate>
);

export default withSizes(isLaptop)(Ticket);
