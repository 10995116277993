import React from "react";
import { Translate } from "react-localize-redux";
import { Form, Field } from "react-final-form";

import Panel from "../Panel";
import FormGroup from "../FormGroup";
import InputField from "../InputField";
import PrimaryButton from "../Buttons/PrimaryButton";

const AccountPassword = ({
  validateChangePasswordSubmit,
  onChangePassword,
  isChangingPassword
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <Panel
          title={translate("my-account.security.account-password")}
          description={translate("my-account.security.change-password")}
        >
          <Form
            onSubmit={onChangePassword}
            validate={validateChangePasswordSubmit}
            render={({ handleSubmit, submitting, invalid, form }) => (
              <form
                id="update-customer-password-form"
                onSubmit={event => {
                  handleSubmit(event);
                  form.reset();
                }}
              >
                <Field
                  name="password"
                  label={translate("my-account.security.current-password")}
                  placeholder={translate(
                    "my-account.security.current-password-placeholder"
                  )}
                  type="password"
                  component={InputField}
                />

                <Field
                  name="newPassword"
                  label={translate("my-account.security.new-password")}
                  placeholder={translate(
                    "my-account.security.new-password-placeholder"
                  )}
                  type="password"
                  component={InputField}
                />

                <Field
                  name="confirmNewPassword"
                  label={translate("my-account.security.confirm-new-password")}
                  placeholder={translate(
                    "my-account.security.confirm-new-password-placeholder"
                  )}
                  type="password"
                  component={InputField}
                />

                <FormGroup className="text-right">
                  <PrimaryButton
                    disabled={submitting || invalid || isChangingPassword}
                    submitting={submitting || isChangingPassword}
                    type="submit"
                    key="update-customer-password"
                  >
                    {translate("my-account.security.change-password-action")}
                  </PrimaryButton>
                </FormGroup>
              </form>
            )}
          />
        </Panel>
      )}
    </Translate>
  );
};

export default AccountPassword;
