import React from "react";
import { Field, Form, FormSpy } from "react-final-form";
import { Translate } from "react-localize-redux";

import InputField from "../../../../../components/InputField";
import BlockRadiobox from "../../../../../components/BlockRadiobox";
import SelectBox from "../../../../../components/SelectBox";

import graphicIndividualDefault from "../../../../../assets/images/graphic_individual.png";
import graphicCompanyDefault from "../../../../../assets/images/graphic_company.png";

import { CONTACT_DETAILS_TYPE_COMPANY } from "./ContactDetailsTypes";
import { CONTACT_TYPE_ADMINISTRATIVE } from "../DomainContactTypes";

const ContactDetailsForm = ({
  onSubmit,
  onCancel,
  validate,
  contact,
  onSubmitting,
  countries,
  domain
}) => (
  <Translate>
    {({ translate }) => (
      <Form
        onSubmit={onSubmit}
        validate={validate}
        initialValues={contact}
        render={({ handleSubmit, values }) => {
          return (
            <form id="domain-contact-details-form" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  {!domain.isMkDomain &&
                    contact.type !== CONTACT_TYPE_ADMINISTRATIVE && (
                      <div className="row">
                        <div className="col-6 col-sm-6 col-md-6">
                          <Field
                            component={BlockRadiobox}
                            activeIcon={graphicIndividualDefault}
                            defaultIcon={graphicIndividualDefault}
                            name="contact_type"
                            value="individual"
                            type="radio"
                          >
                            {translate("domain.domain-contacts.individual")}
                          </Field>
                        </div>

                        <div className="col-6 col-sm-6 col-md-6">
                          <Field
                            component={BlockRadiobox}
                            activeIcon={graphicCompanyDefault}
                            defaultIcon={graphicCompanyDefault}
                            name="contact_type"
                            value="company"
                            type="radio"
                          >
                            {translate("domain.domain-contacts.company")}
                          </Field>
                        </div>
                      </div>
                    )}
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6">
                      <Field
                        component={InputField}
                        label={translate("domain.domain-contacts.first-name")}
                        name="first_name"
                      />
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <Field
                        component={InputField}
                        label={translate("domain.domain-contacts.last-name")}
                        name="last_name"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-6">
                      {" "}
                      <Field
                        component={InputField}
                        label={translate("domain.domain-contacts.email")}
                        name="email"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-6 col-sm-6 col-md-6">
                      {" "}
                      <Field
                        component={InputField}
                        label={translate("domain.domain-contacts.phone-number")}
                        name="phone"
                      />
                    </div>
                  </div>
                  {values.contact_type === CONTACT_DETAILS_TYPE_COMPANY &&
                    !domain.isMkDomain &&
                    contact.type !== CONTACT_TYPE_ADMINISTRATIVE && (
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                          <Field
                            component={InputField}
                            label={translate("pro-invoices.view.company")}
                            name="company"
                          />
                        </div>

                        <div className="col-12 col-sm-12 col-md-6">
                          <Field
                            component={InputField}
                            label={translate("pro-invoices.view.tax-number")}
                            name="tax_number"
                          />
                        </div>
                      </div>
                    )}
                  {!domain.isMkDomain &&
                    contact.type !== CONTACT_TYPE_ADMINISTRATIVE && (
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          {" "}
                          <Field
                            component={InputField}
                            label={translate("domain.domain-contacts.address")}
                            name="address1"
                          />
                        </div>
                      </div>
                    )}
                  {!domain.isMkDomain &&
                    contact.type !== CONTACT_TYPE_ADMINISTRATIVE && (
                      <div className="row">
                        <div className="col-6 col-sm-6 col-md-6">
                          {" "}
                          <Field
                            component={InputField}
                            label={translate("domain.domain-contacts.city")}
                            name="city"
                          />
                        </div>
                        <div className="col-6 col-sm-6 col-md-6">
                          <Field
                            component={InputField}
                            label={translate("domain.domain-contacts.zip-code")}
                            name="zip"
                          />
                        </div>
                      </div>
                    )}
                  <div className="row">
                    {!domain.isMkDomain &&
                      contact.type !== CONTACT_TYPE_ADMINISTRATIVE && (
                        <div className="col-6 col-sm-6 col-md-6">
                          <Field
                            component={InputField}
                            label={translate(
                              "domain.domain-contacts.state-region"
                            )}
                            name="state_region"
                          />
                        </div>
                      )}
                    <div className="col-6 col-sm-6 col-md-6">
                      <Field
                        component={SelectBox}
                        options={countries}
                        isSearchable={true}
                        label={translate("domain.domain-contacts.country")}
                        name="country"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <FormSpy
                subscription={{ submitting: true, invalid: true }}
                onChange={formstate => onSubmitting({ ...formstate })}
              />
            </form>
          );
        }}
      />
    )}
  </Translate>
);

export default ContactDetailsForm;
