import React from "react";
import styled from "styled-components";

import InputField from "../../../components/InputField";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import SecondaryWarningButton from "../../../components/Buttons/SecondaryWarningButton";



import { isAuthenticated } from "../../Authentication/reducers/authReducer";
import { connect } from "react-redux";
import { Translate, withLocalize } from "react-localize-redux";

const StyledCartCoupon = styled.div`
  padding: 30px;
  padding-bottom: 0;
`;

class CartCoupon extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      promotionCode: props.promotionCode
    };
  }

  changePromotionCode = value => {
    this.setState({
      promotionCode: value
    });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
            <StyledCartCoupon>
              {this.props.isAuthenticated ? (
                <div className="row no-gutters">
                  <div className="col-12 col-sm-12 col-md-12">
                    <InputField
                      label={translate("cart.discount-coupon.label")}
                      disabled={
                        this.props.promotionCode !== "" ||
                        this.props.isApplyingPromotion
                      }
                      name="coupon"
                      type="text"
                      value={
                        this.props.promotionCode !== ""
                          ? this.props.promotionCode
                          : this.state.promotionCodeInput
                      }
                      placeholder={translate("cart.discount-coupon.placeholder")}
                      onChange={e => this.changePromotionCode(e.target.value)}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 text-right">
                    {this.props.promotionCode !== "" &&
                    !this.props.isApplyingPromotion ? (
                      <SecondaryWarningButton
                        disabled={this.props.isCleaningPromotion}
                        submitting={this.props.isCleaningPromotion}
                        size="m"
                        onClick={e => this.props.clearPromotionCode()}
                        className="btn"
                      >
                        {translate("cart.discount-coupon.remove")}
                      </SecondaryWarningButton>
                    ) : (
                      <SecondaryButton
                        disabled={
                          this.props.promotionCode !== "" ||
                          this.props.isApplyingPromotion
                        }
                        submitting={this.props.isApplyingPromotion}
                        size="m"
                        onClick={e =>
                          this.props.applyPromotionCode(this.state.promotionCode)
                        }
                      >
                        {translate("cart.discount-coupon.apply")}
                      </SecondaryButton>
                    )}
                  </div>
                </div>
              ) : (
                <div className="row no-gutters">
                  <div className="col-12 col-sm-12 col-md-12">
                    {translate("cart.discount-coupon.promo-info")}
                  </div>
                </div>
              )}
            </StyledCartCoupon>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state)
  };
};

export default connect(
  mapStateToProps
)((withLocalize(CartCoupon)));
