import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { MobileOnlyView, isTablet, isBrowser } from "react-device-detect";

import Checkbox from "../Checkbox";
import Icon from "../Icon";
import CustomTooltip from "../CustomTooltip";

const StyledSelectableProductAddon = styled.div`
  border: 1px solid ${theme.neutral3};
  border-radius: 5px;
  padding: 15px;
  padding-left: 20px;
  margin-bottom: 10px;
  cursor: pointer;

  & .custom-checkbox {
    //top: 3px;
    margin-right: 15px;
  }
  & .icon {
    //top: 2px;
  }
`;

const StyledDescription = styled.div`
  display: inline-block;
  position: relative;
  //top: 3px;
  font-size: 16px;
  line-height: 1.38;
  color: ${theme.neutralBase};
`;

const StyledCheckBox = styled.div`
  display: inline-block;
  //margin-right: 20px;
`;

const SelectableProductAddon = ({
  addon,
  children,
  activated,
  onSelect,
  disabled
}) => (
  <StyledSelectableProductAddon onClick={onSelect}>
    <MobileOnlyView renderWithFragment>
      <StyledCheckBox>
        <Checkbox
          input={{ value: activated, onChange: () => {}, disabled: disabled }}
        />
      </StyledCheckBox>
      <StyledDescription>{children}</StyledDescription>
    </MobileOnlyView>
    {(isTablet || isBrowser) && (
      <div className="row">
        <div className="col-10 col-sm-10 col-md-10">
          <StyledCheckBox>
            <Checkbox
              input={{
                value: activated,
                onChange: () => {},
                disabled: disabled
              }}
            />
          </StyledCheckBox>
          <StyledDescription>{children}</StyledDescription>
        </div>
        <div className="col-2 col-sm-2 col-md-2 text-right">
          <CustomTooltip content={addon.description}>
            <Icon size="l" icon="info" />
          </CustomTooltip>
        </div>
      </div>
    )}
  </StyledSelectableProductAddon>
);

SelectableProductAddon.defaultProps = {
  description: null
};

export default SelectableProductAddon;
