import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";

import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";
import CompactCartDomainProductAddon from "./CompactCartDomainProductAddon";

import { CART_DOMAIN_TYPE_REGISTER } from "../../../Cart/CartDomainTypes/CartDomainTypes";
import { Translate } from "react-localize-redux";

const StyledCompactCartDomainProduct = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  padding: 10px 0;
`;

const StyledProductType = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${theme.neutralBase};
  margin-right: 20px;
`;
const StyledProductTitle = styled.span`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  word-break: break-word;
`;

const CompactCartDomainProduct = ({ product }) => (
  <Translate>
    {({ translate }) => (
      <StyledCompactCartDomainProduct>
        <div className="row">
          <div className="col-7 col-sm-8 col-md-8">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-4">
                <StyledProductType>
                  {product.config.type === CART_DOMAIN_TYPE_REGISTER
                    ? translate("cart.domain-registration")
                    : translate("cart.domain-transfer")}
                </StyledProductType>
              </div>
              <div className="col-12 col-sm-12 col-md-8">
                <StyledProductTitle>
                  {product.domain} - {product.config.period}
                  {product.config.period > 1
                    ? ` ${translate("cart.years")}`
                    : ` ${translate("cart.year")}`}
                </StyledProductTitle>
              </div>
            </div>
          </div>
          <div className="col-4 col-sm-4 col-md-4 text-right">
            <DiscountedAmountBadge
              amount={{
                currency: product.item.pricing[
                  product.config.type
                ].billing_cycle.derivations.find(
                  derivation =>
                    derivation.numeric_code === product.config.period
                ).price.currency.code,
                amount: product.total,
                discountedAmount:
                  product.total < product.price ? product.price : null
              }}
            />
          </div>
        </div>
        {product.item.services &&
          product.item.services.length > 0 &&
          product.item.services
            .filter(service => service.activated)
            .map(service => (
              <CompactCartDomainProductAddon
                key={`ccdps-${service.id}`}
                service={service}
              />
            ))}
      </StyledCompactCartDomainProduct>
    )}
  </Translate>
);

CompactCartDomainProduct.defaultProps = {
  domain: "Domain",
  price: {
    currency: "EUR",
    amount: 30.5
  },
  addons: null
};

export default CompactCartDomainProduct;
