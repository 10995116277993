import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Notification from "../Notification";

const StyledNotificationDanger = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  &:last-child {
    border-bottom: 0;
  }

  &:hover {
    background-color: ${theme.blue1};
  }

  & .icon {
    color: ${theme.redBase};
  }
`;

const NotificationDanger = ({ children, ...rest }) => (
  <StyledNotificationDanger className="notice-success">
    <Notification icon="warning" {...rest}>
      {children}
    </Notification>
  </StyledNotificationDanger>
);

NotificationDanger.defaultProps = {
  className: "notification-warning"
};

export default NotificationDanger;
