import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Button from "../Button";

const PrimarySuccessButton = styled(Button)`
  background-color: ${theme.greenBase};
  color: ${theme.white};
  border: solid 1px ${theme.greenBase};

  &:hover {
    background-color: ${theme.green4};
    border: 1px solid ${theme.green4};
  }

  &:focus {
    box-shadow: 0 0 0 2px ${theme.green3};
  }

  &:active {
    border: 1px solid ${theme.green5};
    background-color: ${theme.green5};
    color: ${theme.white};
  }

  &:disabled {
    background-color: ${theme.neutral2};
    color: ${theme.white};
    border: 1px solid ${theme.neutral2};
  }
`;

PrimarySuccessButton.defaultProps = {
  className: "btn primary-success-button"
};

export default PrimarySuccessButton;
