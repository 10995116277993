import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

const StyledSidebarHeading = styled.h6`
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600px;
  line-height: 2.29;
  letter-spacing: 0.2px;
  color: ${theme.white.fade(0.4)};
  padding-left: 30px;
  padding-bottom: 10px;
`;

StyledSidebarHeading.defaultProps = {
  className:
    "sidebar-heading d-flex justify-content-between align-items-center",
  title: ""
};

const SidebarHeading = props => (
  <StyledSidebarHeading>{props.title}</StyledSidebarHeading>
);

export default SidebarHeading;
