import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import withSizes from "react-sizes";
import isLaptop from "../../utils/isLaptopScreen";
import { isMobile, isBrowser } from "react-device-detect";
import { Translate } from "react-localize-redux";
import { Form, Field } from "react-final-form";

import Container from "../../components/Containers/Container";
import Label from "../../components/Label";
import InputField from "../../components/InputField";
import PrimaryButton from "../../components/Buttons/PrimaryButton";

import { validateDomain } from "../../common/validationRules";

const StyledDomainSearch = styled.div`
  padding: 30px;
  & label {
    font-size: 16px;
    line-height: 22px;
    color: ${theme.neutralBase};
    display: block;
  }

  & input[type="text"] {
    min-width: auto;
  }

  & .domain-search-widget-input {
    display: inline-block;
    width: ${props => {
      if (!props.isMobile && !props.isLaptop) {
        return "70%";
      }

      if (props.isLaptop) {
        return "70%";
      }

      if (props.isMobile) {
        return "100%";
      }
    }};
  }
  
  & .domain-search-widget-input input{
    text-transform: lowercase;
  }

  & button {
    display: inline-block;
    float: right;
    margin-right: 0;
  }
`;

class DomainSearchWidget extends React.Component {
  onKeyPress = (e, values) => {
    // enter button
    if (e.keyCode === 13) {
      this.props.onDomainSearched(values);
    }
  };

  validate = values => {
    return {
      domainSearched: validateDomain(values.domainSearched)
    };
  };

  render() {
    let colSizeInput = "col-12 col-sm-12 col-md-12";

    if (!isMobile && isBrowser && this.props.isLaptop) {
      colSizeInput = "col-12 col-sm-12 col-md-12";
    } else if (!isMobile && isBrowser && !this.props.isLaptop) {
      colSizeInput = "col-12 col-sm-12 col-md-12";
    }
    return (
      <Translate>
        {({ translate }) => (
          <Container className="widget-container">
            <StyledDomainSearch
              className="widget domain-search-widget"
              isMobile={isMobile}
              isLaptop={this.props.isLaptop}
            >
              <Form
                onSubmit={this.props.onDomainSearched}
                validate={this.validate}
                render={({ handleSubmit, submitting, values }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="row align-items-center">
                      <div className={colSizeInput}>
                        <Label>
                          {translate("home.widgets.domain-search.label")}
                        </Label>
                        <Field
                          component={InputField}
                          className="domain-search-widget-input"
                          onKeyDown={e => this.onKeyPress(e, values)}
                          placeholder={translate(
                            "home.widgets.domain-search.placeholder"
                          )}
                          name="domainSearched"
                          format={value => {
                            const val = value
                              ? value
                                  .replace(/[^0-9a-z\u0400-\u04FF\-.+]+/gi, "")
                                  .replace(/\s\D+/g, "")
                              : "";
                            return val.toLowerCase();
                          }}
                        />

                        <PrimaryButton size="m">
                          {translate("home.widgets.domain-search.button")}
                        </PrimaryButton>
                      </div>
                    </div>
                  </form>
                )}
              />
            </StyledDomainSearch>
          </Container>
        )}
      </Translate>
    );
  }
}

export default withSizes(isLaptop)(DomainSearchWidget);
