import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { withLocalize } from "react-localize-redux";

import SelectBox from "../../../components/SelectBox";
import NoticeInfo from "../../../components/Notices/NoticeInfo";

const StyledRenewDomainWithPeriod = styled.div`
  padding-top: 30px;
  border-top: 1px solid ${theme.neutral3};
`;

const RenewDomainWithPeriod = ({
  domain,
  domainPrices,
  parsedDomain,
  translate,
  customerCurrencyCode,
  onChange
}) => {
  let renewYears = [];
  for (let i = 1; i < 11; i++) {
    let renewPrice = "";

    renewPrice =
      domainPrices[parsedDomain.tld][customerCurrencyCode].renew[i] ||
      undefined;
    if (renewPrice === "0.00") {
      renewPrice = translate("general.free");
    }

    if (renewPrice > "0.00") {
      renewPrice =
        i > 1
          ? `${translate(
              "general.years"
            )} (${customerCurrencyCode.toUpperCase()} ${
              customerCurrencyCode === "eur"
                ? parseFloat(renewPrice).toFixed(2)
                : parseFloat(renewPrice).toFixed(0)
            })`
          : `${translate(
              "general.years"
            )} (${customerCurrencyCode.toUpperCase()} ${
              customerCurrencyCode === "eur"
                ? parseFloat(renewPrice).toFixed(2)
                : parseFloat(renewPrice).toFixed(0)
            })`;
    }

    if (renewPrice === undefined) {
      renewPrice =
        i > 1
          ? `${translate("general.years")}`
          : `${translate("general.year")}`;
    }

    renewYears = renewYears.concat({
      label: `${i} ${renewPrice}`,
      value: i
    });
  }

  const selectedValue = renewYears.find(
    item => item.value === domain.renewal_period
  );
  return (
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12">
        <StyledRenewDomainWithPeriod className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <SelectBox
              label={translate("domains.chose-renewal-period")}
              options={renewYears}
              defaultValue={selectedValue}
              input={{ onChange: onChange }}
            />
          </div>
          <div className="col-12 col-sm-12 col-md-12">
            <NoticeInfo>
              {translate("domains.renewal-period-notice")}
            </NoticeInfo>
          </div>
        </StyledRenewDomainWithPeriod>
      </div>
    </div>
  );
};

export default withLocalize(RenewDomainWithPeriod);
