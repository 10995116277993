import React from "react";
import {Translate, withLocalize} from "react-localize-redux";
import {
    isFetchingAvailableBackups
} from "../../../Customer/reducers/customerReducer";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchHostingBackups} from "../../../Customer/actions/customerActions";
import withLoading from "../../../../components/Loaders/WithLoading";
import ContentLoader from "../../../../components/Loaders/ContentLoader";
import styled from "styled-components";
import moment from "moment";
import BackupRestoreHistory from "./BackupRestoreHistory";
import BackupInfo from "./BackupInfo";
import {theme} from "../../../../design/Themes";
import {DayPicker} from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import mk from 'date-fns/locale/mk';
import Panel from "../../../../components/Panel";
import BackupInfoPanel from "./BackupInfoPanel";
import RoundLoader from "../../../../components/RoundLoader";

const StyledHostingAvailableBackups = styled.div`
  margin-bottom: 1px;
`;

const StyledContent = styled.div`
  padding: 30px;
`;


const StyledBackupsWrapper = styled.div`
  margin-bottom: 30px;
`;


class BackupRestore extends React.Component {
     bookedStyle = { border: '2px solid #0000FF', backgroundColor: '#E7EDFF', color: '#5E6C83' };

    constructor(props) {
        super(props);

        this.state = {
            backups_dates: [],
            selectedDate: null,
            isDateValid: false,
            updateHistory : new Date()
        };
    }

    componentDidMount() {
        this.props.fetchHostingBackups(this.props.hosting).then(backups_dates => {
            this.setState({backups_dates: backups_dates});
        });
    }


    checkValidDate(selectedDate, modifiers) {
        let datesList = this.state.backups_dates.map(date => {
            return moment(date).format("DD-MM-YYYY");
        });
        if (datesList.find(x => x === moment(selectedDate).format("DD-MM-YYYY"))) {
            this.setState({
                isDateValid: true,
                selectedDate,
            })
        } else {
            this.setState({
                isDateValid: false,
            })
        }
    }

    render() {
        const StyledHostingAvailableBackupsWithLoading = withLoading(
            StyledHostingAvailableBackups,
            ContentLoader
        );
        let datesList = this.state.backups_dates.map(date => {
            return moment(date).format("DD-MM-YYYY");
        });

        return (
            <Translate>
                {({translate, activeLanguage}) => (
                    <div className={'row'}>
                        <StyledBackupsWrapper className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                     <BackupInfoPanel/>
                            <StyledBackupsWrapper>
                                    <Panel title={translate("backup.available-backups")} >
                                    <StyledContent>
                                        <div className={'row'}>

                                            <div className={'col-12 col-sm-12 col-md-6 col-lg-6 '}>
                                                <StyledHostingAvailableBackupsWithLoading
                                                    isLoading={this.props.isFetchingAvailableBackups}
                                                >
                                                    <DayPicker
                                                        locale={activeLanguage.code === 'mk' ? mk : null}
                                                        onDayClick={(day) => {
                                                            this.checkValidDate(day)
                                                        }}
                                                        selected={this.state.selectedDate}
                                                        mode={'single'}
                                                        defaultMonth={this.state.selectedDate}
                                                        modifiersStyles={{ selected: this.bookedStyle }}

                                                        firstDayOfWeek={1}
                                                        disabled={(day) => {
                                                            return !datesList.find(x=> x === moment(day).format("DD-MM-YYYY"))
                                                        }}
                                                    />
                                                </StyledHostingAvailableBackupsWithLoading>
                                            </div>
                                            <div className={'col-12 col-sm-12 col-md-6 col-lg-6'}>
                                                {this.state.isDateValid &&
                                                <BackupInfo hosting={this.props.hosting}
                                                            resetDate={() => {this.setState({
                                                                selectedDate : null,
                                                                bookedDays: [],
                                                                updateHistory : new Date(),
                                                                isDateValid : false })}}
                                                            translate={translate}
                                                            dateSelected={this.state.selectedDate}/>
                                                }
                                            </div>
                                        </div>
                                    </StyledContent>
                                    </Panel>
                            </StyledBackupsWrapper>
                        </StyledBackupsWrapper>
                        <StyledBackupsWrapper className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                            <StyledBackupsWrapper>
                                    <BackupRestoreHistory hosting={this.props.hosting}  key={this.state.updateHistory}/>
                            </StyledBackupsWrapper>
                        </StyledBackupsWrapper>
                    </div>
                )}
            </Translate>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        isFetchingAvailableBackups: isFetchingAvailableBackups(state),
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {fetchHostingBackups},
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withLocalize(BackupRestore));
