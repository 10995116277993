import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Link } from "react-router-dom";

import Icon from "../../Icon";
import NotificationsNavItem from "../NotificationsNavItem";

import { ROUTE_CART } from "../../../routes/routes";

const StyledMobileSubNavigation = styled.div`
  position: fixed;
  right: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  top: 74px;
  background-color: ${theme.neutralBase};
  text-align: right;
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  color: ${theme.white};
  padding: 30px 0;
`;

const MobileSubNavigation = props => (
  <StyledMobileSubNavigation className="row">
    <div className="col-12 col-sm-12 col-md-12">
      {props.isAuthenticated && <NotificationsNavItem />}
      <Link to={ROUTE_CART} className="mr-2">
        <StyledIcon icon="cart" />
      </Link>
    </div>
  </StyledMobileSubNavigation>
);
export default MobileSubNavigation;
