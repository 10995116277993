import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import {
  MobileOnlyView,
  isMobileOnly,
  isTablet,
  isBrowser
} from "react-device-detect";

import Container from "../../../components/Containers/Container";
import PrimaryButton from "../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../components/Buttons/SecondaryButton";
import DropdownButton from "../../../components/DropdownButton";
import DropdownItem from "../../../components/DropdownItem";
import ButtonGroupContentDropdown from "../../../components/ButtonGroupContentDropdown";
import Form from "../../../components/Forms/Form";
import SearchField from "../../../components/SearchField";
import Checkbox from "../../../components/Checkbox";
import AmountBadge from "../../../components/AmountBadge";
import Icon from "../../../components/Icon";

import Table from "../../../components/Table";
import TableHeader from "../../../components/Table/TableHeader";
import TableHeading from "../../../components/Table/TableHeading";
import TableBody from "../../../components/Table/TableBody";
import TableRow from "../../../components/Table/TableRow";
import TableCell from "../../../components/Table/TableCell";

import Pagination from "../../../components/Pagination";

import HostingFilter from "../HostingFilter";

import getBadgeForStatus from "../../../utils/getBadgeForStatus";
import getStatusTextForStatus from "../../../utils/getStatusTextForStatus";

import withLoading from "../../../components/Loaders/WithLoading";
import TableContentLoader from "../../../components/Loaders/TableContentLoader";

import {
  compileRoute,
  ROUTE_VIEW_HOSTING_GENERAL_INFO,
  ROUTE_SHOP_HOSTING
} from "../../../routes/routes";

import {
  HOSTING_STATUS_PENDING,
  HOSTING_STATUS_TERMINATED
} from "../HostingStatuses/HostingStatuses";

const StyledHostingListWrapper = styled.div`
  margin-bottom: ${props => (props.isMobile ? "50px" : "30px")};
`;

const StyledHostingList = styled.div`
  padding-top: 30px;
  padding-bottom: 15px;

  & .hosting-list-header {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
  }
  //
  //& .hosting-list-header button.purchase-hosting {
  //  margin-right: 0;
  //}
`;

const StyledHostingListContainer = styled.div`
  & a.hosting-link {
    font-size: 16px;
  }

  & a.hosting-link-block {
    display: block;
  }

  & .domain-url-block {
    display: block;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }

  & p.domain-name {
    font-size: 12px;
  }
`;

const StyledCancelPlan = styled.span`
  color: #be2b16;
`;

const StyledPurchaseHosting = styled(Link)`
  margin-right: 15px;
`;

const StyledSelectedCount = styled.span`
  margin-right: 15px;
`;

const HostingList = props => {
  const StyledHostingsTableWithLoading = withLoading(
    StyledHostingListContainer,
    TableContentLoader
  );

  const filtersApplied = props.countFiltersApplied();

  return (
    <Translate>
      {({ translate }) => (
        <StyledHostingListWrapper>
          <Container>
            <StyledHostingList>
              <MobileOnlyView>
                <div className="hosting-list-header">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <StyledPurchaseHosting to={ROUTE_SHOP_HOSTING}>
                        <PrimaryButton className="purchase-hosting w-100">
                          {translate("hosting.table.purchase-hosting")}
                        </PrimaryButton>
                      </StyledPurchaseHosting>

                      {props.isLaptop && (
                        <ButtonGroupContentDropdown
                          text={`${translate("hosting.filter.title")}  ${
                            filtersApplied > 0 ? " - " + filtersApplied : ""
                          }`}
                          size="m"
                          alignMenu="right"
                        >
                          <HostingFilter
                            productsListFilters={props.productsListFilters}
                            onStatusFilterOptionChange={
                              props.onStatusFilterOptionChange
                            }
                            onPlanTypesFilterOptionChange={
                              props.onPlanTypesFilterOptionChange
                            }
                            onFiltersClear={props.onFiltersClear}
                            countFiltersApplied={props.countFiltersApplied}
                            filterStatusOptions={props.filterStatusOptions}
                            filterPlanTypesOptions={
                              props.filterPlanTypesOptions
                            }
                            products={props.products}
                            selectedRowsCount={props.selectedRowsCount}
                            onRowSelectionChanged={props.onRowSelectionChanged}
                            selectAllRows={props.selectAllRows}
                            deselectRows={props.deselectRows}
                          />
                        </ButtonGroupContentDropdown>
                      )}
                    </div>
                  </div>
                </div>
                <div className="hosting-list-header">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                        }}
                      >
                        <SearchField
                          onChange={props.onChangeKeywordSearch}
                          name="search"
                          value={props.searchQuery}
                          placeholder={translate(
                            "hosting.table.search-placeholder"
                          )}
                        />
                      </Form>
                    </div>
                  </div>
                </div>
              </MobileOnlyView>
              {(isTablet || isBrowser) && (
                <div className="hosting-list-header">
                  <div className="row">
                    <div className="col-6 col-sm-6 col-md-6">
                      <Form
                        onSubmit={e => {
                          e.preventDefault();
                        }}
                      >
                        <SearchField
                          onChange={props.onChangeKeywordSearch}
                          name="search"
                          value={props.searchQuery}
                          placeholder={translate(
                            "hosting.table.search-placeholder"
                          )}
                        />
                      </Form>
                    </div>
                    <div className="col-6 col-sm-6 col-md-6 text-right">
                      <StyledPurchaseHosting to={ROUTE_SHOP_HOSTING}>
                        <PrimaryButton className="purchase-hosting">
                          {translate("hosting.table.purchase-hosting")}
                        </PrimaryButton>
                      </StyledPurchaseHosting>

                      {props.isLaptop && (
                        <ButtonGroupContentDropdown
                          text={`${translate("hosting.filter.title")}  ${
                            filtersApplied > 0 ? " - " + filtersApplied : ""
                          }`}
                          size="m"
                          alignMenu="right"
                        >
                          <HostingFilter
                            productsListFilters={props.productsListFilters}
                            onStatusFilterOptionChange={
                              props.onStatusFilterOptionChange
                            }
                            onPlanTypesFilterOptionChange={
                              props.onPlanTypesFilterOptionChange
                            }
                            onFiltersClear={props.onFiltersClear}
                            countFiltersApplied={props.countFiltersApplied}
                            filterStatusOptions={props.filterStatusOptions}
                            filterPlanTypesOptions={
                              props.filterPlanTypesOptions
                            }
                            products={props.products}
                            selectedRowsCount={props.selectedRowsCount}
                            onRowSelectionChanged={props.onRowSelectionChanged}
                            selectAllRows={props.selectAllRows}
                            deselectRows={props.deselectRows}
                          />
                        </ButtonGroupContentDropdown>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  <StyledHostingsTableWithLoading isLoading={props.isLoading}>
                    <Table>
                      {isBrowser && (
                        <TableHeader>
                          {props.selectedRowsCount === 0 ? (
                            <TableRow>
                              <TableHeading
                                width="30%"
                                isSortable={true}
                                sortBy="name"
                                isDefaultSort={
                                  props.products.sort.sort_by === "name"
                                }
                                sortDirection={props.products.sort.order_by}
                                changeListOrderTrigger={
                                  props.changeListOrderTrigger
                                }
                              >
                                {translate("hosting.table.name")}
                              </TableHeading>
                              <TableHeading
                                width="17%"
                                isSortable={true}
                                sortBy="renewal_date"
                                isDefaultSort={
                                  props.products.sort.sort_by === "renewal_date"
                                }
                                sortDirection={props.products.sort.order_by}
                                changeListOrderTrigger={
                                  props.changeListOrderTrigger
                                }
                              >
                                {translate("hosting.table.renewal-date")}
                              </TableHeading>
                              <TableHeading
                                width="16%"
                                isSortable={true}
                                sortBy="renewal_price"
                                isDefaultSort={
                                  props.products.sort.sort_by ===
                                  "renewal_price"
                                }
                                sortDirection={props.products.sort.order_by}
                                changeListOrderTrigger={
                                  props.changeListOrderTrigger
                                }
                              >
                                {translate("hosting.table.renewal-price")}
                              </TableHeading>
                              <TableHeading
                                width="10%"
                                isSortable={true}
                                sortBy="status"
                                isDefaultSort={
                                  props.products.sort.sort_by === "status"
                                }
                                sortDirection={props.products.sort.order_by}
                                changeListOrderTrigger={
                                  props.changeListOrderTrigger
                                }
                              >
                                {translate("hosting.table.status")}
                              </TableHeading>
                              <TableHeading width="25%" />
                            </TableRow>
                          ) : (
                            <TableRow>
                              <TableHeading colSpan={6}>
                                <StyledSelectedCount>
                                  {props.selectedRowsCount}{" "}
                                  {translate("domains.table.selected")}
                                </StyledSelectedCount>
                                <SecondaryButton size="s">
                                  {translate("domains.table.renew")}
                                </SecondaryButton>
                              </TableHeading>
                            </TableRow>
                          )}
                        </TableHeader>
                      )}
                      <TableBody>
                        {props.products.items.length === 0 && (
                          <TableRow>
                            <TableCell colSpan="6">
                              {translate("hosting.table.empty")}
                            </TableCell>
                          </TableRow>
                        )}
                        {props.products.items.length > 0 &&
                          props.products.items.map(product => {
                            const BadgeComponent = getBadgeForStatus(
                              product.status.state
                            );

                            const StatusComponent = getStatusTextForStatus(
                              product.status.state
                            );
                            return (
                              <TableRow key={`customer-product-${product.id}`}>
                                <TableCell width="30%">
                                  <Link
                                    className={
                                      isTablet || isMobileOnly
                                        ? "hosting-link hosting-link-block"
                                        : "hosting-link"
                                    }
                                    to={compileRoute(
                                      ROUTE_VIEW_HOSTING_GENERAL_INFO
                                    )({
                                      id: product.id,
                                      domain: product.domain
                                    })}
                                  >
                                    {product.name}
                                  </Link>{" "}
                                  <span
                                    className={
                                      isTablet || isMobileOnly
                                        ? "domain-url domain-url-block"
                                        : "domain-url"
                                    }
                                  >
                                    {product.domain}
                                  </span>
                                  {(isTablet || isMobileOnly) && (
                                    <StatusComponent>
                                      {translate(
                                        "hosting.statuses." +
                                          product.status.name
                                      )}
                                    </StatusComponent>
                                  )}
                                </TableCell>
                                {isBrowser && (
                                  <TableCell width="17%">
                                    {product.renewal_date}
                                  </TableCell>
                                )}
                                <TableCell width="16%">
                                  <AmountBadge
                                    currency={
                                      product.renewal_price.currency.code
                                    }
                                    amount={product.renewal_price.value}
                                  />
                                </TableCell>
                                {isBrowser && (
                                  <TableCell width="10%">
                                    <BadgeComponent>
                                      {translate(
                                        "hosting.statuses." +
                                          product.status.name
                                      )}
                                    </BadgeComponent>
                                  </TableCell>
                                )}
                                {!isMobileOnly && (
                                  <TableCell
                                    width="25%"
                                    className="text-right actions"
                                  >
                                    {isBrowser &&
                                      product.status.code !==
                                        HOSTING_STATUS_PENDING &&
                                      product.status.code !==
                                        HOSTING_STATUS_TERMINATED && (
                                        <SecondaryButton
                                          onClick={e =>
                                            props.onRenewProduct(product)
                                          }
                                          disabled={
                                            props.renewProductSubmittingId ===
                                              product.id &&
                                            props.renewProductSubmitting
                                          }
                                          submitting={
                                            props.renewProductSubmittingId ===
                                              product.id &&
                                            props.renewProductSubmitting
                                          }
                                          size="s"
                                        >
                                          {translate("hosting.table.renew-now")}
                                        </SecondaryButton>
                                      )}
                                    {product.status.code !==
                                      HOSTING_STATUS_PENDING &&
                                      product.status.code !==
                                        HOSTING_STATUS_TERMINATED && (
                                        <DropdownButton
                                          size="s"
                                          text={() => (
                                            <Icon icon="dots_horizontal" />
                                          )}
                                          alignMenu="right"
                                        >
                                          {
                                            <DropdownItem
                                              onClick={e =>
                                                props.onUpgradePlan(product)
                                              }
                                            >
                                              {translate(
                                                "hosting.table.upgrade-downgrade-plan"
                                              )}
                                            </DropdownItem>
                                          }
                                          <DropdownItem
                                            onClick={e =>
                                              props.onCancelHosting(product)
                                            }
                                          >
                                            <StyledCancelPlan>
                                              {translate(
                                                "hosting.table.cancel-plan"
                                              )}
                                            </StyledCancelPlan>
                                          </DropdownItem>
                                        </DropdownButton>
                                      )}
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </StyledHostingsTableWithLoading>
                </div>
              </div>
              <Pagination
                itemsName={translate("hosting.table.hostings")}
                paging={props.products.paging}
                handlePaginationClick={props.handlePaginationClick}
                onPerPageOptionChanged={props.onPerPageOptionChanged}
              />
            </StyledHostingList>
          </Container>
        </StyledHostingListWrapper>
      )}
    </Translate>
  );
};

export default HostingList;
