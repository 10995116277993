import React from "react";
import styled from "styled-components";

import { theme } from "../../design/Themes";

const StyledSectionHeader = styled.div`
  padding: 30px 30px 15px 30px;
  border-bottom: 1px solid ${theme.neutral3};
  span.title {
    font-size: 20px;
    font-weight: 600;
    color: ${theme.neutralBase};
    line-height: 1.4;
    display: block;
  }
  span.subtitle {
    display: block;
    font-size: 16px;
    line-height: 1.5;
    color: ${theme.neutral6};
  }
`;

const SectionHeader = props => (
  <StyledSectionHeader>
    <span className="title">{props.title}</span>
    {props.subtitle && <span className="subtitle">{props.subtitle}</span>}
  </StyledSectionHeader>
);

export default SectionHeader;
