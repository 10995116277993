import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withLocalize } from "react-localize-redux";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { FORM_ERROR } from "final-form";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import {
  BrowserView,
  MobileView,
  isMobile,
  isMobileOnly,
  isIE
} from "react-device-detect";

import { forgotPassword } from "../actions/authActions";
import { isAuthenticated } from "../reducers/authReducer";

import ForgotPasswordForm from "./ForgotPasswordForm";

import { validateEmail } from "../../../common/validationRules";

import whiteLogo from "../../../assets/images/white-logo.svg";

import {
  setApplicationLocale,
  getApplicationLocale
} from "../../../services/localizationService";

import { ROUTE_WEBSITE } from "../../../routes/routes";

const StyledLogoContainer = styled.div`
  background-color: ${theme.neutralBase};
  position: relative;
  text-align: center;
  vertical-align: middle;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
`;

const StyledMobileLogoContainer = styled(StyledLogoContainer)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 1031;
  border-radius: 0;
  padding-top: 18px;
  padding-bottom: 18px;
`;

const StyledForgotPasswordFormContainer = styled.div`
  margin-top: ${props => (props.isMobile ? "150px" : "80px")};
  margin-bottom: 30px;
`;

const StyledForgotPasswordFormWrapper = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const StyledLogo = styled.img`
  position: relative;
  top: 45%;
  width: ${props => props.isMobile && "55%"};
`;

const validateForgotPassword = values => {
  return {
    email: validateEmail(values.email)
  };
};

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resetEmailSent: false,
      currentLocale: getApplicationLocale(),
      reCaptchaToken: null
    };
  }

  onForgotPassword = values => {
    return this.props
      .forgotPassword(values.email, this.state.reCaptchaToken)
      .then(
        () => {
          this.setState({
            resetEmailSent: true
          });
        },
        err => {
          const { messages } = err.response.data.data;
          return {
            [FORM_ERROR]: messages[0]
          };
        }
      )
      .catch(error => {
        const { messages } = error.response.data.data;
        this.setState({
          resetEmailSent: true
        });
        if (window.grecaptcha) {
          window.grecaptcha
            .execute(process.env.REACT_APP_MKHOST_GOOGLE_RECAPTCHA_KEY)
            .then(newToken => {
              this.onSetReCaptchaToken(newToken);
            });
        }
        return {
          [FORM_ERROR]: messages[0]
        };
      });
  };

  changeLocale = locale => {
    this.props.setActiveLanguage(locale);
    setApplicationLocale(locale);

    this.setState({ currentLocale: getApplicationLocale() });
  };

  onSetReCaptchaToken = token => {
    this.setState({
      reCaptchaToken: token
    });
  };

  render() {
    return (
      <div className="row">
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_MKHOST_GOOGLE_RECAPTCHA_KEY}
        >
          <BrowserView viewClassName="col-6 offset-3 col-sm-6 offset-sm-3 col-md-6 offset-md-3">
            <StyledForgotPasswordFormContainer className="row no-gutters">
              <StyledLogoContainer className="col-6 col-sm-6 col-md-6">
                <a href={`${ROUTE_WEBSITE}/${this.state.currentLocale}`}>
                  <StyledLogo src={whiteLogo} alt="MKHost" isIE={isIE} />
                </a>
              </StyledLogoContainer>
              <StyledForgotPasswordFormWrapper className="col-6 col-sm-6 col-md-6">
                <ForgotPasswordForm
                  resetEmailSent={this.state.resetEmailSent}
                  onSubmit={this.onForgotPassword}
                  validate={validateForgotPassword}
                  isMobile={isMobile}
                  changeLocale={this.changeLocale}
                  currentLocale={this.state.currentLocale}
                  onSetReCaptchaToken={this.onSetReCaptchaToken}
                />
              </StyledForgotPasswordFormWrapper>
            </StyledForgotPasswordFormContainer>
          </BrowserView>
          <MobileView viewClassName="col-12 col-sm-12 col-md-12">
            <StyledForgotPasswordFormContainer
              isMobile={isMobileOnly}
              className="row"
            >
              <StyledMobileLogoContainer className="col-12 col-sm-12 col-md-12">
                <a href={`${ROUTE_WEBSITE}/${this.state.currentLocale}`}>
                  <StyledLogo
                    isMobile={isMobileOnly}
                    src={whiteLogo}
                    isIE={isIE}
                    alt="MKHost"
                  />
                </a>
              </StyledMobileLogoContainer>
              <StyledForgotPasswordFormWrapper className="col-12 col-sm-12 col-md-12">
                <ForgotPasswordForm
                  resetEmailSent={this.state.resetEmailSent}
                  onSubmit={this.onForgotPassword}
                  validate={validateForgotPassword}
                  isMobile={isMobile}
                  changeLocale={this.changeLocale}
                  currentLocale={this.state.currentLocale}
                  onSetReCaptchaToken={this.onSetReCaptchaToken}
                />
              </StyledForgotPasswordFormWrapper>
            </StyledForgotPasswordFormContainer>
          </MobileView>
        </GoogleReCaptchaProvider>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state)
  };
};

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      forgotPassword
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(ForgotPassword));
