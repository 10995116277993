import React from "react";
import { Translate } from "react-localize-redux";

import Panel from "../../../../../components/Panel";
import Togglebox from "../../../../../components/Togglebox";
import DomainLockLoader from "../../../../../components/Loaders/DomainLockLoader";
import Icon from "../../../../../components/Icon";
import CustomTooltip from "../../../../../components/CustomTooltip";

const DomainLock = ({
  domainLocked,
  onUnlockDomain,
  onLockDomain,
  disableDomainUnlocking,
  submitting,
  isMkDomain,
  domain //@TODO, temp for rs and al tlds
}) => (
  <Translate>
    {({ translate }) => (
      <Panel
        title={translate("domain.settings.domain-lock")}
        description={translate("domain.settings.domain-lock-description")}
      >
        <div className="row">
          <div className="col-12 col-sm-6 col-md-6">
            {translate("domain.settings.domain-lock")}{" "}
            <CustomTooltip
              inline
              content={translate("domain.settings.domain-lock-description-2")}
            >
              <Icon icon="info" />
            </CustomTooltip>
          </div>
          <div className="col-12 col-sm-6 col-md-6">
            { (isMkDomain || domain.domain_name.endsWith(".rs") || domain.domain_name.endsWith(".al"))  && (
              <CustomTooltip
                trigger={["hover"]}
                content={isMkDomain ? translate("domain.settings.domain-lock-not-available-for-mk-domains") : translate("domain.settings.domain-lock-not-available-for-domain")}
              >
                <Togglebox
                  toggled={domainLocked}
                  onToggle={() => {}}
                  disabled={true}
                  submitting={false}
                  className="float-right"
                />
              </CustomTooltip>
            )}
            {!isMkDomain && !domain.domain_name.endsWith(".rs") && !domain.domain_name.endsWith(".al") && (
              <Togglebox
                toggled={domainLocked}
                onToggle={() =>
                  domainLocked ? onUnlockDomain() : onLockDomain()
                }
                disabled={disableDomainUnlocking}
                submitting={submitting}
                className="float-right"
              />
            )}
          </div>
        </div>
      </Panel>
    )}
  </Translate>
);

export default DomainLock;
