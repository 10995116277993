import React from "react";
import styled from "styled-components";
import { Translate } from "react-localize-redux";

const StyledPromotionInfo = styled.div`
  padding: 30px;
  padding-bottom: 0px;
`;

class PromotionInfo extends React.Component {
  render() {
    return(
      <Translate>
        {({ translate }) => (
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              {false && (
              <StyledPromotionInfo>
                {translate("cart.cart-summary-info")}
              </StyledPromotionInfo>
              )}
            </div>
          </div>
        )}
      </Translate>
    )
  }
}

export default PromotionInfo;
