import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";

import UserWithEmail from "../../../../components/UserWithEmail";
import Icon from "../../../../components/Icon";
import CustomTooltip from "../../../../components/CustomTooltip";

import { Translate } from "react-localize-redux";

const StyledInvoiceToTitle = styled.div`
  display: inline-flex;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${theme.neutral5};
  text-transform: uppercase;
  margin-bottom: 30px;
  padding-left: 0;
`;

const StyledDashedWrapper = styled.div`
  border-radius: 5px;
  margin-bottom: 15px;
  & .user-avatar {
    vertical-align: top;
  }
  & .user-with-email {
    margin-left: 0;
    margin-bottom: 20px;
  }
`;

const StyledAddressDetails = styled.div`
  margin-bottom: 20px;
  & p {
    margin-bottom: 5px;
    font-size: 16px;
    color: ${theme.neutralBase};
  }
`;
const StyledContactDetails = styled.div`
  padding-top: 0;

  & p {
    margin-bottom: 5px;
    font-size: 16px;
    color: ${theme.neutralBase};
  }
`;

const StyledCustomTooltip = styled.div`
  display: inline;
`;

const InvoiceTo = ({
  contact,
  onEditPayer,
  onChangePayer,
  proInvoiceStatus
}) => {
  return (
    <Translate>
      {({ translate }) => (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <StyledInvoiceToTitle>
              {translate("invoice-to.title")}
              <StyledCustomTooltip>
                <CustomTooltip
                  content={translate("shop.checkout.invoice-to-tooltip")}
                >
                  <Icon size="l" icon="info" />
                </CustomTooltip>
              </StyledCustomTooltip>
            </StyledInvoiceToTitle>
          </div>
          <StyledDashedWrapper className="col-12 col-sm-12 col-md-12">
            <UserWithEmail user={contact.full_name} email={contact.email} />
          </StyledDashedWrapper>
          <StyledDashedWrapper className="col-12 col-sm-12 col-md-12">
            <StyledAddressDetails>
              {contact.address1 &&
                contact.address !== "" && <p>{contact.address1}</p>}
              {contact.city &&
                contact.zip && (
                  <p>
                    {contact.zip} {contact.city}
                  </p>
                )}
              {contact.country &&
                contact.country.id !== "" && <p>{contact.country.name}</p>}
            </StyledAddressDetails>
            <StyledContactDetails>
              {contact.phone && contact.phone !== "" && <p>{contact.phone}</p>}
            </StyledContactDetails>
          </StyledDashedWrapper>
        </div>
      )}
    </Translate>
  );
};

export default InvoiceTo;
