import React from "react";
import styled from "styled-components";

import {
  PRODUCT_DOMAIN,
  PRODUCT_PRODUCT,
  PRODUCT_DEDICATED_SERVER,
  PRODUCT_CLOUD_SERVER,
  DOMAIN_SERVICE,
  HOSTING_SERVICE,
  SERVER_SERVICE
} from "../../Products/ProductTypes";

import CompactCartDomainProduct from "./CompactCartDomainProduct";
import CompactCartProduct from "./CompactCartProduct";
import CompactCartService from "./CompactCartService";

const StyledCompactCartProductsList = styled.div``;

const CompactCartProductsList = ({ products }) => {
  return (
    <StyledCompactCartProductsList>
      {products
        .filter(product => product.type === PRODUCT_DOMAIN)
        .map(product => (
          <CompactCartDomainProduct
            key={`compact-domain-${product.id}`}
            product={product}
          />
        ))}
      {products
        .filter(product => product.type === PRODUCT_PRODUCT)
        .map(product => (
          <CompactCartProduct
            key={`compact-product-${product.id}`}
            product={product}
          />
        ))}
      {products
        .filter(
          product =>
            product.type === PRODUCT_DEDICATED_SERVER ||
            product.type === PRODUCT_CLOUD_SERVER
        )
        .map(product => (
          <CompactCartProduct
            key={`compact-product-${product.id}`}
            product={product}
          />
        ))}

      {products
        .filter(
          product =>
            product.type === DOMAIN_SERVICE ||
            product.type === HOSTING_SERVICE ||
            product.type === SERVER_SERVICE
        )
        .map(product => (
          <CompactCartService
            key={`compact-service-${product.id}`}
            product={product}
          />
        ))}
    </StyledCompactCartProductsList>
  );
};

export default CompactCartProductsList;
