import styled from "styled-components";
import { theme } from "../../../design/Themes";

const TableRow = styled.tr`
  &:hover {
    background-color: ${theme.blue1};
    cursor: pointer;
  }
`;
export default TableRow;
