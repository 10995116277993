import React from "react";

import Loader from "../Loader";

const KnowledgeBaseSearchByKeywordResultsLoader = () => (
  <Loader width="280" height="300">
    <rect x="0" y="10" width="30" height="10" rx="3" />
    <rect x="35" y="10" width="30" height="10" rx="3" />
    <rect x="0" y="25" width="280" height="20" rx="3" />

    <rect x="0" y="50" width="30" height="10" rx="3" />
    <rect x="35" y="50" width="30" height="10" rx="3" />
    <rect x="0" y="65" width="280" height="20" rx="3" />

    <rect x="0" y="90" width="30" height="10" rx="3" />
    <rect x="35" y="90" width="30" height="10" rx="3" />
    <rect x="0" y="105" width="280" height="20" rx="3" />
  </Loader>
);

export default KnowledgeBaseSearchByKeywordResultsLoader;
