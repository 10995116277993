import React ,{useState,useEffect} from 'react';
import styled from 'styled-components';
import Button from '../Buttons/Button';
import PrimaryButton from '../Buttons/PrimaryButton';
import { Translate } from "react-localize-redux";


import Modal from '../Modal';


const StyledPdfModal = styled(Modal)`
  & div.modal-dialog { // Adjust based on the actual nested structure
    padding-top: 0 !important;
  }
`;


const StyledIframe = styled.iframe`
  width: 100%;
  height: 50vh;
  overflow-y: auto; 
  border: none; 
`;

// const ButtonContainer = styled.div`
//   display: flex;
//   justify-content: center; 
//   margin-top: 10px; 
//   gap:2rem;
// `;

const ButtonContainer2 = styled.div`
  display: flex;
  flex-direction: row; 
  justify-content: flex-start; 
  align-items:flex-start;
  flex-wrap: wrap; 
  gap: 2rem; 
  margin-top: 10px; 
`;

const Rulebook=styled.div`
display:flex;
font-size:12px;
align-items:flex-start;
margin-top:2px;
gap:2px;`

const Text=styled.p`
font-size:14px;
`

function PdfPopup({ isVisible, pdfUrls, onAccept, onClose }) {
    const [isAgreed, setIsAgreed] = useState(false); 
    const [showAgreementError, setShowAgreementError] = useState(false); 

    useEffect(() => {
        if (pdfUrls && pdfUrls.length > 0) {
          setSelectedPdf(pdfUrls[0]);
        }
      }, [pdfUrls]);
  const [selectedPdf, setSelectedPdf] = useState(pdfUrls && pdfUrls.length > 0 ? pdfUrls[0] : null);


 if (!isVisible || !pdfUrls || pdfUrls.length === 0) return null;


  const handlePdfSelection = (url) => {
    setSelectedPdf(url);
  };

  const handleCheckboxChange = (e) => {
    setIsAgreed(e.target.checked);
    if (!e.target.checked) {
      setShowAgreementError(true); // Consider showing the error when unchecked
    } else {
      setShowAgreementError(false); // Hide error when checked
    }
  };
  

  const handleAccept = () => {
    // console.log("Is agreed:", isAgreed); 
    if (!isAgreed) {
      setShowAgreementError(true); 
      return;
    }
    onAccept(); 
  };

//   return (
//     <Translate>
//       {({ translate }) => (
//         <Overlay>
//           <PdfContainer>
//             <Text>{translate("domain-accept")}</Text>

        
//             <ButtonContainer>
//                        <Button onClick={onClose}>{translate("change-info-before-continue")}<br/>{translate("change-info-before-continue2")}</Button>
//             </ButtonContainer>
//           </PdfContainer>
//         </Overlay>
//       )}
//     </Translate>
//   );
// }





return (
  <Translate>
      {({ translate }) => (
  <StyledPdfModal
    title={translate("domain-pdf-title")}
    subTitle={translate("domain-accept")}
    onCloseModal={onClose}
    size="large"

    body={() => (
      <>
  
      <ButtonContainer2>
      {pdfUrls.map((url, index) => (
                    <Button key={index} onClick={() => handlePdfSelection(url)}>
                      {translate("document")} {index + 1}
                    </Button>
                  ))}
      </ButtonContainer2>
                 
      <StyledIframe src={`${selectedPdf}#navpanes=0&toolbar=0`} title="PDF Document" />
      
      
                  <Rulebook>
                    <input
                      type="checkbox"
                      id="agreementCheckbox"
                      checked={isAgreed}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor="agreementCheckbox">
                    {translate("rulebook-agree")}{" "}
                      <a href="https://mkhost-public-assets.s3.eu-central-1.amazonaws.com/pravilnik-mk-mkd.pdf" target="_blank" rel="noopener noreferrer">
                      {translate("rulebook-link")}                </a>.
                      {showAgreementError && (
                      <div style={{ color: 'red', marginTop: '2px',fontSize:"10px" }}>
                        {translate("error-must-agree-to-terms")} 
                      </div>
                    )}
                    </label>
                   
                  </Rulebook>
                  </>
    
    )}
    footer={() => (
      <>
        <Button onClick={onClose}>{translate("change-info-before-continue")}<br/>{translate("change-info-before-continue2")}</Button>
        <PrimaryButton onClick={handleAccept}>
                {translate("accept-continue-checkout")}<br/>{translate("accept-continue-checkout2")}
              </PrimaryButton>  
              </>
    )}
  />
  )}
     </Translate>
);
}

export default PdfPopup;