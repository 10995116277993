import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

import Container from "../Containers/Container";

const StyledPanel = styled.div`
  margin-bottom: 30px;
`;
const StyledPanelHeader = styled.div`
  padding: 30px 30px 20px 30px;
  border-bottom: 1px solid ${theme.neutral3};
  & h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
  }

  & p {
    font-size: 16px;
    line-height: 1.5;
    color: ${theme.neutral6};
    white-space: pre-wrap;
  }
`;

const StyledPanelContent = styled.div`
  padding: 30px;
  & .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.43;
    color: ${theme.neutral5};
  }
`;

const StyledPanelFooter = styled.div`
  border-top: 1px solid ${theme.neutral3};
  padding: 30px;
`;

const Panel = ({ title, description, children, footer }) => (
  <StyledPanel>
    <Container>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <StyledPanelHeader>
            <h4>{title}</h4>
            {description && <p>{description}</p>}
          </StyledPanelHeader>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-sm-12 col-md-12">
          <StyledPanelContent>{children}</StyledPanelContent>
        </div>
      </div>
      {typeof footer === "function" && (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <StyledPanelFooter>{footer()}</StyledPanelFooter>
          </div>
        </div>
      )}
    </Container>
  </StyledPanel>
);

Panel.defaultProps = {
  title: "",
  description: null
};

export default Panel;
