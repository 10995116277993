import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { Form, Field, FormSpy } from "react-final-form";
import { isMobile, isMobileOnly, isBrowser } from "react-device-detect";
import isLaptop from "../../../../../utils/isLaptopScreen";
import withSizes from "react-sizes";
import { Translate } from "react-localize-redux";

import Panel from "../../../../../components/Panel";
import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";
import Radiobox from "../../../../../components/Radiobox";
import Label from "../../../../../components/Label";
import InputField from "../../../../../components/InputField";

import {
  validateDnsIP4Address,
  validateNameserver
} from "../../../../../common/validationRules";
import {
  NAMESERVERS_DEFAULT,
  NAMESERVERS_CUSTOM,
  NAMESERVERS_CHILD
} from "./NameserverTypes";

const StyledNameserversWrapper = styled.div`
  padding-top: 20px;
`;

const StyledNameserversOption = styled.div`
  margin-bottom: 20px;
`;

const StyledNameserversOptionContent = styled.div`
  padding: 15px;
  padding-left: 25px;

  & label {
    margin-right: 20px;
  }
`;

const StyledRequiredFieldAsteriks = styled.span`
  color: ${theme.redBase};
`;

const StyledNameserverAddress = styled.p`
  display: inline-block;
  font-size: 16px;
  line-height: 1.38;
  font-weight: 400;
  color: ${theme.neutralBase};
`;

const StyledNameserversOptionContentBrowser = styled.div`
  & label {
    margin-right: 0px;
    margin-top: 9px;
  }
`;

const StyledIpAddressWrapper = styled.div`
  width: ${props => (props.isMobile ? "100%" : "50%")};
`;

const formatNameservers = nameservers => {
  let ns1 = nameservers.list["ns1"] || "";
  let ip_address_1 = "";
  if (ns1 !== "") {
    ip_address_1 = ns1.split(/[- ]+/).pop();
    ns1 = nameservers.list["ns1"].slice(
      0,
      nameservers.list["ns1"].length - ip_address_1.length - 1
    );

    if (validateDnsIP4Address(ip_address_1) !== undefined) {
      ip_address_1 = "";
      ns1 = nameservers.list["ns1"];
    }
  }

  let ns2 = nameservers.list["ns2"] || "";
  let ip_address_2 = "";
  if (ns2 !== "") {
    ip_address_2 = ns2.split(/[- ]+/).pop();
    ns2 = nameservers.list["ns2"].slice(
      0,
      nameservers.list["ns2"].length - ip_address_2.length - 1
    );
    if (validateDnsIP4Address(ip_address_2) !== undefined) {
      ip_address_2 = "";
      ns2 = nameservers.list["ns2"];
    }
  }

  let ns3 = nameservers.list["ns3"] || "";
  let ip_address_3 = "";
  if (ns3 !== "") {
    ip_address_3 = ns3.split(/[- ]+/).pop();
    ns3 = nameservers.list["ns3"].slice(
      0,
      nameservers.list["ns3"].length - ip_address_3.length - 1
    );
    if (validateDnsIP4Address(ip_address_3) !== undefined) {
      ip_address_3 = "";
      ns3 = nameservers.list["ns3"];
    }
  }

  let ns4 = nameservers.list["ns4"] || "";
  let ip_address_4 = "";
  if (ns4 !== "") {
    ip_address_4 = ns4.split(/[- ]+/).pop();
    ns4 = nameservers.list["ns4"].slice(
      0,
      nameservers.list["ns4"].length - ip_address_4.length - 1
    );
    if (validateDnsIP4Address(ip_address_4) !== undefined) {
      ip_address_4 = "";
      ns4 = nameservers.list["ns4"];
    }
  }

  //let ns5 = nameservers.list["ns5"] || "";
  //let ip_address_5 = "";
  //if (ns5 !== "") {
  //  ip_address_5 = ns5.split(/[- ]+/).pop();
  //  ns5 = nameservers.list["ns5"].slice(
  //    0,
  //    nameservers.list["ns5"].length - ip_address_5.length - 1
  //  );
  //  if (validateDnsIP4Address(ip_address_5) !== undefined) {
  //    ip_address_5 = "";
  //    ns5 = nameservers.list["ns5"];
  //  }
  //}

  return {
    ns1,
    ns2,
    ns3,
    ns4,
    //ns5,
    ip_address_1,
    ip_address_2,
    ip_address_3,
    ip_address_4
    //ip_address_5
  };
};

class Nameservers extends React.Component {
  validateNameservers = values => {
    return {
      ns1: validateNameserver(values.ns1, true),
      ns2: validateNameserver(values.ns2, true),
      ns3: validateNameserver(values.ns3),
      ns4: validateNameserver(values.ns4)
      //ns5: validateNameserver(values.ns5)
    };
  };

  onSaveNameservers = () => {
    document
      .getElementById(`domain-nameservers-form-${this.props.domain.domain}`)
      .dispatchEvent(new Event("submit", { cancelable: true }));
  };

  render() {
    const {
      domain,
      nameservers,
      onSaveNameservers,
      onSubmittingDomainNameservers,
      submittingDomainNameservers,
      invalidDomainNameservers,
      isLaptop,
      onNameServersKeyPress
    } = this.props;
    return (
      <Translate>
        {({ translate }) => (
          <Form
            onSubmit={onSaveNameservers}
            validate={this.validateNameservers}
            initialValues={domain.nameservers || {}}
            render={({ handleSubmit }) => (
              <StyledNameserversWrapper>
                <form
                  id={`domain-nameservers-form-${domain.domain}`}
                  onSubmit={handleSubmit}
                >
                  <div className="row">
                    <StyledNameserversOption className="col-12 col-sm-12 col-md-12">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12">
                          {(isLaptop || isMobile) && (
                            <StyledNameserversOptionContent className="row">
                              <div className="col-12 col-sm-12 col-md-12">
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-12">
                                    <Label>
                                      {translate("domain.settings.nameserver")}{" "}
                                      1
                                      <StyledRequiredFieldAsteriks>
                                        *
                                      </StyledRequiredFieldAsteriks>
                                    </Label>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-12">
                                    <Field
                                      component={InputField}
                                      name="ns1"
                                      placeholder={`${translate(
                                        "domain.settings.nameserver"
                                      )} 1`}
                                      onBlur={() => this.onSaveNameservers()}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-12">
                                    <Label>
                                      {translate("domain.settings.nameserver")}{" "}
                                      2
                                      <StyledRequiredFieldAsteriks>
                                        *
                                      </StyledRequiredFieldAsteriks>
                                    </Label>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-12">
                                    <Field
                                      component={InputField}
                                      name="ns2"
                                      placeholder={`${translate(
                                        "domain.settings.nameserver"
                                      )} 2`}
                                      onBlur={() => this.onSaveNameservers()}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-12">
                                    <Label>
                                      {translate("domain.settings.nameserver")}{" "}
                                      3
                                    </Label>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-12">
                                    <Field
                                      component={InputField}
                                      name="ns3"
                                      placeholder={`${translate(
                                        "domain.settings.nameserver"
                                      )} 3`}
                                      onBlur={() => this.onSaveNameservers()}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-12">
                                    <Label>
                                      {translate("domain.settings.nameserver")}{" "}
                                      4
                                    </Label>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-12">
                                    <Field
                                      component={InputField}
                                      name="ns4"
                                      placeholder={`${translate(
                                        "domain.settings.nameserver"
                                      )} 4`}
                                      onBlur={() => this.onSaveNameservers()}
                                    />
                                  </div>
                                </div>
                              </div>
                            </StyledNameserversOptionContent>
                          )}
                          {!isLaptop && !isMobile && isBrowser && (
                            <StyledNameserversOptionContentBrowser className="row">
                              <div className="col-12 col-sm-12 col-md-12">
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-4">
                                    <Label>
                                      {translate("domain.settings.nameserver")}{" "}
                                      1
                                      <StyledRequiredFieldAsteriks>
                                        *
                                      </StyledRequiredFieldAsteriks>
                                    </Label>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      component={InputField}
                                      name="ns1"
                                      placeholder={`${translate(
                                        "domain.settings.nameserver"
                                      )} 1`}
                                      onBlur={() => this.onSaveNameservers()}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-4">
                                    <Label>
                                      {translate("domain.settings.nameserver")}{" "}
                                      2
                                      <StyledRequiredFieldAsteriks>
                                        *
                                      </StyledRequiredFieldAsteriks>
                                    </Label>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      component={InputField}
                                      name="ns2"
                                      placeholder={`${translate(
                                        "domain.settings.nameserver"
                                      )} 2`}
                                      onBlur={() => this.onSaveNameservers()}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-4">
                                    <Label>
                                      {translate("domain.settings.nameserver")}{" "}
                                      3
                                    </Label>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      component={InputField}
                                      name="ns3"
                                      placeholder={`${translate(
                                        "domain.settings.nameserver"
                                      )} 3`}
                                      onBlur={() => this.onSaveNameservers()}
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-sm-12 col-md-4">
                                    <Label>
                                      {translate("domain.settings.nameserver")}{" "}
                                      4
                                    </Label>
                                  </div>
                                  <div className="col-12 col-sm-12 col-md-8">
                                    <Field
                                      component={InputField}
                                      name="ns4"
                                      placeholder={`${translate(
                                        "domain.settings.nameserver"
                                      )} 4`}
                                      onBlur={() => this.onSaveNameservers()}
                                    />
                                  </div>
                                </div>
                              </div>
                            </StyledNameserversOptionContentBrowser>
                          )}
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 text-right">
                          <PrimaryButton
                            type="submit"
                            disabled={
                              submittingDomainNameservers ||
                              invalidDomainNameservers
                            }
                            submitting={submittingDomainNameservers}
                            onClick={e => this.onSaveNameservers()}
                            key="save-ns"
                          >
                            {translate("domain.settings.save-changes")}
                          </PrimaryButton>
                        </div>
                      </div>
                    </StyledNameserversOption>
                  </div>
                  <FormSpy
                    subscription={{ submitting: true, invalid: true }}
                    onChange={formstate =>
                      onSubmittingDomainNameservers({ ...formstate })
                    }
                  />
                </form>
              </StyledNameserversWrapper>
            )}
          />
        )}
      </Translate>
    );
  }
}

export default withSizes(isLaptop)(Nameservers);
