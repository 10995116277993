import styled from "styled-components";
import { theme } from "../../../design/Themes";

import StatusText from "../StatusText";

const StatusTextWarning = styled(StatusText)`
  color: ${theme.yellow2};
`;

export default StatusTextWarning;
