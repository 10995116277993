import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";

const StyledAmountBadge = styled.div`
  text-align: ${props => (props.textAlign ? props.textAlign : "right")};

  & .amount-badge-currency {
    font-size: 16px;
    margin-right: 5px;
    color: ${theme.neutral4};
    text-transform: uppercase;
  }

  & .amount-badge-amount {
    font-weight: 600;
    font-size: 16px;
    color: ${theme.neutralBase};
  }
`;

const AmountBadge = props => (
  <StyledAmountBadge className={props.className} textAlign={props.textAlign}>
    <span className="amount-badge-currency">{props.currency}</span>
    <span className="amount-badge-amount">
      {props.amount && props.currency.toLowerCase() !== "мкд"
        ? parseFloat(props.amount).toFixed(2)
        : parseFloat(props.amount).toFixed(0)}
    </span>
  </StyledAmountBadge>
);

AmountBadge.defaultProps = {
  currency: "МКД",
  amount: "0",
  textAlign: "right"
};

export default AmountBadge;
