import axios from "axios";
import { sprintf } from "sprintf-js";

import {
  PROINVOICES_API_ENDPOINT,
  PROINVOICES_FILTER_STATUS_COLLECTION_API_ENDPOINT,
  PROINVOICES_DOWNLOAD_PROINVOICE_API_ENDPOINT,
  PROINVOICES_APPLY_CREDIT_API_ENDPOINT,
  PROINVOICES_FORWARD_PROINVOICE,
  PROINVOICES_UPDATE_PROINVOICE,
  PROINVOICES_ASSIGN_PROINVOICE_PAYER,
  PROINVOICES_MASS_PAY_ENDPOINT,
  PROINVOICES_CANCEL_PROINVOICE_API_ENDPOINT
} from "./endpoints";

export function all(limit, page, sort, filters) {
  return axios.get(PROINVOICES_API_ENDPOINT, {
    params: {
      limit: limit ? limit : 10,
      page: page ? page : 1,
      sort_by: sort ? sort.sort_by : "",
      order_by: sort ? sort.order_by : "",
      ...filters
    }
  });
}

export function fetchProInvoiceFilterStatusCollection() {
  return axios.get(PROINVOICES_FILTER_STATUS_COLLECTION_API_ENDPOINT, {});
}

export function fetchProInvoice(id) {
  return axios.get(`${PROINVOICES_API_ENDPOINT}/${id}`);
}

export function downloadProInvoice(id) {
  return axios.get(
    sprintf(PROINVOICES_DOWNLOAD_PROINVOICE_API_ENDPOINT, { id }),
    {
      responseType: "arraybuffer",
      headers: {
        Accept: "application/pdf"
      }
    }
  );
}

export function applyCreditToProInvoice(proinvoice, amount) {
  return axios.post(
    sprintf(PROINVOICES_APPLY_CREDIT_API_ENDPOINT, proinvoice),
    {
      credit_amount: amount
    }
  );
}

export function forwardProInvoice(proinvoice, email, message = "") {
  return axios.post(sprintf(PROINVOICES_FORWARD_PROINVOICE, proinvoice), {
    forward_to_email: email,
    message
  });
}

export function updateProInvoicePaymentMethod(proinvoice, paymentMethod) {
  return axios.put(sprintf(PROINVOICES_UPDATE_PROINVOICE, proinvoice), {
    payment_method: paymentMethod
  });
}

export function massPay(invoiceIds) {
  return axios.put(PROINVOICES_MASS_PAY_ENDPOINT, {
    invoices: invoiceIds
  });
}

export function payProInvoice(proinvoice, paymentGateway) {
  return axios.post(
    paymentGateway.processUrl,
    {
      ...paymentGateway
    },
    {
      headers: { "Content-Type": "multipart/form-data" }
    }
  );
}

export function assignProInvoicePayer(proinvoice, payer) {
  return axios.post(PROINVOICES_ASSIGN_PROINVOICE_PAYER, {
    invoice_id: proinvoice.id,
    payer_id: payer.id,
    ...payer
  });
}

export function cancelProInvoice(proinvoice) {
  return axios.put(
    sprintf(PROINVOICES_CANCEL_PROINVOICE_API_ENDPOINT, { id: proinvoice.id })
  );
}
