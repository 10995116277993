import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { Translate } from "react-localize-redux";

import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";
import CompactCartProductAddon from "./CompactCartProductAddon";

const StyledCompactCartProduct = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  padding: 10px 0;
`;

const StyledProductType = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: ${theme.neutralBase};
  margin-right: 50px;
`;
const StyledProductTitle = styled.span`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
`;

const CompactCartProduct = ({ product }) => {

  let firstYearDerivation = null;
  let possiblePeriodNumericCodes = [0.1, 0.3, 0.5, 1, 2, 3];

  // const firstYearDerivation = product.item.pricing.buy.billing_cycle.derivations.find(
  //   d => d.numeric_code === 1
  // );

  // Temporary solution, should be sync with api derivation numeric codes
  possiblePeriodNumericCodes.some(numericCode => {
    firstYearDerivation = product.item.pricing.buy.billing_cycle.derivations.find(
      d => d.numeric_code === numericCode
    );

    if (typeof(firstYearDerivation) !== 'undefined') {
      console.log(numericCode);
      return true;
    }
  });
  // END

  const selectedYearDerivation = product.item.pricing.buy.billing_cycle.derivations.find(
    d => d.numeric_code === product.config.period
  );

  let selectedPeriodCode = typeof(selectedYearDerivation) !== 'undefined' ? selectedYearDerivation.code : 'monthly';

  return (
    <Translate>
      {({ translate }) => (
        <StyledCompactCartProduct>
          <div className="row">
            <div className="col-8 col-sm-8 col-md-8">
              <div className="row">
                <div className="col-12 col-sm-12 col-md-4">
                  <StyledProductType>{product.item.name}</StyledProductType>
                </div>
                <div className="col-12 col-sm-12 col-md-8">
                  <StyledProductTitle>
                    {product.domain} -{" "}
                    {product.config.period < 1 ? "" : product.config.period}
                    {product.config.period < 1
                      ? `${translate("shop.hostings.derivations." + selectedPeriodCode)}`
                      : product.config.period > 1
                        ? ` ${translate("cart.years")}`
                        : ` ${translate("cart.year")}`}
                  </StyledProductTitle>
                </div>
              </div>
            </div>
            <div className="col-4 col-sm-4 col-md-4 text-right">
              <DiscountedAmountBadge
                amount={{
                  currency:
                    product.item.pricing.buy.billing_cycle.derivations[0].price
                      .currency.code,
                  amount: product.total,
                  discountedAmount:
                    selectedYearDerivation.numeric_code < 1
                      ? null
                      : firstYearDerivation.numeric_code !==
                        selectedYearDerivation.numeric_code
                        ? firstYearDerivation.price.value *
                          selectedYearDerivation.numeric_code
                        : null
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12" />
          </div>
          {product.item.services &&
            product.item.services.length > 0 &&
            product.item.services
              .filter(service => service.activated)
              .map(service => (
                <CompactCartProductAddon
                  key={`compact-cart-product-service-${service.id}`}
                  service={service}
                />
              ))}
        </StyledCompactCartProduct>
      )}
    </Translate>
  );
};

CompactCartProduct.defaultProps = {
  product: "Product",
  price: {
    currency: "МКД",
    amount: 1000
  },
  addons: null
};

export default CompactCartProduct;
