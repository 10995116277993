import React from "react";
import styled from "styled-components";
import { theme } from "../../../../../design/Themes";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";

import Container from "../../../../../components/Containers/Container";
import DetailsPanelItem from "../../../../../components/DetailsPanel/DetailsPanelItem";
import NoticeInfo from "../../../../../components/Notices/NoticeInfo";
import CustomTooltip from "../../../../../components/CustomTooltip";

import {
  compileRoute,
  ROUTE_CREATE_TICKET_WITH_PARAMS,
  ROUTE_VIEW_DOMAIN_SETTINGS
} from "../../../../../routes/routes";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";

const StyledHeader = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  padding-bottom: 15px;
`;

const StyledContent = styled.div`
  & .details-panel-item-label {
    margin-top: 10px;
  }
`;

const StyledNoticeContent = styled.div`
  padding: 15px;
`;

const StyledTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  text-transform: uppercase;
  color: ${theme.neutral5};
`;

const StyledManageNameservers = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.2px;
  color: ${theme.blueBase};
  text-transform: none;
`;

const MAX_NS = 5;

const fillRestOfNameservers = nameservers => {
  if (!nameservers || !nameservers.list) {
    return null;
  }
  const numOfNameservers = Object.keys(nameservers.list).length;
  const emptyNameservers = MAX_NS - numOfNameservers;
  let list = [];
  if (emptyNameservers) {
    for (let i = numOfNameservers + 1; i <= MAX_NS; i++) {
      list.push({ nameserver: i, value: i });
    }
    return list;
  }
  return null;
};

const Nameservers = ({ domain }) => {
  const restNameservers = fillRestOfNameservers(domain.name_servers);

  return (
    <Translate>
      {({ translate }) => (
        <Container>
          <StyledHeader>
            <StyledTitle>
              <div className="row">
                <div className="col-6 col-sm-6 col-md-6">
                  {translate("domain.general-info.nameservers")}
                </div>
                <div className="col-6 col-sm-6 col-md-6 text-right">
                  { ( !domain.domain_name.endsWith(".al") && !domain.domain_name.endsWith(".rs") ) ? (
                      <StyledManageNameservers
                        to={compileRoute(ROUTE_VIEW_DOMAIN_SETTINGS)({
                          id: domain.id,
                          name: domain.domain_name
                        })}
                      >
                        {translate("domain.general-info.manage-nameservers")}
                      </StyledManageNameservers>
                    ) : (
                      <CustomTooltip
                        trigger={["hover"]}
                        content={translate("domain.general-info.update-dns-for-al-rs-tlds-open-ticket-info")}
                      >
                        <StyledManageNameservers
                          to={compileRoute(ROUTE_CREATE_TICKET_WITH_PARAMS)({id: 12})}
                        >
                          {translate("help-desk.ticket.open-new-ticket")}
                        </StyledManageNameservers>
                      </CustomTooltip>
                    )}
                </div>
              </div>
            </StyledTitle>
          </StyledHeader>
          { ( domain.domain_name.endsWith(".al") || domain.domain_name.endsWith(".rs") && false ) ? (
            <StyledNoticeContent>
              <NoticeInfo>
                {translate(
                  "domain.general-info.unavailable-for-al-and-rs-tlds"
                )}
                <div className="d-flex text-right pt-3">
                  <Link to={compileRoute(ROUTE_CREATE_TICKET_WITH_PARAMS)({id: 12})} className="ml-auto">
                    <SecondaryButton>
                      {translate("help-desk.ticket.open-new-ticket")}
                    </SecondaryButton>
                  </Link>
                </div>
              </NoticeInfo>
            </StyledNoticeContent>
          ) : (
            <StyledContent>
              {domain.name_servers &&
                domain.name_servers.list &&
                Object.keys(domain.name_servers.list).map(
                  (nameserver, index) => (
                    <DetailsPanelItem
                      striped={true}
                      key={`nameserver-${nameserver}`}
                      label={`${translate(
                        "domain.settings.nameserver"
                      )} ${index + 1}`}
                    >
                      {domain.name_servers.list[nameserver]}
                    </DetailsPanelItem>
                  )
                )}
              {restNameservers &&
                restNameservers.map(nameserver => (
                  <DetailsPanelItem
                    striped={true}
                    key={`nameserver-${nameserver.nameserver}`}
                    label={`${translate("domain.settings.nameserver")} ${
                      nameserver.nameserver
                    }`}
                  />
                ))}
            </StyledContent>
          )}
        </Container>
      )}
    </Translate>
  );
};
export default Nameservers;
