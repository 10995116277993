import React from "react";

import PrimaryButton from "../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../components/Buttons/SecondaryButton";
import GhostButton from "../../components/Buttons/GhostButton";
import PrimaryWarningButton from "../../components/Buttons/PrimaryWarningButton";
import SecondaryWarningButton from "../../components/Buttons/SecondaryWarningButton";
import ButtonGroup from "../../components/ButtonGroup";
import ButtonGroupDropdown from "../../components/ButtonGroupDropdown";
import DropdownButton from "../../components/DropdownButton";
import DropdownItem from "../../components/DropdownItem";
import DropdownItemDescription from "../../components/DropdownItemDescription";
import Container from "../../components/Containers/Container";
import ContainerFlag from "../../components/Containers/ContainerFlag";
import ContainerInlineDialog from "../../components/Containers/ContainerInlineDialog";
import ContainerModal from "../../components/Containers/ContainerModal";
import ContainerTooltip from "../../components/Containers/ContainerTooltip";
//import Modal from "../../components/Modal";
import Icon from "../../components/Icon";
import InputIcon from "../../components/InputIcon";
import InputField from "../../components/InputField";
//import FileInput from "../../components/FileInput";
import TextArea from "../../components/TextArea";
import Form from "../../components/Forms/Form";
import SearchField from "../../components/SearchField";
import Checkbox from "../../components/Checkbox";
import Radiobox from "../../components/Radiobox";
import Togglebox from "../../components/Togglebox";
import Badge from "../../components/Badges/Badge";
import BadgeSuccess from "../../components/Badges/BadgeSuccess";
import BadgeWarning from "../../components/Badges/BadgeWarning";
import BadgeDanger from "../../components/Badges/BadgeDanger";
import BadgeInfo from "../../components/Badges/BadgeInfo";
import NoticeInfo from "../../components/Notices/NoticeInfo";
import NoticeSuccess from "../../components/Notices/NoticeSuccess";
import NoticeWarning from "../../components/Notices/NoticeWarning";
import NoticeDanger from "../../components/Notices/NoticeDanger";
import FlagNormalInfo from "../../components/Flags/FlagNormalInfo";
import FlagNormalSuccess from "../../components/Flags/FlagNormalSuccess";
import FlagNormalWarning from "../../components/Flags/FlagNormalWarning";
import FlagNormalDanger from "../../components/Flags/FlagNormalDanger";
import FlagDanger from "../../components/Flags/FlagDanger";
import Navigation from "../../components/Navigation";
import Sidebar from "../../components/Sidebar";

import Table from "../../components/Table";
import TableHeader from "../../components/Table/TableHeader";
import TableHeading from "../../components/Table/TableHeading";
import TableBody from "../../components/Table/TableBody";
import TableRow from "../../components/Table/TableRow";
import TableCell from "../../components/Table/TableCell";

import "./Guideline.css";

class Guideline extends React.Component {
  render() {
    return (
      <div className="components-guideline">
        <h3 className="text-left">Buttons</h3>

        <div className="row g-row">
          <div className="col">
            <PrimaryButton>Primary</PrimaryButton>
            <SecondaryButton>Secondary</SecondaryButton>
            <GhostButton>Ghost</GhostButton>
            <PrimaryWarningButton>Primary Warning</PrimaryWarningButton>
            <SecondaryWarningButton>Secondary Warning</SecondaryWarningButton>
            <PrimaryButton disabled>Primary</PrimaryButton>
          </div>
        </div>

        <div className="row g-row">
          <div className="col">
            <PrimaryButton>
              <Icon icon="add" /> Primary
            </PrimaryButton>
            <SecondaryButton>
              <Icon icon="add" /> Secondary
            </SecondaryButton>
            <GhostButton>
              <Icon icon="add" /> Ghost
            </GhostButton>
            <PrimaryWarningButton>
              <Icon icon="add" /> Primary Warning
            </PrimaryWarningButton>
            <SecondaryWarningButton>
              <Icon icon="add" /> Secondary Warning
            </SecondaryWarningButton>
          </div>
        </div>

        <h3 className="text-left">Button Groups</h3>
        <div className="row g-row">
          <div className="col">
            <ButtonGroup className="btn-group">
              <SecondaryButton size="s" className="btn" type="button">
                Left
              </SecondaryButton>
              <SecondaryButton size="s" className="btn" type="button">
                Middle
              </SecondaryButton>
              <SecondaryButton size="s" className="btn">
                Right
              </SecondaryButton>
            </ButtonGroup>
          </div>
          <div className="col">
            <PrimaryButton>Primary</PrimaryButton>
            <SecondaryButton>
              <Icon icon="settings" />
            </SecondaryButton>
          </div>
        </div>
        <div className="row g-row">
          <div className="col">
            <ButtonGroup className="btn-group">
              <SecondaryButton size="s">Left</SecondaryButton>
              <SecondaryButton size="s">Middle</SecondaryButton>

              <ButtonGroupDropdown size="s" text="">
                <DropdownItem>Dropdown link</DropdownItem>
                <DropdownItem>Dropdown link</DropdownItem>
              </ButtonGroupDropdown>
            </ButtonGroup>
          </div>

          <div className="col">
            <ButtonGroup className="btn-group">
              <SecondaryButton size="s">Action options</SecondaryButton>
              <ButtonGroupDropdown size="s" text="">
                <DropdownItem>Dropdown link</DropdownItem>
                <DropdownItem>Dropdown link</DropdownItem>
              </ButtonGroupDropdown>
            </ButtonGroup>
          </div>
        </div>
        <div className="row g-row">
          <div className="col">
            <ButtonGroup className="btn-group">
              <ButtonGroupDropdown size="s" text="">
                <DropdownItem>Dropdown link</DropdownItem>
                <DropdownItem>Dropdown link</DropdownItem>
              </ButtonGroupDropdown>
              <SecondaryButton size="s">Middle</SecondaryButton>
              <SecondaryButton size="s">Right</SecondaryButton>
            </ButtonGroup>
          </div>
        </div>
        <div className="row g-row">
          <div className="col">
            <ButtonGroup className="btn-group">
              <SecondaryButton size="s" className="btn" type="button">
                Left
              </SecondaryButton>
              <PrimaryButton size="s" className="btn" type="button">
                Selected
              </PrimaryButton>
              <SecondaryButton disabled size="s" className="btn">
                Disabled
              </SecondaryButton>
              <SecondaryButton size="s" className="btn">
                Right
              </SecondaryButton>
            </ButtonGroup>
          </div>
          <div className="col">
            <PrimaryButton>Primary</PrimaryButton>
            <DropdownButton text="Dropdown">
              <DropdownItem>
                A
                <DropdownItemDescription>
                  Lorem ipsum sin dolor amet...
                </DropdownItemDescription>
              </DropdownItem>
              <DropdownItem>B</DropdownItem>
              <DropdownItem>C</DropdownItem>
            </DropdownButton>
          </div>
        </div>

        <h3 className="text-left">Button Sizes</h3>

        <div className="row g-row">
          <div className="col">
            <PrimaryButton size="s">Button small</PrimaryButton>
            <PrimaryButton size="m">Button medium</PrimaryButton>
            <PrimaryButton size="l">Button large</PrimaryButton>
          </div>
          <div className="col" />
          <div className="col" />
        </div>

        <div className="row g-row">
          <div className="col">
            <PrimaryButton size="s">
              <Icon icon="add" /> Button small
            </PrimaryButton>
            <PrimaryButton size="m">
              <Icon icon="add" /> Button medium
            </PrimaryButton>
            <PrimaryButton size="l">
              <Icon icon="add" /> Button large
            </PrimaryButton>
          </div>
          <div className="col" />
          <div className="col" />
        </div>

        <h3>Containers</h3>
        <div className="row g-row">
          <div className="col">
            <Container
              style={{ padding: "30px 50px" }}
              width="300px"
              height="200px"
            >
              Container
            </Container>
          </div>
          <div className="col">
            <ContainerInlineDialog
              style={{ padding: "30px 50px" }}
              width="300px"
              height="200px"
            >
              Container Inline Dialog
            </ContainerInlineDialog>
          </div>
          <div className="col">
            <ContainerModal
              style={{ padding: "30px 50px" }}
              width="300px"
              height="200px"
            >
              Container Modal
            </ContainerModal>
          </div>
          <div className="col">
            <ContainerFlag style={{ padding: "30px 50px" }}>
              Container Flag
            </ContainerFlag>
          </div>
          <div className="col">
            <ContainerTooltip
              style={{ padding: "30px 50px" }}
              width="300px"
              height="200px"
            >
              Container Tooltip
            </ContainerTooltip>
          </div>
        </div>

        <h3>Modals</h3>
        <div className="row g-row">
          <div className="col"></div>
        </div>
        <div className="row g-row">
          <div className="col"></div>
          <div className="col"></div>
        </div>
        <div className="row g-row">
          <div className="col"></div>
        </div>

        <h3>Icons</h3>
        <h4>Icon Sizes</h4>
        <div className="row g-row">
          <div className="col-1">
            <Icon icon="message" size="s" />
            <p>Small 20x20</p>
          </div>
          <div className="col-1">
            <Icon icon="message" size="m" />
            <p>Medium 24x24</p>
          </div>
          <div className="col-1">
            <Icon icon="message" size="l" />
            <p>Large 30x30</p>
          </div>
        </div>

        <h4>UI Icons</h4>
        <div className="row g-row">
          <div className="col-1">
            <Icon icon="add_user" />
          </div>
          <div className="col-1">
            <Icon icon="add" />
          </div>
          <div className="col-1">
            <Icon icon="billing" />
          </div>
          <div className="col-1">
            <Icon icon="cart" />
          </div>
          <div className="col-1">
            <Icon icon="arrow g-row_right" />
          </div>
          <div className="col-1">
            <Icon icon="chevron_down" />
          </div>
          <div className="col-1">
            <Icon icon="chevron_left" />
          </div>
          <div className="col-1">
            <Icon icon="chevron_right" />
          </div>
          <div className="col-1">
            <Icon icon="chevron_up" />
          </div>
          <div className="col-1">
            <Icon icon="clear" />
          </div>
          <div className="col-1">
            <Icon icon="close" />
          </div>
          <div className="col-1">
            <Icon icon="company" />
          </div>
          <div className="col-1">
            <Icon icon="contacts" />
          </div>
          <div className="col-1">
            <Icon icon="cpanel" />
          </div>
          <div className="col-1">
            <Icon icon="delete" />
          </div>
          <div className="col-1">
            <Icon icon="domains" />
          </div>
          <div className="col-1">
            <Icon icon="dots_horizontal" />
          </div>
          <div className="col-1">
            <Icon icon="edit" />
          </div>
          <div className="col-1">
            <Icon icon="email" />
          </div>
          <div className="col-1">
            <Icon icon="files" />
          </div>
          <div className="col-1">
            <Icon icon="help_desk" />
          </div>
          <div className="col-1">
            <Icon icon="home" />
          </div>
          <div className="col-1">
            <Icon icon="hosting" />
          </div>
          <div className="col-1">
            <Icon icon="info" />
          </div>
          <div className="col-1">
            <Icon icon="lock" />
          </div>
          <div className="col-1">
            <Icon icon="message" />
          </div>
          <div className="col-1">
            <Icon icon="notification" />
          </div>
          <div className="col-1">
            <Icon icon="paid" />
          </div>
          <div className="col-1">
            <Icon icon="print" />
          </div>
          <div className="col-1">
            <Icon icon="privacy" />
          </div>
          <div className="col-1">
            <Icon icon="refunded" />
          </div>
          <div className="col-1">
            <Icon icon="reply" />
          </div>
          <div className="col-1">
            <Icon icon="search" />
          </div>
          <div className="col-1">
            <Icon icon="settings" />
          </div>
          <div className="col-1">
            <Icon icon="setup" />
          </div>
          <div className="col-1">
            <Icon icon="slash" />
          </div>
          <div className="col-1">
            <Icon icon="success" />
          </div>
          <div className="col-1">
            <Icon icon="unpaid" />
          </div>
          <div className="col-1">
            <Icon icon="user" />
          </div>
          <div className="col-1">
            <Icon icon="warning" />
          </div>
          <div className="col-1">
            <Icon icon="websites" />
          </div>
        </div>

        <h4>Input Icons</h4>
        <div className="row g-row">
          <div style={{ position: "relative" }}>
            <div className="col-1">
              <InputIcon icon="input-indeterminate" />
            </div>
            <div className="col-1">
              <InputIcon icon="input-radio" />
            </div>
            <div className="col-1">
              <InputIcon icon="input-checkbox" />
            </div>
          </div>
        </div>

        <h3>Forms</h3>
        <div className="row g-row">
          <div className="col-6"></div>
        </div>
        <div className="row g-row">
          <div className="col-6">
            <TextArea
              label="Text area"
              placeholder="Placeholder text"
              helpText="Note text"
            />
          </div>
        </div>
        <div className="row g-row">
          <div className="col-3">
            <Form noValidate className="needs-validation">
              <InputField
                type="text"
                label="Default field"
                placeholder="Placeholder text"
                helpText="Note text"
              />
            </Form>
          </div>

          <div className="col-3">
            <InputField
              type="text"
              label="Label icon field"
              placeholder="Placeholder text"
              labelIcon="info"
            />
          </div>

          <div className="col-3">
            <InputField
              type="text"
              label="Disabled"
              placeholder="Placeholder text"
              disabled
            />
          </div>

          <div className="col-3">
            <InputField
              type="text"
              label="Read Only field"
              placeholder="Placeholder text"
              readOnly
            />
          </div>
        </div>
        <div className="row g-row">
          <div className="col">
            <div className="col-3">
              <InputField
                type="text"
                label="Success field"
                placeholder="Placeholder text"
                helpText="Note text"
                valid
              />
            </div>
            <div className="col-3">
              <InputField
                type="text"
                label="Error field"
                placeholder="Placeholder text"
                helpText="Note text"
                invalid
                required
              />
            </div>
            <div className="col-3">
              <SearchField placeholder="Search..." />
            </div>
          </div>
        </div>

        <h3>Checkboxes</h3>
        <div className="row g-row">
          <div className="col-2">
            <Checkbox>Default</Checkbox>
          </div>
          <div className="col-2">
            <Checkbox checked>Checked</Checkbox>
          </div>
          <div className="col-2">
            <Checkbox disabled>Disabled</Checkbox>
          </div>
          <div className="col-2">
            <Checkbox checked disabled>
              Checked & Disabled
            </Checkbox>
          </div>
          <div className="col-2">
            <Checkbox indeterminate="true">Indeterminate</Checkbox>
          </div>
        </div>

        <h3>Radioboxes</h3>
        <div className="row g-row">
          <div className="col-2">
            <Radiobox>Default</Radiobox>
          </div>
          <div className="col-2">
            <Radiobox checked>Checked</Radiobox>
          </div>
          <div className="col-2">
            <Radiobox disabled>Disabled</Radiobox>
          </div>
          <div className="col-2">
            <Radiobox checked disabled>
              Checked & Disabled
            </Radiobox>
          </div>
        </div>

        <h3>Toggle</h3>
        <div className="row g-row">
          <div className="col-2">
            <Togglebox toggled />
          </div>
          <div className="col-2">
            <Togglebox />
          </div>

          <div className="col-2">
            <Togglebox toggled disabled />
          </div>

          <div className="col-2">
            <Togglebox disabled />
          </div>
        </div>

        <h3>Tags (Badges</h3>
        <div className="row g-row">
          <div className="col-1">
            <Badge>tag-neutral</Badge>
          </div>
          <div className="col-1">
            <BadgeSuccess>tag-success</BadgeSuccess>
          </div>
          <div className="col-1">
            <BadgeWarning>tag-success</BadgeWarning>
          </div>
          <div className="col-1">
            <BadgeDanger>tag-success</BadgeDanger>
          </div>
          <div className="col-1">
            <BadgeInfo>tag-info</BadgeInfo>
          </div>
        </div>

        <h3>Information box</h3>
        <div className="row g-row">
          <div className="col-3">
            <NoticeInfo icon="lock">
              You must set the default nameservers in order to transfer the
              domain to another registrar.
            </NoticeInfo>
          </div>
        </div>
        <div className="row g-row">
          <div className="col-3">
            <NoticeInfo icon="info">
              You cannot redirect the domain while it’s locked.
            </NoticeInfo>
          </div>
        </div>
        <div className="row g-row">
          <div className="col-3">
            <NoticeSuccess dismissable icon="success">
              Your changes have been saved. <a href="/">View Invoice</a>.
            </NoticeSuccess>
          </div>
        </div>
        <div className="row g-row">
          <div className="col-3">
            <NoticeWarning icon="warning">
              Your product is pending transfer.
            </NoticeWarning>
          </div>
        </div>
        <div className="row g-row">
          <div className="col-3">
            <NoticeDanger dismissable icon="clear">
              Changes could not be saved. <a href="/">Retry</a>.
            </NoticeDanger>
          </div>
        </div>

        <h3>Flags</h3>
        <div className="row g-row">
          <div className="col-3">
            <FlagNormalInfo title="Pro-invoice sent">
              Your pro-invoice should be delivered within 10 minutes.
            </FlagNormalInfo>
          </div>
        </div>
        <div className="row g-row">
          <div className="col-3">
            <FlagNormalSuccess title="Added to cart">
              Domain privacy for sanjazakovska.com
              <a href="/">View cart</a>
            </FlagNormalSuccess>
          </div>
        </div>

        <div className="row g-row">
          <div className="col-3">
            <FlagNormalWarning title="Addon will expire">
              This addon is not set to auto-renew and will expire soon.
            </FlagNormalWarning>
          </div>
        </div>

        <div className="row g-row">
          <div className="col-3">
            <FlagNormalDanger title="Cannot renew domain">
              This domain is set to auto-renew in 2 days, you cannot renew
              manually.
            </FlagNormalDanger>
          </div>
        </div>
        <div className="row g-row">
          <div className="col-3">
            <FlagDanger title="Our server is down">
              We are sorry for the incovinience, please check back later.
            </FlagDanger>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Navigation />
          </div>
        </div>

        <div className="row" style={{ position: "relative" }}>
          <div className="col">
            <Sidebar />
          </div>
        </div>

        <h3>Tables</h3>
        <div className="row">
          <div className="col">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHeading>Name</TableHeading>
                  <TableHeading>Last Name</TableHeading>
                  <TableHeading>Email</TableHeading>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow>
                  <TableCell>John</TableCell>
                  <TableCell>Doe</TableCell>
                  <TableCell>john@doe.com</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

export default Guideline;
