import React, { Component } from "react";
import { Translate } from "react-localize-redux";
import styled from "styled-components";
import { theme } from "../../../design/Themes";

import Container from "../../../components/Containers/Container/Container";
import Icon from "../../../components/Icon";
import { Link } from "react-router-dom";
import EmailVerificationLink from "../EmailVerificationLink/EmailVerificationLink";
import { isAuthenticated } from "../../Authentication/reducers/authReducer";
import { useSelector } from 'react-redux';

const LargeIcon = styled(Icon)`
  font-size: 100px;
  padding-bottom: 40px;
`;

const StyledSuccessContainer = styled(Container)`
  padding: 80px;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & .flex-container {
    display: flex;
    flex-direction: row;
    justify-content:center;
  }

  & img {
    margin-bottom: 30px;
    width: 120px;
    height: 120px;
  }

  & h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    color: ${theme.neutralBase};
    text-align: center;
    margin-bottom: 5px;
  }

  & p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    color: ${theme.neutral6};
    text-align: center;
    margin-bottom: 30px;
  }
};`

const StyledLink = styled.span`
  font-weight: 600;
  color: ${theme.blueBase};
  line-height: 1.43;
  letter-spacing: 0.2px;
  cursor: pointer;
  text-decoration: underline;
`;

function EmailVerificationError() {
  const isLoggedIn = useSelector(isAuthenticated);

  return (
    <Translate>
      {({ translate }) => (
        <div className="row">
          <div className="col-12 col-sm-12 col-md-6 offset-md-3">
            <StyledSuccessContainer>
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12 text-center justify-content-center">
                  <LargeIcon icon="warning" color="#fe9b34" />
                  <h4>{translate('email-verification.not-valid-or-expired-link')}</h4>
                  <div className="flex-container">
                    {isLoggedIn ? (
                      <EmailVerificationLink text={translate('email-verification.resend-link')} />
                    ) : (
                      <StyledLink>
                        <a href="/login">{translate('navigation.login')}</a>
                      </StyledLink>
                    )}
                    <span style={{ whiteSpace: 'pre' }}>
                      {' '}
                      {translate('email-verification.or')}{' '}
                    </span>
                    <StyledLink>
                      <a href="/my-account">{translate('email-verification.change-email')}</a>
                    </StyledLink>
                  </div>
                </div>
              </div>
            </StyledSuccessContainer>
          </div>
        </div>
      )}
    </Translate>
  );
}

export default EmailVerificationError;