import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Switch, Route } from "react-router-dom";
import { Translate, withLocalize } from "react-localize-redux";
import withSizes from "react-sizes";
import { MobileOnlyView, TabletView, BrowserView } from "react-device-detect";
import isLaptop from "../../../utils/isLaptopScreen";

import displayToastMessageForResponse from "../../../utils/displayToastMessageForResponse";

import Icon from "../../../components/Icon";
import Tabs from "../../../components/Tabs";
import Tab from "../../../components/Tabs/Tab";
import TabContent from "../../../components/Tabs/TabContent";

import ProductPlan from "./ProductPlan";
import Modal from "../../../components/Modal";
import PurchaseHosting from "../../../components/PurchaseHosting";

import {
  getProductsListingByGroup,
  isFetchingProducts
} from "../../Products/reducers/productsReducer";
import { fetchProductsByGroup } from "../../Products/actions/productsActions";

import ContentLoader from "../../../components/Loaders/ContentLoader";
import withLoading from "../../../components/Loaders/WithLoading";

import {
  addProductToCart,
  assignDomainToProduct,
  fetchCart
} from "../../Cart/actions/cartActions";
import {
  getCartId,
  getDomainsInCart,
  getCart
} from "../../Cart/reducers/cartReducer";

import {
  compileRoute,
  ROUTE_SHOP_HOSTING,
  ROUTE_CART,
  ROUTE_SHOP_HOSTING_PACKAGE
} from "../../../routes/routes";

import * as domainsService from "../../../services/domainsService";
import { validateDomain } from "../../../common/validationRules";

import { fetchDomainsWithoutHosting } from "../../Customer/actions/customerActions";
import { getDomainsWithoutHosting } from "../../Customer/reducers/customerReducer";
import { isAuthenticated } from "../../Authentication/reducers/authReducer";

import { PRODUCT_DOMAIN } from "../../Cart/Products/ProductTypes";

const StyledHostingWrapper = styled.div`
  margin-left: -30px;
  margin-right: -30px;
`;

const StyledHostingHeader = styled.div`
  background-color: ${theme.neutralBase};
  padding-top: 80px;
  padding-bottom: 80px;
`;

const StyledHeaderTitle = styled.h3`
  font-size: 30px;
  font-weight: 600;
  color: ${theme.white};
  text-transform: uppercase;
`;

const StyledSellingPoint = styled.span`
  color: ${theme.white};
  font-size: 16px;
  line-height: 1.38;
  display: block;
  margin-bottom: 7px;

  & > .icon {
    color: ${theme.greenBase};
    margin-right: 10px;
  }
`;

const StyledExtraSellingPoint = styled.span`
  color: ${theme.white};
  font-size: 16px;
  line-height: 1.38;
  font-weight: 600;
  margin-top: 20px;
`;

const StyledHostingContent = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const HostingContentWithLoading = withLoading(
  StyledHostingContent,
  ContentLoader
);

class Hosting extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showDomainModal: false,
      selectedProduct: null,
      assignedDomain: null,
      submitting: false,
      domainAddToCartSubmitting: false,
      searchingDomain: false,
      searchingDomainInput: null,
      domainAvailable: null,
      domainPrice: null,
      domainPriceCurrency: null,
      domainResultsAvailable: false,
      domainsWithoutHostingOptions: null,
      alreadyOwnedDomain: null,
      customDomain: "",
      customDomainValid: false,
      showAssignDomainToHosting: false,
      assignHosting: null,
      extensions: null,
      currency: null
    };

    this.isMountedRef = null;
  }

  setMountedRef = ref => (this.isMountedRef = ref);
  componentWillReceiveProps(nextProps) {
    if (nextProps.domainsWithoutHosting) {
      this.setState({
        domainsWithoutHostingOptions: this.remapDomainsWithoutHostingOptions()
      });
    }
  }

  componentDidMount() {
    if (!this.props.products.length) {
      this.props.fetchProductsByGroup().then(() => this.redirectToFirstTab());
    } else {
      this.redirectToFirstTab();
    }

    domainsService.fetchDomainsPrices().then(result => {
      const { tld } = result.data.data;

      let extensions = [];
      let currency =
        this.props.activeLanguage && this.props.activeLanguage === "en"
          ? "EUR"
          : "МКД";

      Object.keys(tld).map(ext => {
        extensions.push({ label: `.${ext}`, value: ext });
        return ext;
      });

      if (this.isMountedRef) {
        this.setState({
          currency,
          extensions
        });
      }
    });
    if (!this.props.domains.length) {
      this.props.fetchCart();
    }

    if (
      this.props.isAuthenticated &&
      !this.props.domainsWithoutHosting.length
    ) {
      this.props.fetchDomainsWithoutHosting();
    }
  }

  redirectToFirstTab = () => {
    if (
      this.props.products &&
      this.props.products.length > 0 &&
      this.props.location.pathname === ROUTE_SHOP_HOSTING
    ) {
      this.props.history.push(
        compileRoute(ROUTE_SHOP_HOSTING_PACKAGE)({
          name: this.props.products[0].name.replace(/\s+/g, "-").toLowerCase()
        })
      );
    }
  };

  onShowDomainModal = product => {
    if (this.state.assignedDomain === null) {
      this.setState({
        ...this.state,
        showDomainModal: true,
        selectedProduct: product
      });
    }
  };

  onHideDomainModal = () => {
    this.setState({
      ...this.state,
      showDomainModal: false
    });
  };

  onAssingDomainChange = domain => {
    this.setState({
      ...this.state,
      assignedDomain: domain
    });
  };

  onAssignDomain = () => {
    if (
      this.state.assignedDomain !== null &&
      this.state.selectedProduct !== null
    ) {
      this.props.assignDomainToProduct(
        this.state.assignedDomain,
        this.state.selectedProduct
      );

      this.onAddToCart(this.state.selectedProduct, this.state.assignedDomain);
      this.onHideDomainModal();
    }
  };

  selectAlreadyOwnedDomain = () => {
    if (
      this.state.alreadyOwnedDomain !== null &&
      this.state.selectedProduct !== null
    ) {
      this.onAddToCart(
        this.state.selectedProduct,
        this.state.alreadyOwnedDomain
      );

      this.onHideDomainModal();
    }
  };

  selectCustomDomain = () => {
    if (
      this.state.customDomain !== null &&
      this.state.selectedProduct !== null
    ) {
      this.onAddToCart(this.state.selectedProduct, this.state.customDomain);

      this.onHideDomainModal();
    }
  };

  onAddToCart = product => {
    if (product.type === PRODUCT_DOMAIN) {
      const domainsInCart = this.props.cart.items.filter(
        item => item.type === PRODUCT_DOMAIN
      );
      const hasDomain = domainsInCart.find(
        item => item.domain === product.domain
      );
      if (hasDomain) {
        return false;
      }
    }

    return this.props
      .addProductToCart(product)
      .then(
        result => {
          displayToastMessageForResponse(
            this.props.translate("shop.shop"),
            result,
            ROUTE_CART,
            this.props.translate("shop.view-cart")
          );
          this.onHideAssignDomainToHosting();
          return result;
        },
        error => {
          displayToastMessageForResponse(
            this.props.translate("shop.shop"),
            error
          );
          this.onHideAssignDomainToHosting();
        }
      )
      .catch(errorResult => {
        displayToastMessageForResponse(
          this.props.translate("shop.shop"),
          errorResult
        );
        this.onHideAssignDomainToHosting();
      });
  };

  onAddDomainToCart = e => {
    this.setState({
      domainAddToCartSubmitting: true
    });
    this.props
      .addProductToCart({
        type: "domain",
        domain_id: null,
        domain: this.state.searchingDomainInput,
        quantity: 1,
        config: {
          type: "register",
          period: 1
        }
      })
      .then(response => {
        displayToastMessageForResponse(
          this.props.translate("shop.shop"),
          response,
          ROUTE_CART,
          this.props.translate("shop.view-cart")
        );

        this.setState({
          domainAddToCartSubmitting: false,
          domainAvailable: null,
          searchingDomainInput: null,
          domainPrice: null,
          domainPriceCurrency: null,
          domainResultsAvailable: false
        });
      });
  };

  onRegisterDomainSearch = () => {
    this.setState({
      searchingDomain: true,
      domainAvailable: null,
      domainResultsAvailable: false
    });

    domainsService
      .isDomainAvailable(this.state.searchingDomainInput)
      .then(response => {
        this.setState({
          searchingDomain: false,
          domainAvailable: response.data.data.domain.status === "available",
          domainPrice: this.findDomainPrice(
            response.data.data.domain.pricing,
            this.extractExtensionFromDomainName(response.data.data.domain.name)
          ),
          domainPriceCurrency: this.findDomainCurrency(
            response.data.data.domain.pricing
          ),
          domainResultsAvailable: true
        });
      })
      .catch(error => {
        this.setState({
          searchingDomain: false
        });
      });
  };

  onDomainSearchInputChange = value => {
    this.setState({
      searchingDomainInput: value,
      domainResultsAvailable: false
    });
  };

  findDomainPrice = (pricing, extension) => {
    return pricing.register.billing_cycle.derivations[0].price.value;
  };

  findDomainCurrency = (pricing, extension) => {
    return pricing.register.billing_cycle.derivations[0].price.currency;
  };

  extractExtensionFromDomainName = domain => {
    const regex = /(?:\.([^.]+))?$/;

    return regex.exec(domain)[1];
  };

  remapDomainsWithoutHostingOptions = () => {
    const options = this.props.domainsWithoutHosting.map(domain => {
      return { label: domain.domain_name, value: domain.domain_name };
    });

    return options;
  };

  onDomainsWithoutHostingOptionChange = selectedOptionValue => {
    this.setState({ alreadyOwnedDomain: selectedOptionValue.value });
  };

  onCustomDomainInputChange = value => {
    this.setState({
      customDomain: value,
      customDomainValid: validateDomain(value)
    });
  };

  calculateHostingColSize = numberOfHostings => {
    const size = numberOfHostings > 1 ? Math.round(12 / numberOfHostings) : 4;

    return size > 4 ? size : 4;
  };

  onShowAssignDomainToHosting = hosting => {
    this.setState({
      assignHosting: hosting,
      showAssignDomainToHosting: true
    });
  };

  onHideAssignDomainToHosting = () => {
    this.setState({
      showAssignDomainToHosting: false
    });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => {
          return (
            <StyledHostingWrapper className="row" ref={this.setMountedRef}>
              <div className="col-12 col-sm-12 col-md-12">
                <StyledHostingHeader className="row">
                  <div className="col-12 col-sm-8 offset-sm-2 col-md-8 offset-md-2">
                    <StyledHeaderTitle>
                      {translate("shop.hostings.title")}
                    </StyledHeaderTitle>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-12">
                        <StyledSellingPoint>
                          <Icon icon="input-checkbox" />{" "}
                          {translate("shop.hostings.unlimited-email-addresses")}
                        </StyledSellingPoint>
                        <StyledSellingPoint>
                          <Icon icon="input-checkbox" />{" "}
                          {translate(
                            "shop.hostings.free-wordpress-installation"
                          )}
                        </StyledSellingPoint>
                        <StyledSellingPoint>
                          <Icon icon="input-checkbox" />{" "}
                          {translate("shop.hostings.unlimited-databases")}
                        </StyledSellingPoint>
                        <StyledExtraSellingPoint>
                          {translate("shop.hostings.free-domain")}
                        </StyledExtraSellingPoint>
                      </div>
                    </div>
                  </div>
                </StyledHostingHeader>
                <MobileOnlyView>
                  <StyledHostingContent className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {this.props.products && this.props.products.length > 0 && (
                        <Tabs
                          defaultTab={
                            ROUTE_SHOP_HOSTING +
                            "/" +
                            this.props.products[0].name
                              .replace(/\s+/g, "-")
                              .toLowerCase()
                          }
                          renderTabs={(active, onSelect) =>
                            this.props.products.map(tab => {
                              const tabName = tab.name
                                .replace(/\s+/g, "-")
                                .toLowerCase();
                              return (
                                <Tab
                                  key={`tab-id-${tab.id}`}
                                  label={tabName}
                                  active={active}
                                  linkTo={ROUTE_SHOP_HOSTING + "/" + tabName}
                                >
                                  {tab.name}
                                </Tab>
                              );
                            })
                          }
                          renderTabContent={active =>
                            this.props.products.map(tab => {
                              const tabName = tab.name
                                .replace(/\s+/g, "-")
                                .toLowerCase();

                              return (
                                <Switch key={`switch-tab-${tabName}`}>
                                  <Route
                                    path={ROUTE_SHOP_HOSTING + "/" + tabName}
                                    exact
                                    render={() => (
                                      <TabContent
                                        key={`tab-${tabName}`}
                                        label={tabName}
                                        active={true}
                                        isLoading={!!tab.hostings}
                                      >
                                        <div className="row">
                                          {tab.hostings &&
                                            tab.hostings.length > 0 &&
                                            tab.hostings.map(hosting => {
                                              if (
                                                hosting.pricing.buy
                                                  .billing_cycle.derivations
                                                  .length === 0
                                              )
                                                return null;
                                              const pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                                                d => d.numeric_code === 1
                                              );

                                              if (!pricing) {
                                                return null;
                                              }
                                              return (
                                                <div
                                                  key={`product-plan-${hosting.id}`}
                                                  className={`col-12 col-sm-12 col-md-12`}
                                                >
                                                  <ProductPlan
                                                    hosting={hosting}
                                                    onAddToCart={
                                                      this
                                                        .onShowAssignDomainToHosting
                                                    }
                                                    disabled={
                                                      !this.state.extensions
                                                    }
                                                    submitting={
                                                      this.state.submitting
                                                    }
                                                  />
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </TabContent>
                                    )}
                                  />
                                </Switch>
                              );
                            })
                          }
                        />
                      )}
                    </div>
                  </StyledHostingContent>
                </MobileOnlyView>
                <TabletView>
                  <StyledHostingContent className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {this.props.products && this.props.products.length > 0 && (
                        <Tabs
                          defaultTab={
                            ROUTE_SHOP_HOSTING +
                            "/" +
                            this.props.products[0].name
                              .replace(/\s+/g, "-")
                              .toLowerCase()
                          }
                          renderTabs={(active, onSelect) =>
                            this.props.products.map(tab => {
                              const tabName = tab.name
                                .replace(/\s+/g, "-")
                                .toLowerCase();
                              return (
                                <Tab
                                  key={`tab-id-${tab.id}`}
                                  label={tabName}
                                  active={active}
                                  linkTo={ROUTE_SHOP_HOSTING + "/" + tabName}
                                >
                                  {tab.name}
                                </Tab>
                              );
                            })
                          }
                          renderTabContent={active =>
                            this.props.products.map(tab => {
                              let colSize = this.calculateHostingColSize(
                                tab.hostings.length
                              );
                              const tabName = tab.name
                                .replace(/\s+/g, "-")
                                .toLowerCase();

                              return (
                                <Switch key={`switch-tab-${tabName}`}>
                                  <Route
                                    path={ROUTE_SHOP_HOSTING + "/" + tabName}
                                    exact
                                    render={() => (
                                      <TabContent
                                        key={`tab-${tabName}`}
                                        label={tabName}
                                        active={true}
                                      >
                                        <div className="row">
                                          {tab.hostings &&
                                            tab.hostings.length > 0 &&
                                            tab.hostings.map(hosting => {
                                              if (
                                                hosting.pricing.buy
                                                  .billing_cycle.derivations
                                                  .length === 0
                                              )
                                                return null;
                                              const pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                                                d => d.numeric_code === 1
                                              );

                                              if (!pricing) {
                                                return null;
                                              }
                                              return (
                                                <div
                                                  key={`product-plan-${hosting.id}`}
                                                  className={`col-12 col-sm-${colSize} col-md-${colSize} `}
                                                >
                                                  <ProductPlan
                                                    hosting={hosting}
                                                    onAddToCart={
                                                      this
                                                        .onShowAssignDomainToHosting
                                                    }
                                                    disabled={
                                                      !this.state.extensions
                                                    }
                                                    submitting={
                                                      this.state.submitting
                                                    }
                                                  />
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </TabContent>
                                    )}
                                  />
                                </Switch>
                              );
                            })
                          }
                        />
                      )}
                    </div>
                  </StyledHostingContent>
                </TabletView>
                <BrowserView>
                  {this.props.isLaptop && (
                    <HostingContentWithLoading
                      isLoading={this.props.isFetchingProducts}
                      className="row"
                    >
                      <div className="col-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1">
                        {this.props.products && this.props.products.length > 0 && (
                          <Tabs
                            defaultTab={
                              ROUTE_SHOP_HOSTING +
                              "/" +
                              this.props.products[0].name
                                .replace(/\s+/g, "-")
                                .toLowerCase()
                            }
                            renderTabs={(active, onSelect) =>
                              this.props.products.map(tab => {
                                const tabName = tab.name
                                  .replace(/\s+/g, "-")
                                  .toLowerCase();
                                return (
                                  <Tab
                                    key={`tab-id-${tab.id}`}
                                    label={tabName}
                                    onSelect={onSelect}
                                    active={active}
                                    linkTo={ROUTE_SHOP_HOSTING + "/" + tabName}
                                  >
                                    {tab.name}
                                  </Tab>
                                );
                              })
                            }
                            renderTabContent={active =>
                              this.props.products.map(tab => {
                                let colSize = this.calculateHostingColSize(
                                  tab.hostings.length
                                );
                                const tabName = tab.name
                                  .replace(/\s+/g, "-")
                                  .toLowerCase();

                                return (
                                  <Switch key={`switch-tab-${tabName}`}>
                                    <Route
                                      path={ROUTE_SHOP_HOSTING + "/" + tabName}
                                      exact
                                      render={() => (
                                        <TabContent
                                          key={`tab-${tabName}`}
                                          label={tabName}
                                          active={true}
                                        >
                                          <div className="row">
                                            {tab.hostings &&
                                              tab.hostings.length > 0 &&
                                              tab.hostings.map(hosting => {
                                                if (
                                                  hosting.pricing.buy
                                                    .billing_cycle.derivations
                                                    .length === 0
                                                )
                                                  return null;
                                                const pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                                                  d => d.numeric_code === 1
                                                );

                                                if (!pricing) {
                                                  return null;
                                                }
                                                return (
                                                  <div
                                                    key={`product-plan-${hosting.id}`}
                                                    className={`col-12 col-sm-${colSize} col-md-${colSize} `}
                                                  >
                                                    <ProductPlan
                                                      hosting={hosting}
                                                      onAddToCart={
                                                        this
                                                          .onShowAssignDomainToHosting
                                                      }
                                                      submitting={
                                                        this.state.submitting
                                                      }
                                                    />
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </TabContent>
                                      )}
                                    />
                                  </Switch>
                                );
                              })
                            }
                          />
                        )}
                      </div>
                    </HostingContentWithLoading>
                  )}
                  {!this.props.isLaptop && (
                    <HostingContentWithLoading
                      isLoading={this.props.isFetchingProducts}
                      className="row"
                    >
                      <div className="col-12 col-sm-8 offset-sm-2 col-md-8 offset-md-2">
                        {this.props.products && this.props.products.length > 0 && (
                          <Tabs
                            defaultTab={
                              ROUTE_SHOP_HOSTING +
                              "/" +
                              this.props.products[0].name
                                .replace(/\s+/g, "-")
                                .toLowerCase()
                            }
                            renderTabs={(active, onSelect) =>
                              this.props.products.map(tab => {
                                const tabName = tab.name
                                  .replace(/\s+/g, "-")
                                  .toLowerCase();
                                return (
                                  <Tab
                                    key={`tab-id-${tab.id}`}
                                    label={tabName}
                                    onSelect={onSelect}
                                    active={active}
                                    linkTo={ROUTE_SHOP_HOSTING + "/" + tabName}
                                  >
                                    {tab.name}
                                  </Tab>
                                );
                              })
                            }
                            renderTabContent={active =>
                              this.props.products.map(tab => {
                                let colSize = this.calculateHostingColSize(
                                  tab.hostings.length
                                );
                                const tabName = tab.name
                                  .replace(/\s+/g, "-")
                                  .toLowerCase();

                                return (
                                  <Switch key={`switch-tab-${tabName}`}>
                                    <Route
                                      path={ROUTE_SHOP_HOSTING + "/" + tabName}
                                      exact
                                      render={() => (
                                        <TabContent
                                          key={`tab-${tabName}`}
                                          label={tabName}
                                          active={true}
                                        >
                                          <div className="row">
                                            {tab.hostings &&
                                              tab.hostings.length > 0 &&
                                              tab.hostings.map(hosting => {
                                                if (
                                                  hosting.pricing.buy
                                                    .billing_cycle.derivations
                                                    .length === 0
                                                )
                                                  return null;
                                                const pricing = hosting.pricing.buy.billing_cycle.derivations.find(
                                                  d => d.numeric_code === 1
                                                );

                                                if (!pricing) {
                                                  return null;
                                                }
                                                return (
                                                  <div
                                                    key={`product-plan-${hosting.id}`}
                                                    className={`col-12 col-sm-${colSize} col-md-${colSize} `}
                                                  >
                                                    <ProductPlan
                                                      hosting={hosting}
                                                      disabled={
                                                        !this.state.extensions
                                                      }
                                                      onAddToCart={
                                                        this
                                                          .onShowAssignDomainToHosting
                                                      }
                                                      submitting={
                                                        this.state.submitting
                                                      }
                                                    />
                                                  </div>
                                                );
                                              })}
                                          </div>
                                        </TabContent>
                                      )}
                                    />
                                  </Switch>
                                );
                              })
                            }
                          />
                        )}
                      </div>
                    </HostingContentWithLoading>
                  )}
                </BrowserView>
              </div>
              {this.state.showAssignDomainToHosting && (
                <Modal
                  title={this.props.translate(
                    "shop.hostings.pick-domain-for-hosting"
                  )}
                  subTitle={
                    this.state.assignHosting.name +
                    " " +
                    this.props.translate("shop.hostings.hosting-package")
                  }
                  onCloseModal={this.onHideAssignDomainToHosting}
                  size="xl"
                  body={() => (
                    <PurchaseHosting
                      cart={this.props.cart}
                      cartId={this.props.cartId}
                      hosting={this.state.assignHosting}
                      onAssignDomainToHosting={this.onAssignDomainToHosting}
                      extensions={this.state.extensions}
                      currency={this.state.currency}
                      onAddToCart={this.onAddToCart}
                    />
                  )}
                />
              )}
            </StyledHostingWrapper>
          );
        }}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    products: getProductsListingByGroup(state),
    cartId: getCartId(state),
    cart: getCart(state),
    domains: getDomainsInCart(state),
    isFetchingProducts: isFetchingProducts(state),
    domainsWithoutHosting: getDomainsWithoutHosting(state),
    isAuthenticated: isAuthenticated(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchProductsByGroup,
      addProductToCart,
      assignDomainToProduct,
      fetchCart,
      fetchDomainsWithoutHosting
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(withLocalize(Hosting)));
