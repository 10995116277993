import axios from "axios";

import {
  NOTIFICATIONS_UNREAD_API_ENDPOINT,
  NOTIFICATIONS_ENABLE_API_ENDPOINT,
  NOTIFICATIONS_MARK_AS_READ_API_ENDPOINT,
  NOTIFICATIONS_CONFIG_API_ENDPOINT
} from "./endpoints";

export function fetchUnreadNotifications() {
  return axios.get(NOTIFICATIONS_UNREAD_API_ENDPOINT);
}

export function fetchNotificationsConfig() {
  return axios.get(NOTIFICATIONS_CONFIG_API_ENDPOINT);
}

export function enableNotifications(notification, enabled) {
  return axios.put(NOTIFICATIONS_ENABLE_API_ENDPOINT, {
    [notification]: enabled
  });
}

export function markAsRead(notifications_ids) {
  return axios.put(NOTIFICATIONS_MARK_AS_READ_API_ENDPOINT, {
    ids: notifications_ids
  });
}
