import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import withSizes from "react-sizes";
import isLaptop from "../../utils/isLaptopScreen";
import {
  MobileOnlyView,
  BrowserView,
  TabletView,
  isMobileOnly
} from "react-device-detect";
import { Translate, withLocalize } from "react-localize-redux";
import { debounce } from "throttle-debounce";

import Breadcrumb from "../../components/Breadcrumb";
import BreadcrumbItem from "../../components/Breadcrumb/BreadcrumbItem";

import withLoading from "../../components/Loaders/WithLoading";
import FilterLoader from "../../components/Loaders/FilterLoader";

import TicketsFilter from "./TicketsFilter";
import TicketsList from "./TicketsList";

import {
  fetchTickets,
  fetchTicketRelatedProducts,
  fetchTicketDepartments
} from "../HelpDesk/actions/helpdeskActions";
import {
  getTickets,
  isFetchingTickets,
  getTicketDepartments,
  getTicketRelatedProducts,
  isFetchingTicketDepartments,
  isFetchingTicketRelatedProducts,
  getTicketsListFilters
} from "../HelpDesk/reducers/helpdeskReducer";

import { getCartId } from "../Cart/reducers/cartReducer";

import {
  ROUTE_INDEX,
  ROUTE_HELPDESK_TICKET_VIEW,
  compileRoute
} from "../../routes/routes";

import {
  SORT_ORDER_DESCENDING,
  SORT_ORDER_ASCENDING
} from "../../types/SortTypes";

import { ALL_TICKET_STATUSES } from "./TicketStatuses/TicketStatuses";

const StyledTicketsWrapper = styled.div`
  margin-bottom: ${props => props.isMobile && "100px"};
`;

const TicketsFilterWithLoading = withLoading(TicketsFilter, FilterLoader);

class Tickets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchQuery:
        props.tickets.filters && props.tickets.filters.keyword
          ? props.tickets.filters.keyword
          : ""
    };

    this.searchTicketsByKeywordThrottled = debounce(
      1000,
      this.searchTicketsByKeyword
    );
  }

  componentDidMount() {
    this.props.fetchTickets(
      10,
      1,
      this.props.tickets.sort,
      this.props.tickets.filters
    );
  }

  handlePaginationClick = (event, page) => {
    event.preventDefault();

    if (page <= 0 || page > this.props.tickets.paging.total_pages) {
      return;
    }

    this.props.fetchTickets(
      this.props.tickets.paging.limit,
      page,
      this.props.tickets.sort,
      this.props.tickets.filters
    );
  };

  onPerPageOptionChanged = value => {
    this.props.fetchTickets(
      value.value,
      1,
      this.props.tickets.sort,
      this.props.tickets.filters
    );
  };

  changeListOrderTrigger = (sortBy, orderBy) => {
    this.props.fetchTickets(
      this.props.tickets.paging.limit,
      this.props.tickets.paging.current_page,
      {
        sort_by: sortBy,
        order_by:
          orderBy === SORT_ORDER_DESCENDING
            ? SORT_ORDER_ASCENDING
            : SORT_ORDER_DESCENDING
      },
      this.props.tickets.filters
    );
  };

  onChangeKeywordSearch = event => {
    this.setState({ searchQuery: event.target.value }, () => {
      this.searchTicketsByKeywordThrottled(this.state.searchQuery);
    });
  };

  searchTicketsByKeyword = value => {
    this.props.fetchTickets(
      this.props.tickets.paging.limit,
      1,
      this.props.tickets.sort,
      {
        ...this.props.tickets.filters,
        keyword: value
      }
    );
  };

  onStatusFilterOptionChange = selectedOptionValue => {
    this.props.fetchTickets(
      this.props.tickets.paging.limit,
      1,
      this.props.tickets.sort,
      {
        ...this.props.tickets.filters,
        status: [selectedOptionValue.value]
      }
    );
  };

  onDepartmentFilterOptionChange = selectedOptionValue => {
    this.props.fetchTickets(
      this.props.tickets.paging.limit,
      1,
      this.props.tickets.sort,
      {
        ...this.props.tickets.filters,
        department: selectedOptionValue.value
      }
    );
  };

  onProductsFilterOptionChange = selectedOptionValue => {
    this.props.fetchTickets(
      this.props.tickets.paging.limit,
      1,
      this.props.tickets.sort,
      {
        ...this.props.tickets.filters,
        product: selectedOptionValue.value
      }
    );
  };

  onFiltersClear = () => {
    this.props.fetchTickets(
      this.props.tickets.paging.limit,
      1,
      this.props.tickets.sort,
      {}
    );
  };

  countFiltersApplied = () => {
    const filters = Object.entries(this.props.tickets.filters).reduce(
      (accumulator, item) => {
        return item[0] !== "keyword" && (item[1] !== "" && item[1].length > 0)
          ? ++accumulator
          : accumulator;
      },
      0
    );
    return filters;
  };

  remapFilterStatusesCollection = (value, callback) => {
    const options = ALL_TICKET_STATUSES.map(status => {
      return {
        label: (
          <Translate>
            {({ translate }) =>
              translate("help-desk.ticket.statuses." + status)
            }
          </Translate>
        ),
        value: status
      };
    });

    callback(options);
  };

  remapTicketRelatedProducts = (value, callback) => {
    this.props.fetchTicketRelatedProducts().then(() => {
      const relatedProducts = this.props.products.map(product => {
        return {
          label: product.name,
          value: product.id
        };
      });

      callback(relatedProducts);
    });
  };

  remapTicketDepartments = (value, callback) => {
    this.props.fetchTicketDepartments().then(() => {
      const departments = this.props.departments.map(department => {
        return {
          label: department.name,
          value: department.id
        };
      });
      callback(departments);
    });
  };

  render() {
    return (
      <Translate>
        {({ translate }) => (
          <StyledTicketsWrapper isMobile={isMobileOnly} className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <Breadcrumb separator={true}>
                <BreadcrumbItem
                  to={ROUTE_INDEX}
                  title={translate("breadcrumbs.home")}
                />
                <BreadcrumbItem title={translate("hosting.title")} />
              </Breadcrumb>

              <MobileOnlyView>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <TicketsFilterWithLoading
                      ticketsListFilters={this.props.ticketsListFilters}
                      onStatusFilterOptionChange={
                        this.onStatusFilterOptionChange
                      }
                      onProductsFilterOptionChange={
                        this.onProductsFilterOptionChange
                      }
                      onDepartmentFilterOptionChange={
                        this.onDepartmentFilterOptionChange
                      }
                      onFiltersClear={this.onFiltersClear}
                      countFiltersApplied={this.countFiltersApplied}
                      filterStatusOptions={this.remapFilterStatusesCollection}
                      filterProductsOptions={this.remapTicketRelatedProducts}
                      filterDepartmentsOptions={this.remapTicketDepartments}
                      products={this.props.products}
                      departments={this.props.departments}
                      tickets={this.props.tickets}
                    />
                  </div>
                  <div className="col-12 col-sm-12 col-md-12">
                    {this.props.tickets && (
                      <TicketsList
                        isLoading={this.props.isFetching}
                        isLaptop={this.props.isLaptop}
                        tickets={this.props.tickets}
                        handlePaginationClick={this.handlePaginationClick}
                        onPerPageOptionChanged={this.onPerPageOptionChanged}
                        changeListOrderTrigger={this.changeListOrderTrigger}
                        onChangeKeywordSearch={this.onChangeKeywordSearch}
                        searchQuery={this.state.searchQuery}
                        ticketsListFilters={this.props.ticketsListFilters}
                        onStatusFilterOptionChange={
                          this.onStatusFilterOptionChange
                        }
                        onProductsFilterOptionChange={
                          this.onProductsFilterOptionChange
                        }
                        onDepartmentFilterOptionChange={
                          this.onDepartmentFilterOptionChange
                        }
                        onFiltersClear={this.onFiltersClear}
                        countFiltersApplied={this.countFiltersApplied}
                        filterStatusOptions={this.remapFilterStatusesCollection}
                        filterProductsOptions={this.remapTicketRelatedProducts}
                        filterDepartmentsOptions={this.remapTicketDepartments}
                        products={this.props.products}
                        departments={this.props.departments}
                      />
                    )}
                  </div>
                </div>
              </MobileOnlyView>
              <TabletView>
                <div className="row">
                  <div className="col-4 col-sm-4 col-md-4">
                    <TicketsFilterWithLoading
                      ticketsListFilters={this.props.ticketsListFilters}
                      onStatusFilterOptionChange={
                        this.onStatusFilterOptionChange
                      }
                      onProductsFilterOptionChange={
                        this.onProductsFilterOptionChange
                      }
                      onDepartmentFilterOptionChange={
                        this.onDepartmentFilterOptionChange
                      }
                      onFiltersClear={this.onFiltersClear}
                      countFiltersApplied={this.countFiltersApplied}
                      filterStatusOptions={this.remapFilterStatusesCollection}
                      filterProductsOptions={this.remapTicketRelatedProducts}
                      filterDepartmentsOptions={this.remapTicketDepartments}
                      products={this.props.products}
                      departments={this.props.departments}
                      tickets={this.props.tickets}
                    />
                  </div>
                  <div className="col-8 col-sm-8 col-md-8">
                    {this.props.tickets && (
                      <TicketsList
                        isLoading={this.props.isFetching}
                        isLaptop={this.props.isLaptop}
                        tickets={this.props.tickets}
                        handlePaginationClick={this.handlePaginationClick}
                        onPerPageOptionChanged={this.onPerPageOptionChanged}
                        changeListOrderTrigger={this.changeListOrderTrigger}
                        onChangeKeywordSearch={this.onChangeKeywordSearch}
                        searchQuery={this.state.searchQuery}
                        ticketsListFilters={this.props.ticketsListFilters}
                        onStatusFilterOptionChange={
                          this.onStatusFilterOptionChange
                        }
                        onProductsFilterOptionChange={
                          this.onProductsFilterOptionChange
                        }
                        onDepartmentFilterOptionChange={
                          this.onDepartmentFilterOptionChange
                        }
                        onFiltersClear={this.onFiltersClear}
                        countFiltersApplied={this.countFiltersApplied}
                        filterStatusOptions={this.remapFilterStatusesCollection}
                        filterProductsOptions={this.remapTicketRelatedProducts}
                        filterDepartmentsOptions={this.remapTicketDepartments}
                        products={this.props.products}
                        departments={this.props.departments}
                      />
                    )}
                  </div>
                </div>
              </TabletView>
              <BrowserView>
                {this.props.isLaptop && (
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12">
                      {this.props.tickets && (
                        <TicketsList
                          isLoading={this.props.isFetching}
                          isLaptop={this.props.isLaptop}
                          tickets={this.props.tickets}
                          handlePaginationClick={this.handlePaginationClick}
                          onPerPageOptionChanged={this.onPerPageOptionChanged}
                          changeListOrderTrigger={this.changeListOrderTrigger}
                          onChangeKeywordSearch={this.onChangeKeywordSearch}
                          searchQuery={this.state.searchQuery}
                          ticketsListFilters={this.props.ticketsListFilters}
                          onStatusFilterOptionChange={
                            this.onStatusFilterOptionChange
                          }
                          onProductsFilterOptionChange={
                            this.onProductsFilterOptionChange
                          }
                          onDepartmentFilterOptionChange={
                            this.onDepartmentFilterOptionChange
                          }
                          onFiltersClear={this.onFiltersClear}
                          countFiltersApplied={this.countFiltersApplied}
                          filterStatusOptions={
                            this.remapFilterStatusesCollection
                          }
                          filterProductsOptions={
                            this.remapTicketRelatedProducts
                          }
                          filterDepartmentsOptions={this.remapTicketDepartments}
                          products={this.props.products}
                          departments={this.props.departments}
                        />
                      )}
                    </div>
                  </div>
                )}
                {!this.props.isLaptop && (
                  <div className="row">
                    <div className="col-12 col-sm-3 col-md-3">
                      <TicketsFilterWithLoading
                        ticketsListFilters={this.props.ticketsListFilters}
                        onStatusFilterOptionChange={
                          this.onStatusFilterOptionChange
                        }
                        onProductsFilterOptionChange={
                          this.onProductsFilterOptionChange
                        }
                        onDepartmentFilterOptionChange={
                          this.onDepartmentFilterOptionChange
                        }
                        onFiltersClear={this.onFiltersClear}
                        countFiltersApplied={this.countFiltersApplied}
                        filterStatusOptions={this.remapFilterStatusesCollection}
                        filterProductsOptions={this.remapTicketRelatedProducts}
                        filterDepartmentsOptions={this.remapTicketDepartments}
                        products={this.props.products}
                        departments={this.props.departments}
                        tickets={this.props.tickets}
                      />
                    </div>
                    <div className="col-12 col-sm-9 col-md-9">
                      {this.props.tickets && (
                        <TicketsList
                          isLoading={this.props.isFetching}
                          isLaptop={this.props.isLaptop}
                          tickets={this.props.tickets}
                          handlePaginationClick={this.handlePaginationClick}
                          onPerPageOptionChanged={this.onPerPageOptionChanged}
                          changeListOrderTrigger={this.changeListOrderTrigger}
                          onChangeKeywordSearch={this.onChangeKeywordSearch}
                          searchQuery={this.state.searchQuery}
                          ticketsListFilters={this.props.ticketsListFilters}
                          onStatusFilterOptionChange={
                            this.onStatusFilterOptionChange
                          }
                          onProductsFilterOptionChange={
                            this.onProductsFilterOptionChange
                          }
                          onDepartmentFilterOptionChange={
                            this.onDepartmentFilterOptionChange
                          }
                          onFiltersClear={this.onFiltersClear}
                          countFiltersApplied={this.countFiltersApplied}
                          filterStatusOptions={
                            this.remapFilterStatusesCollection
                          }
                          filterProductsOptions={
                            this.remapTicketRelatedProducts
                          }
                          filterDepartmentsOptions={this.remapTicketDepartments}
                          products={this.props.products}
                          departments={this.props.departments}
                        />
                      )}
                    </div>
                  </div>
                )}
              </BrowserView>
            </div>
          </StyledTicketsWrapper>
        )}
      </Translate>
    );
  }
}

const mapStateToProps = state => {
  return {
    tickets: getTickets(state),
    ticketsListFilters: getTicketsListFilters(state),
    cartId: getCartId(state),
    isFetching: isFetchingTickets(state),
    departments: getTicketDepartments(state),
    products: getTicketRelatedProducts(state),
    isFetchingTicketDepartments: isFetchingTicketDepartments(state),
    isFetchingTicketRelatedProducts: isFetchingTicketRelatedProducts(state)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchTickets,
      fetchTicketRelatedProducts,
      fetchTicketDepartments
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSizes(isLaptop)(withLocalize(Tickets)));
