import React from "react";
import styled from "styled-components";

import ActionButton from "../Buttons/ActionButton";
import DropdownMenu from "../DropdowMenu";

const StyledDropdownButton = styled.div`
  position: relative;
  display: inline-block;
  & > .btn.dropdown-toggle {
    padding-left: 10px;
    padding-right: 10px;
  }

  & > .btn.dropdown-toggle:after {
    border-top: ${props =>
      typeof props.text !== "function" ? "6px solid" : "0"};
    border-right: ${props =>
      typeof props.text !== "function" ? " 6px solid transparent" : "0"};
    border-bottom: 0;
    border-left: ${props =>
      typeof props.text !== "function" ? "6px solid transparent" : "0"};
    vertical-align: middle;
  }

  & .icon {
    left: 2px;
  }
`;

StyledDropdownButton.defaultsProps = {
  className: "dropdown"
};

class DropdownButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
  }

  onShowMenu = e => {
    e.preventDefault();
    this.setState({ opened: true }, () => {
      document.addEventListener("click", this.onCloseMenu);
    });
  };

  onCloseMenu = e => {
    e.preventDefault();
    this.setState({ opened: false }, () => {
      document.removeEventListener("click", this.onCloseMenu);
    });
  };

  componentWillUnmount() {
    document.removeEventListener("click", this.onCloseMenu);
  }

  render() {
    const { children, ...rest } = this.props;

    return (
      <StyledDropdownButton
        className={`dropdown ${this.state.opened ? "show" : ""}`}
        {...rest}
      >
        <ActionButton
          className="btn dropdown-toggle"
          size={rest.size}
          onClick={this.onShowMenu}
        >
          {typeof rest.text === "function" && rest.text()}
          {typeof rest.text !== "function" && rest.text}
        </ActionButton>

        <DropdownMenu
          className={`${this.state.opened ? "show" : ""}`}
          alignMenu={rest.alignMenu}
        >
          {children}
        </DropdownMenu>
      </StyledDropdownButton>
    );
  }
}

export default DropdownButton;
