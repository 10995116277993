import React from "react";
import styled from "styled-components";
import { theme } from "../../../design/Themes";
import { Link } from "react-router-dom";

import KnowledgeBaseArticleMeta from "../KnowledgeBaseArticleMeta";

const StyledKnowledgeBaseArticle = styled.div`
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;
const StyledTitle = styled.p`
  margin-bottom: 5px;
  & .popular-article-link {
    font-size: 16px;
    font-weight: 600;
    line-height: 1.38;
    color: ${theme.blueBase};
  }
`;
const StyledContent = styled.p`
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  margin-bottom: 0;
`;

const KnowledgeBaseArticle = ({
  article,
  title,
  children,
  votes,
  categoryName,
  showMeta,
  articleLink,
  onVoteArticle
}) => (
  <StyledKnowledgeBaseArticle>
    <div className="row">
      <div className="col-12 col-sm-12 col-md-12">
        {showMeta &&
          article && (
            <KnowledgeBaseArticleMeta
              categoryName={categoryName}
              votes={votes}
              onVoteArticle={onVoteArticle}
              article={article}
              articleId={article && article.id ? article.id : article}
            />
          )}
        <StyledTitle>
          <Link className="popular-article-link" to={articleLink}>
            {title}
          </Link>
        </StyledTitle>
      </div>
      <div className="col-12 col-sm-12 col-md-12">
        <StyledContent>{children}</StyledContent>
      </div>
    </div>
  </StyledKnowledgeBaseArticle>
);

export default KnowledgeBaseArticle;
