export const DOMAIN_STATUS_ACTIVE = "active";
export const DOMAIN_STATUS_PENDING = "pending";
export const DOMAIN_STATUS_PENDING_TRANSFER = "pending transfer";
export const DOMAIN_STATUS_CANCELLED = "cancelled";
export const DOMAIN_STATUS_AVAILABLE = "available";
export const DOMAIN_STATUS_UNAVAILABLE = "unavailable";
export const DOMAIN_STATUS_GRACE_PERIOD = "grace";
export const DOMAIN_STATUS_REDEMPTION = "redemption";
export const DOMAIN_STATUS_INVALID = "invalid";
export const DOMAIN_STATUS_UNSUPPORTED = "unsupported";
export const DOMAIN_STATUS_ERROR = "error";
