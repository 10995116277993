import React from "react";
import styled from "styled-components";
import Tooltip from "rc-tooltip";

const StyledToolip = styled.div`
  display: ${props => props.inline && "inline-block"};
`;

const CustomTooltip = ({
  children,
  content,
  inline,
  trigger,
  preventClick,
  placement,
  customClassName,
  ...rest
}) => (
  <StyledToolip
    className={`custom-tooltip ${customClassName}`}
    inline={!!inline}
    onClick={e => {
      if (preventClick) {
        
        e.stopPropagation();
      }
    }}
  >
    <Tooltip
      placement={placement ? placement : "top"}
      trigger={trigger ? trigger : ["hover"]}
      overlay={content}
      {...rest}
    >
      {children}
    </Tooltip>
  </StyledToolip>
);

CustomTooltip.defaultProps = {
  preventClick: true
};
export default CustomTooltip;
