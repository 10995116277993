import React from "react";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";

import InputField from "../../../../../../../components/InputField";

const StyledWeight = styled.div`
  display: inline-block;
  width: 80px;
  margin-right: 15px;
  vertical-align: top;
`;

const Weight = props => (
  <StyledWeight>
    <InputField label={props.translate("domain.zone-file.weight")} {...props} />
  </StyledWeight>
);

export default withLocalize(Weight);
