import React from "react";
import styled from "styled-components";
import { theme } from "../../../../design/Themes";
import { MobileOnlyView, TabletView, BrowserView } from "react-device-detect";
import withSizes from "react-sizes";
import isLaptop from "../../../../utils/isLaptopScreen";

import DiscountedAmountBadge from "../../../../components/DiscountedAmountBadge";
import CompactCartDomainProductAddon from "./CompactCartDomainProductAddon";
import { Translate } from "react-localize-redux";

import { CART_DOMAIN_TYPE_REGISTER } from "../../CartDomainTypes/CartDomainTypes";

const StyledCompactCartDomainProduct = styled.div`
  border-bottom: 1px solid ${theme.neutral3};
  padding: 30px;
`;

const StyledProductType = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutralBase};
`;
const StyledProductTitle = styled.span`
  display: block;
  font-size: 14px;
  line-height: 1.43;
  color: ${theme.neutral6};
  word-break: break-word;
`;

const CompactCartDomainProduct = ({ product, isLaptop }) => (
  <Translate>
    {({ translate }) => (
      <StyledCompactCartDomainProduct>
        <MobileOnlyView>
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12">
              <StyledProductType>
                {product.config.type === CART_DOMAIN_TYPE_REGISTER
                  ? translate("cart.domain-registration")
                  : translate("cart.domain-transfer")}
              </StyledProductType>
              <StyledProductTitle>{product.domain}</StyledProductTitle>
            </div>
            <div className="col-12 col-sm-12 col-md-12 text-right">
              <DiscountedAmountBadge
                amount={{
                  currency: product.item.pricing[
                    product.config.type
                  ].billing_cycle.derivations.find(
                    derivation =>
                      derivation.numeric_code === product.config.period
                  ).price.currency.code,
                  amount: product.total,
                  discountedAmount:
                    product.total < product.price ? product.price : null
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12" />
          </div>
        </MobileOnlyView>
        <TabletView>
          <div className="row">
            <div className="col-8 col-sm-8 col-md-8">
              <StyledProductType>
                {product.config.type === CART_DOMAIN_TYPE_REGISTER
                  ? translate("cart.domain-registration")
                  : translate("cart.domain-transfer")}
              </StyledProductType>
              <StyledProductTitle>{product.domain}</StyledProductTitle>
            </div>
            <div className="col-4 col-sm-4 col-md-4 text-right">
              <DiscountedAmountBadge
                amount={{
                  currency: product.item.pricing[
                    product.config.type
                  ].billing_cycle.derivations.find(
                    derivation =>
                      derivation.numeric_code === product.config.period
                  ).price.currency.code,
                  amount: product.total,
                  discountedAmount:
                    product.total < product.price ? product.price : null
                }}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-12" />
          </div>
        </TabletView>
        <BrowserView>
          {isLaptop && (
            <div className="row">
              <div className="col-7 col-sm-7 col-md-7">
                <StyledProductType>
                  {product.config.type === CART_DOMAIN_TYPE_REGISTER
                    ? translate("cart.domain-registration")
                    : translate("cart.domain-transfer")}
                </StyledProductType>
                <StyledProductTitle>
                  {product.domain} - {product.config.period}{" "}
                  {product.config.period > 1
                    ? ` ${translate("cart.years")}`
                    : ` ${translate("cart.year")}`}
                </StyledProductTitle>
              </div>
              <div className="col-5 col-sm-5 col-md-5 text-right">
                <DiscountedAmountBadge
                  amount={{
                    currency: product.item.pricing[
                      product.config.type
                    ].billing_cycle.derivations.find(
                      derivation =>
                        derivation.numeric_code === product.config.period
                    ).price.currency.code,
                    amount: product.total,
                    discountedAmount:
                      product.total < product.price ? product.price : null
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12" />
            </div>
          )}
          {!isLaptop && (
            <div className="row">
              <div className="col-8 col-sm-8 col-md-8">
                <StyledProductType>
                  {product.config.type === CART_DOMAIN_TYPE_REGISTER
                    ? translate("cart.domain-registration")
                    : translate("cart.domain-transfer")}
                </StyledProductType>
                <StyledProductTitle>
                  {product.domain} - {product.config.period}{" "}
                  {product.config.period > 1
                    ? ` ${translate("cart.years")}`
                    : ` ${translate("cart.year")}`}
                </StyledProductTitle>
              </div>
              <div className="col-4 col-sm-4 col-md-4 text-right">
                <DiscountedAmountBadge
                  amount={{
                    currency: product.item.pricing[
                      product.config.type
                    ].billing_cycle.derivations.find(
                      derivation =>
                        derivation.numeric_code === product.config.period
                    ).price.currency.code,
                    amount: product.total,
                    discountedAmount:
                      product.total < product.price ? product.price : null
                  }}
                />
              </div>
              <div className="col-12 col-sm-12 col-md-12" />
            </div>
          )}
        </BrowserView>
        {product.item.services &&
          product.item.services.length > 0 &&
          product.item.services
            .filter(service => service.activated)
            .map(service => (
              <CompactCartDomainProductAddon
                key={`ccdps-${service.id}`}
                service={service}
              />
            ))}
      </StyledCompactCartDomainProduct>
    )}
  </Translate>
);

CompactCartDomainProduct.defaultProps = {
  domain: "Domain",
  price: {
    currency: "EUR",
    amount: 30.5
  },
  addons: null
};

export default withSizes(isLaptop)(CompactCartDomainProduct);
