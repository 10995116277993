import React from "react";
import styled from "styled-components";
import { Form, Field } from "react-final-form";
import { isMobileOnly, isTablet } from "react-device-detect";
import { Translate } from "react-localize-redux";

import TextArea from "../../../../../components/TextArea";
import FileUpload from "../../../../../components/FileUpload";
//import Form from "../../../../components/Forms/Form";

import PrimaryButton from "../../../../../components/Buttons/PrimaryButton";
import SecondaryButton from "../../../../../components/Buttons/SecondaryButton";

const StyledTicketReplyForm = styled.div`
  .form-group {
    width: 100%;
    margin-bottom: 25px;
    .btn.dropdown-toggle {
      width: 100%;
    }
  }
  .related-product-col {
    @media (min-width: 768px) and (max-width: 1199px) {
      padding-left: 5px;
      padding-right: 5px;
    }
    @media (min-width: 1441px) {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  .dropdown-col {
    @media (min-width: 1200px) and (max-width: 1440px) {
      width: 100%;
      flex: 100%;
      max-width: 100%;
    }
    @media (max-width: 767px) {
      width: 100%;
      flex: 100%;
      max-width: 100%;
    }
  }
  input {
    min-width: auto;
  }
`;

const acceptFileExtensions = [".jpg", ".gif", ".jpeg", ".png", ".txt", ".pdf"];

const TicketReplyForm = props => (
  <Translate>
    {({ translate }) => {
      return (
        <StyledTicketReplyForm>
          <Form
            onSubmit={props.onSubmit}
            validate={props.validate}
            render={({ handleSubmit, submitting, invalid, form }) => (
              <form
                onSubmit={e => {
                  handleSubmit(e).then(form.reset);
                }}
              >
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <Field
                      component={TextArea}
                      name="message"
                      label={translate("help-desk.ticket.message")}
                      placeholder={translate("help-desk.ticket.enter-message")}
                    />
                  </div>

                  <div className="col-12 col-sm-12 col-md-12">
                    <Field
                      component={FileUpload}
                      acceptFileExtensions={acceptFileExtensions}
                      name="attachments"
                      label={translate("help-desk.ticket.attachments")}
                      helpText={acceptFileExtensions.join(", ")}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <SecondaryButton onClick={() => props.onCancel()}>
                      {translate("help-desk.ticket.cancel")}
                    </SecondaryButton>{" "}
                    <PrimaryButton
                      disabled={submitting || invalid}
                      submitting={submitting}
                      type="submit"
                    >
                      {translate("help-desk.ticket.send-reply")}
                    </PrimaryButton>
                  </div>
                </div>
              </form>
            )}
          />
        </StyledTicketReplyForm>
      );
    }}
  </Translate>
);

export default TicketReplyForm;
