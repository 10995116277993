import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import Button from "../Buttons/Button";

const StyledButtonGroupContentDropdown = styled.div`
  background-color: ${theme.white};
  border-color: ${theme.neutral3};
  top: 0;

  & > button {
    margin-right: 0;
  }

  & > button:disabled {
    background-color: ${theme.white};
    border-color: ${theme.neutral3};
    color: ${theme.neutral4};
  }

  & > .btn.dropdown-toggle {
    padding-left: 10px;
    padding-right: 10px;
  }

  & > .btn.dropdown-toggle:after {
    border-top: 6px solid;
    border-right: 6px solid transparent;
    border-bottom: 0;
    border-left: 6px solid transparent;
    vertical-align: middle;
  }
`;

const StyledContent = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  left: auto;
  z-index: 1000;
  display: block;
  float: left;
  min-width: 300px;
  margin: 0;
  padding: 0;
  text-align: left;
`;

class ButtonGroupContentDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
  }

  onShowMenu = e => {
    e.preventDefault();
    this.setState({ opened: true });
  };

  onCloseMenu = e => {
    e.preventDefault();
    this.setState({ opened: false });
  };

  onToggleMenu = e => {
    e.preventDefault();
    this.setState({
      opened: !this.state.opened
    });
  };

  render() {
    return (
      <StyledButtonGroupContentDropdown
        className={`btn-group ${this.state.opened ? "show" : ""}`}
        {...this.props}
      >
        <Button
          className="btn dropdown-toggle"
          size={this.props.size}
          onClick={this.onToggleMenu}
          disabled={this.props.disabled}
        >
          {this.props.text}
        </Button>

        {this.state.opened && (
          <StyledContent>{this.props.children}</StyledContent>
        )}
      </StyledButtonGroupContentDropdown>
    );
  }
}

ButtonGroupContentDropdown.defaultProps = {
  alignMenu: "right"
};

export default ButtonGroupContentDropdown;
