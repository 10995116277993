import React from "react";
import styled from "styled-components";
import { withLocalize } from "react-localize-redux";

import InputField from "../../../../../../../components/InputField";

const StyledPort = styled.div`
  display: inline-block;
  width: 100px;
  margin-right: 15px;
  vertical-align: top;
`;

const Port = props => (
  <StyledPort>
    <InputField label={props.translate("domain.zone-file.port")} {...props} />
  </StyledPort>
);

export default withLocalize(Port);
