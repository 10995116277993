import React from "react";

import Loader from "../Loader";

const KnowledgeBaseArticleLoader = () => (
  <Loader width="280" height="300">
    <rect x="5" y="10" width="30" height="10" rx="3" />
    <rect x="40" y="10" width="30" height="10" rx="3" />
    <rect x="5" y="30" width="270" height="20" rx="3" />
    <rect x="5" y="60" width="270" height="200" rx="3" />
  </Loader>
);

export default KnowledgeBaseArticleLoader;
