import React from "react";
import styled from "styled-components";
import { theme } from "../../design/Themes";
import { Link } from "react-router-dom";
import { Translate } from "react-localize-redux";
import { isMobileOnly, isTablet, isBrowser } from "react-device-detect";
import withSizes from "react-sizes";
import isLaptop from "../../utils/isLaptopScreen";

import Container from "../../components/Containers/Container";
import WidgetTitle from "../WidgetTitle";

import Table from "../../components/Table";
import TableBody from "../../components/Table/TableBody";
import TableRow from "../../components/Table/TableRow";
import TableCell from "../../components/Table/TableCell";

import SecondaryButton from "../../components/Buttons/SecondaryButton";

import AmountBadge from "../../components/AmountBadge";

import ProInvoicesWidgetEmptyState from "./ProInvoiceWidgetEmptyState";

import withLoading from "../../components/Loaders/WithLoading";
import ProInvoicesWidgetLoader from "../../components/Loaders/ProInvoicesWidgetLoader";

import getBadgeForStatus from "../../utils/getBadgeForStatus";
import getStatusTextForStatus from "../../utils/getStatusTextForStatus";
import { PROINVOICE_UNPAID } from "../../features/Billing/ProInvoices/ProInvoicesStatus/ProInvoicesStatus";

const StyledProInvoicesWidget = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;

  & .widget-title {
    border-bottom: 1px solid ${theme.neutral3};
  }
`;

const StyledProInvoicesWidgetContent = styled.div`
  margin-top: -1px;
  & a.proinvoice-link {
    font-size: 16px;
  }

  & a.proinvoice-link-block {
    display: block;
  }

  & span.domain-url {
    font-size: 14px;
    color: ${theme.neutral6};
  }
`;

const ProInvoicesWidget = props => {
  const ProInvoicesWidgetContentWithLoading = withLoading(
    StyledProInvoicesWidgetContent,
    ProInvoicesWidgetLoader
  );
  return (
    <Translate>
      {({ translate }) => (
        <Container className="widget-container">
          <StyledProInvoicesWidget className="widget pro-invoices-widget">
            <WidgetTitle
              title={translate("home.widgets.pro-invoices.title")}
              actionTo={props.proInvoicesRoute()}
              actionTitle={translate(
                "home.widgets.pro-invoices.view-all-pro-invoices"
              )}
            />
            <StyledProInvoicesWidgetContent
              isLoading={props.isFetchingProInvoices}
            >
              <div className="row">
                <div className="col-12 col-sm-12 col-md-12">
                  {props.proinvoices.length > 0 ? (
                    <Table>
                      <TableBody>
                        {props.proinvoices.map(proinvoice => {
                          const StatusComponent = getStatusTextForStatus(
                            proinvoice.status.state
                          );
                          const BadgeComponent = getBadgeForStatus(
                            proinvoice.status.state
                          );
                          return (
                            <TableRow key={`proinvoice-${proinvoice.id}`}>
                              <TableCell className="row-selector">
                                <Link
                                  className={
                                    isTablet || isMobileOnly
                                      ? "proinvoice-link proinvoice-link-block"
                                      : "proinvoice-link"
                                  }
                                  to={props.viewProinvoiceRoute({
                                    id: proinvoice.id
                                  })}
                                >
                                  #{proinvoice.id}
                                </Link>{" "}
                                {(isTablet || isMobileOnly) && (
                                  <StatusComponent>
                                    {proinvoice.status.code ===
                                    PROINVOICE_UNPAID
                                      ? proinvoice.status.overdue_days > 0
                                        ? translate(
                                            "pro-invoices.table.invoice-statuses.not-paid-matures",
                                            {
                                              days: Math.abs(
                                                proinvoice.status.overdue_days
                                              )
                                            }
                                          )
                                        : translate(
                                            "pro-invoices.table.invoice-statuses.not-paid",
                                            {
                                              days: Math.abs(
                                                proinvoice.status.overdue_days
                                              )
                                            }
                                          )
                                      : translate(
                                          "pro-invoices.table.invoice-statuses." +
                                            proinvoice.status.code
                                        )}
                                  </StatusComponent>
                                )}
                              </TableCell>
                              {isBrowser && (
                                <TableCell className="text-center">
                                  <BadgeComponent>
                                    {proinvoice.status.code ===
                                    PROINVOICE_UNPAID
                                      ? proinvoice.status.overdue_days > 0
                                        ? translate(
                                            "pro-invoices.table.invoice-statuses.not-paid-matures",
                                            {
                                              days: Math.abs(
                                                proinvoice.status.overdue_days
                                              )
                                            }
                                          )
                                        : translate(
                                            "pro-invoices.table.invoice-statuses.not-paid",
                                            {
                                              days: Math.abs(
                                                proinvoice.status.overdue_days
                                              )
                                            }
                                          )
                                      : translate(
                                          "pro-invoices.table.invoice-statuses." +
                                            proinvoice.status.code
                                        )}
                                  </BadgeComponent>
                                </TableCell>
                              )}
                              <TableCell>
                                <AmountBadge
                                  currency={proinvoice.currency.code}
                                  amount={proinvoice.total}
                                />
                              </TableCell>

                              {!isMobileOnly && !props.isLaptop && (
                                <TableCell className="text-right actions">
                                  {proinvoice.status.code === "unpaid" &&
                                    isBrowser && (
                                      <Link
                                        to={props.viewProinvoiceRoute({
                                          id: proinvoice.id
                                        })}
                                      >
                                        <SecondaryButton size="s">
                                          {translate(
                                            "pro-invoices.table.pay-now"
                                          )}
                                        </SecondaryButton>
                                      </Link>
                                    )}
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  ) : (
                    <ProInvoicesWidgetEmptyState />
                  )}
                </div>
              </div>
            </StyledProInvoicesWidgetContent>
          </StyledProInvoicesWidget>
        </Container>
      )}
    </Translate>
  );
};

ProInvoicesWidget.defaultProps = {
  proinvoices: null
};

export default withSizes(isLaptop)(ProInvoicesWidget);
